import {
  FormControlLabel,
  Divider,
  Card,
  CardContent,
  Switch,
  IconButton,
  Chip,
  Tooltip
} from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Icon } from '@iconify/react';
import accountCircle from '@iconify/icons-mdi/account-circle';
import emailPlus from '@iconify/icons-mdi/email-plus-outline';

import { MdError } from 'react-icons/md';
import PhoneAddSVG from 'assets/svg/PhoneAddSVG';
import { COLORS } from 'utils/colors';
import { AddPhoneDialog } from '../../../general/AddPhoneDialog';
import { AddEmailDialog } from '../../../general/AddEmailDialog';

const StorageMonitorAlerts = ({
  selectedStorage,
  setSelectedStorage,
  setIsStorageEdited,
  userInfo,
  classes,
  i18n
}) => {
  const dispatch = useDispatch();

  const [isAddPhoneDialog, setIsAddPhoneDialog] = useState(false);
  const [isAddEmailDialog, setIsAddEmailDialog] = useState(false);

  // console.log('monitor sms :', userInfo, selectedStorage.alertMessaging?.sms);
  // SMS
  const [smsON, setSmsON] = useState(selectedStorage.alertMessaging?.sms?.active || false);
  const [emailON, setEmailON] = useState(selectedStorage.alertMessaging?.email?.active || false);

  const smsHandler = (e) => {
    setSmsON(e.target.checked);
    setSelectedStorage((tempStorage) => ({
      ...tempStorage,
      alertMessaging: {
        ...selectedStorage.alertMessaging,
        sms: { ...selectedStorage.alertMessaging?.sms, active: e.target.checked }
      }
    }));
    if (setIsStorageEdited) {
      setIsStorageEdited(true);
      dispatch({ type: 'SET_EDIT_CONFIRM_NEEDED', value: true });
    }
  };

  const emailHandler = (e) => {
    setEmailON(e.target.checked);
    setSelectedStorage((tempStorage) => ({
      ...tempStorage,
      alertMessaging: {
        ...selectedStorage.alertMessaging,
        email: { ...selectedStorage.alertMessaging?.email, active: e.target.checked }
      }
    }));
    if (setIsStorageEdited) {
      setIsStorageEdited(true);
      dispatch({ type: 'SET_EDIT_CONFIRM_NEEDED', value: true });
    }
  };

  // CHIP PHONE DATA
  const selectedPhones = selectedStorage.alertMessaging?.sms?.UIDS || [];
  const selectedEmails = selectedStorage.alertMessaging?.email?.UIDS || [];

  // PHONE LIST
  const phoneList = [
    {
      UID: userInfo.U_ID,
      phoneNumber: userInfo.phones[0].phoneNumber,
      phoneName: userInfo.phones[0].phoneName || userInfo.firstname,
      checked: selectedStorage.alertMessaging?.sms?.UIDS?.includes(userInfo.U_ID) || false
    },
    userInfo.subUsers?.map((sub) => ({
      UID: sub.U_ID,
      phoneNumber: sub.phone,
      phoneName: sub.name || null,
      checked:
        selectedStorage.alertMessaging?.sms?.UIDS?.map((UID) => UID === sub.U_ID).includes(true) ||
        false
    }))
  ]
    .filter(Boolean)
    .flat(1);

  // EMAIL LIST
  const emailList = [
    {
      UID: userInfo.U_ID,
      emailAddress: userInfo.email,
      emailName: userInfo.firstname,
      checked: selectedStorage.alertMessaging?.email?.UIDS?.includes(userInfo.U_ID) || false
    },
    userInfo.subUsers?.map((sub) => ({
      UID: sub.U_ID,
      emailAddress: sub.email,
      emailName: sub.name || null,
      checked:
        selectedStorage.alertMessaging?.email?.UIDS?.map((UID) => UID === sub.U_ID).includes(
          true
        ) || false
    }))
  ]
    .filter(Boolean)
    .flat(1);

  const [phoneNumbers, setPhoneNumbers] = useState(phoneList);
  const [emails, setEmails] = useState(emailList);

  const cancelPhone = () => {
    setIsAddPhoneDialog(false);
    setPhoneNumbers(phoneList);
  };
  const cancelEmail = () => {
    setIsAddEmailDialog(false);
    setEmails(emailList);
  };

  // CHIP DELETION
  const handleSMSDelete = (chipToDelete) => () => {
    if (setIsStorageEdited) {
      setIsStorageEdited(true);
      dispatch({ type: 'SET_EDIT_CONFIRM_NEEDED', value: true });
    }

    setSelectedStorage((tempStorage) => ({
      ...tempStorage,
      alertMessaging: {
        ...selectedStorage.alertMessaging,
        sms: {
          ...selectedStorage.alertMessaging?.sms,
          UIDS: selectedStorage.alertMessaging?.sms?.UIDS.filter((UID) => UID !== chipToDelete)
        }
      }
    }));
  };

  const handleEmailDelete = (chipToDelete) => () => {
    if (setIsStorageEdited) {
      setIsStorageEdited(true);
      dispatch({ type: 'SET_EDIT_CONFIRM_NEEDED', value: true });
    }

    setSelectedStorage((tempStorage) => ({
      ...tempStorage,
      alertMessaging: {
        ...selectedStorage.alertMessaging,
        email: {
          ...selectedStorage.alertMessaging?.email,
          UIDS: selectedStorage.alertMessaging?.email?.UIDS.filter((UID) => UID !== chipToDelete)
        }
      }
    }));
  };

  // ACTIONS IN DIALOG
  const onSavePhones = () => {
    setSelectedStorage((tempStorage) => ({
      ...tempStorage,
      alertMessaging: {
        ...selectedStorage.alertMessaging,
        sms: {
          ...selectedStorage.alertMessaging?.sms,
          UIDS: phoneNumbers.map((nb) => nb.checked === true && nb.UID).filter(Boolean)
        }
      }
    }));
    if (setIsStorageEdited) {
      setIsStorageEdited(true);
      dispatch({ type: 'SET_EDIT_CONFIRM_NEEDED', value: true });
    }
    setIsAddPhoneDialog(false);
  };

  const onSaveEmails = () => {
    setSelectedStorage((tempStorage) => ({
      ...tempStorage,
      alertMessaging: {
        ...selectedStorage.alertMessaging,
        email: {
          ...selectedStorage.alertMessaging?.email,
          UIDS: emails.map((nb) => nb.checked === true && nb.UID).filter(Boolean)
        }
      }
    }));
    if (setIsStorageEdited) {
      setIsStorageEdited(true);
      dispatch({ type: 'SET_EDIT_CONFIRM_NEEDED', value: true });
    }
    setIsAddEmailDialog(false);
  };

  const handlePhoneSelectionChange = (event) => {
    setPhoneNumbers([
      ...phoneNumbers.map((number) =>
        number.UID === event.target.name
          ? { ...number, checked: event.target.checked }
          : { ...number }
      )
    ]);
  };
  const handleEmailSelectionChange = (event) => {
    setEmails([
      ...emails.map((email) =>
        email.UID === event.target.name ? { ...email, checked: event.target.checked } : { ...email }
      )
    ]);
  };

  return (
    <Card className={[classes.cardWrap, classes.smsWrap].join(' ')}>
      {/* <Card className={classes.card} item={selectedStorage.monitor}> */}
      <CardContent>
        <div className={classes.smsCard}>
          <FormControlLabel
            className={classes.onoffLabel}
            control={<Switch checked={smsON} onChange={(e) => smsHandler(e)} name='sms' />}
            label={i18n.sms || 'SMS'}
          />
          <Divider className={classes.divider} orientation='vertical' flexItem />
          {!smsON ? null : (
            <>
              <ul className={classes.smsList}>
                {(selectedPhones.length > 0 &&
                  // {chipData.length > 0 ? (
                  selectedPhones.map((UID) =>
                    phoneList.find((ph) => ph.UID === UID) ? (
                      <li key={UID} style={{ margin: 5 }}>
                        <Tooltip
                          title={phoneList.find((ph) => ph.UID === UID).phoneNumber}
                          placement='bottom'
                          disableFocusListener
                        >
                          <Chip
                            variant='outlined'
                            size='small'
                            icon={<Icon icon={accountCircle} />}
                            disabled={!smsON}
                            label={
                              phoneList.find((ph) => ph.UID === UID).phoneName ||
                              phoneList.find((ph) => ph.UID === UID).phoneNumber
                            }
                            // label={data.phoneName || data.phoneNumber}
                            onDelete={handleSMSDelete(UID)}
                            className={classes.chip}
                          />
                        </Tooltip>
                      </li>
                    ) : null
                  )) || (
                  <li style={{ margin: 5 }}>
                    <Chip
                      variant='outlined'
                      size='small'
                      icon={<MdError style={{ color: COLORS.alert }} />}
                      disabled={!smsON}
                      label={i18n.no_phones_sel || 'No phones selected'}
                      className={classes.chipNoPhone}
                    />
                  </li>
                )}
              </ul>
              <IconButton onClick={() => setIsAddPhoneDialog(true)} disabled={!smsON}>
                <PhoneAddSVG
                  color='rgba(0, 0, 0, 0.54)'
                  style={{
                    width: 20,
                    height: 20
                  }}
                />
              </IconButton>
            </>
          )}
        </div>

        <div className={classes.smsCard}>
          <FormControlLabel
            className={classes.onoffLabel}
            control={<Switch checked={emailON} onChange={(e) => emailHandler(e)} name='email' />}
            label={i18n.email || 'Email'}
          />
          <Divider className={classes.divider} orientation='vertical' flexItem />
          {!emailON ? null : (
            <>
              <ul className={classes.smsList}>
                {(selectedEmails.length > 0 &&
                  // {chipData.length > 0 ? (
                  selectedEmails.map((UID) =>
                    emailList.find((em) => em.UID === UID) ? (
                      <li key={UID} style={{ margin: 5 }}>
                        <Tooltip
                          title={emailList.find((em) => em.UID === UID).emailAddress}
                          placement='bottom'
                          disableFocusListener
                        >
                          <Chip
                            variant='outlined'
                            size='small'
                            icon={<Icon icon={accountCircle} />}
                            disabled={!emailON}
                            label={
                              emailList.find((em) => em.UID === UID).emailName ||
                              emailList.find((em) => em.UID === UID).emailAddress
                            }
                            onDelete={handleEmailDelete(UID)}
                            className={classes.chip}
                          />
                        </Tooltip>
                      </li>
                    ) : null
                  )) || (
                  <li style={{ margin: 5 }}>
                    <Chip
                      variant='outlined'
                      size='small'
                      icon={<MdError style={{ color: COLORS.alert }} />}
                      disabled={!emailON}
                      label={i18n.no_emails_sel || 'No emails selected'}
                      className={classes.chipNoPhone}
                    />
                  </li>
                )}
              </ul>
              <IconButton onClick={() => setIsAddEmailDialog(true)} disabled={!emailON}>
                <Icon
                  icon={emailPlus}
                  color='rgba(0, 0, 0, 0.54)'
                  style={{
                    width: 20,
                    height: 20
                  }}
                />
              </IconButton>
            </>
          )}
        </div>
      </CardContent>
      {isAddPhoneDialog && (
        <AddPhoneDialog
          isAddPhoneDialog={isAddPhoneDialog}
          cancel={cancelPhone}
          onSavePhones={onSavePhones}
          phoneNumbers={phoneNumbers}
          handleSelectionChange={handlePhoneSelectionChange}
          i18n={i18n}
        />
      )}
      {isAddEmailDialog && (
        <AddEmailDialog
          isAddEmailDialog={isAddEmailDialog}
          cancel={cancelEmail}
          onSaveEmails={onSaveEmails}
          emails={emails}
          handleSelectionChange={handleEmailSelectionChange}
          i18n={i18n}
        />
      )}
    </Card>
  );
};

export default StorageMonitorAlerts;
