import { isUndefined, isObject } from 'lodash';

/* eslint-disable class-methods-use-this */
export default class DefaultStorage {
  getItem(key) {
    try {
      return JSON.parse(window.localStorage.getItem(key));
    } catch (error) {
      return window.localStorage.getItem(key);
    }
  }

  setItem(key, val) {
    const value1 = isUndefined(val) ? null : val;
    const value = isObject(value1) ? JSON.stringify(value1) : value1;
    window.localStorage.setItem(key, value);
  }

  removeItem(key) {
    window.localStorage.removeItem(key);
  }

  clear() {
    window.localStorage.clear();
  }

  get length() {
    return window.localStorage.length;
  }
}
/* eslint-enable class-methods-use-this */
