// PUBLIC IMPORTS
import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';

import { isNumber, range, size } from 'lodash';
import {
  VictoryChart,
  VictoryLine,
  VictoryScatter,
  VictoryAxis,
  VictoryTooltip,
  VictoryVoronoiContainer
} from 'victory';
import localeFormat from 'utils/localeFormat';
import { COLORS } from 'utils/colors';
import { useStyles, chartStyles } from './SensorChartType.styled';
import CustomTooltip from './CustomTooltip';
import NoteTooltip from './NoteTooltip';
import NoteScatterPoint from './NoteScatterPoint';
import { getDataForChart } from './helpers';

const initialState = {
  dataValue: [{ x: 0, y: -1000 }],
  data1Value: [{ x: 0, y: -1000 }],
  minValue: -120,
  maxValue: -20,
  customRange: range(-120, -19, 10),
  domain: [0, 1],
  rangeTime: [0],
  rangeLabel: false,
  rangeFormat: '',
  reference: [],
  referenceLabel: false,
  referenceFormat: '',
  tooltipFormat: ''
};

export default function SensorSignalChart({
  min,
  max,
  data0,
  data1,
  logs,
  isSnapShot,
  i18n,
  dateFormat,
  timeFormat
}) {
  const classes = useStyles();
  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (!data0[1]) {
      setState({ domain: [0, 1] });
    }
    if (isNumber(data0[1]?.y)) {
      setState({
        dataValue: data0,
        data1Value: data1,
        ...getDataForChart(min, max, data0, -120, -20, isSnapShot, [])
      });
    }
  }, [min, max, data0, data1, isSnapShot]);

  const getTickFormat = (value) =>
    (state.rangeLabel &&
      format(new Date(value), state.rangeFormat, { locale: localeFormat(i18n.locale) })) ||
    (state.reference.findIndex((time) => time === value) !== -1
      ? format(new Date(value), state.referenceFormat, { locale: localeFormat(i18n.locale) })
      : '');

  return (
    <div
      className={
        isSnapShot ? [classes.mainContainer, classes.overviewBg].join(' ') : classes.mainContainer
      }
      id='chartMainContainer'
    >
      <VictoryChart
        style={chartStyles.victoryChart}
        padding={{ top: 50, bottom: 50, left: 70, right: 50 }}
        width={document.getElementById('chartMainContainer')?.offsetWidth || 0}
        height={document.getElementById('chartMainContainer')?.offsetHeight || 0}
        scale={{ x: 'linear', y: 'linear' }}
        containerComponent={
          <VictoryVoronoiContainer
            labels={() => [state.tooltipFormat, state.minValue, state.maxValue]}
            labelComponent={
              <CustomTooltip
                data={state.dataValue}
                data1={state.data1Value}
                mode={1}
                i18n={i18n}
                dateFormat={dateFormat}
                timeFormat={timeFormat}
              />
            }
            responsive={false}
            voronoiBlacklist={['data1', 'noteScatter']}
          />
        }
      >
        <defs>
          <filter id='shadow'>
            <feDropShadow dx='0' dy='1' stdDeviation='1' floodOpacity='0.4' />
          </filter>
        </defs>
        <VictoryAxis
          domain={state.domain}
          tickValues={state.rangeTime}
          tickFormat={(value) => getTickFormat(value)}
          offsetY={50}
          style={chartStyles.verticalGrid}
        />
        {size(logs) > 0 && (
          <VictoryAxis
            domain={state.domain}
            tickValues={logs.map((log) => log.log_ts)}
            tickFormat={() => null}
            offsetY={50}
            style={chartStyles.verticalGrid2}
          />
        )}
        <VictoryAxis
          domain={state.domain}
          tickValues={state.reference}
          offsetY={50}
          style={chartStyles.rangeStick}
        />
        <VictoryAxis
          dependentAxis
          domain={[state.minValue, state.maxValue]}
          tickValues={state.customRange}
          tickFormat={(value) => `${value} dBm`}
          style={chartStyles.horizontalGrid}
        />
        <VictoryLine
          name='data1'
          data={state.data1Value.filter((data) => data.y !== null)}
          domain={{
            x: state.domain,
            y: [state.minValue, state.maxValue]
          }}
          interpolation='monotoneX'
          style={{ data: { stroke: COLORS.lightBlue, strokeWidth: 2 } }}
        />
        <VictoryScatter
          name='noteScatter'
          data={
            isSnapShot
              ? []
              : logs.map((log) => ({
                  x: log.log_ts,
                  y: state.maxValue,
                  size: 5,
                  label: '',
                  log
                }))
          }
          dataComponent={<NoteScatterPoint />}
          labelComponent={
            <VictoryTooltip orientation='bottom' dy={8} flyoutComponent={<NoteTooltip />} />
          }
          // events={[
          //   {
          //     target: 'data',
          //     eventHandlers: {
          //       onClick: (evt, clickedProps) => {
          //         toggleNoteDialog(true, logs[clickedProps.index]);
          //       }
          //     }
          //   }
          // ]}
        />
        <VictoryLine
          name='data0'
          data={state.dataValue.filter((data) => data.y !== null)}
          domain={{
            x: state.domain,
            y: [state.minValue, state.maxValue]
          }}
          interpolation='monotoneX'
          style={{ data: { stroke: COLORS.chetwodeBlue, strokeWidth: 2 } }}
        />
      </VictoryChart>
    </div>
  );
}
