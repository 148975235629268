import {
  AppBar,
  Button,
  Toolbar,
  Typography,
  IconButton,
  Badge,
  Tabs,
  Tab,
  useMediaQuery,
  RadioGroup,
  Radio,
  FormControlLabel,
  Tooltip
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Route, Link } from 'react-router-dom';
import { findIndex, size } from 'lodash';

// REDUX
import { useSelector, useDispatch } from 'react-redux';

// ICONS
import { MdMenu, MdNotifications } from 'react-icons/md';
import { InlineIcon } from '@iconify/react';
import shieldAccount from '@iconify/icons-mdi/shield-account';
import certificate from '@iconify/icons-mdi/certificate';
import faceAgent from '@iconify/icons-mdi/face-agent';
import schoolIcon from '@iconify/icons-mdi/school';

import { useConfirm } from 'hook/useConfirm';
import { postGroupBSs } from 'api/serverAPI';

// SVG
import QuanturiSVG from 'assets/svg/QuanturiSVG';
import QuanturiMiniSVG from 'assets/svg/QuanturiMiniSVG';
import PeriskopSVG from 'assets/svg/PeriskopSVG';
import PeriskopMiniSVG from 'assets/svg/PeriskopMiniSVG';

// CUSTOM COMPONENTS IMPORT
import BasestationSelect from 'components/general/BasestationSelect';

import { COLORS } from 'utils/colors';
import isDevMode from 'utils/isDevMode';
import DemoTypeSelection from 'components/demo/DemoTypeSelector';
import { useStyles } from './MainNavigator.styled';
import { fetchData } from './helper';

const BasestationSelection = ({
  adminUsername,
  selectedBS,
  baseStations,
  gariBaseStations,
  handleChangeAdminBS
}) => {
  const classes = useStyles();
  const isAdmin = useSelector((store) => store.isAdmin);
  const sessionInfo = useSelector((store) => store.sessionInfo);

  const adminGroup = sessionInfo?.admin_group;

  const [displayedBSID, setDisplayedBSID] = useState(selectedBS);
  const onBsChange = (item) => {
    setDisplayedBSID(item.value);
    handleChangeAdminBS(item);
  };

  const [bsType, setBsType] = React.useState('quanturi');

  const handleChange = (event) => {
    setBsType(event.target.value);
  };

  const devMode = isDevMode();

  return (
    <div
      style={{ backgroundColor: devMode ? COLORS.adminDev : COLORS.adminProd }}
      className={isAdmin ? classes.adminBar : classes.hide}
      id='adminBar'
    >
      <RadioGroup row className={classes.bsRadio} value={bsType} onChange={handleChange}>
        <FormControlLabel
          size='small'
          value='quanturi'
          control={<Radio color='default' size='small' />}
          label='Q'
        />
        <FormControlLabel
          size='small'
          value='gari'
          control={<Radio color='default' size='small' />}
          label='G'
        />
      </RadioGroup>
      <BasestationSelect
        selectedBS={displayedBSID}
        suggestionsBS={baseStations
          .map((item) => ({
            value: item.bs_ids[0],
            label: item.bs_ids[0]
          }))
          .filter((bs) => (adminGroup !== 0 ? bs?.value !== 'DEMOdata' : true))}
        suggestionsGariBS={gariBaseStations?.map((item) => ({
          value: item.BS_ID,
          label: item.BS_ID
        }))}
        bsType={bsType}
        onChange={(item) => onBsChange(item)}
      />
      <Typography
        color='inherit'
        style={{
          margin: 5,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap'
        }}
        className={classes.grow}
      >
        {devMode ? 'DEVELOPMENT' : `PRODUCTION | ${adminUsername}`}
      </Typography>
      <Button className={classes.qualityAnalysis} href='/SAV-quanturi/'>
        <InlineIcon style={{ paddingRight: 5 }} width={20} icon={faceAgent} /> SAV
      </Button>
      <Button className={classes.qualityAnalysis} component={Link} to='/quality'>
        <InlineIcon style={{ paddingRight: 5 }} width={20} icon={certificate} /> Hay Quality
      </Button>
    </div>
  );
};

const Logo = ({ periskop }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isXsWidth = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const bsConf = useSelector((state) => state.bsConf);
  const editConfirmNeeded = useSelector((state) => state.editConfirmNeeded);

  const generalUpdate = () => {
    if (editConfirmNeeded) {
      dispatch({ type: 'SET_UNSAVED_CHANGES', value: true });
    } else {
      console.log('generalUpdate');
      fetchData(bsConf.bs_id, dispatch);
    }
  };

  return (
    <IconButton
      className={classes.qButton}
      style={{
        backgroundColor: periskop ? COLORS.periskopGreen : 'inherit',
        marginLeft: periskop ? -20 : 'inherit',
        paddingLeft: periskop ? 25 : 'inherit',
        paddingRight: periskop ? 20 : 'inherit'
      }}
      color='inherit'
      aria-label='Home'
      component={!editConfirmNeeded ? Link : 'div'}
      onClick={generalUpdate}
      to={!editConfirmNeeded ? '/' : ''}
    >
      {isXsWidth ? (
        periskop ? (
          <PeriskopMiniSVG
            color={COLORS.white}
            style={{
              width: 33,
              height: 33,
              marginTop: 98,
              transform: 'translateY(-48px)'
            }}
          />
        ) : (
          <QuanturiMiniSVG
            color={COLORS.white}
            style={{
              width: 32,
              height: 32,
              marginTop: 6
            }}
          />
        )
      ) : periskop ? (
        <PeriskopSVG
          color={COLORS.white}
          betaColor='rgba(0,0,0,0)'
          style={{
            width: 130,
            height: 130,
            marginTop: 98
          }}
        />
      ) : (
        <QuanturiSVG
          color={COLORS.white}
          betaColor='rgba(0,0,0,0)'
          style={{
            width: 140,
            height: 140,
            marginTop: 115
          }}
        />
      )}
    </IconButton>
  );
};

const Path = () => {
  const classes = useStyles();
  const i18n = useSelector((state) => state.i18n);

  return (
    <Typography variant='h6' color='inherit' className={[classes.grow, classes.barTitle].join(' ')}>
      <div className={classes.barTitleTxt}>
        <Route path='/settings' component={() => i18n.settings ?? 'Settings'} />
        <Route path='/probes' component={() => i18n.probes ?? 'Probes'} />
        <Route path='/account' component={() => i18n.account ?? 'User account'} />
        <Route path='/batches' component={() => i18n.batches ?? 'Batches'} />
        <Route path='/storages' component={() => i18n.storages ?? 'Storages'} />
        <Route path='/quality' component={() => i18n.hay_quality ?? 'Hay Quality'} />
      </div>
    </Typography>
  );
};

const NotifIcon = ({ toggleNotif }) => {
  const classes = useStyles();
  const i18n = useSelector((state) => state.i18n);
  const notificationsCount = useSelector((state) => size(state.notifications));
  return (
    <IconButton color='inherit' aria-label={i18n.notifications} onClick={() => toggleNotif(true)}>
      <Badge className={classes.badge} color='error' badgeContent={notificationsCount}>
        <div />
      </Badge>
      <MdNotifications size='1.3em' />
    </IconButton>
  );
};

const MenuIcon = ({ toggleDrawer }) => {
  const i18n = useSelector((state) => state.i18n);
  return (
    <IconButton color='inherit' aria-label={i18n.menu} onClick={() => toggleDrawer(true)}>
      <MdMenu size='1.5em' />
    </IconButton>
  );
};

const MainAppBar = ({
  adminUsername,
  isMultiBS,
  selectedBS,
  baseStations,
  gariBaseStations,
  handleChangeBS,
  toggleNotif,
  toggleDrawer
}) => {
  const classes = useStyles();
  const confirm = useConfirm();

  const { isAdmin, userInfo, isDemoMode, isSupervisorCompost, isStorageSelected } = useSelector(
    (store) => ({
      isAdmin: store.isAdmin,
      userInfo: store.userInfo,
      isDemoMode: store.isDemoMode,
      isSupervisorCompost: store.isSupervisorCompost,
      isStorageSelected: store.groupDetails
    })
  );

  const [index, setIndex] = useState(0);
  const [tabs, setTabs] = useState([]);

  const [demoSelectorVisible, setDemoSelectorVisible] = useState(false);

  useEffect(() => {
    if (isMultiBS && userInfo) {
      setTabs(userInfo.bs_list);
      if (selectedBS) {
        const selectedIndex = userInfo.bs_list.find((bs) => bs.bsId === selectedBS)
          ? findIndex(userInfo.bs_list, (bs) => bs.bsId === selectedBS)
          : 0;
        setIndex(selectedIndex);
      }
    }
  }, [userInfo, isMultiBS, selectedBS]);

  const onMergeBSs = async () => {
    try {
      // In case there is already a bsGroup, take all BSs of the group to merge.
      const allBSS = userInfo.bs_list.map((b) => b.bsGroup || [b.bsId]).flat();
      await confirm({
        title: 'Hello admin',
        description: `Are you sure you want to merge [${allBSS}]?`
      });
      const response = await postGroupBSs({
        bs_ids: allBSS
      });
      if (response.status_code === 0) {
        window.location.href = '/';
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <AppBar className={classes.appBar}>
      {adminUsername && (
        <BasestationSelection
          adminUsername={adminUsername}
          selectedBS={selectedBS}
          baseStations={baseStations}
          gariBaseStations={gariBaseStations}
          handleChangeAdminBS={handleChangeBS}
        />
      )}
      <Toolbar
        className={`${classes.toolbar} ${isSupervisorCompost ? classes.periskopToolbar : ''}`}
        // style={{ backroundColor: isSupervisorCompost ? '#0E3B43' : '#009966' }}
        id='mainToolbar'
      >
        <Logo periskop={isSupervisorCompost} />
        <Path />
        {(isDemoMode || selectedBS === 'DEMOdata') && demoSelectorVisible && <DemoTypeSelection />}
        {(isDemoMode || selectedBS === 'DEMOdata') && (
          <Button variant='text' onClick={() => setDemoSelectorVisible(!demoSelectorVisible)}>
            <InlineIcon className={classes.demoSelector} width={20} icon={schoolIcon} />
          </Button>
        )}
        <NotifIcon toggleNotif={toggleNotif} />
        <MenuIcon toggleDrawer={toggleDrawer} />
      </Toolbar>

      {isMultiBS && (
        <>
          <Tabs
            value={index}
            classes={{
              root: isSupervisorCompost ? classes.bsTabsRootPeriskop : classes.bsTabsRoot,
              indicator: isSupervisorCompost
                ? classes.multiBarIndicatorPeriskop
                : classes.multiBarIndicator
            }}
            id='bsTabs'
            variant='scrollable'
            scrollButtons='auto'
            // style={{ borderTop: '1px solid #ccc' }}
            onChange={(e, newIndex) => {
              setIndex(newIndex);
              handleChangeBS({ value: tabs[newIndex].bsId });
            }}
          >
            {tabs.map(({ bsId, bsName, bsGroup }) => (
              <Tooltip
                title={
                  bsGroup ? (
                    <span style={{ whiteSpace: 'pre-line' }}>{bsGroup.join('\n')}</span>
                  ) : (
                    ''
                  )
                }
                key={bsId}
                arrow
                placement='bottom'
                disableFocusListener
              >
                <Tab
                  classes={{
                    root: tabs.length >= 10 ? classes.bsTabRootNarrow : classes.bsTabRoot,
                    wrapper: classes.bsTabLabel,
                    selected: isSupervisorCompost
                      ? classes.bsTabSelectedPeriskop
                      : classes.bsTabSelected
                  }}
                  disabled={!!isStorageSelected}
                  key={bsId}
                  label={`${bsName || bsId}${bsGroup ? ` (${bsGroup.length})` : ''}`}
                />
              </Tooltip>
            ))}
          </Tabs>
          {isAdmin && tabs?.length < 5 && (
            <div className={classes.mergeBSButtonDiv}>
              <Button
                onClick={() => onMergeBSs()}
                endIcon={<InlineIcon icon={shieldAccount} />}
                classes={{
                  root: classes.mergeBSButtonRoot,
                  label: classes.mergeBSButtonLabel
                }}
              >
                MERGE BSs
              </Button>
            </div>
          )}
        </>
      )}
    </AppBar>
  );
};

export default MainAppBar;
