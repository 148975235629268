import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import { Icon } from '@iconify/react';
import alertIcon from '@iconify/icons-mdi/alert';
import textBoxCheckOutline from '@iconify/icons-mdi/text-box-check-outline';
// import { getSensorData2 } from 'api/serverAPI';
import { COLORS } from 'utils/colors';
import { SpinnerPrintableReport } from 'utils/Spinners';
// import { BatchDetailsContext } from '../batch-details/BatchDetailsContext';
import { generatePrintableReport, openPrintableReport } from './VerifixPrintableReport';

const useStyles = makeStyles(() => ({
  reportDialog: {
    maxWidth: 400
  },
  spinnerContainer: {
    height: 100,
    width: '100%',
    display: 'flex',
    boxSizing: 'border-box',
    paddingLeft: 'calc(50% - 35px)'
  },
  errorIcon: {
    width: 50,
    height: 50,
    color: COLORS.alert
  },
  okIcon: {
    width: 50,
    height: 50,
    color: COLORS.greenForest
  }
}));

const ReportDataLoader = ({
  loadingReport,
  setLoadingReport,
  timeFormat,
  dateFormat,
  i18n,
  data,
  verification,
  reportDates,
  endDate,
  reportName
}) => {
  const classes = useStyles();
  const degrees = useSelector((store) => store.sessionInfo?.ui_preferences?.degrees);

  const [loadingError, setLoadingError] = useState(null);
  const [dataReady, setDataReady] = useState(false);
  const [cancelled, setCancelled] = useState(false);
  // const loadSensorDataInProgress = useRef(false);

  const cancelReport = () => {
    setCancelled(true);
    setLoadingError(false);
    setLoadingReport(false);
  };

  const [generatedReport, setGeneratedReport] = useState(null);

  const openReport = () => {
    setLoadingReport(false);
    setLoadingError(false);
    openPrintableReport(generatedReport);
  };

  useEffect(() => {
    async function prepareDataForReport() {
      const report = await generatePrintableReport(
        i18n,
        // timeFormat,
        dateFormat,
        // degrees,
        data,
        verification.probeID,
        reportDates,
        endDate,
        verification.verifixID,
        verification.calibration_date
      );
      report.documentName = reportName;
      setGeneratedReport(report);
      setDataReady(true);
    }
    prepareDataForReport();
  }, [
    loadingReport,
    loadingError,
    cancelled,
    dateFormat,
    i18n,
    timeFormat,
    degrees,
    verification,
    reportDates,
    endDate,
    reportName,
    data
  ]);

  return (
    <Dialog open={loadingReport} aria-labelledby='responsive-dialog-title'>
      <DialogTitle>{dataReady ? i18n.printable_report_title : i18n.preparing_report}</DialogTitle>
      <DialogContent className={classes.reportDialog} elevation={0}>
        <div className={classes.spinnerContainer}>
          {loadingError ? (
            <Icon className={classes.errorIcon} icon={alertIcon} />
          ) : dataReady ? (
            <Icon className={classes.okIcon} icon={textBoxCheckOutline} />
          ) : (
            <SpinnerPrintableReport />
          )}
        </div>
        {loadingError ? (
          <Typography color='error'>{loadingError}</Typography>
        ) : (
          <Typography>{i18n.preparing_report_msg}</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => cancelReport()} color='primary'>
          {i18n.cancel}
        </Button>
        <Button
          onClick={() => openReport()}
          disabled={!dataReady}
          color='secondary'
          style={{ color: COLORS.white }}
          variant='contained'
        >
          {i18n.msg_save_pdf}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReportDataLoader;
