import {
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  Divider,
  InputAdornment,
  TextField,
  Button,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { size, isEmpty, intersection } from 'lodash';
// import Button from '@material-ui/core/Button';
import { DebounceInput } from 'react-debounce-input';
import { Icon } from '@iconify/react';
import magnifyIcon from '@iconify/icons-mdi/magnify';
import closeIcon from '@iconify/icons-mdi/close';
import shieldAccount from '@iconify/icons-mdi/shield-account';
import cogIcon from '@iconify/icons-mdi/cog';

import { getMaxPlan } from 'assets/utils';
import { Spinner } from 'utils/Spinners';
import { COLORS } from 'utils/colors';
import { controllerRule } from 'components/storages/monitors/monitor-utils/fcHelpers';
import ProbeActivationDialog from 'navigation/ProbeActivationDialog';
import BasicSensorList from '../../general/basic-sensor-list/BasicSensorList';
import SensorGroupSelect from '../../general/SensorGroupSelect';
import ToggleSensorsDialog from './ToggleSensorsDialog';
import TransferSensorsDialog from './TransferSensorsDialog';
import { useStyles } from '../Settings.styled';
import { splitArrayByCommas } from './helper';

const Sensors = ({ probesView }) => {
  const classes = useStyles();

  const {
    i18n,
    isLoading,
    bsConf,
    auxConf,
    userInfo,
    data,
    batches,
    isSupervisorCompost,
    isAdmin,
    storages,
    showHandleBar,
    isSimExpired,
    isPlanExpired,
    isDemoMode,
    sensorConfigurations
  } = useSelector((store) => ({
    i18n: store.i18n,
    isLoading: store.isLoading,
    bsConf: store.bsConf,
    auxConf: store.auxConf,
    userInfo: store.userInfo,
    data: store.sensorsData,
    batches: store.batches,
    isSupervisorCompost: store.isSupervisorCompost,
    isAdmin: store.isAdmin,
    storages: store.storages,
    showHandleBar: store.sessionInfo?.ui_preferences?.handle_temp_enabled === 'true' || false,
    isSimExpired: store.isSimExpired,
    isPlanExpired: store.isPlanExpired,
    isDemoMode: store.isDemoMode,
    sensorConfigurations: store.sensorConfigurations
  }));
  const history = useHistory();

  const dispatch = useDispatch();

  const [noProbesDialogOpen, setNoProbesDialogOpen] = useState();
  const [isTransferSensorsDialog, setIsTransferSensorsDialog] = useState();
  const [toggleSensorsState, setToggleSensorsState] = useState({
    isToggleSensorsDialog: false,
    isDisabledMode: false
  });
  const [searchString, setSearchString] = useState('');

  const [state, setState] = useState({ enabledSensors: [] });

  const plan = getMaxPlan(userInfo?.plans, userInfo?.service);
  const isFreeUser = (plan.planLevel === 0 || plan.planLevel === 3) && !!plan.expired;

  const [isMultiBS] = useState(bsConf?.bsGroup && !probesView);
  // By default select the last of bsGroup
  const [selectedMultiBS, setSelectedMultiBS] = useState(
    bsConf?.bsGroup ? bsConf.bsGroup[bsConf.bsGroup.length - 1] : bsConf?.bs_id
  );

  useEffect(() => {
    if (isMultiBS) {
      setState({
        enabledSensors: bsConf?.sensor_configurations.filter((sc) => sc.realBs === selectedMultiBS),
        disabledSensors: bsConf?.disabled_sensors.filter((sc) => sc.realBs === selectedMultiBS)
      });
    } else {
      setState({
        enabledSensors: bsConf?.sensor_configurations,
        disabledSensors: bsConf?.disabled_sensors
      });
    }
  }, [bsConf, isMultiBS, probesView, selectedMultiBS]);

  const clearSearch = () => {
    setSearchString('');
  };

  const handleSearch = (event) => {
    const keyword = event.target.value;
    setSearchString(keyword);
  };

  const [newSensorsActivated, setNewSensorsActivated] = useState(false);

  // Handle sensor click
  const handleClick = (id) => {
    // event.preventDefault();
    dispatch({ type: 'RESET_CHARTDATA' });
    dispatch({ type: 'RESET_DATATABLE' });
    history.push({
      pathname: `/probes/${id}`
      // state: {
      //   urlBack: `/settings`
      // }
    });
  };

  const isOtherBSStorage = (sto) => {
    const sensorIDsPlacedInStorage =
      sto?.layoutType === 3
        ? sto?.stacks
            .map((s) => s.sensors)
            .flat()
            .map((s) => s.id)
        : sto?.sensor_layout
        ? sto.sensor_layout?.sensors?.filter((sen) => sen.column >= 0).map((sen) => sen.id) || []
        : [];

    return (
      !isEmpty(sensorIDsPlacedInStorage) &&
      isEmpty(
        intersection(
          sensorIDsPlacedInStorage,
          sensorConfigurations.map((s) => s.sensor_id_sys)
        )
      )
    );
  };

  const fcCheck = (st) => st.monitor?.rules?.find((r) => controllerRule(r));

  const outdoorIDS = storages
    .filter(() => (isDemoMode ? isDemoMode !== 'WASTE' : true)) // in demo, take off sensors if waste demo
    ?.map((st) => fcCheck(st))
    .filter(Boolean)
    .map((r) => r.settings.outdoorID);

  const storagesWithFC = storages
    ?.filter((st) => fcCheck(st))
    .map((st) => ({
      stoID: st.itemID,
      stoName: st.name
    }))
    .map((value, index) => ({
      storage: value.stoName,
      id: value.stoID,
      outdoorID: outdoorIDS[index]
    }));

  // Group selector for showing probes of selected storage or batch
  const [selectedGroup, setSelectedGroup] = useState(null);
  // Displayed sensor can be filtered by group selection
  const [displayedSensors, setDisplayedSensors] = useState(
    (state.enabledSensors || []).concat(auxConf || []) || []
  );
  // Show global alerts in main sensor list
  const [alertSensorIDs, setAlertSensorIDs] = useState(
    !probesView ? [] : data.filter((s) => s?.alert).map((s) => s?.sensor_id)
  );
  const storagesWithSensors = storages
    ?.filter(() => (isDemoMode ? isDemoMode !== 'WASTE' : true)) // in demo, take off sensors if waste demo
    ?.filter((s) => !isOtherBSStorage(s))
    ?.filter(
      (s) =>
        (s.stacks && s.stacks.map((stack) => stack.sensors.length > 0)) || // For Stack storages
        (s.sensor_layout && s.sensor_layout.sensors && s.sensor_layout.sensors.length > 0) // For Flat storages
    );
  // Batches are overviews with only one (last) phase. Thus batch_phases[0] is the last.
  const batchesWithSensors = batches
    .filter(() => (isDemoMode ? isDemoMode === 'WASTE' : true)) // in demo, take off batches if not waste demo
    ?.filter(
      (b) => b.batch_phases[0].sensor_progress && b.batch_phases[0].sensor_progress.length > 0
    );

  useEffect(() => {
    setDisplayedSensors((state.enabledSensors || []).concat(auxConf || []));
    setAlertSensorIDs(!probesView ? [] : data?.filter((s) => s?.alert).map((s) => s?.sensor_id));
  }, [auxConf, bsConf, data, probesView, state.enabledSensors]);

  let sensorIDsPlaced = []; // For showing vacant sensors
  // Is not usual that a user has both storages and batches.
  // Still, storages and batches need to have different values, as their IDs could be same.
  // We use separator s_ or b_ for storage and batch value strings.
  // Type field is used for group list icons.
  let groupOptions = [
    {
      // Value '0' is for all probes option
      value: '0',
      label: `${i18n.all_sensors || 'All probes'} (${size(
        (
          state.enabledSensors
            .map((a) => a.sensor_id_sys)
            .filter((v, i, a) => a.indexOf(v) === i) || []
        ).concat(auxConf || [])
      )})`
    }
  ];
  if (!isFreeUser && probesView && !isPlanExpired && !isSimExpired) {
    // If there are storages or batches, have also vacant probes category
    // with probes that are not placed anywhere
    if ((storages && !isEmpty(storages)) || (batches && !isEmpty(batches))) {
      // Just sum of n sensors in storages
      const sensorsInStorages = storages
        ?.filter(() => (isDemoMode ? isDemoMode !== 'WASTE' : true)) // in demo, take off sensors if not waste demo
        ?.flatMap(
          (s) =>
            (s.stacks && s.stacks.flatMap((st) => st.sensors.map((ls) => ls.id))) ||
            s.sensor_layout?.sensors.map((ls) => ls.id)
        )
        .filter((a) => !!a)
        .concat(outdoorIDS);
      // Sensor can be in many batches, so need to get unique IDs in batches
      const sensorsInBatches = batches
        ?.filter(() => (isDemoMode ? isDemoMode === 'WASTE' : true)) // in demo, take off sensors if waste demo
        ?.flatMap((b) => b.batch_phases[0].sensor_progress.map((sp) => sp.sensor_id));

      sensorIDsPlaced = sensorsInStorages.concat(sensorsInBatches);
      const sensorIDsVacant = (state.enabledSensors || [])
        .concat(auxConf || [])
        .filter((sen) => sensorIDsPlaced.indexOf(sen.sensor_id_sys || sen.a) < 0);
      groupOptions.push({
        // Value '-1' is for vacant probes option
        value: '-1',
        label: `${i18n.vacant_sensors || 'Vacant probes'} (${sensorIDsVacant?.length})`,
        disabled: sensorIDsVacant?.length <= 0,
        last: true
      });
    }
    if (!isPlanExpired) {
      groupOptions = groupOptions
        .concat(
          storagesWithSensors
            // sort alphabetically
            .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
            .map((s, i) => ({
              value: `s_${s.itemID}`,
              label: `${s.name} (${
                (s.stacks
                  ? s.stacks.map((st) => st.sensors.length).reduce((a, b) => a + b)
                  : s.sensor_layout.sensors.length) +
                storagesWithFC?.filter((fs) => fs.id === s.itemID).length
              })`,
              disabled:
                // disable selection of items with no probes
                (s.stacks
                  ? s.stacks.map((st) => st.sensors.length).reduce((a, b) => a + b)
                  : s.sensor_layout.sensors.length) +
                  storagesWithFC?.filter((fs) => fs.id === s.itemID).length ===
                0,
              type: 'sto',
              // field to indicate end of storages
              last: batches && i === storagesWithSensors.length - 1
            }))
        )
        .concat(
          batchesWithSensors
            // sort alphabetically
            .sort((a, b) => (a.batch_name.toLowerCase() < b.batch_name.toLowerCase() ? -1 : 1))
            .map((b) => ({
              value: `b_${b.batch_id}`,
              label: `${b.batch_name} (${b.batch_phases[0].sensor_progress.length})`,
              type: Number(b.status) // Type field to indicate ongoing (1) or completed(2) batch
            }))
        );
    }
  }
  const groupSelectionChange = (sel) => {
    const selected = groupOptions.find((g) => sel.value === g.value);
    if (selected.value === '0') {
      setSelectedGroup(selected); // Reset to all sensors
      setDisplayedSensors((state.enabledSensors || []).concat(auxConf || []));
    } else if (selected.value === '-1') {
      // Show vacant probes
      setDisplayedSensors(
        (state.enabledSensors || [])
          .concat(auxConf || [])
          .filter((sen) => sensorIDsPlaced.indexOf(sen.sensor_id_sys || sen.a) < 0)
      );
    } else {
      setSelectedGroup(selected);
      const valueTypeAndID = selected.value.split('_');
      if (valueTypeAndID[0] === 's') {
        // Storage selected
        const selectedStorage = storages.find((s) => s.itemID === valueTypeAndID[1]);
        const storageSensorIDs = selectedStorage.stacks
          ? selectedStorage.stacks.flatMap((st) => st.sensors.map((ls) => ls.id))
          : selectedStorage.sensor_layout.sensors.map((ls) => ls.id);
        setDisplayedSensors(
          (state.enabledSensors || []).concat(auxConf || [])?.filter(
            (sensor) =>
              storageSensorIDs.indexOf(sensor.sensor_id_sys) >= 0 ||
              // possible auxConf: sensor ID is in a field
              storageSensorIDs.indexOf(sensor.a) >= 0 ||
              // also include possible FC outdoor sensor in the storage
              storagesWithFC
                .filter((fcsto) => fcsto.id === selectedStorage.itemID)
                .map((fc) => fc.outdoorID)
                .indexOf(sensor.sensor_id_sys) >= 0
          )
        );
      } else if (valueTypeAndID[0] === 'b') {
        // Batch selected
        const selectedBatch = batches.find((b) => b.batch_id === valueTypeAndID[1]);
        const batchSensorIDs = selectedBatch.batch_phases[0].sensor_progress.map(
          (sp) => sp.sensor_id
        );
        setDisplayedSensors(
          (state.enabledSensors || []).concat(auxConf || [])?.filter(
            (sensor) =>
              batchSensorIDs.indexOf(sensor.sensor_id_sys) >= 0 ||
              batchSensorIDs.indexOf(sensor.a) >= 0 // possible auxConf: sensor ID is in a field
          )
        );
      }
    }
  };

  const goToSensorSettings = () => {
    const path = '/settings/probes';
    history.push(path);
  };

  if (!displayedSensors) {
    return <Spinner />;
  }
  const changeMultiBS = (event) => {
    const bsID = event.target.value;
    setSelectedMultiBS(bsID);
  };

  const BsSelection = isMultiBS ? (
    <div className={classes.multiBS}>
      <Typography variant='body1' className={classes.multiBSSelect}>
        {`${i18n.sel_trg_system}${i18n.colon}`}
      </Typography>
      <Select
        style={{ margin: '0 10px' }}
        value={selectedMultiBS}
        onChange={(e) => changeMultiBS(e)}
      >
        {bsConf.bsGroup.map((option, i) => (
          <MenuItem key={i} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </div>
  ) : null;

  return (
    <div className={classes.mainContainer}>
      {probesView && displayedSensors.length < 1 ? (
        // No sensors.
        <div className={classes.welcome}>
          <h2>{i18n.probes_dashboard || 'Probes dashboard'}</h2>
          <p>{i18n.no_sensors_welcome || 'Get started by adding probes in the settings view.'}</p>
          <Button
            variant='contained'
            color='primary'
            startIcon={<Icon icon={cogIcon} />}
            onClick={() => goToSensorSettings()}
          >
            {i18n.go_to_sensor_settings || 'Open Settings view'}
          </Button>
        </div>
      ) : (
        <Paper className={isLoading ? classes.hide : classes.settingsView}>
          <List>
            {!probesView && BsSelection}
            {!probesView && (
              <>
                <ListItem style={{ marginBottom: 10 }}>
                  <ListItemIcon>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => setNoProbesDialogOpen(true)}
                      disabled={bsConf?.configType === 'gari'}
                    >
                      {i18n.msg_more_sensors_title || 'Add Sensors'}
                    </Button>
                  </ListItemIcon>
                  {(userInfo?.bs_BS_IDs?.length > 1 || isAdmin) && (
                    <ListItemSecondaryAction>
                      <Button
                        endIcon={isAdmin ? <Icon icon={shieldAccount} /> : null}
                        disabled={
                          bsConf?.configType === 'gari' || (state.enabledSensors || []).length === 0
                        }
                        variant='contained'
                        onClick={() => setIsTransferSensorsDialog(true)}
                      >
                        {i18n.transfer_sensors || 'Transfer Sensors'}
                      </Button>
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
                <Divider />
              </>
            )}
            {/* <ListItem
            button
            onClick={() =>
              setToggleSensorsState({ isToggleSensorsDialog: true, isDisabledMode: false })
            }
          >
            <ListItemText
              primary={`${i18n.enabled_sensors || 'Enabled sensors'} (${size(
                bsConf?.sensor_configurations
              )})`}
              // secondary={state.enabledSensors}
              secondary={ */}
            <div className={classes.settingSensorsHeader}>
              {probesView ? (
                groupOptions.length === 1 ? (
                  <ListItemText
                    style={{ padding: '0 0 10px 20px' }}
                    primary={`${i18n.all_sensors || 'All probes'} (${size(
                      (
                        state.enabledSensors
                          .map((a) => a.sensor_id_sys)
                          .filter((v, i, a) => a.indexOf(v) === i) || []
                      ).concat(auxConf || [])
                    )})`}
                  />
                ) : (
                  <div style={{ padding: '0 0 10px 20px' }}>
                    <SensorGroupSelect
                      groups={groupOptions}
                      selectedGroup={selectedGroup}
                      onChange={groupSelectionChange}
                    />
                  </div>
                )
              ) : (
                <ListItemText
                  style={{ padding: '8px 16px' }}
                  primary={`${i18n.enabled_sensors || 'Enabled sensors'} (${size(
                    (state.enabledSensors || []).concat(auxConf || [])
                  )})`}
                />
              )}
              <DebounceInput
                className={classes.settingSensorFind}
                element={TextField}
                minLength={1}
                debounceTimeout={150}
                onChange={handleSearch}
                value={searchString}
                placeholder={i18n.msg_table_search_tip || 'Find sensor'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start' onClick={() => clearSearch()}>
                      <Icon
                        width='20'
                        height='20'
                        icon={searchString.length === 0 ? magnifyIcon : closeIcon}
                        style={
                          searchString.length === 0
                            ? { color: COLORS.warning }
                            : { cursor: 'pointer' }
                        }
                      />
                    </InputAdornment>
                  )
                }}
                // endAdornment={
                //   <InputAdornment position='end'>
                //     <SearchIcon />
                //   </InputAdornment>
                // }
              />
            </div>
            <div className={classes.settingsSensorList}>
              <BasicSensorList
                sensorIDs={(isMultiBS ? state.enabledSensors : displayedSensors)
                  .filter(
                    (s) =>
                      (s.sensor_id_sys && s.sensor_id_sys.includes(searchString)) ||
                      (s.sensor_id && s.sensor_id.includes(searchString)) ||
                      (s.sensor_name &&
                        s.sensor_name.toUpperCase().includes(searchString.toUpperCase()))
                  )
                  .map((s) => s.sensor_id_sys || s.sensor_id)
                  .filter((v, i, a) => a.indexOf(v) === i)}
                showMultipoint
                dataSnapshot={data}
                bsConf={bsConf}
                auxConf={auxConf}
                selectedSensor={null}
                setSelectedSensor={(id) => handleClick(id)}
                alertSensorIDs={alertSensorIDs}
                showHandleBar={(isAdmin || !isSupervisorCompost) && showHandleBar}
                isPassive={!isAdmin && isPlanExpired}
                // onSensorHover={onSensorHover}
                batches={batches || []}
                storages={storages || []}
                isSettingsView={!probesView}
                newSensorsActivated={newSensorsActivated}
                setNewSensorsActivated={setNewSensorsActivated}
                outdoorIDS={outdoorIDS}
                storagesWithFC={storagesWithFC}
                isExpired={(isSimExpired || isPlanExpired) && !isAdmin}
                isFreeUser={probesView && isFreeUser}
                isPlanExpired={isPlanExpired && !isAdmin}
                marginRight={isEmpty(alertSensorIDs) ? 0 : 17}
              />
            </div>
            {!probesView && <Divider />}
            {!probesView && (
              <ListItem
                button
                onClick={() =>
                  setToggleSensorsState({ isToggleSensorsDialog: true, isDisabledMode: true })
                }
              >
                <ListItemText
                  classes={{ secondary: classes.disabledProbes }}
                  primary={`${i18n.disabled_sensors || 'Disabled sensors'} (${size(
                    state.disabledSensors
                  )})`}
                  secondary={splitArrayByCommas(state.disabledSensors, true)}
                />
              </ListItem>
            )}
          </List>

          {noProbesDialogOpen && (
            <ProbeActivationDialog
              noProbesDialogOpen={noProbesDialogOpen}
              setNoProbesDialogOpen={setNoProbesDialogOpen}
              sensorsView
              selectedMultiBS={isMultiBS && selectedMultiBS}
            />
          )}

          {toggleSensorsState.isToggleSensorsDialog && (
            <ToggleSensorsDialog
              isDisabledMode={toggleSensorsState.isDisabledMode}
              isToggleSensorsDialog={toggleSensorsState.isToggleSensorsDialog}
              setToggleSensorsState={setToggleSensorsState}
              setNewSensorsActivated={setNewSensorsActivated}
              bsConf={bsConf}
              selectedMultiBS={isMultiBS && selectedMultiBS}
              i18n={i18n}
              isDemoMode={isDemoMode}
              isAdmin={isAdmin}
            />
          )}

          {isTransferSensorsDialog && (
            <TransferSensorsDialog
              isTransferSensorsDialog={isTransferSensorsDialog}
              setIsTransferSensorsDialog={setIsTransferSensorsDialog}
              bsConf={bsConf}
              selectedMultiBS={isMultiBS && selectedMultiBS}
              i18n={i18n}
              userInfo={userInfo}
              isAdmin={isAdmin}
            />
          )}
        </Paper>
      )}
    </div>
  );
};

export default Sensors;
