import { Link as LinkUI } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import ReactCountryFlag from 'react-country-flag';
import detectBrowserLanguage from 'detect-browser-language';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { getTranslationsData } from 'redux/reducers/setLanguage';

import ChangeLanguageDialog from '../general/login/ChangeLanguageDialog';

const LanguageSelector = ({ main }) => {
  const i18n = useSelector((store) => store.i18n);
  const supportedLanguages = useSelector((store) => store.supportedLanguages);
  const defaultLang = detectBrowserLanguage().substring(0, 2);
  const storedLang = i18n.locale || Cookies.get('qAppLang') || defaultLang;
  const initialState = React.useMemo(
    () => ({
      locale: storedLang,
      localeLabel: supportedLanguages.find((la) => la.locale === storedLang)?.name,
      localeFlag: storedLang === 'en' ? 'gb' : storedLang,
      isLanguageDialogOpened: false
    }),
    [storedLang, supportedLanguages]
  );
  const [state, setState] = useState(initialState);

  useEffect(() => {
    setState(initialState);
  }, [supportedLanguages, initialState]);

  const dispatch = useDispatch();

  useEffect(() => {
    const getFirstLocale = async () => {
      try {
        const firstLang =
          Cookies.get('qAppLang') !== 'undefined' ? Cookies.get('qAppLang') : defaultLang;
        await dispatch(getTranslationsData(firstLang, null, 'anonymous'));
      } catch (e) {
        console.log('Error', e.message);
      }
    };
    if (!i18n.locale) {
      getFirstLocale();
    }
    return () => {
      // console.log('getFirstLocale Cleanup');
    };
  });

  const toggleLanguageDialog = () => {
    setState((tempState) => ({
      ...tempState,
      isLanguageDialogOpened: !state.isLanguageDialogOpened
    }));
  };

  const handleChangeLanguage = (selectedLanguage) => {
    const { locale, code, name } = selectedLanguage;

    setState((tempState) => ({
      ...tempState,
      locale,
      localeLabel: name,
      localeFlag: code,
      isLanguageDialogOpened: !state.isLanguageDialogOpened
    }));
    console.log('getTranslationsData at: handleChangeLanguage');
    dispatch(getTranslationsData(locale, null, 'anonymous'));
    Cookies.set('qAppLang', locale);
  };

  return (
    <div style={main && { top: 70, marginRight: 30 }}>
      <ReactCountryFlag
        className='emojiFlag'
        countryCode={state.localeFlag}
        style={{ marginRight: '10px', fontSize: '15px', marginTop: '-5px' }}
        svg
        cdnUrl='https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/'
        cdnSuffix='svg'
      />
      <LinkUI
        style={{ cursor: 'pointer' }}
        color='secondary'
        variant='body2'
        onClick={toggleLanguageDialog}
      >
        <span>{state.localeLabel}</span>
      </LinkUI>

      {state.isLanguageDialogOpened && (
        <ChangeLanguageDialog
          i18n={i18n}
          locale={state.locale}
          isLanguageDialogOpened={state.isLanguageDialogOpened}
          toggleLanguageDialog={toggleLanguageDialog}
          handleChangeLanguage={handleChangeLanguage}
          supportedLanguages={supportedLanguages}
        />
      )}
    </div>
  );
};

export default LanguageSelector;
