import React from 'react';
import { format } from 'date-fns';
import { VictoryTooltip, VictoryLabel } from 'victory';
import { useSelector } from 'react-redux';
import localeFormat from 'utils/localeFormat';

import { getGradientColor, tempFormat } from 'assets/utils';
import { COLORS } from 'utils/colors';
import { voltageStyle, signalStyle, humidityStyle } from './SensorChartType.styled';

export default function CustomTooltip(props) {
  const degrees = useSelector((store) => store.sessionInfo?.ui_preferences?.degrees ?? '°C');

  const { text, datum, x, y, data, data1, mode, dateFormat, timeFormat, isSnapShot, i18n } = props; // wrapperWidth

  const defaultFormat = text[0].split(',')[0];
  const max = parseInt(text[0].split(',')[2]);
  const dataX = datum.x;
  let dataY = datum.y;
  let dy = 6;
  let dataY1;
  let y1;

  if (datum && datum.moist) {
    return null;
  }

  if (mode === 1) {
    dataY = data.find((point) => point.x === datum.x)?.y || 0;
    dataY1 = data1.find((point) => point.x === datum.x)?.y || 0;
    y1 = (max - dataY1) * ((y - 50) / (max - dataY)) + 50;
    dy = y - y1 > 0 ? y - y1 + 6 : 6;
  }

  const getTooltipStyle = (selectedMode) => {
    switch (selectedMode) {
      case 1:
        return signalStyle;
      case 2:
        return voltageStyle;
      case 5:
        return humidityStyle;
      case 3:
        return [
          { fill: COLORS.primaryColor, fontFamily: 'Roboto', fontSize: 13 },
          { fill: COLORS.mediumGray, fontFamily: 'Roboto', fontSize: 11 },
          {
            fill: getGradientColor(dataY),
            fontFamily: 'Roboto',
            fontSize: 15,
            fontWeight: 'bold'
          },
          { fill: COLORS.lightGray3, fontFamily: 'Roboto', fontSize: 11 }
        ];
      case 4:
        return [
          { fill: COLORS.primaryColor, fontFamily: 'Roboto', fontSize: 13 },
          { fill: getGradientColor(dataY), fontFamily: 'Roboto', fontSize: 15, fontWeight: 'bold' }
        ];
      default:
        return [
          { fill: COLORS.primaryColor, fontFamily: 'inherit', fontSize: 12 },
          {
            fill: getGradientColor(dataY),
            fontFamily: 'inherit',
            fontSize: 16,
            fontWeight: 'bold'
          }
        ];
    }
  };

  const getTooltipText = (selectedMode) => {
    switch (selectedMode) {
      case 1:
        return [
          format(
            dataX,
            isSnapShot
              ? defaultFormat || dateFormat
              : defaultFormat || `${dateFormat} ${timeFormat}` || defaultFormat
          ),
          `${dataY} dBm`,
          `${dataY1} dBm (bs)`
        ];
      case 2:
        return [
          format(
            dataX,
            isSnapShot
              ? defaultFormat || dateFormat
              : defaultFormat || `${dateFormat} ${timeFormat}` || defaultFormat
          ),
          `${(dataY / 100).toFixed(2)} V`
        ];
      case 5:
        return [
          format(
            dataX,
            isSnapShot
              ? defaultFormat || dateFormat
              : defaultFormat || `${dateFormat} ${timeFormat}` || defaultFormat
          ),
          `${dataY} %`
        ];
      case 3:
        return [format(dataX, dateFormat || defaultFormat)];
      default:
        return [
          format(
            dataX,
            isSnapShot
              ? defaultFormat || dateFormat
              : defaultFormat || `${dateFormat} ${timeFormat}`,
            { locale: localeFormat(i18n.locale) }
          ),
          `${tempFormat(degrees, dataY)}${degrees}`
        ];
    }
  };

  return (
    <g>
      {mode === 0 && <circle cx={x} cy={y} r='6' stroke='none' fill={getGradientColor(dataY)} />}
      {mode === 3 && <circle cx={x} cy={y} r='3' stroke='none' fill={getGradientColor(dataY)} />}
      {mode === 4 && <circle cx={x} cy={y} r='2' stroke='none' fill='rgb(102, 102, 102)' />}
      {mode === 1 && (
        <>
          <circle cx={x} cy={y1} r='4' stroke='none' fill={COLORS.lightBlue} />
          <circle cx={x} cy={y} r='4' stroke='none' fill={COLORS.chetwodeBlue} />
        </>
      )}
      {mode === 2 && <circle cx={x} cy={y} r='4' stroke='none' fill={COLORS.orange} />}
      {mode === 5 && <circle cx={x} cy={y} r='4' stroke='none' fill={COLORS.mediumPurple} />}
      <VictoryTooltip
        {...props}
        cornerRadius={4}
        pointerLength={5}
        labelComponent={<VictoryLabel lineHeight={1.6} />}
        flyoutStyle={{
          fill: COLORS.white,
          filter: 'url(#shadow)',
          stroke: 'rgba(0,0,0,0)'
        }}
        text={getTooltipText(mode)}
        dy={mode === 1 ? -dy : 0}
        style={getTooltipStyle(mode)}
      />
    </g>
  );
}
