import { SET_STORAGES } from 'redux/constants';
import initialState from 'redux/store/initialState';

export default function setStorages(state = initialState.storages, action) {
  switch (action.type) {
    case SET_STORAGES: {
      return action.value;
    }
    default: {
      return state;
    }
  }
}
