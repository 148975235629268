import React from 'react';
import {
  SurveyRadio,
  SurveySlider,
  SurveyToggleButton
} from 'components/account/account-users/account-background/helpers';

const SurveyHay = ({ i18n, state, setState }) => {
  const Activity = ({ id }) => (
    <SurveyRadio i18n={i18n} info={state[id]} id={id} setState={setState} />
  );

  const Type = ({ id }) => (
    <SurveyToggleButton i18n={i18n} id={id} info={state[id]} setState={setState} />
  );

  const Cuts = ({ id }) => (
    <div style={{ maxWidth: 400 }}>
      <SurveySlider i18n={i18n} id={id} info={state[id]} setState={setState} />
    </div>
  );

  return (
    <div>
      <Activity id='activityType' />
      <Type id='hayType' />
      <Cuts id='yearlyCuts' />
    </div>
  );
};

export default SurveyHay;
