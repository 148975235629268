import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  Typography,
  Chip,
  useMediaQuery
} from '@material-ui/core';
import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { DebounceInput } from 'react-debounce-input';
import { Icon } from '@iconify/react';
import numeric2Circle from '@iconify/icons-mdi/numeric-2-circle';
import SettingsIcon from '@material-ui/icons/Settings';
import CheckIcon from '@material-ui/icons/Check';
import * as yup from 'yup';
import queryString from 'query-string';
import { isEmpty } from 'lodash';
import { useLocation } from 'react-router-dom';

import { postActivateUsersSet, getBsConf } from 'api/serverAPI';
import { COLORS } from 'utils/colors';

import { Spinner } from 'utils/Spinners';
import DialogTitleWithClose from 'utils/DialogWithCloseIcon';

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: '100%',
    height: 'inherit',
    [theme.breakpoints.up('sm')]: {
      maxHeight: 500,
      maxWidth: 700,
      height: 'auto'
    }
  },
  form: {
    height: 'calc(100% - 64px)',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto'
  },
  dialogContent: {
    height: 'inherit',
    paddingTop: 0
  },
  introtext: {
    margin: '0px 0 20px'
  },
  text: {
    margin: '15px 0 '
  },
  boxes: {
    [theme.breakpoints.up('sm')]: {
      padding: '0 100px'
    }
  },
  box: {
    border: '1px solid black',
    padding: 10,
    display: 'flex',
    justifyContent: 'space-between'
  },
  codeDiv: {
    minWidth: 120,
    marginRight: 10
  },
  activedBox: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      textAlign: 'center',
      alignItems: 'center'
    }
  },
  activateBtn: {
    color: COLORS.white,
    marginLeft: 10
  },
  textLater: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    fontSize: 13
  },
  iconLater: {
    color: 'gray',
    marginRight: 5
  },
  title: {
    color: COLORS.dimGray,
    fontSize: 12,
    marginBottom: 3
  },
  code_2: {
    fontWeight: 'bolder',
    fontSize: 20,
    whiteSpace: 'nowrap',
    minWidth: 120
  },
  probesDiv: {
    width: 300,
    [theme.breakpoints.down('xs')]: {
      width: 'inherit',
      margin: '10px 0'
    }
  },
  probes: {
    fontSize: 13,
    marginRight: 10
  },
  activated: {
    margin: 'auto 0'
  }
}));

const ProbeActivationDialog = ({
  noProbesDialogOpen,
  setNoProbesDialogOpen,
  selectedMultiBS,
  sensorsView
}) => {
  const { code2 } = queryString.parse(useLocation().search);
  const dispatch = useDispatch();
  const classes = useStyles();
  const isXsWidth = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const { i18n, bsConf, userInfo } = useSelector((store) => ({
    i18n: store.i18n,
    bsConf: store.bsConf,
    userInfo: store.userInfo
  }));
  const [activatedPacks, setActivatedPacks] = useState([]);

  const onClose = async () => {
    if (!isEmpty(activatedPacks)) {
      const responseBSConf = await getBsConf({ basestationID: bsConf.bs_id });
      dispatch({ type: 'SET_BSCONF', value: responseBSConf.conf_data });
      dispatch({
        type: 'SET_SENSORCONF',
        value: responseBSConf.conf_data.sensor_configurations
      });
      dispatch({ type: 'SET_SOCKET_UPDATES', value: true });
    }
    setNoProbesDialogOpen(false);
  };

  const schema = yup.object().shape({
    resCode: yup.string().required(i18n.act_code_req)
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    setError
  } = useForm({
    defaultValues: { resCode: code2 },
    resolver: yupResolver(schema)
  });

  const onSubmit = async (values) => {
    try {
      const res = await postActivateUsersSet({
        BS_ID: selectedMultiBS || bsConf.bs_id,
        UID: userInfo.U_ID,
        set_id: values.resCode
      });
      switch (Number(res.status_code)) {
        case 0: {
          setActivatedPacks((tempState) => [
            ...tempState,
            {
              code2: values.resCode,
              probes: res.probes.map((p) => p.sensor_id_sys)
            }
          ]);
          setValue('resCode', '');
          break;
        }
        case 2: {
          setError('resCode', {
            type: 'notMatch',
            message: i18n.code_err
          });
          break;
        }
        case 3: {
          setError('resCode', {
            type: 'notMatch',
            message: i18n.probe_set_already_active
          });
          break;
        }
        default: {
          setError('resCode', {
            type: 'notMatch',
            message: i18n.server_err
          });
          break;
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  if (isEmpty(i18n)) {
    return <Spinner />;
  }

  return (
    <Dialog
      open={noProbesDialogOpen}
      maxWidth='md'
      fullScreen={isXsWidth}
      classes={{ paper: classes.dialog }}
    >
      <DialogTitleWithClose onClose={onClose}>
        <div>{i18n.probe_activation_title}</div>
      </DialogTitleWithClose>
      <form onSubmit={handleSubmit(onSubmit)} noValidate className={classes.form}>
        <DialogContent elevation={0} className={classes.dialogContent}>
          <Typography className={classes.introtext}>{i18n.probe_activatation_text}</Typography>
          <div className={classes.boxes}>
            {!isEmpty(activatedPacks) &&
              activatedPacks.map((pack, i) => (
                <div key={i} className={[classes.box, classes.activedBox].join(' ')}>
                  <div className={classes.codeDiv}>
                    <div className={classes.title}>{i18n.activation_code}</div>
                    <div className={classes.code_2}>{pack.code2}</div>
                  </div>
                  <div className={classes.probesDiv}>
                    <div className={classes.title}>{i18n.probe_ids}</div>
                    <div className={classes.probes}>{pack.probes.join(', ')}</div>
                  </div>
                  <div className={classes.activated}>
                    <Chip
                      icon={<CheckIcon />}
                      style={{
                        color: COLORS.greenJade,
                        borderColor: COLORS.greenJade
                      }}
                      label={i18n.activated}
                      color='secondary'
                      variant='outlined'
                    />
                  </div>
                </div>
              ))}
            <div className={classes.box}>
              <Controller
                control={control}
                name='resCode'
                render={({ field }) => (
                  <DebounceInput
                    {...field}
                    element={TextField}
                    minLength={2}
                    debounceTimeout={300}
                    error={Boolean(errors.resCode)}
                    helperText={errors.resCode?.message}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <Icon width='25' height='25' icon={numeric2Circle} />
                        </InputAdornment>
                      )
                    }}
                  />
                )}
              />
              <div>
                <Button
                  type='submit'
                  color='secondary'
                  variant='contained'
                  className={classes.activateBtn}
                >
                  {i18n.activate}
                </Button>
              </div>
            </div>
            <div className={classes.text}>
              {!sensorsView && (
                <div className={classes.textLater}>
                  <SettingsIcon width={60} height={60} className={classes.iconLater} />
                  {i18n.probe_activatation_text2}
                </div>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color='primary'>
            {i18n.close}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ProbeActivationDialog;
