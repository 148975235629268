import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'utils/colors';

export const useStyles = makeStyles((theme) => ({
  hide: {
    display: 'none'
  },
  bsMainContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1
  },
  bsList: {
    height: '100%',
    width: '100%',
    maxWidth: 600,
    marginBottom: 10,
    // overflowY: 'auto',
    // overflowX: 'hidden',
    boxShadow: 'none',
    [theme.breakpoints.down('xs')]: {
      marginBottom: 0
    }
  },
  gridContainer: {
    margin: '20px 0',
    border: '1px solid rgba(0, 0, 0, 0.12)'
  },
  addBSContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between'
  },
  multiBS: {
    backgroundColor: COLORS.chartArea,
    marginBottom: -10,
    marginTop: 15,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1px 0px 2px'
  },
  multiBSSelect: {
    fontSize: 16,
    marginTop: 5,
    marginLeft: 10
  },
  mobileInfo: {
    cursor: 'pointer',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 14,
    marginLeft: '1em',
    marginTop: -10,
    marginBottom: '1em',
    border: `1px solid ${COLORS.lightGray2}`,
    width: 'fit-content'
  }
}));
