import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';

import { makeStyles } from '@material-ui/core/styles';
import { Line, defaults } from 'react-chartjs-2';
import localeFormat from 'utils/localeFormat';
import 'chartjs-adapter-date-fns';

import { generateTimeline } from 'assets/utils';

const useStyles = makeStyles(() => ({
  mainContainer: {
    height: 55,
    marginTop: 10,
    marginLeft: 50,
    marginRight: 50,
    borderRadius: 4
  }
}));

defaults.defaultFontFamily = 'Roboto';

const enMonths = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export default function SensorControlChart({ data }) {
  const classes = useStyles();
  const [gradient, setGradient] = useState('transparent');
  const i18n = useSelector((store) => store.i18n);

  useEffect(() => {
    if (data) {
      setGradient(generateTimeline(data));
    }
  }, [data]);

  return (
    <div className={classes.mainContainer}>
      <Line
        data={{
          datasets: [
            {
              data,
              pointborderColor: 'rgba(0,0,0,0)',
              pointBackgroundColor: 'rgba(0,0,0,0)',
              fill: false,
              pointHoverRadius: 0,
              borderColor: 'rgba(0,0,0,0)',
              spanGaps: false
            }
          ]
        }}
        options={{
          legend: { display: false },
          responsive: true,
          maintainAspectRatio: false,
          animation: false,
          tooltips: {
            enabled: false
          },
          scales: {
            x: {
              type: 'time',
              time: {
                unit: 'month',
                stepSize: 1
                // Firefox does understand this, but we get only EN months:
                // displayFormats: {
                //   month: 'MMM'
                //   // month: i18n.locale === 'en' ? 'MMM' : 'MM/YY'
                //   // locale: i18n.locale
                // }
              },
              ticks: {
                callback(value) {
                  // Value is e.g. "Jan 2021"
                  // let date = new Date(value); // Firefox says !date._isValid
                  // Firefox does not accept Date("Feb 2021").
                  // Use ISO format Date("2021-02-01")
                  const mmyy = value.split(' ');
                  const date = `${mmyy[1]}-${`0${enMonths.indexOf(mmyy[0]) + 1}`.slice(-2)}-01`;
                  // Capitalize 1st letter
                  const str = format(new Date(date), 'MMM', {
                    locale: localeFormat(i18n.locale)
                  }); // moment(`${value}`, 'MMM'); // .format('MMM');
                  return str.charAt(0).toUpperCase() + str.slice(1);
                }
              }
            },
            y: {
              display: false
            }
          }
        }}
      />
      <div
        style={{
          width: '100%',
          height: 28,
          borderRadius: '4px',
          position: 'relative',
          top: -55,
          zIndex: 1,
          backgroundImage: `linear-gradient${gradient}`
        }}
      />
    </div>
  );
}
