import { Drawer, Toolbar, IconButton } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Icon } from '@iconify/react';
import googleTranslate from '@iconify/icons-mdi/google-translate';
import bookOpenVariant from '@iconify/icons-mdi/book-open-variant';
import shieldAccount from '@iconify/icons-mdi/shield-account';
import warehouseIcon from '@iconify/icons-mdi/warehouse';
import nail from '@iconify/icons-mdi/nail';

// ICONS
import {
  MdSettings,
  MdAccountBox,
  MdLandscape
  // MdHelp
} from 'react-icons/md';

import { COLORS } from 'utils/colors';
import { useStyles } from './MainNavigator.styled';

const Item = ({ to, faded, children }) => {
  const currentView = useSelector((state) => state.currentView);
  const editConfirmNeeded = useSelector((state) => state.editConfirmNeeded);
  const dispatch = useDispatch();

  return (
    <IconButton
      component={!editConfirmNeeded ? Link : 'div'}
      onClick={() => editConfirmNeeded && dispatch({ type: 'SET_UNSAVED_CHANGES', value: true })}
      to={!editConfirmNeeded ? `/${to}` : ''}
      style={
        faded
          ? {
              color: currentView === to ? COLORS.secondaryColor : COLORS.silverSonic,
              marginTop: 10,
              opacity: 0.4
            }
          : {
              color: currentView === to ? COLORS.secondaryColor : COLORS.silverSonic,
              marginTop: 10
            }
      }
    >
      {children}
    </IconButton>
  );
};

const DrawerRight = ({ isMultiBS, openManual }) => {
  const classes = useStyles();
  const isAdmin = useSelector((state) => state.isAdmin);
  const isSupervisor = useSelector((state) => state.isSupervisor);
  const isSupervisorCompost = useSelector((state) => state.isSupervisorCompost);
  const insightService = useSelector((state) => state.insightService);
  const isPremium = useSelector((state) => state.isPremium);
  const isGariUser = useSelector((state) => state.isGariUser);
  const userInfo = useSelector((state) => state.userInfo);
  const expiredPlan = userInfo?.plans && userInfo?.plans[0]?.expired;
  const storageAuth =
    !isGariUser &&
    (insightService || isSupervisor || (isPremium && !expiredPlan)) &&
    !isSupervisorCompost;

  const paperStyle =
    isAdmin && isMultiBS
      ? classes.miniDrawerPaperWithDoubleTopBar
      : isAdmin || isMultiBS
      ? classes.miniDrawerPaperWithTopBar
      : classes.miniDrawerPaper;

  return (
    <Drawer
      anchor='right'
      variant='permanent'
      classes={{
        paper: paperStyle
      }}
    >
      <div
        id='rightDrawer'
        className={isAdmin || isMultiBS ? classes.miniDrawerListWithTopBar : classes.miniDrawerList}
      >
        <Toolbar className={classes.closeContainer} />
        {userInfo?.translator && (
          <Item to='translations'>
            <Icon width={40} height={40} icon={googleTranslate} />
          </Item>
        )}
        {isAdmin && (
          <Item to='admin'>
            <Icon width={40} height={40} icon={shieldAccount} />
          </Item>
        )}
        {isSupervisorCompost && !isGariUser && (
          <Item to='batches'>
            <MdLandscape size={40} />
          </Item>
        )}
        {storageAuth && (
          <Item to='storages'>
            <Icon width={40} height={40} icon={warehouseIcon} />
          </Item>
        )}
        <Item to='probes'>
          <Icon width={40} height={40} icon={nail} />
        </Item>
        <Item to='settings'>
          <MdSettings size={40} />
        </Item>
        {!isGariUser && (
          <Item to='account'>
            <MdAccountBox size={40} />
          </Item>
        )}
        <div className={classes.grow} />

        <IconButton onClick={openManual} style={{ color: COLORS.silverSonic, marginBottom: 10 }}>
          <Icon width={40} height={35} icon={bookOpenVariant} />
        </IconButton>
      </div>
    </Drawer>
  );
};

export default DrawerRight;
