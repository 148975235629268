// PUBLIC IMPORTS
import React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';

class PeriskopMiniSVG extends React.Component {
  render() {
    return (
      <div id={this.props.id} style={this.props.style}>
        <svg
          id='Calque_1'
          data-name='Calque 1'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 417.85 417.85'
        >
          <path
            style={{ fill: '#fff' }}
            d='M271.85,4H146C67.7,4,4,67.7,4,146V271.85c0,78.3,63.7,142,142,142H271.85c78.3,0,142-63.7,142-142V146C413.85,67.7,350.15,4,271.85,4ZM377.33,271.85A105.49,105.49,0,0,1,271.85,377.33H193.19V323.11H232a62.49,62.49,0,0,0,33.71-9.27,65.62,65.62,0,0,0,23.45-25.28q8.56-16,8.57-35.95,0-20.24-8.43-36.24a64.29,64.29,0,0,0-23.45-25.28A63.19,63.19,0,0,0,232,181.82h-6.33A105.47,105.47,0,0,0,120.16,287.29v86.84A105.52,105.52,0,0,1,40.52,271.85V146A105.49,105.49,0,0,1,146,40.52H271.85A105.49,105.49,0,0,1,377.33,146ZM191.51,252.33a39.34,39.34,0,0,1,4.35-18.82,33.48,33.48,0,0,1,12.08-12.93,32.73,32.73,0,0,1,17.56-4.77,33.1,33.1,0,0,1,18,4.77,34,34,0,0,1,11.94,12.93,39.34,39.34,0,0,1,4.35,18.82,40.22,40.22,0,0,1-4.35,19,33.64,33.64,0,0,1-11.94,13.06,32.47,32.47,0,0,1-17.7,4.77,34.64,34.64,0,0,1-18.12-4.77,30.69,30.69,0,0,1-12.08-13.06A42.55,42.55,0,0,1,191.51,252.33Z'
          />
        </svg>
      </div>
    );
  }
}

const styles = (theme) => ({
  hide: {
    display: 'none'
  }
});

export default withRouter(withWidth()(withStyles(styles)(PeriskopMiniSVG)));
