import { Dialog, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { DialogActionUtil } from './DialogActions';

import { dialogStyle } from './sharedStyles';

export const EditDialog = ({
  open,
  close,
  value,
  setValue,
  onChange,
  onSave,
  text,
  deleteButton,
  handleDelete,
  textAction,
  type,
  rows,
  required,
  SpecialEditField
}) => {
  const classes = dialogStyle();
  const [touched, setTouched] = useState(!!SpecialEditField ?? false);

  return (
    <Dialog
      open={open}
      onClose={() => close(false)}
      fullWidth
      maxWidth={type ? 'sm' : 'md'}
      aria-labelledby='edit-dialog'
      aria-describedby='alert-dialog-edit'
    >
      <div className={classes.Dialog}>
        {SpecialEditField ?? (
          <TextField
            className={classes.TextField}
            type={type ? 'text' : 'textarea'}
            multiline={!type}
            maxRows={(!type && rows) || '3'}
            label={text}
            value={value || ''}
            onBlur={(e) => onChange(e)}
            onChange={(e) => [setValue(e.target.value), setTouched(true)]}
          />
        )}
        <DialogActionUtil
          onCancel={close}
          onSave={onSave}
          text={textAction}
          disabled={required ? value === '' || !touched : !touched}
          deleteButton={deleteButton}
          handleDelete={handleDelete}
        />
      </div>
    </Dialog>
  );
};
