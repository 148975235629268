import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { useSelector, useDispatch } from 'react-redux';
import { Icon } from '@iconify/react';
import pencilIcon from '@iconify/icons-mdi/pencil';
import { isEmpty } from 'lodash';
import { editableStyle } from 'utils/sharedStyles';
import { EditDialog } from 'utils/EditDialog';
import StoragePropertiesHay from './StoragePropertiesHay';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  summaryLine: {
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  detailsContent: {
    // display: 'flex',
    display: 'block',
    padding: 30,
    flexWrap: 'nowrap',
    overflow: 'auto',
    position: 'relative'
  },
  slider: {
    paddingTop: 50,
    paddingBottom: 10,
    width: '100%'
  }
}));

export function NewlineText(props) {
  const { text, className } = props;
  const splitted = text.split('\n');
  // replace all but last line with <p>
  const newText = splitted.map((str, index) => (
    <p key={index} className={index < splitted.length - 1 ? className : null}>
      {str}
    </p>
  ));
  return newText;
}

const StorageProperties = ({ selectedStorage, setSelectedStorage, setIsStorageEdited }) => {
  const dispatch = useDispatch();

  const classesEditable = editableStyle();
  const classes = useStyles();
  const i18n = useSelector((store) => store.i18n);
  const surveyAnswers = useSelector((store) => store.surveyAnswers);

  // NAME
  const [nameEditDialog, setNameEditDialog] = useState(false);
  const [editName, setEditName] = useState(selectedStorage.name);
  const onSaveName = () => {
    setSelectedStorage((tempStorage) => ({
      ...tempStorage,
      name: editName
    }));
    setNameEditDialog(false);
    setIsStorageEdited(true);
    dispatch({ type: 'SET_EDIT_CONFIRM_NEEDED', value: true });
  };

  const nameHandler = (e) => {
    setEditName(e.target.value);
  };

  const NameEditDialog = (
    <EditDialog
      i18n={i18n}
      open={nameEditDialog}
      close={() => setNameEditDialog(false)}
      value={editName}
      setValue={setEditName}
      onChange={nameHandler}
      onSave={onSaveName}
      text={i18n.name || 'Name'}
      textAction={i18n.apply}
      required
      type
    />
  );

  return (
    <div className={classes.detailsContent}>
      <table>
        <tbody>
          <tr>
            <td className={classesEditable.rowTitle}>{i18n.name || 'Name'}</td>
            <td className={classesEditable.rowText}>
              <div
                className={`${classesEditable.editable} ${
                  !selectedStorage.name && classesEditable.editablePlaceholder
                }`}
                style={{ maxWidth: 300 }}
                onClick={() => setNameEditDialog(true)}
                onKeyDown={() => setNameEditDialog(true)}
                role='presentation'
              >
                {selectedStorage.name || i18n.add_name || 'Add name...'}
                <Icon className={classesEditable.editPen} icon={pencilIcon} />
              </div>
              {NameEditDialog}
            </td>
          </tr>
          {!isEmpty(surveyAnswers) && (
            <StoragePropertiesHay
              i18n={i18n}
              dispatch={dispatch}
              classes={classes}
              classesEditable={classesEditable}
              selectedStorage={selectedStorage}
              setSelectedStorage={setSelectedStorage}
              setIsStorageEdited={setIsStorageEdited}
              surveyAnswers={surveyAnswers}
            />
          )}
        </tbody>
      </table>
    </div>
  );
};

export default StorageProperties;
