import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'utils/colors';

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
    flexGrow: 1
  },
  donutContainer: {
    width: 200,
    height: 200,
    display: 'block',
    margin: 'auto',
    marginBottom: 40
  },
  sensorTemperature: {
    position: 'relative',
    width: '100%',
    height: 40,
    top: -130,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    color: COLORS.primaryColor,
    flexGrow: 1,
    fontSize: 30,
    fontWeight: 'bold',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
  },
  sensorUpdated: {
    position: 'relative',
    width: '100%',
    height: 40,
    top: -135,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    color: '#888888',
    flexGrow: 1,
    fontSize: 13,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
  },
  dialogContent: {
    paddingTop: 10,
    minWidth: 300,
    [theme.breakpoints.down('xs')]: {
      minWidth: 0
    }
  },
  dialogContentNew: {
    paddingTop: 10,
    [theme.breakpoints.down('xs')]: {
      minWidth: 0
    }
  },
  grow: {
    flexGrow: 1
  }
}));
