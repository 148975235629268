import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SensorValidity from '../../../admin/SensorValidity';

const ProbeLifetimeContainer = () => {
  const location = useLocation();
  const probePath = location.pathname.split('/');
  const probeID = probePath[probePath.length - 1];
  const BS_ID = useSelector((state) => state.bsConf.bs_id);

  return <SensorValidity BS_ID={BS_ID} probeID={probeID} />;
};

export default ProbeLifetimeContainer;
