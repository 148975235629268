import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'utils/colors';

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  sensorToolbar: {
    backgroundColor: COLORS.primaryColor,
    color: COLORS.white,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    zIndex: 1250,
    height: '48px'
  },
  periskopSensorToolbar: {
    backgroundColor: COLORS.periskopBlue
  },
  sensorTitle: {
    flexGrow: 1,
    height: 35,
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '50%'
  },
  sensorName: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  sensorTitleSmallID: {
    margin: '0px 0 -5px',
    fontSize: 11
  },
  chevronLeft: {
    width: '20%',
    margin: 0,
    textAlign: 'right',
    justifyContent: 'center',
    alignItems: 'center'
  },
  chevronRight: {
    width: '30%',
    margin: 0,
    textAlign: 'left',
    justifyContent: 'center',
    alignItems: 'center'
  },
  close: {
    margin: 0,
    padding: 0,
    position: 'absolute',
    right: 10
  },
  sensorTabs: {
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
    flexGrow: 1,
    width: 'auto',
    height: 'calc(100vh - 64px - 8px - 32px)'
  },
  sensorTabsDialog: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexGrow: 1,
    width: 'auto',
    height: window.innerHeight - 48 - 64,
    // maxWidth: 900,
    maxHeight: 700,
    [theme.breakpoints.down('lg')]: {
      // maxWidth: 800,
      maxHeight: 800
    },
    [theme.breakpoints.down('md')]: {
      // maxWidth: 700,
      maxHeight: 700
    },
    [theme.breakpoints.down('sm')]: {
      // maxWidth: 600,
      maxHeight: 600
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: '100%'
    }
  },
  toolbarToggleButton: {
    background: COLORS.white
  }
}));
