import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  InputBase,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup
} from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { compact, isEmpty, sortBy } from 'lodash';
import { DebounceInput } from 'react-debounce-input';

import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';

import { postToggleSensors, getBsConf } from 'api/serverAPI';
import { convertArrayToObject, convertObjectToArray } from './helper';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minHeight: '50vh',
    maxHeight: '50vh'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200
  }
}));

const ToggleSensorsDialog = ({
  isDisabledMode,
  isToggleSensorsDialog,
  setToggleSensorsState,
  bsConf,
  selectedMultiBS,
  setNewSensorsActivated,
  i18n,
  isDemoMode,
  isAdmin
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [state, setState] = useState({
    searchKeyword: '',
    allSensors: selectedMultiBS
      ? sortBy(
          [
            ...bsConf?.sensor_configurations.filter((sc) => sc.realBs === selectedMultiBS),
            ...(bsConf.disabled_sensors?.filter((sc) => sc.realBs === selectedMultiBS) ?? [])
          ],
          'sensor_id_sys'
        )
      : sortBy(
          [...bsConf?.sensor_configurations, ...(bsConf.disabled_sensors ?? [])],
          'sensor_id_sys'
        ),
    selectedSensors: isDisabledMode
      ? convertArrayToObject(bsConf.disabled_sensors)
      : convertArrayToObject(bsConf?.sensor_configurations)
  });

  const handleSearch = (event) => {
    const keyword = event.target.value;
    const allSensors = selectedMultiBS
      ? sortBy(
          [
            ...bsConf?.sensor_configurations.filter((sc) => sc.realBs === selectedMultiBS),
            ...(bsConf.disabled_sensors?.filter((sc) => sc.realBs === selectedMultiBS) ?? [])
          ],
          'sensor_id_sys'
        )
      : sortBy(
          [...bsConf?.sensor_configurations, ...(bsConf.disabled_sensors ?? [])],
          'sensor_id_sys'
        );
    setState((tempState) => ({
      ...tempState,
      searchKeyword: keyword,
      allSensors: isEmpty(keyword)
        ? allSensors
        : compact(
            allSensors.map((sensor) => {
              if (sensor.sensor_id_sys.includes(keyword)) {
                return sensor;
              }
              return null;
            })
          )
    }));
  };

  const handleChangeSensor = (event) => {
    setState((tempState) => ({
      ...tempState,
      selectedSensors: {
        ...tempState.selectedSensors,
        [event.target.name]: event.target.checked
      }
    }));
  };

  const onSave = async () => {
    if (isDemoMode && !isAdmin) {
      const allSensors = [...bsConf.sensor_configurations, ...(bsConf.disabled_sensors || [])];

      const enabledSensors = [];
      const disabledSensors = [];

      allSensors.forEach((sensor) => {
        if (convertObjectToArray(state.selectedSensors).includes(sensor.sensor_id_sys)) {
          delete sensor.storage_location;
          disabledSensors.push(sensor);
        } else {
          enabledSensors.push(sensor);
        }
      });

      const newBsConf = {
        ...bsConf,
        sensor_configurations: enabledSensors,
        disabled_sensors: disabledSensors
      };

      dispatch({ type: 'SET_BSCONF', value: newBsConf });
    } else {
      try {
        await postToggleSensors({
          bs_id: selectedMultiBS || bsConf.bs_id,
          selected_sensor_ids: convertObjectToArray(state.selectedSensors),
          is_disabled: isDisabledMode
        });
        const responseBSConf = await getBsConf({ basestationID: bsConf.bs_id });
        dispatch({ type: 'SET_BSCONF', value: responseBSConf.conf_data });
        dispatch({
          type: 'SET_SENSORCONF',
          value: responseBSConf.conf_data.sensor_configurations
        });
      } catch (e) {
        console.log(e);
      }
    }
    setToggleSensorsState((tempState) => ({ ...tempState, isToggleSensorsDialog: false }));
    setNewSensorsActivated(true);
  };

  return (
    <Dialog
      open={isToggleSensorsDialog}
      aria-labelledby='responsive-dialog-title'
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle>
        <div>{isDisabledMode ? i18n.disabled_sensors : i18n.enabled_sensors}</div>
      </DialogTitle>
      <DialogContent elevation={0}>
        <div>
          <FormControl className={classes.formControl}>
            <DebounceInput
              element={InputBase}
              minLength={2}
              debounceTimeout={300}
              onChange={handleSearch}
              value={state.searchKeyword}
              placeholder={i18n.msg_table_search_tip || 'Find sensor'}
              endAdornment={
                <InputAdornment position='end'>
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
        <div>
          <FormControl component='fieldset' className={classes.formControl}>
            <FormGroup>
              {state.allSensors.map((sensor) => (
                <FormControlLabel
                  key={sensor.sensor_id_sys}
                  control={
                    <Checkbox
                      checked={!!state.selectedSensors[sensor.sensor_id_sys]}
                      onChange={handleChangeSensor}
                      name={sensor.sensor_id_sys}
                    />
                  }
                  label={sensor.sensor_id_sys}
                />
              ))}
            </FormGroup>
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() =>
            setToggleSensorsState((tempState) => ({ ...tempState, isToggleSensorsDialog: false }))
          }
          color='primary'
        >
          {i18n.cancel}
        </Button>
        <Button onClick={onSave} color='secondary'>
          {i18n.apply || 'Apply'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ToggleSensorsDialog;
