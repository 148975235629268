import { Tooltip, Toolbar } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';

import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { InlineIcon } from '@iconify/react';
import eyeIcon from '@iconify/icons-mdi/eye';
import viewModule from '@iconify/icons-mdi/view-module';
import { componentToolbarStyle } from 'utils/sharedStyles';

export default function StoragesToolbar() {
  const { url } = useRouteMatch();
  const history = useHistory();
  const lastURL = url.split('/')[url.split('/').length - 1];

  const i18n = useSelector((state) => state.i18n);
  const classes = componentToolbarStyle();

  return (
    <Toolbar className={classes.componentToolbar}>
      <ToggleButtonGroup
        className={classes.toolbarToggleButton}
        size='small'
        value={lastURL || 'storages'}
        color='primary'
        exclusive
      >
        <Tooltip title={i18n.storages || 'Storages'} placement='bottom' disableFocusListener>
          <ToggleButton
            value='storages'
            selected={lastURL === 'storages'}
            onClick={() => history.replace('/storages')}
          >
            <InlineIcon icon={viewModule} width={24} height={24} />
          </ToggleButton>
        </Tooltip>

        <Tooltip title={i18n.monitors || 'Monitors'} placement='bottom' disableFocusListener>
          <ToggleButton
            value='monitors'
            selected={lastURL === 'monitors'}
            onClick={() => history.replace('/storages/monitors')}
          >
            <InlineIcon icon={eyeIcon} width={24} height={24} />
          </ToggleButton>
        </Tooltip>
      </ToggleButtonGroup>
    </Toolbar>
  );
}
