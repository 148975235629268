import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'utils/colors';

export const useStyles = makeStyles({
  editPen: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
});

const CustomSVGPoint = (props) => {
  const classes = useStyles();
  return (
    <svg
      {...props}
      className={classes.editPen}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      height='16'
      width='16'
    >
      <path
        d='M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z'
        fill={COLORS.secondaryColor}
      />
      <path d='M0 0h24v24H0z' fill='none' />
    </svg>
  );
};

const NoteScatterPoint = (props) => {
  const { x, y, events } = props;
  return <CustomSVGPoint {...events} x={x} y={y - 18} />;
};

export default NoteScatterPoint;
