import { Button, Checkbox, FormControlLabel, FormGroup, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { addDays, format } from 'date-fns';
import { postInsightMigration, getInsightTrialTime } from 'api/serverAPI';
import GenericAppBar from 'components/general/GenericAppBar';
import insightPicture from 'assets/images/greengrass.jpg';
import { getDateFromStr } from 'utils/getDateFromStr';
import { i18nVariablesInjection } from 'utils/sharedHelper';
import { COLORS } from 'utils/colors';
import { insightStyles } from './insightStyles';

const InsightWelcome = ({
  i18n,
  plan,
  oldHayUser,
  setOldHayUser,
  setInsightUser,
  history,
  newUser,
  setNewUser
}) => {
  const classes = insightStyles();
  const [trialDays, setTrialDays] = useState(1);
  useEffect(() => {
    const getTrialDays = async () => {
      const insightTrialDays = await getInsightTrialTime();
      setTrialDays(insightTrialDays.trialDays);
    };
    if (oldHayUser) {
      getTrialDays();
    }
  }, [oldHayUser]);

  // const dateFormat = useSelector(
  //   (store) => store.sessionInfo?.ui_preferences?.date_format || 'd/MM/yyyy'
  // );

  // const userInfo = useSelector((store) => store.userInfo);
  let oldPremium = false;
  let activePremium = false;
  let trialEndDate;
  if (oldHayUser) {
    const oldPlan = plan;
    const oldPlanExp = oldPlan.dueDate && getDateFromStr(oldPlan.dueDate);
    trialEndDate = addDays(new Date(), trialDays);

    oldPremium = oldPlan.planLevel === 3 && !oldPlan.expired;
    activePremium = oldPremium && oldPlanExp >= new Date();
    if (activePremium) {
      if (oldPlanExp < trialEndDate) {
        trialEndDate = new Date(+trialEndDate + (+trialEndDate - +oldPlanExp) / 1000);
      } else {
        trialEndDate = oldPlanExp;
      }
    }
  }

  const [termChecked, setTermChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const termAcceptedHandler = () => {
    setErrorMessage(null);
    setTermChecked((prevState) => !prevState);
  };

  const acceptedMigrationHandler = async () => {
    if (!oldHayUser && newUser) {
      setNewUser(false);
    } else if (!termChecked) {
      setErrorMessage(i18n.must_accept_gtc || 'You must accept the terms and conditions');
    } else {
      try {
        await postInsightMigration();
        setOldHayUser(false);
        setInsightUser(true);
        // In case someone refreshes the page, user won't need to accept terms again
        history.replace({
          ...history.location,
          state: { plan: null, service: { plan: 2, trial: 1 } }
        });
      } catch (e) {
        console.log('postInsightMigration ERROR');
      }
    }
  };

  return (
    <>
      <GenericAppBar title={i18n.insight ?? 'INSIGHT'} noLanguageSelector />
      <div className={classes.welcomeRoot} style={{ backgroundImage: `url(${insightPicture})` }}>
        {/* <div>
          <img width='100%' alt='Insight bales' src={insightPicture} />
        </div> */}

        <div className={classes.welcomeContent}>
          <div style={{ display: 'flex' }}>
            <div>
              <h2>{i18n.insight_welcome_title}</h2>
              <div className={classes.div}>
                {i18nVariablesInjection(
                  i18n.insight_welcome_msg_1,
                  null,
                  `color: ${COLORS.secondaryColor}; font-weight: 600`
                )}
              </div>
              <div className={classes.div}>
                {i18nVariablesInjection(
                  i18n.insight_welcome_msg_2,
                  null,
                  `color: ${COLORS.secondaryColor}; font-weight: 600`
                )}
              </div>
              <div className={classes.div}>
                {i18n.insight_welcome_msg_3}
                <ul className={classes.list}>
                  <li>{i18n.insight_welcome_list_1}</li>
                  <li>{i18n.insight_welcome_list_2}</li>
                  <li>{i18n.insight_welcome_list_3}</li>
                  <li>{i18n.insight_welcome_list_4}</li>
                </ul>
              </div>
              {!newUser && (
                <>
                  <div className={classes.trial}>
                    {activePremium
                      ? i18n.insight_welcome_msg_premium
                      : i18nVariablesInjection(i18n.insight_welcome_msg_freemium, {
                          trialDays
                        })}
                    {activePremium && (
                      <span style={{ fontWeight: 'bold' }}>
                        {format(trialEndDate, 'd.MM.yyyy')}
                      </span>
                    )}
                  </div>
                  {!oldPremium && (
                    <div className={classes.noCommitment}>{i18n.no_commitment_needed}</div>
                  )}
                  <FormGroup row style={{ width: 'fit-content' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          required
                          checked={termChecked}
                          color='primary'
                          onClick={termAcceptedHandler}
                        />
                      }
                      required
                      label={
                        <div>
                          {i18n.accept_gtcs_1}
                          <a
                            rel='noopener noreferrer'
                            style={{ color: 'black' }}
                            href='https://quanturi.com/pages/terms-of-use'
                            target='_blank'
                          >
                            {i18n.accept_gtcs_2}
                          </a>
                          .
                        </div>
                      }
                    />
                  </FormGroup>
                  <Typography className={classes.errorMessage} color='error'>
                    {errorMessage && errorMessage}
                  </Typography>
                </>
              )}
              <div style={{ margin: '30px 0', textAlign: 'center' }}>
                <Button
                  // className={classes.button}
                  variant='contained'
                  onClick={acceptedMigrationHandler}
                  color='secondary'
                  style={{ color: 'white', minWidth: 100 }}
                >
                  {i18n.next}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InsightWelcome;
