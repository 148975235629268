import { Fab } from '@material-ui/core';
import { makeStyles, withStyles, createTheme } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import LoginBackground from 'assets/images/LoginBackground.png';
import { getGradientColor } from 'assets/utils';
import { COLORS } from 'utils/colors';

export const StyledFab = withStyles({
  root: {
    position: 'absolute',
    bottom: 20,
    right: 30
  }
})(Fab);

export const QSlider = withStyles({
  root: {
    color: (props) => getGradientColor(props.value, 1),
    height: 8,
    width: '90%',
    display: 'inline-block'
  },
  valueLabel: {
    color: (props) => getGradientColor(props.value, 1)
  }
})(Slider);

export const normal = createTheme({
  palette: {
    primary: {
      main: COLORS.primaryColor
    }
  },
  typography: {
    fontFamily: "'Roboto', sans-serif",
    useNextVariants: true
  }
});

export const green = createTheme({
  palette: {
    primary: {
      main: COLORS.greenJade
    }
  },
  typography: {
    fontFamily: "'Roboto', sans-serif",
    useNextVariants: true
  }
});

export const error = createTheme({
  palette: {
    primary: {
      main: COLORS.alert
    }
  },
  typography: {
    fontFamily: "'Roboto', sans-serif",
    useNextVariants: true
  }
});

export const mainComponentStyle = makeStyles(
  (theme) => ({
    hide: {
      display: 'none'
    },
    mainWrapper: {
      width: '100%',
      height: '100%',
      overflow: 'hidden'
    },
    mainContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      overflowY: 'auto',
      overflow: 'hidden',
      margin: 'auto',
      padding: '20px 0',
      boxSizing: 'border-box'
    },
    toolbar: {
      boxShadow: '0px 0px 10px rgba(0,0,0,0.5)',
      zIndex: 100
    },
    modeButtonsContainer: {
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center'
    },
    modeButtonsContent: {
      width: 150,
      [theme.breakpoints.down('xs')]: {
        width: 100
      }
    },
    noLink: {
      color: 'inherit',
      textDecoration: 'inherit'
    }
  }),
  { index: 1 }
);

export const listStyle = makeStyles(
  (theme) => ({
    noLink: {
      color: 'inherit',
      textDecoration: 'inherit'
    },
    listMain: {
      width: '100%',
      margin: 'auto',
      overflowY: 'auto',
      height: 'calc(100% - 56px)', // 56 is the toolbar height.. Should not be like this
      padding: '20px 0 0 10px',
      boxSizing: 'border-box',
      backgroundColor: COLORS.whiteSmoke
    },
    list: {
      display: 'inline-flex',
      flexFlow: 'wrap',
      alignItems: 'start',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center'
      }
    },
    welcome: {
      textAlign: 'center',
      marginTop: 50
    }
  }),
  { index: 1 }
);

export const listItemStyle = makeStyles(
  () => ({
    noLink: {
      color: 'inherit',
      textDecoration: 'inherit'
    },
    hide: {
      display: 'none'
    },
    Item: {
      width: 'max-content',
      padding: 0,
      margin: 8
    },
    ListItem: {
      minWidth: 250,
      minHeight: 80,
      maxWidth: 250,
      backgroundColor: COLORS.lightGray2,
      padding: 5,
      borderRadius: 3,
      overflow: 'hidden',
      zIndex: 0,
      cursor: 'pointer',
      border: '2px solid transparent',
      transition: 'background-color 0.2s ease-out, opacity 0.2s ease-out',
      boxShadow:
        '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
      '&:hover': {
        backgroundColor: COLORS.secondaryColor,
        opacity: 1,
        color: COLORS.white
      }
    },
    ListItemDisabled: {
      minWidth: 250,
      minHeight: 80,
      maxWidth: 250,
      backgroundColor: COLORS.lightGray2,
      opacity: 0.5,
      filter: 'grayscale(0.5)',
      padding: 5,
      borderRadius: 3,
      overflow: 'hidden',
      zIndex: 0,
      cursor: 'default',
      border: '2px solid transparent',
      boxShadow:
        '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
    },
    alerted: {
      borderColor: COLORS.alert
    },
    lowAlerted: {
      borderColor: COLORS.chetwodeBlue
    },
    initialItem: {
      backgroundColor: COLORS.secondaryColor,
      opacity: 1,
      cursor: 'default'
    },
    itemTitle: {
      fontSize: 18,
      fontWeight: 600,
      marginBottom: 5
    },
    itemContent: {
      color: COLORS.black,
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100% - 27px)'
    },
    itemContentSection: {
      backgroundColor: COLORS.white,
      marginTop: 1,
      padding: '5px 12px 5px 8px'
    },
    onlyMonitorSection: {
      borderRadius: '5px 5px 0 0'
    },
    storageBatch: {
      borderRadius: '50% 50% 0 0',
      textAlign: 'center',
      justifyContent: 'center',
      height: 25,
      lineHeight: '32px'
    },
    stretchSection: {
      marginTop: 0,
      flexGrow: 1,
      backgroundColor: COLORS.white
    },
    phaseStatusDate: {
      display: 'flex',
      height: 18
    },
    phaseName: {
      color: COLORS.primaryColor,
      maxWidth: '80%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontSize: '15px'
    },
    phaseStatus: {
      color: COLORS.darkGray,
      display: 'inline-block',
      verticalAlign: 'top',
      fontSize: '15px',
      whiteSpace: 'nowrap'
    },
    phaseDate: {
      fontSize: '11px',
      lineHeight: '20px',
      marginLeft: 2
    },
    phaseStatusIcon: {
      color: COLORS.dimGray,
      marginRight: '2px',
      width: 16,
      height: 18
    },

    phaseInfo: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    archived: {
      filter: 'grayscale(0.7)'
    },
    alert: {
      backgroundColor: COLORS.alert,
      width: 30,
      height: 30,
      borderRadius: 40,
      position: 'absolute',
      top: -10,
      left: -10,
      textAlign: 'center',
      paddingTop: 4,
      boxSizing: 'border-box',
      boxShadow: '0px 0px 2px',
      zIndex: 100
    },
    lowAlert: {
      backgroundColor: COLORS.chetwodeBlue,
      width: 25,
      height: 25,
      borderRadius: 40,
      position: 'absolute',
      top: -7,
      left: -7,
      textAlign: 'center',
      paddingTop: 4,
      boxSizing: 'border-box',
      boxShadow: '0px 0px 2px',
      zIndex: 99
    },
    alertIcon: {
      color: COLORS.white,
      width: 20,
      height: 20
    },
    lowAlertIcon: {
      color: COLORS.white,
      width: 18,
      height: 18,
      marginTop: -1
    },
    noMonitors: {
      fontSize: '15px',
      justifyContent: 'center',
      margin: 'auto',
      color: COLORS.warning
    },
    empty: {
      backgroundColor: COLORS.whiteSmoke,
      display: 'flex'
    },
    storageChartPanel: {
      minWidth: 90,
      minHeight: 50,
      verticalAlign: 'top',
      backgroundColor: COLORS.white,
      justifyContent: 'left',
      padding: '5px 5px 0',
      position: 'relative'
    },
    noSensorsIcon: {
      width: 40,
      height: 40,
      color: COLORS.secondaryColor,
      marginTop: -8
    },
    noSensors: {
      marginBottom: -3, // fine tune handle placement
      height: 60
    },
    sensorCount: {
      paddingLeft: 50,
      paddingTop: 15,
      fontSize: 18,
      fontWeight: 600
    },
    fanIcon: {
      width: 21,
      height: 21,
      color: COLORS.lightGray3
    }
  }),
  { index: 1 }
);

export const listItemStatusBarStyle = makeStyles(
  () => ({
    statusArea: {
      marginTop: 1,
      display: 'flex',
      alignItems: 'stretch',
      minHeight: 63
    },
    hide: {
      display: 'none'
    },
    tempsPanel: {
      minWidth: 80,
      verticalAlign: 'top',
      backgroundColor: COLORS.white,
      justifyContent: 'left',
      padding: '8px 8px 0',
      position: 'relative'
    },
    tempsPanelRule: {
      minWidth: 70,
      padding: '0 !important',
      backgroundColor: 'inherit'
    },
    tempsCard: {
      display: 'flex',
      alignItems: 'center',
      minWidth: 100
    },
    sensorCount: {
      paddingLeft: '50px',
      paddingTop: '15px'
    },
    probeCountPanel: {
      background: COLORS.white,
      marginLeft: -5,
      padding: 0,
      paddingTop: 5,
      zIndex: 100
    },
    probeCountIcon: {
      width: 28,
      height: 28,
      color: COLORS.warning
    },
    probeCount: {
      marginBottom: 0,
      color: COLORS.primaryColor,
      fontSize: 11,
      height: 20,
      width: 20,
      margin: 'auto',
      lineHeight: '20px',
      textAlign: 'center'
    },
    statusIconsPanel: {
      backgroundColor: COLORS.white,
      marginLeft: '1px',
      width: '100%',
      display: 'flex',
      justifyContent: 'center'
    },
    statusFcPanel: {
      textAlign: 'center',
      justifyContent: 'space-between',
      backgroundColor: COLORS.fcBackground
    },
    cyclicFcPanel: {
      display: 'flex',
      width: '100%',
      alignItems: 'center'
    },
    statusItem: {
      margin: '15px 8px 0',
      height: 20
    },
    initialStatusIcon: {
      color: COLORS.secondaryColor,
      width: 40,
      height: 40,
      marginTop: 10
    },
    okStatusIcon: {
      color: COLORS.greenForest,
      width: 40,
      height: 40,
      marginTop: 10
    },
    okWrapper: {
      height: 40
    },
    alertWrapper: {
      display: 'flex',
      justifyContent: 'center',
      height: 40
    },
    alertStatusIcon: {
      color: COLORS.alert,
      width: 28,
      height: 28
    },
    severityRed: {
      color: COLORS.alert,
      width: 28,
      height: 28
    },
    severityOrange: {
      color: 'orange',
      width: 28,
      height: 28
    },
    severityGray: {
      color: COLORS.lightGray3,
      width: 28,
      height: 28
    },
    statusText: {
      fontSize: 18,
      fontWeight: 600,
      color: COLORS.darkGray,
      display: 'inline-block',
      lineHeight: '22px',
      height: 28,
      verticalAlign: 'bottom',
      marginLeft: 5
    },
    tempIndicator: {
      backgroundColor: COLORS.warning,
      marginBottom: 5,
      padding: '1px 2px 0',
      color: COLORS.white,
      borderRadius: 3,
      textAlign: 'center',
      justifyContent: 'space-between',
      display: 'flex'
    },
    tempIndicatorRule: {
      borderRadius: 30,
      marginBottom: 0
    },
    tempIconMax: {
      verticalAlign: 'top',
      minWidth: '20%',
      height: 19,
      marginLeft: '5px'
    },
    tempIconMin: {
      verticalAlign: 'bottom',
      minWidth: '20%',
      height: 19,
      marginLeft: '5px'
    },
    deltaTemp: {
      fontSize: 14,
      marginTop: 1,
      marginRight: -5
    },
    tempFigure: {
      minWidth: '75%',
      textAlign: 'center',
      fontSize: '13px',
      lineHeight: '19px'
    },
    monitorStyle: {
      fontSize: 11,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: 125,
      margin: 'auto',
      marginBottom: 0,
      marginTop: 5
    },
    statusBar: {
      backgroundColor: COLORS.whiteSmoke,
      width: '100%'
    },
    noMonitorStatusBar: {
      fontSize: '15px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: COLORS.warning
    },
    alertsCount: {
      fontWeight: 'bold',
      padding: 5,
      fontSize: 20
    },
    targetIndicator: {
      width: '100%',
      margin: '5px 0',
      display: 'grid',
      fontSize: 13,
      lineHeight: '20px',
      gridTemplateColumns: 'max-content auto max-content'
    },
    targetBarWrapper: {
      display: 'flex'
    },
    targetBarWrapperDisabled: {
      display: 'flex',
      opacity: 0.5,
      filter: 'grayscale(1)'
    },
    targetBar: {
      width: 110,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: COLORS.lightGray3,
      position: 'relative',
      background: COLORS.white,
      borderRadius: 25,
      height: 17,
      textAlign: 'center',
      // color: COLORS.lightGray3,
      margin: '8px 5px',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      boxSizing: 'border-box'
    },
    targetBarText: {
      // position: 'absolute',
      // width: '100%',
      zIndex: 3,
      padding: 10,
      fontSize: 12
    },
    targetProgress: {
      position: 'absolute',
      zIndex: 1,
      left: 0,
      height: '100%',
      transition: 'all 0.5s ease-out',
      backgroundColor: COLORS.validStatus
    },
    targetProgressDiff: {
      left: 0,
      zIndex: 2,
      opacity: 0.7,
      position: 'absolute',
      height: '100%',
      background: COLORS.lightGray2,
      borderRight: '1px solid white',
      transition: 'all 0.5s ease-out'
    },
    targetText: {
      position: 'relative',
      textShadow: '2px 1px 4px rgba(0,0,0,0.5)',
      lineHeight: '15px',
      fontSize: 20
    },
    tempLimit: {
      minWidth: 40
    },
    timeLimit: {
      minWidth: 30,
      textAlign: 'right'
    },
    timeCountFull: {
      position: 'absolute',
      top: 0,
      zIndex: 100,
      color: COLORS.white,
      fontSize: '11px'
    },
    timeCountShort: {
      position: 'absolute',
      top: 0,
      zIndex: 100,
      color: COLORS.darkGray,
      fontSize: '11px'
    },
    tempException: {
      color: COLORS.alert,
      minWidth: 20,
      marginLeft: 5,
      textAlign: 'right',
      fontSize: '20px'
    }
  }),
  { index: 1 }
);

export const listItemTitleStyle = makeStyles(
  () => ({
    hide: {
      display: 'none'
    },
    itemTitle: {
      marginBottom: 5,
      display: 'flex',
      justifyContent: 'space-between'
    },
    isInitial: {
      color: COLORS.white
    },
    itemText: {
      fontSize: 18,
      maxWidth: '85%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    },
    storageTitle: {
      textAlign: 'center',
      maxWidth: '85%',
      margin: 'auto'
    },
    itemStatus: {
      fontSize: 10,
      lineHeight: '18px',
      opacity: 0.5
    },
    admin: {
      fontSize: 10,
      fontWeight: 600,
      verticalAlign: 'super',
      color: COLORS.mediumPurple
    }
  }),
  { index: 1 }
);

export const targetProgressIndicatorStyle = makeStyles(
  () => ({
    hide: {
      display: 'none'
    },
    targetIndicator: {
      width: '100%',
      margin: '5px 0',
      display: 'grid',
      fontSize: 13,
      lineHeight: '20px',
      gridTemplateColumns: 'max-content auto max-content'
    },
    targetBar: {
      position: 'relative',
      background: COLORS.whiteSmoke, // former efefef
      borderRadius: 25,
      height: 20,
      textAlign: 'center',
      color: COLORS.white,
      margin: '0 0 3px 8px',
      overflow: 'hidden'
    },
    targetProgress: {
      position: 'absolute',
      left: 0,
      height: '100%',
      transition: 'all 0.5s ease-out',
      backgroundColor: COLORS.lightGray2
    },
    targetProgressDiff: {
      position: 'absolute',
      right: 0,
      height: '100%',
      background: 'rgba(255, 255, 255, 0.5)',
      transition: 'all 0.5s ease-out'
    },
    targetText: {
      position: 'relative',
      textShadow: '2px 1px 4px rgba(0,0,0,0.5)',
      lineHeight: '15px',
      fontSize: 20
    },
    tempLimit: {
      minWidth: 40
    },
    timeLimit: {
      minWidth: 30,
      textAlign: 'right',
      marginLeft: 5
    },
    continuousIcon: {
      color: COLORS.lightGray3,
      marginLeft: 2,
      marginRight: -2,
      marginBottom: -2,
      width: 15,
      height: 15
    },
    timeCountFull: {
      position: 'absolute',
      top: 0,
      zIndex: 100,
      color: COLORS.white,
      fontSize: '11px'
    },
    timeCountShort: {
      position: 'absolute',
      top: 0,
      zIndex: 100,
      color: COLORS.darkGray,
      fontSize: '11px'
    },
    tempException: {
      color: COLORS.alert,
      minWidth: 20,
      marginLeft: 5,
      textAlign: 'right',
      fontSize: '20px'
    },
    severityRed: {
      color: COLORS.alert
    },
    severityGray: {
      color: COLORS.lightGray3
    }
  }),
  { index: 1 }
);

export const selectedToolbarStyle = makeStyles(
  (theme) => ({
    toolbar: {
      boxShadow: '0px 0px 10px rgba(0,0,0,0.5)',
      zIndex: 100,
      display: 'flex',
      width: '100%',
      flexFlow: 'wrap',
      justifyContent: 'space-between',
      boxSizing: 'border-box',
      minHeight: 56,
      paddingRight: 10,
      paddingLeft: 10,
      [theme.breakpoints.down('xs')]: {
        padding: 10
      }
    },
    deleteInToolbar: {
      [theme.breakpoints.only('xs')]: {
        '& > button': {
          padding: 0
        }
      }
    },
    selectedName: {
      fontSize: 18,
      maxWidth: '50%',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      cursor: 'default',
      [theme.breakpoints.only('xs')]: {
        fontSize: 14
      }
    },
    hidden: {
      display: 'none'
    },
    admin: {
      fontSize: 10,
      fontWeight: 600,
      verticalAlign: 'super',
      color: COLORS.mediumPurple
    }
  }),
  { index: 1 }
);

export const componentToolbarStyle = makeStyles(
  (theme) => ({
    noLink: {
      color: 'inherit',
      textDecoration: 'inherit'
    },
    componentToolbar: {
      boxShadow: '0px 0px 10px rgba(0,0,0,0.5)',
      zIndex: 100,
      display: 'inline-flex',
      width: '100%',
      flexFlow: 'wrap',
      justifyContent: 'space-between',
      boxSizing: 'border-box',
      backgroundColor: COLORS.white,
      minHeight: 56
    },
    toolbarToggleButton: {
      maxHeight: 35,
      marginBottom: -5
    },
    modeButtonsContainer: {
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center'
    },
    modeButtonsContent: {
      width: 150,
      [theme.breakpoints.down('xs')]: {
        width: 100
      }
    },
    iconSpacing: {
      marginRight: 4,
      marginBottom: 3,
      [theme.breakpoints.down('sm')]: {
        marginRight: 0
      }
    },
    statusButtonText: {
      marginRight: '4px',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
        marginRight: 0
      }
    },
    sortButton: {
      opacity: 0
    }
  }),
  { index: 1 }
);

export const templateMonitorStyle = makeStyles(
  (theme) => ({
    root: {
      overflow: 'auto',
      padding: 20,
      [theme.breakpoints.down('sm')]: {
        padding: 10
      },
      height: 'calc(100% - 56px)', // 56 is the toolbar height.. Should not be like this
      boxSizing: 'border-box'
    },
    mainContainer: {
      textAlign: 'center',
      margin: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    div: {
      overflow: 'auto'
    },
    cardWrap: {
      width: '100%',
      minWidth: 200,
      maxWidth: 500,
      marginBottom: 15,
      '&:hover': {
        boxShadow: '1px 1px 5px rgb(0 0 0 / 30%)',
        cursor: 'pointer'
      }
    },
    ruleIconWrapper: {
      borderRadius: 50,
      backgroundColor: COLORS.lightGray3,
      margin: '8px 10px 8px 0px',
      width: 36,
      height: 36,
      minWidth: 36,
      minHeight: 36,
      textAlign: 'center',
      border: '1px solid white',
      display: 'inline-block'
    },
    hot: {
      backgroundColor: COLORS.ruleTypeMax
    },
    cold: {
      backgroundColor: COLORS.ruleTypeMin
    },
    ruleIcon: {
      padding: '3px',
      color: COLORS.white,
      width: 26,
      height: 26,
      transform: 'translateY(2px) !important'
    },
    ruleIcon_REL_MAX: {
      width: 28,
      height: 28,
      transform: 'rotate(-45deg) translate(2px, 0px) !important'
    },
    ruleIcon_REL_MIN: {
      width: 28,
      height: 28,
      transform: 'scaleX(-1) rotate(135deg) translate(4px, -1px) !important'
    },
    selectedObject: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      background: COLORS.whiteSmoke,
      borderRadius: 5,
      textTransform: 'initial',
      margin: '0px 0px 10px',
      '&:hover': {
        boxShadow: '1px 1px 5px rgb(0 0 0 / 30%)',
        cursor: 'pointer'
      },
      padding: '0 10px',
      boxSizing: 'border-box'
    },
    target: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100%',
      [theme.breakpoints.only('xs')]: {
        fontSize: 10
      }
    },
    targetDisabled: {
      filter: 'grayscale(1)',
      opacity: 0.5
    },
    selectableRule: {
      backgroundColor: COLORS.whisperGray,
      borderRadius: 4,
      cursor: 'pointer',
      textTransform: 'initial',
      display: 'flex',
      textAlign: 'left',
      margin: '10px 20px',
      padding: '3px 10px',
      '&:hover': {
        boxShadow: '1px 1px 5px rgb(0 0 0 / 30%)',
        cursor: 'pointer'
      },
      width: 'calc(100% - 40px)',
      justifyContent: 'left',
      minWidth: 400
    },

    selectableRuleContent: {
      display: 'flex',
      width: '100%'
    },
    ruleText: {
      display: 'inline-block'
    },
    ruleDesc: {
      fontSize: 13
    },
    ruleTitle: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden'
    },
    ruleTitleNarrow: {
      [theme.breakpoints.only('sm')]: {
        fontSize: 12
      }
    },
    FcIcon: {
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    manualFcIcon: {
      padding: '3px',
      color: COLORS.lightGray3,
      width: 20,
      height: 20,
      transform: 'translateY(2px)'
    },
    autoFcIcon: {
      color: COLORS.darkGray
    },
    handIcon: {
      color: COLORS.darkGray,
      transform: 'rotate(50deg) translateY(2px) scale(0.9)'
    },
    transparent: {
      color: 'transparent !important'
    },
    disabled: {
      color: COLORS.lightGray2
    },
    selector: {
      '&>div': {
        paddingTop: 5,
        paddingBottom: 5
      }
    },
    compostRCmonitorItem: {
      minWidth: 330,
      width: 330,
      maxWidth: 330
    },
    compostRCmonitorTitle: {
      textAlign: 'center',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      border: `1px solid ${COLORS.lightGray}`,
      borderRadius: 5,
      marginBottom: 10,
      padding: '0 10px'
    },
    compostMonitorType: {
      fontSize: 12,
      textAlign: 'center',
      textTransform: 'uppercase',
      padding: '2px 0',
      color: COLORS.white,
      borderRadius: 3
    },
    compostMonitorCycle: {
      textAlign: 'center'
    },
    assignedBatch: {
      borderRadius: 20,
      fontSize: 13,
      // backgroundColor: COLORS.mediumGray,
      border: `1px solid ${COLORS.lightGray2}`,
      color: COLORS.primaryColor,
      padding: '1px 5px',
      marginBottom: 8,
      maxWidth: 130,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    },
    assignedMonitor: {
      fontSize: 12,
      // backgroundColor: COLORS.lightGray3,
      border: `1px solid ${COLORS.lightGray2}`,
      color: COLORS.primaryColor,
      borderRadius: 3,
      // border: '1px solid #aaa',
      padding: '3px 5px',
      marginBottom: 8,
      maxWidth: 130,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    },
    nonAssigned: {
      color: COLORS.mediumGray,
      fontSize: 13
    },
    assignedRC: {
      marginLeft: 10,
      marginBottom: 5,
      height: 20,
      fontSize: 13,
      color: COLORS.mediumGray,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      width: 135
    }
  }),

  { index: 1 }
);

export const editableStyle = makeStyles(
  () => ({
    editablePlaceholder: {
      color: COLORS.lightGray2
    },
    editable: {
      position: 'relative',
      cursor: 'pointer',
      width: 'calc(100% - 20px)',
      padding: '5px 10px',
      borderRadius: 4,
      fontSize: 13,
      marginBottom: 20,
      boxSizing: 'border-box',
      '&:hover': {
        backgroundColor: COLORS.whiteSmoke,
        '& $editPen': {
          cursor: 'pointer',
          opacity: 1
        }
      },
      transition: 'all 300ms'
    },
    editPen: {
      opacity: 0,
      position: 'absolute',
      top: 0,
      right: -22,
      color: COLORS.primaryColor,
      width: 20,
      height: 20,
      transition: 'all 300ms'
    },
    rowTitle: {
      fontWeight: 500,
      paddingRight: 30,
      padding: '5px 10px 5px 0px',
      verticalAlign: 'top',
      whiteSpace: 'nowrap',
      textTransform: 'capitalize'
    },
    rowText: {
      paddingBottom: 5,
      verticalAlign: 'top',
      width: '100%'
    }
  }),
  { index: 1 }
);

export const dialogStyle = makeStyles(
  (theme) => ({
    DialogTitle: {
      background: COLORS.secondaryColor,
      color: COLORS.white,
      padding: '12px 24px 12px'
    },
    Dialog: {
      padding: '20px 20px 0 20px',
      minWidth: 'calc(100% - 100px)',
      overflow: 'auto',
      [theme.breakpoints.down('sm')]: {
        minHeight: 'auto',
        minWidth: 'auto'
      }
    },
    TextField: {
      resize: 'both',
      minHeight: '20px',
      width: '100%',
      marginTop: '30px',
      '&:nth-child(1)': {
        marginTop: 'auto'
      },
      [theme.breakpoints.down('sm')]: {
        minHeight: 'auto',
        minWidth: 'auto'
      }
    },
    newPhaseDialog: {
      '& > div': {
        maxWidth: 'none'
      }
    },
    Step3: {
      marginTop: 30
    },
    DialogActions: {
      justifyContent: 'space-between',
      padding: '10px 20px 20px'
    },
    Buttons: {
      '& > :not(:first-child)': {
        marginLeft: 8
      }
    }
  }),
  { index: 1 }
);

export const selectedDetailsStyle = makeStyles(
  () => ({
    selectedContainer: {
      height: '100%',
      backgroundColor: COLORS.white
    },
    selectedContainerVh: {
      height: '100vh',
      backgroundColor: COLORS.white
    },
    tabSelected: {
      color: `${COLORS.primaryColor} !important`,
      cursor: 'default'
    },
    tab: {
      color: 'white !important'
    },
    tabs: {
      '& .MuiTab-wrapper': {
        display: 'contents'
      }
    }
  }),
  { index: 1 }
);

export const templateEditorStyle = makeStyles(
  (theme) => ({
    root: {
      width: '100%'
    },
    div: {
      margin: 10
    },
    button: {
      marginRight: theme.spacing(1)
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    dialog: {
      minHeight: 200,
      minWidth: 400,
      padding: 15,
      overflow: 'inherit',
      [theme.breakpoints.down('xs')]: {
        minHeight: 'auto',
        minWidth: 'auto',
        padding: '0'
      }
    },
    dialogTitle: {
      background: COLORS.secondaryColor,
      color: COLORS.white,
      paddingTop: 4,
      paddingBottom: 4,
      cursor: 'move'
    },
    closeButton: {
      margin: 0,
      padding: 0,
      position: 'absolute',
      right: 10,
      width: 32,
      height: 32
    },
    dialogComponent: {
      maxWidth: '100%',
      maxHeight: '100%'
    },
    adjustedDialogContent: {
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100% - 168px)' // where does 168px  come
    },
    draggable: {
      maxWidth: '100%',
      maxHeight: '100%'
    },
    targetProgress: {
      pointerEvents: 'none'
    },
    selectedObject: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      background: COLORS.whiteSmoke,
      borderRadius: 5,
      textTransform: 'initial',
      margin: '0px 0px 10px',
      '&:hover': {
        boxShadow: '1px 1px 5px rgb(0 0 0 / 30%)',
        cursor: 'pointer'
      }
    }
  }),
  { index: 1 }
);

export const ruleEditorStyle = makeStyles(
  (theme) => ({
    root: {
      width: '100%'
    },
    div: {
      margin: 10,
      minWidth: 400
    },
    divQSlider: {
      margin: 10,
      textAlign: 'center'
    },
    TextField: {
      margin: 10
    },
    TextFieldN: {
      '& > div > input': {
        padding: 5
      }
    },
    NumberField: {
      display: 'inline-flex',
      alignItems: 'center'
    },
    button: {
      marginRight: theme.spacing(1)
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    dialog: {
      minHeight: 150,
      minWidth: 400,
      padding: 20,
      overflow: 'inherit',
      [theme.breakpoints.down('xs')]: {
        minHeight: 'auto',
        minWidth: 'auto',
        padding: '0'
      }
    },
    DialogTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    closeButton: {
      margin: 0,
      padding: 0,
      position: 'absolute',
      right: 10,
      width: 32,
      height: 32
    },
    dialogComponent: {
      maxWidth: '100%',
      maxHeight: '100%'
    },
    adjustedDialogContent: {
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100% - 168px)' // where does 168px  come
    },
    ToggleButtonGroup: {
      maxHeight: 10,
      marginBottom: 50
    },
    draggable: {
      maxWidth: '100%',
      maxHeight: '100%'
    },
    formControl: {
      margin: '10px 20px',
      minWidth: 200
    },
    formControlDepth: {
      margin: '10px 20px',
      minWidth: 75
    },
    inlineIcon: {
      padding: '3px',
      border: '1px solid',
      borderRadius: '100%',
      background: COLORS.secondaryColor,
      color: COLORS.white,
      '&:hover': {
        backgroundColor: 'lightblue',
        cursor: 'pointer'
      }
    },
    FC_icon: {
      color: COLORS.validStatus,
      float: 'right',
      margin: 5,
      overflow: 'hidden'
    },
    slider: {
      marginTop: 30
    }
  }),
  { index: 1 }
);

export const adminStyles = makeStyles(
  (theme) => ({
    root: {
      minWidth: 275,
      border: '1px solid #E5E5E5',
      margin: '10px auto',
      maxWidth: 1200,
      width: 'fit-content'
    },
    mainContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      minHeight: 320,
      flexGrow: 1,
      marginTop: -20,
      zIndex: 1,
      '& svg': {
        overflow: 'visible',
        zIndex: 2
      },
      [theme.breakpoints.up('xs')]: {
        height: 388
      }
    },
    CardHeader: {
      textAlign: 'center',
      marginBottom: -30
    },
    loading: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 200,
      minWidth: 500
    },
    formControl: {
      margin: '10px 20px',
      minWidth: 100
    },
    TextFieldShort: {
      maxWidth: 125,
      margin: 5
    },
    TextField: {
      margin: 5
    },
    searchBar: {
      margin: '0 0 10px',
      display: 'flex'
    },
    groupSearch: {
      display: 'flex',
      flexDirection: 'column'
    },
    divider: {
      margin: '0 10px'
    },
    gridContainer: {
      margin: '20px 0',
      border: '1px solid rgba(0, 0, 0, 0.12)'
    },
    flexDiv: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between'
    }
  }),
  { index: 1 }
);

export const activeStyles = makeStyles(
  (theme) => ({
    activationContainer: {
      position: 'fixed',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      // top: 0,
      // left: 0,
      // right: 0,
      // bottom: 0,
      backgroundColor: '#f5f5f5',
      backgroundImage: `url(${LoginBackground})`,
      backgroundSize: 'cover',
      overflow: 'auto'
      // height: 550
    },
    paper: {
      // marginTop: theme.spacing(8),
      [theme.breakpoints.down('xs')]: {
        marginTop: 350
      },
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center'
    },
    Card: {
      display: 'inline-block',
      width: 220,
      marginBottom: 15,
      textAlign: 'center',
      cursor: 'pointer',
      color: COLORS.white,
      verticalAlign: 'top',
      background: 'rgb(110 189 187 / 80%)',
      borderRadius: 5,
      border: 'none',
      margin: 10,
      maxWidth: 440,
      position: 'relative'
    },
    CardText: {
      color: COLORS.white,
      fontSize: 12,
      minHeight: 50,
      textAlign: 'center',
      marginTop: 15
    },
    container: {
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column'
      }
    },
    activationLang: {
      margin: '0px 0 15px'
    }
  }),
  { index: 1 }
);

export const activationStyles = makeStyles(
  (theme) => ({
    appBar: {
      position: 'relative'
    },
    card: {
      boxShadow: '5px 5px 5px rgb(0 0 0 / 50%)',
      borderRadius: 10,
      padding: theme.spacing(2, 4),
      marginTop: -10,
      marginBottom: 20,
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(2, 1),
        marginTop: 10
      }
    },
    layout: {
      marginTop: 150,
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto'
      },
      textAlign: 'center'
    },
    paper: {
      margin: '125px auto',
      padding: theme.spacing(2)
    },
    backStepper: {
      padding: theme.spacing(0, 0, 3),
      maxWidth: 300,
      margin: 'auto',
      marginTop: -30,
      transform: 'scale(0.8)',
      [theme.breakpoints.down('xs')]: {
        marginTop: 0
      }
    },
    stepper: {
      padding: theme.spacing(0, 0, 3),
      maxWidth: 300,
      margin: 'auto',
      marginTop: 0,
      transform: 'scale(0.8)'
    },
    actLang: {
      position: 'fixed',
      right: 30,
      top: 20,
      zIndex: 10000,
      [theme.breakpoints.down('xs')]: {
        position: 'initial',
        marginTop: -20
      }
    },
    buttons: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: 15,
      marginTop: 30
    },
    loneButton: {
      margin: 15,
      marginTop: 30
    },
    confirmError: {
      fontSize: 12,
      margin: '-10px 0 15px'
    },
    noSMSPanel: {
      marginTop: 15,
      fontSize: 13
    },
    smsSkipPanel: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: COLORS.boulderGray,
      display: 'flex',
      padding: '15px 10px',
      textAlign: 'left',
      margin: '20px 0 15px'
    },
    phoneBoxPanel: {
      backgroundColor: 'rgba(110,189,187,0.15)',
      padding: 10,
      margin: '-15px 0 15px',
      fontSize: 13
    },
    confirmInfo: {
      display: 'flex',
      textAlign: 'left'
    },
    confirmIcon: {
      color: COLORS.secondaryColor,
      width: 50,
      height: 50,
      minWidth: 50,
      margin: '0px 15px 0px 5px'
    },
    button: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1)
    },
    backButton: {
      display: 'block',
      fontSize: 12
    },
    fullpageGrayBg: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      minHeight: '100%',
      backgroundColor: COLORS.whiteSmoke
    },
    probeCard: {
      borderRadius: 5,
      border: 'none',
      padding: 20,
      maxWidth: 480,
      margin: 'auto',
      textAlign: 'left',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        flexFlow: 'column'
      }
    },
    probeInfo: {
      display: 'inline-block',
      maxWidth: '55%',
      verticalAlign: 'middle',
      paddingRight: 15,
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
        paddingBottom: 15
      }
    },
    probeAction: {
      textAlign: 'center'
    },
    buttonCTA: {
      color: COLORS.white,
      margin: '15px 0 20px'
    },
    helperText: {
      margin: '5px 5px 10px'
    },
    errorText: {
      margin: '0px 0px -5px',
      textAlign: 'left',
      fontSize: 12
    },
    userLabel: {
      textAlign: 'right',
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
        marginBottom: -20,
        marginTop: 5
      }
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120
    },
    errorMessage: {
      textAlign: 'center',
      fontSize: '0.75rem'
    },
    gariIdField: { display: 'flex', alignContent: 'space-around', maxWidth: 200 }
  }),
  { index: 1 }
);

export const directConnectStyles = makeStyles(
  () => ({
    connectApp: {
      minHeight: 45,
      width: '100%',
      backgroundColor: COLORS.secondaryColor,
      borderTop: '1px solid white',
      color: COLORS.black,
      position: 'fixed',
      zIndex: 1,
      opacity: 0.8,
      textDecoration: 'none',
      textAlign: 'center',
      paddingTop: 10,
      paddingBottom: 5,
      '&:hover': {
        color: COLORS.white,
        cursor: 'pointer'
      }
    },
    connectAppSub: {
      top: 50
    }
  }),
  { index: 1 }
);

export const accountPasswordStyles = makeStyles(
  (theme) => ({
    mainContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexGrow: 1
    },
    settingsList: {
      width: '100%',
      maxWidth: 600,
      marginBottom: 10,
      [theme.breakpoints.down('xs')]: {
        marginBottom: 0
      }
    },
    dialogContentPassword: {
      paddingTop: 10,
      width: 300,
      [theme.breakpoints.down('xs')]: {
        width: '85%'
      }
    },
    dialogContent: {
      paddingTop: 10,
      minWidth: 200
    },
    username: {
      marginBottom: 10,
      fontSize: '0.75rem'
    },
    password: {
      marginBottom: 40,
      fontSize: '0.75rem'
    }
  }),
  { index: 1 }
);

export const accountServicesStyles = makeStyles(
  (theme) => ({
    hide: {
      display: 'none'
    },
    mainContainer: {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      flexGrow: 1
    },
    userList: {
      width: '100%',
      maxWidth: 600,
      marginBottom: 10,
      overflowY: 'auto',
      overflowX: 'hidden',
      [theme.breakpoints.down('xs')]: {
        marginBottom: 0
      }
    },
    dialogContent: {
      paddingTop: 10,
      minWidth: 300
    },
    plan: {
      marginBottom: 10
    },
    chip: {
      fontWeight: 500,
      borderRadius: 0,
      border: 'none',
      fontSize: 14,
      marginLeft: -3,
      marginTop: 5,
      color: COLORS.white,
      transition: 'none'
    },
    valid: {
      marginBottom: 20
    },
    upgradeButton: {
      marginLeft: 15,
      marginBottom: 20
    },
    secondary: {
      color: COLORS.alert
    }
  }),
  { index: 1 }
);

export const accountPhoneStyles = makeStyles(
  (theme) => ({
    mainContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexGrow: 1
    },
    phonesList: {
      width: '100%',
      maxWidth: 600,
      marginBottom: 10,
      [theme.breakpoints.down('xs')]: {
        marginBottom: 0
      }
    },
    mainPhone: {
      marginBottom: 10
    },
    dialogContentPhone: {
      display: 'flex',
      justifyContent: 'center',
      flexGrow: 1,
      paddingTop: 10,
      width: 300,
      [theme.breakpoints.down('xs')]: {
        width: '80%'
      }
    },
    dialogMessage: {
      marginTop: 10,
      marginBottom: 10
    },
    dialogContent: {
      paddingTop: 10,
      minWidth: 200
    },
    mainPhoneEdit: {
      overflow: 'hidden'
    },
    mainPhoneConfirm: {
      overflow: 'hidden'
    },
    name: {
      width: 200
    },
    bottom: {
      width: '100%',
      height: 100,
      display: 'flex',
      justifyContent: 'center'
    },
    grow: {
      flexGrow: 1
    },
    phoneFab: {
      marginRight: theme.spacing(4),
      marginBottom: theme.spacing(2)
    }
  }),
  { index: 1 }
);

export const accountUsersStyles = makeStyles(
  (theme) => ({
    hide: {
      display: 'none'
    },
    usersMainContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexGrow: 1,
      margin: '5px 10px'
    },
    userList: {
      width: '100%',
      maxWidth: 600,
      marginBottom: 10,
      overflowY: 'auto',
      overflowX: 'hidden',
      boxShadow: 'none',
      [theme.breakpoints.down('xs')]: {
        marginBottom: 0
      }
    },
    gridContainer: {
      margin: '20px 0',
      border: '1px solid rgba(0, 0, 0, 0.12)'
    },
    addUserContainer: {
      display: 'flex',
      flexDirection: 'row-reverse'
    },
    dialogContent: {
      paddingTop: 10,
      minWidth: 300
    }
  }),
  { index: 1 }
);

export const editPhoneDialogStyles = makeStyles(
  (theme) => ({
    mainContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexGrow: 1
    },
    phonesList: {
      width: '100%',
      maxWidth: 600,
      marginBottom: 10,
      [theme.breakpoints.down('xs')]: {
        marginBottom: 0
      }
    },
    mainPhone: {
      marginBottom: 10
    },
    dialogContentPhone: {
      display: 'flex',
      justifyContent: 'center',
      flexGrow: 1,
      paddingTop: 10,
      width: 300,
      [theme.breakpoints.down('xs')]: {
        width: '80%'
      }
    },
    dialogMessage: {
      marginTop: 10,
      marginBottom: 10
    },
    dialogContent: {
      paddingTop: 10,
      minWidth: 200
    },
    mainPhoneEdit: {
      overflow: 'hidden'
    },
    mainPhoneConfirm: {
      overflow: 'hidden'
    },
    name: {
      width: 200
    },
    bottom: (props) => ({
      width: '100%',
      display: 'flex',
      justifyContent: props.index === 0 ? 'flex-end' : 'space-between'
    }),
    grow: {
      flexGrow: 1
    },
    phoneFab: {
      marginRight: theme.spacing(4),
      marginBottom: theme.spacing(2)
    },
    errorMessage: (props) => ({
      color: COLORS.alert,
      textAlign: props.index === 0 ? 'left' : 'center',
      fontSize: '0.75rem'
    })
  }),
  { index: 1 }
);

export const batchCreateDialogStyles = makeStyles(
  (theme) => ({
    paper: {
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100% - 50%)',
      width: 'calc(100% - 50%)',
      [theme.breakpoints.down('lg')]: {
        height: 'calc(100% - 30%)',
        width: 'calc(100% - 30%)'
      },
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 20%)',
        height: 'calc(100% - 20%)'
      },
      [theme.breakpoints.down('xs')]: {
        width: 'calc(100% - 5%)',
        height: 'calc(100% - 5%)'
      }
    },
    root: {
      textAlign: 'center',
      display: 'block',
      border: 0
    },
    TextField: {
      whiteSpace: 'nowrap',
      width: '40%',
      textAlign: 'center',
      display: 'flex',
      border: 0,
      margin: '20px 30px 0',
      position: 'relative',
      minWidth: 0,
      flexDirection: 'column',
      verticalAlign: 'top',
      marginTop: 'calc(.25 * 15vh)'
    },
    TextArea: {
      textAlign: 'center',
      display: 'flex',
      border: 0,
      margin: '20px 30px 0px 30px',
      padding: '30px 0',
      position: 'relative',
      minWidth: 0,
      flexDirection: 'column',
      verticalAlign: 'top',
      marginTop: 'calc(.25 * 15vh)'
    },
    button: {
      marginRight: theme.spacing(1)
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    dialogComponent: {
      height: '90%',
      overflowY: 'auto',
      overflowX: 'hidden',
      padding: 'auto'
    },
    Stepper: {
      padding: '30px 0 0',
      [theme.breakpoints.down('md')]: {
        minHeight: 'auto',
        width: 'auto'
      }
    },
    stepLabel: {
      fontSize: 12,
      marginTop: '5px !important'
    },
    dialogTitle: {
      background: COLORS.secondaryColor,
      color: COLORS.white,
      paddingTop: 4,
      paddingBottom: 4
    },
    step2root: {
      height: '100%',
      display: 'block',
      border: 0,
      marginBottom: 30
    },
    closeButton: {
      margin: 0,
      padding: 0,
      position: 'absolute',
      right: 10,
      width: 32,
      height: 32
    },
    batchDialogActions: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 'auto',
      position: 'absolute',
      bottom: '10px',
      right: '10px'
    },
    root2: {
      width: '100%',
      height: '100%',
      display: 'block',
      border: 0,
      flexGrow: 1
    },
    boolean: {
      color: green[400]
    },
    formControl: {
      margin: '10px 50px',
      minWidth: 150
    },
    List: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    ListTarget: {
      margin: '10px 50px',
      position: 'relative'
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    },
    summaryContainer: {
      display: 'flex',
      margin: 'auto',
      justifyContent: 'center'
    },
    itemWrapper: {
      margin: 'auto',
      marginTop: 50
    },
    title: {
      fontSize: 18
    },
    pos: {
      marginBottom: 12
    }
  }),
  { index: 1 }
);

export const storageMonitorsStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100vh'
  },
  cardWrap: {
    // '&.MuiAccordion-root:before': {
    //   display: 'none'
    // },
    // '&:hover': {
    //   boxShadow: '1px 1px 5px rgb(0 0 0 / 30%)',
    //   cursor: 'pointer'
    // },
    margin: 15,
    boxShadow: '1px 1px 5px rgba(0,0,0,0.2)',
    borderRadius: 4
    // width: '100%'
  },
  smsWrap: {
    '&:hover': {
      cursor: 'default'
    }
  },
  smsCard: {
    display: 'flex',
    marginTop: 10,
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  onoffLabel: {
    minWidth: 145
  },
  card: {
    // [theme.breakpoints.down('sm')]: {
    '& > div:first-child': {
      width: '100%'
      // margin: '5px -10px 10px 10px'
    }
    // }
    // margin: 15,
    // '& .MuiAccordionSummary-root': {
    //   padding: '0 !important',
    // }
  },
  title: {
    textAlign: 'center'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '100%',
    flexShrink: 0,
    width: '100%'
  },
  summary: {
    width: '100%',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  monitorRule: {
    width: '40%',
    maxWidth: 350,
    marginRight: 10,
    [theme.breakpoints.only('sm')]: {
      fontSize: 12
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  summaryAlertIcon: {
    width: 32,
    height: 32,
    color: COLORS.alert,
    margin: '0 8px'
  },
  alertSummary: {
    width: '60%',
    display: 'inline-flex',
    // borderLeft: '1px solid #8080808c',
    alignItems: 'center',
    [theme.breakpoints.only('sm')]: {
      fontSize: 12
    },
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      marginTop: 10
    }
  },
  FcStats: {
    justifyContent: 'space-around'
  },
  FcStatsIcon: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      margin: '0 20px'
    }
  },
  summaryLine: {
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'left'
    },
    flexWrap: 'wrap'
  },
  detailsContent: {
    // display: 'flex',
    display: 'block',
    padding: 20,
    flexWrap: 'nowrap',
    // overflow: 'auto',
    position: 'relative',
    width: 'inherit',
    height: 'calc(100% - 200px)', // 160 for toolbars + 40 for space
    overflow: 'auto'
  },
  formControl: {
    margin: '0px 15px 10px 5px',
    minWidth: '175px !important'
  },
  monitorSelector: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: -50,
    [theme.breakpoints.down('xs')]: {
      marginBottom: -10,
      justifyContent: 'flex-start'
    }
  },
  smsList: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: 10
    }
  },
  chip: {
    padding: 5
  },
  chipNoPhone: {
    padding: 5,
    color: COLORS.alert,
    borderColor: COLORS.alert
  },
  divider: {
    [theme.breakpoints.down('xs')]: {
      height: 1,
      width: '100%'
    }
  },
  alertTableWrapper: {
    overflow: 'hidden',
    width: '100%'
  },
  monitorHistory: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderSpacing: '10px 5px',
    fontSize: 13
  },
  titleCard: {
    margin: 15
  },
  titleTable: {
    padding: '3px 10px',
    fontSize: 17
  },
  okStatusIcon: {
    color: COLORS.greenForest,
    width: 40,
    height: 40,
    margin: '0 6px 0 10px',
    [theme.breakpoints.down('xs')]: {
      margin: '0px 5px 0 0px'
    }
  },
  noProbesIcon: {
    color: COLORS.primaryColor,
    width: 24,
    height: 24,
    margin: '0 10px 0 15px',
    [theme.breakpoints.down('xs')]: {
      margin: '0px 5px 0 0px'
    }
  },
  noMonitor: {
    textAlign: 'center',
    color: COLORS.warning,
    marginTop: 70
  }

  // column: {
  //   float: 'left',
  //   width: '50%'
  // },
  // row: {
  //   height: 30,
  //   marginLeft: 40,
  //   '&:after': {
  //     content: '',
  //     display: 'table',
  //     clear: 'both'
  //   }
  // }
}));

export const menuTabStyles = makeStyles(
  (theme) => ({
    root: {
      flexGrow: 1,
      display: 'flex',
      height: '100%',
      overflow: 'auto',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column'
      }
    },
    adminTabsRoot: {
      width: 200,
      borderRight: `1px solid rgba(0, 0, 0, 0.12)`,
      position: 'fixed',
      zIndex: 100,
      backgroundColor: '#efefef',
      height: 'calc(100% - 80px)',
      // height: 100%;
      /* overflow: auto; */
      left: 0,
      marginTop: 5,
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'row',
        borderRight: `none`,
        borderBottom: `1px solid rgba(0, 0, 0, 0.12)`,
        width: '100%',
        height: 'auto',
        marginTop: 0
      }
    },
    indicator: {
      backgroundColor: (props) => (props ? COLORS.adminDev : COLORS.adminProd)
    },
    tabPanel: {
      flexGrow: 25,
      [theme.breakpoints.down('xs')]: {
        marginTop: 40
      }
    },
    adminTab: {
      backgroundColor: (props) => (props ? COLORS.adminDev : COLORS.adminProd),
      opacity: '1 !important',
      flexDirection: 'row',
      color: COLORS.white,
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },
    adminLabel: {
      textTransform: 'none'
    },
    tabRoot: {
      borderBottom: '1px solid white',
      backgroundColor: COLORS.whisperGray,
      minHeight: 40,
      height: 40,

      [theme.breakpoints.down('xs')]: {
        borderBottom: 'none',
        borderRight: '1px solid white',
        minHeight: 50,
        height: 50,
        width: 40,
        minWidth: 50
      }
    },
    tabItemSelected: {
      backgroundColor: COLORS.altoGray
    },
    tabLabel: {
      textTransform: 'initial',
      alignItems: 'flex-start',
      flexFlow: 'row',
      justifyContent: 'initial',
      [theme.breakpoints.down('xs')]: {
        fontSize: '14px',
        fontWeight: 300,
        justifyContent: 'center'
      }
    },
    savTabs: {
      border: '1px solid rgb(204, 204, 204)',
      borderRight: 'none',
      backgroundColor: '#efefef',
      maxWidth: 80,
      minWidth: 80
    },
    tabWrapperSAV: {
      textTransform: 'initial'
    }
  }),
  { index: 1 }
);
