import { Button, DialogActions, Step, StepLabel, Stepper, Divider } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import GenericAppBar from 'components/general/GenericAppBar';
import { COLORS } from 'utils/colors';
import { getInsightSurvey, postInsightStorages, postInsightSurvey } from 'api/serverAPI';
import { Spinner } from 'utils/Spinners';
import {
  generateAnswersObject,
  insightBackground
} from 'components/account/account-users/account-background/backgroundStateManager';
import { constructSaveObject } from 'components/account/account-users/account-background/helpers';
import SurveyHay from './insightSurvey/SurveyHay';
import SurveyScale from './insightSurvey/SurveyScale';
import SurveyStorage from './insightSurvey/SurveyStorage';
import SurveyReady from './insightSurvey/SurveyReady';
import { insightStyles } from './insightStyles';

const InsightSurvey = ({ i18n }) => {
  const history = useHistory();
  const classes = insightStyles();
  const [state, setState] = useState(insightBackground);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getAnswers = async () => {
      try {
        setIsLoading(true);
        const surveyAnswers = await getInsightSurvey();
        if (surveyAnswers.status_code === 0) {
          setState(generateAnswersObject(surveyAnswers.data));
        }
        setIsLoading(false);
      } catch (e) {
        console.log('Error getting previous answers');
      }
    };
    getAnswers();
  }, []);

  const getSteps = () => [
    i18n.hay || 'Hay',
    i18n.scale || 'Scale',
    i18n.storage || 'Storage',
    i18n.ready || 'Ready'
  ];

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <SurveyHay classes={classes} i18n={i18n} state={state} setState={setState} />;
      case 1:
        return <SurveyScale classes={classes} i18n={i18n} state={state} setState={setState} />;
      case 2:
        return <SurveyStorage classes={classes} i18n={i18n} state={state} setState={setState} />;
      case 3:
        return <SurveyReady classes={classes} i18n={i18n} state={state} setState={setState} />;
      default:
        return 'Unknown step';
    }
  };

  const [activeStep, setActiveStep] = React.useState(0);
  const [isFinishClicked, setIsFinishClicked] = React.useState(false);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleFinishSurvey = async () => {
    try {
      const finalAnswers = constructSaveObject(state);
      console.log('finalAnswers', finalAnswers);

      // Cleaning up the answers
      if (finalAnswers.hayType === 'bales') {
        if (finalAnswers.hayTypeUnit === 'bales') {
          delete finalAnswers.averageTonsPerYear;
        } else {
          delete finalAnswers.balesPerYear;
        }
      } else {
        delete finalAnswers.balesPerYear;
        delete finalAnswers.balesType;
        delete finalAnswers.balesWeight;
      }
      // delete finalAnswers.hayTypeUnit;

      if (!isFinishClicked) {
        setIsFinishClicked(true);
        const resultSurvey = await postInsightSurvey({
          answers: finalAnswers,
          fromWelcomePage: true
        });
        const resultStorages = await postInsightStorages({
          storages: state.storages,
          locale: i18n.locale
        });
        if (resultSurvey.status_code === 0 && resultStorages.status_code === 0) {
          history.replace({
            pathname: '/',
            state: { surveyCompleted: true }
          });
        }
      }
    } catch (e) {
      console.log('Error in saving the survey: ', e);
    }
  };

  const disabled = (step) => {
    if (step === 0) {
      return (
        !state.activityType.value ||
        (state.activityType.value === 'other' && state.activityType.extra.value === '') ||
        !state.hayType.value ||
        (state.yearlyCuts.value === 0 && state.yearlyCuts.extra.value === false)
      );
    }
    if (step === 1) {
      if (state.hayType.value === 'bales') {
        if (state.hayTypeUnit.value === 'bales') {
          return (
            state.balesPerYear.value === 0 || !state.balesType.value || !state.balesWeight.value
          );
        }
        return (
          state.averageTonsPerYear.value === 0 || !state.balesType.value || !state.balesWeight.value
        );
      }
      return state.averageTonsPerYear.value === 0;
    }
    if (step === 2) {
      return (
        state.storageTime.value.reduce((a, b) => a + b, 0) === 0 || state.storagesNumber.value === 0
      );
    }
  };

  return (
    <div>
      <GenericAppBar
        title={i18n.insight_starting ?? 'INSIGHT - Getting Started'}
        // noLanguageSelector
      />
      <div className={classes.root}>
        <Stepper className={classes.stepper} activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel classes={{ label: classes.stepLabel }}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {isLoading ? (
          <Spinner />
        ) : (
          <div className={classes.survey}>
            {getStepContent(activeStep)}
            <Divider />
            <DialogActions className={classes.batchDialogActions}>
              <div>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} className={classes.button}>
                    {i18n.back || 'Back'}
                  </Button>
                )}
                {activeStep !== steps.length - 1 ? (
                  <Button
                    variant='contained'
                    color='secondary'
                    style={{ color: COLORS.white }}
                    onClick={handleNext}
                    disabled={disabled(activeStep)}
                    className={classes.button}
                  >
                    {i18n.next || 'Next'}
                  </Button>
                ) : (
                  <Button
                    variant='contained'
                    color='secondary'
                    style={{ color: COLORS.white }}
                    onClick={handleFinishSurvey}
                    className={classes.button}
                    // disabled={isSavingisLoading}
                  >
                    {i18n.finish || 'Finish'}
                  </Button>
                )}
              </div>
            </DialogActions>
          </div>
        )}
      </div>
    </div>
  );
};

export default InsightSurvey;
