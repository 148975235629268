import { SET_CHARTCONFIG } from 'redux/constants';
import initialState from 'redux/store/initialState';

export default function setChartConfig(state = initialState.chartConfig, action) {
  switch (action.type) {
    case SET_CHARTCONFIG: {
      return {
        ...state,
        ...action.value
      };
    }
    default: {
      return state;
    }
  }
}
