import { Tooltip } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'utils/colors';
import { getGradientColor, getOpacity } from 'assets/utils';

const useStyles = makeStyles(() => ({
  tagItem: {
    minWidth: 68,
    fontSize: 13,
    marginBottom: 6,
    marginRight: 6,
    boxSizing: 'border-box',
    borderRadius: 3,
    position: 'relative',
    backgroundColor: COLORS.altoGray,
    fontFamily: `'Roboto', sans-serif`,
    fontWeight: 400,
    // paddingRight: 0,
    cursor: 'default',
    transition: 'all 200ms'
  },

  hovered: {
    backgroundColor: `${COLORS.mediumGray} !important`,
    color: 'white !important'
  },
  isActive: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: `${COLORS.mediumGray} !important`,
      color: 'white !important'
    }
  },

  selectedSensor: {
    color: COLORS.white
  },
  removedSensor: {
    opacity: 0.4,
    cursor: 'default'
  },

  contentArea: {
    zIndex: 100,
    display: 'flex',
    width: '100%',
    textAlign: 'left',
    textTransform: 'initial',
    marginTop: 3
  },

  contentName: {
    width: '50%',
    paddingTop: 4,
    maxHeight: 20,
    zIndex: 100,
    marginLeft: 5,
    display: 'flex',
    fontSize: 12
  },
  tempBox: {
    width: 10,
    height: 10,
    marginLeft: 5,
    marginTop: 6,
    border: '1px solid rgba(255,255,255,0.7)',
    boxSizing: 'border-box'
  },
  namedSensorId: {
    fontSize: 9,
    marginTop: -4,
    marginBottom: 1,
    zIndex: 100
  },
  sensorName: {
    marginTop: -3,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    zIndex: 100,
    position: 'absolute',
    maxWidth: 49
  },
  selectionOverlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 0,
    zIndex: 90,
    position: 'absolute',
    borderRadius: 4,
    backgroundColor: 'rgba(0,0,0,0.45)'
  }
}));

const SensorTag = ({
  sensorID,
  lastData,
  sensorConf,
  sensorClicked,
  isSelectedSensor,
  hoveredSensor,
  onSensorHover,
  isPassive // no sensor hover & onClick
}) => {
  const classes = useStyles();
  const isRemovedSensor = !sensorConf;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start'
      }}
    >
      <div
        aria-hidden='true' // enables onClick for div
        onClick={() => {
          if (!isPassive && !isRemovedSensor) {
            sensorClicked(sensorID);
          }
        }}
        onMouseEnter={() => {
          if (onSensorHover) {
            onSensorHover(sensorID, true);
          }
        }}
        onMouseLeave={() => {
          if (onSensorHover) {
            onSensorHover(sensorID, false);
          }
        }}
        style={{
          minHeight: 30,
          maxHeight: 45
          // backgroundColor: lastData ? getGradientColor(lastData.temperature) : 'initial',
          // opacity: lastData ? getOpacity(lastData.meas_ts * 1000) : 1
        }}
        className={[
          classes.tagItem,
          !isPassive ? classes.isActive : null,
          hoveredSensor && hoveredSensor === sensorID ? classes.hovered : null,
          isSelectedSensor ? classes.selectedSensor : null,
          isRemovedSensor ? classes.removedSensor : null
        ].join(' ')}
      >
        <Tooltip arrow title={sensorConf.sensor_name || ''} placement='bottom' disableFocusListener>
          <div className={classes.contentArea}>
            <div
              className={classes.tempBox}
              style={{
                opacity: lastData ? getOpacity(lastData.meas_ts * 1000) : 1,
                backgroundColor: lastData
                  ? getGradientColor(lastData.temperature)
                  : COLORS.lightGray2
              }}
            />
            <div className={classes.contentName}>
              {sensorConf && sensorConf.sensor_name ? (
                <div>
                  <div className={classes.namedSensorId}>{sensorID}</div>
                  <div className={classes.sensorName}>{sensorConf.sensor_name}</div>
                </div>
              ) : (
                <div style={isRemovedSensor ? { textDecoration: 'line-through' } : {}}>
                  {sensorID}
                </div>
              )}
            </div>
          </div>
        </Tooltip>
        {isSelectedSensor ? <div className={classes.selectionOverlay} /> : null}
      </div>
    </div>
  );
};

export default SensorTag;
