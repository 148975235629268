import { DialogActions, Button, IconButton, Tooltip } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';

import DeleteIcon from '@material-ui/icons/Delete';

import { COLORS } from 'utils/colors';
import { dialogStyle } from './sharedStyles';

export const DialogActionUtil = ({
  onCancel,
  cancelText,
  onSave,
  text,
  disabled,
  deleteButton,
  deleteUnauthorized,
  handleDelete,
  cancelOnly,
  cancelDisabled,
  specialDeleteUnauthorized
}) => {
  const classes = dialogStyle();
  const i18n = useSelector((store) => store.i18n);
  return (
    <DialogActions
      style={cancelOnly ? { justifyContent: 'flex-end', margin: '0 10px 5px ' } : null}
      className={classes.DialogActions}
    >
      {deleteButton ? (
        deleteUnauthorized ? (
          <Tooltip title={specialDeleteUnauthorized ?? i18n.delete_unauthorized}>
            <IconButton style={{ cursor: 'not-allowed', color: 'lightgray' }} aria-label='Delete'>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <IconButton aria-label='Delete' onClick={() => handleDelete()}>
            <DeleteIcon />
          </IconButton>
        )
      ) : cancelOnly ? (
        <></>
      ) : (
        <div />
      )}
      <div className={classes.Buttons}>
        <Button onClick={onCancel} color='primary' disabled={cancelDisabled}>
          {cancelText || i18n.cancel || 'Cancel'}
        </Button>
        {!cancelOnly && (
          <Button
            onClick={onSave}
            type='submit'
            variant='contained'
            color='secondary'
            style={{ color: COLORS.white }}
            disabled={disabled}
          >
            {text || i18n.edit || 'Edit'}
          </Button>
        )}
      </div>
    </DialogActions>
  );
};
