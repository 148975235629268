import { Dialog, Grow } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { getUpgradeInfo } from 'api/serverAPI';
import { Spinner } from 'utils/Spinners';
import DialogTitleWithClose from 'utils/DialogWithCloseIcon';
import { productsToInclude, generateBillingInfo, setErrorLanguage } from './helpers';
import CheckoutForm from './CheckoutForm';
import { ELEMENTS_OPTIONS } from './StripeElements';

const Stripe = ({
  unauthorized,
  renewalUnauthorizedPlan,
  renewalUnauthorizedSim,
  renewalUnauthorizedAPI,
  renewalInfo,
  isFreeUser,
  insightService
}) => {
  const { type } = queryString.parse(useLocation().search);

  const simRenewal = type === 'sim';
  const APIRenewal = type === 'api';

  const [upgradeInfo, setUpgradeInfo] = useState();

  const history = useHistory();
  const { i18n, userInfo } = useSelector((store) => ({
    i18n: store.i18n,
    userInfo: store.userInfo
  }));

  const i18nStripe = setErrorLanguage(i18n);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!upgradeInfo) {
      getUpgradeInfo({
        UID: userInfo.U_ID
      })
        .then((response) => {
          if (response.status_code) {
            // non-zero status code is error
            console.log('Error in getUpgradeInfo: status=', response.status_code);
          } else {
            console.log('UpgradeInfo: ', response); // TODO: use the UpgradeInfo
            setUpgradeInfo((tempState) => ({
              ...tempState,
              ...response,
              ...generateBillingInfo(userInfo, i18n),
              renewalUnauthorizedPlan,
              renewalUnauthorizedSim,
              renewalUnauthorizedAPI,
              upgradeToPremium: isFreeUser && !APIRenewal && !simRenewal
            }));
            // Give premium same structure
            if (!response.renewal && response.prices) {
              setUpgradeInfo((tempState) => ({
                ...tempState,
                renewal: {
                  level: response.upgrade_plan_level,
                  months: response.prices[0]?.months,
                  name: response.upgrade_plan_name,
                  price: response.prices[0]?.price
                }
              }));
            }
            if (simRenewal) {
              setUpgradeInfo((tempState) => ({
                ...tempState,
                sim_renewal_only: simRenewal
              }));
            }
            if (APIRenewal) {
              setUpgradeInfo((tempState) => ({
                ...tempState,
                API_renewal_only: APIRenewal
              }));
            }
            setUpgradeInfo((tempState) => ({
              ...tempState,
              products: productsToInclude(
                response,
                renewalInfo,
                simRenewal,
                APIRenewal,
                renewalUnauthorizedPlan,
                renewalUnauthorizedSim,
                renewalUnauthorizedAPI,
                isFreeUser,
                insightService
              )
            }));

            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log('Error in getUpgradeInfo: ', error);
        });
    }
    return () => {};
  }, [
    i18n,
    upgradeInfo,
    userInfo,
    renewalUnauthorizedPlan,
    renewalUnauthorizedSim,
    renewalUnauthorizedAPI,
    simRenewal,
    APIRenewal,
    isFreeUser,
    renewalInfo,
    insightService
  ]);

  const onCloseCheckout = () => {
    history.replace('/account/services');
  };

  const [success, setSuccess] = useState(false);

  const APICheckout =
    APIRenewal || (renewalUnauthorizedPlan && renewalUnauthorizedSim && !renewalUnauthorizedAPI);

  const headerLogic = success
    ? '' // 'Hooray!'
    : APICheckout
    ? i18n.msg_API_upgrade
    : upgradeInfo?.sim_renewal_only
    ? i18n.msg_sim_subscription
    : isFreeUser
    ? i18n.msg_service_upgrade
    : i18n.msg_service_subscription;

  const [headerName, setHeaderName] = useState(headerLogic);

  useEffect(() => {
    setHeaderName(headerLogic);
    return () => {};
  }, [headerLogic]);

  if (isLoading || !upgradeInfo) {
    return <Spinner />;
  }
  const stripePromise = loadStripe(upgradeInfo.id);

  return (
    <Dialog
      open
      maxWidth='md'
      PaperProps={{ style: { overflow: 'auto' } }}
      TransitionComponent={Grow}
      aria-labelledby='responsive-dialog-title'
    >
      {isLoading ? (
        <div style={{ margin: 50 }}>
          <Spinner noStyle />
        </div>
      ) : (
        <>
          <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS(i18n.locale)}>
            <DialogTitleWithClose onClose={onCloseCheckout}>{headerName}</DialogTitleWithClose>
            <CheckoutForm
              i18n={i18n}
              i18nStripe={i18nStripe}
              renewalInfo={renewalInfo}
              upgradeInfo={upgradeInfo}
              setUpgradeInfo={setUpgradeInfo}
              unauthorized={unauthorized}
              success={success}
              setSuccess={setSuccess}
              isFreeUser={isFreeUser}
              onClose={onCloseCheckout}
            />
          </Elements>
        </>
      )}
    </Dialog>
  );
};

export default Stripe;
