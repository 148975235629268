import { orderBy } from 'lodash';
import { format } from 'date-fns';
import { tempFormat, isNil } from 'assets/utils';

const ONE_MINUTE_IN_SECONDS = 60;

const withinOneMinute = (currentItem, previousItem) =>
  Math.abs(currentItem.b - previousItem.b) <= ONE_MINUTE_IN_SECONDS;

const checkDuplicate = (currentItem, previousItem, index, countDuplicate) => {
  if (index === 0 || !withinOneMinute(currentItem, previousItem)) {
    return '';
  }
  if (previousItem.notemp) {
    // Not judging G4 temp/volt double data as double
    return '';
  }
  if (countDuplicate) {
    countDuplicate.value += 1;
  }
  return 'D';
};

export const getDataForTable = (sensorData, sensorDetails, probeType, degrees, adminChecked) => {
  const countDuplicate = { value: 0 };
  const sensorDataTable = orderBy(
    sensorData.map((data, index) => ({
      duplicate: checkDuplicate(data, sensorData[index - 1], index, countDuplicate),
      repeat: data.r,
      notemp: data.notemp, // G4 data
      novolt: data.novolt, // G4 data
      id: data.a, // sensor_id
      name:
        sensorDetails?.name && sensorDetails.name !== '' && sensorDetails.name !== data.a
          ? sensorDetails.name
          : null,
      date: data.b * 1000,
      // If admin checked, show full precision temps
      celcius: adminChecked ? data.h : tempFormat(degrees, Number(data.h)),
      hh: probeType !== 'singlepoint' ? data.hh?.slice(1) : [],
      n: data.n,
      handle:
        probeType !== 'singlepoint'
          ? adminChecked
            ? data.hh
              ? data.hh[0]
              : 0
            : tempFormat(degrees, data.hh ? data.hh[0] : 0)
          : null,
      batt: (Number(data.e) / 100).toFixed(2), // voltage
      rss: parseInt(data.f), // sensor_RSSI
      rsb: parseInt(data.g), // bs_RSSI
      tdiff: data.b - data.c, // bs_diff
      bs: data.bs
    })),
    ['date'],
    ['desc']
  );
  return { countDuplicate, sensorDataTable };
};

const convertToNumber = (col, value, alphaColumns) => {
  // Convert non-null numeric values to numbers (or they remain as string in excel)
  if (!alphaColumns.find((a) => col === a)) {
    return isNil(value) ? value : Number(value);
  }
  return value;
};

export const parseData = (data, columns) => {
  // These columns have string values
  const alphaColumns = ['id', 'name', 'date', 'duplicate', 'repeat'];
  return data.map((item) => {
    const value = columns.map((col) => convertToNumber(col, item[col], alphaColumns));
    return [value].flat();
  });
};

export const parseCSV = (data, columns) => {
  const results = {};
  const CSVData = [];

  data.map((item) => {
    columns.forEach((key) => {
      results[key] = item[key];
    });
    return CSVData.push({ ...results });
  });
  return CSVData;
};

const convertMeas = (sensorID, conf, measIndex, meas, prevMeas, i18n) => {
  const name = conf.find((s) => s.sensor_id_sys === sensorID)?.sensor_name || sensorID;
  if (checkDuplicate(meas, prevMeas, measIndex) === 'D') {
    return null;
  }
  const conv = {};
  conv.ID = sensorID;
  conv[i18n.name] = name;
  conv[i18n.date_time] = format(new Date(meas.b * 1000), 'yyyy-MM-dd HH:mm:ss');
  conv[i18n.temperature] = Number(meas.h.toFixed(1)); // String: preserves decimal point as ".";
  return conv;
};

export const formatBatchDataExport = (batch, batchData, sensorConf, i18n) => {
  // batchData is in array of phases with index 0,1.. as key
  // batchData[index] is an array of sensor data with sensor is as key:
  // [090111: [data array], 090222: [data array]]
  // data array is [{a:090111, b: 1650520846, h:22.8}]

  // The data for export is an array of phases
  // [0: [data of phase 0], 1: [data of phase 1]]
  // [data of phase 0] array includes sensors' data
  // {ID: 090111, Name: "Probe name1", Timestamp: 2022-04-22 09:00:22, Temperature (°C): 22.3}
  // {ID: 090111, Name: "Probe name1", Timestamp: 2022-04-22 08:01:26, Temperature (°C): 22.3}
  // {ID: 090222, Name: "Probe name2", Timestamp: 2022-04-22 09:12:45, Temperature (°C): 21.8}
  // {ID: 090222, Name: "Probe name2", Timestamp: 2022-04-22 08:11:55, Temperature (°C): 21.5}

  //  console.log('formatBatchDataExport', batch, batchData, sensorConf);

  // Get phase indices sorted 0,1,2,...
  const indices = batch.batch_phases.map((ph) => +ph.index).sort((a, b) => (a < b ? -1 : 1));
  const data = [];
  indices.map((i) => {
    const phaseData = batchData[i];
    data[i] = [];
    // Sorting probe IDs
    const phaseSensorIDs = Object.keys(phaseData).sort((a, b) => a.localeCompare(b));
    phaseSensorIDs.map((sid) => {
      const sensorData = phaseData[sid].map((meas, measIndex) =>
        convertMeas(sid, sensorConf, measIndex, meas, phaseData[sid][measIndex - 1], i18n)
      );
      // Filter out duplicates (nulls returned by convertMeas)
      data[i] = data[i].concat(sensorData.filter((d) => !!d));
      return sid;
    });
    return i;
  });
  return data;
};
