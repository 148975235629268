import { Slider } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { ToggleButton } from '@material-ui/lab';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { InlineIcon } from '@iconify/react';
import cancel from '@iconify/icons-mdi/cancel';
import checkCircle from '@iconify/icons-mdi/check-circle';
import fan from '@iconify/icons-mdi/fan';
import fanOff from '@iconify/icons-mdi/fan-off';
import { COLORS } from 'utils/colors';

import { TEMPERATURE_MARKS } from 'components/settings/SettingProps';

// Not optimal: 2 slider versions with disabled and enabled hours color
// (could not parametrize the type for only 1 slider)
const AirbnbSlider = withStyles({
  root: {
    color: COLORS.schedulerRed,
    height: 3,
    padding: '13px 0',
    width: '55%',
    margin: 0
  },
  thumb: {
    height: 27,
    width: 27,
    backgroundColor: COLORS.white,
    border: '1px solid currentColor',
    marginTop: -12,
    marginLeft: -13,
    boxShadow: '#ebebeb 0 2px 2px',
    '&:focus, &:hover, &$active': {
      boxShadow: '#ccc 0 2px 3px 1px'
    },
    '& .bar': {
      // display: inline-block !important;
      height: 9,
      width: 1,
      backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1
    },

    '&:after': {
      top: 5,
      left: 5,
      right: 5,
      bottom: 5,
      position: 'absolute',
      borderRadius: '50%'
    }
  },
  active: {},
  track: {
    height: 3
  },
  rail: {
    color: COLORS.lightGray,
    opacity: 1,
    height: 3
  },
  markLabel: {
    top: '-25px',
    color: COLORS.black
  },
  mark: {
    display: 'none'
  },
  markLabelActive: {
    color: COLORS.black
  }
})(Slider);

const AirbnbSliderEnable = withStyles({
  root: {
    color: COLORS.secondaryDark,
    height: 3,
    padding: '13px 0',
    width: '55%',
    margin: 0
  },
  thumb: {
    height: 27,
    width: 27,
    backgroundColor: COLORS.white,
    border: '1px solid currentColor',
    marginTop: -12,
    marginLeft: -13,
    boxShadow: '#ebebeb 0 2px 2px',
    '&:focus, &:hover, &$active': {
      boxShadow: '#ccc 0 2px 3px 1px'
    },
    '& .bar': {
      // display: inline-block !important;
      height: 9,
      width: 1,
      backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1
    },

    '&:after': {
      top: 5,
      left: 5,
      right: 5,
      bottom: 5,
      position: 'absolute',
      borderRadius: '50%'
    }
  },
  active: {},
  track: {
    height: 3
  },
  rail: {
    color: COLORS.lightGray,
    opacity: 1,
    height: 3
  },
  markLabel: {
    top: '-25px',
    color: COLORS.black
  },
  mark: {
    display: 'none'
  },
  markLabelActive: {
    color: COLORS.black
  }
})(Slider);

function AirbnbThumbComponent(props) {
  return (
    <span {...props}>
      <span className='bar' />
      <span className='bar' />
      <span className='bar' />
    </span>
  );
}

const useStyles = makeStyles(() => ({
  revButton: {
    padding: '0 5px'
  }
}));

const SchedulerSlider = ({
  index,
  weekLabel,
  disabledHours,
  setDisabledHours,
  onValueChange,
  monitorInUse,
  setToRunScheduler,
  reversed,
  setReversed,
  fanIcons
}) => {
  const i18n = useSelector((store) => store.i18n);
  const classes = useStyles();

  const handleChange = (e, newValue) => {
    if (monitorInUse) {
      setToRunScheduler(true);
    }
    if (setDisabledHours) {
      setDisabledHours([newValue]);
    }
    if (onValueChange) {
      onValueChange(index, [newValue]);
    }
  };

  const TimeLabel = () => {
    const hours = ` ${`0${disabledHours[0][0]}`.slice(-2)} - ${`0${disabledHours[0][1]}`.slice(
      -2
    )}`;
    if (disabledHours[0][0] === disabledHours[0][1]) {
      return <div style={{ fontSize: 12 }}>{i18n.no_restrictions || 'No restrictions'}</div>;
    }
    return (
      <ToggleButton
        onClick={() => setReversed(index, !reversed)}
        className={classes.revButton}
        selected={!reversed}
        variant='outlined'
        value={index}
        style={{
          color:
            reversed && disabledHours[0][0] !== disabledHours[0][1]
              ? COLORS.secondaryDark
              : COLORS.schedulerRed
        }}
      >
        <InlineIcon
          style={{ width: 20, height: 20, marginRight: 5 }}
          icon={
            reversed && disabledHours[0][0] !== disabledHours[0][1]
              ? fanIcons
                ? fan
                : checkCircle
              : fanIcons
              ? fanOff
              : cancel
          }
        />{' '}
        {hours}
      </ToggleButton>
    );
  };

  return (
    <div
      style={{
        margin: index === 1 ? '50px 0 0 0' : '0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly'
      }}
    >
      <div style={{ width: 30, marginRight: 10 }}>{weekLabel}</div>
      {reversed && disabledHours[0][0] !== disabledHours[0][1] ? (
        <AirbnbSliderEnable
          component='div'
          ThumbComponent={AirbnbThumbComponent}
          value={disabledHours[0]}
          min={0}
          max={24}
          marks={index === 1 && TEMPERATURE_MARKS(0, 24, '', 3, true)}
          onChange={(e, newValue) => handleChange(e, newValue)}
        />
      ) : (
        <AirbnbSlider
          component='div'
          ThumbComponent={AirbnbThumbComponent}
          value={disabledHours[0]}
          min={0}
          max={24}
          marks={index === 1 && TEMPERATURE_MARKS(0, 24, '', 3, true)}
          onChange={(e, newValue) => handleChange(e, newValue)}
        />
      )}
      <div style={{ marginLeft: 10, whiteSpace: 'nowrap' }}>
        <TimeLabel />
      </div>
    </div>
  );
};

export default SchedulerSlider;
