import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid } from '@material-ui/core';
import React, { useState, useContext } from 'react';

import Nouislider from 'react-nouislider';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format, startOfDay, endOfDay, differenceInDays, differenceInMonths } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import localeFormat from 'utils/localeFormat';

import { YEAR_IN_MILLISECONDS, DAY_IN_MILLISECONDS } from 'redux/constants';
import SensorDataTableContext from './SensorDataTableContext';

import { useStyles } from './SensorDataTableFilterDialog.styled';

const MAX_DAYS = 60;

class LocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return format(date, 'd MMM yyyy', { locale: this.locale });
  }
}

const formatWithStartOfDate = (date) => +startOfDay(new Date(date));

const formatWithEndOfDate = (date) => +endOfDay(new Date(date));

const minData = formatWithStartOfDate(new Date()) - YEAR_IN_MILLISECONDS;
const maxData = formatWithEndOfDate(new Date());

const SensorDataTableFilterDialog = ({ gradient }) => {
  const classes = useStyles();
  const { filter, setFilter, setIsConfigChanged, isFilterDialogOpened, setIsFilterDialogOpened } =
    useContext(SensorDataTableContext);
  const dispatch = useDispatch();
  const i18n = useSelector((store) => store.i18n);
  const dateFormat = useSelector(
    (store) => store.sessionInfo.ui_preferences?.date_format ?? 'd/MM/yyyy'
  );
  const timeFormat = useSelector(
    (store) => store.sessionInfo.ui_preferences?.time_format ?? 'HH:mm'
  );

  const [state, setState] = useState({
    ...filter,
    toDateTemp: filter.toDate + DAY_IN_MILLISECONDS - 1
  });

  const handleChangeDate = (date, isFrom) => {
    if (isFrom) {
      setState((tempState) => ({
        ...tempState,
        fromDate: +new Date(date)
      }));
    } else {
      setState((tempState) => ({
        ...tempState,
        toDate: +new Date(date),
        toDateTemp: null
      }));
    }
  };

  const handleChangeSlider = (value, handle, date) => {
    if (differenceInDays(date[1], date[0]) <= MAX_DAYS) {
      setState((tempState) => ({
        ...tempState,
        fromDate: formatWithStartOfDate(date[0]),
        toDate: formatWithStartOfDate(date[1]),
        toDateTemp: formatWithStartOfDate(date[1]) + DAY_IN_MILLISECONDS - 1
      }));
    } else if (handle === 0) {
      // Left handler moves
      setState((tempState) => ({
        ...tempState,
        fromDate: formatWithStartOfDate(state.toDate - 60 * DAY_IN_MILLISECONDS),
        toDate: formatWithStartOfDate(state.toDate),
        toDateTemp: formatWithStartOfDate(date[1]) + DAY_IN_MILLISECONDS - 1
      }));
    } else if (handle === 1) {
      // Right handler moves
      setState((tempState) => ({
        ...tempState,
        fromDate: formatWithStartOfDate(state.fromDate),
        toDate: formatWithStartOfDate(state.fromDate + 60 * DAY_IN_MILLISECONDS),
        toDateTemp: formatWithStartOfDate(date[1]) + DAY_IN_MILLISECONDS - 1
      }));
    }
  };

  const onCancel = () => setIsFilterDialogOpened(false);

  const onSave = () => {
    const { fromDate, toDate, toDateTemp } = state;
    const toDateValue = toDateTemp || toDate;

    dispatch({
      type: 'SET_DATATABLECONFIG',
      value: { filter: { fromDate, toDate: toDateValue } }
    });
    setFilter({
      fromDate,
      toDate: toDateValue
    });
    setIsConfigChanged(true);
    setIsFilterDialogOpened(false);
  };

  return (
    <Dialog
      open={isFilterDialogOpened}
      aria-labelledby='responsive-dialog-title'
      fullWidth
      style={{ padding: 10 }}
    >
      <DialogTitle className={classes.dialogTitle}>
        {i18n.table_date_range || 'Table date range'}
      </DialogTitle>
      <DialogContent className={classes.dialogContent} elevation={0}>
        <div id='time_slider' className={classes.slider}>
          <Nouislider
            range={{ min: +new Date(minData), max: +new Date(maxData) }}
            pips={{
              mode: 'values',
              values: [],
              density: 100 / differenceInMonths(maxData, minData)
            }}
            behaviour='drag'
            connect
            start={[state.fromDate, state.toDate]}
            onChange={handleChangeSlider}
            tooltips
            format={{
              from(value) {
                return +value;
              },
              to(value) {
                return format(+value, 'MMM d', { locale: localeFormat(i18n.locale) });
              }
            }}
          />
        </div>
        <div
          style={{
            height: 28,
            borderRadius: '4px',
            position: 'absolute',
            top: 67,
            left: 49,
            right: 50,
            zIndex: 0,
            backgroundImage: `linear-gradient${gradient}`
          }}
        />

        <MuiPickersUtilsProvider
          utils={LocalizedUtils}
          locale={{
            ...localeFormat(i18n.locale),
            options: { weekStartsOn: 1, firstWeekContainsDate: 4 }
          }}
        >
          <Grid container spacing={1} justifyContent='flex-end'>
            <Grid container item sm={3} xs={6} spacing={1} direction='column'>
              <div className={classes.dateLabel}>{i18n.from_date || 'From Date'}</div>
              <div className={classes.dateText}>{format(state.fromDate, dateFormat)}</div>
            </Grid>

            <Grid container item sm={3} xs={6} spacing={1}>
              <TimePicker
                className={classes.picker}
                ampm={timeFormat === 'hh:mm a'}
                label={i18n.time}
                value={new Date(state.fromDate)}
                onChange={(date) => handleChangeDate(date, true)}
                okLabel={i18n.ok}
                cancelLabel={i18n.cancel}
              />
            </Grid>

            <Grid container item sm={3} xs={6} spacing={1} direction='column'>
              <div className={classes.dateLabel}>{i18n.to_date || 'To Date'}</div>
              <div className={classes.dateText}>{format(state.toDate, dateFormat)}</div>
            </Grid>

            <Grid container item sm={3} xs={6} spacing={1}>
              <TimePicker
                className={classes.picker}
                ampm={timeFormat === 'hh:mm a'}
                label={i18n.time}
                value={new Date(state.toDate + DAY_IN_MILLISECONDS - 1)}
                onChange={(date) => handleChangeDate(date, false)}
                okLabel={i18n.ok}
                cancelLabel={i18n.cancel}
              />
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </DialogContent>

      <DialogActions>
        <Button onClick={onCancel} color='primary'>
          {i18n.cancel}
        </Button>
        <Button onClick={onSave} color='secondary'>
          {i18n.ok}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SensorDataTableFilterDialog;
