import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Nouislider from 'react-nouislider';
import { format, startOfDay, endOfDay, differenceInMonths } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import { YEAR_IN_MILLISECONDS } from 'redux/constants';
import localeFormat from 'utils/localeFormat';
import { generateMinMaxGradient } from 'assets/utils';
import { getMinmaxData } from '../../../api/serverAPI';

const useStyles = makeStyles(() => ({
  qslider: {
    height: 90,
    marginTop: 20,
    marginLeft: 50,
    marginRight: 50
  },
  periodDesc: {
    fontSize: 13,
    marginTop: -10,
    marginBottom: 40
  },
  footerLift: {
    marginTop: -50
  }
}));

const formatWithStartOfDate = (date) => +startOfDay(new Date(date));
const formatWithEndOfDate = (date) => +endOfDay(new Date(date));

const StoragePeriodDialog = ({
  sensorIDs,
  fromDate,
  toDate,
  setFromDate,
  setToDate,
  isPeriodDialogOpen,
  setIsPeriodDialogOpen,
  storagePeriodChanged,
  ongoing,
  setOngoing,
  applyStoragePeriod,
  cancelStoragePeriod,
  title,
  desc,
  todayLabel
}) => {
  const classes = useStyles();
  const [gradient, setGradient] = useState('transparent');
  const { i18n, bsConf } = useSelector((store) => ({
    i18n: store.i18n,
    bsConf: store.bsConf
  }));

  const bsID = bsConf?.bs_id;

  const yearBack = formatWithStartOfDate(new Date()) - YEAR_IN_MILLISECONDS;
  const minData = Math.min(yearBack, +fromDate);
  const maxData = formatWithEndOfDate(new Date());

  const endOfToday = +endOfDay(new Date());
  const thisYear = new Date().getFullYear();

  const [yearData, setYearData] = useState(null);

  useEffect(() => {
    const getYearMinmax = async () => {
      await getMinmaxData({
        basestationID: bsID,
        sensorIDs,
        shortData: true,
        fromTS: Math.round(minData / 1000),
        toTS: Math.round(maxData / 1000),
        yearData: true
      }).then((response) => {
        setYearData(response);
        // console.log('yearData ', response);
        setGradient(generateMinMaxGradient(minData, maxData, response));
      });
    };
    if (!yearData) {
      getYearMinmax();
    }
  }, [bsID, sensorIDs, minData, maxData, yearData, setYearData]);

  const handleChangeSlider = (value, endHandle, date) => {
    if (endHandle) {
      // Adjusting end date
      const end = endOfDay(new Date(date[1]));
      if (+end !== +toDate) {
        setToDate(end);
        setOngoing(+end === endOfToday);
        storagePeriodChanged(null, end);
      }
    } else {
      const start = startOfDay(new Date(date[0]));
      if (+start !== +fromDate) {
        setFromDate(start);
        storagePeriodChanged(start, null);
      }
    }
  };

  const onCancel = () => {
    cancelStoragePeriod();
    setIsPeriodDialogOpen(false);
  };

  const onSave = () => {
    applyStoragePeriod(fromDate, toDate);
    setIsPeriodDialogOpen(false);
  };

  return (
    <Dialog
      open={isPeriodDialogOpen}
      aria-labelledby='responsive-dialog-title'
      fullWidth
      style={{ padding: 10 }}
    >
      <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
      <DialogContent className={classes.dialogContent} elevation={0}>
        <Typography variant='body1' className={classes.periodDesc}>
          {desc}
        </Typography>

        <div id='time_slider' className={classes.qslider}>
          <Nouislider
            range={{ min: +new Date(minData), max: +new Date(maxData) }}
            pips={{
              mode: 'values',
              values: [],
              density: 100 / differenceInMonths(maxData, minData)
            }}
            behaviour='drag'
            connect
            start={[+fromDate, +toDate]}
            onChange={handleChangeSlider}
            tooltips
            format={{
              from(value) {
                return +value;
              },
              to(value) {
                return ongoing && +value === endOfToday && todayLabel
                  ? todayLabel
                  : format(
                      +value,
                      new Date(+value).getFullYear() !== thisYear ? 'MMM d yyyy' : 'MMM d',
                      {
                        locale: localeFormat(i18n.locale)
                      }
                    );
              }
            }}
          />
        </div>
        <div
          style={{
            height: 28,
            borderRadius: '4px',
            position: 'absolute',
            bottom: 88,
            left: 74,
            right: 74,
            zIndex: 0,
            backgroundImage: `linear-gradient${gradient}`
            // backgroundImage: `linear-gradient(to right, rgb(222,2,45), rgb(33,134,45), transparent, rgb(120,34,145))`
          }}
        />
      </DialogContent>

      <div className={classes.footerLift}>
        <DialogActions>
          <Button onClick={onCancel} color='primary'>
            {i18n.cancel}
          </Button>
          <Button onClick={onSave} color='secondary'>
            {i18n.apply}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default StoragePeriodDialog;
