// PUBLIC IMPORTS
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid
} from '@material-ui/core';
import React, { useState } from 'react';
import { isEmpty } from 'lodash';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { useConfirm } from 'hook/useConfirm';
import adminChangesConfirm from 'utils/adminChangesConfirm';

import { getBsConf, postSensorConf } from 'api/serverAPI';

import { useStyles } from './SensorProperties.styled';

const EditNameDialog = ({ editName, toggleName }) => {
  const classes = useStyles();
  const confirm = useConfirm();
  const dispatch = useDispatch();
  const sensorDetails = useSelector((state) => state.sensorDetails);
  const sessionInfo = useSelector((state) => state.sessionInfo);
  const sensorConfigurations = useSelector((state) => state.sensorConfigurations);
  const i18n = useSelector((state) => state.i18n);
  const isAdmin = useSelector((state) => state.isAdmin);
  const isDemoMode = useSelector((state) => state.isDemoMode);
  const bsConf = useSelector((state) => state.bsConf);

  const [sensorName, setSensorName] = useState(sensorDetails.name);

  const handleChangeName = (e) => setSensorName(e.target.value);

  const saveLogs = async (newSensorDetails) => {
    const newSensorConf = [...sensorConfigurations];
    const findIndex = newSensorConf.findIndex(
      (sensor) => sensor.sensor_id_sys === newSensorDetails.id
    );

    if (findIndex !== -1) {
      newSensorConf[findIndex].sensor_name =
        newSensorDetails.name === newSensorDetails.id ? '' : newSensorDetails.name;
    }
    const sensorConfiguration = {
      sensorConfigurations: [
        {
          sensor_id_sys: newSensorDetails.id,
          sensor_name: newSensorDetails.name === newSensorDetails.id ? '' : newSensorDetails.name
        }
      ]
    };

    dispatch({ type: 'SET_SENSORDETAILS', value: newSensorDetails });
    dispatch({ type: 'SET_SENSORCONF', value: newSensorConf });

    let dataBsconf;
    if (isDemoMode && !isAdmin) {
      dataBsconf = { ...bsConf, sensor_configurations: newSensorConf };
      dispatch({ type: 'SET_BSCONF', value: dataBsconf });
    } else {
      try {
        const realBs = sensorConfigurations?.find(
          (s) => s.sensor_id_sys === newSensorDetails.id
        )?.realBs;
        await postSensorConf({ basestationID: realBs || bsConf.bs_id }, sensorConfiguration);
        dataBsconf = await getBsConf({ basestationID: bsConf.bs_id });
      } catch (err) {
        console.log('err: ', err);
        return;
      }

      if (dataBsconf.status_code === 0) {
        dispatch({ type: 'SET_BSCONF', value: dataBsconf.conf_data });
      }
    }
  };

  const onSave = async () => {
    await adminChangesConfirm(isAdmin, confirm, 'Probe name', sessionInfo.username);

    const newSensorDetails = { ...sensorDetails };
    newSensorDetails.name = !isEmpty(sensorName) ? sensorName : sensorDetails.subid;
    toggleName();
    saveLogs(newSensorDetails);
  };

  return (
    <Dialog open={editName} aria-labelledby='responsive-dialog-title'>
      <DialogTitle id='edit-sensor-name'>{i18n.sensor_name}</DialogTitle>
      <DialogContent className={classes.dialogContent} elevation={0}>
        <Grid container justifyContent='space-around'>
          <TextField
            id='sensor_name'
            label={i18n.name}
            placeholder={sensorDetails.subid}
            style={{ margin: 8 }}
            value={sensorName}
            onChange={handleChangeName}
            fullWidth
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleName} color='primary'>
          {i18n.cancel}
        </Button>
        <Button onClick={onSave} color='secondary'>
          {i18n.ok}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditNameDialog;
