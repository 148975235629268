import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDrop } from 'react-dnd';
import { isEmpty } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'utils/colors';
import { ItemTypes } from './ItemTypes';
import UnplacedSensor from './UnplacedSensor';

const useStyles = makeStyles(() => ({
  SensorListDroppable: {
    zIndex: 100,
    borderBottom: '1px solid #ccc',
    overflow: 'hidden',
    overflowY: 'auto',
    width: '100%',
    // height: 65,
    transition: 'all 300ms',
    boxSizing: 'border-box',
    textAlign: 'center'
  },
  noProbes: {
    marginTop: 20
  }
}));

export default function SensorListDroppable({ sensors, onDrop, listHeight, data }) {
  const classes = useStyles();

  const i18n = useSelector((store) => store.i18n);

  // When rendered, expand height from 0 to listHeight with transition
  const [height, setHeight] = useState(0);
  // setHeight(listHeight);
  useEffect(() => {
    setHeight(listHeight);
    // const timer = setTimeout(() => setHeight(listHeight), 10);
    // return () => clearTimeout(timer);
  }, [setHeight, listHeight]);

  const id = 'bin';

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: ItemTypes.SENSOR,
    drop: () => ({ id }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });
  const isActive = canDrop && isOver;
  let backgroundColor = COLORS.whisperGray;
  // let color = '#999';
  if (isActive) {
    backgroundColor = COLORS.yellowBright;
    // color = '#999';
  } else if (canDrop) {
    backgroundColor = COLORS.whisperGray;
    // color = '#d66';
  }

  // const height = isEditing ? 'fit-content' : '0 !important';

  return (
    <div
      id='storageSensorList'
      ref={drop}
      className={classes.SensorListDroppable}
      style={{ backgroundColor, height }}
    >
      {isEmpty(sensors) ? (
        <div className={classes.noProbes}>{i18n.no_probes_available || 'No probes available'}</div>
      ) : (
        sensors
          .sort((a, b) => (a.id.substr(3, 6) < b.id.substr(3, 6) ? -1 : 1))
          .map((sen) => (
            <UnplacedSensor
              measurement={data ? data.find((meas) => meas.sensor_id === sen.id) : null}
              key={sen.id}
              id={sen.id}
              type={sen.type}
              onDrop={onDrop}
            />
          ))
      )}
    </div>
  );
}
