import { Tooltip, Toolbar, IconButton, Button } from '@material-ui/core';
import React, { useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

// import { isEmpty } from 'lodash';
import { InlineIcon } from '@iconify/react';
import closeIcon from '@iconify/icons-mdi/close';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  processStorageMonitors,
  postSaveStorage,
  postRemoveStorage,
  setFcUse
} from 'api/serverAPI';
import { useConfirm } from 'hook/useConfirm';
import { COLORS } from 'utils/colors';
import { ConfirmChanges } from 'utils/ConfirmChanges';
import adminChangesConfirm from 'utils/adminChangesConfirm';
import { DeleteDialog } from 'utils/DeleteDialog';
import { selectedToolbarStyle } from 'utils/sharedStyles';
import { StorageContext } from '../StorageContext';
import { controllerRule } from '../monitors/monitor-utils/fcHelpers';

const SelectedStorageToolbar = ({
  selectedStorage,
  setSelectedStorage,
  setStoragesChanges,
  isStorageEdited,
  setIsStorageEdited
}) => {
  const history = useHistory();
  const dispatchRedux = useDispatch();
  const confirm = useConfirm();
  const userInfo = useSelector((store) => store.userInfo);
  const i18n = useSelector((store) => store.i18n);
  const FcStatus = useSelector((store) => store.FcStatus);
  const bsConf = useSelector((store) => store.bsConf);
  const isDemoMode = useSelector((store) => store.isDemoMode);
  const isAdmin = useSelector((store) => store.isAdmin);
  const storages = useSelector((store) => store.storages);
  const classes = selectedToolbarStyle();
  const BS_ID = useSelector((store) => store.bsConf?.bs_id);

  const disableDemoDelete =
    BS_ID === 'DEMOdata0' && ['293', '292', '674', '675', '676'].includes(selectedStorage?.itemID);

  const [isConfirmNeeded, setIsConfirmNeeded] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const { _, dispatch } = useContext(StorageContext);

  const handleClose = (okToClose) => {
    // const flatProbes = selectedStorage?.sensor_layout?.sensors || [];
    // const stackProbes = selectedStorage.stacks
    //   ? selectedStorage?.stacks.map((st) => st.sensors).flat()
    //   : [];
    // const noProbes = isEmpty(flatProbes.concat(stackProbes));

    if (isStorageEdited && okToClose !== true) {
      setIsConfirmNeeded(true);
      // Notification of alerts disabled with cnacel/OK dialog:
      // } else if (noProbes && okToClose) {
      //   setIsConfirmNeeded(1);
      // } else if (!selectedStorage.monitor && okToClose) {
      //   setIsConfirmNeeded(2);
      // } else if (
      //   (!selectedStorage.alertMessaging?.sms?.active ||
      //     isEmpty(selectedStorage.alertMessaging?.sms?.UIDS)) &&
      //   okToClose
      // ) {
      //   setIsConfirmNeeded(3);
    } else {
      // we reuse redundant groupDetails redux field for "storage details open" state
      dispatchRedux({ type: 'SET_GROUPDETAILS', value: null }); // clear storage selection
      setIsConfirmNeeded(false);
      // setStoragesChanges(true);
      setSelectedStorage(null);
      setIsStorageEdited(false);
      history.replace('/storages');
      dispatch({ type: 'PROBE_SELECTED', value: false }); // clear probe selection
      dispatch({ type: 'SHOW_PREFS', value: false }); // no prefs show by default
      dispatchRedux({ type: 'SET_EDIT_CONFIRM_NEEDED', value: false });
    }
  };

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleClickOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
    // if (isStorageEdited) {
    //   alert(i18n.save_before_continue || 'Save your changes or discard them before continuing');
    // } else {
    //   setOpenDeleteDialog(true);
    // }
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const deleteStorageHandler = () => {
    const storageToRemove = {
      UID: userInfo.U_ID,
      itemID: selectedStorage.itemID
    };
    if (isDemoMode && !isAdmin) {
      dispatchRedux({
        type: 'SET_STORAGES',
        value: storages.filter((st) => +st.itemID !== +selectedStorage.itemID)
      });
      setOpenDeleteDialog(false);
      setIsStorageEdited(false);
      dispatchRedux({ type: 'SET_EDIT_CONFIRM_NEEDED', value: false });
      handleClose();
    } else {
      postRemoveStorage(storageToRemove)
        .then((response) => {
          console.log(response);
          setOpenDeleteDialog(false);
          setIsStorageEdited(false);
          const alreadyInUse = FcStatus.find((FC) => FC.inUse === storageToRemove.itemID);
          if (alreadyInUse) {
            setFcUse({
              id: alreadyInUse.FC_ID
            })
              .then((res) => {
                console.log('setFcUse to remove response', res);
              })
              .catch((error) => {
                console.log('Error in setFcUse: ', error);
              });
          }
          dispatchRedux({ type: 'SET_EDIT_CONFIRM_NEEDED', value: false });
          setStoragesChanges(true);
          handleClose();
        })
        .catch((error) => {
          console.log('Error in deleteStorageHandler: ', error);
        });
    }
  };

  const saveStorage = async () => {
    const savedStorage = {
      UID: userInfo.U_ID,
      item: selectedStorage
    };
    await adminChangesConfirm(isAdmin, confirm, 'Storage saving status', userInfo.U_ID);

    // Deleting undefinedLayoutType
    if (savedStorage.item.undefinedLayoutType) {
      delete savedStorage.item.undefinedLayoutType;
    }
    if (isDemoMode && !isAdmin) {
      const newStoragesArray = storages.map(
        (obj) => [selectedStorage].find((o) => o.itemID === obj.itemID) || obj
      );
      dispatchRedux({ type: 'SET_STORAGES', value: newStoragesArray });
      setIsStorageEdited(false);
    } else {
      try {
        const response = await postSaveStorage(savedStorage);
        const FCinUse = response.item.monitor?.rules?.find((rule) => controllerRule(rule));
        const alreadyInUse = FcStatus.find((FC) => FC.inUse === response.itemID);
        if (alreadyInUse && (FCinUse?.settings.FC_ID !== alreadyInUse.FC_ID || !FCinUse)) {
          try {
            const res = await setFcUse({
              id: alreadyInUse.FC_ID
            });
            console.log('setFcUse to remove response', res);
          } catch (error) {
            console.log('Error in setFcUse: ', error);
          }
        }
        if (FCinUse) {
          console.log('FCinUse,', {
            id: FCinUse.settings.FC_ID,
            use: response.itemID,
            outdoorID: FCinUse.settings.outdoorID,
            BS_ID: bsConf.bs_id
          });
          try {
            const res = await setFcUse({
              id: FCinUse.settings.FC_ID,
              use: response.itemID,
              outdoorID: FCinUse.settings.outdoorID,
              BS_ID: bsConf.bs_id
            });
            console.log('setFcUse response', res);
          } catch (error) {
            console.log('Error in setFcUse: ', error);
          }
        }
        try {
          await processStorageMonitors({
            BS_ID: bsConf.bs_id,
            UID: savedStorage.UID,
            itemIDs: [savedStorage.item.itemID]
          });
        } catch (error) {
          console.log('Error in processStorageMonitors: ', error);
        }
      } catch (error) {
        console.log('Error in saveStorage: ', error);
      }

      // setServerBatch(null);
      setStoragesChanges(true);
      setIsStorageEdited(false);
      dispatchRedux({ type: 'SET_EDIT_CONFIRM_NEEDED', value: false });
    }
  };

  return (
    <>
      <Toolbar className={classes.toolbar} id='storageToolbar'>
        <div className={classes.deleteInToolbar}>
          <Tooltip
            title={i18n.delete_storage || 'Delete storage'}
            placement='bottom'
            disableFocusListener
          >
            <IconButton
              disabled={disableDemoDelete}
              aria-label='Delete storage'
              onClick={() => handleClickOpenDeleteDialog()}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
        <div className={classes.selectedName}>
          {selectedStorage?.name}
          {selectedStorage?.isAdmin ? <span className={classes.admin}> *ADMIN</span> : null}
        </div>
        <div>
          <Button
            type='submit'
            color='secondary'
            style={!isStorageEdited ? null : { color: COLORS.white }}
            disabled={!isStorageEdited}
            variant={!isStorageEdited ? null : 'contained'}
            onClick={() => saveStorage()}
          >
            {i18n.save || 'Save'}
          </Button>
          <Button onClick={handleClose} color='primary' style={{ marginLeft: 10 }}>
            {i18n.close || 'Close'}
            <InlineIcon
              style={{ marginTop: -3, marginLeft: 5 }}
              icon={closeIcon}
              width={24}
              height={24}
            />
          </Button>
        </div>
      </Toolbar>
      <ConfirmChanges
        isConfirmNeeded={isConfirmNeeded}
        setIsConfirmNeeded={setIsConfirmNeeded}
        isEdited={isStorageEdited}
        setIsEdited={setIsStorageEdited}
        handleClose={handleClose}
        onSave={saveStorage}
      />
      <DeleteDialog
        i18n={i18n}
        openDeleteDialog={openDeleteDialog}
        handleCloseDeleteDialog={handleCloseDeleteDialog}
        handleDelete={() => deleteStorageHandler()}
        text={i18n.alert_storage_delete || 'Are you sure you want to delete this storage?'}
        textAction={i18n.delete || 'delete'}
      />
    </>
  );
};

export default SelectedStorageToolbar;
