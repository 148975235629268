// PUBLIC IMPORTS
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid
} from '@material-ui/core';
import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { format } from 'date-fns';

import { Icon } from '@iconify/react';
import thermometerLines from '@iconify/icons-mdi/thermometer-lines';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { useConfirm } from 'hook/useConfirm';
import adminChangesConfirm from 'utils/adminChangesConfirm';

import { getBsConf, postSensorConf } from 'api/serverAPI';

import { useStyles } from './SensorProperties.styled';

const EditCorrectionDialog = ({ corrections, editCorrection, toggleCorrection }) => {
  const classes = useStyles();
  const confirm = useConfirm();
  const dispatch = useDispatch();
  const sensorDetails = useSelector((state) => state.sensorDetails);
  const sessionInfo = useSelector((state) => state.sessionInfo);
  const sensorConfigurations = useSelector((state) => state.sensorConfigurations);
  const i18n = useSelector((state) => state.i18n);
  const isAdmin = useSelector((state) => state.isAdmin);
  const bsConf = useSelector((state) => state.bsConf);

  const [currentValue, setCurrentValue] = useState(
    corrections && !isEmpty(corrections)
      ? corrections.sort((d1, d2) => (d2.date > d1.date ? 1 : -1))[0].factor
      : 0
  );

  const prevCorrection = corrections?.sort((d1, d2) => (d2.date > d1.date ? 1 : -1))[0];

  const handleChangeValue = (e) => {
    let val = e.target.value;
    // Limit to +-1
    if (val > 1) {
      val = 1;
    }
    if (val < -1) {
      val = -1;
    }
    setCurrentValue(val);
  };

  const saveCorrection = async (user, value) => {
    const newSensorConf = [...sensorConfigurations];
    const findIndex = newSensorConf.findIndex(
      (sensor) => sensor.sensor_id_sys === sensorDetails.id
    );

    if ((prevCorrection && prevCorrection.factor === value) || (!prevCorrection && value === 0)) {
      // Do not save if factor is not changed
      return;
    }

    const lastCorrection = { date: Math.round(new Date().getTime() / 1000), factor: value, user };
    const allCorrections =
      findIndex !== -1 && newSensorConf[findIndex].corrections
        ? newSensorConf[findIndex].corrections
        : [];

    allCorrections.push(lastCorrection);
    if (findIndex !== -1) {
      newSensorConf[findIndex].corrections = allCorrections;
    }

    const sensorConfiguration = {
      sensorConfigurations: [
        {
          sensor_id_sys: sensorDetails.id,
          corrections: allCorrections
        }
      ]
    };

    dispatch({ type: 'SET_SENSORCONF', value: newSensorConf });

    let dataBsconf;
    try {
      const realBs = sensorConfigurations?.find(
        (s) => s.sensor_id_sys === sensorDetails.id
      )?.realBs;
      await postSensorConf({ basestationID: realBs || bsConf.bs_id }, sensorConfiguration);
      dataBsconf = await getBsConf({ basestationID: bsConf.bs_id });
    } catch (err) {
      console.log('err: ', err);
      return;
    }

    if (dataBsconf.status_code === 0) {
      dispatch({ type: 'SET_BSCONF', value: dataBsconf.conf_data });
    }
  };

  const onSave = async () => {
    await adminChangesConfirm(isAdmin, confirm, 'Value correction', sessionInfo.username);
    const user = isAdmin ? `admin:${sessionInfo.admin_username}` : sessionInfo.username;
    toggleCorrection();
    saveCorrection(user, Number(currentValue));
  };

  return (
    <Dialog open={editCorrection} aria-labelledby='responsive-dialog-title'>
      <DialogTitle>
        <Icon width={30} style={{ marginBottom: -10, marginRight: 10 }} icon={thermometerLines} />
        {i18n.sensor_correction || 'Value correction'}
      </DialogTitle>
      <DialogContent className={classes.dialogContent} elevation={0}>
        <Grid container justifyContent='space-around'>
          <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
            <TextField
              id='correction_value'
              style={{ padding: 0 }}
              onChange={handleChangeValue}
              // Prevent writing, only arrows used
              onKeyDown={(event) => {
                event.preventDefault();
              }}
              type='number'
              variant='outlined'
              InputProps={{
                inputProps: {
                  min: -1,
                  max: 1,
                  step: 0.1
                }
              }}
              value={Number(currentValue).toFixed(1)}
            />
            <div style={{ marginLeft: 20, opacity: Number(currentValue) === 0 ? 0 : 1 }}>
              {`50.0 °C \u21D2 ${(50 + Number(currentValue)).toFixed(1)} °C`}
            </div>
          </div>
          {corrections && !isEmpty(corrections) ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                width: '100%',
                marginLeft: 10,
                marginTop: 10
              }}
            >
              <div style={{ marginBottom: 5, fontSize: 13, fontWeight: 'bold' }}>
                {i18n.correction_history || 'Correction history'}
              </div>
              <div style={{ maxHeight: 75, overflowY: 'auto' }}>
                {corrections
                  ?.sort((d1, d2) => (d2.date > d1.date ? 1 : -1))
                  .map((corr, i) => (
                    <div
                      style={{ display: 'flex', marginBottom: 3, fontSize: 13, paddingRight: 20 }}
                      key={i}
                    >
                      <div style={{ minWidth: 120 }}>
                        {format(new Date(corr.date * 1000), 'd.M.yyyy HH:mm')}
                      </div>
                      <div style={{ minWidth: 40, textAlign: 'right' }}>{`${Number(
                        corr.factor
                      ).toFixed(1)} °C`}</div>
                      <div style={{ marginLeft: 30 }}>{`${corr.user}`}</div>
                    </div>
                  ))}
              </div>
            </div>
          ) : null}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleCorrection} color='primary'>
          {i18n.cancel}
        </Button>
        <Button onClick={onSave} color='secondary'>
          {i18n.ok}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditCorrectionDialog;
