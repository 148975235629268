import { Dialog, Grow, Button } from '@material-ui/core';
import { InlineIcon } from '@iconify/react';
import checkCircle from '@iconify/icons-mdi/check-circle';
import closeCircle from '@iconify/icons-mdi/close-circle';
import lockCheck from '@iconify/icons-mdi/lock-check';
import stripeImage from 'assets/images/stripe.png';
import { Spinner } from 'utils/Spinners';
import { COLORS } from 'utils/colors';

const ResetButton = ({ onClick, i18n }) => (
  <Button color='primary' variant='contained' onClick={onClick}>
    {i18n.continue || 'Continue'}
  </Button>
);

export const SuccessDialog = ({
  success,
  successInvoice,
  error,
  i18n,
  billingDetails,
  upgradeInfo,
  unauthorized,
  reset
}) => (
  <div className='Result'>
    <div>
      <InlineIcon
        style={{
          color: success || successInvoice ? COLORS.greenForest : COLORS.alert,
          marginBottom: 10
        }}
        width={50}
        height={50}
        icon={success || successInvoice ? checkCircle : closeCircle}
      />
    </div>
    <div className='ResultTitle' role='alert'>
      {error
        ? i18n.msg_general_error
        : successInvoice
        ? i18n.msg_invoice_success
        : success && upgradeInfo.upgradeToPremium
        ? i18n.msg_upgrade_success
        : success && !upgradeInfo.upgradeToPremium
        ? i18n.msg_renewal_success
        : unauthorized
        ? i18n.msg_renewal_unavailable
        : ''}
    </div>
    <div className='ResultMessage'>
      {error
        ? i18n.cardErrorMsg // || state.cardErrorMsg
        : successInvoice
        ? `${i18n.msg_renewal_success} ${i18n.msg_invoice_email}${i18n.colon}${billingDetails.email}`
        : success
        ? i18n.msg_renewal_thank
        : unauthorized
        ? i18n.msg_renewal_unavailable_title
        : ''}
    </div>
    <ResetButton onClick={reset} i18n={i18n} />
  </div>
);

export const ProcessingDialog = ({ i18n }) => (
  <Dialog
    open
    maxWidth='md'
    PaperProps={{ style: { overflow: 'auto' } }}
    TransitionComponent={Grow}
    aria-labelledby='responsive-dialog-title'
  >
    <div style={{ textAlign: 'center', margin: 30 }}>
      <Spinner noStyle />
      <div style={{ marginTop: 20, fontSize: 24 }} className='ResultTitle' role='alert'>
        <InlineIcon style={{ marginRight: 5 }} icon={lockCheck} />
        {i18n.msg_secure_payment || 'Processing your payment'}
      </div>
      <div style={{ textAlign: 'right' }}>
        <img width='120px' alt='Stripe' src={stripeImage} />
      </div>
      <div style={{ marginTop: 20 }} className='ResultMessage'>
        {i18n.processingMsg || 'Please wait a few seconds'}
      </div>
    </div>
  </Dialog>
);
