// MAIN IMPORTS
import { Tooltip } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Switch, Link, useRouteMatch } from 'react-router-dom';

// CSS IMPORTS
import { MdAdd } from 'react-icons/md';
import { getMonitors, getFcItem } from 'api/serverAPI';
import { mainComponentStyle, StyledFab } from 'utils/sharedStyles';

// SERVER IMPORTS

import SensorDetails from 'components/probes/sensor/sensor-detail/SensorDetails';
// CREATE
import StorageCreate from './storage-create/StorageCreate';

// TOOLBAR
import StoragesToolbar from './storage-toolbar/StoragesToolbar';

// CONTAINER
import StorageList from './storage-list/StorageList';
import MonitorsView from './monitors/MonitorsView';
import StorageSelected from './StorageSelected';

// CONTEXT
import { StorageProvider } from './StorageContext';
import getStoragesHelper from './helper';

const Storages = () => {
  const dispatch = useDispatch();
  const { url } = useRouteMatch();
  const classes = mainComponentStyle();

  const {
    i18n,
    bsConf,
    userInfo,
    socketUpdates,
    FC_IDS,
    FcStatus,
    storages,
    isAdmin,
    previousUpdateTS,
    isSimExpired,
    isDemoMode
  } = useSelector((store) => ({
    i18n: store.i18n,
    bsConf: store.bsConf,
    userInfo: store.userInfo,
    socketUpdates: store.socketUpdates,
    FC_IDS: store.userInfo?.FC_IDs,
    FcStatus: store.FcStatus,
    storages: store.storages,
    isAdmin: store.isAdmin,
    previousUpdateTS: store.previousUpdateTS,
    isSimExpired: store.isSimExpired,
    isDemoMode: store.isDemoMode
  }));
  const [storagesChanges, setStoragesChanges] = useState(false);
  const [selectedMonitor, setSelectedMonitor] = useState(null);
  const [loadingStorages, setLoadingStorages] = useState(true);

  useEffect(() => {
    dispatch({ type: 'TOGGLE_VIEW', value: 'storages' }); // changes menu icon selection
    dispatch({ type: 'SET_GROUPDETAILS', value: null }); // clear possible storage selection
    if (storages) {
      setLoadingStorages(false);
    }
  }, [dispatch, storages]);

  const [selectedStorageUpdates, setSelectedStorageUpdates] = useState(false);
  // UPDATE WHEN SOCKET ARRIVES
  useEffect(() => {
    if (bsConf && storagesChanges) {
      console.log('getStoragesHelper');
      getStoragesHelper(
        setLoadingStorages,
        setStoragesChanges,
        storagesChanges,
        bsConf.bs_id,
        dispatch,
        previousUpdateTS,
        userInfo,
        bsConf,
        isAdmin,
        setSelectedStorageUpdates,
        isDemoMode
      );
    }
    return () => {};
  }, [bsConf, dispatch, isAdmin, isDemoMode, previousUpdateTS, storagesChanges, userInfo]);

  const [monitors, setMonitors] = useState(null);
  const [monitorsChanges, setMonitorsChanges] = useState(false);
  const [createMonitor, setCreateMonitor] = useState(false);

  useEffect(() => {
    if ((!monitors || monitorsChanges) && userInfo) {
      setMonitorsChanges(false);
      const query = {
        UID: userInfo ? userInfo.U_ID : 'no_uid'
      };

      if (isDemoMode) {
        query.isDemoMode = isDemoMode;
      }

      getMonitors(query)
        .then((response) => {
          console.log('response getMonitors: ', response);
          setMonitors(response);
        })
        .catch((error) => {
          console.log('Error in getMonitors: ', error);
          setMonitorsChanges(false);
        });
    }
    setMonitorsChanges(false);
    return () => {};
  }, [isDemoMode, monitors, monitorsChanges, userInfo]);

  useEffect(() => {
    const getFcItemFromAPI = async () => {
      setStoragesChanges(false);
      try {
        const response = await getFcItem({ id: FC_IDS.join(',') });
        console.log('response getFcItem', response);
        dispatch({ type: 'SET_FC_STATUS', value: response });
      } catch (error) {
        console.log('Error in getFcItem: ', error);
      }
    };
    if (
      FC_IDS &&
      (!FcStatus || FcStatus.length < 1 || socketUpdates || monitorsChanges || storagesChanges)
    ) {
      getFcItemFromAPI();
    }
    return () => {};
  }, [FC_IDS, FcStatus, dispatch, monitorsChanges, socketUpdates, storagesChanges]);

  const [waitForServer, setWaitForServer] = useState(false);

  const newStorageButton = (
    <Link className={classes.noLink} to={`${url}/new-storage`}>
      <Tooltip title={i18n.new_storage || 'New storage'} placement='left' disableFocusListener>
        <StyledFab style={{ zIndex: 1 }} color='primary'>
          {/*  onClick={() => createStorageHandler()} */}
          <MdAdd size='2em' />
        </StyledFab>
      </Tooltip>
    </Link>
  );

  return (
    <div className={classes.mainWrapper}>
      <Switch>
        <StorageProvider>
          <Route
            exact
            path={`${url}/monitors`}
            render={() => (
              <>
                <StoragesToolbar />
                <MonitorsView
                  selectedMonitor={selectedMonitor}
                  setSelectedMonitor={setSelectedMonitor}
                  monitors={monitors?.filter((mon) => !mon.compost)}
                  setMonitors={setMonitors}
                  setMonitorsChanges={setMonitorsChanges}
                  createMonitor={createMonitor}
                  setCreateMonitor={setCreateMonitor}
                  storages={storages}
                  setStoragesChanges={setStoragesChanges}
                  FC_IDS={FC_IDS}
                />
              </>
            )}
          />
          <Route exact path={`${url}/:itemID`}>
            <StorageSelected
              storages={storages}
              setMonitors={setMonitors}
              storagesChanges={storagesChanges}
              setStoragesChanges={setStoragesChanges}
              monitors={monitors}
              createMonitor={createMonitor}
              setCreateMonitor={setCreateMonitor}
              setMonitorsChanges={setMonitorsChanges}
              selectedMonitor={selectedMonitor}
              setSelectedMonitor={setSelectedMonitor}
              waitForServer={waitForServer}
              setWaitForServer={setWaitForServer}
              selectedStorageUpdates={selectedStorageUpdates}
              setSelectedStorageUpdates={setSelectedStorageUpdates}
            />
          </Route>
          <Route exact path={`${url}/:itemID/quality`}>
            <StorageSelected
              storages={storages}
              setMonitors={setMonitors}
              storagesChanges={storagesChanges}
              setStoragesChanges={setStoragesChanges}
              monitors={monitors}
              createMonitor={createMonitor}
              setCreateMonitor={setCreateMonitor}
              setMonitorsChanges={setMonitorsChanges}
              selectedMonitor={selectedMonitor}
              setSelectedMonitor={setSelectedMonitor}
              waitForServer={waitForServer}
              setWaitForServer={setWaitForServer}
              selectedStorageUpdates={selectedStorageUpdates}
              setSelectedStorageUpdates={setSelectedStorageUpdates}
              qualityMode
            />
          </Route>
          <Route exact path={`${url}/:itemID/edit`}>
            <StorageSelected
              storages={storages}
              setMonitors={setMonitors}
              storagesChanges={storagesChanges}
              setStoragesChanges={setStoragesChanges}
              monitors={monitors}
              createMonitor={createMonitor}
              setCreateMonitor={setCreateMonitor}
              setMonitorsChanges={setMonitorsChanges}
              selectedMonitor={selectedMonitor}
              setSelectedMonitor={setSelectedMonitor}
              waitForServer={waitForServer}
              setWaitForServer={setWaitForServer}
              selectedStorageUpdates={selectedStorageUpdates}
              setSelectedStorageUpdates={setSelectedStorageUpdates}
              editMode
            />
          </Route>
          <Route exact path={`${url}/:itemID/probes/:probeId`} render={() => <SensorDetails />} />

          <Route exact path={url}>
            <StoragesToolbar />
            <StorageList loadingStorages={loadingStorages} storages={storages} />
            {!isSimExpired && newStorageButton}
          </Route>

          <Route
            exact
            path={`${url}/new-storage`}
            render={() => (
              <>
                {ReactDOM.createPortal(
                  <StorageCreate
                    storages={storages}
                    setStoragesChanges={setStoragesChanges}
                    setWaitForServer={setWaitForServer}
                    monitors={monitors}
                    isDemoMode={isDemoMode}
                  />,
                  document.getElementById('dialog-root')
                )}
              </>
            )}
          />
        </StorageProvider>
      </Switch>
    </div>
  );
};

export default Storages;
