// PUBLIC IMPORTS
import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { range, size } from 'lodash';
import {
  VictoryChart,
  VictoryLine,
  VictoryScatter,
  VictoryAxis,
  VictoryTooltip,
  VictoryVoronoiContainer
} from 'victory';
import localeFormat from 'utils/localeFormat';

import { COLORS } from 'utils/colors';
import { useStyles, chartStyles } from './SensorChartType.styled';
import CustomTooltip from './CustomTooltip';
import NoteTooltip from './NoteTooltip';
import NoteScatterPoint from './NoteScatterPoint';
import { getDataForChart } from './helpers';

const initialState = {
  dataValue: [{ x: 0, y: -1000 }],
  minValue: 0,
  maxValue: 100,
  customRange: range(0, 100, 10),
  domain: [0, 1],
  rangeTime: [0],
  rangeLabel: false,
  rangeFormat: '',
  reference: [],
  referenceLabel: false,
  referenceFormat: '',
  tooltipFormat: ''
};

export default function SensorHumidityChart({
  min,
  max,
  data,
  logs,
  isSnapShot,
  i18n,
  dateFormat,
  timeFormat
}) {
  const classes = useStyles();
  const [state, setState] = useState(initialState);

  console.log('SensorHumidityChart', isSnapShot, data);
  useEffect(() => {
    if (!data[1]) {
      setState({ domain: [0, 1] });
    }

    setState({
      dataValue: data,
      ...getDataForChart(min, max, data, 0, 100, isSnapShot, [])
    });
  }, [min, max, data, isSnapShot]);

  const getTickFormat = (value) =>
    (state.rangeLabel &&
      format(new Date(value), state.rangeFormat, { locale: localeFormat(i18n.locale) })) ||
    (state.reference.findIndex((time) => time === value) !== -1
      ? format(new Date(value), state.referenceFormat, { locale: localeFormat(i18n.locale) })
      : '');

  return (
    <div
      className={
        isSnapShot ? [classes.mainContainer, classes.overviewBg].join(' ') : classes.mainContainer
      }
      id='chartMainContainer'
    >
      <VictoryChart
        style={chartStyles.victoryChart}
        padding={{ top: 50, bottom: 50, left: 70, right: 50 }}
        width={document.getElementById('chartMainContainer')?.offsetWidth || 0}
        height={document.getElementById('chartMainContainer')?.offsetHeight || 0}
        scale={{ x: 'linear', y: 'linear' }}
        containerComponent={
          <VictoryVoronoiContainer
            labels={() => [state.tooltipFormat, state.minValue, state.maxValue]}
            labelComponent={
              <CustomTooltip mode={5} i18n={i18n} dateFormat={dateFormat} timeFormat={timeFormat} />
            }
            responsive={false}
            voronoiBlacklist={['noteScatter']}
          />
        }
      >
        <defs>
          <filter id='shadow'>
            <feDropShadow dx='0' dy='1' stdDeviation='1' floodOpacity='0.4' />
          </filter>
        </defs>
        <VictoryAxis
          domain={state.domain}
          tickValues={state.rangeTime}
          tickFormat={(value) => getTickFormat(value)}
          offsetY={50}
          style={chartStyles.verticalGrid}
        />
        {size(logs) > 0 && (
          <VictoryAxis
            domain={state.domain}
            tickValues={logs.map((log) => log.log_ts)}
            tickFormat={() => null}
            offsetY={50}
            style={chartStyles.verticalGrid2}
          />
        )}
        <VictoryAxis
          domain={state.domain}
          tickValues={state.reference}
          offsetY={50}
          style={chartStyles.rangeStick}
        />
        <VictoryAxis
          dependentAxis
          domain={[state.minValue, state.maxValue]}
          tickValues={state.customRange}
          tickFormat={(value) => `${value} %`}
          style={chartStyles.horizontalGrid}
        />
        <VictoryScatter
          name='moistureScatter'
          data={state.dataValue.filter((point) => point.y !== null && !Number.isNaN(point.y))}
          domain={{
            x: state.domain,
            y: [state.minValue, state.maxValue]
          }}
          size={4}
          style={{
            data: {
              fill: COLORS.mediumPurple
            }
          }}
        />
        <VictoryScatter
          name='noteScatter'
          data={
            isSnapShot
              ? []
              : logs.map((log) => ({
                  x: log.log_ts,
                  y: state.maxValue,
                  size: 5,
                  label: '',
                  log
                }))
          }
          dataComponent={<NoteScatterPoint />}
          labelComponent={
            <VictoryTooltip orientation='bottom' dy={8} flyoutComponent={<NoteTooltip />} />
          }
        />
        <VictoryLine
          data={state.dataValue.filter((point) => point.y !== null && !Number.isNaN(point.y))}
          domain={{
            x: state.domain,
            y: [state.minValue, state.maxValue]
          }}
          interpolation='monotoneX'
          style={{ data: { stroke: COLORS.mediumPurple, strokeWidth: 2 } }}
        />
      </VictoryChart>
    </div>
  );
}
