import React from 'react';
import { useSelector } from 'react-redux';
import { useDrag } from 'react-dnd';
import { makeStyles } from '@material-ui/core/styles';
import { ItemTypes } from './ItemTypes';
import SensorHandle from './SensorHandle';

const useStyles = makeStyles(() => ({
  draggableSensor: {
    position: 'absolute',
    minHeight: 30,
    minWidth: 30,
    left: 'calc(50% - 15px)',
    top: 'calc(50% - 15px)',
    transition: 'transform 200ms'
  }
}));

const DraggableSensor = ({
  id,
  type,
  measurement,
  disabled,
  isActive,
  onDrop,
  inList,
  onSensorClicked
}) => {
  const classes = useStyles();

  const isAdmin = useSelector((store) => store.isAdmin);

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.SENSOR,
    item: { id, sensortype: type },
    canDrag: () => !disabled,
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        onDrop(item.id, dropResult.id, item.sensortype);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });
  let handlePoint = false;

  // For now, do not show handle point data to basic users
  if (!isAdmin) {
    handlePoint = false;
  }

  const sensorHandle = (
    <SensorHandle
      isSelected={false}
      id={id}
      type={type}
      isActive={isActive}
      inList={inList}
      showHandleTemp={false}
      temperature={measurement ? measurement.temperature : null}
      timestamp={measurement ? measurement.meas_ts : null}
      onSensorClicked={onSensorClicked}
    />
  );

  const handleHeight = handlePoint ? 60 : 32;
  const height = inList ? 32 : handleHeight;
  const minWidth = 30;

  const opacity = isDragging ? 0 : 1;
  const position = inList ? 'unset' : 'absolute';
  const cursor = disabled ? 'default' : 'grab';
  return (
    <div
      ref={drag}
      className={classes.draggableSensor}
      style={{ opacity, height, minWidth, cursor, position }}
    >
      {sensorHandle}
    </div>
  );
};
export default DraggableSensor;
