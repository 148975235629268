import { Slider, InputBase } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { getGradientColor, fToCelsius } from 'assets/utils';
import ColorBar from 'assets/images/bggorr.png';
import { COLORS } from 'utils/colors';

export const QSlider = withStyles({
  root: {
    color: (props) => getGradientColor(props.value, [30, 60]),
    height: 8
  },
  valueLabel: {
    color: (props) => getGradientColor(props.value, [30, 60])
  }
})(Slider);

export const useStyles = makeStyles(
  (theme) => ({
    hide: {
      display: 'none'
    },
    mainContainer: {
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(1),
      overflow: 'hidden',
      [theme.breakpoints.down('xs')]: {
        padding: 0
      }
    },
    main: {
      overflowX: 'auto'
    },
    groupContainer: {
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(1),
      overflow: 'hidden',
      [theme.breakpoints.down('xs')]: {
        height: '100%',
        padding: 0
      }
    },
    settingsSensorList: { maxWidth: 350, margin: 'auto', marginTop: 5, marginBottom: 10 },
    settingSensorsHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column'
      }
    },
    settingSensorFind: {
      maxWidth: 150,
      marginRight: 20,
      [theme.breakpoints.down('xs')]: {
        marginLeft: 20
      }
    },
    settingsView: {
      width: '100%',
      height: '100%',
      maxWidth: 600
    },
    alertsDescDiv: {
      display: 'flex',
      background: COLORS.zirconBlue,
      margin: '-10px 15px 3px',
      padding: '12px 0px 5px'
    },
    alertsDesc: {
      fontSize: 14,
      padding: '5px 20px 0px 0px',
      marginTop: -10
    },
    alertsIcon: {
      width: 24,
      height: 24,
      paddingLeft: 10,
      minWidth: 36,
      paddingRight: 10
    },
    safetySettingDesc: {
      fontSize: 11,
      padding: '0px 20px 15px 17px',
      marginTop: -10
    },
    spinnerContainer: {
      display: 'flex',
      marginTop: 20,
      marginBottom: 20,
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        padding: 0
      }
    },
    tempContainer: {
      alignItems: 'flex-start',
      height: '140px',
      padding: '30px'
    },
    tempTitle: {
      position: 'relative',
      width: '100%',
      height: 20,
      top: -20,
      left: -15
    },
    sliderContainer: {
      display: 'block',
      margin: '30px 20px 30px 5px',
      height: 80,
      textAlign: 'center'
    },
    divider: {
      marginBottom: 10
    },
    dialogContentRadio: {
      paddingTop: 10,
      minWidth: 200
    },
    dialogContent: {
      paddingTop: 10,
      minWidth: 300,
      [theme.breakpoints.down('xs')]: {
        minWidth: 0
      }
    },
    dialogContentSlider: {
      alignItems: 'center',
      textAlign: 'center',
      minHeight: 40,
      padding: 60,
      paddingBottom: 40,
      paddingTop: 50,
      width: 'auto',
      minWidth: 400,
      [theme.breakpoints.down('xs')]: {
        minWidth: 150,
        padding: 42
      }
    },
    //
    settingsWrapper: {
      margin: 'auto'
    },
    settingGroup: {
      margin: '16px 8px'
    },
    settingGroupTitle: {
      margin: '10px 0'
    },
    select: {
      margin: '12px'
    },
    timelineWrapper: {
      position: 'absolute',
      left: 30,
      right: 80,
      bottom: 5
    },
    alert: {
      margin: '16px 30px',
      textAlign: 'center',
      marginTop: 50
    },
    chartScale: {
      margin: 30,
      marginTop: 50,
      textAlign: 'center',
      '& > span': {
        maxWidth: '800px',
        display: 'inline-block'
      }
    },
    capitalize: {
      '&::first-letter': {
        textTransform: 'capitalize'
      }
    },
    disabledProbes: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    },
    backdrop: {
      zIndex: 500
    },
    welcome: {
      textAlign: 'center',
      marginTop: 50
    },
    multiBS: {
      backgroundColor: COLORS.chartArea,
      marginBottom: 5,
      display: 'flex',
      justifyContent: 'space-between',
      padding: '2px 0px  7px'
    },
    multiBSSelect: {
      fontSize: 16,
      marginTop: 5,
      marginLeft: 10
    }
  }),
  { index: 1 }
);

export const ChartSlider = withStyles({
  root: {
    color: COLORS.primaryColor,
    height: 4
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: COLORS.white,
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit'
    }
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
    fontFamily: `'Roboto', sans-serif`,
    letterSpacing: -0.5
  },
  mark: {
    height: 4,
    color: COLORS.white
  },
  track: {
    height: 4,
    borderRadius: 4
  },
  rail: {
    height: 4,
    borderRadius: 4
  }
})(Slider);

export const ColorSlider = withStyles({
  root: {
    color: COLORS.primaryColor,
    height: 8
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: 'transparent',
    border: '2px solid currentColor',
    marginTop: -6,
    marginLeft: -9,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit'
    }
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
    fontFamily: `'Roboto', sans-serif`,
    letterSpacing: -0.5
  },
  mark: {
    display: 'none'
  },
  track: {
    height: 8,
    borderRadius: 4,
    backgroundImage: `url(${ColorBar})`,
    backgroundSize: '100% 8px',
    backgroundRepeat: 'no-repeat'
  },

  rail: {
    height: 8,
    borderRadius: 4,
    opacity: 1,
    backgroundImage: (props) =>
      props && props.value && props.value.length === 5
        ? `linear-gradient(to right, #6ebdbb 0%, #6ebdbb ${
            props.is_f ? fToCelsius(props.value[0]) : props.value[0]
          }%, #64c864 ${props.is_f ? fToCelsius(props.value[1]) : props.value[1]}%, #f3ab38 ${
            props.is_f ? fToCelsius(props.value[2]) : props.value[2]
          }%, #de534e ${props.is_f ? fToCelsius(props.value[3]) : props.value[3]}%, #252525 ${
            props.is_f ? fToCelsius(props.value[4]) : props.value[4]
          }% 100%, #252525 100%)`
        : 'linear-gradient(to right, #6ebdbb, #64c864, #f3ab38, #de534e, #252525)',

    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  }
})(Slider);

export const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: COLORS.secondaryColor,
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}))(InputBase);
