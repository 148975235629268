import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import {
  getSummary,
  getOverview,
  getMeasurements,
  activateSystem,
  activateProbes,
  deactivateBS,
  reactivateBS
} from './serverAPI';

const getFunctionName = (functionName) => {
  let serverAPICall;
  switch (functionName) {
    case 'getSummary':
      serverAPICall = getSummary;
      break;
    case 'getOverview':
      serverAPICall = getOverview;
      break;
    case 'getMeasurements':
      serverAPICall = getMeasurements;
      break;
    case 'activateSystem':
      serverAPICall = activateSystem;
      break;
    case 'activateProbes':
      serverAPICall = activateProbes;
      break;
    case 'deactivateBS':
      serverAPICall = deactivateBS;
      break;
    case 'reactivateBS':
      serverAPICall = reactivateBS;
      break;
    default:
      break;
  }
  return serverAPICall;
};

const PublicAPI = () => {
  const location = useLocation();

  const apiCall = location.pathname.split('/');
  const functionName = apiCall[apiCall.length - 1];

  const query = queryString.parse(useLocation().search);
  const nameApiCall = getFunctionName(functionName);
  const [response, setResponse] = useState();
  useEffect(() => {
    if (!response) {
      nameApiCall(query)
        .then((res) => setResponse(res))
        .catch((err) => setResponse(err));
    }
  }, [nameApiCall, query, response, functionName]);

  console.log(nameApiCall.name, response);
  return (
    <pre>
      <code>{JSON.stringify(response, undefined, 2)}</code>
    </pre>
  );
};

export default PublicAPI;
