import {
  useMediaQuery,
  Paper,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  Divider,
  IconButton,
  Portal,
  Snackbar
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';

// REDUX
import { useSelector } from 'react-redux';

// ICONS
import { MdClose } from 'react-icons/md';
import { accountPasswordStyles } from 'utils/sharedStyles';
import ChangePasswordDialog from './ChangePasswordDialog';

const ChangePasswordNotification = ({ snack, closeSnack }) => {
  const { i18n, isAdmin, sessionInfo } = useSelector((state) => ({
    i18n: state.i18n,
    isAdmin: state.isAdmin,
    sessionInfo: state.sessionInfo
  }));

  return (
    <Portal>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={snack}
        autoHideDuration={3000}
        onClose={closeSnack}
        message={
          <span id='message-id'>
            {isAdmin
              ? `Password for user ${
                  sessionInfo && sessionInfo.username
                } has been changed successfully!`
              : i18n.password_success}
          </span>
        }
        action={[
          <IconButton key='close' aria-label='Close' color='inherit' onClick={closeSnack}>
            <MdClose />
          </IconButton>
        ]}
      />
    </Portal>
  );
};

const initialState = {
  username: '',
  editPassword: false,
  snack: false
};

const AccountPassword = () => {
  const classes = accountPasswordStyles();

  const { i18n, sessionInfo, userInfo } = useSelector((store) => ({
    i18n: store.i18n,
    sessionInfo: store.sessionInfo,
    userInfo: store.userInfo
  }));

  const isXsWidth = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (userInfo) {
      setState((tempState) => ({ ...tempState, username: sessionInfo.username }));
    }
  }, [sessionInfo, userInfo]);

  const togglePassword = () => {
    setState((tempState) => ({ ...tempState, editPassword: !state.editPassword }));
  };

  const toggleSnack = () => {
    setState((tempState) => ({ ...tempState, snack: !state.snack }));
  };

  return (
    <div className={classes.mainContainer}>
      <Paper component='div' className={classes.settingsList} elevation={isXsWidth ? 0 : 1}>
        <List>
          <ListSubheader disableSticky component='div'>
            {i18n.account ?? 'User account'}
          </ListSubheader>
          <ListItem className={classes.username}>
            <ListItemText primary={i18n.username} secondary={state.username} />
          </ListItem>
          <Divider />
          <ListItem button onClick={togglePassword}>
            <ListItemText primary={i18n.change_password} secondary={i18n.change_login_password} />
          </ListItem>
        </List>
      </Paper>

      {state.editPassword && (
        <ChangePasswordDialog
          editPassword={state.editPassword}
          togglePassword={togglePassword}
          toggleSnack={toggleSnack}
        />
      )}

      {state.snack && <ChangePasswordNotification snack={state.snack} toggleSnack={toggleSnack} />}
    </div>
  );
};

export default AccountPassword;
