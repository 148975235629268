import { AppBar, Tabs, Tab } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { InlineIcon } from '@iconify/react';
import eyeOff from '@iconify/icons-mdi/eye-off';
import bellOffOutline from '@iconify/icons-mdi/bell-off-outline';
import handBackRight from '@iconify/icons-mdi/hand-back-right';
import { isSMSMessagingOff } from 'assets/utils';
import { selectedDetailsStyle } from 'utils/sharedStyles';
import * as storageConstants from '../../storage-utils/storageConstants';

const StorageDetailsTabs = ({
  selectedStorage,
  selectedTab,
  tabChange,
  manualFCControl,
  alertRulesInStorage
}) => {
  const classes = selectedDetailsStyle();
  const i18n = useSelector((state) => state.i18n);

  const sensorIDsPlaced =
    selectedStorage?.layoutType === 3
      ? selectedStorage?.stacks
          .map((s) => s.sensors)
          .flat()
          .map((s) => s.id)
      : selectedStorage?.sensor_layout
      ? selectedStorage.sensor_layout?.sensors
          ?.filter((sen) => sen.column >= 0)
          .map((sen) => sen.id) || []
      : [];

  return (
    <AppBar position='static' color='secondary' elevation={0}>
      <Tabs
        className={classes.tabs}
        indicatorColor='primary'
        value={selectedTab}
        textColor='primary'
        onChange={tabChange}
        id='storageTabs'
        aria-label='storage tabs'
        // scrollButtons='auto'
        variant='scrollable'
      >
        <Tab
          label={i18n.status || 'status'}
          className={
            selectedTab === storageConstants.TAB_LAYOUT ? classes.tabSelected : classes.tab
          }
          value={storageConstants.TAB_LAYOUT}
        />
        {/* <Tab
          label={`${i18n.batch || 'batch'} (0)`}
          className={selectedTab === storageConstants.TAB_BATCHES ? classes.tabSelected : classes.tab}
          value={storageConstants.TAB_BATCH}
        /> */}
        <Tab
          label={
            <>
              {selectedStorage?.monitor &&
              (isEmpty(sensorIDsPlaced) ||
                isSMSMessagingOff(selectedStorage) ||
                !alertRulesInStorage) ? (
                <InlineIcon style={{ paddingRight: 6 }} icon={bellOffOutline} />
              ) : null}
              {i18n.monitor || 'Monitor'}{' '}
              {!selectedStorage?.monitor ? (
                <InlineIcon style={{ paddingLeft: 8 }} icon={eyeOff} />
              ) : null}
              {selectedStorage?.monitor && manualFCControl ? (
                <InlineIcon
                  style={{ paddingLeft: 8, transform: 'rotate(50deg) translate(-4px, -2px)' }}
                  icon={handBackRight}
                />
              ) : null}
            </>
          }
          className={
            selectedTab === storageConstants.TAB_MONITORS ? classes.tabSelected : classes.tab
          }
          value={storageConstants.TAB_MONITORS}
        />
        <Tab
          label={i18n.properties || 'properties'}
          className={
            selectedTab === storageConstants.TAB_PROPERTIES ? classes.tabSelected : classes.tab
          }
          value={storageConstants.TAB_PROPERTIES}
        />
      </Tabs>
    </AppBar>
  );
};

export default StorageDetailsTabs;
