import React, { useState, useEffect } from 'react';
import { CircularInput, CircularTrack, CircularProgress } from 'react-circular-input';
import { makeStyles } from '@material-ui/core/styles';
import { getGradientColor, isNil } from 'assets/utils';
import { COLORS } from 'utils/colors';

const useStyles = makeStyles(() => ({
  cyclePanel: {
    // backgroundColor: 'yellow',
    position: 'relative'
  },
  minutePanel: {
    backgroundColor: 'transparent',
    borderRadius: '100%',
    position: 'absolute',
    left: 20,
    top: 20
  },
  menuTitle: {
    fontSize: 13,
    fontWeight: 'bold',
    textAlign: 'center'
  }
}));

const RCCycle = ({
  cycleSpan,
  runMinutes,
  width,
  noText,
  colorTemp,
  progressMinutes,
  circleColor,
  progressColor
}) => {
  // cycleDef: {"minTemp":50,"maxTemp":60,"cycleSpan":120,"runMinutes":3}
  const classes = useStyles();

  const [value, setValue] = useState(runMinutes / cycleSpan);
  const [rangeValue, setRangeValue] = useState(
    Math.ceil((runMinutes / cycleSpan) * cycleSpan * 10) / 10
  );

  useEffect(() => {
    // Set up the initial values correctly
    setValue(runMinutes / cycleSpan);
    setRangeValue(Math.ceil((runMinutes / cycleSpan) * cycleSpan * 10) / 10);
  }, [cycleSpan, runMinutes, setValue, setRangeValue]);

  const getCycleLabel = (cycle) => `${cycle} min`;
  const getCycleRadius = () => width;

  return (
    <div className={classes.cyclePanel}>
      {isNil(progressMinutes) && (
        <CircularInput radius={getCycleRadius(cycleSpan)} value={value}>
          <CircularTrack style={{ cursor: 'pointer' }} strokeWidth={3} stroke={COLORS.altoGray} />
          <CircularProgress
            style={{ cursor: 'pointer' }}
            strokeWidth={5}
            stroke={!isNil(colorTemp) ? getGradientColor(colorTemp) : COLORS.lightGray3}
          />

          {!noText && cycleSpan !== rangeValue && (
            <text
              x={getCycleRadius(cycleSpan)}
              y={getCycleRadius(cycleSpan) - 7}
              textAnchor='middle'
              dy='0.3em'
              fontWeight='bold'
            >
              {rangeValue < 1 ? `${rangeValue * 60} s` : `${rangeValue} min`}
            </text>
          )}

          {!noText && cycleSpan !== rangeValue && (
            <text
              x={getCycleRadius(cycleSpan)}
              y={getCycleRadius(cycleSpan) + 7}
              textAnchor='middle'
              fontSize='12px'
              dy='0.3em'
            >
              / {getCycleLabel(cycleSpan)}
            </text>
          )}
          {!noText && cycleSpan === rangeValue && (
            <text
              x={getCycleRadius(cycleSpan)}
              y={getCycleRadius(cycleSpan)}
              textAnchor='middle'
              fontSize='12px'
              dy='0.3em'
            >
              Continuous
            </text>
          )}
        </CircularInput>
      )}
      {!isNil(progressMinutes) && (
        <div style={{ position: 'relative' }}>
          <CircularInput
            // style={{ position: 'absolute', left: 0, top: 0 }}
            radius={getCycleRadius(cycleSpan)}
            value={progressMinutes / cycleSpan}
          >
            <CircularTrack strokeWidth={3} stroke={circleColor || COLORS.altoGray} />
            <CircularProgress strokeWidth={2} stroke={progressColor || COLORS.validStatus} />
            {!noText && (
              <text
                x={getCycleRadius(cycleSpan)}
                y={getCycleRadius(cycleSpan)}
                fill={COLORS.validStatus}
                textAnchor='middle'
                fontSize='12px'
                dy='0.3em'
              >
                {/* {`val= ${progressMinutes / cycleSpan}`} */}
                {Math.floor(progressMinutes)}/{getCycleLabel(cycleSpan)}
              </text>
            )}
          </CircularInput>
          {/* <CircularInput
            style={{ position: 'absolute', left: 0, top: 0 }}
            radius={getCycleRadius(cycleSpan)}
            value={runMinutes / cycleSpan}
          >            
            <CircularProgress strokeWidth={3} stroke={COLORS.validStatus} />
          </CircularInput> */}
        </div>
      )}
    </div>
  );
};

export default RCCycle;
