import { Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { Icon } from '@iconify/react';
import warning from '@iconify/icons-mdi/arrow-down-circle-outline';
import alert from '@iconify/icons-mdi/arrow-down-circle';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'utils/colors';
import { i18nVariablesInjection } from 'utils/sharedHelper';
import { celsiusToF, isQualityWarning, isQualityAlert, getQualityColor } from 'assets/utils';

const useStyles = makeStyles((theme) => ({
  legendBox: {
    borderRadius: 4,
    backgroundColor: COLORS.whisperGray,
    marginBottom: 5,
    borderColor: 'transparent',
    borderWidth: 2,
    borderStyle: 'solid'
  },
  legendWrapper: {
    display: 'flex',
    padding: 8,
    [theme.breakpoints.down('xs')]: {
      flexFlow: 'column'
    }
  },
  legendText: {
    marginLeft: 10,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0
    }
  },
  currentRating: {
    borderColor: COLORS.darkGray
  },
  legendTitle: {},
  legendDesc: {
    fontSize: 12,
    marginTop: 3
  },
  dropNotification: {
    backgroundColor: COLORS.white,
    padding: 5,
    paddingLeft: 8,
    display: 'flex',
    borderRadius: '0 0 3px 3px'
  },
  qDropIndicator: {
    color: COLORS.alert,
    width: 18,
    height: 18,
    marginRight: 5,
    minWidth: 18,
    [theme.breakpoints.down('xs')]: {
      marginTop: 2
    }
  },
  dropText: {
    fontSize: 13,
    lineHeight: '15px',
    marginTop: 3,
    color: COLORS.alert,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      lineHeight: '13px'
    }
  },
  qBarWrapper: {
    margin: 5,
    marginRight: 8,
    display: 'flex',
    padding: 0,
    marginLeft: 0
  },
  qSquare: {
    width: 20,
    height: 15,
    border: `1px solid ${COLORS.white}`
  }
}));

const QualityLegendItem = ({ nBars, probeQuality, i18n, light }) => {
  const degrees = useSelector((store) => store.sessionInfo?.ui_preferences?.degrees);
  let temperature = probeQuality?.lastTemp;
  if (probeQuality?.lastTemp && degrees === '°F') {
    temperature = celsiusToF(probeQuality.lastTemp);
  }

  const HDDperBars = [700, 600, 350, 200, 0]; // example HDD values for bars 1..5
  const titles = [
    i18n.quality_rate_title_1,
    i18n.quality_rate_title_2,
    i18n.quality_rate_title_3,
    i18n.quality_rate_title_4,
    i18n.quality_rate_title_5
  ];
  const texts = [
    i18n.quality_rate_desc_1,
    i18n.quality_rate_desc_2,
    i18n.quality_rate_desc_3,
    i18n.quality_rate_desc_4,
    i18n.quality_rate_desc_5
  ];
  const classes = useStyles();
  const nLevels = 5;
  const indices = Array.from(Array(nLevels).keys()); // [0,1,2,3,4]
  const barSquares = indices.map((i) => (
    <div
      style={{
        backgroundColor:
          i >= nBars ? COLORS.altoGray : getQualityColor(1, HDDperBars[nBars - 1]).color
      }}
      className={classes.qSquare}
      key={i}
    />
  ));
  return (
    <div
      style={{ opacity: probeQuality ? 1 : light ? 0.6 : 1 }}
      className={`${classes.legendBox} ${probeQuality ? classes.currentRating : ''}`}
    >
      <div className={classes.legendWrapper}>
        <div className={classes.qBarWrapper}>{barSquares}</div>
        <div className={classes.legendText} style={probeQuality && { fontWeight: 'bold' }}>
          <div className={classes.legendTitle}>{titles[nBars - 1]}</div>
          <div className={classes.legendDesc}>{texts[nBars - 1]}</div>
        </div>
      </div>

      {probeQuality && isQualityWarning(probeQuality) && (
        <div className={classes.dropNotification}>
          <Icon icon={warning} className={classes.qDropIndicator} />
          <Typography className={classes.dropText}>
            {i18nVariablesInjection(i18n.quality_drop_warning, {
              temperature,
              degrees,
              nDays: Math.round(probeQuality?.daysToDrop)
            })}
          </Typography>
        </div>
      )}
      {probeQuality && isQualityAlert(probeQuality) && (
        <div className={classes.dropNotification}>
          <Icon icon={alert} className={classes.qDropIndicator} />
          <Typography className={classes.dropText}>
            {i18nVariablesInjection(i18n.quality_drop_alert, {
              temperature,
              degrees,
              nHours: Math.round(probeQuality?.daysToDrop * 24)
            })}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default QualityLegendItem;
