import {
  Chip,
  Paper,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  Button,
  Divider,
  useMediaQuery,
  Dialog,
  Grow
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, Route } from 'react-router-dom';
// import { getBsConf } from 'api/serverAPI';
import { sub } from 'date-fns';
import DialogTitleWithClose from 'utils/DialogWithCloseIcon';
import { getDateFromStr } from 'utils/getDateFromStr';

// import { getUserInfo, postUserInfo } from 'api/serverAPI';
import { getMaxPlan, getPlanName, getPlanColor, getPlanDate, getServiceDate } from 'assets/utils';
import { COLORS } from 'utils/colors';
import { accountServicesStyles } from 'utils/sharedStyles';
import CheckoutContainer from './checkout/CheckoutContainer';
import { checkoutAvailabilityCheck } from './checkout/helpers';
import ServiceRenewal from './checkout/service-renewal/ServiceRenewal';
// import Checkout from './checkout/Checkout';

const AccountServices = () => {
  const classes = accountServicesStyles();
  const history = useHistory();

  const { i18n, userInfo, bsConf, dateFormat, insightService, isPlanExpired } = useSelector(
    (store) => ({
      i18n: store.i18n,
      userInfo: store.userInfo,
      bsConf: store.bsConf,
      insightService: store.insightService,
      dateFormat: store.sessionInfo?.ui_preferences?.date_format,
      isPlanExpired: store.isPlanExpired
    })
  );
  const plan = getMaxPlan(userInfo?.plans);

  const isFreeUser = !plan || plan.planLevel < 1;
  // const isPremiumUser = getPlanName(plan) === 'Quanturi Premium';

  const isXsWidth = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const [state, setState] = useState();
  const [renewalUnauthorizedService, setRenewalUnauthorizedService] = useState();
  const [renewalUnauthorizedPlan, setRenewalUnauthorizedPlan] = useState();
  const [renewalUnauthorizedSim, setRenewalUnauthorizedSim] = useState();
  const [renewalUnauthorizedAPI, setRenewalUnauthorizedAPI] = useState();
  const [unauthorized, setUnauthorized] = useState(false);

  // useEffect(() => {
  //   getBsConf({ basestationID: sim.bs_id });
  // }, [sim.bs_id]);
  useEffect(() => {
    if (userInfo) {
      const generatedPlan = getMaxPlan(userInfo.plans);
      setState((tempState) => ({
        ...tempState,
        plan: generatedPlan,
        service: userInfo.service,
        api: userInfo.api_plan,
        U_ID: userInfo.U_ID
      }));
    }
  }, [userInfo]);

  useEffect(() => {
    if (bsConf) {
      setState((tempState) => ({
        ...tempState,
        sim: { sim_exp_date: bsConf.sim_exp_date, simgroup: bsConf.simgroup },
        bs_id: bsConf.bs_id
      }));
    }
  }, [bsConf]);

  useEffect(() => {
    checkoutAvailabilityCheck(
      isFreeUser,
      state,
      renewalUnauthorizedService,
      setRenewalUnauthorizedService,
      renewalUnauthorizedPlan,
      setRenewalUnauthorizedPlan,
      renewalUnauthorizedSim,
      setRenewalUnauthorizedSim,
      renewalUnauthorizedAPI,
      setRenewalUnauthorizedAPI,
      setUnauthorized
    );
  }, [
    state,
    isFreeUser,
    renewalUnauthorizedPlan,
    renewalUnauthorizedSim,
    renewalUnauthorizedAPI,
    renewalUnauthorizedService
  ]);

  if (
    !state ||
    !userInfo ||
    typeof renewalUnauthorizedService === 'undefined' ||
    typeof renewalUnauthorizedPlan === 'undefined' ||
    typeof renewalUnauthorizedSim === 'undefined' ||
    typeof renewalUnauthorizedAPI === 'undefined'
  ) {
    return null;
  }

  const dueDateShortened = (state?.plan?.dueDate || state?.plan?.expired)?.substr(0, 8);

  // Handle upgrade/renew button clicking
  const handleClickUpgrade = (type) => {
    if (type === 'service') {
      history.push(`services/renewal`);
      return;
    }
    history.push(`services/checkout/?type=${type}`);
  };

  const simPayable = state.sim?.sim_exp_date && state.sim?.simgroup !== 'f';
  const simExpired =
    simPayable && getDateFromStr(state.sim?.sim_exp_date) < sub(new Date(), { days: 1 });

  const apiExpired = getDateFromStr(state.api?.due) < sub(new Date(), { days: 1 });

  // 6.5.24: FR Insight users not to anymore pay online but by invoice.
  const isFrenchInsight = userInfo.service && userInfo.country === 'fr';

  // Regarding service subscription renewal, please contact
  // Concernant le renouvellement du service, veuillez contacter
  const frTitle = `${i18n.msg_service_subscription} (Carte SIM)`;
  const frText = (
    <div>
      {i18n.msg_fr_pleasecontact}{' '}
      <a
        href={`mailto:support@quanturi.com?subject=${i18n.msg_service_subscription} (Carte SIM) - ${userInfo.bs_BS_IDs[0]}`}
      >
        support@quanturi.com
      </a>
      .
    </div>
  );

  const onCloseFrench = () => {
    history.replace('/account/services');
  };

  const frenchDialog = (
    <Dialog
      open
      maxWidth='md'
      PaperProps={{ style: { overflow: 'auto' } }}
      TransitionComponent={Grow}
    >
      <DialogTitleWithClose
        style={{ minWidth: 300 }}
        onClose={() => {
          onCloseFrench();
        }}
      >
        {frTitle}
      </DialogTitleWithClose>
      <div style={{ padding: 20, paddingBottom: 40 }}>{frText}</div>
    </Dialog>
  );

  return (
    <div className={classes.mainContainer}>
      <Paper component='div' className={classes.userList} elevation={isXsWidth ? 0 : 1}>
        <List>
          <ListSubheader component='div' disableSticky>
            {i18n.service_subscription}
          </ListSubheader>
          <ListItem className={classes.plan}>
            <ListItemText
              primary={i18n.service_plan}
              secondary={
                <Chip
                  component='span'
                  className={classes.chip}
                  label={getPlanName(state.plan, state.service)}
                  style={
                    isPlanExpired
                      ? { backgroundColor: COLORS.dimGray, textDecoration: 'line-through' }
                      : { backgroundColor: getPlanColor(state.plan, state.service) }
                  }
                  color={isPlanExpired ? 'secondary' : 'primary'}
                  variant='outlined'
                  disabled={!!state.expired}
                />
              }
            />
          </ListItem>
          <ListItem className={classes.valid}>
            <ListItemText
              classes={isPlanExpired ? { secondary: classes.secondary } : {}}
              primary={isPlanExpired ? i18n.expired : i18n.valid_until}
              secondary={
                state.service
                  ? getServiceDate(state.service, dateFormat, 1)
                  : isFreeUser
                  ? i18n.unlimited
                  : getPlanDate(state.plan, dateFormat, state.plan.expired ?? state.plan.dueDate) ||
                    i18n.unlimited
              }
            />

            {(!renewalUnauthorizedPlan || !renewalUnauthorizedService) && (
              <Button
                onClick={() => handleClickUpgrade(state.service ? 'service' : 'plan')}
                className={classes.upgradeButton}
                variant='contained'
                color='primary'
              >
                <span>{!isFreeUser || state.service ? i18n.renew_plan : i18n.upgrade}</span>
              </Button>
            )}
          </ListItem>
        </List>
        {simPayable && (
          <>
            <Divider />
            <List>
              <ListItem className={classes.plan}>
                <ListItemText primary={i18n.msg_sim_card_title} />
              </ListItem>
              <ListItem className={classes.valid}>
                <ListItemText
                  classes={simExpired ? { secondary: classes.secondary } : {}}
                  primary={simExpired ? i18n.expired : i18n.valid_until}
                  secondary={getPlanDate(state.sim, dateFormat, state.sim?.sim_exp_date)}
                />

                {!renewalUnauthorizedSim && state.sim.sim_exp_date !== dueDateShortened && (
                  <Button
                    onClick={() => handleClickUpgrade('sim')}
                    className={classes.upgradeButton}
                    variant='contained'
                    color='primary'
                  >
                    <span>{i18n.renew_sim}</span>
                  </Button>
                )}
              </ListItem>
            </List>
          </>
        )}
        {userInfo?.api_key && userInfo.api_plan && (
          <>
            <Divider />
            <List>
              <ListItem className={classes.plan}>
                <ListItemText primary={i18n.msg_api_card_title} />
              </ListItem>
              <ListItem className={classes.valid}>
                <ListItemText
                  classes={apiExpired ? { secondary: classes.secondary } : {}}
                  primary={apiExpired ? i18n.expired : i18n.valid_until}
                  secondary={getPlanDate(state, dateFormat, state.api?.due)}
                />

                {!renewalUnauthorizedAPI && state.api.due && (
                  <Button
                    onClick={() => handleClickUpgrade('api')}
                    className={classes.upgradeButton}
                    variant='contained'
                    color='primary'
                  >
                    <span>{i18n.renew_api}</span>
                  </Button>
                )}
              </ListItem>
              <ListItem className={classes.valid}>
                <ListItemText primary={i18n.api_key} secondary={userInfo?.api_key} />
              </ListItem>
            </List>
          </>
        )}
      </Paper>
      <Route
        path='/account/services/checkout/'
        render={() =>
          isFrenchInsight ? (
            frenchDialog
          ) : (
            <CheckoutContainer
              unauthorized={unauthorized}
              renewalUnauthorizedPlan={renewalUnauthorizedPlan}
              renewalUnauthorizedSim={renewalUnauthorizedSim}
              renewalUnauthorizedAPI={renewalUnauthorizedAPI}
              renewalInfo={state}
              isFreeUser={isFreeUser}
              insightService={insightService}
              // location={history.location}
            />
          )
        }
      />
      <Route
        path='/account/services/renewal'
        render={() => <ServiceRenewal unauthorized={renewalUnauthorizedService} />}
      />
    </div>
  );
};

export default AccountServices;
