import { fetchData } from 'navigation/MainNavigator/helper';

const getStoragesHelper = async (
  setLoadingStorages,
  setStoragesChanges,
  storagesChanges,
  bsId,
  dispatch,
  previousUpdateTS,
  userInfo,
  bsConf,
  isAdmin,
  setSelectedStorageUpdates,
  isDemoMode
) => {
  try {
    if (storagesChanges && userInfo) {
      console.log('getStoragesHelper');
      await fetchData(
        bsId,
        dispatch,
        previousUpdateTS,
        userInfo,
        bsConf,
        isAdmin,
        null,
        isDemoMode
      );
      if (setLoadingStorages) {
        setLoadingStorages(false);
      }
      setStoragesChanges(false);
      if (setSelectedStorageUpdates) {
        setSelectedStorageUpdates(true);
      }
    }
  } catch (error) {
    console.log('Error in getStorages: ', error);
    if (setLoadingStorages) {
      setLoadingStorages(false);
    }
    setStoragesChanges(false);
  }
};

export default getStoragesHelper;
