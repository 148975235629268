import { Icon } from '@material-ui/core';
import React, { useState } from 'react';
import pencilIcon from '@iconify/icons-mdi/pencil';
import { getHayType, storageTypeOptionsArray } from 'components/insight/helpers';
import { EditDialog } from 'utils/EditDialog';
import {
  StorageCapacitySelector,
  StorageTypeSelector
} from 'components/account/account-users/account-background/helpers';

const StoragePropertiesHay = ({
  i18n,
  dispatch,
  classes,
  classesEditable,
  selectedStorage,
  setSelectedStorage,
  setIsStorageEdited,
  surveyAnswers
}) => {
  // Type
  const [typeEditDialog, setTypeEditDialog] = useState(false);
  const storageTypeOptions = storageTypeOptionsArray(i18n);
  const [storageType, setStorageType] = useState(selectedStorage?.type ?? null);

  const typeHandler = (e, val) => {
    if (val !== null) {
      setStorageType(val);
    }
  };

  const onSaveType = () => {
    setSelectedStorage((tempStorage) => ({
      ...tempStorage,
      type: storageType
    }));
    setTypeEditDialog(false);
    setIsStorageEdited(true);
    dispatch({ type: 'SET_EDIT_CONFIRM_NEEDED', value: true });
  };

  const TypeEditDialog = (
    <EditDialog
      i18n={i18n}
      open={typeEditDialog}
      close={() => setTypeEditDialog(false)}
      value={storageType}
      setValue={setStorageType}
      onChange={typeHandler}
      onSave={onSaveType}
      text={i18n.type || 'type'}
      textAction={i18n.apply}
      type
      SpecialEditField={
        <StorageTypeSelector
          i18n={i18n}
          storageType={storageType}
          onChange={typeHandler}
          storageTypeOptions={storageTypeOptions}
        />
      }
    />
  );

  // Capacity
  const [capacityEditDialog, setCapacityEditDialog] = useState(false);
  const [storageCapacity, setStorageCapacity] = useState(selectedStorage?.capacity ?? 0);
  const { hayType, hayTypeUnit, balesPerYear, averageTonsPerYear } = surveyAnswers;
  const typeUnit = getHayType(hayType, hayTypeUnit);

  const maxCapacity = typeUnit === 'bales' ? balesPerYear : averageTonsPerYear;

  const onSaveCapacity = () => {
    setSelectedStorage((tempStorage) => ({
      ...tempStorage,
      capacity: storageCapacity
    }));
    setCapacityEditDialog(false);
    setIsStorageEdited(true);
    dispatch({ capacity: 'SET_EDIT_CONFIRM_NEEDED', value: true });
  };

  const capacityHandler = (e, val) => {
    setStorageCapacity(val);
  };

  const CapacityEditDialog = (
    <EditDialog
      i18n={i18n}
      open={capacityEditDialog}
      close={() => setCapacityEditDialog(false)}
      value={storageCapacity}
      setValue={setStorageCapacity}
      onChange={capacityHandler}
      onSave={onSaveCapacity}
      text={i18n.capacity || 'capacity'}
      textAction={i18n.apply}
      type
      SpecialEditField={
        <StorageCapacitySelector
          i18n={i18n}
          storageCapacity={storageCapacity}
          hayType={hayType}
          typeUnit={typeUnit}
          classes={classes}
          onChangeHandler={capacityHandler}
          maxCapacity={maxCapacity}
        />
      }
    />
  );

  return (
    <>
      <tr>
        <td className={classesEditable.rowTitle}>{i18n.type || 'Type'}</td>
        <td className={classesEditable.rowText}>
          <div
            className={`${classesEditable.editable} ${
              !selectedStorage.type && classesEditable.editablePlaceholder
            }`}
            style={{ maxWidth: 300 }}
            onClick={() => setTypeEditDialog(true)}
            onKeyDown={() => setTypeEditDialog(true)}
            role='presentation'
          >
            {storageTypeOptions.find((opt) => opt.id === selectedStorage.type)?.label ||
              i18n.add_type ||
              'Add type...'}
            <Icon className={classesEditable.editPen} icon={pencilIcon} />
          </div>
          {TypeEditDialog}
        </td>
      </tr>
      <tr>
        <td className={classesEditable.rowTitle}>{i18n.capacity || 'Capacity'}</td>
        <td className={classesEditable.rowText}>
          <div
            className={`${classesEditable.editable} ${
              !selectedStorage.capacity && classesEditable.editablePlaceholder
            }`}
            style={{ maxWidth: 300 }}
            onClick={() => setCapacityEditDialog(true)}
            onKeyDown={() => setCapacityEditDialog(true)}
            role='presentation'
          >
            {selectedStorage.capacity || i18n.add_capacity || 'Add capacity...'}
            <Icon className={classesEditable.editPen} icon={pencilIcon} />
          </div>
          {CapacityEditDialog}
        </td>
      </tr>
    </>
  );
};

export default StoragePropertiesHay;
