import { Tooltip } from '@material-ui/core';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import bellIcon from '@iconify/icons-mdi/bell';
import batteryOffOutline from '@iconify/icons-mdi/battery-off-outline';
import { Icon } from '@iconify/react';
import ThermometerSun from 'assets/svg/ThermometerSun';
import { COLORS } from 'utils/colors';

import {
  getGradientColor,
  getOpacity,
  getSignalLevel,
  tempFormat,
  isLowBattery,
  isNil,
  getDateString
} from 'assets/utils';
import SignalSVG from 'assets/svg/SignalSVG';

const multiTempBarHeight = 8;
const useStyles = makeStyles(() => ({
  listSensor: {
    width: '100%',
    minHeight: 30,
    maxHeight: 35,
    fontSize: 13,
    marginBottom: 6,
    boxSizing: 'border-box',
    borderRadius: 3,
    position: 'relative',
    backgroundColor: COLORS.altoGray,
    fontFamily: `'Roboto', sans-serif`,
    fontWeight: 400,
    // paddingRight: 0,
    border: '1px solid transparent',
    cursor: 'default',
    transition: 'all 200ms'
  },
  listSensorWithAdd: {
    borderRadius: '3px 3px 0 0'
  },
  monitorButton: {
    display: 'flex',
    position: 'relative'
  },
  hovered: {
    backgroundColor: `${COLORS.mediumGray} !important`,
    color: 'white !important'
  },
  isActive: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: `${COLORS.mediumGray} !important`,
      color: 'white !important'
    }
  },
  alerted: {
    borderColor: COLORS.alert
  },

  selectedSensor: {
    color: COLORS.white
  },
  removedSensor: {
    opacity: 0.4,
    cursor: 'default'
  },
  fullTemperatureBar: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    borderRadius: '3px 0px 0px 3px',
    zIndex: 90,
    maxWidth: '100%'
  },
  multiBarWrapper: {
    width: '100%',
    borderTop: '1px solid white',
    position: 'absolute',
    left: 0,
    height: multiTempBarHeight
  },
  handleWrapper: {
    width: '100%',
    position: 'absolute',
    left: 0,
    height: 5,
    borderBottom: '1px solid white',
    top: 0
  },
  multiTemperatureBar: {
    position: 'absolute',
    left: 0,
    height: multiTempBarHeight - 1,
    zIndex: 90,
    maxWidth: '100%',
    boxSizing: 'border-box'
  },
  depthAlert: {
    border: '1px solid #f44336',
    borderWidth: '1px 1px 1px 0px'
  },
  contentArea: {
    zIndex: 100,
    display: 'flex',
    width: '100%',
    textAlign: 'left',
    textTransform: 'initial',
    marginTop: 3
  },
  contentAreaWithHandle: {
    marginTop: 7
  },
  contentAreaWithHandleAndSensorName: {
    marginTop: 10
  },
  contentName: {
    width: '50%',
    paddingTop: 3,
    maxHeight: 20,
    zIndex: 100,
    marginLeft: 5,
    display: 'flex'
  },
  namedSensorId: {
    fontSize: 9,
    marginTop: -4,
    marginBottom: 1,
    zIndex: 100
  },
  sensorName: {
    marginTop: -3,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    zIndex: 100,
    position: 'absolute',
    maxWidth: '35%'
  },
  contentTemp: {
    width: 60,
    minWidth: 60,
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'right',
    paddingRight: 25,
    letterSpacing: -0.3,
    zIndex: 100,
    marginTop: 2,
    position: 'relative'
  },
  contentSignal: {
    width: 40,
    minWidth: 30,
    paddingTop: 3,
    zIndex: 100
  },
  contentUpdated: {
    width: 50,
    minWidth: 50,
    fontSize: 11,
    paddingTop: 4,
    zIndex: 100
  },
  over24h: {
    color: COLORS.alert
  },
  selectionOverlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 0,
    zIndex: 90,
    position: 'absolute',
    borderRadius: 4,
    backgroundColor: 'rgba(0,0,0,0.45)'
  },
  alertIcon: {
    color: COLORS.alert,
    width: 23,
    height: 23,
    position: 'absolute',
    right: -25,
    top: 2,
    zIndex: 200
  },
  noAddComp: {
    border: '1px solid transparent'
  },
  withAddComp: {
    marginBottom: 6,
    borderRadius: 4,
    border: '1px solid #ccc',
    backgroundColor: COLORS.whiteSmoke
  },
  withAddCompHover: {
    '&:hover': {
      borderColor: `${COLORS.mediumGray} !important`
    }
  },
  additionalComponent: {
    marginTop: -4,
    marginBottom: 4,
    marginLeft: 2
  },
  freeMonitor: {
    minWidth: 40,
    maxWidth: 40,
    padding: 0,
    marginLeft: 3
    // minHeight: 110,
    // maxHeight: 125
    // TODO: make the height match the sibling
  },
  noAlerts: {
    background: 'rgba(0, 0, 0, 0.07)'
  }
}));

const SensorListItem = ({
  sensorID,
  lastData,
  sensorConf,
  sensorClicked,
  isSelectedSensor,
  showMultipoint,
  showAlert,
  marginRight,
  alertDepths,
  showHandleBar,
  hoveredSensor,
  onSensorHover,
  additionalComponent, // to show sensor's placements in batches or storages
  isPassive, // no sensor hover & onClick
  isAux,
  i18n,
  isOutdoorID,
  isExpired
  // sensor,
}) => {
  const classes = useStyles();
  const degrees = useSelector((store) => store.sessionInfo?.ui_preferences?.degrees || '°C');
  const isAdmin = useSelector((store) => store.isAdmin);
  if (lastData && lastData.temperatures) {
    // In case of multipoint, display max temp
    const values = lastData.temperatures.slice(1).filter((el) => el !== null); // remove 1st value and possible nulls
    const maxTemp = Math.max.apply(null, values);
    if (Number.isFinite(maxTemp) && maxTemp !== lastData.temperature) {
      lastData.temperature = maxTemp;
    }
  }

  const getMultipointBarHeight = (datapoints, handle) => {
    const style = {
      minHeight: (handle ? 6 : 0) + 30 + (datapoints - 1) * multiTempBarHeight,
      maxHeight: 45 + (datapoints - 1) * multiTempBarHeight
    };
    return style;
  };

  const isRemovedSensor = !sensorConf && !lastData;

  const signalLevel = lastData
    ? getSignalLevel(lastData.sensor_RSSI, lastData.bs_RSSI, lastData.meas_ts)
    : null;

  const lowBattery = isLowBattery(lastData);

  const signalLabel =
    isAdmin && lastData
      ? `${lastData.sensor_RSSI}s / ${lastData.bs_RSSI}bs`
      : lowBattery
      ? i18n.low_battery || 'Battery low'
      : signalLevel === -2
      ? ''
      : signalLevel === -1
      ? i18n.no_signal
      : signalLevel === 0
      ? i18n.signal0
      : signalLevel === 1
      ? i18n.signal1
      : signalLevel === 2
      ? i18n.signal2
      : signalLevel === 3
      ? i18n.signal3
      : signalLevel === 4
      ? i18n.signal4
      : '';

  const multibarLengthLogic = lastData
    ? lastData.n > 4 || lastData.temperatures > 4
      ? Math.min(lastData.n, lastData.temperatures?.length)
      : lastData.n + 1 || lastData.temperatures?.length
    : null;

  let tempBars = null;
  let multipointStyle = null;
  const sensorNameWithHandle = showHandleBar && sensorConf?.sensor_name;
  if (!showMultipoint || !lastData || !lastData.temperatures) {
    // single-point sensors: full temp bar
    tempBars = (
      <div
        className={classes.fullTemperatureBar}
        style={{
          backgroundColor: lastData ? getGradientColor(lastData.temperature) : 'initial',
          opacity: lastData ? getOpacity(lastData.meas_ts * 1000) : 1,
          width:
            !lastData || isNil(lastData.temperature)
              ? 0
              : `${Math.min(100, Math.max(5, 10 + lastData.temperature))}%`,
          borderRadius: lastData && additionalComponent ? '3px 0 0 0' : '3px 0 0 3px'
        }}
      />
    );
  } else {
    multipointStyle = getMultipointBarHeight(
      lastData ? multibarLengthLogic : 0,
      sensorNameWithHandle
    );
    // multi-point sensors: temp bars of each point
    const handleBar =
      !showHandleBar || isAux ? null : (
        <div key={1} className={classes.handleWrapper}>
          <Tooltip
            title={
              lastData
                ? `${i18n.handle || 'Handle'}${i18n.colon}${tempFormat(
                    degrees,
                    lastData.temperatures[0]
                  )}${degrees}`
                : ''
            }
            enterDelay={500}
            enterNextDelay={1000}
            placement='right'
            disableFocusListener
          >
            <div
              className={classes.multiTemperatureBar}
              style={{
                height: 5,
                top: 0,
                backgroundColor: COLORS.warning,
                // backgroundColor: getGradientColor(lastData.temperatures[0]),
                opacity: lastData ? getOpacity(lastData.meas_ts * 1000) : 1,
                width: lastData
                  ? `${Math.min(100, Math.max(10, 10 + lastData.temperatures[0]))}%`
                  : 0,
                borderRadius: '3px 0px 0px 0px'
              }}
            />
          </Tooltip>
        </div>
      );

    const multibars = !lastData
      ? null
      : lastData.temperatures.slice(1, multibarLengthLogic).map((temp, index) => (
          <div
            key={index}
            className={classes.multiBarWrapper}
            style={{
              bottom: multiTempBarHeight * (multibarLengthLogic - 2 - index)
            }}
          >
            <Tooltip
              title={`${isAux ? i18n.depth_title : ''} ${index + 1}${isAux ? '' : ' m'}${
                i18n.colon
              }${tempFormat(degrees, lastData.temperatures[index + 1])}${degrees}`}
              enterDelay={100}
              placement='right'
              disableFocusListener
            >
              <div
                key={index}
                className={[
                  classes.multiTemperatureBar,
                  alertDepths && alertDepths.find((d) => d === index + 1)
                    ? classes.depthAlert
                    : null
                ].join(' ')}
                style={{
                  // bottom: 0 - 5 * index,
                  backgroundColor: getGradientColor(lastData.temperatures[index + 1]),
                  opacity: getOpacity(lastData.meas_ts * 1000),
                  width: isNil(lastData.temperatures[index + 1])
                    ? 0
                    : `${Math.min(100, Math.max(5, 10 + lastData.temperatures[index + 1]))}%`,
                  borderRadius:
                    index === multibarLengthLogic - 2 && !additionalComponent
                      ? '0px 0px 0px 3px'
                      : 0
                }}
              />
            </Tooltip>
          </div>
        ));
    tempBars = (
      <>
        {handleBar}
        {multibars}
      </>
    );
  }
  const [isHovered, setHovered] = useState(false);

  const alertIndicator = showAlert ? (
    <div className={classes.alertIcon} style={{ right: -25 }}>
      <Icon width={15} height={15} icon={bellIcon} />
    </div>
  ) : null;

  return (
    <div
      id={`probeListItem-${sensorID}`}
      className={
        additionalComponent
          ? isPassive
            ? classes.withAddComp
            : [classes.withAddComp, classes.withAddCompHover].join(' ')
          : classes.noAddComp
      }
      style={{
        marginRight: marginRight || 0,
        display: 'block'
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start'
        }}
      >
        <div
          aria-hidden='true' // enables onClick for div
          onClick={() => {
            if (!isPassive && !isRemovedSensor) {
              sensorClicked(sensorID);
            }
          }}
          onMouseEnter={() => {
            setHovered(true);
            if (onSensorHover) {
              onSensorHover(sensorID, true);
            }
          }}
          onMouseLeave={() => {
            setHovered(false);
            if (onSensorHover) {
              onSensorHover(sensorID, false);
            }
          }}
          style={multipointStyle}
          className={[
            classes.listSensor,
            additionalComponent ? classes.listSensorWithAdd : null,
            !isPassive ? classes.isActive : null,
            hoveredSensor && hoveredSensor === sensorID ? classes.hovered : null,
            isSelectedSensor ? classes.selectedSensor : null,
            isRemovedSensor ? classes.removedSensor : null,
            showAlert ? classes.alerted : null,
            alertDepths && alertDepths.filter((d) => !!d).length > 0 ? classes.alerted : null
          ].join(' ')}
        >
          {alertIndicator}
          {isRemovedSensor || isExpired ? null : tempBars}
          <div
            className={[
              classes.contentArea,
              showHandleBar && !!multipointStyle && !isAux
                ? sensorNameWithHandle
                  ? classes.contentAreaWithHandleAndSensorName
                  : classes.contentAreaWithHandle
                : ''
            ].join(' ')}
          >
            <div className={classes.contentName}>
              {isOutdoorID && (
                <ThermometerSun
                  style={{
                    float: 'left'
                  }}
                  color={COLORS.black}
                />
              )}
              {sensorConf && sensorConf.sensor_name ? (
                <div>
                  <div className={classes.namedSensorId}>{sensorID}</div>
                  <div
                    style={isOutdoorID && marginRight ? { maxWidth: '25%' } : {}}
                    className={classes.sensorName}
                  >
                    {sensorConf.sensor_name}
                  </div>
                </div>
              ) : (
                <div style={isRemovedSensor ? { textDecoration: 'line-through' } : {}}>
                  {sensorID}
                </div>
              )}
            </div>
            <div className={classes.contentTemp}>
              {lastData && !isExpired
                ? `${tempFormat(degrees, lastData?.temperature)} ${degrees}`
                : null}
            </div>
            {!lastData || isExpired ? null : (
              <Tooltip
                title={signalLabel}
                enterDelay={500}
                enterNextDelay={1000}
                placement='left'
                disableFocusListener
              >
                <div
                  className={classes.contentSignal}
                  style={{
                    opacity: lastData
                      ? !isPassive &&
                        (lowBattery ||
                          isSelectedSensor ||
                          isHovered ||
                          (hoveredSensor && hoveredSensor === sensorID))
                        ? 1
                        : getOpacity(lastData.meas_ts * 1000)
                      : 1
                  }}
                >
                  {lowBattery ? (
                    <Icon
                      color={
                        !isPassive &&
                        (isSelectedSensor ||
                          isHovered ||
                          (hoveredSensor && hoveredSensor === sensorID))
                          ? COLORS.white
                          : COLORS.alert
                      }
                      width={16}
                      icon={batteryOffOutline}
                    />
                  ) : signalLevel < -1 ? null : (
                    <SignalSVG
                      level={signalLevel}
                      i18n={i18n}
                      alertColor={isSelectedSensor || isHovered ? COLORS.white : COLORS.alert}
                      color={
                        !isPassive &&
                        (isSelectedSensor ||
                          isHovered ||
                          (hoveredSensor && hoveredSensor === sensorID))
                          ? COLORS.white
                          : COLORS.primaryColor
                      }
                      style={{
                        width: 18,
                        opacity: lastData ? getOpacity(lastData.meas_ts * 1000, 2) : 1
                      }}
                    />
                  )}
                </div>
              </Tooltip>
            )}
            {!isExpired && (
              <div
                className={
                  lastData && Number(lastData.meas_ts) < +new Date() / 1000 - 24 * 60 * 60
                    ? [classes.over24h, classes.contentUpdated].join(' ')
                    : classes.contentUpdated
                }
                style={{
                  color:
                    !isPassive && (isHovered || (hoveredSensor && hoveredSensor === sensorID))
                      ? COLORS.white
                      : ''
                }}
              >
                {lastData ? getDateString(lastData.meas_ts * 1000, i18n) : ''}
              </div>
            )}
          </div>
          {isSelectedSensor ? <div className={classes.selectionOverlay} /> : null}
        </div>
      </div>

      {additionalComponent ? (
        <div className={classes.additionalComponent}>{additionalComponent}</div>
      ) : null}
    </div>
  );
};

export default SensorListItem;
