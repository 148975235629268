import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Radio,
  RadioGroup,
  FormControlLabel
} from '@material-ui/core';
import React, { useState } from 'react';
import ReactCountryFlag from 'react-country-flag';

import { useDialogStyles } from './Login.styled';

const RadioItem = ({ locale, code, name }) => (
  <FormControlLabel
    value={locale}
    control={<Radio />}
    label={
      <div>
        <ReactCountryFlag
          className='emojiFlag'
          countryCode={code}
          style={{ marginRight: '10px', fontSize: '15px' }}
          svg
          cdnUrl='https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/'
          cdnSuffix='svg'
        />
        {name}
      </div>
    }
  />
);

const ChangeLanguageDialog = ({
  i18n,
  locale,
  isLanguageDialogOpened,
  toggleLanguageDialog,
  handleChangeLanguage,
  supportedLanguages
}) => {
  const [selectedLocale, setSelectedLocale] = useState(locale);
  const classes = useDialogStyles();

  const onCancel = () => {
    setSelectedLocale(locale);
    toggleLanguageDialog();
  };

  const onChange = (e) => setSelectedLocale(e.target.value);

  const changeLanguage = () => {
    const lang = supportedLanguages.find((l) => l.locale === selectedLocale);
    handleChangeLanguage(lang);
  };

  return (
    <Dialog open={isLanguageDialogOpened} aria-labelledby='responsive-dialog-title'>
      <DialogTitle id='language'>{i18n.select_your_language}</DialogTitle>
      <Divider />

      <DialogContent className={classes.dialogContent} elevation={0}>
        <RadioGroup
          aria-label='language'
          name='language'
          value={selectedLocale}
          onChange={onChange}
        >
          {supportedLanguages.map(({ locale: lang, code, name }) => (
            <RadioItem key={lang} locale={lang} code={code} name={name} />
          ))}
        </RadioGroup>
      </DialogContent>

      <Divider />
      <DialogActions>
        <Button onClick={onCancel} color='primary'>
          {i18n.cancel}
        </Button>
        <Button onClick={changeLanguage} color='secondary'>
          {i18n.ok}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeLanguageDialog;
