import React from 'react';

import thermometer from '@iconify/icons-mdi/thermometer';
import thermometerChevronUp from '@iconify/icons-mdi/thermometer-chevron-up';
import thermometerChevronDown from '@iconify/icons-mdi/thermometer-chevron-down';
import { Icon } from '@iconify/react';
import { useSelector } from 'react-redux';
import { getGradientColor, getOpacity, tempFormat, isNil } from 'assets/utils';
import { COLORS } from 'utils/colors';

const TempIndicators = ({ classes, minTemp, maxTemp, minTS, maxTS, phase }) => {
  const degrees = useSelector((store) => store.sessionInfo?.ui_preferences?.degrees);

  const noMinTemp = isNil(minTemp) || minTemp === -Infinity || minTemp === Infinity;
  const noMaxTemp = isNil(maxTemp) || maxTemp === -Infinity || maxTemp === Infinity;

  const maxTempColor = noMaxTemp
    ? COLORS.warning
    : `rgba(${getGradientColor(maxTemp).split('(')[1].split(')')[0]},1)`;
  const minTempColor = noMinTemp
    ? COLORS.warning
    : `rgba(${getGradientColor(minTemp).split('(')[1].split(')')[0]},1)`;
  const minTempOpacity = isNil(minTS) || noMinTemp ? 1 : getOpacity(minTS * 1000);
  const maxTempOpacity = isNil(maxTS) || noMaxTemp ? 1 : getOpacity(maxTS * 1000);

  const minTempStyle =
    // if only 1 sensor or no data, show only current max temp (as it is same as min temp)
    phase?.sensor_progress?.length === 1 || noMinTemp
      ? { opacity: 0 }
      : { opacity: minTempOpacity, backgroundColor: minTempColor };

  const maxTempStyle = { opacity: maxTempOpacity, backgroundColor: maxTempColor };

  return (
    <div className={classes.tempsPanel}>
      <div className={classes.tempValues}>
        <div style={maxTempStyle} className={classes.tempIndicator}>
          <Icon
            className={classes.tempIconMax}
            icon={
              phase?.sensor_progress?.length === 1 || noMinTemp ? thermometer : thermometerChevronUp
            }
          />
          <div className={classes.tempFigure}>
            {noMaxTemp ? '- - -' : tempFormat(degrees, maxTemp)} {degrees}
          </div>
        </div>
        <div style={minTempStyle} className={classes.tempIndicator}>
          <Icon className={classes.tempIconMin} icon={thermometerChevronDown} />
          <div className={classes.tempFigure}>
            {noMinTemp ? '- - -' : tempFormat(degrees, minTemp)} {degrees}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TempIndicators;
