import { CircularProgress } from '@material-ui/core';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { useLocation } from 'react-router-dom';

import { generateTimeline } from 'assets/utils';
import { getSensorDataFromAPI } from 'components/probes/sensor/sensor-chart/helper';
import SensorDataTable from './SensorDataTable';
import SensorDataTableFilterDialog from './SensorDataTableFilterDialog';
import SensorDataTableContext from './SensorDataTableContext';
import { getDataForTable } from './helper.js';
import { checkProbeType } from '../sensor-chart/sensor-chart-type/helpers';
import { useStyles } from './SensorData.styled';

export default function SensorData() {
  const location = useLocation();

  const classes = useStyles();
  const dispatch = useDispatch();
  const { promiseInProgress } = usePromiseTracker();

  const {
    // sessionInfo: { bs_ids: bsIds },
    bsID,
    username,
    sensorDetails: { id: sensorId },
    dataTable,
    dataTableConfig: { admin, filter: filterFromStore },
    chartConfig,
    chartData: { isSnapShot, tempSensorData, snapshots },
    degrees,
    auxConf,
    auxIDuser,
    handleTempEnabled,
    showHandle,
    dateFormat,
    isAdmin,
    isSupervisorCompost,
    isDemoMode,
    sensorConfigurations
  } = useSelector((state) => ({
    // sessionInfo: state.sessionInfo,
    bsID: state.baseStation.bs_BS_ID,
    username: state.sessionInfo?.username,
    sensorDetails: state.sensorDetails,
    dataTable: state.dataTable,
    dataTableConfig: state.dataTableConfig,
    chartConfig: state.chartConfig,
    chartData: state.chartData,
    degrees: state.sessionInfo?.ui_preferences?.degrees ?? '°C',
    auxConf: state.auxConf,
    auxIDuser: state.userInfo?.auxDataIDs ? state.userInfo.auxDataIDs[0]?.id : null,
    handleTempEnabled: state.sessionInfo?.ui_preferences?.handle_temp_enabled === 'true',
    showHandle: state.showHandle,
    dateFormat: state.sessionInfo?.ui_preferences?.date_format ?? 'd.MM.yyyy',
    isAdmin: state.isAdmin,
    isSupervisorCompost: state.isSupervisorCompost,
    isDemoMode: state.isDemoMode,
    sensorConfigurations: state.sensorConfigurations
  }));

  const mikes = username === 'mikesTEST';
  const [filter, setFilter] = useState({ ...filterFromStore });
  const [countDuplicate, setCountDuplicate] = useState(null);
  const [data, setData] = useState([]);
  const [isConfigChanged, setIsConfigChanged] = useState(false);
  const [isFilterDialogOpened, setIsFilterDialogOpened] = useState(false);

  const sensorDet = useSelector((state) => state.sensorDetails);

  const sensorPath = location.pathname.split('/');
  const id = sensorPath[sensorPath.length - 1];
  const auxProbe = auxConf?.find((aux) => aux.a === id);

  const setAdmin = (val) => dispatch({ type: 'SET_DATATABLECONFIG', value: { admin: val } });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dataTableMemo = useMemo(() => dataTable, [sensorId]);

  const checkSameChartConfig = useCallback(() => {
    const { date } = chartConfig;
    const { fromDate, toDate } = filterFromStore;
    return isSnapShot === false && date[0] === fromDate && date[1] === toDate;
  }, [isSnapShot, chartConfig, filterFromStore]);

  const [dataGradient, setDataGradient] = useState(null);

  useEffect(() => {
    const fetchSensorData = async () => {
      // If virtualBS, see real BS from bsConf
      const realBs = sensorConfigurations?.find((s) => s.sensor_id_sys === sensorId)?.realBs;
      if ((realBs || bsID) && sensorId) {
        let returnData;
        if (isConfigChanged || !dataTableMemo.sensorDataTable) {
          const { fromDate, toDate } = filter;
          const sensorData = (
            checkSameChartConfig()
              ? tempSensorData
              : await trackPromise(
                  getSensorDataFromAPI(
                    realBs || bsID,
                    sensorId,
                    fromDate,
                    toDate,
                    false,
                    auxProbe,
                    auxIDuser,
                    isDemoMode
                  )
                )
          ).filter((dat) => dat.b >= fromDate / 1000);
          const stype = checkProbeType({ dataCheck: sensorData[0] });
          returnData = getDataForTable(sensorData, sensorDet, stype, degrees, admin || mikes);

          dispatch({
            type: 'SET_DATATABLE',
            sensorDataTable: data.sensorDataTable,
            countDuplicate: data.countDuplicate
          });
        } else {
          returnData = dataTableMemo;
        }
        setCountDuplicate(returnData.countDuplicate);
        setData(returnData.sensorDataTable);
      }
    };
    fetchSensorData();
    setDataGradient(generateTimeline(snapshots));
  }, [
    bsID,
    sensorId,
    filter,
    isConfigChanged,
    checkSameChartConfig,
    tempSensorData,
    dataTableMemo,
    dispatch,
    data.sensorDataTable,
    data.countDuplicate,
    degrees,
    admin,
    snapshots,
    auxIDuser,
    auxProbe,
    dateFormat,
    mikes,
    isDemoMode,
    sensorDet,
    sensorConfigurations
  ]);
  const handleConditions =
    showHandle && handleTempEnabled && !auxProbe && (isAdmin || !isSupervisorCompost);

  return (
    <div className={classes.mainContainer}>
      {promiseInProgress ? (
        <div className={classes.dataOverlay}>
          <CircularProgress
            className={classes.dataSpinner}
            color='primary'
            size={70}
            disableShrink
          />
        </div>
      ) : (
        <SensorDataTableContext.Provider
          value={{
            filter,
            setFilter,
            countDuplicate,
            admin: admin || mikes,
            setAdmin,
            setIsConfigChanged,
            isFilterDialogOpened,
            setIsFilterDialogOpened
          }}
        >
          <SensorDataTable data={data} showHandle={handleConditions} />
          {isFilterDialogOpened && <SensorDataTableFilterDialog gradient={dataGradient} />}
        </SensorDataTableContext.Provider>
      )}
    </div>
  );
}
