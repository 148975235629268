// PUBLIC IMPORTS
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { format } from 'date-fns';

import { getMobileTelemetry } from 'api/serverAPI';

import { useStyles } from './BaseStation.styled';

const TelemetryLogDialog = ({ BS_ID, setTelemetryIsOpen }) => {
  const classes = useStyles();

  const [telemetryLog, setTelemetryLog] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('No mobile network data');
  const limit = 100;

  useEffect(() => {
    const getOverviewData = async () => {
      try {
        const response = await getMobileTelemetry({ BS_ID, limit });
        if (!response.status_code) {
          setTelemetryLog(response);
          setLoading(false);
          setMessage(
            response && response.length < limit
              ? isEmpty(response)
                ? 'No mobile network data'
                : `${response.length} calls`
              : `Last ${limit} calls`
          );
        } else {
          setMessage(`Error status: ${response.status_code}`);
          setLoading(false);
        }
      } catch (err) {
        setMessage(`Error in getting telemetry: ${err.message}`);
        setLoading(false);
      }
    };

    getOverviewData();
  }, [BS_ID]);

  return (
    <Dialog open aria-labelledby='responsive-dialog-title'>
      <DialogTitle>{`${BS_ID} Mobile network log`}</DialogTitle>
      <DialogContent className={classes.dialogContent} elevation={0}>
        <Grid container justifyContent='space-around'>
          {loading ? (
            'Loading...'
          ) : telemetryLog && !isEmpty(telemetryLog) ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start'
                // width: '100%'
                // marginLeft: 10,
                // marginTop: 10
              }}
            >
              <div style={{ marginBottom: 5, fontSize: 13, fontWeight: 'bold' }}>{message}</div>
              <div
                className={classes.mobileInfo}
                style={{
                  fontSize: 12,
                  margin: 0,
                  cursor: 'default',
                  maxHeight: 300,
                  overflowY: 'auto',
                  color: '#252525'
                }}
              >
                {telemetryLog
                  // ?.sort((d1, d2) => (d2.date > d1.date ? 1 : -1))
                  .map((tele, i) => (
                    <div
                      style={{
                        whiteSpace: 'nowrap',
                        display: 'flex',
                        fontSize: 12,
                        borderBottom: '1px solid #ccc'
                      }}
                      key={i}
                    >
                      <table>
                        <tbody>
                          <tr>
                            <td style={{ padding: '5px 10px', borderRight: '1px solid #ccc' }}>
                              {format(new Date(tele.ts * 1000), 'd.M.yyyy HH:mm')}
                            </td>
                            <td style={{ padding: '5px 10px', borderRight: '1px solid #ccc' }}>
                              {tele.operator}
                            </td>
                            <td style={{ padding: '5px 10px', borderRight: '1px solid #ccc' }}>
                              {tele.net}
                            </td>
                            <td style={{ padding: '5px 10px' }}>{tele.mobile_rssi}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ))}
              </div>
            </div>
          ) : (
            <div>{message}</div>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setTelemetryIsOpen(false)} color='primary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TelemetryLogDialog;
