import { Toolbar, IconButton, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { CloudDownload, CalendarToday, MoreHoriz } from '@material-ui/icons';
import { SpeedDial, SpeedDialIcon, SpeedDialAction } from '@material-ui/lab';
import microsoftExcel from '@iconify/icons-mdi/microsoft-excel';
import fileTableOutline from '@iconify/icons-mdi/file-table-outline';
import { Icon } from '@iconify/react';

import { COLORS } from 'utils/colors';
import SensorDataTableContext from './SensorDataTableContext';
import { parseData, parseCSV } from './helper';
import { useStyles } from './SensorDataTableToolbar.styled';

// import XLSX from 'xlsx';
const XLSX = require('xlsx');

const SensorDataTableToolbar = ({ data, columns }) => {
  const classes = useStyles();
  const i18n = useSelector((store) => store.i18n);

  const dateFormat = useSelector(
    (store) => store.sessionInfo?.ui_preferences?.date_format ?? 'd.MM.yyyy'
  );

  const { filter, setIsFilterDialogOpened } = useContext(SensorDataTableContext); // admin

  const fromDate = format(filter.fromDate, dateFormat);
  const toDate = format(filter.toDate, dateFormat);

  const [open, setOpen] = React.useState(false);

  const actions = [
    {
      icon: (
        <Icon
          icon={microsoftExcel}
          width='25px'
          height='25px'
          style={{ color: COLORS.darkGreen }}
        />
      ),
      type: 'excel',
      name: i18n.download_excel
    },
    {
      icon: (
        <Icon
          icon={fileTableOutline}
          width='25px'
          height='25px'
          style={{ color: COLORS.primaryColor }}
        />
      ),
      type: 'csv',
      name: i18n.download_csv
    }
  ];

  const getDataFileName = () => `${data && data[0] ? data[0].id : 'Data'}_${fromDate}-${toDate}`;

  const exportExcelFile = (csv) => {
    // Add name column if probe has name
    if (data && data[0] && data[0].name) {
      // Remove name column
      columns.find((c) => c.name === 'Name').show = true;
    }

    const filteredColumns = columns.filter((column) => column.show).map((col) => col.accessor);
    let exportData;
    if (!csv) {
      exportData = [
        columns.filter((column) => column.show).map((column) => column.Header),
        ...parseData(data, filteredColumns)
      ];

      const wb = XLSX.utils.book_new();
      const wsAll = XLSX.utils.aoa_to_sheet(exportData);

      XLSX.utils.book_append_sheet(
        wb,
        wsAll,
        `${data && data[0] ? data[0].id : i18n.data_table || 'Data'}`
      );
      XLSX.writeFile(wb, `${getDataFileName()}.xlsx`);
    } else {
      const dataCSV = parseCSV(data, filteredColumns);
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(dataCSV);

      XLSX.utils.book_append_sheet(
        wb,
        ws,
        `${data && data[0] ? data[0].id : i18n.data_table || 'Data'}`
      );
      XLSX.writeFile(wb, `${getDataFileName()}.csv`);
    }
  };

  const onDownloadHandler = (actionType) => {
    if (actionType === 'csv') {
      exportExcelFile(actionType);
    }
    if (actionType === 'excel') {
      exportExcelFile();
    }
    setOpen(false);
  };

  return (
    <Toolbar role='toolbar' aria-label='Table Toolbar'>
      <div className={classes.left}>
        <Typography variant='h6' className={classes.title}>
          {`${fromDate} - ${toDate}`}
          <IconButton
            aria-label='Print'
            classes={{ root: classes.icon }}
            onClick={() => setIsFilterDialogOpened(true)}
          >
            <CalendarToday />
          </IconButton>
        </Typography>
      </div>

      <div className={classes.actions}>
        <SpeedDial
          ariaLabel='download'
          className={classes.speedDial}
          icon={
            <SpeedDialIcon
              icon={<CloudDownload height='25px' width='25px' />}
              openIcon={<MoreHoriz height='25px' width='25px' />}
            />
          }
          direction='left'
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              tooltipPlacement='bottom'
              onClick={() => onDownloadHandler(action.type)}
            />
          ))}
        </SpeedDial>
      </div>
    </Toolbar>
  );
};

export default SensorDataTableToolbar;
