import { TextField } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LoginBackground from 'assets/images/LoginBackground.png';
import { COLORS } from 'utils/colors';

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: `url(${LoginBackground})`,
    backgroundSize: 'cover'
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  buttonLower: {
    width: 100,
    textTransform: 'capitalize',
    height: 45,
    marginTop: '20%'
  }
}));

export const StyledTextField = withStyles({
  root: {
    backgroundColor: COLORS.white,
    borderRadius: '5px'
  }
})(TextField);

export const useDialogStyles = makeStyles((theme) => ({
  dialogContent: {
    paddingTop: 10,
    width: 300,
    [theme.breakpoints.down('xs')]: {
      minWidth: 0
    }
  },
  dialogMessage: {
    marginTop: 10,
    marginBottom: 10
  },
  dialogPasswordMessage: {
    marginTop: 50,
    marginBottom: 30
  },
  resetView: {
    overflow: 'hidden'
  }
}));
