import { SET_ADMIN_BS } from 'redux/constants';
import initialState from 'redux/store/initialState';

export default function setAdminBS(state = initialState.adminBS, action) {
  switch (action.type) {
    case SET_ADMIN_BS: {
      return action.value;
    }
    default: {
      return state;
    }
  }
}
