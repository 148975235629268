import { Fab, Button } from '@material-ui/core';
import React, { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { useSelector } from 'react-redux';
import MultiBackend, { TouchTransition } from 'react-dnd-multi-backend';
import { Icon } from '@iconify/react';
import plus from '@iconify/icons-mdi/plus';
import minus from '@iconify/icons-mdi/minus';
import closeBoxMultipleOutline from '@iconify/icons-mdi/close-box-multiple-outline';

import { makeStyles } from '@material-ui/core/styles';
import { getSensorType, formatFloor, formatSensorRow } from 'assets/utils';
import { COLORS } from 'utils/colors';
import LayerCell from './LayerCell';
import SensorListDroppable from './SensorListDroppable';

const bgColor = COLORS.white; // '#eeffee';
const MAX_STACKS = 10;
const MAX_COLS = 20;
const MAX_FLOORS = 10;
const MAX_CELL_SIZE = 80;
const BOTTOM_MARGIN = 50;
const SENSORS_HEIGHT = 65;

const useStyles = makeStyles((theme) => ({
  layoutWrapper: {},
  layoutWrapperDisabled: {
    opacity: 0.6
  },
  row: {
    display: 'flex',
    // height: 50,
    // padding: '25px 30px 45px',
    boxSizing: 'content-box',
    transition: 'all 200ms'
  },
  rowLabel: {
    fontSize: 11,
    textAlign: 'center',
    cursor: 'default',
    minWidth: 16,
    maxWidth: 16,
    background: '#aaaaaaaa',
    boxSizing: 'content-box',
    border: '1px solid white'
  },
  rowLabelMargin: {
    minWidth: 16,
    boxSizing: 'border-box',
    margin: 1
  },
  sliceContainer: {
    display: 'flex',
    transition: 'all 300ms'
  },
  rowSegment: {
    position: 'relative',
    width: 40
  },
  canvas: {
    width: '100%',
    // overflow: 'auto',
    position: 'relative',
    display: 'inline-block',
    overflow: 'auto'
  },
  depthLayer3D: {
    position: 'absolute',
    height: 70, // 3rows @ 0.4
    marginLeft: 50,
    marginRight: 50,
    width: 'min-content',
    transition: 'all 200ms',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0
    }
  },
  perspectiveLayer: {
    transformStyle: 'preserve-3d'
  },
  buttonIcon: {
    marginRight: 5
  },

  controlsPanel: {
    width: 'fit-content',
    height: 'inherit',
    padding: '10px 15px 0',
    backgroundColor: COLORS.whiteSmoke
  },
  dimensionButton: {
    color: COLORS.white,
    width: 37,
    minWidth: 37,
    height: 30
  },
  dimensionControl: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 20,
    paddingTop: 10
  },
  dimensionLabels: {
    textTransform: 'capitalize',
    lineHeight: '35px',
    whiteSpace: 'nowrap',
    padding: '0 15px',
    fontSize: 14
  },
  stackLabel: {
    position: 'absolute',
    color: 'rgba(0,0,0,0.4)',
    left: -25,
    zIndex: 100
  },
  stackLabelLetter: {
    fontSize: 30,
    fontWeight: 'bold'
  },
  stackLabelText: {
    fontSize: 9,
    textAlign: 'center',
    marginTop: -7
  }
}));

const HTML5toTouch = {
  backends: [
    {
      backend: HTML5Backend
    },
    {
      backend: TouchBackend, // Note that you can call your backends with options
      preview: true,
      transition: TouchTransition,
      skipDispatchOnTransition: true
    }
  ]
};

const StorageStackLayout = ({
  viewHeight,
  theLayout,
  data,
  bsConf,
  auxConf,
  isEditing,
  angle,
  cellWidth,
  spacingFactor,
  isActive,
  onLayoutChange,
  onSensorChange,
  onSensorClicked,
  tempScale,
  alertSensorDepths,
  selectedSensor,
  hoveredSensor,
  onSensorHover,
  leftPadding,
  sensorIDsInOtherStorages,
  storageType
}) => {
  const i18n = useSelector((state) => state.i18n);
  const isDemoMode = useSelector((state) => state.isDemoMode);
  const classes = useStyles();

  // Do not show sensorIDsInOtherStorages as available sensors
  let allSensors = bsConf?.sensor_configurations
    ?.filter((sen) => sensorIDsInOtherStorages?.indexOf(sen.sensor_id_sys) < 0)
    ?.map((sen) => {
      let sensorPoints = 1;
      if (data) {
        const lastMeas = data.find((meas) => meas.sensor_id === sen.sensor_id_sys);
        if (lastMeas && lastMeas.temperatures) {
          sensorPoints = lastMeas.n ? Number(lastMeas.n) + 1 : lastMeas.temperatures.length;
        }
      }
      return {
        id: sen.sensor_id_sys,
        type: getSensorType(sen.sensor_id_sys, sensorPoints, isDemoMode),
        points: sensorPoints,
        row: -1,
        column: -1
      };
    });

  // Include possible auxConf sensors that are not in other storages
  if (auxConf) {
    allSensors = allSensors
      .concat(
        auxConf.map((aux) => {
          const auxItem = {
            id: aux.a,
            type: 'A',
            points: aux.hh.length,
            row: -1,
            column: -1
          };
          return auxItem;
        })
      )
      .filter((aux) => sensorIDsInOtherStorages?.indexOf(aux.id) < 0);
  }

  // theLayout.sensors have locations of storage sensors. Set the locations in allSensors respectively
  allSensors = allSensors?.map((aSensor) => {
    const layoutSensor = theLayout?.sensors?.find((sen) => sen.id === aSensor.id);
    if (layoutSensor) {
      layoutSensor.type = aSensor.type;
      layoutSensor.points = aSensor.points;
    }
    return layoutSensor || aSensor;
  });

  const [multiBSsensors] = useState(
    theLayout?.sensors?.filter((s) => !allSensors.map((sen) => sen.id).includes(s.id))
  );

  // Prevent from using Outdoor ID in a layout
  // storage.layoutType === 3: 3D storage for Haytech probes
  // otherwise it is a flat storage for Tango
  allSensors = allSensors?.filter((sensor) =>
    storageType === 3 ? sensor.type === 'H' : sensor.type !== 'H'
  );

  // console.log('3 theLayout rows: ', theLayout.rows);
  const [rowArray, setRowArray] = useState(theLayout.rows);
  const [sensorArray, setSensorArray] = useState(allSensors.concat(multiBSsensors));
  const [floorCount, setFloorCount] = useState(theLayout.floors);

  const [lastRowFade, setLastRowFade] = useState(false);
  const [lastColFade, setLastColFade] = useState(false);
  const [lastFloorFade, setLastFloorFade] = useState(false);

  const dimRemovableItem = (fade, itemType) => {
    // itemType: 'stacks', 'columns', 'floors'
    if (itemType === 'stacks') {
      setLastRowFade(fade);
    } else if (itemType === 'columns') {
      setLastColFade(fade);
    } else if (itemType === 'floors') {
      setLastFloorFade(fade);
    }
  };

  const [showCoordinates] = useState(true);

  const applyLayoutChange = (newRowArray, fl) => {
    const floors = fl || floorCount;
    if (onLayoutChange) {
      onLayoutChange(newRowArray, floors);
    } else {
      console.log('onLayoutChange() missing for rows = ', newRowArray);
    }
    setRowArray(newRowArray);
    theLayout.rows = newRowArray;
    setFloorCount(floors);
    theLayout.floors = floors;
  };

  let hoverStack = -1;
  let hoverColumn = -1;
  let hoverFloor = -1;
  if (hoveredSensor) {
    const hoverItem = sensorArray.find((sen) => sen.id === hoveredSensor);
    if (hoverItem) {
      hoverStack = hoverItem.row;
      hoverColumn = hoverItem.column;
      hoverFloor = hoverItem.floor;
    }
  }

  const applySensorsChange = (newSensorArray) => {
    setSensorArray(newSensorArray);
    // newSensorArray includes all sensors, not only those assigned to the batch phase.
    // Save only assigned sensors to sensor_layout.sensors. Sensor is assigned if it has real (>=0) row coordinate.
    theLayout.sensors = newSensorArray.filter((sen) => Number(sen.row) >= 0);
    const sensorsWithoutType = theLayout.sensors.map((sen) => {
      const withoutType = { id: sen.id, row: sen.row, column: sen.column, floor: sen.floor };
      return withoutType;
    });
    if (onSensorChange) {
      // Do not save sensor.type and sensor.points, they are for display only
      onSensorChange(sensorsWithoutType);
    } else {
      console.log('onSensorChange() missing for changed sensors = ', sensorsWithoutType);
    }
  };

  const onSensorMoved = (sensorId, targetId) => {
    const movedSensor = sensorArray.find((s) => s.id === sensorId);
    const prevLocation = [
      Number(movedSensor.row),
      Number(movedSensor.column),
      Number(movedSensor.floor)
    ];
    let targetLocation = [-1, -1, -1]; // use this when sensor removed from layout (targetId === 'bin')
    if (targetId !== 'bin') {
      // slot Ids have format 'row.col.floor' -> split by '.' to separate row, col and floor
      targetLocation = targetId.split('.').map((loc) => Number(loc));
    }
    // console.log(sensorId, ' target loc=', targetLocation);
    const [row, column, floor] = targetLocation;

    if (row === prevLocation[0] && column === prevLocation[1] && floor === prevLocation[2]) {
      // nothing moved
      return;
    }
    movedSensor.row = row;
    movedSensor.column = column;
    movedSensor.floor = floor;
    // remove sensor, and add it as new with location updated
    const newSensorArray = sensorArray.filter((sen) => sen.id !== sensorId);
    newSensorArray.push(movedSensor);
    applySensorsChange(newSensorArray);
  };

  const onRemoveAllSensors = () => {
    const sensorArrayAllOut = sensorArray.map((sensor) => ({ ...sensor, row: -1, column: -1 }));
    applySensorsChange(sensorArrayAllOut);
  };

  const changeColumns = (change) => {
    const lastColumnIndex = rowArray[0].rowLength - 1;
    if (change < 0 && lastColumnIndex === 0) {
      return; // Cannot remove all columns
    }
    let newRowArray = rowArray.map((row) => {
      const rowItem = row;
      rowItem.rowLength = Number(rowItem.rowLength) + change;
      return rowItem;
    });
    if (change < 0) {
      // Column removed: remove sensors on removed column on all stacks
      const affectedSensors = sensorArray.filter((s) => Number(s.column) === lastColumnIndex);
      affectedSensors.map((affectedSensor) =>
        onSensorMoved(affectedSensor.id, 'bin', affectedSensor.type)
      );
    }
    // keep rows sorted from 0 upwards
    newRowArray = newRowArray.sort((a, b) => (Number(a.index) < Number(b.index) ? -1 : 1));
    applyLayoutChange(newRowArray);
    setLastRowFade(false);
  };

  const changeStacks = (change) => {
    if (change < 0 && rowArray.length === 1) {
      return; // Cannot remove all stacks
    }
    let newRowArray = rowArray;
    if (change < 0) {
      // Stack removed: remove sensors on removed stack
      const affectedSensors = sensorArray.filter((s) => Number(s.row) === rowArray.length - 1);
      affectedSensors.map((affectedSensor) =>
        onSensorMoved(affectedSensor.id, 'bin', affectedSensor.type)
      );
      newRowArray = newRowArray.filter((s) => s.index < rowArray.length - 1);
    } else {
      // Add stack
      newRowArray.push({ index: rowArray.length, rowLength: rowArray[0].rowLength });
    }
    newRowArray = newRowArray.sort((a, b) => (Number(a.index) < Number(b.index) ? -1 : 1));
    applyLayoutChange(newRowArray);
    setLastRowFade(false);
  };

  const changeFloors = (change) => {
    if (change < 0 && floorCount === 1) {
      return; // Cannot remove all stacks
    }
    if (change < 0) {
      // Floor removed: remove sensors on removed floor
      const affectedSensors = sensorArray.filter((s) => Number(s.floor) === floorCount - 1);
      affectedSensors.map((affectedSensor) =>
        onSensorMoved(affectedSensor.id, 'bin', affectedSensor.type)
      );
      applyLayoutChange(rowArray, floorCount - 1);
    } else {
      // Add floor
      applyLayoutChange(rowArray, floorCount + 1);
    }
    setLastFloorFade(false);
  };

  const floorIndices = Array.from(Array(floorCount).keys()).reverse();
  const stackIndices = rowArray.map((r) => Number(r.index));

  const controls = ['stacks', 'columns', 'floors'];
  const counts = [rowArray.length, rowArray[0].rowLength, floorCount];

  const handleAddRemove = (type, isAdd) => {
    // type: 'stacks', 'columns', 'floors'
    if (type === 'stacks') {
      changeStacks(isAdd ? 1 : -1);
    } else if (type === 'columns') {
      // console.log('rowArray: ', rowArray);
      // console.log('last idx: ', rowArray[0].rowLength - 1);
      changeColumns(isAdd ? 1 : -1);
    } else if (type === 'floors') {
      // changeColumns(rowArray[0].rowLength-1, isAdd ? 1 : -1)
      changeFloors(isAdd ? 1 : -1);
    }
  };

  const controlButtonsPanel = (
    <div className={classes.controlsPanel}>
      <Button
        style={{ whiteSpace: 'nowrap', opacity: theLayout.sensors.length > 0 ? 1 : 0 }}
        color='secondary'
        onClick={() => onRemoveAllSensors()}
      >
        <Icon className={classes.buttonIcon} icon={closeBoxMultipleOutline} />
        {i18n.remove_all_sensors}
      </Button>
      {controls.map((cnt, i) => (
        <div key={i} className={classes.dimensionControl}>
          <Fab
            disabled={
              cnt === 'stacks'
                ? rowArray.length === 1
                : cnt === 'columns'
                ? rowArray[0].rowLength === 1
                : floorCount === 1
            }
            className={classes.dimensionButton}
            // disabled={}
            color='primary'
            onClick={() => handleAddRemove(cnt)}
            onMouseEnter={() => dimRemovableItem(true, cnt)}
            onMouseLeave={() => dimRemovableItem(false, cnt)}
          >
            <Icon width={20} height={20} icon={minus} />
          </Fab>
          <div className={classes.dimensionLabels}>{`${i18n[cnt] || cnt} (${counts[i]})`}</div>
          <Fab
            className={classes.dimensionButton}
            disabled={
              cnt === 'stacks'
                ? rowArray.length >= MAX_STACKS
                : cnt === 'columns'
                ? rowArray[0].rowLength >= MAX_COLS
                : floorCount >= MAX_FLOORS
            }
            color='secondary'
            onClick={() => handleAddRemove(cnt, true)}
          >
            <Icon width={20} height={20} icon={plus} />
          </Fab>
        </div>
      ))}
    </div>
  );

  const layoutComponent = stackIndices.map((stack) => (
    <div
      key={stack}
      id={`stack_${stack}`}
      style={{
        height: 30 + (stackIndices.length - 1) * 20,
        top:
          20 +
          ((0.9 * cellWidth) / MAX_CELL_SIZE) * stack * spacingFactor * floorCount +
          (isEditing ? stack * 10 : 0),
        left:
          spacingFactor === 0
            ? leftPadding
            : stack * cellWidth * 0.5 +
              leftPadding +
              (angle / 60) * stackIndices.length * 5 +
              angle * 4 * stack -
              2 * angle +
              Math.max(0, (3 * (angle - 20)) / 5),
        transform: `skewY(${-angle / 6}deg) scaleX(${(1 - angle / 90).toFixed(3)})`,
        opacity: lastRowFade && stack === stackIndices.length - 1 ? 0.2 : 1,
        filter: lastRowFade && stack === stackIndices.length - 1 ? 'grayscale(1)' : 'none',
        perspective: angle ? 150 - (100 / 60) * angle : 'none'
      }}
      className={classes.depthLayer3D}
    >
      <div className={classes.perspectiveLayer} style={{ transform: `rotateY(${angle / 6}deg)` }}>
        {spacingFactor > 0 ? (
          <div className={classes.stackLabel} style={{ bottom: isEditing ? 10 : 60 }}>
            <div className={classes.stackLabelLetter}>{formatSensorRow(stack)}</div>
            <div className={classes.stackLabelText}>{i18n.stack || 'Stack'}</div>
          </div>
        ) : null}
        {floorIndices.map((floor) => {
          const columnIndices = Array.from(Array(Number(rowArray[stack].rowLength)).keys());
          return (
            <div key={floor}>
              <div className={classes.row}>
                <div
                  className={classes.sliceContainer}
                  style={
                    lastFloorFade && floor === floorIndices.length - 1
                      ? { filter: 'grayscale(1)', opacity: 0.2 }
                      : {}
                  }
                >
                  {isEditing || showCoordinates ? (
                    <div
                      className={classes.rowLabel}
                      style={{
                        paddingTop: Math.round(cellWidth / 2) - 10,
                        marginBottom:
                          floor === 0
                            ? 18 +
                              (isEditing ? 0 : BOTTOM_MARGIN / Math.cos((angle * Math.PI) / 180))
                            : 0,
                        paddingBottom: cellWidth - 15 - (Math.round(cellWidth / 2) - 10),
                        backgroundColor:
                          hoverStack === stack && hoverFloor === floor ? COLORS.yellowBright : null
                      }}
                    >
                      {formatFloor(floor)}
                    </div>
                  ) : (
                    <div className={classes.rowLabelMargin} />
                  )}
                  {columnIndices.map((column) => {
                    const sensorInColumn = sensorArray.find(
                      (sen) =>
                        floor === sen.floor &&
                        Number(sen.row) === stack &&
                        Number(sen.column) === column
                    );
                    const lastMeasInCell = sensorInColumn
                      ? data.find((meas) => meas.sensor_id === sensorInColumn.id)
                      : null;
                    // console.log(stack, 'meas=', lastMeasInCell, getCellMeas(lastMeasInCell, stack));
                    return (
                      <div key={column}>
                        <LayerCell
                          row={stack}
                          column={column}
                          depth={floor}
                          width={cellWidth}
                          isEditing={isEditing}
                          isActive={isActive}
                          onSensorMoved={onSensorMoved}
                          onSensorClicked={onSensorClicked}
                          id={`${stack}.${column}.${floor}`}
                          label={`${column + 1}`}
                          sensorInColumn={sensorInColumn}
                          onSensorHover={onSensorHover}
                          isSelected={
                            sensorInColumn &&
                            (selectedSensor === sensorInColumn.id ||
                              hoveredSensor === sensorInColumn.id)
                          }
                          isAlert={
                            sensorInColumn &&
                            alertSensorDepths &&
                            alertSensorDepths
                              .find((al) => al && al.sensor_id === sensorInColumn.id)
                              ?.depths?.indexOf(stack + 1) >= 0
                          }
                          occupiedType={sensorInColumn ? sensorInColumn.type : null}
                          measInLocation={lastMeasInCell}
                          showColumnLabel={
                            (isEditing || stack === stackIndices.length - 1) && floor === 0
                          }
                          columnHover={!isEditing && hoverColumn === column}
                          isBottomFloor={floor === 0}
                          isFaded={lastColFade && column === columnIndices.length - 1}
                          bgColor={bgColor}
                          tempScale={tempScale}
                          angle={angle}
                          antiSkew={{ transform: 'none' }}
                          showDataless
                          showID
                          cellBgColor={`rgba(64,64,64,${
                            !spacingFactor ? 0.1 : 0.3 + spacingFactor / 500
                          })`}
                        />
                        {/* </div> */}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  ));
  return (
    <div className={classes.layoutWrapper}>
      <DndProvider backend={MultiBackend} options={HTML5toTouch}>
        {isEditing ? (
          <SensorListDroppable
            sensors={sensorArray.filter((sen) => sen.row < 0)}
            data={data}
            onDrop={onSensorMoved}
            listHeight={SENSORS_HEIGHT}
          />
        ) : null}
        <div style={{ display: 'flex' }}>
          {isEditing && controlButtonsPanel}
          <div
            id='storageLayoutPanel'
            style={
              viewHeight
                ? { height: viewHeight - (isEditing ? SENSORS_HEIGHT : 0) }
                : { height: 500 }
            }
            className={classes.canvas}
          >
            {layoutComponent}
          </div>
        </div>
      </DndProvider>
    </div>
  );
};

export default StorageStackLayout;
