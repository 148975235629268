import { AppBar, Toolbar, Typography, IconButton, useMediaQuery } from '@material-ui/core';
import React from 'react';

// SVG
import QuanturiSVG from 'assets/svg/QuanturiSVG';
import QuanturiMiniSVG from 'assets/svg/QuanturiMiniSVG';

import { COLORS } from 'utils/colors';
import LanguageSelector from 'components/activation/LanguageSelector';
import { useStyles } from '../../navigation/MainNavigator/MainNavigator.styled';

const Logo = ({ onClick }) => {
  const classes = useStyles();

  const isXsWidth = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  return (
    <IconButton className={classes.qButton} color='inherit' aria-label='Home' onClick={onClick}>
      {isXsWidth ? (
        <QuanturiMiniSVG
          color={COLORS.white}
          style={{
            width: 32,
            height: 32,
            marginTop: 6
          }}
        />
      ) : (
        <QuanturiSVG
          color={COLORS.white}
          betaColor='rgba(0,0,0,0)'
          style={{
            width: 140,
            height: 140,
            marginTop: 115
          }}
        />
      )}
    </IconButton>
  );
};

const Path = ({ title, onClick }) => {
  const classes = useStyles();

  return (
    <Typography
      onClick={onClick}
      variant='h6'
      color='inherit'
      className={[classes.grow, classes.barTitle].join(' ')}
    >
      <div className={classes.barTitleTxt}>{title}</div>
    </Typography>
  );
};

const GenericAppBar = ({ title, onClickLogo, onClickPath, noLanguageSelector }) => {
  const classes = useStyles();

  return (
    <AppBar className={classes.appBar}>
      <Toolbar className={classes.toolbar} id='mainToolbar'>
        {/* <div> */}
        <Logo onClick={onClickLogo} />
        <Path onClick={onClickPath} title={title} />
        {/* </div> */}
        {!noLanguageSelector && <LanguageSelector main />}
      </Toolbar>
    </AppBar>
  );
};

export default GenericAppBar;
