// PUBLIC IMPORTS
import { CircularProgress, Tooltip, ButtonGroup, Button } from '@material-ui/core';
import React from 'react';

// import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import Nouislider from 'react-nouislider';
import { ToggleButton } from '@material-ui/lab';
import { InlineIcon } from '@iconify/react';
import batteryOutline from '@iconify/icons-mdi/battery-outline';
import wifiIcon from '@iconify/icons-mdi/wifi';
import allIcon from '@iconify/icons-mdi/heart-multiple-outline';
import thermometerIcon from '@iconify/icons-mdi/thermometer';
import waterPercent from '@iconify/icons-mdi/water-percent';
import thermometerChevronUp from '@iconify/icons-mdi/thermometer-chevron-up';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import localeFormat from 'utils/localeFormat';
import { COLORS } from 'utils/colors';
import { isNil } from 'assets/utils';

// CUSTOM COMPONENTS IMPORT
import SensorTemperatureChart from 'components/probes/sensor/sensor-chart/sensor-chart-type/SensorTemperatureChart';
import SensorSignalChart from 'components/probes/sensor/sensor-chart/sensor-chart-type/SensorSignalChart';
import SensorVoltageChart from 'components/probes/sensor/sensor-chart/sensor-chart-type/SensorVoltageChart';
import SensorHumidityChart from 'components/probes/sensor/sensor-chart/sensor-chart-type/SensorHumidityChart';
import SensorAllChart from 'components/probes/sensor/sensor-chart/sensor-chart-type/SensorAllChart';
import { Spinner } from 'utils/Spinners';

import { useStyles } from './SensorChart.styled';

export const Loading = ({ isLoading }) => {
  const classes = useStyles();
  return (
    <div className={isLoading ? classes.chartOverlay : classes.hide}>
      <CircularProgress className={classes.chartSpinner} color='primary' size={70} disableShrink />
    </div>
  );
};

export const Chart = ({ mode, data, sensorData, probeMoistData }) => {
  const chartScale = useSelector((store) => store.sessionInfo?.ui_preferences?.chart_scale);
  const i18n = useSelector((store) => store.i18n);
  const degrees = useSelector((store) => store.sessionInfo?.ui_preferences?.degrees ?? '°C');

  const dateFormat = useSelector(
    (store) => store.sessionInfo?.ui_preferences?.date_format ?? 'd/MM/yyyy'
  );
  const timeFormat = useSelector(
    (store) => store.sessionInfo?.ui_preferences?.time_format ?? 'HH:mm'
  );

  if (!sensorData) {
    return <Spinner />;
  }

  const getChart = () => {
    switch (mode) {
      case 0:
        return (
          <SensorTemperatureChart
            data={data.data_temperature}
            // logs={data.logs}
            yMin={chartScale ? chartScale[0] : data.temperatureMin}
            yMax={chartScale ? chartScale[1] : data.temperatureMax}
            isSnapShot={data.isSnapShot}
            sensorData={sensorData}
            i18n={i18n}
            degrees={degrees}
            dateFormat={dateFormat}
            timeFormat={timeFormat}
            probeMoistData={probeMoistData}
          />
        );
      case 1:
        return (
          <SensorSignalChart
            data0={data.data_signal0}
            data1={data.data_signal1}
            logs={data.logs}
            min={data.signalMin}
            max={data.signalMax}
            isSnapShot={data.isSnapShot}
            i18n={i18n}
            dateFormat={dateFormat}
            timeFormat={timeFormat}
          />
        );
      case 2:
        return (
          <SensorVoltageChart
            data={data.data_voltage}
            logs={data.logs}
            min={data.voltageMin}
            max={data.voltageMax}
            isSnapShot={data.isSnapShot}
            i18n={i18n}
            dateFormat={dateFormat}
            timeFormat={timeFormat}
          />
        );
      case 3: // humidity
        return (
          <SensorHumidityChart
            data={data.data_humidity}
            logs={data.logs}
            min={0}
            max={100}
            isSnapShot={data.isSnapShot}
            i18n={i18n}
            dateFormat={dateFormat}
            timeFormat={timeFormat}
          />
        );
      case 10:
        return (
          <SensorAllChart
            sensorData={sensorData}
            dataTemp={data.data_temperature}
            dataBatt={data.data_voltage.map((b) => ({
              ...b,
              y: b.y ? Number((b.y - 200) / 2) : null
            }))}
            dataSignal={data.data_signal0.map((sig) => ({
              ...sig,
              y: !isNil(sig.y) ? sig.y + 100 : null
            }))}
            logs={data.logs}
            min={data.signalMin}
            max={data.signalMax}
            isSnapShot={data.isSnapShot}
            i18n={i18n}
            dateFormat={dateFormat}
            timeFormat={timeFormat}
          />
        );
      default:
    }
  };

  return getChart();
};

export const Slider = ({ min, max, date, handleChangeSlider }) => {
  const classes = useStyles();
  const i18n = useSelector((store) => store.i18n);

  return (
    <div className={classes.sliderContainer}>
      <div id='time_slider' className={classes.slider}>
        <Nouislider
          range={{ min, max }}
          behaviour='drag'
          connect
          start={date}
          onChange={handleChangeSlider}
          tooltips
          format={{
            from(value) {
              return +value;
            },
            to(value) {
              return format(+value, 'MMM d', { locale: localeFormat(i18n.locale) });
            }
          }}
        />
      </div>
    </div>
  );
};

export const MoistureButton = ({ isSelected, toggleMoisture }) => {
  const classes = useStyles();
  return (
    <div className={classes.spanButtonsContent} spacing={10} direction='row'>
      <ToggleButton
        selected={isSelected}
        className={classes.moistButton}
        onClick={() => toggleMoisture()}
      >
        <InlineIcon
          icon={waterPercent}
          style={{ color: COLORS.mediumPurple }}
          width={20}
          height={20}
        />
      </ToggleButton>
    </div>
  );
};

export const MeasureButtons = ({ mode, handleChangeMode }) => {
  const classes = useStyles();

  return (
    <div className={classes.spanButtonsContent} spacing={10} direction='row'>
      <ButtonGroup size='small' color='primary' aria-label='outlined primary button group'>
        <Button
          fullWidth
          disabled={mode === 0}
          className={classes.battButton}
          onClick={() => handleChangeMode(0)}
        >
          <InlineIcon icon={thermometerIcon} width={20} height={20} />
        </Button>
        <Button
          fullWidth
          disabled={mode === 3}
          className={classes.battButton}
          onClick={() => handleChangeMode(3)}
        >
          <InlineIcon icon={waterPercent} width={20} height={20} />
        </Button>
        <Button
          fullWidth
          disabled={mode === 1}
          className={classes.battButton}
          onClick={() => handleChangeMode(1)}
        >
          <InlineIcon icon={wifiIcon} width={20} height={20} />
        </Button>
        <Button
          fullWidth
          disabled={mode === 2}
          className={classes.battButton}
          onClick={() => handleChangeMode(2)}
        >
          <InlineIcon icon={batteryOutline} width={20} height={20} />
        </Button>
        <Button
          fullWidth
          disabled={mode === 10}
          className={classes.battButton}
          onClick={() => handleChangeMode(10)}
        >
          <InlineIcon icon={allIcon} width={20} height={20} />
        </Button>
      </ButtonGroup>
    </div>
  );
};

export const DateButtons = ({ span, handleChangeSpan, isSnapShot }) => {
  const classes = useStyles();
  const i18n = useSelector((state) => state.i18n);
  return (
    <div className={classes.spanButtonsContent} spacing={10} direction='row'>
      {isSnapShot ? (
        <Tooltip title={i18n.chart_max_overview_desc} placement='bottom' disableFocusListener>
          <div className={classes.overviewIconWrapper}>
            <InlineIcon className={classes.overviewIcon} icon={thermometerChevronUp} />
          </div>
        </Tooltip>
      ) : null}
      <ButtonGroup size='small' color='primary' aria-label='outlined primary button group'>
        <Button
          fullWidth
          disabled={span === 1}
          className={classes.spanButton}
          onClick={() => handleChangeSpan(1)}
        >
          {i18n.month}
        </Button>
        <Button
          fullWidth
          disabled={span === 2}
          className={classes.spanButton}
          onClick={() => handleChangeSpan(2)}
        >
          {i18n.week}
        </Button>
        <Button
          fullWidth
          disabled={span === 3}
          className={classes.spanButton}
          onClick={() => handleChangeSpan(3)}
        >
          {i18n.day}
        </Button>
      </ButtonGroup>
    </div>
  );
};
