import Cookies from 'js-cookie';

const isDevMode = () => {
  const mode = process.env.REACT_APP_API_ENV === 'local' || process.env.REACT_APP_API_ENV === 'dev';

  return mode;
};

export const DEBUG = isDevMode() || (!isDevMode() && Cookies.get('DEBUG'));

export default isDevMode;
