import {
  Dialog,
  DialogTitle,
  TextField,
  Typography,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Icon } from '@iconify/react';
import fan from '@iconify/icons-mdi/fan';
import fanOff from '@iconify/icons-mdi/fan-off';
import shieldAccount from '@iconify/icons-mdi/shield-account';
import cogOutline from '@iconify/icons-mdi/cog-outline';
import batchOngoing from '@iconify/icons-mdi/image-filter-hdr';
import batchCompl from '@iconify/icons-mdi/image-filter-hdr-outline';

import { format } from 'date-fns';
import { setFcAssignment } from 'api/serverAPI';
import RCManualSVG from 'assets/svg/RCManualSVG';
import { COLORS } from 'utils/colors';
import { DialogActionUtil } from 'utils/DialogActions';
import { ConfirmChanges } from 'utils/ConfirmChanges';
import { BootstrapInput } from '../../settings/Settings.styled';
// import RCLog from './RCLog';
import PhaseRCLog from './PhaseRCLog';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  div: {
    margin: 10,
    marginLeft: 0,
    marginRight: 0
  },

  dialog: {
    minHeight: 250,
    minWidth: 400,
    padding: 20,
    paddingTop: 0,
    overflowY: 'auto',
    overflowX: 'hidden',
    [theme.breakpoints.down('xs')]: {
      minHeight: 'auto',
      minWidth: 'auto',
      padding: '0'
    }
  },
  dialogTitle: {
    background: COLORS.secondaryColor,
    color: COLORS.white,
    paddingTop: 4,
    paddingBottom: 4,
    cursor: 'move'
  },
  closeButton: {
    margin: 0,
    padding: 0,
    position: 'absolute',
    right: 10,
    width: 32,
    height: 32
  },
  dialogComponent: {
    maxWidth: '100%',
    maxHeight: '100%'
  },
  adjustedDialogContent: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 168px)' // where does 168px  come
  },
  targetGroup: {
    backgroundColor: COLORS.whiteSmoke,
    padding: 10,
    borderRadius: 5,
    marginBottom: 10
  },
  explanationText: {
    marginTop: 10,
    fontSize: 13
  },
  rc_device_info_title: {
    fontSize: 12,
    marginBottom: 5
  }
}));

const CompostRCEditor = ({ selectedRC, onClose, compostMonitors, batches, RCs, setRCsChanged }) => {
  // rcStorage, rcBatch,
  const classes = useStyles();
  const { i18n, dateFormat, timeFormat, userInfo, isAdmin } = useSelector((store) => ({
    i18n: store.i18n,
    storeFCs: store.FcStatus,
    userInfo: store.userInfo,
    isAdmin: store.isAdmin,
    dateFormat: store.sessionInfo?.ui_preferences?.date_format,
    timeFormat: store.sessionInfo?.ui_preferences?.time_format
  }));

  const [modifiedRC, setModifiedRC] = useState(
    RCs.find((fc) => fc.FC_ID === selectedRC.FC_ID && fc.channel === selectedRC.channel)
  );
  const [saveNeeded, setSaveNeeded] = React.useState(false);
  const [isConfirmNeeded, setIsConfirmNeeded] = useState(false);

  const [batchAssignment, setBatchAssignment] = useState(selectedRC.batchRef || '0');
  const [monitorAssignment, setMonitorAssignment] = useState(
    selectedRC.monitorRef || compostMonitors[0]?.itemID
  );

  const [showLog, setShowLog] = React.useState(false);

  useEffect(() => {
    // console.log('CompostRCEditor Use eff ', storeFCs);
    setModifiedRC(
      RCs.find((fc) => fc.FC_ID === selectedRC.FC_ID && fc.channel === selectedRC.channel)
    );
  }, [RCs, selectedRC]);

  // const manualNotApplied =
  //   modifiedRC?.fc_mode !== 'drive' ||
  //   modifiedRC?.connectionStatus?.isOffline ||
  //   modifiedRC?.connectionStatus?.newOfflineLogged;

  const handleNameEdit = (evt) => {
    setModifiedRC({ ...modifiedRC, name: evt.target.value });
    setSaveNeeded(evt.target.value !== '');
  };

  const onSaveRC = async () => {
    const saveData = {
      UID: userInfo.U_ID,
      fc_id: modifiedRC?.FC_ID,
      isManual: modifiedRC?.isManual,
      manualCommand: modifiedRC?.manualCommand,
      name: modifiedRC?.name
    };
    if (modifiedRC.channel) {
      saveData.channel = modifiedRC.channel;
    }
    // console.log('onSaveRC ', saveData);

    if (batchAssignment !== selectedRC.batchRef || monitorAssignment !== selectedRC.monitorRef) {
      saveData.batchRef = batchAssignment === '0' ? null : batchAssignment;
      saveData.monitorRef = batchAssignment === '0' ? null : monitorAssignment;
      if (batchAssignment === '0') {
        saveData.clearRefs = true;
      }
    }

    try {
      const response = await setFcAssignment(saveData);
      console.log('setFcAssignment response:', response, saveData);
    } catch (e) {
      console.log('setFcAssignment error:', e.message);
    }
    setIsConfirmNeeded(false);
    setRCsChanged(true);
    onClose();
  };

  const startClosing = () => {
    if (saveNeeded) {
      setIsConfirmNeeded(true);
    } else {
      onClose();
    }
  };

  const manualEnabledHandler = () => {
    // console.log(`schedulerEnabledHandler ${!scheduler}`);
    setModifiedRC({
      ...modifiedRC,
      isManual: !modifiedRC?.isManual
    });
    setSaveNeeded(true);
  };

  const manualCommandChanged = (newValue) => {
    // console.log(`schedulerEnabledHandler ${!scheduler}`);
    setModifiedRC({
      ...modifiedRC,
      manualCommand: Number(newValue)
    });
    setSaveNeeded(true);
  };

  const handleBatchselection = (e) => {
    const batchSelected = e.target.value; // '0' for not selected
    setBatchAssignment(batchSelected);
    setSaveNeeded(true);
  };
  const handleMonitorselection = (e) => {
    const monitorIdSelected = e.target.value;
    setMonitorAssignment(monitorIdSelected);
    setSaveNeeded(true);
  };

  return (
    <Dialog
      className={classes.dialog}
      open={!!selectedRC}
      aria-labelledby='responsive-dialog-title'
    >
      <DialogTitle>
        {`${i18n.rc_unit}${i18n.colon} ${modifiedRC?.name}`}
        {isAdmin && (
          <ToggleButton
            style={{ position: 'absolute', right: 0, marginRight: 20, padding: 5 }}
            value={showLog}
            selected={showLog}
            onChange={() => {
              setShowLog(!showLog);
            }}
          >
            <Icon style={{ marginRight: 5 }} width={18} icon={shieldAccount} />
            Log
          </ToggleButton>
        )}
      </DialogTitle>
      {!showLog && (
        <div className={classes.dialog}>
          <div className={classes.div}>
            <TextField
              className={classes.TextField}
              // id='standard-basic-1'
              label={i18n.display_name}
              type='text'
              onChange={(e) => handleNameEdit(e)}
              value={modifiedRC?.name}
            />
          </div>
          <div className={classes.targetGroup}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                {!modifiedRC?.isManual ? (
                  <div style={{ minHeight: 40, paddingTop: 10, boxSizing: 'border-box' }}>
                    <Typography>{i18n.rc_manual_control}</Typography>
                  </div>
                ) : (
                  <ToggleButtonGroup
                    size='small'
                    // className={classes.toolbarToggleButton}
                    value={modifiedRC?.manualCommand ? '1' : '0'}
                    color='primary'
                    exclusive
                    onChange={(event, newValue) => {
                      manualCommandChanged(newValue);
                    }}
                  >
                    <ToggleButton
                      value='0'
                      selected={
                        !modifiedRC?.manualCommand || Number(modifiedRC.manualCommand) === 0
                      }
                    >
                      <RCManualSVG
                        color={
                          Number(modifiedRC?.manualCommand) !== 1
                            ? COLORS.mediumGray
                            : COLORS.lightGray3
                        }
                        style={{
                          width: 20,
                          height: 20,
                          marginRight: 5
                        }}
                      />
                      <Icon
                        width={24}
                        color={
                          Number(modifiedRC?.manualCommand) !== 1
                            ? COLORS.validStatus
                            : COLORS.lightGray3
                        }
                        icon={fanOff}
                      />
                    </ToggleButton>
                    <ToggleButton value='1' selected={Number(modifiedRC?.manualCommand) === 1}>
                      <RCManualSVG
                        color={
                          Number(modifiedRC?.manualCommand) === 1
                            ? COLORS.mediumGray
                            : COLORS.lightGray3
                        }
                        style={{
                          width: 20,
                          height: 20,
                          marginRight: 5
                        }}
                      />

                      <Icon
                        width={24}
                        color={
                          Number(modifiedRC?.manualCommand) === 1
                            ? COLORS.validStatus
                            : COLORS.lightGray3
                        }
                        icon={fan}
                      />
                    </ToggleButton>
                  </ToggleButtonGroup>
                )}
              </div>
              <FormControlLabel
                style={{ minHeight: 40, marginRight: 0 }}
                control={
                  <Switch
                    checked={modifiedRC?.isManual}
                    onChange={manualEnabledHandler}
                    name='Manual Switch'
                  />
                }
              />
            </div>
            {/* {manualNotApplied && (
              <Typography
                className={classes.explanationText}
                style={{ opacity: modifiedRC?.isManual ? 1 : 0 }}
              >
                {i18n.manual_rc_disclaimer ||
                  'Manual control is not applied until the unit is online and in AUTO mode.'}
              </Typography>
            )} */}
          </div>

          <div className={classes.targetGroup}>
            <Typography>{i18n.rc_assignment_title}</Typography>
            <div style={{ display: 'flex', marginTop: 10 }}>
              <FormControl className={classes.formControl}>
                <InputLabel>
                  {/* <Icon
                  width={20}
                  icon={batchOngoing}
                  style={{ color: COLORS.mediumGray, marginBottom: -4, marginLeft: 2 }}
                /> */}
                  {i18n.batch}
                </InputLabel>
                <Select
                  value={batchAssignment}
                  onChange={(e) => handleBatchselection(e)}
                  style={{ minWidth: 150, marginTop: 20 }}
                  disabled={!compostMonitors || isEmpty(compostMonitors)}
                  input={<BootstrapInput />}
                >
                  <MenuItem value='0'>
                    <em>{i18n.rc_not_assigned || 'Not assigned'}</em>
                  </MenuItem>

                  {batches
                    ?.filter(
                      (bat) =>
                        selectedRC.batchRef === bat.batch_id ||
                        (!bat.isAdmin &&
                          (bat.status === '1' || selectedRC.batchRef === bat.batch_id))
                    )
                    .reverse()
                    .map((bat) => {
                      const disabled =
                        RCs.find((rc) => rc.batchRef === bat.batch_id) &&
                        selectedRC.batchRef !== bat.batch_id;
                      return (
                        <MenuItem key={bat.batch_id} value={bat.batch_id} disabled={disabled}>
                          <Icon
                            style={{ color: COLORS.mediumGray, marginBottom: -4, marginRight: 5 }}
                            icon={bat.status === '2' ? batchCompl : batchOngoing}
                          />
                          {`${bat.batch_name}${bat.isAdmin ? ' [ADM]' : ''}`}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
              {batchAssignment && batchAssignment !== '0' && (
                <div style={{ marginLeft: 20 }}>
                  <FormControl className={classes.formControl}>
                    <InputLabel>
                      {' '}
                      <Icon
                        icon={cogOutline}
                        style={{ color: COLORS.mediumGray, marginBottom: -2 }}
                      />{' '}
                      {i18n.rc_controller_rule}
                    </InputLabel>
                    <Select
                      value={monitorAssignment}
                      onChange={(e) => handleMonitorselection(e)}
                      style={{ minWidth: 150, marginTop: 20 }}
                      input={<BootstrapInput />}
                    >
                      {/* <MenuItem selected value='0'>
                        <em>{i18n.no_monitor || 'No rule'}</em>
                      </MenuItem> */}

                      {compostMonitors?.map((mon) => (
                        <MenuItem key={mon.itemID} value={mon.itemID}>
                          {mon.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )}
            </div>
          </div>

          <div>
            <Typography className={classes.rc_device_info_title}>
              {i18n.rc_device_info_title || 'Device information'}
            </Typography>
            <div>
              {`${i18n.rc_device_id}${i18n.colon}`}
              {`${modifiedRC?.FC_ID} - ${i18n.rc_line} ${modifiedRC?.channel || 1}/${
                modifiedRC?.channels || 1
              }`}
            </div>
            {/* <div>Version: {modifiedRC?.fc_version}</div> */}
            <div>
              {`${i18n.rc_operation_mode}${i18n.colon}`}
              {`${
                modifiedRC?.fc_mode === 'drive'
                  ? 'AUTO'
                  : modifiedRC?.fc_mode === 'off'
                  ? 'STOP'
                  : 'RUN'
              }`}
            </div>
            <div>
              {`${i18n.rc_network_status}${i18n.colon}`}
              {modifiedRC?.connectionStatus?.isOffline ||
              modifiedRC?.connectionStatus?.newOfflineLogged
                ? i18n.rc_status_offline
                : i18n.rc_status_online}
            </div>
            <div>
              {`${i18n.rc_last_connection}${i18n.colon}`}
              {!modifiedRC?.fc_date
                ? '---'
                : format(new Date(modifiedRC?.fc_date), `${dateFormat} ${timeFormat}`)}
            </div>
            {/* <div>Last connection: {new Date(modifiedRC?.fc_date)}</div> */}
          </div>

          {/* <div className={classes.targetGroup}>
          <Typography>Status</Typography>
          <div className={classes.targetGroup}>{JSON.stringify(modifiedRC)}</div>
        </div> */}
        </div>
      )}
      {showLog && (
        <PhaseRCLog
          isOpen={showLog}
          setIsOpen={setShowLog}
          full
          RC={selectedRC}
          channel={selectedRC.channel}
        />
        // <div className={classes.dialog}>
        //   <RCLog RCid={selectedRC.FC_ID} channel={selectedRC.channel} />
        // </div>
      )}
      <DialogActionUtil
        onCancel={startClosing}
        onSave={onSaveRC}
        cancelText={i18n.close || 'Close'}
        text={i18n.save || 'Save'}
        disabled={!saveNeeded}
      />
      <ConfirmChanges
        isConfirmNeeded={isConfirmNeeded}
        setIsConfirmNeeded={setIsConfirmNeeded}
        isEdited={saveNeeded}
        setIsEdited={(val) => {
          setSaveNeeded(val);
        }}
        handleClose={onClose}
        onSave={onSaveRC}
      />
    </Dialog>
  );
};

export default CompostRCEditor;
