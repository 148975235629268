import { add, format } from 'date-fns';
import { getPlanName, planHandler } from 'assets/utils';
import { postConfirmPayment, postCharge, getUserInfo } from 'api/serverAPI';

export const getOrderSummaryService = (userInfo, renewalInfo, dateFormat) => {
  let serviceDate = new Date(userInfo.service.nextPayment.date);
  if (serviceDate < new Date()) {
    serviceDate = new Date();
  }
  const serviceDateAfterCheckout = format(
    add(serviceDate, {
      months: renewalInfo.period
    }),
    dateFormat
  );

  serviceDate = format(serviceDate, dateFormat ?? 'yyyy-MM-dd');
  const serviceDates = `${serviceDate} → ${serviceDateAfterCheckout}`;
  const serviceLabel = (
    <div>
      {getPlanName(null, userInfo.service)}
      <div>{serviceDates}</div>
    </div>
  );

  const servicePrice = renewalInfo.totalPrice;
  const serviceVTA = (servicePrice * renewalInfo.taxRate) / 100;
  const servicePriceWithVAT = servicePrice * (1 + renewalInfo.taxRate / 100);

  // const subTotal = servicePrice;
  const totalVTA = serviceVTA;
  const totalPrice = servicePriceWithVAT;

  return {
    serviceLabel,
    servicePrice,
    // subTotal,
    totalVTA,
    totalPrice
  };
};

export const getDescriptionService = (userInfo, renewalInfo, i18n) =>
  `${getPlanName(null, userInfo.service)} (${renewalInfo.period} ${i18n.months})`;

export const generateChargeService = (
  tokenId,
  renewalInfo,
  userInfo,
  billingInfo,
  companyName,
  totalPrice,
  i18n
) => ({
  stripeToken: tokenId,
  customerName: billingInfo.name,
  receiptEmail: billingInfo.email,
  companyName,
  uid: userInfo.U_ID,
  serviceRenewal: true,
  orderDescription: getDescriptionService(userInfo, renewalInfo, i18n),
  price: Math.round(totalPrice * 100),
  currency: renewalInfo.currency,
  billingInfo
});

export const generateConfirmService = (responseCharge, billingInfo, userInfo) => ({
  intent_id: responseCharge.intent_id,
  receiptEmail: billingInfo?.email,
  uid: userInfo.U_ID,
  serviceRenewal: true
});

export const getConfirmService = async (
  confirmInfo,
  responseCharge,
  userInfo,
  setProcessing,
  setSuccess,
  setError,
  dispatch,
  i18nStripe,
  stripe
) => {
  const confirm =
    confirmInfo ||
    generateConfirmService(responseCharge, responseCharge.data?.billingInfo, userInfo);

  const updatingState = async () => {
    let responseUser;
    try {
      responseUser = await getUserInfo({ UID: userInfo.U_ID });
      if (responseUser.status_code === 0) {
        dispatch({ type: 'SET_USERINFO', value: responseUser.userinfo });
        const { service } = userInfo;
        planHandler(service, dispatch);
      }
      setSuccess(true);
      setProcessing(false);
    } catch (err) {
      setError(err);
      setProcessing(false);
      console.log('err: ', err);
    }
  };

  try {
    const responseConfirm = await postConfirmPayment({
      ...confirm,
      alreadyConfirmed: !!confirmInfo
    });
    console.log('responseConfirm', responseConfirm);
    if (responseConfirm.actionRequired) {
      // We perform 3D Secure authentication
      const { paymentIntent, error } = await stripe.confirmCardPayment(
        responseConfirm.clientSecret
      );
      if (error) {
        setProcessing(false);
        setError('Error in payment, please try again later');
      }
      if (paymentIntent.status === 'succeeded')
        // return alert(`Payment successful, payment ID - ${responseConfirm.id}`);
        getConfirmService(
          confirm,
          responseCharge,
          userInfo,
          setProcessing,
          setSuccess,
          setError,
          dispatch,
          i18nStripe,
          stripe
        );

      // alert(`Payment successful, payment ID - ${responseConfirm.id}`);
    } else if (responseConfirm.status_code === 0) {
      updatingState();
    } else {
      setProcessing(false);
      console.log('error 1');

      setError({
        ...responseConfirm.error,
        message: i18nStripe[responseConfirm.error?.code] || responseConfirm.error?.message
      });
    }
  } catch (error) {
    // console.log('error 2');
    console.log('Confirmation.js 28 | ', error);
    setError({
      ...error,
      message:
        i18nStripe.failed_to_process_payment || 'An error occurred while processing your order.'
    });
    setProcessing(false);
  }
};

export const payHandlerService = async (
  stripe,
  elements,
  CardElement,
  cardComplete,
  renewalInfo,
  billingDetails,
  companyName,
  totalPrice,
  i18n,
  i18nStripe,
  setProcessing,
  setSuccess,
  setError,
  dispatch,
  userInfo
) => {
  const processSubmit = async (pMethod) => {
    try {
      const charge = generateChargeService(
        pMethod?.id,
        renewalInfo,
        userInfo,
        billingDetails,
        companyName,
        totalPrice,
        i18n
      );
      console.log('charge ready to be sent', charge);
      const response = await postCharge(charge);
      console.log('Stripe 35 | data', response);

      if (response.status_code === 0) {
        console.log('CheckoutForm.js 25 | charge successful, waiting for confirmation!');
        await getConfirmService(
          null,
          response,
          userInfo,
          setProcessing,
          setSuccess,
          setError,
          dispatch,
          i18nStripe,
          stripe
        );
      } else {
        setError({
          ...response,
          message: i18nStripe[response?.code] || response?.message
        });
        setProcessing(false);
      }
    } catch (error) {
      console.log('CheckoutForm.js 28 | ', error);
      // setError({
      //   ...error,
      //   message: i18nStripe[error?.code] || error?.message || ''
      // }
      setError(error);
      setProcessing(false);
    }
  };

  if (!stripe || !elements) {
    // Stripe.js has not loaded yet. Make sure to disable
    // form submission until Stripe.js has loaded.
    return;
  }
  setProcessing(true);

  const { error, paymentMethod } = await stripe.createPaymentMethod({
    type: 'card',
    card: elements.getElement(CardElement),
    billing_details: billingDetails
  });

  // const { token } = await stripe.createToken(elements.getElement(CardElement));

  // console.log('token', token);

  if (error) {
    // console.log('error 5', error);
    elements.getElement('card').focus();
    setProcessing(false);
    setError({
      ...error,
      message: i18nStripe[error?.code] || error?.message
    });
    return;
  }

  if (cardComplete) {
    setProcessing(true);
  }

  if (!error) {
    console.log('Stripe 23 | token generated!', paymentMethod);
  }
  processSubmit(paymentMethod);
};
