import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
// import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import rootReducer from 'redux/reducers';
import { DEBUG } from 'utils/isDevMode';
import initialState from './initialState';

const middleware = [thunk];

// if (DEBUG) {
//   middleware.push(createLogger());
// }

const devTools = DEBUG
  ? composeWithDevTools(applyMiddleware(...middleware))
  : applyMiddleware(...middleware);

export const store = createStore(rootReducer, initialState, devTools);
