import {
  Button,
  Chip,
  Paper,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Grid,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { has } from 'lodash';

import { ThemeProvider } from '@material-ui/core/styles';

import { MdDone, MdError } from 'react-icons/md';

import { getUserInfo, postUserInfo, postChangeUserPhone } from 'api/serverAPI';
// import { generateUser } from './helper';

import { accountUsersStyles, green, error } from 'utils/sharedStyles';

import { phoneNumberFormat } from 'utils/sharedHelper';
import SubUserDialog from './SubUserDialog';
import EditUserItemDialog from './EditUserItemDialog';
import EditPhoneDialog from './EditPhoneDialog';
import { generateUser } from './helpers';
import AccountBackground from './account-background/AccountBackground';

const Item = ({
  userIndex,
  idx,
  pkey,
  user,
  toggleEditUserItem,
  toggleEditUser,
  togglePhoneItem
}) => {
  const { i18n } = useSelector((store) => ({
    i18n: store.i18n
  }));

  const handleClick = (e) => {
    e.stopPropagation();
    return pkey === 'phone'
      ? togglePhoneItem(user[pkey])
      : toggleEditUserItem(userIndex, user[pkey]);
  };

  if (userIndex === 0 && idx <= 3) {
    return (
      <Grid item xs={12}>
        <ListItem button onClick={handleClick} name={user[pkey].key}>
          <ListItemText primary={user[pkey].title} secondary={user[pkey].value} />
          {has(user[pkey], 'confirmed') && (
            <ThemeProvider theme={user[pkey].confirmed ? green : error}>
              <ListItemSecondaryAction>
                <Chip
                  icon={user[pkey].confirmed ? <MdDone size='1.5em' /> : <MdError size='1.5em' />}
                  label={user[pkey].confirmed ? i18n.confirmed : i18n.not_confirmed}
                  onClick={(e) => {
                    if (!user[pkey].confirmed) {
                      e.stopPropagation();
                      togglePhoneItem(user[pkey], 1);
                      postChangeUserPhone({
                        oldPhone: phoneNumberFormat(user[pkey].value),
                        newPhone: phoneNumberFormat(user[pkey].value)
                      });
                    }
                  }}
                  style={{ margin: 10 }}
                  color='primary'
                  variant='outlined'
                />
              </ListItemSecondaryAction>
            </ThemeProvider>
          )}
        </ListItem>
        <Divider />
      </Grid>
    );
  }
  return (
    <Grid item xs={12} sm={userIndex === 0 ? 6 : 12}>
      <ListItem
        button
        onClick={() =>
          userIndex === 0 ? toggleEditUserItem(userIndex, user[pkey]) : toggleEditUser(userIndex)
        }
      >
        <ListItemText primary={user[pkey].title} secondary={user[pkey].value} />
      </ListItem>
    </Grid>
  );
};

const AccountUsers = () => {
  const classes = accountUsersStyles();
  const dispatch = useDispatch();

  const isXsWidth = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const {
    i18n,
    isPremium,
    insightService,
    isAdmin,
    userInfo,
    sessionInfo,
    surveyAnswers,
    isDemoMode
  } = useSelector((store) => ({
    i18n: store.i18n,
    isPremium: store.isPremium,
    insightService: store.insightService,
    isAdmin: store.isAdmin,
    userInfo: store.userInfo,
    sessionInfo: store.sessionInfo,
    surveyAnswers: store.surveyAnswers,
    isDemoMode: store.isDemoMode
  }));

  const [isSubUserDialog, setIsSubUserDialog] = useState();
  // const [state, setState] = useState();
  const [editState, setEditState] = useState({
    editUserItem: false,
    editPhoneItem: false,
    userIndex: 0,
    userItem: null,
    subUser: null
  });

  const [state, setState] = useState();
  const useInfoStringified = JSON.stringify(userInfo);

  useEffect(() => {
    let isCancelled = false;

    if (!isCancelled) {
      const getUserData = async () => {
        if (userInfo) {
          const users = await generateUser(userInfo, i18n, dispatch);
          setState(users);
        }
      };
      getUserData();
    }
    return () => {
      isCancelled = true;
    };
  }, [userInfo, i18n, dispatch, useInfoStringified]);

  if (!state || !userInfo) {
    return null;
  }

  const toggleEditUserItem = (userIndex, item) => {
    if (!item) {
      setEditState({ ...state, editUserItem: false });
    } else {
      setEditState({
        ...state,
        userIndex,
        userItem: item,
        editUserItem: true
      });
    }
  };

  const toggleSubUser = (userIndex) => {
    if (userIndex) {
      const { name, phone, email } = state[userIndex];
      const subUser = {
        name: name.value,
        phone: phone.value,
        email: email.value,
        UID: userInfo.subUsers[userIndex - 1].U_ID
      };
      setEditState({ ...state, userIndex, subUser });
    } else {
      setEditState({ ...state, subUser: null });
    }
    setIsSubUserDialog(true);
  };

  const togglePhoneItem = (item, index) => {
    setEditState((tempState) => ({
      ...tempState,
      index,
      userItem: item,
      editPhoneItem: !tempState.editPhoneItem
    }));
  };

  const _saveUserInfo = async (userIndex, newUser) => {
    let newUserInfo = null;
    try {
      newUserInfo = { ...userInfo };
      newUserInfo.firstname = newUser.name.value;
      newUserInfo.phone = newUser.phone.value;
      newUserInfo.email = newUser.email.value;
      newUserInfo.lastname = newUser.company.value;
      newUserInfo.country = newUser.country.value;
      newUserInfo.address = newUser.address.value;
      newUserInfo.zip = newUser.zip.value;
      newUserInfo.city = newUser.city.value;
      if (isAdmin) {
        newUserInfo.UID = sessionInfo.UID;
      }
      if (!isDemoMode || isAdmin) {
        await postUserInfo(newUserInfo);
        const dataUser = await getUserInfo({ UID: sessionInfo.UID });
        if (dataUser.status_code === 0) {
          dispatch({ type: 'SET_USERINFO', value: dataUser.userinfo });
        }
      }
    } catch (err) {
      console.log('err: ', err);
    }
  };

  const saveUserItem = (userIndex, userItem) => {
    const newUser = { ...state[userIndex] };
    newUser[userItem.key].value = userItem.value;
    _saveUserInfo(userIndex, newUser);
  };

  const subUsersAuth = insightService || (isPremium && !userInfo.plans[0]?.expired);

  return (
    <div className={classes.usersMainContainer}>
      <Paper component='div' className={classes.userList} elevation={isXsWidth ? 0 : 1}>
        <Typography variant='h6' component='h6'>
          {i18n.main_user ?? 'Main user'}
        </Typography>

        {state.map((user, i) =>
          i > 0 ? null : (
            <Grid key={i} container className={classes.gridContainer}>
              {Object.keys(user).map((key, idx) => (
                <Item
                  key={key}
                  userIndex={i}
                  idx={idx}
                  pkey={key}
                  user={user}
                  toggleEditUserItem={toggleEditUserItem}
                  toggleEditUser={toggleSubUser}
                  togglePhoneItem={togglePhoneItem}
                />
              ))}
            </Grid>
          )
        )}

        {subUsersAuth && (
          <>
            <Typography variant='h6' component='h6'>
              {i18n.other_users ?? 'Other users'}
            </Typography>
            {state.map((user, i) =>
              i === 0 ? null : (
                <Grid key={i} container className={classes.gridContainer}>
                  {Object.keys(user).map((key, idx) => (
                    <Item
                      key={key}
                      userIndex={i}
                      idx={idx}
                      pkey={key}
                      user={user}
                      toggleEditUserItem={toggleEditUserItem}
                      toggleEditUser={toggleSubUser}
                      togglePhoneItem={togglePhoneItem}
                    />
                  ))}
                </Grid>
              )
            )}
          </>
        )}

        {state.length < 3 && subUsersAuth && (
          <div className={classes.addUserContainer}>
            <Button color='primary' variant='contained' onClick={() => toggleSubUser()}>
              {i18n.add_user || 'Add User'}
            </Button>
          </div>
        )}

        {surveyAnswers && insightService && <AccountBackground surveyAnswers={surveyAnswers} />}

        {isSubUserDialog && (
          <SubUserDialog
            subUser={editState.subUser}
            mainUserCountry={state[0].country.value}
            isSubUserDialog={isSubUserDialog}
            setIsSubUserDialog={setIsSubUserDialog}
            isDemoMode={isDemoMode}
          />
        )}

        {editState.editUserItem && (
          <EditUserItemDialog
            editUserItem={editState.editUserItem}
            userIndex={editState.userIndex}
            userItem={editState.userItem}
            toggleEditUserItem={toggleEditUserItem}
            saveUserItem={saveUserItem}
          />
        )}

        {editState.editPhoneItem && (
          <EditPhoneDialog
            index={editState.index}
            phone={editState.userItem?.value}
            isConfirmed={editState.userItem?.confirmed}
            editPhoneItem={editState.editPhoneItem}
            togglePhoneItem={togglePhoneItem}
            isDemoMode={isDemoMode}
          />
        )}
      </Paper>
    </div>
  );
};

export default AccountUsers;
