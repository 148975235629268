import { Button } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'utils/colors';
import PlacementSensorList from '../../../general/basic-sensor-list/PlacementSensorList';

const useStyles = makeStyles(() => ({
  sensorPanelWrapper: {
    width: 250,
    paddingLeft: 10,
    boxSizing: 'border-box',
    overflowY: 'auto'
  },
  sensorSelection: {
    minHeight: 120,
    width: 120,
    backgroundColor: COLORS.white,
    zIndex: 200
  },
  cancelPanel: {
    position: 'absolute',
    bottom: -46,
    borderTop: `1px solid ${COLORS.lightGray2}`,
    backgroundColor: COLORS.yellowBright,
    width: 250,
    height: 46,
    zIndex: 100,
    marginLeft: -10,
    textAlign: 'center',
    padding: 7,
    boxSizing: 'border-box'
  },
  noProbes: {
    backgroundColor: COLORS.whiteSmoke,
    marginRight: 10,
    textAlign: 'center',
    fontSize: 14,
    padding: '10px 0'
  }
}));

const PlaceSensorList = ({
  selectedStorage,
  bsConf,
  auxConf,
  sensorsData,
  viewHeight,
  // onSensorHover,
  // onLayoutChange,
  // onSensorChange,
  //   onSensorClicked,
  onSensorClick,
  selectedSensor,
  //   leftPadding,
  availableSensors,
  allSensors,
  addingProbePosition,
  hintChanged
}) => {
  const i18n = useSelector((state) => state.i18n);
  const classes = useStyles();

  // Adjusting list height depending on headers
  const [hintHeight, setHintHeight] = useState(
    document.getElementById('probeListHeader')?.offsetHeight || 0
  );

  useEffect(() => {
    setHintHeight(document.getElementById('probeListHeader')?.offsetHeight || 0);
  }, [hintChanged]);

  return (
    <div
      style={{ height: viewHeight - hintHeight - (addingProbePosition ? 45 : 0) }}
      className={classes.sensorPanelWrapper}
    >
      {isEmpty(availableSensors) ? (
        !isEmpty(allSensors) ? null : (
          <div className={classes.noProbes}>
            {isEmpty(bsConf?.sensor_configurations)
              ? i18n.no_probes || 'No probes'
              : i18n.no_probes_available || 'All probes placed'}
          </div>
        )
      ) : (
        <PlacementSensorList
          sensorIDs={availableSensors}
          // isPassive={!addingProbePosition}
          showMultipoint
          dataSnapshot={sensorsData}
          bsConf={bsConf}
          auxConf={auxConf}
          selectedSensor={selectedSensor}
          onSensorClick={(id) => onSensorClick(id)}
          // setSelectedSensor={(id) => setSelectedSensor(id)}
          // alertSensorIDs={alertSensorIDs}
          marginRight={10}
          //   alertSensorDepths={alertSensorDepths}
          // showHandleBar={prefs.ui_preferences.handle_temp_enabled === 'true'}
          // onSensorHover={onSensorHover}
          // hoveredSensor={hoveredSensor}
          //   outdoorIDS={[outdoorID]}
          // batches={batches}
          // storages={storages}
          //   outdoorIDAdded={outdoorIDAdded}
          //   setOutdoorIDAdded={setOutdoorIDAdded}
          selectedStorage={selectedStorage}
        />
      )}
      {addingProbePosition ? (
        <div className={classes.cancelPanel}>
          <Button
            color='primary'
            style={{ maxHeight: 30 }}
            variant='contained'
            onClick={() => onSensorClick()}
          >
            {i18n.cancel || 'Cancel'}
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default PlaceSensorList;
