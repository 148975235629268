import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  IconButton,
  Typography
} from '@material-ui/core';

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import 'yup-phone';
import { yupResolver } from '@hookform/resolvers/yup';
import ReactPhoneInput from 'material-ui-phone-number';

import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';

import { isEmpty } from 'lodash';
import { useConfirm } from 'hook/useConfirm';
import { PHONE_COUNTRIES } from 'redux/constants';

import { addNewUser, getUserInfo, postMinorUserInfo, postDeleteSubUser } from 'api/serverAPI';
import { COLORS } from 'utils/colors';
import { phoneNumberFormat } from 'utils/sharedHelper';

const useStyles = makeStyles(() => ({
  dialogTitle: {
    padding: '10px 24px 0px 24px'
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 300
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  textField: {
    margin: '5px 0'
  },
  helperText: {
    margin: '5px 0'
  },
  errorMessage: {
    color: COLORS.alert,
    margin: '5px 0',
    fontSize: '0.75rem'
  }
}));

const SubUserDialog = ({
  subUser,
  mainUserCountry,
  isSubUserDialog,
  setIsSubUserDialog,
  isDemoMode
}) => {
  const classes = useStyles();
  const confirm = useConfirm();
  const dispatch = useDispatch();
  const { i18n, userInfo, isAdmin } = useSelector((store) => ({
    i18n: store.i18n,
    userInfo: store.userInfo,
    isAdmin: store.isAdmin
  }));

  const AddUserDialogSchema = yup.object().shape({
    name: yup.string().required(i18n.name_req || 'Name is required'),
    phone: yup
      .string()
      .phone(null, null, i18n.invalid_format)
      .required(i18n.mobile_req || 'Mobile phone is required')
      .min(8, i18n.phone_num_length || 'Phone must have at least 8 numbers'),
    email: yup.string().nullable().email(i18n.invalid_format)
  });

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(AddUserDialogSchema),
    defaultValues: { ...subUser }
  });

  const onSave = async (values) => {
    try {
      let res = {};
      let plusPhone = phoneNumberFormat(values.phone);
      // Ensure international format
      if (plusPhone && plusPhone.charAt(0) !== '+') {
        plusPhone = `+${values.phone}`;
      }
      if (subUser) {
        const minorUserObject = {
          name: values.name,
          phone: plusPhone,
          email: values.email,
          UID: subUser.UID
        };
        if (isDemoMode && !isAdmin) {
          minorUserObject.U_ID = subUser.UID;
          const newSubUsers = userInfo.subUsers.map(
            (sub) => [minorUserObject].find((minor) => minor.U_ID === sub.U_ID) || sub
          );
          const newDataUser = { ...userInfo, subUsers: newSubUsers };

          dispatch({ type: 'SET_USERINFO', value: newDataUser });
          setIsSubUserDialog(false);
        } else {
          res = await postMinorUserInfo(minorUserObject);
        }
      } else {
        const newUserObject = {
          name: values.name,
          mobilePhone: plusPhone,
          email: values.email
        };
        if (isDemoMode && !isAdmin) {
          newUserObject.U_ID = !isEmpty(userInfo?.subUsers)
            ? Math.max(...userInfo.subUsers.map((s) => +s.U_ID)) + 1
            : 1;
          newUserObject.phone = plusPhone;
          const newDataUser = userInfo;
          if (!newDataUser.subUsers) {
            newDataUser.subUsers = [newUserObject];
          } else {
            newDataUser.subUsers = [...newDataUser.subUsers, newUserObject];
          }
          dispatch({ type: 'SET_USERINFO', value: newDataUser });
          setIsSubUserDialog(false);
        } else {
          res = await addNewUser({
            UID: userInfo.U_ID,
            new_user: newUserObject
          });
        }
      }
      if (res.status_code === 0) {
        const dataUser = await getUserInfo({ UID: userInfo.U_ID });
        dispatch({ type: 'SET_USERINFO', value: dataUser.userinfo });
        setIsSubUserDialog(false);
      } else {
        return;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const deleteSubUser = async () => {
    try {
      await confirm({
        title: i18n.delete_user_title || 'Delete user',
        description: `${i18n.delete_user_conf || 'Are you sure you want to delete user'} ${
          i18n.quoteStart
        }${subUser.name}${i18n.quoteEnd}?`
      });
      if (isDemoMode && !isAdmin) {
        const subUsersNewObject = userInfo.subUsers.filter((sub) => sub.U_ID !== subUser.UID);
        const newUserInfo = { ...userInfo, subUsers: subUsersNewObject };
        dispatch({ type: 'SET_USERINFO', value: newUserInfo });
        setIsSubUserDialog(false);
      } else {
        const res = await postDeleteSubUser({
          mainUserID: userInfo.U_ID,
          subUserID: subUser.UID
        });
        if (res.status_code === 0) {
          const dataUser = await getUserInfo({ UID: userInfo.U_ID });
          dispatch({ type: 'SET_USERINFO', value: dataUser.userinfo });
          setIsSubUserDialog(false);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Dialog open={isSubUserDialog} aria-labelledby='responsive-dialog-title'>
      <form noValidate onSubmit={handleSubmit(onSave)}>
        <DialogTitle className={classes.dialogTitle}>
          {subUser ? i18n.edit_user : i18n.add_user}
        </DialogTitle>
        <DialogContent elevation={0} className={classes.dialogContent}>
          <FormControl className={classes.textField}>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  label={i18n.name || 'Name'}
                  variant='outlined'
                  required
                  error={Boolean(errors.name)}
                  helperText={errors.name?.message}
                  FormHelperTextProps={{ classes: { root: classes.helperText } }}
                />
              )}
              name='name'
              control={control}
            />
          </FormControl>

          <FormControl className={classes.textField}>
            <Controller
              render={({ field }) => (
                <ReactPhoneInput
                  {...field}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                  autoFocus={Boolean(errors.phone)}
                  disableAreaCodes
                  defaultCountry={mainUserCountry}
                  onlyCountries={PHONE_COUNTRIES}
                />
              )}
              label={i18n.mobile_phone || 'Phone'}
              name='phone'
              rules={{ required: true }}
              control={control}
              defaultValue=''
              required
            />
            <Typography className={classes.errorMessage} color='error'>
              {errors.phone?.message}
            </Typography>
          </FormControl>

          <FormControl className={classes.textField}>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  label={i18n.email_address || 'Email'}
                  variant='outlined'
                  error={Boolean(errors.email)}
                  helperText={errors.email?.message}
                  FormHelperTextProps={{ classes: { root: classes.helperText } }}
                />
              )}
              name='email'
              control={control}
            />
          </FormControl>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <div>
            {subUser && (
              <IconButton aria-label='Delete User' onClick={() => deleteSubUser()}>
                <DeleteIcon />
              </IconButton>
            )}
          </div>
          <div>
            <Button onClick={() => setIsSubUserDialog(false)} color='primary'>
              {i18n.cancel}
            </Button>
            <Button type='submit' color='secondary'>
              {i18n.save || 'Save'}
            </Button>
          </div>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default SubUserDialog;
