import { TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { COLORS } from 'utils/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 200,
    backgroundColor: COLORS.white,
    color: `${theme.palette.text.primary}`,
    border: 'none'
  },
  input: {
    height: '10px',
    border: 'none'
  }
}));

const BasestationSelect = ({ selectedBS, suggestionsBS, suggestionsGariBS, bsType, onChange }) => {
  const classes = useStyles();
  const suggestions = bsType === 'quanturi' ? suggestionsBS : suggestionsGariBS;
  const [selectedOption, setSelectedOption] = useState(
    suggestions[0] || { value: '0', label: 'Select a BS' }
  );

  useEffect(() => {
    const seletedOpt = suggestions.find((opt) => opt.value === selectedBS);
    setSelectedOption(seletedOpt || { value: '0', label: 'Select a BS' });
  }, [selectedBS, suggestions]);

  const onSelectChange = (val) => {
    if (val) {
      onChange({ value: val.value });
    }
  };

  return (
    <Autocomplete
      classes={classes}
      options={suggestions}
      value={selectedOption}
      getOptionLabel={(option) => option.label || option}
      getOptionSelected={(option) => option.value}
      renderInput={(params) => <TextField {...params} variant='outlined' fullWidth size='small' />}
      onChange={(e, val) => onSelectChange(val)}
      size='small'
    />
  );
};

export default BasestationSelect;
