import React from 'react';
import { format } from 'date-fns';
import { VictoryTooltip, VictoryLabel } from 'victory';
import { useSelector } from 'react-redux';
// import localeFormat from 'utils/localeFormat';

import { getGradientColor } from 'assets/utils';
// import { isNil } from 'assets/utils';
import { COLORS } from 'utils/colors';

export default function CustomAllTooltip(props) {
  const degrees = useSelector((store) => store.sessionInfo?.ui_preferences?.degrees ?? '°C');

  const {
    text,
    datum,
    x,
    y,
    tempData,
    signalData,
    battData,
    handleData,
    mode,
    dateFormat,
    timeFormat,
    isSnapShot,
    chartHei
  } = props; // wrapperWidth

  const defaultFormat = text[0].split(',')[0];
  const max = parseInt(text[0].split(',')[2]);
  const dataX = datum.x;
  let dataY = datum.y;
  let dy = 6;
  let dataY1;
  let dataY2;
  let dataY3;
  let y1;

  if (datum && datum.moist) {
    return null;
  }

  if (mode === 10) {
    dataY = tempData.find((point) => point.x === datum.x)?.y || 0;
    dataY1 = signalData.find((point) => point.x === datum.x)?.y || 0;
    dataY2 = battData.find((point) => point.x === datum.x)?.y || 0;
    dataY3 = handleData?.find((point) => point.x === datum.x)?.y || 0;
    y1 = (max - dataY1) * ((y - 50) / (max - dataY)) + 50;
    dy = y - y1 > 0 ? y - y1 + 6 : 6;
  }

  if (dataY === 0 && dataY1 === 0 && dataY2 === 0) {
    return null;
  }

  const getTooltipStyle = () => [
    { fill: COLORS.primaryColor, fontFamily: 'inherit', fontSize: 11 },
    {
      fill: getGradientColor(dataY),
      fontFamily: 'inherit',
      fontSize: 13,
      fontWeight: 'bold'
    },
    { fill: COLORS.boulderGray, fontFamily: 'inherit', fontSize: 12, filter: 'grayscale(1)' },
    { fill: COLORS.orange, fontFamily: 'inherit', fontSize: 13, fontWeight: 'bold' },
    {
      fill: COLORS.chetwodeBlue,
      fontFamily: 'inherit',
      fontSize: 13,
      fontWeight: 'bold'
    }
  ];

  const getTooltipText = () => [
    format(
      dataX,
      isSnapShot
        ? defaultFormat || dateFormat
        : defaultFormat || `${dateFormat} ${timeFormat}` || defaultFormat
    ),
    `${dataY.toFixed(1)} ${degrees}`,
    `${!dataY3 ? '' : `✋ ${dataY3.toFixed(1)} ${degrees}`}`,
    `${((dataY2 * 2 + 200) / 100).toFixed(2)} V`,
    `${-(100 - dataY1)} dBm`
  ];

  return (
    <g>
      <line
        role='presentation'
        x1={x}
        x2={x}
        y1={50}
        y2={chartHei - 50}
        style={{ stroke: 'rgba(0,0,0,0.2)', strokeWidth: 3 }}
      />
      {/* {mode === 10 && (
        <>
          <circle cx={x} cy={y1} r='4' stroke='none' fill={COLORS.primaryColor} />
          <circle cx={x} cy={y1} r='4' stroke='none' fill={COLORS.primaryColor} />
          <circle cx={x} cy={y} r='4' stroke='none' fill={COLORS.primaryColor} />
          <circle cx={x} cy={y} r='4' stroke='none' fill={COLORS.primaryColor} />
        </>
      )} */}
      <VictoryTooltip
        {...props}
        flyoutHeight={100}
        cornerRadius={4}
        pointerLength={5}
        labelComponent={<VictoryLabel lineHeight={1.6} />}
        flyoutStyle={{
          fill: COLORS.white,
          filter: 'url(#shadow)',
          stroke: 'rgba(0,0,0,0)'
        }}
        text={getTooltipText()}
        dy={mode === 1 ? -dy : 0}
        style={getTooltipStyle()}
      />
    </g>
  );
}
