import {
  Dialog,
  DialogContent,
  Toolbar,
  Typography,
  IconButton,
  useMediaQuery
} from '@material-ui/core';

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// ICONS
import { MdClose } from 'react-icons/md';

import Notifications from 'components/notifications/Notifications';
import { useStyles } from './MainNavigator.styled';

const NotifDialog = ({ notif, isMultiBS, toggleNotif }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const i18n = useSelector((state) => state.i18n);
  const editConfirmNeeded = useSelector((state) => state.editConfirmNeeded);
  const isAdmin = useSelector((state) => state.isAdmin);
  const isXsWidth = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const onClickNotifications = () => {
    if (editConfirmNeeded) {
      dispatch({ type: 'SET_UNSAVED_CHANGES', value: true });
    }
    toggleNotif(false);
  };
  return (
    <Dialog
      PaperProps={{
        className: isAdmin || isMultiBS ? classes.notificationsWithTopBar : classes.notifications
      }}
      fullScreen={isXsWidth}
      open={notif}
      onClose={onClickNotifications}
      aria-labelledby='responsive-dialog-title'
    >
      <Toolbar>
        <Typography variant='h6' color='inherit' className={classes.grow}>
          {i18n.notifications}
        </Typography>
        <IconButton
          button='true'
          onClick={() => toggleNotif(false)}
          color='inherit'
          aria-label={i18n.close}
          className={classes.close}
        >
          <MdClose size='1.5em' />
        </IconButton>
      </Toolbar>
      <DialogContent className={classes.dialogContent} elevation={0}>
        <Notifications onClick={onClickNotifications} editConfirmNeeded={editConfirmNeeded} />
      </DialogContent>
    </Dialog>
  );
};

export default NotifDialog;
