import { Button, Dialog, DialogTitle, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Alert } from '@material-ui/lab';
import { isEmpty } from 'lodash';
import { DeleteDialog } from 'utils/DeleteDialog';
import { DialogActionUtil } from 'utils/DialogActions';

import { COLORS } from 'utils/colors';
import {
  postSaveMonitor,
  postRemoveMonitor,
  postSaveStorage,
  processStorageMonitors,
  runScheduler
} from 'api/serverAPI';

import { templateEditorStyle } from 'utils/sharedStyles';
import RuleTypeSelector from './rule-editor-dialog/RuleTypeSelector';
import { StorageContext } from '../../StorageContext';
import MonitorRules from '../monitor-utils/MonitorRulesUtils';

const MonitorEditor = ({
  userInfo,
  createMonitor,
  setCreateMonitor,
  setMonitorsChanges,
  selectedMonitor,
  // setStoragesChanges,
  setSelectedMonitor,
  storages,
  fromStorageMonitors,
  FC_IDS,
  FcStatus,
  editingFromStorageMonitors,
  setEditingFromStorageMonitors,
  selectedStorage,
  monitors,
  setMonitors,
  periskopMonitor
}) => {
  const { state } = useContext(StorageContext);
  const { i18n, bsConf, isDemoMode, isAdmin, insightService, isPeriskop } = useSelector(
    (store) => ({
      i18n: store.i18n,
      bsConf: store.bsConf,
      isDemoMode: store.isDemoMode,
      isAdmin: store.isAdmin,
      insightService: store.insightService,
      isPeriskop: store.isSupervisorCompost
    })
  );
  const classes = templateEditorStyle();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const initialMonitor = {
    name: '',
    rules: []
  };
  const [monitorEdited, setMonitorEdited] = useState(initialMonitor);

  const [selectRuleTypeDialog, setSelectRuleTypeDialog] = useState(false);
  const [selectedRuleType, setSelectedRuleType] = useState(null);
  const [selectedRule, setSelectedRule] = useState(null);
  const [newRule, setNewRule] = useState(false);
  const [ruleInUseEdited, setRuleInUseEdited] = useState(false);
  const storagesToEdit = selectedMonitor
    ? storages?.filter((storage) => storage.monitor?.itemID === selectedMonitor?.itemID)
    : fromStorageMonitors
    ? null
    : [];
  const [toRunScheduler, setToRunScheduler] = useState(false);

  useEffect(() => {
    if (selectedMonitor) {
      console.log('Making copy of the monitor to edit it :', selectedMonitor);

      setMonitorEdited(selectedMonitor);
    }
  }, [selectedMonitor, setMonitorEdited]);

  // Enabling RC rules for compost
  let availableRules = FC_IDS
    ? state.ruleTypes.filter((rule) => (periskopMonitor && isPeriskop ? rule.compostRule : true))
    : state.ruleTypes
        .filter((rule) => rule.group !== 'FC')
        .filter((rule) => (periskopMonitor && isPeriskop ? rule.compostRule : true));

  if (insightService) {
    availableRules = availableRules.filter((rule) => rule.group === 'ABS');
  }

  const closeMonitorCreate = () => {
    setCreateMonitor(false);
    if (!fromStorageMonitors) {
      setSelectedMonitor(null);
      setOpenDeleteDialog(false);
      setMonitorEdited(initialMonitor);
      setRuleInUseEdited(false);
      setToRunScheduler(false);
    }
    if (fromStorageMonitors) {
      setEditingFromStorageMonitors(false);
    }
  };

  const timeStamp = Math.round(+new Date() / 1000).toString();

  const onSaveMonitor = () => {
    const savedArray = {
      UID: userInfo.U_ID,
      item: monitorEdited
    };

    if (isDemoMode && isAdmin) {
      savedArray.item.demoType = isDemoMode;
    }
    if (isDemoMode && !isAdmin) {
      if (monitorEdited.itemID) {
        const newMonitorsArray = monitors.map(
          (obj) => [monitorEdited].find((o) => o.itemID === obj.itemID) || obj
        );
        setMonitors(newMonitorsArray);
      } else {
        monitorEdited.itemID = isEmpty(monitors)
          ? 1
          : Math.max(...monitors.map((mon) => +mon.itemID)) + 1;
        setMonitors([...monitors, monitorEdited]);
      }
      closeMonitorCreate();
    } else {
      postSaveMonitor(savedArray)
        .then((response) => {
          console.log(response, 'response');
          if (storagesToEdit?.length > 0) {
            storagesToEdit.forEach((storage) => {
              let rulesToHistory = [];
              if (ruleInUseEdited) {
                rulesToHistory = storage.monitor.rules
                  .map((rule) =>
                    rule.currentAlerts?.map((currentAlert) =>
                      currentAlert.resetTS === null || !currentAlert.resetTS
                        ? {
                            ...currentAlert,
                            resetTS: timeStamp
                            // previousAlert: currentAlert.alertLimit
                            // }
                          }
                        : { ...currentAlert }
                    )
                  )
                  .flat(1);
              }
              // console.log('rulesToHistory', rulesToHistory);
              const storageUpdated = {
                ...storage,
                monitor: {
                  ...storage.monitor,
                  ...monitorEdited,
                  rules: monitorEdited.rules.map((rule) =>
                    storage.monitor.rules.find((ruleMonitor) => ruleMonitor.id === rule.id)
                      ? rule.ruleChanged?.oldSettings !==
                        storage.monitor.rules.find((ruleMonitor) => ruleMonitor.id === rule.id)
                          ?.ruleChanged?.settings
                        ? {
                            ...storage.monitor.rules.find(
                              (ruleMonitor) => ruleMonitor.id === rule.id
                            ),
                            ...rule,
                            alertHistory: [
                              storage.monitor.rules.find(
                                (ruleMonitor) => ruleMonitor.id === rule.id
                              )?.alertHistory,
                              ...rulesToHistory
                            ]
                              .flat(1)
                              .filter(Boolean),
                            currentAlerts: []
                          }
                        : {
                            ...storage.monitor.rules.find(
                              (ruleMonitor) => ruleMonitor.id === rule.id
                            )
                          }
                      : { ...rule }
                  )
                }
              };
              postSaveStorage({ UID: userInfo.U_ID, item: storageUpdated })
                .then((responseStorage) => {
                  console.log(responseStorage, 'responseStorage');
                  // if (setStoragesChanges) {
                  //   setStoragesChanges(true);
                  // }
                })
                .catch((error) => {
                  console.log('Error in postSaveStorage: ', error);
                });

              if (toRunScheduler) {
                const runSchedulerMatch = FcStatus?.find((fc) => fc.inUse === storage?.itemID);
                const runSchedulerBody = runSchedulerMatch && {
                  id: runSchedulerMatch.FC_ID,
                  itemID: runSchedulerMatch.inUse
                };
                console.log('runSchedulerBody', runSchedulerBody);

                runScheduler(runSchedulerBody)
                  .then((responseScheduler) => {
                    console.log('response runScheduler', responseScheduler);
                  })
                  .catch((error) => {
                    console.log('Error in postSaveStorage: ', error);
                  });
              }
            });
            console.log(
              'storagesToEdit 2',
              storagesToEdit,
              storagesToEdit ? storagesToEdit.map((s) => s.itemID) : null
            );
            processStorageMonitors({
              BS_ID: bsConf.bs_id,
              UID: userInfo?.U_ID,
              itemIDs: storagesToEdit ? storagesToEdit.map((s) => s.itemID) : null
            })
              .then((responseProcess) => {
                console.log('response processStorageMonitors', responseProcess);
                // if (setStoragesChanges) {
                //   setStoragesChanges(true);
                // }
                // closeMonitorCreate();
              })
              .catch((error) => {
                console.log('Error in processStorageMonitors: ', error);
              });
          }
          if (setMonitorsChanges) {
            setMonitorsChanges(true);
          }
          closeMonitorCreate();
        })
        .catch((error) => {
          console.log('Error in postSaveMonitor: ', error);
        });
    }
  };

  const handleClickOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleDeleteMonitor = async () => {
    if (selectedMonitor) {
      const savedArray = {
        UID: userInfo.U_ID,
        itemID: selectedMonitor.itemID
      };

      if (isDemoMode && !isAdmin) {
        setMonitors(monitors.filter((mon) => +mon.itemID !== +selectedMonitor.itemID));
        closeMonitorCreate();
      } else {
        try {
          const response = await postRemoveMonitor(savedArray);
          console.log(response, 'response');
          if (setMonitorsChanges) {
            setMonitorsChanges(true);
          }
          closeMonitorCreate();
        } catch (error) {
          console.log('Error in postRemoveMonitor: ', error);
        }
      }
    }
  };

  const openRuleTypeSelector = () => {
    setSelectedRuleType(null);
    setSelectRuleTypeDialog(true);
  };

  return (
    <Dialog
      className={classes.dialog}
      open={createMonitor}
      aria-labelledby='responsive-dialog-title'
    >
      <DialogTitle>
        {selectedMonitor
          ? i18n.update_monitor || 'Update monitor'
          : i18n.new_monitor || 'New monitor'}
      </DialogTitle>
      <form className={classes.dialog} noValidate autoComplete='on'>
        <div className={classes.div}>
          <TextField
            className={classes.TextField}
            // id='standard-basic-1'
            label={i18n.monitor_name || 'Monitor name'}
            type='text'
            value={monitorEdited?.name}
            onChange={(e) => setMonitorEdited({ ...monitorEdited, name: e.target.value })}
            // onChange={e => dispatch({ type: 'CHANGE_Monitor_NAME', value: e.target.value })}
            required
          />
        </div>
        <div className={classes.div}>
          <Typography variant='body2' gutterBottom>
            {i18n.alert_rules || 'Alert rules'}
          </Typography>
          <MonitorRules
            monitor={monitorEdited}
            state={state}
            inEditor
            setSelectedRuleType={setSelectedRuleType}
            selectedRule={selectedRule}
            setSelectedRule={setSelectedRule}
            setNewRule={setNewRule}
            FcStatus={FcStatus}
          />
        </div>
        <div className={classes.div} style={{ paddingTop: 5 }}>
          <Button
            variant='contained'
            onClick={() => openRuleTypeSelector()}
            color='secondary'
            // disabled={monitorEdited.name === '' || monitorEdited.rules.length < 1}
            style={{ color: COLORS.white }}
          >
            {i18n.add_rule || 'Add alert rule'}
          </Button>
        </div>

        {ruleInUseEdited &&
          [selectedStorage?.itemID].join(',') !==
            storagesToEdit.map((st) => st.itemID).join(',') && (
            <div>
              <Alert severity={editingFromStorageMonitors ? 'error' : 'info'}>
                {i18n.alert_reset_msg ||
                  'Note: Changing this monitor will apply to following storages'}
                {i18n.colon}
                {storagesToEdit
                  .map((s) => `${i18n.quoteStart}${s.name}${i18n.quoteEnd}`)
                  .join(', ')}
              </Alert>
            </div>
          )}
      </form>
      <RuleTypeSelector
        i18n={i18n}
        setMonitorEdited={setMonitorEdited}
        monitorEdited={monitorEdited}
        ruleTypes={availableRules}
        selectRuleTypeDialog={selectRuleTypeDialog}
        setSelectRuleTypeDialog={setSelectRuleTypeDialog}
        selectedRuleType={selectedRuleType}
        setSelectedRuleType={setSelectedRuleType}
        selectedRule={selectedRule}
        setSelectedRule={setSelectedRule}
        newRule={newRule}
        setNewRule={setNewRule}
        setRuleInUseEdited={setRuleInUseEdited}
        monitorInUse={storagesToEdit?.length > 0}
        selectedMonitor={selectedMonitor}
        timeStamp={timeStamp}
        FC_IDS={FC_IDS}
        FcStatus={FcStatus}
        setToRunScheduler={setToRunScheduler}
      />

      <DialogActionUtil
        onCancel={closeMonitorCreate}
        onSave={onSaveMonitor}
        text={i18n.save || 'Save'}
        disabled={monitorEdited.name === '' || monitorEdited.rules?.length < 1}
        deleteButton={selectedMonitor && !fromStorageMonitors}
        deleteUnauthorized={storagesToEdit?.length > 0}
        handleDelete={handleClickOpenDeleteDialog}
      />

      <DeleteDialog
        i18n={i18n}
        openDeleteDialog={openDeleteDialog}
        handleCloseDeleteDialog={handleCloseDeleteDialog}
        handleDelete={handleDeleteMonitor}
        text={
          monitorEdited.defaultMonitor
            ? i18n.alert_delete_default_monitor ||
              'Your new storages will not be covered by the default monitor anymore. Do you still want to remove the default monitor?'
            : i18n.alert_delete_monitor || 'Do you want to remove this monitor?'
        }
      />
    </Dialog>
  );
};

export default MonitorEditor;
