import { makeStyles, createTheme } from '@material-ui/core/styles';
import { COLORS } from 'utils/colors';

export const premium = createTheme({
  palette: {
    primary: {
      main: COLORS.primaryColor
    },
    secondary: {
      main: COLORS.premium
    }
  },
  typography: {
    fontFamily: "'Roboto', sans-serif",
    useNextVariants: true
  }
});

export const useStyles = makeStyles((theme) => ({
  hide: {
    display: 'none'
  },
  mainContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: COLORS.white
  },
  mainPage: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    top: 52,
    left: 0,
    right: 64,
    bottom: 0,
    [theme.breakpoints.down('xs')]: {
      right: 0
    }
  },
  mainPageWithDoubleTopBar: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    top: 115,
    left: 0,
    right: 64,
    bottom: 0,
    [theme.breakpoints.down('xs')]: {
      right: 0
    }
  },
  mainPageWithTopBar: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    top: 82,
    left: 0,
    right: 64,
    bottom: 0,
    [theme.breakpoints.down('xs')]: {
      right: 0
    }
  },
  mainPageWithTabs: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    top: 85,
    left: 0,
    right: 64,
    bottom: 0,
    [theme.breakpoints.down('xs')]: {
      right: 0
    }
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  adminBar: {
    width: '100%',
    height: 30,
    zIndex: 10000,
    display: 'flex'
  },
  multiBar: {
    width: '100%',
    height: 30,
    backgroundColor: COLORS.eclipseGray,
    zIndex: 10000,
    display: 'flex'
  },
  multiBarIndicatorPeriskop: {
    backgroundColor: COLORS.periskopGreen
  },
  toolbar: {
    height: 56,
    minHeight: 56,
    overflow: 'hidden',
    paddingRight: 0,
    paddingLeft: 10
  },
  periskopToolbar: {
    backgroundColor: COLORS.periskopBlue
  },
  input: {
    width: 120,
    height: 25,
    marginTop: 2,
    marginLeft: 10,
    paddingLeft: 8,
    backgroundColor: COLORS.white
  },
  closeContainer: {
    height: 60
  },
  notifications: {
    position: 'absolute',
    top: 15,
    right: 25,
    width: 400,
    height: 600,
    margin: 48,
    [theme.breakpoints.down('xs')]: {
      top: 0,
      right: 0,
      width: '100%',
      height: '100%',
      margin: 0
    }
  },
  notificationsWithTopBar: {
    position: 'absolute',
    top: 45,
    right: 25,
    width: 400,
    height: 600,
    margin: 48,
    [theme.breakpoints.down('xs')]: {
      top: 0,
      right: 0,
      width: '100%',
      height: '100%',
      margin: 0
    }
  },
  dialogContent: {
    padding: 0
  },
  close: {
    margin: 0,
    padding: 0
  },
  drawerPaper: {
    width: 400,
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  miniDrawerPaper: {
    width: 64,
    [theme.breakpoints.down('xs')]: {
      width: 0
    }
  },
  miniDrawerPaperWithTopBar: {
    width: 64,
    marginTop: 30,
    [theme.breakpoints.down('xs')]: {
      width: 0
    }
  },
  miniDrawerPaperWithDoubleTopBar: {
    width: 64,
    marginTop: 60,
    [theme.breakpoints.down('xs')]: {
      width: 0
    }
  },
  miniDrawerList: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column'
  },
  miniDrawerListWithTopBar: {
    width: '100%',
    height: 'calc(100vh - 30px)',
    display: 'flex',
    flexDirection: 'column'
  },
  drawerList: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column'
  },
  drawerListContainer: {
    width: '100%',
    flexGrow: 1
  },
  drawerFooter: {
    width: '100%',
    height: 50,
    marginBottom: 50
  },
  drawerButton: {
    marginRight: theme.spacing(2)
  },
  drawerVersion: {
    margin: theme.spacing(2)
  },
  grow: {
    flexGrow: 1
  },
  qButton: {
    marginRight: 25
  },
  badge: {
    top: -10,
    right: -25
  },
  barTitle: {
    [theme.breakpoints.down('xs')]: {
      width: 50
    }
  },
  barTitleTxt: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  langSelection: {
    // backgroundColor: 'transparent',
    // color: COLORS.white,
    borderRadius: 5,
    height: 12,
    minWidth: 14,
    padding: '2px 8px',
    cursor: 'pointer',
    // float: 'right',
    fontSize: 11,
    // textAlign: 'center',
    marginLeft: 5,
    // lineHeight: 12,
    border: '1px solid white',
    display: 'inline-flex',
    justifyContent: 'center',
    textTransform: 'uppercase'
  },
  selectedLang: {
    background: COLORS.white,
    color: COLORS.darkGray,
    cursor: 'default'
  },
  bsRadio: { marginLeft: 10, alignContent: 'center', flexWrap: 'nowrap' },
  qualityAnalysis: {
    color: COLORS.white,
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
      textAlign: 'center'
    }
  },
  bsTabsRoot: {
    minHeight: '30px !important',
    height: '30px !important',
    backgroundColor: COLORS.darkGray
  },
  bsTabsRootPeriskop: {
    minHeight: '30px !important',
    height: '30px !important',
    backgroundColor: COLORS.periskopLightBlue,
    borderTop: `1px solid ${COLORS.lightGray2}`
  },
  bsTabRoot: {
    minHeight: '30px !important',
    height: '30px !important',
    maxWidth: 220,
    width: 'fit-content',
    minWidth: 100,
    paddingLeft: '30px !important',
    paddingRight: '30px !important'
  },
  bsTabRootNarrow: {
    minHeight: '30px !important',
    height: '30px !important',
    maxWidth: 120,
    width: 'fit-content',
    minWidth: '50px !important',
    paddingLeft: '5px !important',
    paddingRight: '5px !important',
    borderRight: '1px solid #aaa'
  },
  bsTabLabel: {
    textTransform: 'initial',
    alignItems: 'flex-start',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'inline-block'
  },
  bsTabSelected: {
    backgroundColor: COLORS.primaryColor
  },
  bsTabSelectedPeriskop: {
    backgroundColor: COLORS.periskopBlue
  },
  mergeBSButtonDiv: {
    position: 'absolute',
    right: 5,
    top: 89
  },
  mergeBSButtonRoot: {
    maxHeight: 24,
    color: COLORS.adminDev,
    '&:hover': {
      backgroundColor: COLORS.adminDev,
      color: COLORS.primaryColor
    }
  },
  mergeBSButtonLabel: {
    maxHeight: 24,
    lineHeight: '24px',
    textTransform: 'none'
  },
  demoSelector: {
    color: COLORS.primaryColor,
    '&:hover': {
      color: COLORS.white
    }
  }
}));
