import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  sensorView: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflowX: 'visible !important',
    height: 'inherit'
  },
  sensorTab: {
    minHeight: 40,
    maxHeight: 40
  }
}));
