import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'utils/colors';

export const useStyles = makeStyles((theme) => ({
  tooltip: {
    position: 'relative',
    display: 'inline-block',
    borderBottom: '1px dotted black' /* If you want dots under the hoverable text */,
    '& tooltiptext': {
      visibility: 'hidden',
      width: 120,
      backgroundcolor: COLORS.black,
      color: COLORS.white,
      textAlign: 'center',
      padding: '5px 0',
      borderRadius: 6,
      position: 'absolute' /* Position the tooltip text - see examples below! */,

      zIndex: 1
    },
    '&:hover .tooltiptext': {
      visibility: 'visible'
    }
  },
  notesContainter: {
    display: 'flex',
    margin: '20px 20px 20px 70px'
  },
  note: {
    textAlign: 'center'
  },
  mainContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    minHeight: 320,
    flexGrow: 1,
    // marginTop: -20,
    zIndex: 2,
    '& svg': {
      overflow: 'visible',
      zIndex: 3
    },
    [theme.breakpoints.up('xs')]: {
      height: 388
    }
  },
  overviewBg: {
    backgroundColor: COLORS.snow
  },
  depthSelector: {
    margin: '2px 0px 0 20px',
    zIndex: 100
  },
  depthSelectorButton: {
    fontSize: 12,
    width: 50,
    padding: 2,
    textTransform: 'initial',
    color: COLORS.darkGray
  },
  auxDepth: {
    fontSize: 10
  },
  depthTitle: {
    margin: '40px -15px 0px 20px',
    fontSize: 12
  },
  ovewviewIndicator: {
    position: 'absolute',
    width: '100%',
    textAlign: 'left',
    fontSize: 12,
    marginTop: 11,
    marginLeft: 22
  },
  ovewviewIcon: {
    display: 'inline-block',
    color: COLORS.mediumGray,
    width: 16,
    height: 16,
    marginRight: 5
  },
  ovewviewText: {
    display: 'inline-block',
    height: 18,
    color: COLORS.mediumGray,
    verticalAlign: 'middle'
  },
  noDataDiv: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    position: 'absolute',
    opacity: 0.4,
    width: 'inherit',
    height: 'inherit'
  },
  noDataTypo: {
    width: 'inherit'
  }
}));

export const chartStyles = {
  verticalGrid: {
    grid: { stroke: COLORS.lightGray, strokeWidth: 1 },
    axis: { strokeWidth: 0 },
    ticks: { stroke: COLORS.lightGray, strokeWidth: 1 },
    tickLabels: {
      fill: COLORS.mediumGray,
      fontFamily: 'inherit',
      fontSize: 12
    }
  },
  verticalGrid2: {
    grid: { stroke: COLORS.secondaryColor, strokeWidth: 0.6 }
  },
  horizontalGrid: {
    grid: { stroke: COLORS.lightGray, strokeWidth: 1 },
    axis: { strokeWidth: 0 },
    ticks: { stroke: COLORS.lightGray, strokeWidth: 1 },
    tickLabels: {
      fill: COLORS.mediumGray,
      fontFamily: 'inherit',
      fontSize: 12
    }
  },
  rangeStick: {
    grid: { stroke: COLORS.mediumGray, strokeWidth: 1 },
    axis: { strokeWidth: 0 },
    ticks: { strokeWidth: 0 },
    tickLabels: {
      fill: 'rgba(0,0,0,0)'
    }
  }
};

export const signalStyle = [
  { fill: COLORS.primaryColor, fontFamily: 'inherit', fontSize: 11 },
  { fill: COLORS.chetwodeBlue, fontFamily: 'inherit', fontSize: 15, fontWeight: 'bold' },
  { fill: COLORS.lightBlue, fontFamily: 'inherit', fontSize: 15, fontWeight: 'bold' }
];

// export const allStyle = [
//   { fill: COLORS.primaryColor, fontFamily: 'inherit', fontSize: 11 },
//   {
//     fill: COLORS.adminProd,
//     fontFamily: 'inherit',
//     fontSize: 13,
//     fontWeight: 'bold'
//   },
//   { fill: COLORS.boulderGray, fontFamily: 'inherit', fontSize: 12 },
//   {
//     fill: COLORS.chetwodeBlue,
//     fontFamily: 'inherit',
//     fontSize: 13,
//     fontWeight: 'bold'
//   },
//   { fill: COLORS.orange, fontFamily: 'inherit', fontSize: 13, fontWeight: 'bold' }
// ];

export const voltageStyle = [
  { fill: COLORS.primaryColor, fontFamily: 'inherit', fontSize: 11 },
  { fill: COLORS.orange, fontFamily: 'inherit', fontSize: 15, fontWeight: 'bold' }
];

export const humidityStyle = [
  { fill: COLORS.primaryColor, fontFamily: 'inherit', fontSize: 11 },
  { fill: COLORS.mediumPurple, fontFamily: 'inherit', fontSize: 15, fontWeight: 'bold' }
];
