import { SET_INSIGHT_SERVICE } from 'redux/constants';
import initialState from 'redux/store/initialState';

export default function setInsightService(state = initialState.insightService, action) {
  switch (action.type) {
    case SET_INSIGHT_SERVICE: {
      return action.value;
    }
    default: {
      return state;
    }
  }
}
