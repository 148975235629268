import { add, format } from 'date-fns';
import { getDateFromStr } from 'utils/getDateFromStr';
import { getPlanDate, getEndDate, planHandler } from 'assets/utils';
import {
  postConfirmPayment,
  postCharge,
  getUserInfo,
  getBsConf,
  postInvoiceRequest
} from 'api/serverAPI';

const i18nFr = require('assets/i18n/i18nStripe/fr.json');
const i18nDe = require('assets/i18n/i18nStripe/de.json');
// const i18nEn = require('assets/i18n/i18nStripe/en.json');
// const i18nNl = require('assets/i18n/i18nStripe/nl.json');
// const i18nIt = require('assets/i18n/i18nStripe/it.json');
const i18nFi = require('assets/i18n/i18nStripe/fi.json');
// const i18nHr = require('assets/i18n/i18nStripe/hr.json');

export function setErrorLanguage(i18n) {
  switch (i18n.locale) {
    case 'fr':
      return i18nFr;
    case 'de':
      return i18nDe;
    // case 'nl':
    //   return i18nNl;
    // case 'it':
    //   return i18nIt;
    case 'fi':
      return i18nFi;
    // case 'hr':
    //   return i18nHr;
    default:
      return '';
  }
}

export const productsToInclude = (
  upgradeInfo,
  renewalInfo,
  simRenewalOnly,
  APIRenewalOnly,
  renewalUnauthorizedPlan,
  renewalUnauthorizedSim,
  renewalUnauthorizedAPI,
  isFreeUser,
  insightService
) => {
  let simPlans = [];
  if (!isFreeUser || insightService) {
    const dueDateShortened =
      !insightService &&
      upgradeInfo.currentPlan.dueDate.substr(0, upgradeInfo.currentPlan.dueDate.length - 6);
    simPlans = simRenewalOnly
      ? upgradeInfo.simExp?.filter((sim) => sim.exp === renewalInfo.sim.sim_exp_date)
      : upgradeInfo.simExp?.filter((sim) => sim.exp <= dueDateShortened) || [];
  }
  const APICheckout =
    APIRenewalOnly ||
    (renewalUnauthorizedPlan && renewalUnauthorizedSim && !renewalUnauthorizedAPI);
  return {
    api: APICheckout ? 1 : 0,
    plan: APIRenewalOnly || simRenewalOnly || renewalUnauthorizedPlan ? 0 : 1,
    sim: APICheckout
      ? 0
      : simRenewalOnly
      ? simPlans?.length
      : upgradeInfo?.simExp?.filter(
          (sim) =>
            sim.exp <=
            upgradeInfo.currentPlan?.dueDate?.substr(
              0,
              upgradeInfo.currentPlan?.dueDate?.length - 6
            )
        ).length || 0
  };
};

export const getOrderSummary = (
  currentPlan,
  dateFormat,
  renewal,
  upgradeInfo,
  i18n,
  renewalInfo,
  isFreeUser
) => {
  const planDate =
    (!isFreeUser &&
      getPlanDate(currentPlan, dateFormat, currentPlan?.dueDate, currentPlan?.expired, true)) ||
    format(new Date(), dateFormat ?? 'yyyy-MM-dd');
  const planDateAfterCheckout = getEndDate(
    currentPlan,
    renewal ? renewal?.months : upgradeInfo.months,
    dateFormat,
    null,
    currentPlan?.expired,
    true
  );

  const planDates = `${planDate} → ${planDateAfterCheckout}`;
  const planLabel = (
    <div>
      {renewal?.name}
      <div>{planDates}</div>
    </div>
  );

  const planPrice = upgradeInfo.products.plan * (renewal ? renewal?.price : 0);
  const planVTA = (planPrice * upgradeInfo.taxRate) / 100;
  const planPriceWithVAT = planPrice * (1 + upgradeInfo.taxRate / 100);

  // SIM
  const simPrice = upgradeInfo.simPrice
    ? upgradeInfo.products.sim * upgradeInfo.simPrice?.price
    : 0;
  const simVTA = (simPrice * upgradeInfo.taxRate) / 100;
  const simPriceWithVAT = simPrice * (1 + upgradeInfo.taxRate / 100);
  const simDate = getPlanDate(
    currentPlan,
    dateFormat,
    upgradeInfo.simExp ? upgradeInfo.simExp[0].exp : null,
    null,
    true
  );
  const simDateAfterCheckout = getEndDate(
    currentPlan,
    upgradeInfo.simPrice?.months,
    dateFormat,
    upgradeInfo.simExp ? upgradeInfo.simExp[0].exp : null,
    null,
    true
  );

  const simDates = `${simDate} → ${simDateAfterCheckout}`;
  const simLabel = (
    <div>
      {`${i18n.msg_sim_subscription}, ${renewalInfo?.bs_id}`}
      <div>{simDates}</div>
    </div>
  );

  // API
  const apiPrice =
    upgradeInfo.products.api * (upgradeInfo.apiPrice ? upgradeInfo.apiPrice?.price : 0);
  const apiVTA = (apiPrice * upgradeInfo.taxRate) / 100;
  const apiPriceWithVAT = apiPrice * (1 + upgradeInfo.taxRate / 100);
  const apiDate = getPlanDate(
    currentPlan,
    dateFormat,
    upgradeInfo.apiPlan ? upgradeInfo.apiPlan.due : null,
    null,
    true
  );
  const apiDateAfterCheckout = getEndDate(
    currentPlan,
    upgradeInfo.apiPrice?.months,
    dateFormat,
    upgradeInfo.apiPlan ? upgradeInfo.apiPlan.due : null,
    null,
    true
  );

  const apiDates = `${apiDate} → ${apiDateAfterCheckout}`;
  const apiLabel = (
    <div>
      {upgradeInfo.apiPrice?.name} ({upgradeInfo.apiPrice?.months} {i18n.months})
      <div>{apiDates}</div>
    </div>
  );

  // TOTAL
  // upgradeInfo.products.plan * renewal.price +
  // (upgradeInfo.simPrice ? upgradeInfo.products.sim * upgradeInfo.simPrice?.price : 0);

  const subTotal = apiPrice + planPrice + simPrice;
  const totalVTA = apiVTA + planVTA + simVTA;
  const totalPrice = apiPriceWithVAT + planPriceWithVAT + simPriceWithVAT;

  return {
    planLabel,
    planPrice,
    simPrice,
    simLabel,
    apiPrice,
    apiLabel,
    subTotal,
    totalVTA,
    totalPrice
  };

  // (upgradeInfo.renewal?.price * (1 + upgradeInfo.taxRate / 100)).toFixed(2)
  // isFreeUser
  //   ? upgradeInfo.prices[1].price
  //   : upgradeInfo.sim_renewal_only && bsConf
  //     ? productsToInclude.sim * upgradeInfo.simPrice?.price
  //     : productsToInclude.plan * upgradeInfo.renewal?.price +
  //     productsToInclude.sim * (upgradeInfo.simPrice?.price || 0);
};

export const getDescription = (includedProducts, upgradeInfo, i18n) =>
  includedProducts.api
    ? `${upgradeInfo.apiPrice?.name} (${upgradeInfo.apiPrice?.months} ${i18n.months})`
    : `${
        includedProducts.plan
          ? `${upgradeInfo.renewal?.name} (${upgradeInfo.renewal?.months} ${i18n.months})`
          : ''
      }${includedProducts.plan && includedProducts.sim ? ' + ' : ''}${
        includedProducts.sim
          ? `${includedProducts.sim > 1 ? `${includedProducts.sim}x ` : ''}${
              i18n.msg_sim_subscription
            } (${upgradeInfo.simPrice?.months} ${i18n.months})`
          : ''
      }`;

export const generateBillingInfo = (userInfo) => {
  /* eslint-enable global-require */

  const { firstname, email, lastname, address, zip, city, country } = userInfo;

  console.log('**userInfo: ', userInfo);
  const newBillingInfo = {};
  newBillingInfo.name = firstname || '';
  newBillingInfo.email = email || '';
  newBillingInfo.company = lastname || '';
  newBillingInfo.address = address || '';
  newBillingInfo.zip = zip || '';
  newBillingInfo.city = city || '';
  newBillingInfo.country = country;
  return { billingInfo: newBillingInfo };
};

export const generateCharge = (
  tokenId,
  renewalInfo,
  upgradeInfo,
  billingInfo,
  companyName,
  totalPrice,
  i18n
) => ({
  stripeToken: tokenId,
  customerName: billingInfo.name,
  receiptEmail: billingInfo.email,
  companyName,
  uid: renewalInfo.U_ID,
  simRenewals: upgradeInfo.products.sim >= 1 ? upgradeInfo.simExp : null,
  planRenewal:
    upgradeInfo.products.plan === 1
      ? {
          months: upgradeInfo.renewal.months,
          level: upgradeInfo.renewal.level,
          exp: upgradeInfo.currentPlan?.dueDate
            ? upgradeInfo.currentPlan?.dueDate
            : format(new Date(), 'yyyyMMdd235959')
        }
      : null,
  apiRenewal: upgradeInfo.products.api === 1 ? upgradeInfo.apiPlan : null,
  // // oldPlan: userInfo.plans,
  orderDescription: getDescription(upgradeInfo.products, upgradeInfo, i18n),
  // planInfo: [{ planLevel: 4, dueDate: '20210330235959', segment: '14' }],
  // planInfo: { ...plan.currentPlan, months: plan.renewal.months },
  // products: plan.products,
  price: Math.round(totalPrice * 100),
  // percent: plan.taxRate,
  currency: upgradeInfo.currency,
  // planName: plan.renewal.name,
  billingInfo
});

export const generateConfirm = (responseCharge, plan, billingInfo, renewalInfo) => ({
  // client_secret: responseCharge.client_secret,
  intent_id: responseCharge.intent_id,
  // customerName: billingInfo.name,
  receiptEmail: billingInfo?.email,
  uid: renewalInfo.U_ID,
  simRenewals: plan?.simRenewals,
  planRenewal: plan?.planRenewal,
  apiRenewal: plan?.apiRenewal
  // oldPlan: userInfo.plans,
  // orderDescription: plan.orderDescription,
  // planInfo: plan.planInfo,
  // products: plan.products,
  // price: plan.price,
  // percent: plan.percent,
  // currency: plan.currency,
  // planName: plan.planName,
  // billingInfo
});

export const getConfirm = async (
  confirmInfo,
  responseCharge,
  renewalInfo,
  setProcessing,
  setSuccess,
  setError,
  dispatch,
  invoiceRequest,
  setSuccessInvoice,
  i18nStripe,
  stripe
) => {
  const confirm =
    confirmInfo ||
    generateConfirm(
      responseCharge,
      responseCharge.data,
      responseCharge.data?.billingInfo,
      renewalInfo
    );

  const updatingState = async () => {
    let responseUser;
    let responseBSConf;
    try {
      responseUser = await getUserInfo({ UID: renewalInfo.U_ID });
      responseBSConf = await getBsConf({
        basestationID: renewalInfo.bs_id
      });
      if (responseUser.status_code === 0 && responseBSConf.status_code === 0) {
        dispatch({ type: 'SET_USERINFO', value: responseUser.userinfo });
        dispatch({ type: 'SET_BSCONF', value: responseBSConf.conf_data });
        const { plan } = renewalInfo;
        planHandler(plan, dispatch);
      }
      setSuccess(true);
      setProcessing(false);
    } catch (err) {
      setError(err);
      setProcessing(false);
      console.log('err: ', err);
    }
  };

  if (invoiceRequest) {
    updatingState();
    setSuccessInvoice(true);
  } else {
    try {
      const responseConfirm = await postConfirmPayment({
        ...confirm,
        alreadyConfirmed: !!confirmInfo
      });
      console.log('responseConfirm', responseConfirm);
      if (responseConfirm.actionRequired) {
        // We perform 3D Secure authentication
        const { paymentIntent, error } = await stripe.confirmCardPayment(
          responseConfirm.clientSecret
        );
        if (error) {
          setProcessing(false);
          setError('Error in payment, please try again later');
        }
        if (paymentIntent.status === 'succeeded')
          // return alert(`Payment successful, payment ID - ${responseConfirm.id}`);
          getConfirm(
            confirm,
            responseCharge,
            renewalInfo,
            setProcessing,
            setSuccess,
            setError,
            dispatch,
            invoiceRequest,
            setSuccessInvoice,
            i18nStripe,
            stripe
          );

        // alert(`Payment successful, payment ID - ${responseConfirm.id}`);
      } else if (responseConfirm.status_code === 0) {
        updatingState();
      } else {
        setProcessing(false);
        console.log('error 1');

        setError({
          ...responseConfirm.error,
          message: i18nStripe[responseConfirm.error?.code] || responseConfirm.error?.message
        });
      }
    } catch (error) {
      // console.log('error 2');
      console.log('Confirmation.js 28 | ', error);
      setError({
        ...error,
        message:
          i18nStripe.failed_to_process_payment || 'An error occurred while processing your order.'
      });
      setProcessing(false);
    }
  }
};

export const getCurrencySign = (currency) => {
  switch (currency) {
    case 'dol':
      return '$';
    default:
      return '€';
  }
};

export const checkoutAvailabilityCheck = (
  isFreeUser,
  state,
  renewalUnauthorizedService,
  setRenewalUnauthorizedService,
  renewalUnauthorizedPlan,
  setRenewalUnauthorizedPlan,
  renewalUnauthorizedSim,
  setRenewalUnauthorizedSim,
  renewalUnauthorizedAPI,
  setRenewalUnauthorizedAPI,
  setUnauthorized
) => {
  let mounted1 = false;
  let mounted2 = false;
  let mounted3 = false;
  const dateAllowedRenewal = add(new Date(), {
    months: 2
  });

  if (state) {
    if (state.service) {
      if (dateAllowedRenewal > new Date(state.service.nextPayment.date)) {
        setRenewalUnauthorizedService(false);
      } else {
        setRenewalUnauthorizedService(true);
      }
    } else {
      setRenewalUnauthorizedService(true);
    }

    if (state.plan && !state.service) {
      const dueDatePlan = state?.plan.dueDate
        ? +getDateFromStr(state?.plan.dueDate)
        : state?.plan.expired
        ? +getDateFromStr(state?.plan.expired)
        : null;
      if (isFreeUser) {
        setRenewalUnauthorizedPlan(false);
      } else if (dateAllowedRenewal > dueDatePlan) {
        setRenewalUnauthorizedPlan(false);
      } else {
        setRenewalUnauthorizedPlan(true);
      }
    } else {
      setRenewalUnauthorizedPlan(true);
    }
    mounted1 = true;

    if (state.sim?.sim_exp_date && state.sim?.simgroup !== 'f') {
      const dueDateSim = +getDateFromStr(
        state.sim?.sim_exp_date,
        state.sim?.sim_exp_date?.split('')?.length < 9
      );

      if (dateAllowedRenewal > dueDateSim) {
        setRenewalUnauthorizedSim(false);
      } else {
        setRenewalUnauthorizedSim(true);
      }
    } else {
      setRenewalUnauthorizedSim(true);
    }
    mounted2 = true;

    if (state.api?.due) {
      const dueDateAPI = +getDateFromStr(state.api?.due);
      if (dateAllowedRenewal > dueDateAPI) {
        setRenewalUnauthorizedAPI(false);
      } else {
        setRenewalUnauthorizedAPI(true);
      }
    } else {
      setRenewalUnauthorizedAPI(true);
    }

    mounted3 = true;
  }
  setTimeout(() => {
    if (mounted1 && mounted2 && mounted3) {
      if (renewalUnauthorizedPlan && renewalUnauthorizedSim && renewalUnauthorizedAPI) {
        setUnauthorized(true);
      } else {
        setUnauthorized(false);
      }
    }
  }, 500);
};

export const payHandler = async (
  stripe,
  elements,
  CardElement,
  cardComplete,
  upgradeInfo,
  billingDetails,
  companyName,
  totalPrice,
  i18n,
  i18nStripe,
  setProcessing,
  setSuccess,
  setError,
  dispatch,
  renewalInfo,
  invoiceRequest,
  setSuccessInvoice
) => {
  const processSubmit = async (paymentMethod) => {
    try {
      const charge = generateCharge(
        paymentMethod?.id,
        renewalInfo,
        upgradeInfo,
        billingDetails,
        companyName,
        totalPrice,
        i18n
      );
      let response;
      if (invoiceRequest) {
        response = await postInvoiceRequest({
          ...charge,
          servicePrice: upgradeInfo.renewal?.price,
          sims: upgradeInfo.simExp?.map(
            (sim) =>
              `${sim.bs}: ${sim.exp.substr(0, 4)}-${sim.exp.substr(4, 2)}-${sim.exp.substr(6, 2)}`
          ),
          simPrice: upgradeInfo.simPrice?.price,
          taxRate: upgradeInfo.taxRate,
          bsIDs: renewalInfo.bsIDs
        });
      } else {
        response = await postCharge(charge);
        console.log('Stripe 35 | data', response);
      }
      if (response.status_code === 0) {
        console.log('CheckoutForm.js 25 | charge successful, waiting for confirmation!');
        await getConfirm(
          null,
          response,
          renewalInfo,
          setProcessing,
          setSuccess,
          setError,
          dispatch,
          invoiceRequest,
          setSuccessInvoice,
          i18nStripe,
          stripe
        );
      } else {
        // console.log('error 3');
        setError({
          ...response,
          message: i18nStripe[response?.code] || response?.message
        });
        setProcessing(false);
      }
    } catch (error) {
      // console.log('error 4', error);
      console.log('CheckoutForm.js 28 | ', error);
      // setError({
      //   ...error,
      //   message: i18nStripe[error?.code] || error?.message || ''
      // }
      setError(error);
      setProcessing(false);
    }
  };

  if (!stripe || !elements) {
    // Stripe.js has not loaded yet. Make sure to disable
    // form submission until Stripe.js has loaded.
    return;
  }
  setProcessing(true);

  if (invoiceRequest) {
    processSubmit();
  } else {
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: billingDetails
    });

    // const { token } = await stripe.createToken(elements.getElement(CardElement));

    // console.log('token', token);

    if (error) {
      // console.log('error 5', error);
      elements.getElement('card').focus();
      setProcessing(false);
      setError({
        ...error,
        message: i18nStripe[error?.code] || error?.message
      });
      return;
    }

    if (cardComplete || invoiceRequest) {
      setProcessing(true);
    }

    if (!error || invoiceRequest) {
      console.log('Stripe 23 | token generated!', paymentMethod);
    }
    processSubmit(paymentMethod);
  }
};
