import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'utils/colors';

export const useStyles = makeStyles({
  hide: {
    display: 'none'
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'auto'
  },
  dataContainer: {
    height: '100%',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  dataOverlay: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    bottom: 0,
    margin: 0,
    zIndex: 1000
  },
  dataSpinner: {
    marginTop: '-30vh'
  },
  noData: {
    height: 80,
    left: '15%',
    textAlign: 'center',
    opacity: 0.4,
    marginTop: 100
  },
  duplicate: {
    color: COLORS.invalidStatus
  }
});
