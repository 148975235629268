import { ListItem, ListItemText } from '@material-ui/core';
import React from 'react';

import { COLORS } from 'utils/colors';
import { SETTINGS } from './SettingProps';
import { useStyles } from './Settings.styled';

const ListItemWithText = ({ i18n, id, data, toggleSettings, highlight }) => {
  const classes = useStyles();
  const { value } = data[id];

  const settings = SETTINGS();

  return (
    <ListItem
      className={classes.divider}
      button
      onClick={() => toggleSettings(id, settings[id].type)}
    >
      <ListItemText
        primaryTypographyProps={highlight ? { style: { color: COLORS.alert } } : null}
        className={classes.capitalize}
        primary={i18n[id] || settings[id].title}
        secondary={i18n[settings[id].data[value]] || settings[id].data[value]}
      />
    </ListItem>
  );
};

export default React.memo(ListItemWithText);
