import { Button } from '@material-ui/core';
import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Link, useRouteMatch, useHistory } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { isEmpty, intersection } from 'lodash';
import plusCircle from '@iconify/icons-mdi/plus-circle';

import cogIcon from '@iconify/icons-mdi/cog';
import { getMaxPlan } from 'assets/utils';
import { listStyle } from 'utils/sharedStyles';
import { Spinner } from 'utils/Spinners';
import ExpiredDialog from 'components/general/ExpiredDialog';
import ExpiredInsightFreemium from 'components/general/ExpiredInsightFreemium';
import StorageListItem from '../storage-list-item/StorageListItem';
import { StorageContext } from '../StorageContext';

const StorageList = ({ loadingStorages, storages }) => {
  const classes = listStyle();
  const { url } = useRouteMatch();
  const history = useHistory();

  const {
    i18n,
    userInfo,
    bsConf,
    isSimExpired,
    isAdmin,
    isPlanExpired,
    sensorConfigurations,
    auxConfIDs
  } = useSelector((store) => ({
    i18n: store.i18n,
    bsConf: store.bsConf,
    userInfo: store.userInfo,
    isSimExpired: store.isSimExpired,
    isAdmin: store.isAdmin,
    isPlanExpired: store.isPlanExpired,
    sensorConfigurations: store.sensorConfigurations,
    auxConfIDs: store.auxConf?.map((aux) => aux.a)
  }));

  const plan = getMaxPlan(userInfo?.plans, userInfo?.service);
  const trial = userInfo?.service?.trial;
  const oldFreemiumExpiredTrial = trial && (trial === 2 || trial === -1) && isPlanExpired;

  // eslint-disable-next-line no-unused-vars
  const { _, dispatch } = useContext(StorageContext);

  // const history = useHistory();
  const [scrollPosition, setScrollPosition] = useState(0);

  // Restoring the scroll position after returning from batch details
  useEffect(() => {
    if (document.getElementById('theStorageListContainer')) {
      const scrollNow = document.getElementById('theStorageListContainer').scrollTop;
      if (scrollNow === 0) {
        // set the stored scroll position, as scrollNow will be 0 after rendering the list
        document.getElementById('theStorageListContainer').scrollTop = scrollPosition;
      }
    }
  });

  // const onstorageSelection = itemID => {
  //   dispatch({ type: 'PROBE_SELECTED', value: false }); // clear probe selection
  //   dispatch({ type: 'SHOW_PREFS', value: false }); // no prefs show by default
  //   storageSelection(itemID);
  // };

  const handleScroll = () => {
    const position = document.getElementById('theStorageListContainer')
      ? document.getElementById('theStorageListContainer').scrollTop
      : 0;
    // Store the current scroll position to upper level (Batches)
    setScrollPosition(position);
  };

  if (loadingStorages || !userInfo) {
    // console.log('Spinner in BatchList:');
    return <Spinner />;
  }

  const isOtherBSStorage = (item) => {
    const sensorIDsPlaced =
      item?.layoutType === 3
        ? item?.stacks
            .map((s) => s.sensors)
            .flat()
            .map((s) => s.id)
        : item?.sensor_layout
        ? item.sensor_layout?.sensors?.filter((sen) => sen.column >= 0).map((sen) => sen.id) || []
        : [];

    return (
      !isEmpty(sensorIDsPlaced) &&
      isEmpty(
        intersection(
          sensorIDsPlaced,
          sensorConfigurations.map((s) => s.sensor_id_sys).concat(auxConfIDs)
        )
      )
    );
  };

  const goToSensorSettings = () => {
    const path = '/settings/probes';
    history.push(path);
  };

  // Sort A-Z
  const sortStorages = (a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1);

  return (plan.planLevel >= 3 || plan.plan === 2) && (!isPlanExpired || isAdmin) ? (
    isSimExpired ? (
      <div className={classes.welcome}>
        <h2>{i18n.storage_dashboard || 'Storage monitoring dashboard'}</h2>
        <>{i18n.sim_expired_msg}</>
      </div>
    ) : (
      <div id='theStorageListContainer' onScroll={handleScroll} className={classes.listMain}>
        {(!bsConf?.sensor_configurations || bsConf?.sensor_configurations.length === 0) &&
        storages.length === 0 ? (
          // No sensors.
          <div className={classes.welcome}>
            <h2>{i18n.storage_dashboard || 'Storage monitoring dashboard'}</h2>
            <p>{i18n.no_sensors_welcome || 'Get started by adding probes in the settings view.'}</p>
            <Button
              variant='contained'
              color='primary'
              startIcon={<Icon icon={cogIcon} />}
              onClick={() => goToSensorSettings()}
            >
              {i18n.go_to_sensor_settings || 'Open Settings view'}
            </Button>
          </div>
        ) : storages?.length >= 1 ? (
          <div className={classes.list}>
            {storages
              ?.sort((a, b) => sortStorages(a, b))
              .map((storage) =>
                isOtherBSStorage(storage) ? (
                  <StorageListItem
                    item={storage}
                    disabled
                    key={storage.itemID}
                    // handleClick={onstorageSelection}
                  />
                ) : (
                  <Link
                    key={storage.itemID}
                    className={classes.noLink}
                    to={`${url}/${storage.itemID}`}
                  >
                    <StorageListItem
                      item={storage}
                      disabled={isOtherBSStorage(storage)}
                      key={storage.itemID}
                      // handleClick={onstorageSelection}
                    />
                  </Link>
                )
              )}
          </div>
        ) : (
          <div className={classes.welcome}>
            <h2>{i18n.storage_dashboard || 'Storage monitoring dashboard'}</h2>
            <p>{i18n.storages_welcome || 'Get monitoring started by creating a storage!'}</p>
            <Link className={classes.noLink} to='/storages/new-storage'>
              <Button
                variant='contained'
                color='primary'
                startIcon={<Icon icon={plusCircle} />}
                // onClick={() => createStorageHandler()}
              >
                {i18n.new_storage || 'New storage'}
              </Button>
            </Link>
          </div>
        )}
      </div>
    )
  ) : (
    <div className={classes.welcome}>
      <h2>{i18n.storage_dashboard || 'Storage monitoring dashboard'}</h2>

      {userInfo.plans && userInfo.plans[0] && userInfo.plans[0].planLevel === 3 ? (
        <>
          {i18n.reminder_text1} <span className={classes.bold}>Quanturi Premium</span>{' '}
          {i18n.reminder_text2_expired}
        </>
      ) : isPlanExpired ? (
        <>
          <p>{i18n.supervisor_description}</p>
          {oldFreemiumExpiredTrial ? <ExpiredInsightFreemium /> : <ExpiredDialog />}
        </>
      ) : (
        <p>{i18n.storages_no_supervisor}</p>
      )}
    </div>
  );
};

export default StorageList;
