import { Select, MenuItem, ListItemIcon } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import imageFilterHdr from '@iconify/icons-mdi/image-filter-hdr';
import warehouseIcon from '@iconify/icons-mdi/warehouse';
import { Icon } from '@iconify/react';
import { COLORS } from 'utils/colors';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: 200,
//     backgroundColor: COLORS.white,
//     color: `${theme.palette.text.primary}`,
//     border: 'none'
//   },
//   input: {
//     height: '10px',
//     border: 'none'
//   }
// }));

const SensorGroupSelect = ({ selectedGroup, groups, onChange }) => {
  // const classes = useStyles();
  const [selectedOption, setSelectedOption] = useState(selectedGroup?.value || 0);

  useEffect(() => {
    // setSelectedOption(0);
    // console.log('groups: ', groups);
    // console.log('selectedOption: ', selectedOption);
  });

  const onSelectChange = (val) => {
    setSelectedOption(val.target.value);
    if (onChange) {
      onChange(val.target);
    }
  };

  return (
    <Select style={{ minWidth: 180 }} value={selectedOption} onChange={onSelectChange}>
      {groups &&
        groups.map((grp, i) => (
          <MenuItem
            key={i}
            style={{
              color: grp.type === 2 ? COLORS.lightGray3 : 'inherit',
              borderBottom: grp.last ? `1px solid ${COLORS.lightGray2}` : 'none'
            }}
            value={grp.value}
            disabled={grp.disabled}
          >
            {grp.value === '0' || grp.value === '-1' ? null : (
              <ListItemIcon style={{ minWidth: 30 }}>
                <Icon
                  style={{
                    marginLeft: 10,
                    marginRight: 10,
                    color: grp.type === 2 ? COLORS.lightGray3 : COLORS.mediumGray
                  }}
                  icon={grp.type === 'sto' ? warehouseIcon : imageFilterHdr}
                />
              </ListItemIcon>
            )}
            {grp.label}
          </MenuItem>
        ))}
    </Select>
    // <Select
    //   classes={classes}
    //   options={groups}
    //   value={selectedGroup}
    //   // getOptionLabel={(option) => option.label || option}
    //   // getOptionSelected={(option) => option.value}
    //   onChange={(e, val) => onSelectChange(val)}
    //   size='small'
    // />
  );
};

export default SensorGroupSelect;
