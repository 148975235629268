import { Tooltip, Card, CardContent, Typography, Button } from '@material-ui/core';
import React, { useContext } from 'react'; // useEffect
import { useSelector } from 'react-redux';
import { MdAdd } from 'react-icons/md';
import { useRouteMatch } from 'react-router-dom';
import { Icon } from '@iconify/react';
import plusCircle from '@iconify/icons-mdi/plus-circle';
import { StyledFab, templateMonitorStyle, listStyle } from 'utils/sharedStyles';
import { Spinner } from 'utils/Spinners';
import MonitorEditor from './monitor-editor/MonitorEditor';
import { StorageContext } from '../StorageContext';
import MonitorRules from './monitor-utils/MonitorRulesUtils';

const MonitorsView = ({
  monitors,
  setMonitors,
  setMonitorsChanges,
  // createMonitor,
  // setCreateMonitor,
  setSelectedMonitor,
  selectedMonitor,
  storages,
  // setStoragesChanges,
  FC_IDS
  // FcState
}) => {
  const { url } = useRouteMatch();
  const monitorURL = url.split('/')[url.split('/').length - 1];
  // const reduxDispatch = useDispatch();
  // const history = useHistory();
  const classes = templateMonitorStyle();
  const classesNoMonitor = listStyle();
  const { state } = useContext(StorageContext);
  const [createMonitor, setCreateMonitor] = React.useState(false);
  // const [isMonitorEdited, setIsMonitorEdited] = useState(false);

  // const [editMonitor, setEditMonitor] = useState(false);
  // const [selectedMonitor, setSelectedMonitor] = useState(null);
  const { i18n, userInfo, FcStatus, isSimExpired } = useSelector((store) => ({
    i18n: store.i18n,
    userInfo: store.userInfo,
    FcStatus: store.FcStatus,
    isSimExpired: store.isSimExpired
  }));

  const MonitorSelected = (MonitorID) => {
    if (monitors && MonitorID) {
      const theMonitor = monitors.find((temp) => temp === MonitorID);
      if (theMonitor) {
        setCreateMonitor(true);
        // dispatch({ type: 'SELECT_Monitor', value: theMonitor });
        setSelectedMonitor(theMonitor);
        return;
      }
    }
    setSelectedMonitor(null);
  };

  // if (Number.isFinite(+itemID)) {
  //   console.log('first if', itemID);
  if (!monitors && monitorURL) {
    // console.log('second if');
    return <Spinner />;
    // }
  }

  // const closeMonitorCreate = () => {
  //   setCreateMonitor(false);
  //   // history.replace('/batches/Monitors');
  // };

  const monitorsList = monitors?.map((Monitor) => (
    <div key={Monitor.itemID} className={classes.cardWrap}>
      <Card
        // style={{ backgroundColor: COLORS.whiteSmoke }}
        className={classes.card}
        // item={Monitor}
        onClick={() => MonitorSelected(Monitor)}
      >
        <CardContent>
          <Typography className={classes.title} color='textSecondary' gutterBottom>
            {Monitor.name}
          </Typography>
          <MonitorRules
            monitor={Monitor}
            state={state}
            lastBottomMargin={-15}
            FcStatus={FcStatus}
          />
        </CardContent>
      </Card>
    </div>
  ));

  const MonitorCreate = () => {
    setCreateMonitor(true);
    // setMonitorsChanges(true);
    // dispatch({ type: 'RESET' });
  };

  const newMonitorButton = (
    // <Link className={classes.noLink} to={`${url}/new-monitor`}>
    <Tooltip title={i18n.add_monitor || 'Add monitor...'} placement='left' disableFocusListener>
      <StyledFab style={{ zIndex: 1 }} color='primary' onClick={() => MonitorCreate()}>
        <MdAdd size='2em' />
      </StyledFab>
    </Tooltip>
    // </Link>
  );

  return (
    <>
      <MonitorEditor
        // closeMonitorCreate={closeMonitorCreate}
        // onClose={() => closeMonitorCreate()}
        createMonitor={createMonitor}
        setCreateMonitor={setCreateMonitor}
        userInfo={userInfo}
        selectedMonitor={selectedMonitor}
        // monitors={monitors}
        setMonitorsChanges={setMonitorsChanges}
        setSelectedMonitor={setSelectedMonitor}
        // monitorEdited={monitorEdited}
        // setMonitorEdited={setMonitorEdited}
        storages={storages}
        // setStoragesChanges={setStoragesChanges}
        FC_IDS={FC_IDS}
        FcStatus={FcStatus}
        monitors={monitors}
        setMonitors={setMonitors}
        periskopMonitor={false}
      />
      {!isSimExpired && newMonitorButton}
      {isSimExpired ? (
        <div className={classesNoMonitor.welcome}>
          <h2>{i18n.monitor_dashboard || 'Monitor monitoring dashboard'}</h2>
          <>{i18n.sim_expired_msg}</>
        </div>
      ) : monitors?.length >= 1 ? (
        <div className={classes.root}>
          <div className={classes.mainContainer}>{monitorsList}</div>
        </div>
      ) : (
        <div className={classesNoMonitor.welcome}>
          <h2>{i18n.monitor_dashboard || 'Monitor monitoring dashboard'}</h2>
          <p>{i18n.monitors_welcome || 'Get monitoring started by creating a monitor!'}</p>
          <Button
            variant='contained'
            color='primary'
            startIcon={<Icon icon={plusCircle} />}
            onClick={() => setCreateMonitor(true)}
          >
            {i18n.new_monitor || 'New monitor'}
          </Button>
        </div>
      )}
    </>
  );
};

export default MonitorsView;
