import { Tooltip } from '@material-ui/core';
import React from 'react';
import { InlineIcon } from '@iconify/react';
import bellOffOutline from '@iconify/icons-mdi/bell-off-outline';
import { listItemTitleStyle } from 'utils/sharedStyles';

// Title with storage name optional no-sms indicator
export default function StorageListItemTitle({
  // phaseCount,
  // isInitial,
  alerted,
  storageName,
  messagingOff, // true, if storage has monitor but nobody receiving alerts
  i18n
  // isAdmin
}) {
  const classes = listItemTitleStyle();

  return (
    <div
      style={alerted ? { paddingLeft: 18 } : {}}
      // className={[classes.itemTitle, isInitial ? classes.isInitial : null].join(' ')}
      className={classes.itemTitle}
    >
      <div className={[classes.itemText, classes.storageTitle].join(' ')}>{storageName}</div>
      {messagingOff ? (
        <Tooltip
          title={`${i18n.notif_status}${i18n.colon}${i18n.disabled}`}
          placement='left'
          disableFocusListener
        >
          <div>
            <InlineIcon style={{ width: 16, height: 16 }} icon={bellOffOutline} />
          </div>
        </Tooltip>
      ) : null}
    </div>
  );
}
