import { Snackbar } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { BrowserRouter, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { io } from 'socket.io-client';
import { Alert } from '@material-ui/lab';
import queryString from 'query-string';

import { isEmpty, size, includes, intersection } from 'lodash'; // intersection 7.6.2023

import { postLogout, postRefreshToken, API_SERVER, getFcItem, getSocket } from 'api/serverAPI'; // API_SERVER, getFcItem 7.6.2023
import { getMaxPlan, getManual, getPlanName, getPlanDate, getServiceDate } from 'assets/utils';
import { getTranslationsData } from 'redux/reducers/setLanguage';
import ProbeActivationDialog from '../ProbeActivationDialog';
import RouteChangeTracker from './RouteChangeTracker';
import { generateAllData, dispatchDataToStore, clearDataToStore, fetchData } from './helper';
import MainAppBar from './MainAppBar';
import MainRoutes from './MainRoutes';
import DrawerPopup from './DrawerPopup';
import DrawerRight from './DrawerRight';
import NotifDialog from './NotifDialog';

const initialState = {
  drawer: false,
  notif: false,
  adminUsername: '',
  isMultiBS: false,
  selectedBS: null,
  basestations: []
};

const MainNavigator = ({ match, Cookies }) => {
  const history = useHistory();

  const dispatch = useDispatch();
  const location = useLocation();
  const {
    i18n,
    supportedLanguages,
    isAdmin,
    userInfo,
    bsConf, // 7.6.2023 bsConf,
    sessionInfo,
    isLoading,
    previousUpdateTS, // 7.6.2023 previousUpdateTS,
    unsavedChanges,
    currentView,
    storeBatches, // 7.6.2023 storeBatches,
    FC_IDS, // 7.6.2023 FC_IDS,
    language
  } = useSelector((store) => ({
    i18n: store.i18n,
    supportedLanguages: store.supportedLanguages,
    isAdmin: store.isAdmin,
    userInfo: store.userInfo,
    bsConf: store.bsConf,
    sessionInfo: store.sessionInfo,
    isLoading: store.isLoading,
    previousUpdateTS: store.previousUpdateTS,
    unsavedChanges: store.unsavedChanges,
    currentView: store.currentView,
    storeBatches: store.batches,
    FC_IDS: store.userInfo?.FC_IDs,
    language: store.sessionInfo?.ui_preferences?.language
  }));
  const [state, setState] = useState(initialState);
  const [isOnline, setIsOnline] = useState(true); // 7.6.2023 ,setIsOnline
  const [socketEnabled, setSocketEnabled] = useState(false);

  const [noProbesDialogOpen, setNoProbesDialogOpen] = useState(false);

  // // Check to show all basestation tabs
  // useEffect(() => {
  //   if (userInfo) {
  //     console.log('Check to show all basestation tabs');
  //     setState((tempState) => ({
  //       ...tempState,
  //       isMultiBS: size(userInfo.bs_BS_IDs) > 1
  //     }));
  //   }
  // }, [userInfo]);

  useEffect(() => {
    const generalUpdate = async () => {
      try {
        const selectedBSID = Cookies.get('selectedBS') || null;
        console.log('generalUpdate');
        const objData = await fetchData(selectedBSID, dispatch);
        console.log('objData LOGIN', objData);

        setState((tempState) => ({
          ...tempState,
          ...objData?.data.state,
          isMultiBS: objData?.data?.dataUser?.userinfo?.bs_list?.length > 1
        }));

        // Opening dialog to activate probes if no probes or if code2 in url
        const { code2 } = queryString.parse(location.search);
        setNoProbesDialogOpen(
          !objData.dataSession.admin &&
            (isEmpty(objData.responseBSConf.conf_data.sensor_configurations) ||
              !!code2 ||
              history.location.state === 'activation')
        );
      } catch (err) {
        console.log(err);
        history.push('/login');
      }
    };
    generalUpdate();
  }, [dispatch, history, Cookies, location.search]);

  useEffect(() => {
    const setCookie = () => {
      const languageLogic = includes(
        supportedLanguages.map((lan) => lan.locale),
        language
      )
        ? language
        : 'en';
      Cookies.set('qAppLang', languageLogic);
    };
    if (language !== Cookies.get('qAppLang')) {
      setCookie();
    }
    // return () => {
    //   Cookies.set('qAppLang', 'en');
    // };
  }, [Cookies, language, supportedLanguages]);

  // 7.6.2023 socket issue: do not initiate any sockets whatsoever

  useEffect(() => {
    let socketResponse = null;
    const getSocketFlag = async () => {
      try {
        socketResponse = await getSocket();
        setSocketEnabled(socketResponse === 1);
        // console.log(`socketResponse = ${socketResponse} type ${typeof socketResponse}`);
      } catch (err) {
        return null;
      }
    };
    getSocketFlag();
    console.log('socketEnabled ', socketEnabled);
    const socket = io(API_SERVER);
    if (socketEnabled) {
      socket.on('connect', () => setIsOnline(true));
      socket.on('connect_error', () => {
        if (isOnline) setIsOnline(false);
      });
      const getNewDataSocket = () => {
        socket.on('newData', async (data) => {
          try {
            console.log('socket newData: ', data);
            const updatedBsIds = data.bs_ids.split(' ');
            const bsIds = intersection(sessionInfo?.bs_ids, updatedBsIds);
            if (!isEmpty(bsIds)) {
              console.log('socket update');
              fetchData(
                bsIds[0],
                dispatch,
                previousUpdateTS,
                userInfo,
                bsConf,
                isAdmin,
                storeBatches
              );
            }
          } catch (err) {
            console.log(err);
          }
        });
      };
      getNewDataSocket();
    }

    return () => socket.disconnect();
  }, [
    dispatch,
    isOnline,
    previousUpdateTS,
    sessionInfo,
    history,
    userInfo,
    bsConf,
    isAdmin,
    storeBatches,
    socketEnabled
  ]);

  useEffect(() => {
    const socket = io(API_SERVER);
    socket.on('connect', () => setIsOnline(true));
    socket.on('connect_error', () => {
      if (isOnline) setIsOnline(false);
    });
    const getFcSocket = () => {
      socket.on('fcStat', async (data) => {
        try {
          console.log(
            'socket fcStat: ',
            data,
            FC_IDS.find((fc) => fc === data.id)
          );
          // Fetch updated FCs if given id is user's
          if (FC_IDS.find((fc) => fc === data.id)) {
            getFcItem({ id: FC_IDS.join(',') })
              .then((response) => {
                // console.log('response getFcItem', response);
                dispatch({ type: 'SET_FC_STATUS', value: response });
              })
              .catch((error) => {
                console.log('Error in getFcItem: ', error);
              });
          }
        } catch (err) {
          console.log(err);
        }
      });
    };
    if (FC_IDS) {
      getFcSocket();
    }
    return () => socket.disconnect();
  }, [FC_IDS, dispatch, isOnline]);

  // 7.6.2023 socket issue end of useEffect comment-out

  const logout = async () => {
    try {
      clearDataToStore(dispatch);
      Cookies.remove('selectedBS');
      Cookies.remove('DEBUG');
      setState((tempState) => ({ ...tempState, selectedBS: null }));
      dispatch(getTranslationsData(i18n.locale, null, 'anonymous'));
      history.push('/login');
      await postLogout();
    } catch (err) {
      history.push('/login');
    }
  };

  const openManual = () => {
    if (userInfo?.plans) {
      const plan = getMaxPlan(userInfo.plans);
      const url = getManual(plan, i18n.locale);
      window.open(url, '_blank');
    } else if (userInfo?.service) {
      // Insight
      const url = getManual({ planLevel: 2 }, i18n.locale);
      window.open(url, '_blank');
    }
  };

  const handleChangeBS = async (item) => {
    if (item) {
      dispatch({ type: 'SET_SENSORCONF', value: [] });
      dispatch({ type: 'SET_SENSORGROUPS', value: [] });
      dispatch({ type: 'SET_SENSORS_DATA', value: null });
      dispatch({ type: 'SET_MINMAXDATA', value: null });
      dispatch({ type: 'SET_SENSORLIST', value: [] });
      dispatch({ type: 'SET_BASESTATION', value: null });
      dispatch({ type: 'SET_BASESTATIONS', value: null });
      dispatch({ type: 'SET_ADMIN_BS', value: null });
      dispatch({ type: 'SET_ADMIN_SELECTED_USER', value: null });
      dispatch({ type: 'SET_BSCONF', value: null });
      dispatch({ type: 'SET_AUXCONF', value: null });
      dispatch({ type: 'SET_USERINFO', value: null });
      dispatch({ type: 'SET_SESSIONINFO', value: null });
      dispatch({ type: 'SET_LOADING', value: true });
      dispatch({ type: 'SET_EDIT_CONFIRM_NEEDED', value: false });

      const objData = await generateAllData(item.value, dispatch);
      dispatchDataToStore(objData, dispatch);

      setState((tempState) => ({
        ...tempState,
        selectedBS: item.value,
        isMultiBS: objData?.data?.dataUser?.userinfo?.bs_list?.length > 1
      }));
      console.log('objData', objData);
      console.log(
        objData.gariUser
          ? `GARI USER, New BS: ${item.value}`
          : `New BS: ${item.value}, with plan: ${getPlanName(objData.plan, objData.plan)} (${
              objData.plan.plan
                ? getServiceDate(objData.plan, null, 1)
                : getPlanDate(objData.plan, null, objData.plan?.expired)
            })`
      );
      if (!objData.gariUser) {
        // Store BS selection unless gari BS
        Cookies.set('selectedBS', item.value, { expires: 1 });
      }
    }
  };

  const toggleDrawer = (open) => setState((tempState) => ({ ...tempState, drawer: open }));

  const toggleNotif = (value) => setState((tempState) => ({ ...tempState, notif: value }));

  const { adminUsername, isMultiBS, selectedBS, basestations, gariBasestations, notif, drawer } =
    state;

  const onVisiblityChange = () => {
    // console.log('visibilityState ', document.visibilityState);
    if (document.visibilityState === 'visible') {
      postRefreshToken();
    }
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', onVisiblityChange);
    const timer = setTimeout(() => {
      if (userInfo && !history?.location?.state?.surveyCompleted) {
        const surveyNotCompleted = !!userInfo.service && !userInfo.service.surveyCompleted;
        const oldHayUser = !userInfo.service && getMaxPlan(userInfo.plans).planLevel < 4;
        console.log('timeOut to INSIGHT');
        if (!isAdmin && (oldHayUser || surveyNotCompleted)) {
          history.replace({
            pathname: '/insight',
            state: { plan: getMaxPlan(userInfo.plans), service: userInfo.service }
          });
          return null;
        }
      }
    }, 500);
    return () => {
      clearTimeout(timer);
      document.removeEventListener('visibilitychange', onVisiblityChange);
    };
  }, [history, isAdmin, userInfo]);

  const notificationsCount = useSelector((store) => size(store.notifications));
  useEffect(() => {
    const timer = setTimeout(() => {
      if (notificationsCount && !isAdmin) {
        setState((tempState) => ({
          ...tempState,
          notif: true
        }));
      }
    }, 2000);
    return () => clearTimeout(timer);
  }, [isAdmin, notificationsCount]);

  return (
    <BrowserRouter>
      <RouteChangeTracker />
      <MainAppBar
        adminUsername={adminUsername}
        isMultiBS={isMultiBS}
        selectedBS={selectedBS}
        baseStations={basestations}
        gariBaseStations={gariBasestations}
        handleChangeBS={handleChangeBS}
        toggleNotif={toggleNotif}
        toggleDrawer={toggleDrawer}
      />
      {notif && <NotifDialog notif={notif} isMultiBS={isMultiBS} toggleNotif={toggleNotif} />}
      <DrawerPopup
        drawer={drawer}
        adminUsername={adminUsername}
        toggleDrawer={toggleDrawer}
        openManual={openManual}
        logout={logout}
      />
      <DrawerRight isMultiBS={isMultiBS} openManual={openManual} />
      {!isLoading && <MainRoutes isMultiBS={isMultiBS} path={match.path} />}

      {/* 7.6.2023 socket issue: don't show net error to normal users  */}
      {!isOnline && isAdmin && (
        <Snackbar open anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert severity='warning'>
            {i18n.network_offline || 'Network connection is offline'}
          </Alert>
        </Snackbar>
      )}
      {unsavedChanges && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open
          autoHideDuration={10000}
          onClose={() => dispatch({ type: 'SET_UNSAVED_CHANGES', value: false })}
        >
          <Alert
            onClose={() => dispatch({ type: 'SET_UNSAVED_CHANGES', value: false })}
            severity='error'
          >
            {currentView === 'batches'
              ? i18n.unsaved_batch_message ||
                'You have unsaved edits. Please close the batch before changing the view.'
              : i18n.unsaved_storage_message ||
                'You have unsaved edits. Please close the storage before changing the view.'}
          </Alert>
        </Snackbar>
      )}
      {noProbesDialogOpen && (
        <ProbeActivationDialog
          noProbesDialogOpen={noProbesDialogOpen}
          setNoProbesDialogOpen={setNoProbesDialogOpen}
        />
      )}
    </BrowserRouter>
  );
};

export default MainNavigator;
