import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isEmpty } from 'lodash';
// import { getGradientColor } from 'assets/utils';
import Cookies from 'js-cookie';
import { getBsConf, postBsConf, postUiPrefs } from 'api/serverAPI';
import { getTranslationsData } from 'redux/reducers/setLanguage';
import { fToCelsius } from 'assets/utils';
import { generateSettings, prepareBsConfigForSaving } from './helper';

import SettingsSliderDialog from './SettingsSliderDialog';
import SettingsRadioDialog from './SettingsRadioDialog2';
import { TEMPERATURE_MARKS } from './SettingProps';

const SettingsContext = React.createContext();
const SettingSelectionContext = React.createContext();

const initialSetting = {
  settingsId: '',
  editSettingsRadio: false,
  editSettingsSlider: false
};

const SettingsProvider = ({ children }) => {
  const dispatch = useDispatch();

  const { i18n, isAdmin, sessionInfo, bsConf, degrees, userInfo, isDemoMode } = useSelector(
    (store) => ({
      i18n: store.i18n,
      isAdmin: store.isAdmin,
      sessionInfo: store.sessionInfo,
      bsConf: store.bsConf,
      degrees: store.sessionInfo?.ui_preferences?.degrees ?? '°C',
      userInfo: store.userInfo,
      isDemoMode: store.isDemoMode
    })
  );

  const [settings, setSettings] = useState();
  const [settingSelection, setSettingSelection] = useState(initialSetting);

  useEffect(() => {
    if (bsConf) {
      setSettings((tempState) => ({
        ...tempState,
        ...generateSettings(i18n, bsConf, isAdmin, sessionInfo, degrees)
      }));
    }
  }, [i18n, bsConf, isAdmin, sessionInfo, degrees]);

  const toggleSettings = (id, type) => {
    if (isEmpty(id)) {
      setSettingSelection((tempState) => ({
        ...tempState,
        editSettingsRadio: false,
        editSettingsSlider: false
      }));
    } else if (type === 'radio') {
      setSettingSelection((tempState) => ({
        ...tempState,
        settingsId: id,
        editSettingsRadio: true,
        editSettingsSlider: false
      }));
    } else {
      setSettingSelection((tempState) => ({
        ...tempState,
        settingsId: id,
        editSettingsRadio: false,
        editSettingsSlider: true
      }));
    }
  };

  const saveUiPrefs = async (newSettings) => {
    const uiPrefs = {
      prefs: {
        ...sessionInfo.ui_preferences,
        language: newSettings.lang.value,
        degrees: newSettings.degrees.value,
        date_format: newSettings.d_format.value,
        time_format: newSettings.t_format.value,
        safety_alert: newSettings.safety_alert.value,
        temperature_alert: newSettings.temp_alert.value,
        include_exceptions: newSettings.incl_exc.value,
        include_sensor_data: newSettings.incl_sens_data.value,
        measurements_per_day: newSettings.meas_per_day.value,
        chart_scale: newSettings.def_chart.value,
        color_steps: newSettings.def_color.value,
        handle_temp_enabled: newSettings.handle_temp.value
      }
    };
    const noLocaleChanges = sessionInfo.ui_preferences.language === newSettings.lang.value;
    sessionInfo.ui_preferences = uiPrefs.prefs;

    if (isAdmin) {
      uiPrefs.UID = sessionInfo.UID;
    }
    if (!isDemoMode || isAdmin) {
      try {
        await postUiPrefs(uiPrefs);
      } catch (err) {
        console.log('err: ', err);
        return;
      }
    }
    dispatch({ type: 'SET_SHOW_HANDLE', value: uiPrefs.prefs.handle_temp_enabled });
    dispatch({ type: 'SET_SESSIONINFO', value: sessionInfo });
    if (!isAdmin && !noLocaleChanges) {
      console.log('getTranslationsData at: SettingsContext');
      dispatch(getTranslationsData(sessionInfo.ui_preferences.language, userInfo.U_ID, 'user'));
      Cookies.set('qAppLang', sessionInfo.ui_preferences.language);
    }
  };

  const saveBsConf = async (newSettings) => {
    const bsConfTemp = prepareBsConfigForSaving(newSettings, isAdmin);
    const bsId = sessionInfo.bs_ids.find((bsid) => bsid === bsConf.bs_id);
    try {
      await postBsConf({ basestationID: bsId }, bsConfTemp);
      const response = await getBsConf({ basestationID: bsId });
      if (response.status_code === 0) {
        dispatch({ type: 'SET_BSCONF', value: response.conf_data });
      }
    } catch (err) {
      console.log('err: ', err);
    }
  };

  const saveSettingsToDB = (newSettings, selection) => {
    if (!isEmpty(selection)) {
      if (
        selection === 'lang' ||
        selection === 'degrees' ||
        selection === 'd_format' ||
        selection === 't_format' ||
        selection === 'temp_alert' ||
        selection === 'incl_exc' ||
        selection === 'incl_sens_data' ||
        selection === 'safety_alert' ||
        selection === 'meas_per_day' ||
        selection === 'def_chart' ||
        selection === 'def_color' ||
        selection === 'handle_temp'
      ) {
        saveUiPrefs(newSettings);
      } else {
        saveBsConf(newSettings);
      }
    }
  };

  const saveSettingsSlider = (newValue, id) => {
    const notTempSetting =
      id.includes('interval') || id === 'timeout' || id === 'buffer_max' || id === 'receiving_time';
    let newSettings = { ...settings };
    if (id === 'tempIntervalDegrees') {
      // newSettings[settingSelection.settingsId].value = Number(newValue);
      newSettings = {
        ...settings,
        tempIntervalDegrees: { ...settings.tempIntervalDegrees, value: Number(newValue) }
      };
    } else if (id === 'tempIntervalTime') {
      // newSettings[settingSelection.settingsId].value = Number(newValue);
      newSettings = {
        ...settings,
        tempIntervalTime: { ...settings.tempIntervalTime, value: Number(newValue) }
      };
    } else if (degrees === '°C' || notTempSetting) {
      if (id === 'def_color' || 'def_chart') {
        newSettings[settingSelection.settingsId].value = newValue;
      } else {
        newSettings[settingSelection.settingsId].value = Number(newValue);
      }
    } else if (degrees === '°F') {
      if (id === 'def_color' || 'def_chart') {
        newSettings[settingSelection.settingsId].value = Array.isArray(newValue)
          ? newValue.map((value) => fToCelsius(value))
          : fToCelsius(newValue);
      } else {
        newSettings[settingSelection.settingsId].value = fToCelsius(newValue);
      }
    }
    setSettings(newSettings);
    setSettingSelection({ ...settingSelection, editSettingsSlider: false });
    saveSettingsToDB(newSettings, settingSelection.settingsId);
  };

  const saveSettingsRadio = (newValue) => {
    const newSettings = { ...settings };
    newSettings[settingSelection.settingsId].value = newValue;
    setSettings(newSettings);
    setSettingSelection({ ...settingSelection, editSettingsRadio: false });
    saveSettingsToDB(newSettings, settingSelection.settingsId);
  };

  if (!bsConf || !settings) {
    return null;
  }

  const minSlider = degrees === '°C' ? 0 : 20;
  const maxSlider = degrees === '°C' ? 100 : 220;

  return (
    <>
      <SettingsContext.Provider value={settings}>
        <SettingSelectionContext.Provider value={{ settingSelection, toggleSettings }}>
          {children}
        </SettingSelectionContext.Provider>
      </SettingsContext.Provider>

      {settingSelection.editSettingsSlider && (
        <SettingsSliderDialog
          i18n={i18n}
          degrees={degrees}
          id={settingSelection.settingsId}
          data={settings}
          selectedValue={settings[settingSelection.settingsId].value}
          editSettingsSlider={settingSelection.editSettingsSlider}
          toggleSettings={toggleSettings}
          saveSettingsSlider={saveSettingsSlider}
          marks={TEMPERATURE_MARKS(minSlider, maxSlider, degrees)}
        />
      )}

      {settingSelection.editSettingsRadio && (
        <SettingsRadioDialog
          i18n={i18n}
          id={settingSelection.settingsId}
          selectedValue={settings[settingSelection.settingsId].value}
          editSettingsRadio={settingSelection.editSettingsRadio}
          toggleSettings={toggleSettings}
          saveSettingsRadio={saveSettingsRadio}
        />
      )}
    </>
  );
};

const useSettingsContext = () => {
  const context = React.useContext(SettingsContext);
  if (context === undefined) {
    throw new Error('useSettingsState must be used within a SettingsProvider');
  }
  return context;
};

const useSettingSelectionContext = () => {
  const context = React.useContext(SettingSelectionContext);
  if (context === undefined) {
    throw new Error('useSettingSelectionState must be used within a SettingsProvider');
  }
  return context;
};

export { SettingsProvider, useSettingsContext, useSettingSelectionContext };
