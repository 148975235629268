import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'utils/colors';

export const useStyles = makeStyles((theme) => ({
  hide: {
    display: 'none'
  },
  // specialHide: {
  //   position: 'absolute',
  //   opacity: 0,
  //   width: 0,
  //   height: 0,
  //   margin: '0px !important',
  //   overflow: 'hidden',
  //   zIndex: -1
  // },
  mainContainer: {
    width: '100%',
    height: 'calc(100% - 40px)',
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
    flexGrow: 1
    // overflow: 'visible'
  },
  controlsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    zIndex: 1
  },
  spanButtonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexFlow: 'row',
    alignItems: 'center',
    padding: '0 40px 20px',
    [theme.breakpoints.down('xs')]: {
      flexFlow: 'column'
    }
  },
  spanButtonsContent: {
    // width: 350,
    marginTop: -30,
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 40
    },
    display: 'flex'
  },
  spanButton: {
    fontSize: 13,
    minWidth: 80,
    border: '1px solid rgba(75, 75, 75, 0.4)',
    [theme.breakpoints.down('xs')]: {
      fontSize: 11,
      width: 65
    }
  },
  battButton: {
    fontSize: 13,
    maxWidth: 50,
    border: '1px solid rgba(75, 75, 75, 0.4)'
  },
  moistButton: {
    fontSize: 13,
    width: 50,
    maxHeight: 30
  },
  overviewIconWrapper: {
    marginRight: 10,
    backgroundColor: COLORS.snow,
    padding: '4px 6px',
    borderRadius: 4,
    border: '1px solid #fee'
  },
  overviewIcon: {
    width: 18,
    height: 18,
    color: COLORS.mediumGray
  },

  sliderContainer: {
    position: 'relative',
    width: '100%',
    height: 40,
    top: -40,
    left: 0,
    display: 'flex'
  },
  slider: {
    flexGrow: 1,
    paddingLeft: 50,
    paddingRight: 50
  },
  controls: {
    maxWidth: 400,
    margin: 'auto',
    align: 'center'
  },
  chartLoadingContainer: {
    width: '100%',
    height: '100%',
    minHeight: '20%',
    flexGrow: 1,
    marginTop: -20,
    zIndex: 0,
    [theme.breakpoints.down('md')]: {
      minHeight: 340
    },
    [theme.breakpoints.down('xs')]: {
      height: 408
    }
  },
  chartOverlay: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    bottom: 0,
    margin: 0,
    zIndex: 1000
  },
  chartSpinner: {
    marginTop: '-30vh'
  }
}));
