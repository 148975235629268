import { isEmpty, keys, compact, sortBy } from 'lodash';

export const splitArrayByCommas = (arr, isDisabled) => {
  const filterArr = sortBy(
    arr?.filter((sensor) => {
      if (!sensor.disabled) {
        return true;
      }
      return sensor.disabled === isDisabled;
    }) || [],
    'sensor_id_sys'
  );
  const temp = filterArr.map((sensor) => sensor.sensor_id_sys);
  return temp.join(', ');
};

export const convertArrayToObject = (arr) => {
  if (arr) {
    return arr.reduce((result, s) => {
      result[s.sensor_id_sys] = true;
      return result;
    }, {});
  }
  return {};
};

export const convertObjectToArray = (obj) => {
  if (isEmpty(obj)) {
    return [];
  }
  const arr = keys(obj).map((key) => (obj[key] ? key : null));
  return compact(arr);
};
