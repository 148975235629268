// PUBLIC IMPORTS
import { Paper, Typography, CircularProgress, Button } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { InlineIcon } from '@iconify/react';

import accountBox from '@iconify/icons-mdi/account-box';
// REDUX
import { useSelector, useDispatch } from 'react-redux';

// SVG
// import LayoutsSVG from 'assets/svg/LayoutsSVG';

const useStyles = makeStyles((theme) => ({
  hide: {
    display: 'none'
  },
  mainContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      height: '100%',
      padding: 0
    }
  },
  spinnerContainer: {
    display: 'flex',
    marginTop: 20,
    marginBottom: 20,
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      padding: 0
    }
  },
  storagesView: {
    width: '100%',
    height: '100%',
    maxWidth: 700
  },
  storagesText: {
    width: 'calc(100% - 40px)',
    marginLeft: 20
  },
  storagessupervisorButton: {
    display: 'block',
    width: 150,
    margin: 'auto',
    marginTop: 40,
    marginBottom: 60,
    textAlign: 'center'
  }
}));

const StoragesNoSupervisor = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { i18n, isLoading } = useSelector((store) => ({
    i18n: store.i18n,
    isLoading: store.isLoading
  }));

  useEffect(() => {
    const action = { type: 'TOGGLE_VIEW', value: 'storages' };
    dispatch(action);
  }, [dispatch]);

  return (
    <div className={classes.mainContainer} style={{ overflow: 'hidden' }}>
      <div className={isLoading ? classes.spinnerContainer : classes.hide}>
        <CircularProgress color='primary' size={70} disableShrink />
      </div>

      <Paper className={isLoading ? classes.hide : classes.storagesView} elevation={0}>
        {/* <LayoutsSVG
          style={{
            width: 150,
            margin: 'auto',
            marginTop: 50,
            marginBottom: 50
          }}
        /> */}
        <Typography
          variant='h6'
          className={classes.storagesText}
          style={{ textAlign: 'center', marginTop: 20, marginBottom: 20 }}
        >
          {i18n.storage_dashboard || 'Storage monitor dashboard'}
        </Typography>
        <Typography
          variant='body2'
          className={classes.storagesText}
          style={{ textAlign: 'center', marginTop: 20, marginBottom: 20 }}
        >
          {i18n.supervisor_description}
        </Typography>
        <Button
          component={Link}
          to='/account/services/checkout/?type=plan'
          className={classes.storagessupervisorButton}
          variant='contained'
          color='primary'
        >
          <InlineIcon icon={accountBox} /> {i18n.renew_plan}
        </Button>
      </Paper>
    </div>
  );
};

export default StoragesNoSupervisor;
