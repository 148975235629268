import React from 'react';
import { Redirect, Route } from 'react-router-dom';

export const PATH_LIST = ['probes', 'batches', 'settings', 'account', 'storages'];

export const ANONYMOUS_PATH_LIST = ['login', 'activation', 'SAV-quanturi'];

const PublicRoute = ({ props: cProps, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      cProps.isLoggedIn ? (
        <Redirect to={`${props.location.pathname}${props.location.search}`} />
      ) : (
        <Redirect to={`/login?redirect=${props.location.pathname}${props.location.search}`} />
      )
    }
  />
);

export default PublicRoute;
