// PUBLIC IMPORTS
import React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';

class SignalSVG extends React.Component {
  render() {
    return (
      <div style={this.props.style}>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 92.33 66.58'>
          {this.props.title ? <title>
            {this.props.title}
          </title> : ''}
          <path
            d='M40.59,65.87l9.31,9.32,9.31-9.32A13.15,13.15,0,0,0,40.59,65.87Z'
            transform='translate(-3.73 -8.61)'
            style={{
              fill:
                this.props.level !== -1
                  ? this.props.level >= 1
                    ? this.props.color
                    : 'rgba(0, 0, 0, 0.2)'
                  : 'rgba(0, 0, 0, 0.1)'
            }}
          />
          <path
            d='M28.17,53.46l6.21,6.21a22,22,0,0,1,31,0l6.21-6.21A30.77,30.77,0,0,0,28.17,53.46Z'
            transform='translate(-3.73 -8.61)'
            style={{
              fill:
                this.props.level !== -1
                  ? this.props.level >= 2
                    ? this.props.color
                    : 'rgba(0, 0, 0, 0.2)'
                  : 'rgba(0, 0, 0, 0.1)'
            }}
          />
          <path
            d='M15.75,41,22,47.25a39.51,39.51,0,0,1,55.88,0L84.05,41A48.33,48.33,0,0,0,15.75,41Z'
            transform='translate(-3.73 -8.61)'
            style={{
              fill:
                this.props.level !== -1
                  ? this.props.level >= 3
                    ? this.props.color
                    : 'rgba(0, 0, 0, 0.2)'
                  : 'rgba(0, 0, 0, 0.1)'
            }}
          />
          <path
            d='M89.5,34.33l6.57-6.56a65.2,65.2,0,0,0-92.34,0l6.57,6.56A56.22,56.22,0,0,1,89.5,34.33Z'
            transform='translate(-3.73 -8.61)'
            style={{
              fill:
                this.props.level !== -1
                  ? this.props.level >= 4
                    ? this.props.color
                    : 'rgba(0, 0, 0, 0.2)'
                  : 'rgba(0, 0, 0, 0.1)'
            }}
          />
          <path
            d='M43.2,68.4a7.51,7.51,0,0,1,.5-2.6,6,6,0,0,1,1.4-2.1,5.55,5.55,0,0,1,2.2-1.4,5.92,5.92,0,0,1,2.7-.5,7.51,7.51,0,0,1,2.6.5,7.71,7.71,0,0,1,2.1,1.4,6,6,0,0,1,1.4,2.1,5.75,5.75,0,0,1,.5,2.6,8.08,8.08,0,0,1-.5,2.7,7.71,7.71,0,0,1-1.4,2.1,6,6,0,0,1-2.1,1.4,7.51,7.51,0,0,1-2.6.5,8.57,8.57,0,0,1-2.7-.5,6.36,6.36,0,0,1-2.2-1.4,6,6,0,0,1-1.4-2.1A7.63,7.63,0,0,1,43.2,68.4ZM55.3,10.1V35.6a70.26,70.26,0,0,1-.4,8c-.3,2.6-.6,5.4-1.1,8.3H46.4c-.4-2.9-.8-5.7-1.1-8.3a69.11,69.11,0,0,1-.4-8V10.1Z'
            transform='translate(-3.73 -8.61)'
            style={{ fill: this.props.level === 0 ? this.props.alertColor : 'rgba(0, 0, 0, 0)' }}
          />
        </svg>
      </div>
    );
  }
}

const styles = theme => ({
  hide: {
    display: 'none'
  }
});

export default withRouter(withWidth()(withStyles(styles)(SignalSVG)));
