import { FormControl, MenuItem, Select, Tooltip } from '@material-ui/core';
import React, { useState } from 'react';
import fanAuto from '@iconify/icons-mdi/fan-auto';
import fanIcon from '@iconify/icons-mdi/fan';
import fanOff from '@iconify/icons-mdi/fan-off';
import handBackRight from '@iconify/icons-mdi/hand-back-right';
import { InlineIcon } from '@iconify/react';
import { setFcManualCommand } from 'api/serverAPI';

export const ManualRcIconSelection = ({ option, i18n, classes, disabled }) => {
  let title = '';
  const defaultClasses = [classes.manualFcIcon, disabled && classes.disabled];
  let classes1 = defaultClasses;
  let icon1;
  let classes2 = defaultClasses;
  let icon2;

  if (option === 1) {
    title = i18n.manual_on;
    classes1 = [...classes1, classes.handIcon];
    icon1 = handBackRight;
    icon2 = fanIcon;
  } else if (option === 0) {
    title = i18n.manual_off;
    classes1 = [...classes1, classes.handIcon];
    icon1 = handBackRight;
    icon2 = fanOff;
  } else {
    title = i18n.auto;
    classes1 = [...classes1, classes.transparent];
    icon1 = handBackRight;
    classes2 = [...classes2, classes.autoFcIcon];
    icon2 = fanAuto;
  }

  return (
    <Tooltip title={disabled ? '' : title} arrow placement='bottom' disableFocusListener>
      <div>
        <InlineIcon className={classes1.join(' ')} icon={icon1} />
        <InlineIcon className={classes2.join(' ')} icon={icon2} />
      </div>
    </Tooltip>
  );
};

export const ManualFcMode = ({ i18n, classes, currentFcStatus, rule }) => {
  // console.log('rule', rule);
  const manualRCoptions = rule.type === 'MANUAL_CONTROL' ? [0, 1] : ['drive', 0, 1];
  const [manualOption, setManualOption] = useState(
    currentFcStatus?.manualCommand ?? manualRCoptions[0]
  );
  const disabled =
    currentFcStatus?.fc_mode !== 'drive' ||
    (currentFcStatus?.connectionStatus &&
      (currentFcStatus?.connectionStatus.isOffline ||
        currentFcStatus?.connectionStatus?.newOfflineLogged));

  const onManualFcModeChangeHandler = async (e) => {
    const newOption = e.target.value;
    setManualOption(newOption);
    let object = { fc_id: currentFcStatus.FC_ID, isManual: false };
    if (currentFcStatus.channel) {
      object.channel = currentFcStatus.channel;
    }
    if (newOption !== 'drive') {
      object = { ...object, isManual: true, command: newOption };
    }
    try {
      await setFcManualCommand(object);
    } catch (err) {
      console.log('err', err);
    }
  };

  return (
    <FormControl variant='outlined'>
      <Select
        disabled={disabled}
        className={classes.selector}
        value={manualOption}
        onChange={onManualFcModeChangeHandler}
      >
        {manualRCoptions.map((option) => (
          <MenuItem key={option} value={option}>
            <ManualRcIconSelection
              option={option}
              i18n={i18n}
              classes={classes}
              disabled={disabled}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const controllerRule = (rule) => rule.type.substring(rule.type.length - 7) === 'CONTROL';

export const isAlertRule = (rule) => rule.type.substring(rule.type.length - 7) !== 'CONTROL';
