import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableSortLabel,
  CircularProgress
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty, isNaN } from 'lodash';
import { format } from 'date-fns';
import verificationImage from 'assets/images/verificationInfo.png';
import { COLORS } from 'utils/colors';
import { stableSort, getComparator } from 'utils/sorting';
import { getVerifixData } from 'api/serverAPI';
import { getAgeStrict } from 'utils/sharedHelper';
import { tempFormat, tempFormatDelta } from 'assets/utils';
import { calculatedCorrection } from './helper';

const VERIFIX_INTERVAL = 600;

export const adminTableStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    height: 'inherit',
    whiteSpace: 'nowrap',
    marginTop: 20
  },
  tableContainer: {
    // height: 'inherit'
    height: '100%'
  },
  paper: {
    padding: 5
  },
  table: {
    minWidth: 550
  },
  row: {
    '&:hover': {
      backgroundColor: COLORS.whisperGray,
      cursor: 'pointer'
    }
  },
  cell: {
    border: '1px solid #eeeeeedb',
    padding: 2,
    maxWidth: 400,
    overflow: 'auto',
    textAlign: 'left',
    paddingLeft: 10
  },
  head: {
    background: '-webkit-gradient(linear, left top, left bottom, from(#f6f6f6), to(#eee))',
    whiteSpace: 'nowrap'
  },
  infoWrapper: {
    width: '100%',
    overflow: 'auto',
    background: COLORS.whisperGray,
    fontSize: 15,
    // fontWeight: 'bold',
    padding: 15,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  },
  infoHeader: {
    fontSize: 18,
    marginBottom: 10
  },
  infoImage: {
    width: 250,
    height: 'fit-content',
    minHeight: 150,
    marginRight: 20,
    marginTop: 30,
    [theme.breakpoints.down('xs')]: {
      marginTop: 10,
      width: 170,
      minHeight: 100
    }
  },
  infoText: { whiteSpace: 'normal', fontSize: 13, paddingBottom: 15 },
  tableWrapper: {
    maxHeight: 135,
    overflowY: 'auto'
  },
  infoTextblock: {
    display: 'block',
    maxWidth: 600,
    maxHeight: 300,
    overflowY: 'auto',
    [theme.breakpoints.down('xs')]: {
      maxHeight: 180
    }
  },
  spinnerContainer: {
    display: 'flex',
    marginTop: 50,
    justifyContent: 'center'
  }
}));

const AdminTable = ({ verification, dateFormat, degrees, data, setData }) => {
  const isAdmin = useSelector((state) => state.isAdmin);
  const i18n = useSelector((state) => state.i18n);
  const classes = adminTableStyles();

  const [filteredRows, setFilteredRows] = useState([]);

  const [message, setMessage] = useState(null);

  const unfilteredHeadCells = [
    { id: 'ts', label: i18n.date_time || 'Timestamp' },
    { id: 'temp', label: i18n.temperature || 'T°' },
    { id: 'ref_ts', label: 'Ref TS', admin: true },
    { id: 'ts_diff', label: 'TS diff', admin: true },
    { id: 'ref_temp_raw', label: 'Ref raw', admin: true },
    { id: 'corrT', label: 'Correction', admin: true },
    {
      id: 'real_ref_temp',
      label: `${i18n.verifix_ref_temp || 'Real reference probe T°'} (real)`,
      admin: true
    },
    { id: 'ref_temp', label: i18n.verifix_ref_temp || 'Reference probe T°' },
    { id: 'diff', label: i18n.verifix_diff || 'Difference to calibrated value' }
  ];

  let headCells = unfilteredHeadCells;
  if (!isAdmin) {
    headCells = unfilteredHeadCells.filter((h) => !h.admin);
  }

  useEffect(() => {
    const getRows = (d) => {
      const getRowsPart1 = () => {
        const VerifixTableArray2 = [];
        d.data1.forEach((meas) => {
          const verifixMeas = d.data2.find(
            (m) =>
              m.b * 1000 < (meas.b + VERIFIX_INTERVAL) * 1000 &&
              m.b * 1000 > (meas.b - VERIFIX_INTERVAL) * 1000
          );
          if (verifixMeas) {
            VerifixTableArray2.push({
              b1: meas.b,
              h1: meas.h,
              ...verifixMeas
            });
          }
        });
        return VerifixTableArray2;
      };

      const getRowsPart2 = (measData) => {
        const VerifixTableArray = [];
        measData.forEach((v) => {
          const correction = calculatedCorrection(v.h, verification.calibration);
          const correctedTemp = v.h + correction;
          const verifiedTemp = tempFormat(degrees, v.h1, 1, 0);
          const diffTemp = tempFormatDelta(degrees, v.h1 - correctedTemp, 2, 1);

          VerifixTableArray.push({
            ts: format(v.b1 * 1000, `${dateFormat} HH:mm:ss`),
            temp: `${verifiedTemp} ${degrees}`,
            ref_ts: format(v.b * 1000, 'HH:mm:ss'),
            ts_diff: getAgeStrict(v.b, v.b1), // formatDistanceStrict(v.b1 * 1000, v.b * 1000),
            ref_temp_raw: `${v.h.toFixed(3)} °C`, // shown as C to admin
            corrT: isNaN(correction) ? i18n.n_a : correction.toFixed(4),
            real_ref_temp: isNaN(correction) ? i18n.n_a : `${correctedTemp.toFixed(3)} °C`, // shown as C to admin
            ref_temp: isNaN(correction)
              ? i18n.n_a
              : `${(verifiedTemp - diffTemp).toFixed(degrees === '°C' ? 2 : 1)} ${degrees}`,
            diff: isNaN(correction) ? i18n.n_a : `${diffTemp > 0 ? '+' : ''}${diffTemp} ${degrees}`
          });
        });
        return VerifixTableArray;
      };

      const part1 = getRowsPart1();
      const part2 = getRowsPart2(part1);
      return part2;
    };

    const infoMessage = (
      <div className={classes.infoWrapper}>
        <div className={classes.infoTextblock}>
          <div className={classes.infoHeader}>{i18n.verif_started}</div>
          <div className={classes.infoText}>{i18n.verification_info1}</div>
          <div className={classes.infoText}>{i18n.verification_info2}</div>
        </div>
        <img className={classes.infoImage} alt='verification' src={verificationImage} />
      </div>
    );

    const waitMsg = (
      <div className={classes.spinnerContainer}>
        <CircularProgress color='primary' size={70} disableShrink />
      </div>
    );
    const getVerificationData = async () => {
      setMessage(waitMsg);
      try {
        const response = await getVerifixData({
          verificationID: verification.itemID
        });

        if (!(isEmpty(response.data1) || isEmpty(response.data2))) {
          const rows = getRows(response);
          setData(rows);
          if (!isAdmin) {
            rows.forEach((row) => {
              delete row.ref_ts;
              delete row.ts_diff;
              delete row.ref_temp_raw;
              delete row.corrT;
              delete row.real_ref_temp;
            });
            setFilteredRows(rows);
          } else {
            setFilteredRows(rows);
          }
          setMessage(null);
        } else {
          setData([]);
          setMessage(infoMessage);
        }
      } catch (err) {
        return null;
      }
    };

    getVerificationData();
  }, [
    dateFormat,
    degrees,
    i18n,
    isAdmin,
    setData,
    setMessage,
    verification,
    classes.infoHeader,
    classes.infoImage,
    classes.infoText,
    classes.infoTextblock,
    classes.infoWrapper,
    classes.spinnerContainer
  ]);

  const [order, setOrder] = React.useState(headCells[0].ts);
  const [orderBy, setOrderBy] = React.useState('asc');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <div className={classes.root}>
      {/* //   <div className={classes.paper}> */}
      <AdminDataTable
        classes={classes}
        order={order}
        orderBy={orderBy}
        handleRequestSort={handleRequestSort}
        data={data}
        headCells={headCells}
        message={message}
        filteredRows={filteredRows}
      />
      {/* //   </div> */}
    </div>
  );
};

export const EnhancedTableHead = ({ classes, order, orderBy, onRequestSort, headCells }) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.head}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='center'
            className={classes.cell}
            padding='normal'
            style={headCell.admin ? { background: COLORS.zirconBlue } : {}}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export const AdminTableBody = ({ classes, order, orderBy, filteredRows, headCells }) => (
  <TableBody>
    {stableSort(filteredRows, getComparator(order, orderBy))?.map((row, i) => {
      const values = Object.values(row);
      const keys = Object.keys(row);
      const cells = values.map((r, index) => (
        <TableCell
          style={headCells[index].admin ? { background: '#faffff' } : null}
          className={classes.cell}
          key={`${keys[index]}_${index}`}
          align='center'
        >
          {r}
        </TableCell>
      ));
      return (
        <TableRow key={i} tabIndex={-1}>
          {cells}
        </TableRow>
      );
    })}
  </TableBody>
);

export const AdminDataTable = ({
  classes,
  order,
  orderBy,
  headCells,
  handleRequestSort,
  data,
  rowsPerPage,
  filteredRows,
  message
}) => {
  const Message = () => <div>{message}</div>;

  return message ? (
    <Message />
  ) : (
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table}>
        <EnhancedTableHead
          classes={classes}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          rowCount={data.length}
          headCells={headCells}
        />
        <AdminTableBody
          classes={classes}
          order={order}
          orderBy={orderBy}
          rowsPerPage={rowsPerPage}
          filteredRows={filteredRows}
          headCells={headCells}
        />
      </Table>
    </TableContainer>
  );
};

export default AdminTable;
