import React from 'react';
import { SurveySlider } from 'components/account/account-users/account-background/helpers';

const SurveyStorage = ({ i18n, state, setState }) => {
  const StoringTime = ({ id }) => (
    <div style={{ maxWidth: 500 }}>
      <SurveySlider i18n={i18n} id={id} info={state[id]} setState={setState} />
    </div>
  );

  const StoragesNumber = ({ id }) => (
    <div style={{ maxWidth: 500 }}>
      <SurveySlider i18n={i18n} id={id} info={state[id]} setState={setState} />
    </div>
  );

  return (
    <div>
      <StoringTime id='storageTime' />
      <StoragesNumber id='storagesNumber' />
    </div>
  );
};

export default SurveyStorage;
