export const I18N_SET_LANGUAGE = 'I18N_SET_LANGUAGE';
export const SET_SUPPORTED_LANGUAGES = 'SET_SUPPORTED_LANGUAGES';
export const TOGGLE_VIEW = 'TOGGLE_VIEW';
export const SET_LOADING = 'SET_LOADING';
export const SET_LOGIN = 'SET_LOGIN';
export const SET_ADMIN = 'SET_ADMIN';
export const SET_INSIGHT_SERVICE = 'SET_INSIGHT_SERVICE';
export const SET_PREMIUM = 'SET_PREMIUM';
export const SET_SUPERVISOR_COMPOST = 'SET_SUPERVISOR_COMPOST';
export const SET_SUPERVISOR = 'SET_SUPERVISOR';
export const SET_SIM_EXPIRED = 'SET_SIM_EXPIRED';
export const SET_PLAN_EXPIRED = 'SET_PLAN_EXPIRED';
export const SET_PREMIUMCONGRATS = 'SET_PREMIUMCONGRATS';
export const SET_SESSIONINFO = 'SET_SESSIONINFO';
export const SET_USERINFO = 'SET_USERINFO';
export const SET_SENSORS_DATA = 'SET_SENSORS_DATA';
export const SET_BSCONF = 'SET_BSCONF';
export const SET_AUXCONF = 'SET_AUXCONF';
export const SET_SENSORCONF = 'SET_SENSORCONF';
export const SET_SENSORGROUPS = 'SET_SENSORGROUPS';
export const SET_SENSORDETAILS = 'SET_SENSORDETAILS';
export const SET_GROUPDETAILS = 'SET_GROUPDETAILS';
export const SET_SENSORLIST = 'SET_SENSORLIST';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const SET_LAYOUTS = 'SET_LAYOUTS';
export const SET_STORAGES = 'SET_STORAGES';
export const SET_BATCHES = 'SET_BATCHES';
export const SET_MINMAXDATA = 'SET_MINMAXDATA';
export const SET_SOCKET_UPDATES = 'SET_SOCKET_UPDATES';
export const SET_BATCH_DETAILS = 'SET_BATCH_DETAILS';
export const SET_SENSORTOOLTIP = 'SET_SENSORTOOLTIP';
export const SET_SENSORCLICKHANDLER = 'SET_SENSORCLICKHANDLER';
export const SET_VIEWMODE = 'SET_VIEWMODE';
export const SET_VIEWCLICKHANDLER = 'SET_VIEWCLICKHANDLER';
export const SET_OVERVIEWDATA = 'SET_OVERVIEWDATA';
export const SET_CHARTDATA = 'SET_CHARTDATA';
export const RESET_CHARTDATA = 'RESET_CHARTDATA';
export const SET_CHARTCONFIG = 'SET_CHARTCONFIG';
export const SET_DATATABLE = 'SET_DATATABLE';
export const RESET_DATATABLE = 'RESET_DATATABLE';
export const SET_DATATABLECONFIG = 'SET_DATATABLECONFIG';
export const SET_TABCONFIG = 'SET_TABCONFIG';
export const SET_BASESTATION = 'SET_BASESTATION';
export const SET_BASESTATIONS = 'SET_BASESTATIONS';
export const SET_ADMIN_BS = 'SET_ADMIN_BS';
export const SET_ADMIN_SELECTED_USER = 'SET_ADMIN_SELECTED_USER';
export const SET_PREVIOUS_UPDATE_TS = 'SET_PREVIOUS_UPDATE_TS';
export const SET_EDIT_CONFIRM_NEEDED = 'SET_EDIT_CONFIRM_NEEDED';
export const SET_UNSAVED_CHANGES = 'SET_UNSAVED_CHANGES';
export const SET_FC_STATUS = 'SET_FC_STATUS';
export const SET_SHOW_HANDLE = 'SET_SHOW_HANDLE';
export const SET_SENSOR_SORTING = 'SET_SENSOR_SORTING';
export const SET_GARI_USER = 'SET_GARI_USER';
export const SET_DEMO_MODE = 'SET_DEMO_MODE';
export const SET_SURVEY_ANSWERS = 'SET_SURVEY_ANSWERS';

export const YEAR_IN_MILLISECONDS = 365 * 24 * 3600000;
export const DAY_IN_MILLISECONDS = 24 * 3600000;
export const TAX_PERCENTAGE = 0.2;
export const PACKAGE_PLANS = [
  {
    months: 3,
    price: 60
  },
  {
    months: 12,
    price: 100
  },
  {
    months: 24,
    price: 120
  }
];

export const PHONE_COUNTRIES = [
  'au',
  'at',
  'be',
  'br',
  'bg',
  'cm',
  'ca',
  'hr',
  'cz',
  'dk',
  'ec',
  'ee',
  'fi',
  'fr',
  'pf',
  'de',
  'gb',
  'gr',
  'hu',
  'is',
  'ie',
  'iq',
  'it',
  'lv',
  'li',
  'lt',
  'lu',
  'ml',
  'ma',
  'nz',
  'nl',
  'no',
  'pl',
  'pt',
  'ro',
  'rs',
  'sk',
  'si',
  'es',
  'se',
  'ch',
  'ua',
  'us',
  'ae',
  'sa',
  'om',
  'ni',
  're',
  'yt' // ReactPhoneInput does not support Mayotte ('yt')
];
