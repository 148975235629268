import { TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { addMonths } from 'date-fns';
import { COLORS } from 'utils/colors';
import { getMaxPlan, planHandler } from 'assets/utils';
import { fetchData } from 'navigation/MainNavigator/helper';

const useStylesAutoComplete = makeStyles((theme) => ({
  root: {
    width: 200,
    backgroundColor: COLORS.white,
    color: `${theme.palette.text.primary}`,
    border: 'none',
    margin: 10
  },
  input: {
    height: '10px',
    border: 'none'
  }
}));

const DemoTypeSelection = () => {
  const dispatch = useDispatch();
  const classesAutoComplete = useStylesAutoComplete();
  const userInfo = useSelector((state) => state.userInfo);
  const bsConf = useSelector((state) => state.bsConf);
  const sessionInfo = useSelector((state) => state.sessionInfo);
  const plan = getMaxPlan(userInfo?.plans);

  const selectedPlan = (planDB) => {
    if (planDB.planLevel === 2) {
      return 'HAY';
    }
    if (planDB.planLevel === 4 && +planDB.segment === 14) {
      return 'WASTE';
    }
    return 'GRAIN';
  };
  // const classes = useStyles();
  const suggestions = ['GRAIN', 'HAY', 'WASTE'];
  const [selectedOption, setSelectedOption] = useState(selectedPlan(plan));

  const onSelectChange = async (val) => {
    setSelectedOption(val);
    dispatch({
      type: 'SET_DEMO_MODE',
      value: val
    });
    let planToEdit = plan;
    let service;
    let survey;
    let uiPrefs = sessionInfo.ui_preferences;
    if (val === 'GRAIN') {
      planToEdit = { ...planToEdit, planLevel: 4, segment: 13 };
      uiPrefs = {
        ...uiPrefs,
        temperature_alert: 30,
        chart_scale: [0, 40],
        color_steps: [0, 10, 20, 30, 40],
        handle_temp_enabled: true
      };
    } else if (val === 'WASTE') {
      planToEdit = { ...planToEdit, planLevel: 4, segment: 14 };
      uiPrefs = {
        ...uiPrefs,
        temperature_alert: 85,
        chart_scale: [0, 100],
        color_steps: [0, 40, 60, 80, 100],
        handle_temp_enabled: false
      };
    } else {
      service = {
        plan: 2,
        started: new Date(), // service activation date
        period: 36, // service period (months). New users: 36, Old users: 12?
        renewed: new Date(), // last renewal or initial purchase
        expDate: addMonths(new Date(), 36),
        nextPayment: { date: addMonths(new Date(), 36), type: 'renewal' },
        surveyCompleted: new Date()
      };
      survey = {
        activityType: 'dairy',
        hayType: 'bales',
        yearlyCuts: 3,
        weightUnit: 'kg',
        hayTypeUnit: 'bales',
        balesPerYear: 4400,
        balesType: 'square',
        balesWeight: 200,
        storageTime: [0, 2],
        storagesNumber: 3
      };
      planToEdit = { ...planToEdit, planLevel: 2, segment: undefined };
      uiPrefs = {
        ...uiPrefs,
        temperature_alert: 45,
        chart_scale: [0, 60],
        color_steps: [0, 20, 30, 45, 60],
        handle_temp_enabled: false
      };
    }
    planHandler(planToEdit, dispatch);
    await fetchData(bsConf.bs_id, dispatch, null, null, null, null, null, val);
    dispatch({
      type: 'SET_USERINFO',
      value: { ...userInfo, plans: [planToEdit], service }
    });
    dispatch({ type: 'SET_SESSIONINFO', value: { ...sessionInfo, ui_preferences: uiPrefs } });
    if (val === 'HAY') {
      console.log('survey', survey);
      dispatch({ type: 'SET_SURVEY_ANSWERS', value: survey });
    }
  };

  return (
    // <div
    //   style={{ backgroundColor: devMode ? COLORS.adminDev : COLORS.adminProd }}
    //   className={isDemoMode ? classes.adminBar : classes.hide}
    //   id='adminBar'
    // >
    // <div>
    // {/* <Typography
    //     color='inherit'
    //     style={
    //       {
    //         // margin: 5,
    //         // textOverflow: 'ellipsis',
    //         // overflow: 'hidden',
    //         // whiteSpace: 'nowrap'
    //       }
    //     }
    //     // className={classes.grow}
    //   >
    //     DEMO FOR:
    //   </Typography> */}
    <Autocomplete
      classes={classesAutoComplete}
      options={suggestions}
      value={selectedOption}
      getOptionLabel={(option) => option}
      getOptionSelected={(option) => option}
      renderInput={(params) => <TextField {...params} variant='outlined' fullWidth size='small' />}
      onChange={(e, val) => onSelectChange(val)}
      size='small'
    />
    //   {/* </div>
    // </div> */}
  );
};

export default DemoTypeSelection;
