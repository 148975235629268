import { IconButton, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Icon } from '@iconify/react';
import infoIcon from '@iconify/icons-mdi/information-outline';
import { sub, startOfDay, endOfDay } from 'date-fns';
import { isEmpty } from 'lodash';
import { getQualityColor, getDaysToQualityDrop } from 'assets/utils';
import { COLORS } from 'utils/colors';
import { getMinmaxData } from '../../../api/serverAPI';
import StoragePeriodView from './StoragePeriodView';
import QualitySensorList from './QualitySensorList';
import QualityInfoDialog from './QualityInfoDialog';
import QualitySensorListHeader from './QualitySensorListHeader';
import * as storageConstants from '../storage-utils/storageConstants';

const useStyles = makeStyles(() => ({
  qualityPanel: {},
  periodPanel: {
    padding: 10,
    borderBottom: `1px solid ${COLORS.lightGray}`,
    paddingBottom: 0
  },
  panelTitle: {
    fontSize: 14,
    textAlign: 'left',
    marginBottom: -10
  },
  listHeader: {
    padding: 10,
    paddingBottom: 3,
    display: 'flex',
    justifyContent: 'start',
    position: 'relative'
  },
  infoIcon: {
    width: 22,
    height: 22
  },
  infoButton: {
    maxWidth: 24,
    maxHeight: 24,
    padding: 0,
    marginLeft: 10,
    position: 'absolute',
    right: 7,
    top: 8
  },
  noProbes: {
    color: COLORS.darkGray,
    margin: '5px 5px 0px 10px',
    fontSize: 13
  }
}));

const StorageQualityView = ({
  sensorIDs,
  selectedStorage,
  viewHeight,
  probeQualityData,
  setProbeQualityData,
  onStoragePeriodChange,
  dataSnapshot,
  clickedQualitySensor,
  setClickedQualitySensor,
  showInfoDialog,
  setShowInfoDialog,
  openSensorData
}) => {
  const classes = useStyles();

  const { i18n, bsConf, userInfo } = useSelector((store) => ({
    i18n: store.i18n,
    bsConf: store.bsConf,
    userInfo: store.userInfo
  }));

  const bsID = bsConf?.bs_id;
  const isDemo = bsID === 'DEMOdata0';

  let storageTo = selectedStorage?.qualityPeriod?.to
    ? new Date(selectedStorage.qualityPeriod.to)
    : endOfDay(new Date());
  let storageFrom = selectedStorage?.qualityPeriod?.from
    ? new Date(selectedStorage.qualityPeriod.from)
    : startOfDay(sub(storageTo, { days: storageConstants.DEFAULT_PERIOD }));

  if (selectedStorage?.qualityPeriod?.demoDays) {
    // Demo storage: use n days instead of fixed day
    storageFrom = startOfDay(sub(new Date(), { days: selectedStorage.qualityPeriod.demoDays }));
    console.log('quality start: ', storageFrom);
    storageTo = endOfDay(new Date());
  }

  const [clickedQuality, setClickedQuality] = useState(
    probeQualityData?.find((q) => q.ID === clickedQualitySensor)
  );
  const [fromDate, setFromDate] = useState(storageFrom);
  const [toDate, setToDate] = useState(storageTo);
  const [ongoing, setOngoing] = useState(+storageTo === +endOfDay(new Date()));
  const [isPeriodDialogOpen, setIsPeriodDialogOpen] = useState(false);

  const storagePeriodChanged = () => {
    setProbeQualityData(null);
  };
  const applyStoragePeriod = (start, end) => {
    // Apply period value to storage, saving needed
    storageFrom = start;
    storageTo = end;
    const noEnd = +storageTo === +endOfDay(new Date());
    onStoragePeriodChange(start, noEnd ? null : end, selectedStorage?.qualityPeriod?.demoDays);
  };
  const cancelStoragePeriod = () => {
    // Reset to initial period (of storage or default)
    setFromDate(storageFrom);
    setToDate(storageTo);
    setOngoing(+storageTo === +endOfDay(new Date()));
    setProbeQualityData(null);
  };

  useEffect(() => {
    const getProbeQuality = (probeID, dayMaxData) => {
      if (bsID !== 'F0000203' && (!dayMaxData || isEmpty(dayMaxData))) {
        return {
          ID: probeID
        };
      }
      // console.log(probeID, ' dayMaxData ', dayMaxData);
      const dayHDDs = dayMaxData.map((d) => (d.h > 30 ? d.h - 30 : 0));
      // console.log(probeID, ' dayHDDs ', dayHDDs);
      let HDDSum = dayHDDs.reduce((partialSum, a) => partialSum + a, 0);
      // Test case for displaying different HDDs
      if (bsID === 'F0000203') {
        switch (probeID) {
          case '006058':
            HDDSum = 105;
            break;
          case '006064':
            HDDSum = 150;
            break;
          case '006065':
            HDDSum = 200;
            break;
          case '006066':
            HDDSum = 50;
            break;
          case '006067':
            HDDSum = 100;
            break;
          case '006068':
            HDDSum = 150;
            break;
          case '006054':
            HDDSum = 220;
            break;
          case '006055':
            HDDSum = 350;
            break;
          case '006059':
            HDDSum = 50;
            break;
          case '006061':
            HDDSum = 150;
            break;
          case '006062':
            HDDSum = 100;
            break;
          case '006063':
            HDDSum = 450;
            break;
          default:
            HDDSum = 0;
        }
      }

      const lossInCP = 4.0 * Math.exp(-0.000002 * HDDSum * HDDSum) - 2.6;
      const lossInNFC = 4.8 * Math.exp(-0.000014 * HDDSum * HDDSum) - 6.2;
      const lossInNDF = 4.2 * Math.exp(-0.0000017 * HDDSum * HDDSum) - 3.2;
      const totalLoss = lossInCP + lossInNFC + lossInNDF;
      const relativeQuality = (totalLoss + 12) / 13;

      // console.log(` * ${probeID} totalLoss `, totalLoss, '%=', (totalLoss + 12) / 13);
      // console.log(` * ${probeID} relativeQuality `, relativeQuality);

      // totalLoss is in range 1 .. -12
      // Convert the loss to quality %: 1: 100%, -12: 0%
      // const test = getTestQ(probeID);
      const colorAndBars = getQualityColor(relativeQuality, HDDSum);
      const lastMeas = dataSnapshot?.find((d) => d.sensor_id === probeID);
      return {
        ID: probeID,
        HDD: Math.round(HDDSum),
        TDN: totalLoss,
        //   quality: test,
        quality: relativeQuality,
        color: colorAndBars.color,
        color3D: colorAndBars.color3D,
        bars: colorAndBars.bars,
        size: colorAndBars.size,
        intensity: colorAndBars.intensity,
        lastTemp: lastMeas?.temperature,
        daysToDrop: !ongoing ? 999 : getDaysToQualityDrop(HDDSum, colorAndBars.bars, lastMeas),
        isDemo
      };
    };
    const getMinmaxQualityData = async () => {
      await getMinmaxData({
        basestationID: bsID,
        sensorIDs,
        shortData: true,
        UID: userInfo?.U_ID,
        fromTS: Math.round(startOfDay(fromDate) / 1000),
        toTS: Math.round(endOfDay(toDate) / 1000)
      }).then((response) => {
        if (response) {
          setProbeQualityData(
            sensorIDs
              .map((sid) =>
                getProbeQuality(
                  sid,
                  response.filter((meas) => meas.a === sid)
                )
              )
              .filter(Boolean)
          );
        }
      });
    };
    if (!probeQualityData && selectedStorage?.qualityPeriod) {
      getMinmaxQualityData();
    }
  }, [
    bsID,
    fromDate,
    sensorIDs,
    toDate,
    probeQualityData,
    setProbeQualityData,
    userInfo?.U_ID,
    dataSnapshot,
    selectedStorage?.qualityPeriod,
    ongoing,
    isDemo
  ]);

  const [sensorSorting, setSensorSorting] = useState(['name', 'desc', 'drop']);

  const handleSort = (newSortId) => {
    const sortDirectionChanged = sensorSorting[0] === newSortId;
    const newDirection =
      sortDirectionChanged && sensorSorting[1] === 'asc'
        ? 'desc'
        : sortDirectionChanged && sensorSorting[1] === 'desc'
        ? 'asc'
        : sensorSorting[1];
    setSensorSorting([newSortId, newDirection]);
    // console.log('handleSort ', newSortId, newDirection);
  };

  // const sensorComponents = stableSort(
  //   getTabledata(),
  //   getComparator(sensorSorting[1], sensorSorting[0])
  // )
  //   .map((p) => p.id)
  //   .filter((sid) => sensorIDs.includes(sid))
  //   .map((sid) => (<SensorListItem...>)

  const onSensorClick = (sensorQ) => {
    if (!sensorQ && !selectedStorage?.qualityPeriod) {
      setIsPeriodDialogOpen(true);
      return;
    }
    sensorQ.lastMeas = dataSnapshot.find((d) => d.sensor_id === sensorQ?.ID);
    sensorQ.storageToDate = storageTo;
    setClickedQuality(sensorQ);
    setShowInfoDialog(true);
  };

  return (
    <div className={classes.qualityPanel}>
      <div id='qPeriodPanel' className={classes.periodPanel}>
        <Typography className={classes.panelTitle} color='textSecondary'>
          {i18n.storage_period || 'Storage period'}
        </Typography>
        <StoragePeriodView
          sensorIDs={sensorIDs}
          fromDate={fromDate}
          toDate={toDate}
          setFromDate={setFromDate}
          setToDate={setToDate}
          unset={!selectedStorage?.qualityPeriod}
          ongoing={ongoing}
          setOngoing={setOngoing}
          storagePeriodChanged={storagePeriodChanged}
          applyStoragePeriod={applyStoragePeriod}
          cancelStoragePeriod={cancelStoragePeriod}
          title={i18n.storage_period || 'Storage period'}
          desc={i18n.storage_period_desc}
          todayLabel={i18n.storage_ongoing || 'Ongoing'}
          isPeriodDialogOpen={isPeriodDialogOpen}
          setIsPeriodDialogOpen={setIsPeriodDialogOpen}
        />
      </div>
      <div id='qListHead'>
        <div className={classes.listHeader}>
          <Typography
            className={classes.panelTitle}
            style={{ marginBottom: -5 }}
            color='textSecondary'
          >
            {i18n.hay_quality_title}
          </Typography>
          <IconButton
            classes={{ root: classes.infoButton }}
            aria-label='Select'
            onClick={() => setShowInfoDialog(true)}
          >
            <Icon className={classes.infoIcon} icon={infoIcon} />
          </IconButton>
        </div>
        {!sensorIDs || isEmpty(sensorIDs) ? null : (
          <QualitySensorListHeader
            marginRight={0}
            sortID={sensorSorting[0]}
            sortDirection={sensorSorting[1]}
            handleSort={handleSort}
          />
        )}
      </div>
      {!sensorIDs || isEmpty(sensorIDs) ? (
        <div className={classes.noProbes}>{i18n.quality_no_probes}</div>
      ) : (
        <QualitySensorList
          sensorIDs={sensorIDs}
          bsConf={bsConf}
          probeQualityData={probeQualityData || []}
          selectedStorage={selectedStorage}
          viewHeight={viewHeight}
          sensorSorting={sensorSorting}
          onSensorClick={onSensorClick}
          periodUnset={!selectedStorage?.qualityPeriod}
          i18n={i18n}
        />
      )}
      {showInfoDialog && (
        <QualityInfoDialog
          i18n={i18n}
          isDialogOpen={showInfoDialog}
          setIsDialogOpen={setShowInfoDialog}
          clickedQuality={
            clickedQuality || probeQualityData?.find((q) => q.ID === clickedQualitySensor)
          }
          setClickedQuality={setClickedQuality}
          clickedQualitySensor={clickedQualitySensor}
          setClickedQualitySensor={setClickedQualitySensor}
          fromDate={fromDate}
          toDate={toDate}
          ongoing={ongoing}
          openSensorData={openSensorData}
        />
      )}
    </div>
  );
};

export default StorageQualityView;
