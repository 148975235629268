import outdoor from 'assets/images/outdoor.svg';
import roof from 'assets/images/roof.svg';
import tarp from 'assets/images/tarp.svg';
import openhouse from 'assets/images/openhouse.svg';
import warehouse from 'assets/images/warehouse.svg';

export const storageTypeOptionsArray = (i18n) => [
  { id: 'open_air', label: i18n.open_air || 'Open air', icon: outdoor },
  { id: 'tarp_covered', label: i18n.tarp_covered || 'Tarp covered', icon: tarp },
  { id: 'roof_covered', label: i18n.roof_covered || 'Roof covered', icon: roof },
  { id: 'open-warehouse', label: i18n.open_warehouse || 'Open warehouse', icon: openhouse },
  { id: 'warehouse', label: i18n.warehouse || 'Warehouse', icon: warehouse }
];

export const getHayType = (type, unit) => {
  if (type === 'bales' && unit === 'bales') {
    return 'bales';
  }
  return '';
};
