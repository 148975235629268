import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  useMediaQuery
} from '@material-ui/core';

import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import 'yup-phone';
import { yupResolver } from '@hookform/resolvers/yup';

import SwipeableViews from 'react-swipeable-views';
import ReactPhoneInput from 'material-ui-phone-number';

import PinInput from 'react-pin-input';

import { useDispatch, useSelector } from 'react-redux';
import { useConfirm } from 'hook/useConfirm';
import adminChangesConfirm from 'utils/adminChangesConfirm';
import { PHONE_COUNTRIES } from 'redux/constants';

// import { checkPhoneNumber } from 'assets/utils';
import { getUserInfo, postChangeUserPhone, postConfirmMobile } from 'api/serverAPI';

import { editPhoneDialogStyles } from 'utils/sharedStyles';
import { phoneNumberFormat } from 'utils/sharedHelper';

const SmsConfirm = ({ phone, togglePhoneItem, isDemoMode }) => {
  const classes = editPhoneDialogStyles({ index: 1 });
  const confirm = useConfirm();
  const dispatch = useDispatch();

  const { i18n, sessionInfo, isAdmin, userInfo } = useSelector((store) => ({
    i18n: store.i18n,
    sessionInfo: store.sessionInfo,
    isAdmin: store.isAdmin,
    userInfo: store.userInfo
  }));

  const schema = yup.object().shape({
    smsCode: yup
      .string()
      .length(4, i18n.msg_4_nbs || 'You have to fill 4 numbers')
      .required(i18n.sms_code_req || 'SMS Code is required')
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    setError
  } = useForm({
    resolver: yupResolver(schema)
  });

  const resendSMS = async (event) => {
    event.stopPropagation();
    const phones = {
      oldPhone: phoneNumberFormat(phone),
      newPhone: phoneNumberFormat(phone)
    };

    if (isAdmin) {
      phones.UID = sessionInfo.UID;
    }
    if (isDemoMode && !isAdmin) {
      setError('smsCode', {
        type: 'notMatch',
        message: i18n.sms_code_again || 'SMS Code has been sent again'
      });
    } else {
      try {
        const responsePhone = await postChangeUserPhone(phones);
        if (responsePhone.status_code === 0) {
          setError('smsCode', {
            type: 'notMatch',
            message: i18n.sms_code_again || 'SMS Code has been sent again'
          });
        }
      } catch (err) {
        console.log('err: ', err);
      }
    }
  };

  const onSubmit = async (values) => {
    try {
      let userID;

      await adminChangesConfirm(isAdmin, confirm, 'Phone Number', sessionInfo.username);

      const code = {
        code: values.smsCode
      };

      if (isAdmin) {
        code.username = sessionInfo.username;
        userID = { UID: sessionInfo.UID };
      }
      let responseConfirm = {};
      if (isDemoMode && !isAdmin) {
        responseConfirm.status_code = 13;
      } else {
        responseConfirm = await postConfirmMobile(code);
      }
      switch (responseConfirm.status_code) {
        case 2: {
          // Confirmation code is wrong
          setError('smsCode', {
            type: 'notMatch',
            message: i18n.conf_code_err || 'Confirmation code is wrong'
          });
          break;
        }
        case 0:
        case 13: {
          if (isDemoMode && !isAdmin) {
            dispatch({
              type: 'SET_USERINFO',
              value: {
                ...userInfo,
                phones: [{ phoneNumber: phoneNumberFormat(phone), index: 0, confirmed: 1 }]
              }
            });
          } else {
            // Success or already confirmed
            const responseUser = await getUserInfo(userID);
            if (responseUser.status_code === 0) {
              dispatch({ type: 'SET_USERINFO', value: responseUser.userinfo });
            }
          }
          togglePhoneItem(false);
          break;
        }
        default:
          break;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onCancel = async (event) => {
    event.stopPropagation();
    if (isDemoMode && !isAdmin) {
      togglePhoneItem(false);
    } else {
      try {
        const responseUser = await getUserInfo({ UID: sessionInfo.UID });
        if (responseUser.status_code === 0) {
          dispatch({ type: 'SET_USERINFO', value: responseUser.userinfo });
        }
        togglePhoneItem(false);
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate className={classes.mainPhoneConfirm}>
      <Grid container spacing={10} alignItems='center' direction='column' justifyContent='center'>
        <Grid item>
          <Typography className={classes.dialogMessage}>{i18n.code_text}</Typography>
          <Controller
            control={control}
            name='smsCode'
            rules={{ required: true }}
            onComplete={(value) => setValue('smsCode', value)}
            render={({ field }) => (
              <PinInput
                {...field}
                length={4}
                type='numeric'
                style={{
                  display: 'block',
                  width: 225,
                  margin: 'auto',
                  marginTop: 20,
                  marginBottom: 20
                }}
                inputStyle={{
                  fontSize: 20,
                  fontFamily: "'Roboto', sans-serif",
                  borderColor: 'rgba(0,0,0,0)',
                  borderBottom: '1px solid rgba(0,0,0,0.42)'
                }}
                inputFocusStyle={{
                  fontSize: 20,
                  fontFamily: "'Roboto', sans-serif",
                  borderColor: 'rgba(0,0,0,0)',
                  borderBottom: '2px solid #252525'
                }}
              />
            )}
          />
          <Typography className={classes.errorMessage} color='error'>
            {errors.smsCode?.message}
          </Typography>
        </Grid>

        <Grid item className={classes.bottom}>
          <div>
            <Button onClick={resendSMS} color='primary'>
              {i18n.resend_sms}
            </Button>
          </div>
          <div>
            <Button onClick={onCancel} color='secondary'>
              {i18n.cancel}
            </Button>
            <Button type='submit' variant='contained' color='secondary'>
              {i18n.ok}
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};

const PhoneNumber = ({ oldPhone, isConfirmed, togglePhoneItem, setState, isDemoMode }) => {
  const classes = editPhoneDialogStyles({ index: 0 });

  const { i18n, sessionInfo, isAdmin, userCountry } = useSelector((store) => ({
    i18n: store.i18n,
    sessionInfo: store.sessionInfo,
    isAdmin: store.isAdmin,
    userCountry: store.userInfo?.country
  }));

  const schema = yup.object().shape({
    phoneNumber: yup
      .string()
      .phone(null, null, i18n.invalid_format)
      .required(i18n.phone_req || 'Phone number is required')
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = async (values) => {
    try {
      const phoneObj = {
        oldPhone,
        newPhone: phoneNumberFormat(values.phoneNumber)
      };

      if (phoneObj.oldPhone === phoneObj.newPhone && isConfirmed) {
        setError('phoneNumber', {
          type: 'notMatch',
          message: i18n.phone_is_confirmed || 'This phone number has been confirmed'
        });
        return;
      }

      if (isAdmin) {
        phoneObj.UID = sessionInfo.UID;
      }
      if (isDemoMode && !isAdmin) {
        setState({ phone: values.phoneNumber, index: 1 });
      } else {
        const responsePhone = await postChangeUserPhone(phoneObj);
        if (responsePhone.status_code === 0) {
          setState({ phone: values.phoneNumber, index: 1 });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate className={classes.mainPhoneEdit}>
      <Grid container spacing={10} alignItems='center' direction='column' justifyContent='center'>
        <Grid item>
          <Typography className={classes.dialogMessage}>{i18n.code_warning}</Typography>
          <Controller
            render={({ field }) => (
              <ReactPhoneInput
                {...field}
                variant='outlined'
                margin='dense'
                fullWidth
                autoFocus={Boolean(errors.phoneNumber)}
                disableAreaCodes
                defaultCountry={userCountry}
                onlyCountries={PHONE_COUNTRIES}
              />
            )}
            name='phoneNumber'
            rules={{ required: true }}
            control={control}
            defaultValue={oldPhone}
            required
          />
          <Typography className={classes.errorMessage} color='error'>
            {errors.phoneNumber?.message}
          </Typography>
        </Grid>
        <Grid item className={classes.bottom}>
          <Button onClick={() => togglePhoneItem(false)} color='secondary'>
            {i18n.cancel}
          </Button>
          <Button type='submit' variant='contained' color='primary'>
            {i18n.next}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const EditPhoneDialog = ({
  index,
  phone,
  isConfirmed,
  editPhoneItem,
  togglePhoneItem,
  isDemoMode
}) => {
  const classes = editPhoneDialogStyles();
  const isXsWidth = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const [state, setState] = useState({
    index: index ? 1 : 0,
    phone
  });

  const i18n = useSelector((store) => store.i18n);

  return (
    <Dialog fullScreen={isXsWidth} open={editPhoneItem} aria-labelledby='responsive-dialog-title'>
      <DialogTitle>
        {state.index === 0 ? i18n.change_phone_number : i18n.confirm_phone_number}
      </DialogTitle>
      <DialogContent className={classes.dialogContentPhone} elevation={0}>
        <SwipeableViews
          disabled
          index={state.index}
          onChangeIndex={(i) => setState((tempState) => ({ ...tempState, index: i }))}
        >
          <PhoneNumber
            togglePhoneItem={togglePhoneItem}
            oldPhone={state.phone}
            isConfirmed={isConfirmed}
            setState={setState}
            isDemoMode={isDemoMode}
          />
          <SmsConfirm
            togglePhoneItem={togglePhoneItem}
            phone={state.phone}
            isDemoMode={isDemoMode}
          />
        </SwipeableViews>
      </DialogContent>
    </Dialog>
  );
};

export default EditPhoneDialog;
