import { Button } from '@material-ui/core';
import React from 'react';
import { isEmpty } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { Icon } from '@iconify/react';
import pencilIcon from '@iconify/icons-mdi/pencil-circle';
import checkBold from '@iconify/icons-mdi/check-bold';
import tuneIcon from '@iconify/icons-mdi/tune';
import arrowRightCircle from '@iconify/icons-mdi/arrow-right-circle';
import arrowLeftCircle from '@iconify/icons-mdi/arrow-left-circle';
import { COLORS } from 'utils/colors';

import LayoutControlPanel from './LayoutControlPanel';

const useStyles = makeStyles(() => ({
  layoutMenubar: {
    backgroundColor: COLORS.white,
    // height: 'fit-content'
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #ccc'
  },
  editButton: {
    marginRight: 10
  },
  panelButton: {
    padding: '0 5px',
    margin: 5,
    minWidth: 'fit-content'
  },
  buttonIcon: {
    width: 24,
    height: 24,
    marginRight: 5
  },
  layoutControls: {
    position: 'absolute',
    right: 0,
    top: 0,
    backgroundColor: COLORS.whiteSmoke,
    padding: '0 15px 10px',
    border: '1px solid #ccc',
    boxShadow: '0px 0px 10px rgb(0 0 0 / 50%)',
    zIndex: 200
  }
}));

const LayoutMenubar = ({
  // isAdmin,
  isEditing,
  showControlsButton,
  i18n,
  visuAngle,
  maxAngle,
  minCellSize,
  visuCellsize,
  visuSpacing,
  setVisuAngle,
  setVisuCellsize,
  setVisuSpacing,
  nLayers,
  onEditButtonClick,
  //   onSettingsButtonClick,
  onToggleSettings,
  showVisuSettings,
  // onAngleChanged,
  // onSizeChanged,
  // onSpaceChanged,
  showLeftPanel,
  toggleLeftPanel,
  setIsStorageEdited,
  selectedStorage,
  setSelectedStorage
}) => {
  const classes = useStyles();
  const hideControlsButton = isEditing && !showControlsButton;
  return (
    <div id='layoutMenubar' className={classes.layoutMenubar}>
      {isEditing || isEmpty(selectedStorage?.sensor_layout?.sensors) ? (
        <div className={classes.leftPlaceholder} />
      ) : (
        <Button onClick={toggleLeftPanel} className={classes.panelButton}>
          <Icon width={24} height={24} icon={showLeftPanel ? arrowLeftCircle : arrowRightCircle} />
        </Button>
      )}
      <div className={classes.editButton}>
        <Button color='secondary' onClick={() => onEditButtonClick()}>
          {<Icon className={classes.buttonIcon} icon={isEditing ? checkBold : pencilIcon} />}
          {isEditing ? i18n.finish_edit_layout : i18n.edit_layout || 'Edit layout'}
        </Button>
        {hideControlsButton ? null : (
          <Button
            style={{ minWidth: 'fit-content' }}
            aria-label='Layout settings'
            onClick={() => onToggleSettings()}
            disabled={hideControlsButton}
          >
            <Icon
              style={{ color: hideControlsButton ? COLORS.lightGray2 : COLORS.boulderGray }}
              width={20}
              height={20}
              icon={tuneIcon}
            />
          </Button>
        )}
      </div>
      {showVisuSettings ? (
        <div className={classes.layoutControls}>
          <LayoutControlPanel
            // currentAngle={visuAngle}
            // onAngleChanged={onAngleChanged}
            // currentSize={visuCellsize}
            // onSizeChanged={onSizeChanged}
            currentSpace={visuSpacing}
            visuAngle={visuAngle}
            maxAngle={maxAngle}
            minCellSize={minCellSize}
            visuCellsize={visuCellsize}
            visuSpacing={visuSpacing}
            setVisuAngle={setVisuAngle}
            setVisuCellsize={setVisuCellsize}
            setVisuSpacing={setVisuSpacing}
            nLayers={nLayers}
            i18n={i18n}
            onClose={() => onToggleSettings()}
            setIsStorageEdited={setIsStorageEdited}
            selectedStorage={selectedStorage}
            setSelectedStorage={setSelectedStorage}
          />
        </div>
      ) : null}
    </div>
  );
};
export default LayoutMenubar;
