import { Tooltip } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { getGradientColor, getOpacity, tempFormat } from 'assets/utils';
import { COLORS } from 'utils/colors';

const useStyles = makeStyles(() => ({
  clickableCell: {
    width: '100%',
    height: '100%',
    cursor: 'pointer'
  },
  sensorPoint: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    padding: '6px 10px' // use padding to adjust  point temp size by temp level
  },
  sensorPointID: {
    color: COLORS.primaryColor,
    cursor: 'default'
  },
  sensorIDseries: {
    fontSize: 10
    // marginTop: 7
  },
  sensorIDnumber: {
    fontSize: 13,
    lineHeight: '15px'
  },
  pointTemp: {
    background: COLORS.lightGray2,
    width: '100%',
    height: '100%',
    borderRadius: 5,
    border: '1px solid transparent',
    boxSizing: 'border-box'
  },
  selected: {
    borderColor: 'rgba(0,0,0,0.8)'
  }
}));

export default function SensorPointStatus({
  // index,
  sensorID,
  temperature,
  timestamp,
  // onSensorClicked,
  isSelected,
  width,
  // alert,
  // measurement,
  // depth,
  isActive,
  tempScale,
  showDataless,
  showID
}) {
  const classes = useStyles();
  const degrees = useSelector((store) => store.sessionInfo?.ui_preferences?.degrees);

  let pointDataStyle = { backgroundColor: COLORS.white };
  // const { temperature, timestamp } = measurement;

  // console.log(depth, measurement);
  // const dataInPoint = measurement.temperatures ? measurement.temperatures[depth] : '-x';

  let tempPadding = '20%';

  if (typeof temperature === 'number') {
    pointDataStyle = isActive
      ? {
          backgroundColor: getGradientColor(temperature),
          opacity: timestamp ? getOpacity(timestamp * 1000) : 1
        }
      : { backgroundColor: COLORS.lightGray2 };
    if (tempScale && tempScale.length === 5) {
      // Set the temp indicator spot size according to tempScale values
      tempPadding =
        temperature < tempScale[0]
          ? '35%'
          : temperature > tempScale[3]
          ? '5%'
          : `${
              5 +
              Math.round((1 - (temperature - tempScale[0]) / (tempScale[3] - tempScale[0])) * 30)
            }%`;
    }
  } else {
    pointDataStyle = { backgroundColor: COLORS.lightGray2 };
  }
  // With big padding, the ID does not fit to the small color box.
  // And with small padding, center the ID vertically by the tempPadding
  const tempPaddingNumber = parseInt(tempPadding);

  // TODO: map temperatures to sensorPoints, 1st child is handle:
  // sensorPoint: bottom: 58px; z-index: 4; pointDataStyle: backgroundColor: handlecolor (gray)
  // top/bottom by temps index...
  // result is Npoints <div>s with correct bottom coordinates.
  // also pile stuff must be set higher with bigger N
  return typeof temperature === 'number' ? (
    <div
      className={classes.clickableCell}
      // onClick={() => onSensorClicked(sensorID)}
      aria-hidden='true'
    >
      <Tooltip
        title={`${tempFormat(degrees, temperature)} ${degrees}`}
        placement='right'
        enterDelay={100}
        disableFocusListener
      >
        <div className={classes.sensorPoint} style={{ padding: tempPadding }}>
          <div
            className={
              isSelected ? [classes.pointTemp, classes.selected].join(' ') : classes.pointTemp
            }
            style={pointDataStyle}
          >
            {showID && width - (2 * tempPaddingNumber * width) / 100 >= 27 ? (
              <div
                className={classes.sensorPointID}
                style={{ marginTop: `${22 - tempPaddingNumber}%` }}
              >
                <div className={classes.sensorIDseries}>{sensorID.substr(0, 3)}</div>
                <div className={classes.sensorIDnumber}>{sensorID.substr(3, 6)}</div>
              </div>
            ) : null}
          </div>
        </div>
      </Tooltip>
    </div>
  ) : showDataless ? (
    <div className={classes.sensorPoint} style={{ padding: tempPadding }}>
      <div
        className={`${classes.pointTemp} ${isSelected ? classes.selected : ''}`}
        style={pointDataStyle}
      >
        {showID && width - (2 * tempPaddingNumber * width) / 100 >= 27 ? (
          <div className={classes.sensorPointID}>
            <div className={classes.sensorIDseries}>{sensorID.substr(0, 3)}</div>
            <div className={classes.sensorIDnumber}>{sensorID.substr(3, 6)}</div>
          </div>
        ) : null}
      </div>
    </div>
  ) : null;
}
