// PUBLIC IMPORTS
import React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';

class RCManualSVG extends React.Component {
  render() {
    return (
      <div style={this.props.style}>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12'>
          <path
            d='M10.8,6.9c0.5-0.5,0.5-1.3,0-1.8C10.8,5.1,10.7,5,10.7,5l0.2-0.2c0.5-0.5,0.5-1.3,0-1.8c-0.1-0.1-0.2-0.1-0.3-0.2
            c0.2-0.5,0.1-1-0.3-1.4C9.9,1,9.2,1,8.7,1.3c0-0.1-0.1-0.1-0.2-0.2c-0.5-0.5-1.3-0.5-1.8,0L4.7,3.2c0-0.2-0.1-0.5-0.2-0.7L4.3,2.1
            C4.1,1.8,3.7,1.6,3.2,1.7C2.9,1.7,2.6,2,2.5,2.3L1.1,5.5C0.4,7.1,0.8,8.9,2,10.1c1.6,1.6,4.1,1.6,5.7,0L10.8,6.9 M7,9.4
            c-1.2,1.2-3.1,1.2-4.2,0C1.8,8.5,1.5,7.1,2,5.9l1.4-3.2l0.1,0.2c0.1,0.2,0.1,0.5,0,0.7L3.1,4.8l0.7,0.7l3.7-3.7
            c0.1-0.1,0.3-0.1,0.4,0s0.1,0.3,0,0.4L5.2,4.8l0.7,0.7l3.4-3.4c0.1-0.1,0.3-0.1,0.4,0c0.1,0.1,0.1,0.3,0,0.4L6.3,5.9L7,6.6l2.8-2.8
            c0.1-0.1,0.3-0.1,0.4,0c0.1,0.1,0.1,0.3,0,0.4L7.3,6.9L8,7.6l1.8-1.8c0.1-0.1,0.3-0.1,0.4,0s0.1,0.3,0,0.4L7,9.4z'
            style={{ fill: this.props.color }}
          />
        </svg>
      </div>
    );
  }
}

const styles = (theme) => ({
  hide: {
    display: 'none'
  }
});

export default withRouter(withWidth()(withStyles(styles)(RCManualSVG)));
