import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { format, isToday } from 'date-fns';
import * as dateFns from 'date-fns';
import { countBy } from 'lodash';
import { getValidityOverview } from 'api/serverAPI';
import { getSensorType, getGradientColor } from 'assets/utils';
import { Spinner } from 'utils/Spinners';
import { COLORS } from 'utils/colors';
import SensorValidityTable from './SensorValidityTable';

const validityStyles = makeStyles(() => ({
  wrapper: {
    padding: 10,
    marginTop: -20
  },
  content: {
    height: 'calc(100% - 64px)',
    minHeight: 300,
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  summary: {
    // backgroundColor: 'yellow',
    padding: '10px 10px 16px',
    display: 'flex',
    overflowX: 'auto',
    minHeight: 160,
    overflow: 'hidden'
  },
  daydataTable: {
    overflowY: 'auto',
    overflowX: 'hidden',
    backgroundColor: 'pink'
  },
  histoWrapper: {
    border: '1px solid #666',
    padding: 20,
    paddingTop: 25,
    minWidth: 375,
    background: 'white',
    position: 'relative',
    marginRight: 10,
    marginLeft: 10,
    height: 120
  },
  histoTitle: {
    position: 'absolute',
    top: 6,
    fontSize: 13
  },
  histoDiagram: {
    display: 'flex',
    height: 100,
    backgroundColor: 'white',
    position: 'relative',
    borderBottom: '1px solid #ccc',
    borderTop: '1px solid #ccc'
  },
  histoBar: {
    // backgroundColor: 'pink',
    // border: '1px solid #666',
    position: 'absolute',
    bottom: 0,
    width: 11
  },
  histoBarText: {
    position: 'absolute',
    bottom: -15,
    textAlign: 'center',
    fontSize: 10,
    left: -2,
    color: 'initial'
  },
  histoBarValue: {
    position: 'absolute',
    textAlign: 'center',
    fontSize: 10,
    transform: 'rotate(-90deg)',
    left: -5,
    marginBottom: 7,
    fontWeight: 'bold',
    width: 'max-content'
  },
  rangeDisplay: {
    border: '1px solid #666',
    // minWidth: 120,
    padding: '5px 10px',
    marginBottom: 10,
    minHeight: 53,
    whiteSpace: 'nowrap',
    backgroundColor: 'white'
  },
  infoTitle: {
    fontSize: 13
    // marginBottom: 5
  },
  dateValue: {
    // fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 5,
    display: 'flex'
  },
  infoValue: {
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: 5
    // textAlign: 'center'
  },
  alertValue: {
    color: COLORS.alert
  },
  ageCol: {
    fontWeight: 'normal',
    textAlign: 'right',
    width: '100%',
    marginLeft: 15
  }
}));

const SensorValidity = ({ BS_ID, probeID }) => {
  const classes = validityStyles();
  const [validityData, setValidityData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [dailyPacketsMin, setDailyPacketsMin] = useState(0);
  const [dailyPacketsMax, setDailyPacketsMax] = useState(0);
  const [dailyPacketsAvg, setDailyPacketsAvg] = useState(0);

  // console.log('SensorValidity ', BS_ID, probeID);

  useEffect(() => {
    const getData = async () => {
      const vdata = await getValidityOverview({ BS_ID, probeID });
      if (vdata.status_code && Number(vdata.status_code)) {
        // error status
        setValidityData(`Error: status ${vdata.status_code}`);
      } else {
        setValidityData(vdata.results);
        const pkts = vdata?.results?.dataPerDay
          .map((d) => d.data)
          .map((dd) =>
            dd
              .map((d) => d.pktc)
              .filter(Boolean)
              .filter((v, i, a) => a.indexOf(v) === i)
          )
          .map((a) => a.length)
          .filter((a) => a !== 0);
        if (pkts?.length > 0) {
          setDailyPacketsMin(Math.min(...pkts));
          setDailyPacketsMax(Math.max(...pkts));
          const sum = pkts.reduce((a, b) => a + b);
          setDailyPacketsAvg(Math.round(sum / pkts.length));
        }
      }
      setIsLoading(false);
    };
    if (BS_ID && probeID && isLoading) {
      getData();
    }
  }, [isLoading, BS_ID, probeID]);

  const getHistogramData = (data) => {
    // ignore possible today's value
    const covHours = data
      ?.filter((a) => !isToday(new Date(a.from * 1000)))
      .map((a) => a.coveredHours);
    const covHourCounts = countBy(covHours);
    // console.log('HRS', data, covHours, covHourCounts);
    const hours = [...Array(25).keys()]; // 0 to 24 hours
    return hours.map((h) => ({
      h,
      count: covHourCounts[h] || 0,
      percent: Math.ceil(((covHourCounts[h] || 0) / covHours?.length) * 100)
    }));
  };
  const coverageHistogram = getHistogramData(validityData?.dataPerDay);
  // console.log('coverageHistogram', coverageHistogram);

  const getSensorColor = (id) => {
    const type = getSensorType(id);
    if (type === 'T') {
      return COLORS.tango;
    }
    if (type === 'H') {
      return COLORS.haytech;
    }
    return COLORS.tangoXN;
  };

  function getAgeStr(agee) {
    let age = agee;
    let ageStr = '';
    if (Number.isNaN(age)) {
      return '';
    }
    age = Math.max(age, 0); // no negative ages displayed
    if (age < 60) {
      // was 100 before
      // minutes
      ageStr = `${Math.floor(age)} min`;
    } else {
      age /= 60; // hours
      if (age < 24) {
        ageStr = `${Math.floor(age)} h`;
      } else {
        age /= 24; // days
        ageStr = `${Math.floor(age)} d`;
      }
    }

    return ageStr;
  }

  const getAge2 = (y) => {
    let x = new Date();
    // let temp;
    // temp = dateFns.differenceInYears(y, x);
    // let result = `${temp} y `;
    // x = dateFns.addYears(x, temp);
    let result = '';
    let temp = dateFns.differenceInMonths(y, x);
    if (Math.abs(temp) <= 1) {
      temp = dateFns.differenceInDays(y, x);
      return `${result}${Math.abs(temp)} d`;
    }
    result = `${result}${Math.abs(temp)} months `;
    x = dateFns.addMonths(x, temp);
    temp = dateFns.differenceInDays(y, x);
    result = `${result}${-temp} d`;
    // x = dateFns.addDays(x, temp);
    // temp = dateFns.differenceInHours(y, x);
    // result = `${result}${temp} h `;
    // x = dateFns.addHours(x, temp);
    // temp = dateFns.differenceInMinutes(y, x);
    // result = `${result}${temp} min `;
    // x = dateFns.addMinutes(x, temp);
    // temp = dateFns.differenceInSeconds(y, x);
    // result = `${result}${temp} s `;

    return result;
  };

  const hhDiffs = validityData?.dataPerDay
    ?.map((dd) => dd.maxHH - dd.minHH)
    .filter((d) => d).length;

  return (
    <>
      {validityData?.summary && (
        <div
          className={classes.summary}
          style={{ backgroundColor: getSensorColor(validityData?.summary.id) }}
        >
          <div>
            <div className={classes.rangeDisplay}>
              <div className={classes.infoTitle}>First data</div>
              <div className={classes.dateValue}>
                {`${format(validityData?.summary.t0 * 1000, 'd.M.yyyy')}`}
                <div className={classes.ageCol}>{` (${getAge2(
                  new Date(validityData?.summary.t0 * 1000)
                )})`}</div>
              </div>
              <div className={classes.infoTitle}>Last data</div>
              <div className={classes.dateValue}>
                {`${format(validityData?.summary.t1 * 1000, 'd.M.yyyy HH:mm')}`}
                <div className={classes.ageCol}>{` (${getAgeStr(
                  (new Date() - new Date(validityData?.summary.t1 * 1000)) / 60000
                )})`}</div>
              </div>
            </div>

            <div className={classes.rangeDisplay}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ marginRight: 10 }}>
                  <div className={classes.infoTitle}>Total count</div>
                  <div className={classes.dateValue}>{`${validityData?.summary.N}`}</div>
                  <div
                    className={classes.infoTitle}
                  >{`per ${validityData?.summary.dataDays} days`}</div>
                </div>
                {dailyPacketsMax > 0 && (
                  <div style={{ marginRight: 10 }}>
                    <div className={classes.infoTitle}>Daily ♦</div>
                    <div
                      className={`${classes.dateValue} ${
                        dailyPacketsMax >= 36 && classes.alertValue
                      }`}
                    >{`${dailyPacketsMin}-${dailyPacketsMax} /d`}</div>

                    <div
                      className={`${classes.infoTitle} ${
                        dailyPacketsAvg > 24 && classes.alertValue
                      }`}
                    >{`Avg ~${dailyPacketsAvg}/d`}</div>
                  </div>
                )}
                <div style={{ opacity: dailyPacketsMax > 0 ? 0.5 : 1 }}>
                  <div className={classes.infoTitle}>Daily</div>
                  <div
                    className={`${classes.dateValue} ${
                      dailyPacketsMax === 0 &&
                      validityData?.summary.nPerDayMax >= 36 &&
                      classes.alertValue
                    }`}
                  >{`${validityData?.summary.nPerDayMin}-${validityData?.summary.nPerDayMax} /d`}</div>

                  <div
                    className={`${classes.infoTitle} ${
                      dailyPacketsMax === 0 &&
                      Math.round(validityData?.summary.N / validityData?.summary.dataDays) > 24 &&
                      classes.alertValue
                    }`}
                  >{`Avg ~${Math.round(
                    validityData?.summary.N / validityData?.summary.dataDays
                  )}/d`}</div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.histoWrapper}>
            <div className={classes.histoTitle}>{`Daily hours coverage (N=${
              validityData?.summary.nDays - 1
            } d)`}</div>
            <div className={classes.histoDiagram}>
              {coverageHistogram &&
                coverageHistogram.length > 0 &&
                [...Array(25).keys()].map((h) => (
                  <div
                    className={classes.histoBar}
                    key={h}
                    style={{
                      left: h * 15,
                      height:
                        coverageHistogram[h].percent === 0
                          ? 0
                          : Math.max(2, coverageHistogram[h].percent),
                      border: coverageHistogram[h].percent === 0 ? 'none' : null,
                      color: coverageHistogram[h].percent >= 50 ? 'white' : null,
                      backgroundColor:
                        h > 22
                          ? getGradientColor(30, false, true)
                          : getGradientColor(Math.min(80, 90 - h * 1.8), false, true)
                    }}
                  >
                    <div className={classes.histoBarText}>{h % 4 === 0 && `${h}h`}</div>
                    <div
                      className={classes.histoBarValue}
                      style={{
                        bottom:
                          coverageHistogram[h].percent < 50
                            ? coverageHistogram[h].percent
                            : coverageHistogram[h].percent - 30
                      }}
                    >
                      {coverageHistogram[h].percent > 5 && `${coverageHistogram[h].percent} %`}
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div>
            <div className={classes.rangeDisplay}>
              <div className={classes.infoTitle}>Temperature range</div>
              <div
                className={`${classes.infoValue} ${
                  validityData?.summary.minTemp <= 0 && classes.alertValue
                }`}
              >
                {`${Math.round(validityData?.summary.minTemp)} - ${Math.round(
                  validityData?.summary.maxTemp
                )} °C`}
              </div>
              {hhDiffs > 0 && (
                <div className={`${classes.infoTitle} ${classes.alertValue}`}>
                  Data length deviations!
                </div>
              )}
            </div>
            <div className={classes.rangeDisplay}>
              <div className={classes.infoTitle}>Battery range</div>
              <div className={classes.infoValue}>
                {`${Math.round(validityData?.summary.minBatt)} - ${Math.round(
                  validityData?.summary.maxBatt
                )}`}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={classes.content}>
        {isLoading ? (
          <Spinner />
        ) : (
          <div className={classes.wrapper}>
            <SensorValidityTable
              verification={[]}
              // dateFormat={dateFormat}
              // degrees={degrees}
              data={validityData?.dataPerDay?.sort((a, b) => (a.from > b.from ? -1 : 1))}
              // setData={setValidityData}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default SensorValidity;
