import fan from '@iconify/icons-mdi/fan';
import fanOff from '@iconify/icons-mdi/fan-off';
import fanOffline from '@iconify/icons-mdi/fan-remove';
import modeStop from '@iconify/icons-mdi/toggle-switch-off-outline';
import modeRun from '@iconify/icons-mdi/toggle-switch';
import unknown from '@iconify/icons-mdi/help-rhombus-outline';
import thermometerAlert from '@iconify/icons-mdi/thermometer-alert';
import thermometerOff from '@iconify/icons-mdi/thermometer-off';
import RCManualSVG from 'assets/svg/RCManualSVG';
import { COLORS } from 'utils/colors';

export const getRCstatusIcons = ({ rc, width }) => {
  // return object fields:
  // first: RC status icon
  // second: additional state for RC modes run/stop and manual override
  // color: first icon color
  // spin: first icon rotation
  // auto: no manual or device override
  if (rc?.connectionStatus?.isOffline || rc?.connectionStatus?.newOfflineLogged) {
    return { first: fanOffline, second: null, color: COLORS.alert };
  }
  if (rc?.fc_mode === 'on') {
    // ON icon with RUN
    return { first: fan, second: modeRun, color: COLORS.mediumGray, spin: true };
  }
  if (rc?.fc_mode === 'off') {
    // OFF icon with STOP
    return { first: fanOff, second: modeStop, color: COLORS.mediumGray };
  }
  // mode "drive"
  if (rc?.isManual) {
    // manual override
    if (Number(rc?.manualCommand) === 1) {
      return {
        first: fan,
        second: (
          <RCManualSVG
            color={COLORS.mediumGray}
            style={{
              width: width || 20,
              height: width || 20,
              marginLeft: 7
            }}
          />
        ),
        svg: true,
        color: COLORS.validStatus,
        spin: true
      };
    }
    if (Number(rc?.manualCommand) === 0) {
      return {
        first: fanOff,
        second: (
          <RCManualSVG
            color={COLORS.mediumGray}
            style={{
              width: width || 20,
              height: width || 20,
              marginLeft: 7
            }}
          />
        ),
        svg: true,
        color: COLORS.validStatus
      };
    }
  } else {
    // AUTO mode
    const exceptionIcon =
      rc?.ventMode === 'nodata'
        ? thermometerOff
        : rc?.ventMode === 'safety'
        ? thermometerAlert
        : null;

    const exceptionColor = rc?.ventMode === 'safety' ? COLORS.alert : null;
    if (Number(rc?.command) === 1) {
      return {
        first: fan,
        second: exceptionIcon,
        secondColor: exceptionColor,
        color: COLORS.validStatus,
        spin: true,
        auto: true
      };
    }
    if (Number(rc?.command) === 0) {
      return {
        first: fanOff,
        second: exceptionIcon,
        secondColor: exceptionColor,
        color: COLORS.validStatus,
        auto: true
      };
    }
  }

  // console.log('Unknown RC status indication', rc);
  return { first: unknown, second: null, color: COLORS.mediumGray };
};
