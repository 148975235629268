import { Toolbar, IconButton, Typography } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { MdClose, MdChevronLeft, MdChevronRight } from 'react-icons/md';

import { useDispatch, useSelector } from 'react-redux';

import { useStyles } from './SensorDetails.styled';

const SensorDetailsToolbar = ({ data, isLayout }) => {
  const { i18n, isSupervisorCompost } = useSelector((store) => ({
    i18n: store.i18n,
    isSupervisorCompost: store.isSupervisorCompost
  }));
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const handlePreviousClick = (event) => {
    event.preventDefault();
    dispatch({ type: 'RESET_CHARTDATA' });
    dispatch({ type: 'RESET_DATATABLE' });
    history.replace({
      pathname: `${history.location?.state?.urlBack ?? ''}/probes/${data.sensorPrevious}`,
      state: history.location.state
    });
  };

  const handleNextClick = (event) => {
    event.preventDefault();
    dispatch({ type: 'RESET_CHARTDATA' });
    dispatch({ type: 'RESET_DATATABLE' });
    history.replace({
      pathname: `${history.location?.state?.urlBack ?? ''}/probes/${data.sensorNext}`,
      state: history.location.state
    });
  };

  const goBack = () => {
    history.replace(history.location?.state?.urlBack || history.goBack());
  };

  return (
    <div>
      <Toolbar
        className={`${classes.sensorToolbar} ${
          isSupervisorCompost ? classes.periskopSensorToolbar : ''
        }`}
        variant='dense'
      >
        {!isLayout && (
          <div className={classes.chevronLeft}>
            <IconButton
              onClick={handlePreviousClick}
              color='inherit'
              disabled={!data.sensorPrevious}
              style={{ opacity: !data.sensorPrevious ? 0 : 1 }}
            >
              <MdChevronLeft size='1.5em' />
            </IconButton>
          </div>
        )}
        <div className={classes.sensorTitle}>
          {data?.sensorDetails?.name !== data?.sensorDetails?.subid &&
          data?.sensorDetails?.subid?.length <= 6 ? (
            <div className={classes.sensorTitleSmallID}>{data.sensorDetails.subid}</div>
          ) : null}
          <Typography className={classes.sensorName} variant='h6' color='inherit'>
            {data?.sensorDetails ? data?.sensorDetails?.name : ''}
          </Typography>
        </div>
        {!isLayout && (
          <div className={classes.chevronRight}>
            <IconButton
              onClick={handleNextClick}
              color='inherit'
              style={{ opacity: !data.sensorNext ? 0 : 1 }}
              disabled={!data.sensorNext}
            >
              <MdChevronRight size='1.5em' />
            </IconButton>
          </div>
        )}
        <IconButton
          button='true'
          onClick={goBack}
          color='inherit'
          aria-label={i18n.close}
          className={classes.close}
        >
          <MdClose size='1.5em' />
        </IconButton>
      </Toolbar>
    </div>
  );
};

export default SensorDetailsToolbar;
