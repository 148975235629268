import { Dialog, TextField, Typography, Tooltip } from '@material-ui/core';
import React, { useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import DialogTitleWithClose from 'utils/DialogWithCloseIcon';
import { DialogActionUtil } from 'utils/DialogActions';
import { InsightSlider } from 'components/account/account-users/account-background/helpers';
import { getHayType } from '../helpers';

const StorageEditorDialog = ({
  i18n,
  classes,
  state,
  setState,
  onEditStorage,
  storageSelected,
  setStorageSelected,
  storageTypeOptions
}) => {
  const [selectedType, setSelectedType] = useState(
    state.storages.find((s) => s.id === storageSelected.id)?.type
  );

  const onChangeHandler = (newValue, id) => {
    setStorageSelected((tempState) => ({ ...tempState, [id]: newValue }));
    if (id === 'type') {
      setSelectedType(newValue);
    }
  };

  const onSave = () => {
    setState((tempState) => ({
      ...tempState,
      storages: tempState.storages.map((obj) =>
        storageSelected.id === obj.id ? storageSelected : obj
      )
    }));
    onEditStorage();
  };

  const hayType = getHayType(state.hayType.value, state.hayTypeUnit.value);
  const maxCapacity =
    hayType === 'bales' ? state.balesPerYear.value : state.averageTonsPerYear.value;

  // TODO: Take into account the number of cuts per year
  // const capacityRemaining =
  //   maxCapacity - state.storages.map((st) => st.capacity).reduce((a, b) => a + b, 0);

  const title = `${i18n.capacity}${i18n.colon}${storageSelected?.capacity?.toLocaleString() ?? 0} ${
    i18n[`hayTypeUnit_option_${hayType === 'bales' ? 'bales' : 'tons'}`]
  }`;
  return (
    <Dialog className={classes.dialog} fullWidth open>
      <DialogTitleWithClose onClose={onEditStorage}>
        {i18n.new_storage_title || 'New storage properties'}
      </DialogTitleWithClose>
      <div className={classes.storageEditContent}>
        <div className={classes.question}>
          <TextField
            className={classes.textFieldShort}
            label={i18n.storage_name || 'Storage name'}
            id='name'
            type='text'
            value={storageSelected?.name}
            onChange={(e) => onChangeHandler(e.target.value, 'name')}
          />
        </div>
        <div className={classes.question}>
          <Typography gutterBottom>{i18n.storage_type || 'Storage type'}</Typography>
          <ToggleButtonGroup
            value={storageSelected?.type}
            exclusive
            onChange={(e, val) => onChangeHandler(val, 'type')}
          >
            {storageTypeOptions.map((option) => (
              <Tooltip
                key={option.id}
                title={option.label}
                arrow
                placement='bottom'
                disableFocusListener
              >
                <ToggleButton
                  selected={option.id === selectedType}
                  style={{ padding: 10 }}
                  value={option.id}
                >
                  <img width='60px' alt='type' src={option.icon} />
                </ToggleButton>
              </Tooltip>
            ))}
          </ToggleButtonGroup>
          <div className={classes.question}>
            <Typography gutterBottom>{title}</Typography>
            <InsightSlider
              className={classes.slider}
              value={storageSelected.capacity ?? 0}
              // onChange={onChangeHandler}
              onChange={(e, val) => onChangeHandler(val, 'capacity')}
              valueLabelDisplay='on'
              valueLabelFormat={(val) => val?.toLocaleString()}
              // valueLabelFormat={`${storageSelected.capacity ?? 0} ${
              //   hayType === 'bales' ? '' : 't'
              // }`}
              // onChangeCommitted={(e) => onChangeHandler(e.target.value, 'capacity')}
              step={
                maxCapacity <= 3000
                  ? 100
                  : maxCapacity <= 5000
                  ? 200
                  : maxCapacity <= 10000
                  ? 500
                  : 1000
              }
              // marks
              min={0}
              max={maxCapacity}
              color='secondary'
            />
          </div>
        </div>
      </div>
      <DialogActionUtil
        onCancel={onEditStorage}
        onSave={onSave}
        text={i18n.apply || 'Apply'}
        disabled={!storageSelected.type || (storageSelected.capacity ?? 0) === 0}
      />
    </Dialog>
  );
};

export default StorageEditorDialog;
