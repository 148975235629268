import { Tooltip, IconButton, Fab, Button } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { useSelector } from 'react-redux';
import MultiBackend, { TouchTransition } from 'react-dnd-multi-backend';
import { Icon } from '@iconify/react';
import tableColumnPlusAfter from '@iconify/icons-mdi/table-column-plus-after';
import tableColumnRemove from '@iconify/icons-mdi/table-column-remove';
import tableRowPlusAfter from '@iconify/icons-mdi/table-row-plus-after';
import tableRowRemove from '@iconify/icons-mdi/table-row-remove';
import closeBoxMultipleOutline from '@iconify/icons-mdi/close-box-multiple-outline';

import { makeStyles } from '@material-ui/core/styles';
import { getSensorType, formatSensorRow } from 'assets/utils';
import { COLORS } from 'utils/colors';
import LayerCell from './LayerCell';
import SensorListDroppable from './SensorListDroppable';

const bgColor = COLORS.white; // '#eeffee';
const MAX_ROWS = 10;
const MAX_COLS = 20;
const MAX_CELL_SIZE = 80;
const BOTTOM_MARGIN = 50;
const SENSORS_HEIGHT = 65;

const useStyles = makeStyles((theme) => ({
  layoutWrapper: {},
  layoutWrapperDisabled: {
    opacity: 0.6
  },
  row: {
    display: 'flex',
    // height: 50,
    // padding: '25px 30px 45px',
    boxSizing: 'content-box',
    transition: 'all 200ms'
  },
  rowLabel: {
    fontSize: 16,
    textAlign: 'center',
    cursor: 'default',
    minWidth: 16,
    background: COLORS.lightGray2,
    boxSizing: 'content-box',
    border: '1px solid white'
  },
  rowLabelMargin: {
    minWidth: 16,
    boxSizing: 'border-box',
    margin: 1
  },
  sliceContainer: {
    display: 'flex',
    transition: 'all 300ms'
  },
  rowSegment: {
    position: 'relative',
    width: 40
  },
  canvas: {
    width: '100%',
    // overflow: 'auto',
    position: 'relative',
    display: 'inline-block',
    overflow: 'auto'
  },
  columnButtons: {
    minWidth: 90,
    display: 'flex',
    margin: '5px 0px 0 10px',
    transition: 'opacity 300ms'
  },
  columnButtonsFiller: {
    width: 100,
    minWidth: 100
  },
  columnButton: {
    width: 35,
    height: 30,
    marginRight: 10,
    color: COLORS.white
  },
  rowButtons: {
    minWidth: 100,
    height: 30,
    position: 'absolute',
    transition: 'opacity 300ms'
  },
  deleteRowButton: {
    color: COLORS.warning,
    '&hover': {
      color: COLORS.primaryColor
    },
    float: 'right'
  },
  addRowButton: {
    margin: '5px 0px 0px 100px',
    whiteSpace: 'nowrap'
  },
  depthLayer3D: {
    position: 'absolute',
    height: 70, // 3rows @ 0.4
    marginLeft: 50,
    marginRight: 50,
    width: 'min-content',
    transition: 'all 200ms',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0
    }
  },
  buttonIcon: {
    marginRight: 5
  }
}));

const HTML5toTouch = {
  backends: [
    {
      backend: HTML5Backend
    },
    {
      backend: TouchBackend, // Note that you can call your backends with options
      preview: true,
      transition: TouchTransition,
      skipDispatchOnTransition: true
    }
  ]
};

const StorageLayout = ({
  viewHeight,
  theLayout,
  data,
  bsConf,
  auxConf,
  isEditing,
  angle,
  cellWidth,
  spacingFactor,
  isActive,
  onLayoutChange,
  onSensorChange,
  onSensorClicked,
  tempScale,
  alertSensorDepths,
  selectedSensor,
  hoveredSensor,
  onSensorHover,
  leftPadding,
  sensorIDsInOtherStorages
}) => {
  const i18n = useSelector((state) => state.i18n);
  const isDemoMode = useSelector((state) => state.isDemoMode);
  const classes = useStyles();

  // Do not show sensorIDsInOtherStorages as available sensors
  let allSensors = bsConf?.sensor_configurations
    ?.filter((sen) => sensorIDsInOtherStorages?.indexOf(sen.sensor_id_sys) < 0)
    ?.map((sen) => {
      let sensorPoints = 1;
      if (data) {
        const lastMeas = data.find((meas) => meas.sensor_id === sen.sensor_id_sys);
        if (lastMeas && lastMeas.temperatures) {
          sensorPoints = lastMeas.n ? Number(lastMeas.n) + 1 : lastMeas.temperatures.length;
        }
      }
      return {
        id: sen.sensor_id_sys,
        type: getSensorType(sen.sensor_id_sys, sensorPoints, isDemoMode),
        points: sensorPoints,
        row: -1,
        column: -1
      };
    });

  // Include possible auxConf sensors that are not in other storages
  if (auxConf) {
    allSensors = allSensors
      .concat(
        auxConf.map((aux) => {
          const auxItem = {
            id: aux.a,
            type: 'A',
            points: aux.hh.length,
            row: -1,
            column: -1
          };
          return auxItem;
        })
      )
      .filter((aux) => sensorIDsInOtherStorages?.indexOf(aux.id) < 0);
  }

  // theLayout.sensors have locations of storage sensors. Set the locations in allSensors respectively
  allSensors = allSensors?.map((aSensor) => {
    const layoutSensor = theLayout?.sensors?.find((sen) => sen.id === aSensor.id);
    if (layoutSensor) {
      layoutSensor.type = aSensor.type;
      layoutSensor.points = aSensor.points;
    }
    return layoutSensor || aSensor;
  });

  const [multiBSsensors] = useState(
    theLayout?.sensors?.filter((s) => !allSensors.map((sen) => sen.id).includes(s.id))
  );

  // Prevent from using Outdoor ID in a layout
  allSensors = allSensors?.filter((sensor) => sensor.type !== 'H');

  // console.log('3 theLayout rows: ', theLayout.rows);
  // Ensure there are default 3x3 rows
  const [rowArray, setRowArray] = useState(
    theLayout.rows || [
      { index: 0, rowLength: 3 },
      { index: 1, rowLength: 3 },
      { index: 2, rowLength: 3 }
    ]
  );
  const [sensorArray, setSensorArray] = useState(allSensors.concat(multiBSsensors));

  const [lastRowFade, setLastRowFade] = useState(false);
  const [lastColFade, setLastColFade] = useState(false);

  const [showCoordinates] = useState(true);

  const applyRowChange = (newRowArray) => {
    if (onLayoutChange) {
      onLayoutChange(newRowArray);
    } else {
      console.log("onLayoutChange() missing for phase's new rows = ", newRowArray);
    }
    setRowArray(newRowArray);
    theLayout.rows = newRowArray;
  };
  const applySensorsChange = (newSensorArray) => {
    setSensorArray(newSensorArray);
    // newSensorArray includes all sensors, not only those assigned to the batch phase.
    // Save only assigned sensors to sensor_layout.sensors. Sensor is assigned if it has real (>=0) row coordinate.
    theLayout.sensors = newSensorArray.filter((sen) => Number(sen.row) >= 0);
    const sensorsWithoutType = theLayout.sensors.map((sen) => {
      const withoutType = { id: sen.id, row: sen.row, column: sen.column };
      return withoutType;
    });
    if (onSensorChange) {
      // Do not save sensor.type and sensor.points, they are for display only
      onSensorChange(sensorsWithoutType);
    } else {
      console.log("onSensorChange() missing for phase's new sensors = ", sensorsWithoutType);
    }
  };

  const onSensorMoved = (sensorId, targetId) => {
    const movedSensor = sensorArray.find((s) => s.id === sensorId);
    const prevLocation = [Number(movedSensor.row), Number(movedSensor.column)];
    let targetLocation = [-1, -1]; // use this when sensor removed from layout (targetId === 'bin')
    if (targetId !== 'bin') {
      // slot Ids have format 'row.col' -> split by '.' to separate row and col
      targetLocation = targetId.split('.').map((loc) => Number(loc));
    }
    // console.log(sensorId, ' target loc=', targetLocation);
    const [row, column] = targetLocation;

    if (row === prevLocation[0] && column === prevLocation[1]) {
      // nothing moved
      return;
    }
    movedSensor.row = row;
    movedSensor.column = column;
    // remove sensor, and add it as new with location updated
    const newSensorArray = sensorArray.filter((sen) => sen.id !== sensorId);
    newSensorArray.push(movedSensor);
    applySensorsChange(newSensorArray);
  };

  const onRemoveAllSensors = () => {
    const sensorArrayAllOut = sensorArray.map((sensor) => ({ ...sensor, row: -1, column: -1 }));
    applySensorsChange(sensorArrayAllOut);
  };

  const changeColumns = (rowIndex, change) => {
    // rowArray is array or row items: { index: 0, rowName: 'Row 1', rowLength: 4 }
    // change rowLength of the row with given rowIndex
    const targetRow = rowArray.find((r) => Number(r.index) === Number(rowIndex));
    // targetRow.rowLength = Number(targetRow.rowLength) + change;
    // let newRowArray = rowArray.filter(r => Number(r.index) !== Number(rowIndex));
    // newRowArray.push(targetRow);
    let newRowArray = rowArray.map((row) => {
      const rowItem = row;
      rowItem.rowLength = Number(rowItem.rowLength) + change;
      return rowItem;
    });
    // remove sensors on removed column on all rows
    const affectedSensors = sensorArray.filter(
      (s) => Number(s.column) === Number(targetRow.rowLength)
    );
    affectedSensors.map((affectedSensor) =>
      onSensorMoved(affectedSensor.id, 'bin', affectedSensor.type)
    );
    // keep rows sorted from 0 upwards
    newRowArray = newRowArray.sort((a, b) => (Number(a.index) < Number(b.index) ? -1 : 1));
    applyRowChange(newRowArray);
    setLastRowFade(false);
  };

  const changeRows = (rowIndex, isRemove) => {
    const nthRow = Number(rowArray.map((r) => Number(r.index)).indexOf(Number(rowIndex)));
    if (isRemove) {
      // console.log('REM ', nthRow, ' <-- index=', rowIndex, rowArray);
      // removable row is given index + 1
      const remIndex = Number(rowArray[nthRow + 1].index);
      const newRowArray = rowArray.filter((r) => Number(r.index) !== remIndex);
      const affectedSensors = sensorArray.filter((s) => Number(s.row) === remIndex);
      if (affectedSensors) {
        for (let i = 0; i < affectedSensors.length; i++) {
          onSensorMoved(affectedSensors[i].id, 'bin', affectedSensors[i].type);
        }
      }
      applyRowChange(newRowArray);
    } else {
      // Add same amount of columns as in previous row
      // const prevRowColumnCount = rowArray.find(r => r.index === rowIndex - 1).rowLength;
      // console.log('ADD ', nthRow, ' <-- index=', rowIndex, rowArray);
      const prevRowColumnCount = Number(rowArray[nthRow].rowLength);
      // Add 1 to  the biggest index
      const newIndex = Math.max(...rowArray.map((r) => Number(r.index))) + 1;
      let newRowArray = rowArray.map((r) => r);

      const newRow = {
        index: newIndex,
        rowName: `Row ${newIndex}`,
        rowLength: prevRowColumnCount
      };
      newRowArray.push(newRow);
      newRowArray = newRowArray.sort((a, b) => (a.index < b.index ? -1 : 1));
      applyRowChange(newRowArray);
    }
    setLastRowFade(false);
  };

  // Show col & row edit buttons when layout skew has been cleared after transition
  const [showWithDelay, setShowWithDelay] = useState(0);
  useEffect(() => {
    const timer = setTimeout(() => setShowWithDelay(isEditing ? 1 : 0), 50);
    return () => clearTimeout(timer);
  });

  const getCounterSkewTransform = (skewAngle, scaleY, doTranslate) => ({
    transform: `skewX(-${
      (Math.atan(scaleY * Math.tan((skewAngle * Math.PI) / 180)) / Math.PI) * 180
    }deg) scaleY(${(1 / scaleY).toFixed(3)})${
      doTranslate ? ` translate(-${(4 * skewAngle) / 60}px, ${(6 * skewAngle) / 60}px)` : ''
    }`
  });

  const getColumnButtons = (row) => (
    <div
      className={classes.columnButtons}
      style={{ opacity: showWithDelay }}
      // style={getCounterSkewTransform(skewAngle, scaleY)}
    >
      <Tooltip
        title={i18n.remove_column || 'Remove column'}
        placement='bottom'
        disableFocusListener
      >
        <span>
          <Fab
            className={classes.columnButton}
            color='primary'
            onMouseEnter={() => setLastColFade(true)}
            onMouseLeave={() => setLastColFade(false)}
            disabled={Number(row.rowLength) === 1}
            onClick={() => changeColumns(Number(row.index), -1)}
          >
            <Icon width={20} height={20} icon={tableColumnRemove} />
          </Fab>
        </span>
      </Tooltip>
      <Tooltip title={i18n.add_column || 'Add column'} placement='bottom' disableFocusListener>
        <span>
          <Fab
            className={classes.columnButton}
            disabled={Number(row.rowLength) === MAX_COLS}
            color='secondary'
            onClick={() => changeColumns(Number(row.index), 1)}
          >
            <Icon width={20} height={20} icon={tableColumnPlusAfter} />
          </Fab>
        </span>
      </Tooltip>
    </div>
  );

  // find max of multipoint length among sensors within the layout

  const sensorsInLayout = sensorArray.filter((sen) => Number(sen.row) >= 0).map((s) => s.id);

  const dataLengths = data
    .filter((meas) => sensorsInLayout.indexOf(meas.sensor_id) >= 0)
    .map((m) => (m.temperatures ? m.n || m.temperatures.length - 1 : 1));
  const floorCount =
    sensorsInLayout.length === 0 || dataLengths.length === 0 ? 1 : Math.max(...dataLengths);
  const floorIndices = Array.from(Array(floorCount).keys()).reverse();
  const rowIndices = rowArray.map((r) => Number(r.index));

  const getCellMeas = (meas, depth, showHiTemp) => {
    const newMeas = meas ? { ...meas } : null;
    if (meas && meas.temperatures) {
      if (showHiTemp) {
        // When editing, only top depth is shown.
        // Show temp color of hottest value (=temperature field)
        newMeas.temperature = meas.temperature;
      } else {
        newMeas.temperature = meas.temperatures[depth + 1];
      }
    }
    return meas && meas.temperatures
      ? meas.temperatures.length >= depth + 2
        ? newMeas
        : null
      : depth === 0
      ? meas
      : null;
  };

  const layoutComponent = floorIndices.map((depth) => (
    <div
      key={depth}
      id={`floor_${depth}`}
      style={{
        height: 30 + (rowIndices.length - 1) * 20,
        // Math.max(0,
        top:
          20 +
          // (angle / 60) *
          // (depth * (30 + (rowIndices.length - 1) * 20) - 5 * (rowIndices.length - 1)) +
          (cellWidth / MAX_CELL_SIZE) * depth * spacingFactor * rowIndices.length -
          (angle / 60) * rowIndices.length * 7,
        left: leftPadding + (angle / 60) * rowIndices.length * 5,
        transform: `skewX(${angle}deg) scaleY(${(1 - angle / 90).toFixed(3)})`,
        opacity: isEditing ? (depth > 0 ? 0 : 1) : 1
      }}
      // className={isEditing ? null : classes.depthLayer3D}
      className={classes.depthLayer3D}
    >
      {rowArray.map((row) => {
        const columnIndices = Array.from(Array(Number(row.rowLength)).keys());
        return (
          <div key={row.index}>
            <div className={classes.row}>
              {isEditing ? (
                Number(row.index) === 0 ? (
                  getColumnButtons(row, angle, Number((1 - angle / 90).toFixed(1)))
                ) : (
                  <div className={classes.columnButtonsFiller} />
                )
              ) : null}
              <div
                className={classes.sliceContainer}
                style={
                  lastRowFade && row.index === rowIndices.length - 1
                    ? { filter: 'grayscale(1)', opacity: 0.2 }
                    : {}
                }
              >
                {isEditing || depth === floorIndices.length - 1 || showCoordinates ? (
                  <div
                    className={classes.rowLabel}
                    style={{
                      paddingTop: Math.round(cellWidth / 2) - 10,
                      marginBottom:
                        row.index === rowIndices.length - 1
                          ? 18 + (isEditing ? 0 : BOTTOM_MARGIN / Math.cos((angle * Math.PI) / 180))
                          : 0,
                      paddingBottom:
                        row.index === rowIndices.length - 1
                          ? cellWidth - 22 - (Math.round(cellWidth / 2) - 10)
                          : 0
                    }}
                  >
                    {formatSensorRow(row.index)}
                  </div>
                ) : (
                  <div className={classes.rowLabelMargin} />
                )}
                {columnIndices.map((column) => {
                  const sensorInColumn = sensorArray.find(
                    (sen) =>
                      Number(sen.row) === Number(row.index) && Number(sen.column) === Number(column)
                  );
                  const lastMeasInCell = sensorInColumn
                    ? getCellMeas(
                        data.find((meas) => meas.sensor_id === sensorInColumn.id),
                        depth,
                        isEditing
                      )
                    : null;
                  // console.log(depth, 'meas=', lastMeasInCell, getCellMeas(lastMeasInCell, depth));
                  return (
                    <div key={column}>
                      <LayerCell
                        // row={row}
                        column={column}
                        depth={depth}
                        width={cellWidth}
                        isEditing={isEditing}
                        isActive={isActive}
                        onSensorMoved={onSensorMoved}
                        onSensorClicked={onSensorClicked}
                        id={`${row.index}.${column}`}
                        label={column + 1}
                        sensorInColumn={sensorInColumn}
                        onSensorHover={onSensorHover}
                        isSelected={
                          sensorInColumn &&
                          (selectedSensor === sensorInColumn.id ||
                            hoveredSensor === sensorInColumn.id)
                        }
                        isAlert={
                          sensorInColumn &&
                          alertSensorDepths &&
                          alertSensorDepths
                            .find((al) => al && al.sensor_id === sensorInColumn.id)
                            ?.depths?.indexOf(depth + 1) >= 0
                        }
                        occupiedType={sensorInColumn ? sensorInColumn.type : null}
                        measInLocation={lastMeasInCell}
                        showColumnLabel={
                          (isEditing || depth === floorIndices.length - 1 || showCoordinates) &&
                          row.index === rowIndices.length - 1
                        }
                        isBottomFloor={depth === floorIndices.length - 1}
                        isFaded={lastColFade && column === columnIndices.length - 1}
                        bgColor={bgColor}
                        tempScale={tempScale}
                        angle={angle}
                        antiSkew={getCounterSkewTransform(
                          angle,
                          Number((1 - angle / 90).toFixed(3)),
                          true
                        )}
                      />
                      {/* </div> */}
                    </div>
                  );
                })}
              </div>
            </div>
            {isEditing ? (
              <div
                className={classes.rowButtons}
                style={{ opacity: showWithDelay }}
                // style={getCounterSkewTransform(angle, Number((1 - angle / 90).toFixed(1)))}
              >
                {rowIndices.indexOf(Number(row.index)) < rowArray.length - 1 ? (
                  Number(row.index) < rowArray.length - 2 ? null : (
                    <Tooltip
                      title={i18n.remove_row || 'Remove row'}
                      placement='bottom'
                      disableFocusListener
                    >
                      <IconButton
                        button='true'
                        color='inherit'
                        aria-label='delete row'
                        style={{ marginTop: 10 }}
                        className={classes.deleteRowButton}
                        onMouseEnter={() => setLastRowFade(true)}
                        onMouseLeave={() => setLastRowFade(false)}
                        onClick={() => changeRows(Number(row.index), true)}
                      >
                        <Icon icon={tableRowRemove} />
                      </IconButton>
                    </Tooltip>
                  )
                ) : rowArray.length < MAX_ROWS ? (
                  <div style={{ paddingBottom: 50 }}>
                    <Button
                      button='true'
                      color='secondary'
                      aria-label='add row'
                      startIcon={<Icon icon={tableRowPlusAfter} />}
                      className={classes.addRowButton}
                      onClick={() => changeRows(Number(row.index))}
                    >
                      {i18n.add_row || 'Add row'}
                    </Button>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  ));
  return (
    <div className={classes.layoutWrapper}>
      <DndProvider backend={MultiBackend} options={HTML5toTouch}>
        {isEditing ? (
          <SensorListDroppable
            sensors={sensorArray.filter((sen) => sen.row < 0)}
            data={data}
            onDrop={onSensorMoved}
            listHeight={SENSORS_HEIGHT}
          />
        ) : null}
        <div
          id='storageLayoutPanel'
          style={
            viewHeight ? { height: viewHeight - (isEditing ? SENSORS_HEIGHT : 0) } : { height: 500 }
          }
          className={classes.canvas}
        >
          {layoutComponent}
          {theLayout.sensors.length > 0 && isEditing && (
            <Button color='secondary' onClick={() => onRemoveAllSensors()}>
              <Icon className={classes.buttonIcon} icon={closeBoxMultipleOutline} />
              {i18n.remove_all_sensors}
            </Button>
          )}
        </div>
      </DndProvider>
    </div>
  );
};

export default StorageLayout;
