import { useEffect, useReducer, useMemo } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';

import { compact, isEmpty } from 'lodash';
import { format, isWithinInterval } from 'date-fns';

import { DAY_IN_MILLISECONDS } from 'redux/constants';
import { min, max } from './constants';

import {
  getSensorDataFromAPI,
  generateOverviewChartData,
  generateFullChartData,
  getDays
} from './helper';

const initialState = {
  isLoading: false,
  isSnapShot: false,
  data_temperature: [],
  data_signal0: [],
  data_signal1: [],
  data_voltage: [],
  data_humidity: [],
  snapshots: null,
  temperatureMin: 20,
  temperatureMax: 60,
  signalMin: -120,
  signalMax: -20,
  voltageMin: 250,
  voltageMax: 400,
  logs: []
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOADING_DATA':
      return {
        ...state,
        isLoading: true
      };
    case 'LOAD_OVERVIEW_DATA':
      return {
        ...state,
        snapshots: action.snapshots
      };
    case 'LOAD_DATA':
      return {
        ...state,
        ...action.data,
        logs: action.logs,
        isSnapShot: action.isSnapShot,
        isLoading: false
      };
    default:
      return state;
  }
};

const checkSnapShot = (date) => {
  const { days } = getDays(date);
  return days > 31;
};

const getOverviewData = async (
  bsID,
  sensorId,
  chartDataMemo,
  reduxDispatch,
  auxProbe,
  auxIDuser,
  isDemoMode,
  sensorConfigurations
) => {
  try {
    if (isEmpty(chartDataMemo)) {
      // If virtualBS, see real BS from bsConf
      const realBs = sensorConfigurations?.find((s) => s.sensor_id_sys === sensorId)?.realBs;
      const sensorData = await getSensorDataFromAPI(
        realBs || bsID,
        sensorId,
        min,
        max,
        true,
        auxProbe,
        auxIDuser,
        isDemoMode
      );
      const snapshots = generateOverviewChartData(sensorData, min, max, auxProbe);
      reduxDispatch({ type: 'SET_OVERVIEWDATA', snapshots });
      return snapshots;
    }
    return chartDataMemo.snapshots;
  } catch (err) {
    return null;
  }
};

const getData = async (
  isSnapShot,
  bsID,
  sensorId,
  date,
  chartDataMemo,
  isConfigChangedRefCurrent,
  reduxDispatch,
  auxProbe,
  auxIDuser,
  isDemoMode,
  bsGroup
) => {
  try {
    const fromDate = date[0];
    // Set end of date because slider calculation begin with end of toDate
    const toDate = date[1] + (DAY_IN_MILLISECONDS - 1);
    if (isEmpty(chartDataMemo) || isConfigChangedRefCurrent) {
      const sensorData = await getSensorDataFromAPI(
        bsID,
        sensorId,
        fromDate,
        toDate,
        isSnapShot,
        auxProbe,
        auxIDuser,
        isDemoMode,
        bsGroup
      );
      const fullChartData = generateFullChartData(sensorData, date, isSnapShot, auxProbe);
      reduxDispatch({ type: 'SET_CHARTDATA', sensorData, fullChartData, isSnapShot });
      return fullChartData;
    }
    return chartDataMemo;
  } catch (err) {
    return null;
  }
};

const getLogs = (logs, date) => {
  if (logs) {
    const allLogs = compact(
      logs.map((log, index) => {
        if (
          isWithinInterval(new Date(Number(log.log_ts)), {
            start: new Date(date[0]),
            end: new Date(date[1])
          })
        ) {
          return {
            type: log.type || '1',
            date: format(Number(log.log_ts), 'yyyy/MM/dd HH:mm'),
            index,
            log_ts: Number(log.log_ts),
            note: log.note,
            trash: false
          };
        }
        return null;
      })
    );
    return allLogs;
  }
  return [];
};

const useFetchSensorData = (isConfigChangedRefCurrent, auxProbe, auxIDuser, isDemoMode) => {
  const reduxDispatch = useDispatch();
  const { sensorDetails, chartConfig, chartData, bsID, sensorConfigurations, bsConf } = useSelector(
    (store) => ({
      // sessionInfo: store.sessionInfo,
      sensorDetails: store.sensorDetails,
      chartConfig: store.chartConfig,
      chartData: store.chartData,
      bsID: store.baseStation.bs_BS_ID,
      sensorConfigurations: store.sensorConfigurations,
      bsConf: store.bsConf
    })
  );
  const [state, dispatch] = useReducer(reducer, initialState);

  // const { bs_ids: bsIds } = sessionInfo;
  const { id: sensorId, logs } = sensorDetails;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const chartDataMemo = useMemo(() => chartData, [sensorId]);

  useEffect(() => {
    const fetchOverviewData = async () => {
      if (sensorId) {
        const snapshots = await trackPromise(
          getOverviewData(
            bsID,
            sensorId,
            chartDataMemo,
            reduxDispatch,
            auxProbe,
            auxIDuser,
            isDemoMode,
            sensorConfigurations
          )
        );
        dispatch({ type: 'LOAD_OVERVIEW_DATA', snapshots });
      }
    };
    fetchOverviewData();
  }, [
    bsID,
    sensorId,
    chartDataMemo,
    reduxDispatch,
    auxProbe,
    auxIDuser,
    isDemoMode,
    sensorConfigurations
  ]);

  useEffect(() => {
    const fetchSensorData = async () => {
      if (sensorId) {
        dispatch({ type: 'LOADING_DATA' });
        // If virtualBS, see real BS from bsConf
        const realBs = sensorConfigurations?.find((s) => s.sensor_id_sys === sensorId)?.realBs;
        const { date } = chartConfig;
        const isSnapShot = checkSnapShot(date);
        const [data, allLogs] = await trackPromise(
          Promise.all([
            getData(
              isSnapShot,
              realBs || bsID,
              sensorId,
              date,
              chartDataMemo,
              isConfigChangedRefCurrent,
              reduxDispatch,
              auxProbe,
              auxIDuser,
              isDemoMode,
              bsConf?.bsGroup
            ),
            getLogs(logs, date)
          ])
        );
        dispatch({ type: 'LOAD_DATA', data, logs: allLogs, isSnapShot });
      }
    };
    fetchSensorData();
  }, [
    bsID,
    sensorId,
    logs,
    chartConfig,
    chartDataMemo,
    isConfigChangedRefCurrent,
    reduxDispatch,
    auxProbe,
    auxIDuser,
    isDemoMode,
    sensorConfigurations,
    bsConf?.bsGroup
  ]);

  return navigator.onLine ? state : {};
};

export default useFetchSensorData;
