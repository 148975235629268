import { Typography, TextField, Dialog, DialogTitle, Divider } from '@material-ui/core';
import React, { useState, useEffect, useCallback } from 'react';

import { tempFormatDelta, tempFormatDeltaToSave } from 'assets/utils';
import { DeleteDialog } from 'utils/DeleteDialog';
import { DialogActionUtil } from 'utils/DialogActions';

import relmax0 from 'assets/images/relmax0.png';
import relmaxMinus from 'assets/images/relmaxMinus.png';
import relmaxPlus from 'assets/images/relmaxPlus.png';
import relmin0 from 'assets/images/relmin0.png';
import relminMinus from 'assets/images/relminMinus.png';
import relminPlus from 'assets/images/relminPlus.png';

const RelRuleEditor = ({
  classes,
  i18n,
  degrees,
  selectedRuleType,
  selectedRule,
  setMonitorEdited,
  monitorEdited,
  newRule,
  setNewRule,
  setSelectedRuleType,
  setSelectRuleTypeDialog,
  setSelectedRule,
  // ruleTypes,
  setRuleInUseEdited,
  monitorInUse,
  selectedMonitor,
  timeStamp
}) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  // RULE TYPE GROUP 2
  const [pastMeasurements, setPastMeasurements] = useState(6);
  const [alertToleranceTemp, setAlertToleranceTemp] = useState(3);
  const [alertResetTolerance, setAlertResetTolerance] = useState(0);
  const [illustration, setIllustration] = useState(relmax0);
  const [relType, setRelType] = useState('REL_MAX');
  const [ruleChanged, setRuleChanged] = useState(false);

  const onResetStates = () => {
    setNewRule(false);
    setSelectRuleTypeDialog(false);
    setSelectedRuleType(null);
    setSelectedRule(null);
    setOpenDeleteDialog(false);

    // RULE TYPE 2
    setPastMeasurements(6);
    setAlertToleranceTemp(tempFormatDelta(degrees, 3));
    setAlertResetTolerance(tempFormatDelta(degrees, 0));
  };

  const getIllustration = useCallback(
    (resetValue) => {
      if (relType === 'do nothing') {
        console.log('never here'); // Kludge to allow [relType] dependency list in arrow function
      }
      return relType === 'REL_MAX'
        ? resetValue > 0
          ? relmaxPlus
          : resetValue < 0
          ? relmaxMinus
          : relmax0
        : resetValue > 0
        ? relminPlus
        : resetValue < 0
        ? relminMinus
        : relmin0;
    },
    [relType]
  );

  const handleResetToleranceChange = (value) => {
    setAlertResetTolerance(value);
    setIllustration(getIllustration(value));
    setRuleChanged(true);
  };

  const handleDeleteRule = () => {
    setMonitorEdited({
      ...monitorEdited,
      rules: monitorEdited.rules.filter((ruleToDelete) => ruleToDelete !== selectedRule)
    });

    onResetStates();
  };

  useEffect(() => {
    setRelType(selectedRuleType?.type);
    if (selectedRule && !ruleChanged) {
      // in REL_MAX, resetTolerance is inverted for display (to improve understanding with illustration)
      const toleranceInversion = selectedRuleType?.type === 'REL_MAX' ? -1 : 1;
      // RULE TYPE GROUP 2
      setPastMeasurements(
        selectedRule?.settings?.turnPoint !== undefined
          ? Number(selectedRule.settings?.turnPoint)
          : 6
      );
      setAlertToleranceTemp(
        selectedRule?.settings?.alertLimit !== undefined
          ? tempFormatDelta(degrees, +selectedRule.settings?.alertLimit)
          : tempFormatDelta(degrees, 3)
      );
      setAlertResetTolerance(
        selectedRule?.settings?.alertReset !== undefined
          ? tempFormatDelta(degrees, toleranceInversion * selectedRule.settings?.alertReset)
          : tempFormatDelta(degrees, 0)
      );
      setIllustration(getIllustration(alertResetTolerance));
    } else {
      setIllustration(getIllustration(alertResetTolerance));
    }
  }, [degrees, selectedRule, alertResetTolerance, selectedRuleType, getIllustration, ruleChanged]);

  const onSaveRELRule = (selectedRuleToEdit) => {
    // in REL_MAX, resetTolerance is inverted for display (to improve understanding with illustration)
    const toleranceInversion = relType === 'REL_MAX' ? -1 : 1;
    const settingsToSave = {
      turnPoint: +pastMeasurements,
      alertLimit: tempFormatDeltaToSave(degrees, +alertToleranceTemp),
      alertReset: tempFormatDeltaToSave(degrees, toleranceInversion * alertResetTolerance),
      tempRef: 'SINGLE' // For now only one supported for relative rule
      // depthProbe: depth !== '' ? depth : null,
      // tempRef: selectedRuleType?.refs?.filter(ref => ref.id === tempRef)[0]?.name
    };

    if (selectedRuleToEdit) {
      return {
        id: selectedRuleToEdit.id,
        type: selectedRuleType.type,
        ruleChanged: {
          changedAt: timeStamp,
          oldSettings: selectedMonitor && {
            ...selectedMonitor.rules.find((rule) => rule.id === selectedRuleToEdit.id)?.settings
          },
          newSettings: settingsToSave
        },
        settings: settingsToSave
      };
    }
    return {
      id:
        monitorEdited.rules.length >= 1
          ? Math.max(...monitorEdited.rules?.map((s) => +s.id)) + 1
          : 1,
      type: selectedRuleType.type,
      settings: settingsToSave
    };
  };

  const onSaveRule = () => {
    let ruleData;
    if (selectedRule) {
      if (selectedRuleType.group === 'REL') {
        ruleData = onSaveRELRule(selectedRule);
      } else {
        return;
      }

      setMonitorEdited({
        ...monitorEdited,
        rules: monitorEdited.rules.map((rule) =>
          rule.id === ruleData.id
            ? {
                ...monitorEdited.rules.find((ruleEdited) => ruleEdited.id === ruleData.id),
                ...ruleData
              }
            : { ...rule }
        )
      });
    } else {
      if (selectedRuleType.group === 'REL') {
        ruleData = onSaveRELRule();
      } else {
        return;
      }

      // console.log('ruleData', ruleData, alertToleranceTemp, alertResetTolerance);

      if (monitorEdited.rules) {
        setMonitorEdited({
          ...monitorEdited,
          rules: [...monitorEdited.rules, ruleData]
        });
      } else {
        setMonitorEdited({
          ...monitorEdited,
          rules: [ruleData]
        });
      }
    }
    if (monitorInUse) {
      setRuleInUseEdited(true);
    }
    onResetStates();
  };
  const disabledSave = (typeGroupToSave) => {
    switch (typeGroupToSave) {
      case 'REL':
        return (
          !(pastMeasurements >= 1 && pastMeasurements <= 48) ||
          !(
            alertToleranceTemp >= (degrees === '°C' ? 1 : 2) &&
            alertToleranceTemp <= (degrees === '°C' ? 10 : 20)
          ) ||
          !(
            alertResetTolerance >= (degrees === '°C' ? -5 : -10) &&
            alertResetTolerance <= (degrees === '°C' ? 5 : 10)
          )
        );
      default:
        break;
    }
  };

  return (
    <Dialog className={classes.dialog} open={newRule} aria-labelledby='responsive-dialog-title'>
      <DialogTitle>
        <div className={classes.DialogTitle}>
          <div>{i18n[`${selectedRuleType?.type}_title`] || selectedRuleType?.title}</div>
        </div>
      </DialogTitle>
      <div className={classes.div}>
        {/* <div className={classes.div}>
    <Typography id='button-group' gutterBottom>
      {i18n.turn_point_avg || 'Turn point detection averaging'}
    </Typography>
    <div className={classes.NumberField}>
      <TextField
        className={classes.TextFieldN}
        id='turn-point'
        type='number'
        variant='outlined'
        InputProps={{ inputProps: { min: 1, max: 48 } }}
        value={pastMeasurements}
        onChange={e => setPastMeasurements(e.target.value)}
        error={!(pastMeasurements >= 1 && pastMeasurements <= 48)}
        helperText={
          !(pastMeasurements >= 1 && pastMeasurements <= 48) &&
          (`${i18n.err_value_between} ${1} & ${48}` ||
            'The value has to be between 1 and 48')
        }
        required
      />
      <span style={{ padding: 5 }}>{i18n.past_meas || 'past measurements'}</span>
    </div>
  </div>
  <Divider /> */}

        <div className={classes.div}>
          <Typography id='button-group' gutterBottom>
            {`${i18n.alert_tolerance || 'Alert tolerance'} (A)`}
          </Typography>
          <div className={classes.NumberField}>
            <TextField
              className={classes.TextFieldN}
              id='alert-tolerance'
              type='number'
              variant='outlined'
              InputProps={{
                inputProps: {
                  min: degrees === '°C' ? 1 : 2,
                  max: degrees === '°C' ? 10 : 20
                }
              }}
              value={alertToleranceTemp}
              onChange={(e) => setAlertToleranceTemp(e.target.value)}
              error={
                !(
                  alertToleranceTemp >= (degrees === '°C' ? 1 : 2) &&
                  alertToleranceTemp <= (degrees === '°C' ? 10 : 20)
                )
              }
              helperText={
                !(
                  alertToleranceTemp >= (degrees === '°C' ? 1 : 2) &&
                  alertToleranceTemp <= (degrees === '°C' ? 10 : 20)
                ) &&
                (`${i18n.err_value_between} ${degrees === '°C' ? 1 : 2} - ${
                  degrees === '°C' ? 10 : 20
                }` ||
                  'The value has to be between 1 and 10')
              }
              required
            />
            <span style={{ padding: 5 }}>{degrees}</span>
          </div>
        </div>
        <Divider />

        <div className={classes.div}>
          <Typography id='button-group' gutterBottom>
            {`${i18n.alert_reset_tolerance || 'Alert reset tolerance'} (R)`}
          </Typography>
          <div className={classes.NumberField}>
            <TextField
              className={classes.TextFieldN}
              id='alert-reset-tolerance'
              // type='number'
              variant='outlined'
              InputProps={{
                type: 'number',
                inputProps: {
                  min: degrees === '°C' ? -5 : -10,
                  max: degrees === '°C' ? 5 : 10
                }
              }}
              value={alertResetTolerance}
              onChange={(e) => handleResetToleranceChange(e.target.value)}
              error={
                !(
                  alertResetTolerance >= (degrees === '°C' ? -5 : -10) &&
                  alertResetTolerance <= (degrees === '°C' ? 5 : 10)
                )
              }
              helperText={
                !(
                  alertResetTolerance >= (degrees === '°C' ? -5 : -10) &&
                  alertResetTolerance <= (degrees === '°C' ? 5 : 10)
                ) &&
                (`${i18n.err_value_between} ${0} - ${degrees === '°C' ? 5 : 10}` ||
                  `The value has to be between 0 and 5`)
              }
              required
            />
            <span style={{ padding: 5 }}>{degrees}</span>
          </div>
        </div>

        <div
          style={{
            border: '1px solid #ddd',
            textAlign: 'center',
            padding: '10px 0',
            boxSizing: 'border-box',
            minHeight: 182
          }}
        >
          <img width='400px' alt='reset' src={illustration} />
        </div>
      </div>
      <DialogActionUtil
        onCancel={onResetStates}
        onSave={onSaveRule}
        text={i18n.save || 'Save'}
        disabled={disabledSave(selectedRuleType?.group)}
        deleteButton={selectedRule}
        handleDelete={() => setOpenDeleteDialog(true)}
      />
      <DeleteDialog
        i18n={i18n}
        openDeleteDialog={openDeleteDialog}
        handleCloseDeleteDialog={() => setOpenDeleteDialog(false)}
        handleDelete={handleDeleteRule}
        text={i18n.alert_remove_rule || 'Do you want to remove this rule?'}
      />
    </Dialog>
  );
};

export default RelRuleEditor;
