import { isNil } from 'assets/utils';

function roundUp(x, step) {
  return x % step ? x - (x % step) + step : x + step;
}
function roundDown(x, step) {
  return x % step ? x + -(x % step) - step : x - step;
}

export const getRangeFromData = (sensorIDswithPlace, minmaxData, chartScale) => {
  // Get the min/max temps of all probes in storage to have same sensor chart scale to all sensors

  const dataOfSensorIDswithPlace =
    minmaxData?.filter((dat) => sensorIDswithPlace.indexOf(dat.a) >= 0) || [];

  const chartMinTemp = Math.min(
    ...dataOfSensorIDswithPlace
      .filter((d) => d.ll)
      .map((d) => d.ll.slice(1, d.ll.length)) // ignore 1st (handle temp)
      .flat(1)
      .concat(dataOfSensorIDswithPlace.filter((dat) => !dat.ll).map((dat) => dat.l)) // encounter single point minimums (l)
      .filter((t) => !isNil(t)) // ignore null temps
  );
  const chartMaxTemp = Math.max(
    ...dataOfSensorIDswithPlace
      .filter((d) => d.hh)
      .map((d) => d.hh.slice(1, d.hh.length)) // ignore 1st (handle temp)
      .flat(1)
      .concat(dataOfSensorIDswithPlace.filter((dat) => !dat.hh).map((dat) => dat.h))
      .filter((t) => !isNil(t)) // ignore null temps
  );
  // Ensure we have a value even if data is incomplete
  let scaleMax = Number.isNaN(chartMaxTemp)
    ? chartScale
      ? Number(chartScale[1])
      : 100
    : roundUp(chartMaxTemp, 5); // Round to next full 5 degrees
  let scaleMin = Number.isNaN(chartMinTemp)
    ? chartScale
      ? Number(chartScale[0])
      : 0
    : roundDown(chartMinTemp, 5);
  // Apply chartScale if it is wider than data min/max
  if (chartScale) {
    scaleMax = Math.max(scaleMax, Number(chartScale[1]));
    scaleMin = Math.min(scaleMin, Number(chartScale[0]));
  }
  return [scaleMin, scaleMax];
};

export const getStorageSensorIds = (storage, objects) => {
  const sensors = storage
    ? storage?.layoutType === 3
      ? storage?.stacks.map((s) => s.sensors).flat()
      : storage?.sensor_layout
      ? storage.sensor_layout?.sensors?.filter((sen) => sen.column >= 0) || []
      : []
    : [];
  return objects ? sensors : sensors.map((sen) => sen.id);
};

export const getPilesFromBales = (bales, stack, rows, columns) => {
  const rowIndices = Array.from(Array(rows || stack.nRows).keys());
  const colIndices = Array.from(Array(columns || stack.nColumns).keys());
  return rowIndices
    .map((row) =>
      colIndices.map((col) => ({
        c: col,
        r: row,
        h: bales.filter((b) => b.r === row && b.c === col).length
      }))
    )
    .flat()
    .filter((pile) => pile.h > 0);
};

export const getBalesFromPiles = (piles) =>
  piles
    .map((pile) =>
      Array.from(Array(pile.h).keys()).map((floor) => ({
        c: pile.c,
        r: pile.r,
        f: floor
      }))
    )
    .flat();
