import { Card, CardContent, Typography } from '@material-ui/core';
import { format, isSameDay, add } from 'date-fns';
import React from 'react';
import { useSelector } from 'react-redux';
import { Icon } from '@iconify/react';
// import thermometerAlert from '@iconify/icons-mdi/thermometer-alert';
// import thermometerOff from '@iconify/icons-mdi/thermometer-off';
import storage from '@iconify/icons-mdi/warehouse';
import batchOngoing from '@iconify/icons-mdi/image-filter-hdr';
import batch from '@iconify/icons-mdi/image-filter-hdr-outline';
import ruleIcon from '@iconify/icons-mdi/cog-outline';
import { listItemStyle, templateMonitorStyle } from 'utils/sharedStyles';
import classesSpin from 'utils/Spinner.module.css';
import { COLORS } from 'utils/colors';
// import RCManualSVG from 'assets/svg/RCManualSVG';
import RCCycle from './RCCycle';
import { getRCstatusIcons } from './utils';

const CompostRCItem = ({ rc, onRCSelected, rcStorage, rcBatch, monitors }) => {
  const classes = templateMonitorStyle();
  const itemClasses = listItemStyle();

  const { i18n, dateFormat, timeFormat } = useSelector((store) => ({
    i18n: store.i18n,
    dateFormat: store.sessionInfo?.ui_preferences?.date_format,
    timeFormat: store.sessionInfo?.ui_preferences?.time_format,
    storeFCs: store.FcStatus
  }));

  const assignedIcon = rcStorage
    ? storage
    : rcBatch
    ? Number(rcBatch.status) === 1
      ? batchOngoing
      : batch
    : null;

  const statusIcons = getRCstatusIcons({ rc });

  const getOfflineDateDiv = () => {
    if ((rc?.connectionStatus?.isOffline || rc?.connectionStatus?.newOfflineLogged) && rc.fc_date) {
      const lastCallDate = new Date(rc.fc_date);
      const offlineDate = rc?.connectionStatus?.newOfflineLogged
        ? new Date(rc?.connectionStatus?.newOfflineLogged)
        : add(lastCallDate, {
            seconds: Number(rc.interval)
          });
      const isToday = isSameDay(offlineDate, new Date());
      const date = isToday ? '' : format(offlineDate, dateFormat ?? 'd.M.yy');
      const time = isToday ? format(offlineDate, timeFormat ?? 'HH:mm') : null;

      return (
        <div>
          <div>{date}</div>
          <div>{time}</div>
        </div>
      );
    }
    return null;
  };

  return (
    <div
      key={rc.itemID}
      className={[classes.cardWrap, itemClasses.Item, classes.compostRCmonitorItem].join(' ')}
    >
      <Card className={classes.card} item={rc} onClick={() => onRCSelected(rc)}>
        <CardContent>
          <Typography
            style={{ textAlign: 'left', border: 'none' }}
            className={classes.compostRCmonitorTitle}
            color='textSecondary'
            gutterBottom
          >
            {rc.name}
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div
              style={{
                minWidth: 30,
                paddingRight: 10,
                borderRight: `1px solid ${COLORS.lightGray}`
              }}
            >
              <div style={{ textAlign: 'center', color: statusIcons.color }}>
                <Icon
                  className={statusIcons.spin ? classesSpin.rotateFan : null}
                  // style={{ marginBottom: 5 }}
                  width={35}
                  // color={COLORS.chartArea}
                  icon={statusIcons.first}
                />
              </div>
              <div style={{ textAlign: 'center', fontSize: 12, color: COLORS.alert }}>
                {getOfflineDateDiv()}
                {statusIcons.svg ? (
                  statusIcons.second
                ) : (
                  <Icon
                    width={20}
                    color={statusIcons.secondColor || COLORS.mediumGray}
                    icon={statusIcons.second}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={rcStorage || rcBatch ? classes.assignedBatch : classes.nonAssigned}>
                {rcStorage || rcBatch ? (
                  <Icon
                    style={{ margin: '0px 4px -4px 0' }}
                    width={18}
                    color={COLORS.mediumGray}
                    icon={assignedIcon}
                  />
                ) : null}
                {rcStorage ? rcStorage.name : rcBatch ? rcBatch.batch_name : i18n.rc_not_assigned}
              </div>
              {rcStorage ||
                (rcBatch && (
                  <div className={classes.assignedMonitor}>
                    {rcStorage || rcBatch ? (
                      <Icon
                        style={{ margin: '0px 5px -4px 0px' }}
                        width={16}
                        color={COLORS.mediumGray}
                        icon={ruleIcon}
                      />
                    ) : null}
                    {rcStorage
                      ? rcStorage.monitor?.name
                      : rcBatch
                      ? monitors?.find((mon) => mon.itemID === rc.monitorRef)?.name
                      : 'No monitor'}
                  </div>
                ))}
              {/* {rc.isManual &&
                (rc.connectionStatus?.isOffline ||
                  rc.connectionStatus?.newOfflineLogged ||
                  rc.fc_mode !== 'drive' ||
                  rc.manualDate) && (
                  <div
                    style={{ marginTop: 5, display: 'flex', width: '150%' }}
                    className={classes.nonAssigned}
                  >
                    <RCManualSVG
                      color={COLORS.mediumGray}
                      style={{
                        width: 14,
                        height: 14,
                        marginRight: 7
                      }}
                    />
                    Manual control pending
                  </div>
                )} */}
              {/* {rc.pending && (
                <div style={{ fontSize: 10, width: 200, overflow: 'auto' }}>
                  {JSON.stringify(rc.pending)}
                </div>
              )} */}
            </div>
            <div
              style={{
                minWidth: 60,
                paddingLeft: 15,
                borderLeft: `1px solid ${COLORS.lightGray}`,
                opacity: statusIcons.auto && (rcStorage || rcBatch) ? 1 : 0
              }}
            >
              <div className={classes.compostMonitorCycle}>
                {rc.ventMode === 'cycle' ? (
                  <RCCycle
                    cycleSpan={
                      monitors?.find((mon) => mon.itemID === rc.monitorRef)?.rule
                        .cycleSpanMinutes || 0
                    }
                    runMinutes={
                      monitors?.find((mon) => mon.itemID === rc.monitorRef)?.rule.runMinutes || 0
                    }
                    index={0}
                    width={40}
                    colorTemp={0}
                    progressMinutes={rc?.currentCycleSecs / 60 || 0}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};
export default CompostRCItem;
