const adminChangesConfirm = async (isAdmin, confirm, settingName, username) => {
  if (isAdmin) {
    await confirm({
      title: 'Hello admin',
      description: `Are you sure you want change the value of "${settingName}" for user ${username} ?`
    });
  }
};

export default adminChangesConfirm;
