import { Grid, ListItem, ListItemText, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { accountUsersStyles } from 'utils/sharedStyles';
import { generateAnswersObject } from './backgroundStateManager';
import EditAnswersItemDialog from './EditAnswersItemDialog';
import { getI18nText, getI18nOption, getI18nExtra } from './helpers';

const Item = ({
  // pkey,
  info,
  toggleEditInfo
  // toggleEditUser,
  // togglePhoneItem
}) => {
  const { i18n } = useSelector((store) => ({
    i18n: store.i18n
  }));

  const secondary = (infoSecondary) => {
    // To show 'Non applicable' for Hay cuts
    if (infoSecondary.extra && infoSecondary.extra.value === true) {
      return getI18nExtra(i18n, infoSecondary.key, infoSecondary.extra);
    }
    if (infoSecondary.type === 'radio' || infoSecondary.type === 'toggle') {
      return getI18nOption(
        i18n,
        infoSecondary.key,
        infoSecondary.options.find((option) => option.value === infoSecondary.value)
      );
    }
    if (infoSecondary.key === 'balesWeight') {
      return `${infoSecondary.value} ${infoSecondary.labelOption}`;
    }
    if (infoSecondary.key === 'storageTime') {
      if (infoSecondary.value[0] === infoSecondary.value[1]) {
        return `${infoSecondary.value[0]} ${i18n.months}`;
      }
      return `${infoSecondary.value[0]}-${infoSecondary.value[1]} ${i18n.months}`;
    }
    return infoSecondary.value;
  };

  return (
    <Grid item xs={12} sm={6}>
      <ListItem button onClick={() => toggleEditInfo(info)}>
        <ListItemText primary={getI18nText(i18n, info, 'label')} secondary={secondary(info)} />
      </ListItem>
    </Grid>
  );
};

const AccountBackground = ({ surveyAnswers }) => {
  const classes = accountUsersStyles();

  const backgroundInfo = generateAnswersObject(surveyAnswers);

  const dispatch = useDispatch();

  const filteringItems = (items) => {
    const filtered = items;
    // Cleaning up the survey items
    if (filtered.hayType.value === 'bales') {
      if (filtered.hayTypeUnit.value === 'bales') {
        filtered.averageTonsPerYear.hide = true;
        filtered.balesPerYear.hide = false;
      } else {
        filtered.balesPerYear.hide = true;
        filtered.averageTonsPerYear.hide = false;
      }
      filtered.balesType.hide = false;
      filtered.balesWeight.hide = false;
    } else {
      filtered.averageTonsPerYear.hide = false;
      filtered.balesPerYear.hide = true;
      filtered.balesType.hide = true;
      filtered.balesWeight.hide = true;
    }
    return Object.keys(filtered).filter((data) => !filtered[data].hide);
  };

  const [isOpenEditDialog, setIsOpenEditDialog] = useState(null);

  const toggleEditInfo = (key) => {
    if (key) {
      setIsOpenEditDialog(key);
    } else {
      setIsOpenEditDialog(null);
    }
  };

  const { i18n } = useSelector((store) => ({
    i18n: store.i18n
  }));

  if (isEmpty(surveyAnswers)) {
    return null;
  }

  return (
    <div>
      <Typography variant='h6' component='h6'>
        {i18n.background_info ?? 'Background information'}
      </Typography>

      <Grid container className={classes.gridContainer}>
        {filteringItems(backgroundInfo).map((key) => (
          <Item key={key} pkey={key} info={backgroundInfo[key]} toggleEditInfo={toggleEditInfo} />
        ))}
      </Grid>

      {isOpenEditDialog && (
        <EditAnswersItemDialog
          info={isOpenEditDialog}
          backgroundInfo={backgroundInfo}
          toggleEditInfo={toggleEditInfo}
          dispatch={dispatch}
        />
      )}
    </div>
  );
};

export default AccountBackground;
