import { CircularProgress } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import classes from './Spinner.module.css';

const useStyles = makeStyles(() => ({
  hide: {
    display: 'none'
  },
  spinnerContainer: {
    position: 'absolute',
    left: 'calc(50% - 35px)',
    top: 'calc(50% - 35px)',
    zIndex: 1000
  }
}));

export const Spinner = ({ isLoadingLogic, noStyle }) => {
  const classesSpinner = useStyles();

  const isLoading = useSelector((store) => store.isLoading);

  return (
    <div
      className={
        noStyle
          ? null
          : isLoadingLogic
          ? isLoading
            ? classesSpinner.spinnerContainer
            : classesSpinner.hide
          : classesSpinner.spinnerContainer
      }
    >
      <CircularProgress color='primary' size={70} disableShrink />
    </div>
  );
};

export const SpinnerPrintableReport = () => <div className={classes.loader}>Loading...</div>;
