import { makeStyles } from '@material-ui/styles';
import { COLORS } from 'utils/colors';

export const backgroundInfoStyles = makeStyles(() => ({
  extra1: {
    marginTop: -10
  },
  extra2: {
    marginTop: -10,
    float: 'right',
    fontStyle: 'italic'
  },
  question: {
    // margin: 10,
    padding: '15px 0'
  },
  slider: {
    marginTop: 45,
    width: '100%'
  },
  radio: {
    '&$checked': {
      color: COLORS.haytech
    }
  },
  checked: {},
  checkbox: {
    '&$checked': {
      color: COLORS.haytech
    }
  }
}));
