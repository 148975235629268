import {
  Dialog,
  TextField,
  Slide,
  DialogContent,
  DialogTitle,
  FormControl
} from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

import { getSensorType, createDefaultStack } from 'assets/utils';
import { postSaveStorage } from 'api/serverAPI';
import {
  StorageCapacitySelector,
  StorageTypeSelector
} from 'components/account/account-users/account-background/helpers';
import { DialogActionUtil } from 'utils/DialogActions';
import * as storageConstants from '../storage-utils/storageConstants';
import { getHayType, storageTypeOptionsArray } from '../../insight/helpers';
import StorageTypeOptions from './StorageTypeOptions';

// import { StorageContext } from '../StorageContext';

const useStyles = makeStyles(() => ({
  dialogTitle: {
    padding: 0
  },
  typeTitle: {
    padding: 0,
    marginBottom: -8
  },
  storageTypeToggleButton: {
    paddingRight: 20
  },
  stackIcon: {
    marginRight: 10,
    transform: 'rotate(128deg) scaleX(-1)'
  },
  flatIcon: { marginRight: 10, transform: 'scaleY(0.7)' },
  actionButtons: {
    margin: '5px 15px 10px'
  },
  question: {
    // margin: 10,
    padding: '15px 0'
  },
  slider: {
    marginTop: 35,
    width: '100%'
  }
}));

const Transition = React.forwardRef((props, ref) => <Slide direction='up' ref={ref} {...props} />);

Transition.displayName = 'Transition';

const StorageCreate = ({
  // createStorage,
  storages,
  // onClose,
  setStoragesChanges,
  setWaitForServer,
  selectedStorage,
  setSelectedStorage,
  isMissingInfoOpen,
  // isMissingInfoOpen
  setIsMissingInfoOpen,
  setIsStorageEdited,
  monitors,
  isDemoMode
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { i18n, sensorTypes, userInfo, surveyAnswers, insightService, isAdmin } = useSelector(
    (store) => ({
      i18n: store.i18n,
      sensorTypes: store.sensorConfigurations.map((s) =>
        getSensorType(s.sensor_id_sys, s.multipoint)
      ),
      userInfo: store.userInfo,
      surveyAnswers: store.surveyAnswers,
      insightService: store.insightService,
      isAdmin: store.isAdmin
    })
  );

  const onClose = (newStorage) => {
    if (setIsMissingInfoOpen) {
      setIsMissingInfoOpen(false);
    } else if (newStorage.itemID !== undefined) {
      setWaitForServer(true);
      history.replace(`/storages/${newStorage.itemID}`);
    } else {
      dispatch({ type: 'SET_GROUPDETAILS', value: null }); // clear possible storage selection
      history.replace('/storages');
    }
  };
  const classes = useStyles();

  const [ID, setID] = useState(selectedStorage?.name ?? '');

  // Type option is for user with both Tangos and several Haytechs
  // We may have a grain user with RC (and the reference haytech)
  // A real haytech user would have min. 10 probes.
  // Here we allow down to 6 haytechs to provide a stack storage option.
  // What we would need here are the outdoor probes
  const [showTypeOptions] = useState(
    !isEmpty(sensorTypes.filter((s) => s !== 'H')) &&
      sensorTypes.filter((s) => s === 'H').length > 5
  );

  const { hayType, hayTypeUnit, balesPerYear, averageTonsPerYear } = surveyAnswers;
  const typeUnit = getHayType(hayType, hayTypeUnit);

  const maxCapacity = typeUnit === 'bales' ? balesPerYear : averageTonsPerYear;
  // Prefer flat storage type if user has Tangos
  const [typeSelection, setTypeSelection] = useState(
    isEmpty(sensorTypes)
      ? hayType === 'bales'
        ? 'stack'
        : 'flat'
      : !isEmpty(sensorTypes.filter((s) => s !== 'H'))
      ? 'flat'
      : 'stack'
  );

  const handleIDChange = (event) => {
    setID(event.target.value);
  };

  const storageTypeOptions = storageTypeOptionsArray(i18n);
  const [storageType, setStorageType] = useState(selectedStorage?.type ?? null);
  const [storageCapacity, setStorageCapacity] = useState(selectedStorage?.capacity ?? 0);

  const onSave = async () => {
    const newStorage = {
      name: ID,
      type: storageType,
      capacity: storageCapacity
    };

    if (isDemoMode && isAdmin) {
      newStorage.demoType = isDemoMode;
    }

    // Grain storages are flat storages with Tango probes placed on one layer.
    // The depth comes from the number of Tango measurement points.
    // Stack storages are for Haytech probes to be placed on any layer.
    // We set storage layoutType 3 ('3' for 3D, or for Premium(3)).
    // Btw, Free or expired Premium users should not be here creating a storage.

    // const hasHaytech = !isEmpty(sensorTypes.filter((s) => s === 'H'));
    // const hasTango = !isEmpty(sensorTypes.filter((s) => s !== 'H'));
    if (typeSelection === 'stack') {
      newStorage.layoutType = 3;
      // Create initial empty stack for stack storage
      newStorage.stacks = [
        createDefaultStack(
          storageConstants.DEFAULT_COLUMNS,
          storageConstants.DEFAULT_ROWS,
          storageConstants.DEFAULT_FLOORS
        )
      ];
    }

    if (isDemoMode && !isAdmin) {
      newStorage.monitor = monitors.find((mon) => mon.defaultMonitor === true);
      newStorage.alertMessaging = { sms: { active: true, UIDS: [userInfo.U_ID] } };
      const nextIndex = isEmpty(storages) ? 1 : Math.max(...storages.map((st) => +st.itemID)) + 1;
      const newDemoStorage = { ...newStorage, itemID: nextIndex };
      const newStoragesArray = [...storages, newDemoStorage];
      dispatch({ type: 'SET_STORAGES', value: newStoragesArray });
      onClose(newDemoStorage);
    } else {
      try {
        let response;
        if (selectedStorage) {
          setSelectedStorage((prevData) => {
            const newData = { ...prevData };
            // Deleting undefinedLayoutType
            if (newData.undefinedLayoutType) {
              delete newData.undefinedLayoutType;
            }
            return { ...newData, ...newStorage };
          });
          setIsStorageEdited(true);
          dispatch({ type: 'SET_EDIT_CONFIRM_NEEDED', value: true });
        } else {
          response = await postSaveStorage({
            UID: userInfo?.U_ID || 'no_uid',
            item: newStorage,
            locale: i18n.locale,
            insightUser: insightService
          });
          console.log(response);
          setStoragesChanges(true);
        }
        onClose(response);
      } catch (error) {
        console.log('Error in postSaveStorage: ', error);
        // setIsSavingLoading(false);
      }
    }
  };

  const disabled = () => {
    if (insightService) {
      return ID === '' || !storageType || !storageCapacity;
    }
    return ID === '';
  };

  const onChangeTypeHandler = (e, val) => {
    if (val !== null) {
      setStorageType(val);
    }
  };

  const onChangeCapacityHandler = (e, val) => setStorageCapacity(val);

  return (
    <div>
      <Dialog
        open={selectedStorage ? isMissingInfoOpen : true}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={!!insightService}
      >
        <DialogContent>
          <DialogTitle className={classes.dialogTitle}>
            {selectedStorage
              ? i18n.storage_properties || 'Storage properties'
              : i18n.new_storage || 'New storage'}
          </DialogTitle>

          <FormControl component='fieldset'>
            <TextField
              id='standard-basic'
              label={i18n.storage_name || 'Storage name'}
              type='text'
              value={ID}
              onChange={handleIDChange}
              required
            />
          </FormControl>
          {insightService && (
            <div className={classes.question}>
              <StorageTypeSelector
                i18n={i18n}
                storageType={storageType}
                onChange={onChangeTypeHandler}
                storageTypeOptions={storageTypeOptions}
              />
              <div className={classes.question}>
                <StorageCapacitySelector
                  i18n={i18n}
                  storageCapacity={storageCapacity}
                  hayType={hayType}
                  typeUnit={typeUnit}
                  classes={classes}
                  onChangeHandler={onChangeCapacityHandler}
                  maxCapacity={maxCapacity}
                />
              </div>
            </div>
          )}
          {showTypeOptions && (
            <StorageTypeOptions
              classes={classes}
              i18n={i18n}
              typeSelection={typeSelection}
              setTypeSelection={setTypeSelection}
            />
          )}
        </DialogContent>
        <DialogActionUtil
          cancelDisabled={!!selectedStorage}
          onCancel={onClose}
          onSave={onSave}
          text={selectedStorage ? i18n.apply : i18n.add_storage || 'Add storage'}
          disabled={disabled()}
        />
      </Dialog>
    </div>
  );
};

export default StorageCreate;
