// PUBLIC IMPORTS
import React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';

class PhoneAddSVG extends React.Component {
  render() {
    return (
      <div style={this.props.style}>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12'>
          <path
            d='M7.3,11.5H3.5c-0.6,0-1-0.4-1-1v-9c0-0.6,0.4-1,1-1h5c0.6,0,1,0.4,1,1v5c-0.4,0-0.7,0.1-1,0.2V2.5h-5v7h3
	C6.5,10.3,6.8,11,7.3,11.5z'
            style={{ fill: this.props.color }}
          />
          <polyline
            points='11.4,10 11.4,9 9.9,9 9.9,7.5 8.9,7.5 8.9,9 7.4,9 7.4,10 8.9,10 8.9,11.5 9.9,11.5 9.9,10 11.4,10 '
            style={{ fill: this.props.color }}
          />
        </svg>
      </div>
    );
  }
}

const styles = (theme) => ({
  hide: {
    display: 'none'
  }
});

export default withRouter(withWidth()(withStyles(styles)(PhoneAddSVG)));
