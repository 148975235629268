import React, { useEffect } from 'react';
import { Button } from '@material-ui/core';
import GenericAppBar from 'components/general/GenericAppBar';
import { postInsightHold } from 'api/serverAPI';
import insightPicture from 'assets/images/greengrass.jpg';
import jwtManager, { accessTokenKey, refreshTokenKey } from '../../api/jwtManager';
import { insightStyles } from './insightStyles';

const AccountDeactivated = ({ i18n }) => {
  const classes = insightStyles();

  const postFreeUserEntry = async () => {
    await postInsightHold();
    // Do logout
    jwtManager.removeToken(accessTokenKey);
    jwtManager.removeToken(refreshTokenKey);
  };

  useEffect(() => {
    postFreeUserEntry();
  }, []);

  const logoClick = () => {
    window.location.href = '/login';
  };

  return (
    <>
      <GenericAppBar title={i18n.insight ?? ''} noLanguageSelector onClickLogo={logoClick} />
      <div className={classes.welcomeRoot} style={{ backgroundImage: `url(${insightPicture})` }}>
        <div className={classes.welcomeContent}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div>
              {/* <h2>{i18n.insight_welcome_title}</h2> */}
              <div style={{ fontWeight: 'bold', marginTop: 30 }} className={classes.div}>
                {i18n.free_account_deactivated_1}
              </div>
              <div className={classes.div}>
                {`${i18n.free_account_deactivated_2}`}{' '}
                <a href={`mailto:support@quanturi.com?subject=${i18n.free_account_deactivated_1}`}>
                  support@quanturi.com
                </a>
                .
              </div>
            </div>
          </div>
          <div style={{ margin: '30px 0', textAlign: 'center' }}>
            <Button
              // className={classes.button}
              variant='contained'
              onClick={logoClick}
              color='secondary'
              style={{ color: 'white', minWidth: 100 }}
            >
              {i18n.back}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountDeactivated;
