import { Dialog, DialogTitle } from '@material-ui/core';
import React from 'react';

import { DialogActionUtil } from './DialogActions';

export const DeleteDialog = ({
  i18n,
  openDeleteDialog,
  handleCloseDeleteDialog,
  handleDelete,
  text,
  textAction
}) => (
  <div>
    <Dialog
      open={!!openDeleteDialog}
      onClose={() => handleCloseDeleteDialog()}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle disableTypography style={{ fontSize: '1rem' }} id='alert-dialog-title'>
        {text}
      </DialogTitle>
      <DialogActionUtil
        onCancel={handleCloseDeleteDialog}
        onSave={handleDelete}
        text={textAction || i18n.remove || 'remove'}
      />
    </Dialog>
  </div>
);
