import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { fetchData } from 'navigation/MainNavigator/helper';
import '../styles.css';
import { payHandlerService, getOrderSummaryService } from './helpers';
import { countryObj, countriesOps } from '../../../account-users/helpers';

import { SuccessDialog, ProcessingDialog } from '../checkout-components/CheckoutDialogs';
import BillingInfo from '../checkout-components/BillingInfo';
import { CardField, ErrorMessage, SubmitButton, SummaryField } from '../StripeElements';
import { getCurrencySign, setErrorLanguage } from '../helpers';

const ServiceRenewalForm = ({ i18n, renewalState, unauthorized }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { billingInfo, renewalInfo, userInfo } = renewalState;
  const { dateFormat, bsId } = useSelector((store) => ({
    dateFormat: store.sessionInfo?.ui_preferences?.date_format,
    bsId: store.bsConf?.bs_id
  }));
  const countriesOptions = countriesOps(i18n.locale);
  const i18nStripe = setErrorLanguage(i18n);

  const currencySign = getCurrencySign(renewalInfo.currency);

  // service
  const { serviceLabel, servicePrice, totalVTA, totalPrice } = getOrderSummaryService(
    userInfo,
    renewalInfo,
    dateFormat,
    i18n
  );

  const [success, setSuccess] = useState(false);
  const countryObject = { ...countryObj, data: countriesOptions };
  const [valueCountry, setValueCountry] = useState(
    countriesOptions.find((o) => o.value === billingInfo.country)
  );
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [successInvoice, setSuccessInvoice] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [billingDetails, setBillingDetails] = useState({
    name: billingInfo.name,
    email: billingInfo.email,
    address: {
      city: billingInfo.city,
      country: billingInfo.country,
      line1: billingInfo.address,
      postal_code: billingInfo.zip
    }
  });
  const [companyName, setCompanyName] = useState(billingInfo.company);

  const handleSubmit = async (event) => {
    event?.preventDefault();
    setError(null);
    payHandlerService(
      stripe,
      elements,
      CardElement,
      cardComplete,
      renewalInfo,
      billingDetails,
      companyName,
      totalPrice,
      i18n,
      i18nStripe,
      setProcessing,
      setSuccess,
      setError,
      dispatch,
      userInfo
    );
  };

  const reset = async () => {
    const resetState = () => {
      setError(null);
      setProcessing(false);
      setSuccess(false);
      setSuccessInvoice(false);
      setBillingDetails({
        name: '',
        email: '',
        address: {
          city: '',
          country: '',
          line1: '',
          postal_code: ''
        }
      });
      setCompanyName('');
      setSuccess(false);
      history.replace(success ? '/' : '/account/services');
    };
    if (success) {
      try {
        await fetchData(bsId, dispatch);
        resetState();
      } catch (e) {
        alert('An error occured, please refresh the page');
      }
    } else {
      resetState();
    }
  };

  return unauthorized || success || successInvoice ? (
    <SuccessDialog
      success={success}
      successInvoice={successInvoice}
      error={error}
      i18n={i18n}
      billingDetails={billingDetails}
      upgradeInfo={renewalState}
      unauthorized={unauthorized}
      reset={reset}
    />
  ) : (
    <form className='Form' onSubmit={handleSubmit}>
      <h3 className='Title'>{i18n.billing_information}</h3>
      <fieldset className='FormGroup'>
        <BillingInfo
          i18n={i18n}
          billingDetails={billingDetails}
          setBillingDetails={setBillingDetails}
          companyName={companyName}
          setCompanyName={setCompanyName}
          countryObject={countryObject}
          valueCountry={valueCountry}
          setValueCountry={setValueCountry}
        />
      </fieldset>
      <h3 className='Title'>{i18n.order_summary}</h3>
      <>
        <fieldset className='FormGroup'>
          <SummaryField label={serviceLabel} value={`${servicePrice} ${currencySign}`} />
          {/* <SummaryField label={i18n.subtotal} value={`${subTotal} ${currencySign}`} /> */}
          <SummaryField
            label={`${i18n.vat} (${parseInt(renewalInfo.taxRate)}%)`}
            value={`${totalVTA.toFixed(2)} ${currencySign}`}
          />
          <SummaryField
            total
            label={i18n.total}
            value={`${totalPrice.toFixed(2)} ${currencySign}`}
          />
        </fieldset>
        <h3 className='Title'>{i18n.payment}</h3>
        <fieldset className='FormCardElement'>
          <CardField
            onChange={(e) => {
              setError(null);
              if (e.error) {
                setError(e.error);
              }
              setCardComplete(e.complete);
            }}
          />
        </fieldset>
        {error && <ErrorMessage>{error.message}</ErrorMessage>}
        <div className='submitDiv'>
          <SubmitButton i18n={i18n} processing={processing} error={error} disabled={!stripe}>
            {i18n.buy} ({totalPrice.toFixed(2)} {currencySign})
          </SubmitButton>
        </div>
      </>
      {processing && <ProcessingDialog i18n={i18n} />}
    </form>
  );
};

export default ServiceRenewalForm;
