import { Fab } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { Icon } from '@iconify/react';
import plus from '@iconify/icons-mdi/plus';
import minus from '@iconify/icons-mdi/minus';

import { makeStyles } from '@material-ui/core/styles';
// import { getSensorType } from 'assets/utils';
import { COLORS } from 'utils/colors';
import * as storageConstants from '../../storage-utils/storageConstants';

const MAX_PILEHEIGHT = storageConstants.MAX_FLOORS;

const useStyles = makeStyles(() => ({
  buttonIcon: {
    marginRight: 5
  },

  controlsPanel: {
    marginRight: 10
  },
  dimensionButton: {
    color: COLORS.white,
    width: 37,
    minWidth: 37,
    height: 30,
    borderRadius: 3
  },
  dimensionControl: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
    marginTop: 5,
    padding: 5,
    borderRadius: 3,
    backgroundColor: COLORS.whiteSmoke,
    boxSizing: 'border-box'
  },
  dimensionLabels: {
    // textTransform: 'capitalize',
    lineHeight: '35px',
    whiteSpace: 'nowrap',
    padding: '0 15px',
    fontSize: 14
  }
}));

const BalesHeightControls = ({ disabled, pileHeight, width, onPileHeightChange }) => {
  const i18n = useSelector((state) => state.i18n);
  const classes = useStyles();

  const changeFloors = (change) => {
    if (change < 0 && pileHeight === 1) {
      return; // Cannot remove all floors
    }
    onPileHeightChange(pileHeight + change);
  };

  return (
    <div className={classes.controlsPanel}>
      <div style={{ width, opacity: disabled ? 0.6 : 1 }} className={classes.dimensionControl}>
        <Fab
          disabled={disabled || pileHeight === 1}
          className={classes.dimensionButton}
          color='primary'
          onClick={() => changeFloors(-1)}
        >
          <Icon width={20} height={20} icon={minus} />
        </Fab>
        <div className={classes.dimensionLabels}>{`${
          i18n.pile_height || 'Pile height'
        } (${pileHeight})`}</div>
        <Fab
          className={classes.dimensionButton}
          disabled={disabled || pileHeight >= MAX_PILEHEIGHT}
          color='secondary'
          onClick={() => changeFloors(1)}
        >
          <Icon width={20} height={20} icon={plus} />
        </Fab>
      </div>
    </div>
  );
};

export default BalesHeightControls;
