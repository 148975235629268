import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@material-ui/core';

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useConfirm } from 'hook/useConfirm';
import adminChangesConfirm from 'utils/adminChangesConfirm';

import { accountUsersStyles } from 'utils/sharedStyles';

const regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;

const validateEmail = (value) => !regexEmail.test(value);

const EditUserItemDialog = ({
  editUserItem,
  userIndex,
  userItem,
  toggleEditUserItem,
  saveUserItem
}) => {
  const classes = accountUsersStyles();
  const i18n = useSelector((state) => state.i18n);
  const confirm = useConfirm();

  const { isAdmin, sessionInfo } = useSelector((state) => ({
    isAdmin: state.isAdmin,
    sessionInfo: state.sessionInfo
  }));

  const [value, setValue] = useState(userItem.value);
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    if (userItem.title === 'Email') {
      setError(validateEmail(e.target.value));
    }
    setValue(e.target.value);
  };

  const onSave = async () => {
    await adminChangesConfirm(isAdmin, confirm, userItem.title, sessionInfo.username);
    userItem.value = value;
    saveUserItem(userIndex, userItem);
    toggleEditUserItem();
  };

  return (
    <Dialog open={editUserItem} aria-labelledby='responsive-dialog-title'>
      <DialogTitle>{userItem.title}</DialogTitle>
      <DialogContent className={classes.dialogContent} elevation={0}>
        {userItem.type === 'radio' ? (
          <RadioGroup
            aria-label={userItem.title}
            name={userItem.title}
            value={value}
            onChange={handleChange}
          >
            {Object.keys(userItem.data).map((key, index) => (
              <FormControlLabel
                key={index}
                value={key}
                control={<Radio />}
                label={userItem.data[key]}
              />
            ))}
          </RadioGroup>
        ) : (
          <>
            <TextField
              label={userItem.title}
              value={value}
              onChange={handleChange}
              error={error}
              fullWidth
            />
            <Typography color='error'>{error && i18n.invalid_email}</Typography>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => toggleEditUserItem()} color='primary'>
          {i18n.cancel}
        </Button>
        <Button disabled={error} onClick={onSave} color='secondary'>
          {i18n.ok}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditUserItemDialog;
