// PUBLIC IMPORTS
import { Divider, List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// REDUX
import { useSelector } from 'react-redux';

import { Icon } from '@iconify/react';
import thermometerLines from '@iconify/icons-mdi/thermometer-lines';

import { getSensorType } from 'assets/utils';
import { ListItemWithSlider } from './SensorSettingsSlider';

import EditNameDialog from './EditNameDialog';
import EditCorrectionDialog from './EditCorrectionDialog';

import { useStyles } from './SensorProperties.styled';
import { measRangeLogic } from '../../../settings/helper';

const initialState = {
  editName: false,
  editCorrection: false,
  id: null,
  receiving_time: {
    title: 'Receiving time [ADMIN]',
    range: [3, 10], // 3 being the "default value", when there is no custom sensor setting
    value: 3,
    open: false
  },
  measurement_interval: {
    title: 'Measurement interval [ADMIN]',
    range: [0, 60], // 0 being the "default value", when there is no custom sensor setting
    value: 0,
    open: false
  }
};

const SensorProperties = () => {
  const classes = useStyles();
  const location = useLocation();

  const search = location.pathname.split('/');
  const sensorID = search[search.length - 1];

  const {
    i18n,
    sensorDetails,
    isAdmin,
    bsConf,
    sensorConfigurations,
    baseStation,
    sensorsData,
    enableDataCorrection
  } = useSelector((store) => ({
    i18n: store.i18n,
    sensorDetails: store.sensorDetails,
    isSupervisor: store.isSupervisor,
    isAdmin: store.isAdmin,
    bsConf: store.bsConf,
    baseStation: store.baseStation,
    sensorConfigurations: store.sensorConfigurations.find((s) => s.sensor_id_sys === sensorID),
    sensorsData: store.sensorsData,
    enableDataCorrection: store.userInfo?.enableDataCorrection
  }));

  const [state, setState] = useState(initialState);
  const lastMeas = sensorsData.find((meas) => meas?.sensor_id === sensorID);
  const correctionEnabled = enableDataCorrection && lastMeas && getSensorType(sensorID) === 'XN';

  useEffect(() => {
    const getSensorProperties = () => {
      if (sensorConfigurations) {
        setState((tempState) => ({
          ...tempState,
          receiving_time: {
            ...tempState.receiving_time,
            value: sensorConfigurations.receiving_time || 3 // matching the Default if nothing received by server
          },
          measurement_interval: {
            ...tempState.measurement_interval,
            value:
              sensorConfigurations.measurement_interval / 60 ||
              sensorConfigurations.measurement_interval_0 / 60 ||
              0 // matching the Default if nothing received by server
          }
        }));
      }
    };
    getSensorProperties();
  }, [sensorConfigurations]);

  const toggleName = () => {
    setState({ ...state, editName: !state.editName });
  };

  const toggleCorrection = () => {
    setState({ ...state, editCorrection: !state.editCorrection });
  };

  const { intervalValues, range } = measRangeLogic(bsConf, baseStation, true);

  return (
    <div className={classes.mainContainer}>
      <List>
        <ListItem>
          <ListItemText primary={i18n.sensor_id} secondary={sensorDetails.id} />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary={i18n.sensor_address} secondary={sensorDetails.address} />
        </ListItem>
        <Divider />
        {!sensorDetails.isAux ? (
          <ListItem button onClick={() => toggleName(true)}>
            <ListItemText primary={i18n.sensor_name} secondary={sensorDetails.name} />
          </ListItem>
        ) : (
          <ListItem>
            <ListItemText primary={i18n.sensor_name} secondary={sensorDetails.name} />
          </ListItem>
        )}
        {correctionEnabled || sensorConfigurations?.corrections ? (
          <>
            <Divider />
            <ListItem button onClick={() => toggleCorrection(true)}>
              <ListItemIcon>
                <Icon width={30} icon={thermometerLines} />
              </ListItemIcon>
              <ListItemText
                primary={i18n.sensor_correction || 'Value correction'}
                secondary={`${Number(
                  sensorConfigurations.corrections?.sort((d1, d2) =>
                    d2.date > d1.date ? 1 : -1
                  )[0].factor || 0
                ).toFixed(1)} °C`}
              />
            </ListItem>
          </>
        ) : null}
        {isAdmin && Number(baseStation?.firmware_ver) >= 2 && (
          <>
            <Divider />
            <div style={{ padding: '5px 20px' }}>
              <ListItemWithSlider
                i18n={i18n}
                id='measurement_interval'
                state={state}
                setState={setState}
                intervalValues={intervalValues}
                range={range}
                sensorID={sensorID}
                bastVersion={bsConf?.bast_ver}
              />
            </div>
            {Number(baseStation.firmware_ver) >= 3 && (
              <>
                <Divider />
                <ListItemWithSlider
                  i18n={i18n}
                  id='receiving_time'
                  state={state}
                  setState={setState}
                  intervalValues={intervalValues}
                  range={range}
                  sensorID={sensorID}
                />
              </>
            )}
          </>
        )}
      </List>

      {state.editName && <EditNameDialog editName={state.editName} toggleName={toggleName} />}
      {state.editCorrection && (
        <EditCorrectionDialog
          corrections={sensorConfigurations.corrections?.sort((d1, d2) =>
            d2.date > d1.date ? 1 : -1
          )}
          editCorrection={state.editCorrection}
          toggleCorrection={toggleCorrection}
        />
      )}
    </div>
  );
};

export default SensorProperties;
