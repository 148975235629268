import { Fab } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { Icon } from '@iconify/react';
import plus from '@iconify/icons-mdi/plus';
import minus from '@iconify/icons-mdi/minus';

import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'utils/colors';
import * as storageConstants from '../../storage-utils/storageConstants';
import BalesHeightControls from './BalesHeightControls';
// import { getSensorType } from 'assets/utils';

const useStyles = makeStyles(() => ({
  buttonIcon: {
    marginRight: 5
  },

  controlsPanel: {
    width: 'fit-content',
    height: 'inherit',
    boxSizing: 'border-box'
  },
  dimensionButton: {
    color: COLORS.white,
    width: 37,
    minWidth: 37,
    height: 30,
    borderRadius: 3
  },
  dimensionControl: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
    marginTop: 5,
    padding: 5,
    borderRadius: 3,
    backgroundColor: COLORS.whiteSmoke,
    boxSizing: 'border-box'
  },
  dimensionLabels: {
    textTransform: 'capitalize',
    lineHeight: '35px',
    whiteSpace: 'nowrap',
    padding: '0 15px',
    fontSize: 14
  }
}));

const StackSizeControls = ({
  theStack,
  showPileHeight,
  pileHeight,
  width,
  onLayoutChange,
  onHeightChange
}) => {
  const i18n = useSelector((state) => state.i18n);
  const classes = useStyles();

  const controls = ['columns', 'rows'];
  const counts = [theStack.nColumns, theStack.nRows];

  const applyLayoutChange = (size) => {
    if (onLayoutChange) {
      onLayoutChange(size);
    } else {
      console.log('onLayoutChange() missing for size = ', size);
    }
    theStack.nRows = size.nRows;
    theStack.nColumns = size.nColumns;
  };

  const changeColumns = (change) => {
    if (change < 0 && theStack.nColumns === 1) {
      return; // Cannot remove all columns
    }
    applyLayoutChange({ nRows: theStack.nRows, nColumns: theStack.nColumns + change });
  };

  const changeRows = (change) => {
    if (change < 0 && theStack.nRows === 1) {
      return; // Cannot remove all rows
    }
    applyLayoutChange({ nRows: theStack.nRows + change, nColumns: theStack.nColumns });
    // setLastRowFade(false);
  };

  const handleAddRemove = (type, isAdd) => {
    // type: 'rows', 'columns'
    if (type === 'rows') {
      changeRows(isAdd ? 1 : -1);
    } else if (type === 'columns') {
      changeColumns(isAdd ? 1 : -1);
    }
  };

  return (
    <div className={classes.controlsPanel}>
      {controls.map((cnt, i) => (
        <div
          style={{ width, maxWidth: width, minWidth: width }}
          key={i}
          className={classes.dimensionControl}
        >
          <Fab
            disabled={
              cnt === 'rows'
                ? theStack.nRows === 1
                : cnt === 'columns'
                ? theStack.nColumns === 1
                : false
            }
            className={classes.dimensionButton}
            // disabled={}
            color='primary'
            onClick={() => handleAddRemove(cnt)}
            // onMouseEnter={() => dimRemovableItem(true, cnt)}
            // onMouseLeave={() => dimRemovableItem(false, cnt)}
          >
            <Icon width={20} height={20} icon={minus} />
          </Fab>
          <div className={classes.dimensionLabels}>{`${i18n[cnt] || cnt} (${counts[i]})`}</div>
          <Fab
            className={classes.dimensionButton}
            disabled={
              cnt === 'rows'
                ? theStack.nRows >= storageConstants.MAX_ROWS
                : cnt === 'columns'
                ? theStack.nColumns >= storageConstants.MAX_COLUMNS
                : false
            }
            color='secondary'
            onClick={() => handleAddRemove(cnt, true)}
          >
            <Icon width={20} height={20} icon={plus} />
          </Fab>
        </div>
      ))}
      {showPileHeight ? (
        <BalesHeightControls
          // disabled={Boolean(!isEmpty(pilesToRemove))}
          width={width}
          pileHeight={pileHeight}
          onPileHeightChange={onHeightChange}
        />
      ) : null}
    </div>
  );
};

export default StackSizeControls;
