export const TAB_LAYOUT = '1';
export const TAB_BATCHES = '2';
export const TAB_MONITORS = '3';
export const TAB_PROPERTIES = '4';

export const ACTIVE = 'active';
export const COMPLETED = 'completed';
export const ARCHIVED = 'archived';

export const STATUS_INITIAL = 0;
export const STATUS_ACTIVE = 1;
export const STATUS_COMPLETED = 2;
export const STATUS_ARCHIVED = 3;

export const DEFAULT_ROWS = 3;
export const DEFAULT_COLUMNS = 6;
export const DEFAULT_FLOORS = 4;
export const MAX_FLOORS = 8;
export const MAX_ROWS = 10;
export const MAX_COLUMNS = 20;

export const MODE_TEMP = 1;
export const MODE_HAY = 2;
export const MODE_STACK = 3;
export const MODE_QUALITY = 4;
export const MODE_SIGNAL_SENSOR = 5;
export const MODE_SIGNAL_BS = 6;
export const MODE_BATT = 7;
export const MODE_MEAS_AGE = 8;

export const DEFAULT_PERIOD = 30;
