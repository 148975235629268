import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import SensorTag from './SensorTag';

const useStyles = makeStyles(() => ({
  sensorsListWrapper: {
    display: 'flex',
    flexFlow: 'wrap'
  }
}));

const PlacementSensorList = ({
  sensorIDs,
  showMultipoint,
  dataSnapshot,
  bsConf,
  auxConf,
  selectedSensor,
  showHandleBar,
  marginRight,
  hoveredSensor,
  onSensorHover,
  onSensorClick,
  isPassive
}) => {
  // const dispatch = useDispatch();
  const i18n = useSelector((state) => state.i18n);
  const classes = useStyles();

  const sensorClicked = (id) => {
    if (onSensorClick) {
      onSensorClick(id);
    }
  };

  const sensorComponents = sensorIDs
    .sort((a, b) => (a < b ? -1 : 1))
    .map((sid) => (
      <SensorTag
        key={sid}
        isPassive={isPassive}
        sensorID={sid}
        showMultipoint={showMultipoint}
        lastData={dataSnapshot.find((s) => s?.sensor_id === sid)}
        sensorConf={
          bsConf.sensor_configurations.find((s) => s.sensor_id_sys === sid) ||
          auxConf?.find((aux) => aux.a === sid)
        }
        isAux={auxConf?.find((aux) => aux.a === sid)}
        sensorClicked={sensorClicked}
        isSelectedSensor={selectedSensor === sid}
        marginRight={marginRight}
        i18n={i18n}
        showHandleBar={showHandleBar}
        hoveredSensor={hoveredSensor}
        onSensorHover={onSensorHover}
        sensor={{}}
      />
    ));

  return (
    <div className={classes.sensorsListWrapper}>
      {/* {sensors.length > 0 ? listHeader : null} */}
      {sensorComponents}
    </div>
  );
};

export default PlacementSensorList;
