import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// REDUX
import { useSelector } from 'react-redux';

// CUSTOM COMPONENTS IMPORT
import Settings from 'components/settings/Settings';
import Account from 'components/account/Account';
import Probes from 'components/probes/Probes';
import Translations from 'components/admin/translations/Translations';
import StoragesNoSupervisor from 'components/storages/StoragesNoSupervisor';
import Storages from 'components/storages/Storages';

import { useStyles } from './MainNavigator.styled';
import PublicRoute, { PATH_LIST } from '../PublicRoute';
import GuardedRoute from './GuardedRoute';

const BatchesNoSupervisor = React.lazy(() => import('components/batches/BatchesNoSupervisor'));
// const StoragesNoSupervisor = React.lazy(() => import('components/storages/StoragesNoSupervisor'));
const NoMatch = React.lazy(() => import('components/general/NoMatch'));

// const Storages = React.lazy(() => import('components/storages/Storages'));
const Batches = React.lazy(() => import('components/batches/Batches'));
const HayQuality = React.lazy(() => import('components/hay-quality/QualityContainer'));
const Admin = React.lazy(() => import('components/admin/Admin'));
const BsLogs = React.lazy(() => import('components/admin/EarlyBsCallsLog'));

const MainRoutes = ({ isMultiBS, path }) => {
  const classes = useStyles();

  const {
    isLoggedIn,
    isAdmin,
    insightService,
    isPremium,
    isSupervisorCompost,
    isSupervisor,
    isGariUser,
    userInfo,
    sessionInfo,
    rootAdmin
  } = useSelector((store) => ({
    isLoggedIn: store.isLoggedIn,
    isAdmin: store.isAdmin,
    insightService: store.insightService,
    isPremium: store.isPremium,
    isSupervisorCompost: store.isSupervisorCompost,
    isSupervisor: store.isSupervisor,
    isGariUser: store.isGariUser,
    userInfo: store.userInfo,
    sessionInfo: store.sessionInfo,
    rootAdmin: store.sessionInfo?.admin_group === 0
  }));
  const expiredPlan = userInfo?.plans && userInfo?.plans[0]?.expired;
  const mainPageClass =
    isAdmin && isMultiBS
      ? classes.mainPageWithDoubleTopBar
      : isAdmin
      ? classes.mainPageWithTopBar
      : isMultiBS
      ? classes.mainPageWithTabs
      : classes.mainPage;

  const storageAuth =
    insightService || ((isSupervisor || (isPremium && !expiredPlan)) && !isSupervisorCompost);

  return (
    <main className={mainPageClass}>
      <Switch>
        <Redirect
          exact
          from={path}
          to={
            isSupervisorCompost
              ? `${path}batches`
              : storageAuth
              ? `${path}storages`
              : `${path}probes`
          }
        />
        <GuardedRoute path={`${path}admin`} component={Admin} auth={isAdmin} />
        <GuardedRoute path={`${path}bslog`} component={BsLogs} auth={isAdmin} />
        <GuardedRoute path={`${path}quality`} component={HayQuality} auth={isAdmin} />
        <GuardedRoute
          path={`${path}translations`}
          component={Translations}
          auth={rootAdmin || !!userInfo?.translator || !!sessionInfo?.translator}
        />
        <PublicRoute path={`/${PATH_LIST}`} props={{ isLoggedIn }} />
        <Route path={`${path}settings`} component={Settings} />
        <Route path={`${path}probes`} component={Probes} />
        <GuardedRoute path={`${path}account`} component={Account} auth={!isGariUser} />
        <GuardedRoute
          path={`${path}batches`}
          component={
            (isSupervisorCompost && !expiredPlan) || isAdmin ? Batches : BatchesNoSupervisor
          }
          auth={isSupervisorCompost || isAdmin}
        />
        <GuardedRoute
          path={`${path}storages`}
          component={
            ((isPremium || insightService) && !expiredPlan) || isAdmin
              ? Storages
              : StoragesNoSupervisor
          }
          auth={storageAuth || isAdmin}
        />
        <Route component={NoMatch} />
      </Switch>
    </main>
  );
};

export default MainRoutes;
