import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import DraggableSensor from './DraggableSensor';

const useStyles = makeStyles(() => ({
  holder: {
    padding: 5,
    display: 'inline-block'
  }
}));

export default function UnplacedSensor({ id, type, measurement, onDrop }) {
  const classes = useStyles();

  return (
    <div className={classes.holder}>
      <DraggableSensor id={id} measurement={measurement} type={type} onDrop={onDrop} inList />
    </div>
  );
}
