import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField
} from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles } from '@material-ui/core/styles';

import { postActivateKit, getUserInfo } from 'api/serverAPI';

const useStyles = makeStyles(() => ({
  helperText: {
    margin: '5px 0'
  }
}));

const AddBSDialog = ({ isAddBSDialog, setIsAddBSDialog }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { userInfo, i18n } = useSelector((store) => ({
    userInfo: store.userInfo,
    i18n: store.i18n
  }));

  const schema = yup.object().shape({
    code: yup.string().required(i18n.act_code_req || 'Activation code is required')
  });

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    setError
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema)
  });

  const onSubmit = async (values) => {
    try {
      const { code } = values;
      const res = await postActivateKit({ id: code, uid: userInfo.U_ID });

      switch (Number(res.status_code)) {
        case 0: {
          const resUserInfo = await getUserInfo({ UID: userInfo.U_ID });
          dispatch({ type: 'SET_USERINFO', value: resUserInfo.userinfo });
          setIsAddBSDialog(false);
          break;
        }
        case 2: {
          setError('code', {
            type: 'notMatch',
            message: i18n.code_err || 'The provided code is not correct. Please verify the code.'
          });
          break;
        }
        case 22: {
          setError('code', {
            type: 'notMatch',
            message:
              i18n.gari_service_msg ||
              'System with this activation code must be activated under Gari service.'
          });
          break;
        }
        case 3: {
          setError('code', {
            type: 'notMatch',
            message:
              i18n.sys_already_active || 'The system is already activated. Please verify the code'
          });
          break;
        }
        default:
          setError('code', {
            type: 'notMatch',
            message: i18n.server_err || 'Server connection error. Please try again after a moment.'
          });
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Dialog open={isAddBSDialog} aria-labelledby='responsive-dialog-title'>
      <DialogTitle>Add Base Station</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <DialogContent elevation={0}>
          <div>
            {i18n.system_activation ||
              'Please fill in the code from the inner lid of the system package or from the included Activation Sheet'}
          </div>
          <TextField
            variant='outlined'
            margin='dense'
            name='code'
            {...register('code')}
            required
            fullWidth
            error={Boolean(errors.code)}
            helperText={errors.code?.message}
            FormHelperTextProps={{ classes: { root: classes.helperText } }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsAddBSDialog(!isAddBSDialog)} color='primary'>
            {i18n.cancel || 'Cancel'}
          </Button>
          <Button type='submit' variant='contained' color='primary' disabled={!isValid}>
            {i18n.activate_system || 'Activate system'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddBSDialog;
