// PUBLIC IMPORTS
import React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import { getGradientColor } from 'assets/utils';
import { COLORS } from 'utils/colors';

const styles = () => ({
  hide: {
    display: 'none'
  }
});

function temperatureToY(hei, T0, T1, temp) {
  return ((1 - (temp - T0) / (T1 - T0)) * hei).toFixed(2);
}

const lineMargin = 1;

function TempTrapezoidSVG({
  id,
  noData,
  style,
  wid,
  dataTo,
  hei,
  T0,
  T1,
  maxTemp0,
  maxTemp1,
  minTemp0,
  minTemp1,
  bgColor
}) {
  // console.log('Trap', {
  //   id,
  //   noData,
  //   style,
  //   wid,
  //   dataTo,
  //   hei,
  //   T0,
  //   T1,
  //   maxTemp0,
  //   maxTemp1,
  //   minTemp0,
  //   minTemp1,
  //   bgColor
  // });
  // Ymin and Ymax are y-coordinates (from top)
  const Ymax0 = temperatureToY(hei, T0, T1, maxTemp0); // 31;
  const Ymax1 = temperatureToY(hei, T0, T1, maxTemp1); // 45;
  const Ymin0 = temperatureToY(hei, T0, T1, minTemp0); // 50;
  const Ymin1 = temperatureToY(hei, T0, T1, minTemp1); // 61;
  return (
    <div style={style}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox={`0 0 ${wid} ${hei}`}>
        {noData ? null : (
          <defs>
            <linearGradient id={`${id}_maxGradient`} x1='0%' y1='0%' x2='0%' y2='100%'>
              <stop
                offset='0%'
                stopColor={getGradientColor(maxTemp0 > maxTemp1 ? maxTemp0 : maxTemp1)}
              />
              <stop
                offset='100%'
                stopColor={getGradientColor(maxTemp0 > maxTemp1 ? maxTemp1 : maxTemp0)}
              />
            </linearGradient>
            <linearGradient id={`${id}_minGradient`} x1='0%' y1='0%' x2='0%' y2='100%'>
              <stop offset='0%' stopColor={getGradientColor(minTemp0)} />
              <stop offset='100%' stopColor={getGradientColor(minTemp1)} />
            </linearGradient>
          </defs>
        )}
        <rect className='bg' style={{ fill: bgColor }} width={dataTo || wid} height={hei} />
        {noData ? null : (
          <>
            <polygon
              className='area'
              style={{ fill: COLORS.zirconBlue }}
              points={`${
                dataTo + lineMargin
              },${Ymin1} ${-lineMargin},${Ymin0} ${-lineMargin},${Ymax0} ${
                (dataTo || wid) + lineMargin
              },${Ymax1} `}
            />
            <line
              className='minLine'
              style={{
                opacity: 0.5,
                fill: 'none',
                stroke:
                  Math.abs(Ymin1 - Ymin0) > 1
                    ? `url(#${id}_minGradient)`
                    : getGradientColor(minTemp1),
                strokeWidth: 2,
                strokeLinecap: 'round'
              }}
              x1={-lineMargin}
              y1={Ymin0}
              x2={(dataTo || wid) + lineMargin}
              y2={Ymin1}
            />
            <line
              className='maxLine'
              // no gradient for horizontal line
              style={{
                fill: 'none',
                stroke:
                  Math.abs(Ymax1 - Ymax0) > 1
                    ? `url(#${id}_maxGradient)`
                    : getGradientColor(maxTemp1),
                strokeWidth: 3,
                strokeLinecap: 'round'
              }}
              x1={-lineMargin}
              y1={Ymax0}
              x2={(dataTo || wid) + lineMargin}
              y2={Ymax1}
            />
          </>
        )}
      </svg>
    </div>
  );
}

export default withRouter(withWidth()(withStyles(styles)(TempTrapezoidSVG)));
