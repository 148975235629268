import { get } from 'lodash';
import { startOfDay, endOfDay } from 'date-fns';
import { getSnapshot, getSensorData2, getAuxMinmax } from 'api/serverAPI';
import { DAY_IN_MILLISECONDS } from 'redux/constants';

export const getSensorDataFromAPI = async (
  bsID,
  sensorId,
  fromDate,
  toDate,
  isSnapShot,
  auxProbe,
  auxIDuser,
  isDemoMode,
  bsGroup
) => {
  let sensorData = {};
  try {
    if (isSnapShot) {
      if (auxProbe) {
        sensorData = await getAuxMinmax({
          auxID: auxIDuser,
          auxSensorID: auxProbe.aa,
          sensorID: sensorId,
          fromDate: +startOfDay(+fromDate),
          toDate: +endOfDay(+toDate)
        });
      } else {
        const query = {
          basestationID: bsID,
          sensorIDs: [sensorId],
          fromDate: +startOfDay(+fromDate),
          toDate: +endOfDay(+toDate),
          interval: 1,
          isDemoMode
        };
        sensorData = await getSnapshot(query);
      }
    } else {
      console.log('getSensorData2', auxProbe ? '- Agrolog probe' : '- Quanturi probe');

      const query = {
        BS_ID: bsID,
        sensorID: sensorId,
        startTS: (fromDate - 86400000) / 1000,
        endTS: Math.round(+endOfDay(+toDate) / 1000),
        allFields: 1,
        auxID: auxProbe && auxIDuser,
        auxSensorID: auxProbe?.aa,
        isDemoMode
      };
      if (bsGroup) {
        query.multiBS = bsGroup;
      }
      sensorData = await getSensorData2(query);
    }
  } catch (err) {
    console.log('err: ', err);
    return;
  }
  return sensorData;
};

export const generateOverviewChartData = (sensorData, min, max, auxProbe) => {
  if (sensorData) {
    const sensorChart = [];

    sensorChart.push({
      x: +new Date(min),
      y: null
    });

    if (auxProbe) {
      sensorData.forEach((data) => {
        sensorChart.push({
          x: +new Date(+data.b * 1000),
          y: Number(data.h)
        });
      });
    } else {
      sensorData.results.forEach((data) => {
        sensorChart.push({
          x: +new Date(+data.snaptime * 1000),
          y: Number(data.probes[0].h)
        });
      });
    }

    sensorChart.push({
      x: +new Date(max),
      y: null
    });

    return sensorChart;
  }
};

const generateMinData = (data, constNum) =>
  Math.min(
    ...data.map((item) => Math.floor(item.y / 10) * 10).filter((value) => !Number.isNaN(value)),
    constNum
  );

const generateMaxData = (data, constNum) =>
  Math.max(
    ...data
      .map((item) => Math.floor(item.y / 10) * 10 + 10)
      .filter((value) => !Number.isNaN(value)),
    constNum
  );

const generateCustomChartData = (sensorData, date, xKey, yKey) => {
  if (sensorData) {
    const chart = [];
    const getYValue = (objData) =>
      objData.probes ? get(objData, `probes[0].${yKey}`) : get(objData, `${yKey}`);
    chart.push({
      x: +startOfDay(date[0]),
      y: null
    });

    const dataList =
      sensorData.results ||
      sensorData.filter((a, i) => (i > 0 ? a.b - sensorData[i - 1].b > 60 : true)); // filter duplicates
    dataList.forEach((data) => {
      // Filter out notemp temp data and novolt battery data (G4 data)
      if (!((yKey === 'h' && data.notemp) || (yKey === 'e' && data.novolt))) {
        chart.push({
          x: +new Date(+data[xKey] * 1000),
          y: Number(getYValue(data))
        });
      }
    });

    chart.push({
      x: +endOfDay(date[1]),
      y: null
    });
    return chart;
  }
};

export const generateFullChartData = (sensorData, date, isSnapShot, auxProbe) => {
  if (sensorData) {
    // TEMPERATURE
    const sensorChartTemperature = (
      isSnapShot && !auxProbe
        ? generateCustomChartData(sensorData, date, 'snaptime', 'h')
        : generateCustomChartData(sensorData, date, 'b', 'h')
    ).filter((dat) => dat.x >= date[0]);
    const temperatureMin = generateMinData(sensorChartTemperature, 20);
    const temperatureMax = generateMaxData(sensorChartTemperature, 60);

    // SIGNAL (RSSI SENSOR)
    const sensorChartSignal0 = isSnapShot
      ? generateCustomChartData(sensorData, date, 'snaptime', 'f')
      : generateCustomChartData(sensorData, date, 'b', 'f');

    const signalMin = generateMinData(sensorChartSignal0, -120);
    const signalMax = generateMaxData(sensorChartSignal0, -20);

    // SIGNAL (RSSI BASESTATION)
    const sensorChartSignal1 = isSnapShot
      ? generateCustomChartData(sensorData, date, 'snaptime', 'g')
      : generateCustomChartData(sensorData, date, 'b', 'g');

    // VOLTAGE
    const sensorChartVoltage = isSnapShot
      ? generateCustomChartData(sensorData, date, 'snaptime', 'e')
      : generateCustomChartData(sensorData, date, 'b', 'e');

    // HUMIDITY
    const sensorChartHumidity = isSnapShot
      ? generateCustomChartData(sensorData, date, 'snaptime', 'k')
      : generateCustomChartData(sensorData, date, 'b', 'k');

    return {
      data_temperature: sensorChartTemperature,
      data_signal0: sensorChartSignal0,
      data_signal1: sensorChartSignal1,
      data_voltage: sensorChartVoltage,
      data_humidity: sensorChartHumidity,
      temperatureMin: temperatureMin !== Infinity ? temperatureMin : 20,
      temperatureMax: temperatureMax !== -Infinity ? temperatureMax : 60,
      signalMin: signalMin !== Infinity ? signalMin : -120,
      signalMax: signalMax !== -Infinity ? signalMax : -20,
      voltageMin: 250,
      voltageMax: 400
    };
  }
};

export const getDays = (date) => {
  const date0 = +startOfDay(date[0]);

  const date1 = +startOfDay(date[1]);

  const days = Math.round((date1 - date0) / DAY_IN_MILLISECONDS) + 1;
  return { days, date0, date1 };
};

export const handleChangeSlider = (unencoded, min) => {
  let span = 0;
  const { date0, date1, days } = getDays(unencoded);

  if (days === 30) {
    span = 1;
  } else if (days === 7) {
    span = 2;
  } else if (days === 2) {
    span = 3;
  } else {
    span = 0;
  }

  // MINIMUM SIZE OF SLIDER = 1 DAY
  if (unencoded[1] - unencoded[0] < DAY_IN_MILLISECONDS) {
    if (unencoded[1] - DAY_IN_MILLISECONDS < min) {
      unencoded[0] = min;
      unencoded[1] = min + DAY_IN_MILLISECONDS;
    } else {
      unencoded[0] = unencoded[1] - DAY_IN_MILLISECONDS;
    }
    span = 3;
  }
  return {
    date: [date0, date1],
    span
  };
};

// Handle change of predefined span
// value [Number] [0,1,2] 0: Month - 1: Week - 2: Day
export const handleChangeSpan = (value, min, date) => {
  const unencoded = date;
  let span = 0;

  if (value === 1) {
    span = 29 * DAY_IN_MILLISECONDS; // MONTH
  } else if (value === 2) {
    span = 6 * DAY_IN_MILLISECONDS; // WEEK
  } else if (value === 3) {
    span = 1; // DAY
  }

  if (unencoded[1] - span < min) {
    unencoded[0] = min;
    unencoded[1] = min + span;
  } else {
    unencoded[0] = unencoded[1] - span;
  }

  unencoded[0] = +startOfDay(unencoded[0]);

  unencoded[1] = +startOfDay(unencoded[1]);

  return { date: unencoded, span: value };
};
