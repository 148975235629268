import { SET_SESSIONINFO } from 'redux/constants';
import initialState from 'redux/store/initialState';

export default function setSessionInfo(state = initialState.sessionInfo, action) {
  switch (action.type) {
    case SET_SESSIONINFO: {
      return action.value;
    }
    default: {
      return state;
    }
  }
}
