import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useConfirm } from 'hook/useConfirm';
import adminChangesConfirm from 'utils/adminChangesConfirm';
import { SETTINGS } from './SettingProps';
import { useStyles } from './Settings.styled';

const SettingsRadioDialog = ({
  i18n,
  id,
  selectedValue,
  editSettingsRadio,
  toggleSettings,
  saveSettingsRadio
}) => {
  const classes = useStyles();
  const confirm = useConfirm();
  const isAdmin = useSelector((state) => state.isAdmin);
  const sessionInfo = useSelector((state) => state.sessionInfo);
  const [val, setVal] = useState(selectedValue);
  const settings = SETTINGS();
  useEffect(() => {
    setVal(selectedValue);
  }, [id, selectedValue]);

  const onSave = async () => {
    await adminChangesConfirm(isAdmin, confirm, settings[id].title, sessionInfo?.username);
    saveSettingsRadio(val);
  };

  return (
    <Dialog open={editSettingsRadio} aria-labelledby='responsive-dialog-title'>
      <DialogTitle id={id}>
        <div className={classes.capitalize}>{i18n[id] || settings[id].title}</div>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContentRadio} elevation={0}>
        <RadioGroup
          aria-label={settings[id].title}
          name={id}
          value={`${val}` || ''}
          onChange={(e) => setVal(e.target.value)}
        >
          {Object.keys(settings[id].data).map((key) => (
            <FormControlLabel
              key={key}
              value={key}
              control={<Radio />}
              label={i18n[settings[id].data[key]] || settings[id].data[key]}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={() => toggleSettings()} color='primary'>
          {i18n.cancel}
        </Button>
        <Button onClick={onSave} color='secondary'>
          {i18n.ok}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SettingsRadioDialog;
