import { Grow, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DialogActionUtil } from 'utils/DialogActions';
import { getPlanName } from 'assets/utils';
import { i18nVariablesInjection } from 'utils/sharedHelper';

const ExpiredDialog = () => {
  const history = useHistory();
  const i18n = useSelector((state) => state.i18n);
  const userInfo = useSelector((state) => state.userInfo);
  const insightService = useSelector((store) => store.insightService);
  const isAdmin = useSelector((store) => store.isAdmin);
  const isPlanExpired = useSelector((store) => store.isPlanExpired);
  const isSimExpired = useSelector((store) => store.isSimExpired);

  const expiredInsight = isPlanExpired && insightService;

  const [expiredDialog, setExpiredDialog] = useState(!isAdmin && (isSimExpired || expiredInsight));
  const close = () => {
    setExpiredDialog(false);
  };

  const toCheckout = () => {
    setExpiredDialog(false);
    if (expiredInsight) {
      history.replace('/account/services/renewal');
    } else {
      history.replace('/account/services/checkout/?type=sim');
    }
  };

  return (
    <Dialog
      open={expiredDialog}
      maxWidth='lg'
      TransitionComponent={Grow}
      aria-labelledby='responsive-dialog-title'
    >
      <DialogTitle>{i18n.data_unavailable}</DialogTitle>
      <DialogContent elevation={0}>
        <div>
          {expiredInsight && userInfo
            ? i18nVariablesInjection(i18n.notification_reminder_expired, {
                planName: getPlanName(null, userInfo.service)
              })
            : i18n.sim_expired_msg}
        </div>
      </DialogContent>
      <DialogActionUtil
        onCancel={close}
        cancelText={i18n.close}
        onSave={toCheckout}
        text={expiredInsight ? i18n.renew_plan : i18n.renew_sim}
      />
    </Dialog>
  );
};

export default ExpiredDialog;
