import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getTranslationsData } from 'redux/reducers/setLanguage';
import { Spinner } from 'utils/Spinners';
import InsightWelcome from './InsightWelcome';
import InsightSurvey from './InsightSurvey';
import AccountDeactivated from './AccountDeactivated';

const Insight = () => {
  const history = useHistory();
  const i18n = useSelector((store) => store.i18n);
  const dispatch = useDispatch();

  if (!history.location.state) {
    window.location.href = '/';
  }

  const { service, plan } = history.location.state;

  const [oldFreeUser] = useState(!service && (!plan || (plan && plan.planLevel <= 3)));
  const [oldHayUser, setOldHayUser] = useState(!service && plan.planLevel < 4);
  const [insightUser, setInsightUser] = useState(!!service);
  const [newUser, setNewUser] = useState(
    insightUser && !service?.trial && !service?.surveyCompleted
  );

  useEffect(() => {
    console.log('getTranslationsData at Insight');
    if (!i18n.insight_starting) {
      dispatch(getTranslationsData(i18n.locale, null, 'insightSurvey'));
    }
  }, [dispatch, i18n.locale, i18n.insight_starting]);

  // Making sure we wait for insight translations before we show anything
  if (!i18n.insight_starting) {
    return <Spinner />;
  }
  if (oldFreeUser) {
    return <AccountDeactivated i18n={i18n} />;
  }
  if ((oldHayUser && !insightUser) || newUser) {
    return (
      <InsightWelcome
        i18n={i18n}
        plan={plan}
        oldHayUser={oldHayUser}
        setOldHayUser={setOldHayUser}
        setInsightUser={setInsightUser}
        history={history}
        newUser={newUser}
        setNewUser={setNewUser}
      />
    );
  }

  if (insightUser && !service?.surveyCompleted) {
    return <InsightSurvey i18n={i18n} />;
  }
  return null;
};

export default Insight;
