import { Dialog, DialogTitle, DialogActions, Button } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';

export const ConfirmChanges = ({
  isConfirmNeeded,
  setIsConfirmNeeded,
  isEdited,
  setIsEdited,
  handleClose,
  onSave
}) => {
  const i18n = useSelector((store) => store.i18n);
  const handleConfirm = (choice) => {
    if (choice === 'cancel') {
      setIsConfirmNeeded(false);
    } else if (choice === 'discard') {
      setIsEdited(false);
      handleClose(true);
    } else if (choice === 'save') {
      onSave();
      handleClose(isEdited);
    } else if (choice === 'close') {
      setIsConfirmNeeded(false);
      handleClose();
    }
  };

  const message =
    isConfirmNeeded === 1 || isConfirmNeeded === 2 || isConfirmNeeded === 3
      ? i18n.alert_messages_disabled
      : i18n.save_before_closing || 'Save changes before closing?';

  const subtext =
    isConfirmNeeded === 1
      ? 'No probes'
      : isConfirmNeeded === 2
      ? 'No Monitor'
      : isConfirmNeeded === 3
      ? 'no SMS'
      : null;

  const doNotShow = isConfirmNeeded === true ? null : 'Dont show this message for this storage';
  return !isConfirmNeeded ? null : (
    <Dialog open={!!isConfirmNeeded} aria-labelledby='responsive-dialog-title'>
      <DialogTitle style={{ maxWidth: 500 }}>{message}</DialogTitle>
      {subtext}
      <DialogActions>
        <Button onClick={() => handleConfirm('cancel')}>{i18n.back || 'Back'}</Button>
        {isConfirmNeeded === true ? (
          <Button onClick={() => handleConfirm('discard')}>
            {i18n.discard_changes || 'Discard changes'}
          </Button>
        ) : null}
        {isConfirmNeeded === true ? (
          <Button onClick={() => handleConfirm('save')} color='secondary'>
            {i18n.save || 'Save'}
          </Button>
        ) : (
          <Button onClick={() => handleClose()} color='secondary'>
            {i18n.close || 'Close'}
          </Button>
        )}
      </DialogActions>
      {doNotShow}
    </Dialog>
  );
};
