import {
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  List,
  ListItem,
  TableContainer,
  TableSortLabel,
  InputAdornment,
  TextField,
  Typography
} from '@material-ui/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import { DebounceInput } from 'react-debounce-input';
import { Icon } from '@iconify/react';
import magnifyIcon from '@iconify/icons-mdi/magnify';
import closeIcon from '@iconify/icons-mdi/close';
import ListFooter from 'utils/ListFooter';
import { Spinner } from 'utils/Spinners';
import { COLORS } from 'utils/colors';
import { stableSort, getComparator } from 'utils/sorting';

export const adminTableStyles = makeStyles(() => ({
  root: {
    // width: '100%',
    // margin: 10,
    minWidth: 275,
    margin: '10px auto'
    // maxWidth: '1100px'
  },
  embedded: {
    border: 'none',
    boxShadow: 'none'
  },
  paper: {
    padding: 5
  },
  table: {
    // minWidth: 550
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '10px 35px' // 0
  },
  trExtanded: {
    backgroundColor: COLORS.extandedRow
  },
  selected: {
    fontWeight: 'bold',
    backgroundColor: COLORS.selectedRow,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  visited: {
    // backgroundColor: COLORS.dimGray,
    opacity: 0.5,
    '&:hover': {
      backgroundColor: COLORS.whisperGray,
      cursor: 'pointer'
    }
  },
  row: {
    '&:hover': {
      backgroundColor: COLORS.whisperGray,
      cursor: 'pointer'
    }
  },
  // Rules for gLogs
  okRow: {
    '& > td': {
      color: COLORS.greenJade
    }
  },
  cell: {
    border: '1px solid #eeeeeedb',
    padding: 2,
    maxWidth: 400,
    overflow: 'auto',
    textAlign: 'left',
    paddingLeft: 10,
    whiteSpace: 'nowrap',
    fontSize: 13,
    // Rules for types of sensors
    '&[data-for="T"]': {
      backgroundColor: COLORS.tango
    },
    '&[data-for="XN"]': {
      backgroundColor: COLORS.tangoXN,
      color: COLORS.black
    },
    '&[data-for="TXN"]': {
      backgroundColor: COLORS.tangoTXN,
      backgroundImage:
        'linear-gradient(to bottom right,#e99eff, #e99eff,#e99eff, #f7fa19, #f7fa19, #f7fa19)'
    },
    // Rules for bsLog
    '&[data-for*="reg"]': {
      backgroundColor: 'rgba(250,10,40,0.1)'
    },
    '&[data-for*="act"]': {
      backgroundColor: 'rgba(0,150,250,0.1)'
    },
    // Rules for Logs
    '&[data-for="info"]': {
      color: COLORS.greenJade
      // backgroundColor: COLORS.greenJade,
      // margin: 10,
      // // border: '1px solid #ccc',
      // borderRadius: 100,
      // padding: 5
    },
    '&[data-for="warn"]': {
      color: COLORS.reminder
    },
    '&[data-for="error"]': {
      color: COLORS.alert
    }
  },
  head: {
    background: '-webkit-gradient(linear, left top, left bottom, from(#f6f6f6), to(#eee))',
    whiteSpace: 'nowrap'
  },
  Message: {
    textAlign: 'center',
    background: COLORS.whisperGray,
    fontSize: 15,
    fontWeight: 'bold',
    padding: '20px 0'
  },
  tableWrapper: {
    maxHeight: 135,
    overflowY: 'auto',
    whiteSpace: 'nowrap'
  },
  exp: {
    color: 'red'
  },
  warranty: {
    color: 'rgb(8, 160, 33)'
  }
}));

export const TopBar = ({ title, classes, handleSearch, searchString, clearSearch }) => (
  <div
    className={classes.topBar}
    style={{ maxWidth: title?.substring(0, 5) === 'Users' ? 800 : 'initial' }}
  >
    <Typography variant='h5'>{title}</Typography>
    <DebounceInput
      className={classes.settingSensorFind}
      element={TextField}
      minLength={1}
      debounceTimeout={150}
      onChange={handleSearch}
      value={searchString}
      placeholder='Filter'
      InputProps={{
        startAdornment: (
          <InputAdornment position='start' onClick={() => clearSearch()}>
            <Icon
              width='20'
              height='20'
              icon={searchString?.length === 0 ? magnifyIcon : closeIcon}
              style={searchString?.length === 0 ? { color: COLORS.warning } : { cursor: 'pointer' }}
            />
          </InputAdornment>
        )
      }}
    />
  </div>
);

export const EnhancedTableHead = ({ classes, order, orderBy, onRequestSort, headCells }) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.head}>
      <TableRow>
        {headCells
          // .filter(hC => !hC.hidden)
          .map((headCell) => (
            <TableCell
              key={headCell.id}
              align='center'
              className={classes.cell}
              padding='normal'
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
};

export const tableSearch = ({ searchString, setSearchString, data }) => {
  const clearSearch = () => {
    setSearchString('');
  };

  const handleSearch = (event) => {
    const keyword = event.target.value;
    setSearchString(keyword);
  };

  const filteredRows = data?.filter((u) => {
    const keys = Object.keys(u);
    const values = Object.values(u);
    const filtered = [];
    let matchesSearch = false;
    for (let i = 0; i < keys.length; i += 1) {
      if (String(values[i]).toUpperCase().includes(searchString.toUpperCase())) {
        filtered.push(true);
      }
    }
    if (filtered.length > 0) {
      matchesSearch = true;
    }
    return matchesSearch === true ? u : null;
  });
  return { clearSearch, handleSearch, filteredRows };
};

export const TableState = () => {
  const i18n = useSelector((state) => state.i18n);
  const classes = adminTableStyles();

  const [data, setData] = useState([]);
  const [message, setMessage] = useState(null);
  const [subData, setSubData] = useState([]);
  const [subHeaderNeeded, setSubHeaderNeeded] = useState(false);

  return {
    i18n,
    classes,
    data,
    setData,
    message,
    setMessage,
    subData,
    setSubData,
    subHeaderNeeded,
    setSubHeaderNeeded
  };
};

export const TableOrderState = ({ defaultOrderBy, defaultOrder, setPage }) => {
  const [order, setOrder] = React.useState(defaultOrder);
  const [orderBy, setOrderBy] = React.useState(defaultOrderBy);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setPage(0);
  };

  return { order, setOrder, orderBy, setOrderBy, handleRequestSort };
};

export const AdminTableBody = ({
  classes,
  order,
  orderBy,
  rowsPerPage,
  filteredRows,
  page,
  withSubTable,
  subData,
  subDataSlicingNeeded,
  selectableRow,
  customClick,
  idClick,
  CustomSubData
  // headCells
}) => {
  const [open, setOpen] = useState(false);
  return (
    <TableBody>
      {(rowsPerPage > 0
        ? stableSort(filteredRows, getComparator(order, orderBy))?.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          )
        : stableSort(filteredRows, getComparator(order, orderBy))
      )?.map((row, rowIndex) => {
        const values = Object.values(row);
        const keys = Object.keys(row);
        // console.log(selectableRow, 'keys, values ', keys, values);
        const cells = values?.map((r, index) => (
          <TableCell
            className={classes.cell}
            style={
              keys[index] === 'color'
                ? { backgroundColor: r, color: 'transparent' }
                : {
                    color:
                      keys[index] === 'remain'
                        ? r < 0
                          ? 'red'
                          : r <= 31
                          ? 'darkorange'
                          : '#252525'
                        : keys[index] === 'user_phone'
                        ? !r
                          ? 'red'
                          : '#252525'
                        : keys[index] === 'username' && r === '-INCOMPLETE-'
                        ? 'red'
                        : keys[index] === 'RC_poll_date' && r.includes('!!')
                        ? 'red'
                        : '#252525',
                    whiteSpace:
                      keys[index] === 'user_bs_BS_IDs' ||
                      keys[index] === 'gari_probes_ids' ||
                      keys[index] === 'gari_message' ||
                      keys[index] === 'bslog_response' ||
                      keys[index] === 'text' ||
                      keys[index] === 'log_mess' ||
                      // keys[index] === 'access_header' ||
                      keys[index] === 'probes_code' ||
                      keys[index] === 'access_ip'
                        ? 'normal'
                        : 'nowrap',
                    opacity:
                      keys[index] === 'last_login_ts' && r
                        ? Math.max(0.4, 0.4 + ((32e9 - (new Date() - new Date(r))) / 32e9) * 0.6)
                        : 1
                  }
            } // For color palette
            data-for={keys[index] === 'type' ? r : keys[index]} // For _test probes color & logs
            key={`${keys[index]}_${index}`}
            align='center'
          >
            {keys[index] === 'user_bs_BS_IDs' ||
            keys[index] === 'access_header' ||
            keys[index] === 'RC_BS' ? (
              <List style={{ padding: 0 }}>
                {r?.map((bs, i) => (
                  <ListItem
                    style={{ padding: 0, opacity: bs && bs.includes('*') ? 0.5 : 1 }}
                    key={i}
                  >
                    {bs}
                  </ListItem>
                ))}
              </List>
            ) : keys[index] === 'ID' && idClick ? (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  if (idClick) {
                    idClick(r);
                  }
                  // alert(r);
                }}
                variant='contained'
                color={COLORS.altoGray}
                style={{
                  maxHeight: 20,
                  padding: '5px 0',
                  fontSize: 13,
                  fontWeight: 'normal',
                  overflow: 'hidden',
                  width: '100%',
                  borderRadius: 0,
                  background: COLORS.whisperGray
                }}
              >
                {r}
              </Button>
            ) : (
              r
            )}
          </TableCell>
        ));
        return (
          <AdminTableRow
            classes={classes}
            row={row}
            cells={cells}
            withSubTable={withSubTable}
            subData={subData}
            subDataSlicingNeeded={subDataSlicingNeeded}
            key={rowIndex}
            selectableRow={selectableRow}
            customClick={customClick}
            idClick={idClick}
            CustomSubData={CustomSubData}
            open={open}
            setOpen={setOpen}
            // headCells={headCells}
          />
        );
      })}
    </TableBody>
  );
};

export const AdminDataTable = ({
  classes,
  order,
  orderBy,
  handleRequestSort,
  data,
  headCells,
  rowsPerPage,
  filteredRows,
  page,
  listFooter,
  withSubTable,
  subData,
  subDataSlicingNeeded,
  selectableRow,
  message,
  customClick,
  idClick,
  CustomSubData,
  notStandalone
}) => {
  const Message = () => (
    <TableBody>
      <TableRow>
        <TableCell className={classes.Message} colSpan={headCells.length}>
          {message === 'Updating...' ? <Spinner /> : message}
        </TableCell>
      </TableRow>
    </TableBody>
  );

  return (
    <TableContainer className={notStandalone ? classes.tableWrapper : null}>
      <Table className={classes.table}>
        <EnhancedTableHead
          classes={classes}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          rowCount={data?.length}
          headCells={headCells}
        />
        {message ? (
          <Message />
        ) : (
          <AdminTableBody
            classes={classes}
            order={order}
            orderBy={orderBy}
            rowsPerPage={rowsPerPage}
            filteredRows={filteredRows}
            page={page}
            withSubTable={withSubTable}
            subData={subData}
            subDataSlicingNeeded={subDataSlicingNeeded}
            selectableRow={selectableRow}
            customClick={customClick}
            idClick={idClick}
            CustomSubData={CustomSubData}
            // headCells={headCells}
          />
        )}
        {!message && listFooter}
      </Table>
    </TableContainer>
  );
};

export const AdminTableRow = ({
  classes,
  row,
  cells,
  withSubTable,
  subData,
  subDataSlicingNeeded,
  selectableRow,
  customClick,
  idClick,
  CustomSubData,
  open,
  setOpen
}) => {
  const [visited, setVisited] = useState(false);

  const onClickHandler = (rowID) => {
    let getNewData = true;
    if (rowID === undefined) {
      if (setOpen) {
        getNewData = false;
        setOpen(true);
      }
    } else if (setOpen) {
      // customisations to make sensor details toggle
      const prevOpen = open;
      if (open) {
        // Close currently open row
        setOpen(false);
        getNewData = false;
      }
      if (prevOpen !== rowID) {
        // open another row
        setOpen(rowID);
      }
    }
    if (!idClick) {
      setVisited(true);
    }
    if (customClick) {
      customClick(row, getNewData, setOpen);
    }
  };

  return (
    <>
      <TableRow
        tabIndex={-1}
        onClick={() => onClickHandler(row.ID)}
        className={
          selectableRow
            ? open === row.ID
              ? classes.selected
              : visited
              ? classes.visited
              : classes.row
            : row.type === 'OK'
            ? classes.okRow
            : null
        }
      >
        {cells}
      </TableRow>
      {withSubTable && open === row.ID && CustomSubData ? (
        <CustomSubData id={row.ID} />
      ) : (
        withSubTable &&
        open === row.ID &&
        subData
          .filter((s) => s.ID === row.ID)
          ?.slice(subDataSlicingNeeded ? 1 : 0)
          ?.map((subRow, i) => {
            const subValues = Object.values(subRow);
            const subKeys = Object.keys(subRow);
            const subCells = subValues.map((r, index) => (
              <TableCell key={subKeys[index]} className={classes.cell} align='center'>
                {r}
              </TableCell>
            ));
            return (
              <TableRow
                tabIndex={-1}
                key={subKeys[i] || Math.random()}
                className={classes.trExtanded}
              >
                {subCells}
              </TableRow>
            );
          })
      )}
    </>
  );
};

export const AdminTable = ({
  headCells,
  AdminCustom,
  CustomSearch,
  secondaryHeadCells,
  subDataSlicingNeeded,
  notStandalone
}) => {
  const {
    i18n,
    classes,
    data,
    setData,
    message,
    setMessage,
    subData,
    setSubData,
    subHeaderNeeded,
    setSubHeaderNeeded
  } = TableState();

  const {
    barTitle,
    paginationLimit,
    rowsPerPageOptions,
    defaultOrderBy,
    defaultOrder,
    withSubTable,
    AdditionalComponent,
    selectableRow,
    noFooterNeeded,
    customClick,
    idClick,
    DetailsDialog,
    CustomSubData,
    embeddedTable
  } = AdminCustom({
    i18n,
    data,
    setData,
    subData,
    setSubData,
    setMessage,
    notStandalone
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(paginationLimit);

  const { order, orderBy, handleRequestSort } = TableOrderState({
    defaultOrderBy: defaultOrderBy ?? headCells[0].id,
    defaultOrder: defaultOrder ?? 'asc',
    setPage
  });

  const [searchString, setSearchString] = useState('');
  const { clearSearch, handleSearch, filteredRows } = tableSearch({
    searchString,
    setSearchString,
    data
  });

  const listFooter = noFooterNeeded ? null : (
    <ListFooter
      data={data}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      i18n={i18n}
      rowsPerPageOptions={rowsPerPageOptions}
    />
  );

  return (
    <div
      style={notStandalone ? { maxWidth: 'none' } : {}}
      className={embeddedTable ? classes.embedded : classes.root}
    >
      {DetailsDialog && <DetailsDialog />}
      <div className={embeddedTable ? classes.embedded : classes.paper}>
        {AdditionalComponent && <AdditionalComponent />}
        {CustomSearch ? (
          <CustomSearch
            setData={setData}
            setSubHeaderNeeded={setSubHeaderNeeded}
            setMessage={setMessage}
            notStandalone={notStandalone}
          />
        ) : (
          <TopBar
            title={barTitle}
            classes={classes}
            handleSearch={handleSearch}
            searchString={searchString}
            clearSearch={clearSearch}
          />
        )}
        <AdminDataTable
          classes={classes}
          order={order}
          orderBy={orderBy}
          handleRequestSort={handleRequestSort}
          data={data}
          headCells={subHeaderNeeded ? secondaryHeadCells : headCells}
          rowsPerPage={rowsPerPage}
          filteredRows={filteredRows}
          page={page}
          listFooter={listFooter}
          withSubTable={withSubTable}
          subData={subData}
          subDataSlicingNeeded={subDataSlicingNeeded}
          selectableRow={selectableRow}
          message={message}
          customClick={customClick}
          idClick={idClick}
          CustomSubData={CustomSubData}
          notStandalone={notStandalone}
        />
      </div>
    </div>
  );
};
