import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'utils/colors';
import QualityBar from './QualityBar';

const useStyles = makeStyles(() => ({
  qListItemWrapper: {
    margin: 5,
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 30,
    maxHeight: 35,
    fontSize: 13,
    marginBottom: 7,
    boxSizing: 'border-box',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: COLORS.whisperGray,
    fontFamily: `'Roboto', sans-serif`,
    fontWeight: 400,
    paddingRight: 0,
    marginRight: 20,
    border: '1px solid transparent'
  },
  contentName: {
    width: '50%',
    paddingTop: 3,
    maxHeight: 20,
    marginLeft: 5
  },
  namedSensorId: {
    fontSize: 10,
    marginTop: -2
  },
  sensorName: {
    marginTop: -2,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: 78,
    marginRight: -5
  },
  plainSensorId: {
    marginTop: 3
  }
}));

const QualitySensorListItem = ({
  sensorID,
  sensorConf,
  probeQuality,
  onSensorClick,
  periodUnset,
  i18n
}) => {
  const classes = useStyles();
  //   const qualityFigure =
  //     probeQuality && !isNil(probeQuality.quality)
  //       ? `${(probeQuality.quality * 100).toFixed(1)}%`
  //       : ' --';

  return (
    <div className={classes.qListItemWrapper}>
      {/* {`${sensorID} ${qualityFigure}`} */}
      <div className={classes.contentName}>
        {sensorConf && sensorConf.sensor_name ? (
          <div>
            <div className={classes.namedSensorId}>{sensorID}</div>
            <div className={classes.sensorName}>{sensorConf.sensor_name}</div>
          </div>
        ) : (
          <div className={classes.plainSensorId}>{sensorID}</div>
        )}
      </div>
      <QualityBar
        probeQuality={probeQuality}
        onQualityClick={onSensorClick}
        periodUnset={periodUnset}
        i18n={i18n}
      />
    </div>
  );
};

export default QualitySensorListItem;
