// PUBLIC IMPORTS
import React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';

class FanHandSVG extends React.Component {
  render() {
    return (
      <div style={this.props.style}>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12'>
          <path
            d='M6.5,9.5c0-0.7,0.2-1.4,0.7-1.9C7.1,7.5,7,7.3,6.9,7.2C7.1,7,7.3,6.8,7.4,6.6c0.2,0.1,0.5,0.1,0.7,0.3
            c0.9-0.5,1.9-0.4,2.8,0C11,6.7,11,6.5,11,6.3c0-1.8-2-2.2-3.8-1.2C7,4.9,6.8,4.7,6.6,4.6c0.1-0.5,0.3-1,0.8-1.2C8.5,2.8,8.5,1,6.2,1
            C4.5,1,4.1,3,5.1,4.8C4.9,5,4.7,5.2,4.6,5.4c-0.5-0.1-1-0.3-1.2-0.8C2.8,3.4,1,3.5,1,5.8c0,1.8,2,2.2,3.8,1.2C5,7.1,5.2,7.3,5.4,7.4
            c-0.1,0.5-0.3,1-0.8,1.2C3.5,9.2,3.5,11,5.8,11c0.4,0,0.8-0.1,1-0.3C6.6,10.3,6.5,9.9,6.5,9.5 M6,6.5C5.7,6.5,5.5,6.3,5.5,6
            S5.7,5.5,6,5.5c0.3,0,0.5,0.2,0.5,0.5S6.3,6.5,6,6.5 M8.9,10.9L7.5,9.5c-0.1-0.1-0.1-0.2,0-0.3s0.2-0.1,0.3,0L8.7,10l0.1-0.1
            L7.5,8.6c-0.1-0.1-0.1-0.2,0-0.3c0.1-0.1,0.2-0.1,0.3,0l1.3,1.3l0.1-0.1L7.8,7.9c-0.1-0.1-0.1-0.2,0-0.3s0.2-0.1,0.3,0L9.6,9
            l0.1-0.1L8.6,7.7c-0.1-0.1-0.1-0.2,0-0.3s0.2-0.1,0.3,0L10.5,9l0.1-0.1l-0.3-0.7c0-0.1,0-0.2,0-0.3l0.1-0.1c0-0.1,0.2-0.1,0.3-0.1
            c0,0,0.1,0.1,0.1,0.1l0.6,1.5c0.2,0.6,0.1,1.3-0.3,1.7C10.4,11.5,9.5,11.5,8.9,10.9z'
            style={{ fill: this.props.color }}
          />
        </svg>
      </div>
    );
  }
}

const styles = (theme) => ({
  hide: {
    display: 'none'
  }
});

export default withRouter(withWidth()(withStyles(styles)(FanHandSVG)));
