import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    marginLeft: '10px'
  },
  slider: {
    margin: '10px 25px 40px 25px'
  },
  picker: {
    width: 80,
    marginBottom: theme.spacing(1)
  },
  dateLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '12px',
    fontWeight: 400
  },
  dateText: {
    fontSize: '1rem',
    margin: '5px 0'
  },
  [theme.breakpoints.down('xs')]: {
    dialogTitle: {
      textAlign: 'center'
    },
    dateLabel: {
      marginTop: '-2px'
    }
  }
}));
