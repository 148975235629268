/* eslint-disable no-plusplus */
const getClosestSmallestKey = (myArray, myValue) => {
  let i = 0;
  if (myArray[0] > myValue) {
    return null;
  }
  while (myArray[++i] < myValue);

  return --i;
};

export const calculatedCorrection = (temp, calibration) => {
  const calibrationRange = Object.keys(calibration).map((t) => +t);
  const index0 = getClosestSmallestKey(calibrationRange, temp);
  const T1 = +Object.keys(calibration)[index0];
  const T2 = +Object.keys(calibration)[index0 + 1];
  const C1 = Object.values(calibration)[index0];
  const C2 = Object.values(calibration)[index0 + 1];

  const k = (C2 - C1) / (T2 - T1);
  const corrT = k * temp + C1 - k * T1;

  return corrT;
};
