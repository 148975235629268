import React from 'react';
import ReactDOM from 'react-dom';
import { DEBUG } from 'utils/isDevMode';
import App from './App';

if (!DEBUG) {
  console.log = function () {};
}

// eslint-disable-next-line import/no-cycle
ReactDOM.render(<App />, document.getElementById('root'));
