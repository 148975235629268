import { Button, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import warehouseIcon from '@iconify/icons-mdi/warehouse';
import { InlineIcon } from '@iconify/react';
import { last } from 'lodash';
import { Spinner } from 'utils/Spinners';
import StorageEditorDialog from './StorageEditDialog';
import { storageTypeOptionsArray } from '../helpers';

const SurveyReady = ({ i18n, state, setState, classes }) => {
  useEffect(() => {
    const storageObject = (index) => ({
      id: index,
      name: `${i18n.storage || 'Storage'} ${index}`,
      type: null,
      capacity: null,
      size: null,
      undefinedLayoutType: true
    });

    const createStoragesList = () => {
      const storagesList = [];
      const length = state.storagesNumber.value;
      for (let i = 1; i <= length; i++) {
        storagesList.push(storageObject(i));
      }
      setState((tempState) => ({ ...tempState, storages: storagesList }));
    };
    if (!state.storages) {
      createStoragesList();
    } else if (state.storagesNumber.value > state.storages.length) {
      // Compare values and add storages to the current array
      const numberOfNewStorages = state.storagesNumber.value - state.storages.length;
      let lastIndex = last(state.storages).id;
      const newStoragesToAdd = [];
      for (let index = 0; index < numberOfNewStorages; index++) {
        newStoragesToAdd.push(storageObject(lastIndex + 1));
        lastIndex = last(newStoragesToAdd).id;
      }
      setState((tempState) => ({
        ...tempState,
        storages: tempState.storages.concat(newStoragesToAdd)
      }));
    } else if (state.storagesNumber.value < state.storages.length) {
      // Compare values and remove storages to the current array
      const numberOfStoragesToPop = state.storages.length - state.storagesNumber.value;
      const tempStorage = [...state.storages];
      tempStorage.splice(-numberOfStoragesToPop, numberOfStoragesToPop);
      setState((tempState) => ({
        ...tempState,
        storages: tempStorage
      }));
    }
  }, [i18n.storage, setState, state.storages, state.storagesNumber, state.storagesNumber.value]);

  const [storageSelected, setStorageSelected] = useState();
  const [isStorageEditorOpen, setIsStorageEditorOpen] = useState(false);
  const storageTypeOptions = storageTypeOptionsArray(i18n);

  const onEditStorage = (storageIndex) => {
    setIsStorageEditorOpen(!isStorageEditorOpen);
    if (storageIndex) {
      setStorageSelected(state.storages.find((st) => st.id === +storageIndex));
    } else {
      setStorageSelected();
    }
  };

  if (!state.storages) {
    return <Spinner />;
  }

  return (
    <div className={classes.readyContainer}>
      <Typography component='div'>
        {i18n.storages_info_now ||
          'You may specify the properties of the storages now or complete the information later.'}
      </Typography>
      <div className={classes.storageTable}>
        <div className={classes.storageTableHeader}>
          {/* <div className={classes.storageTableDivIcon} /> */}
          <div className={classes.storageTableDiv} style={{ maxWidth: '33%' }}>
            {i18n.storage_name || 'Name'}
          </div>
          <div className={classes.storageTableDiv}>{i18n.type || 'Type'} </div>
          <div className={classes.storageTableDiv} style={{ maxWidth: '22%' }}>
            {i18n.capacity || 'Capacity'}
          </div>
          {/* <div className={classes.storageTableDiv}>Size / layout</div> */}
          {/* <div className={classes.buttonDiv} /> */}
        </div>
        <div className={classes.storageTableBody}>
          {state.storages.map((st) => (
            <Button
              value={st.id}
              onClick={(e) => onEditStorage(e.currentTarget.value)}
              key={st.id}
              className={classes.storageTableContent}
            >
              {/* <div className={classes.storageTableDivIcon} /> */}
              <div style={{ position: 'relative', width: '35%' }}>
                <InlineIcon
                  width={25}
                  height={25}
                  icon={warehouseIcon}
                  className={classes.storageIcon}
                />
                <div className={`${classes.storageTableDiv} ${classes.storageNameDiv}`}>
                  {st.name}
                </div>
              </div>
              <div className={classes.storageTableDiv}>
                {storageTypeOptions.find((opt) => opt.id === st.type)?.label}
              </div>
              <div style={{ maxWidth: '22%' }} className={classes.storageTableDiv}>
                {st.capacity}
              </div>
              {/* <div className={classes.storageTableDiv}>{st.size}</div> */}
              <div className={st.capacity ? null : classes.buttonDiv}>
                {st.capacity ? '' : i18n.edit || 'Edit'}
              </div>
            </Button>
          ))}
        </div>
      </div>
      {isStorageEditorOpen && (
        <StorageEditorDialog
          i18n={i18n}
          classes={classes}
          state={state}
          setState={setState}
          onEditStorage={onEditStorage}
          storageSelected={storageSelected}
          setStorageSelected={setStorageSelected}
          storageTypeOptions={storageTypeOptions}
        />
      )}
    </div>
  );
};

export default SurveyReady;
