// PUBLIC IMPORTS
import {
  Button,
  CssBaseline,
  Fade,
  Link as LinkUI,
  Grid,
  Typography,
  Container
} from '@material-ui/core';

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { getTranslationsData } from 'redux/reducers/setLanguage';

import QuanturiSVG from 'assets/svg/QuanturiSVG';

import { getLogin, getLoginAdmin } from 'api/serverAPI';
import jwtManager, { accessTokenKey, refreshTokenKey } from 'api/jwtManager';

import { COLORS } from 'utils/colors';
import { directConnectStyles } from 'utils/sharedStyles';
import { getMaxPlan } from 'assets/utils';
import { useStyles, StyledTextField } from './Login.styled';

import ForgetPasswordDialog from './ForgetPasswordDialog';
import LanguageSelector from '../../activation/LanguageSelector';

export const getUrlParams = (search) => {
  if (search) {
    const hashes = search.slice(search.indexOf('?') + 1).split(/[&?]/);
    return hashes.reduce((params, hash) => {
      const [key, val] = hash.split('=');
      return Object.assign(params, { [key]: decodeURIComponent(val) });
    }, {});
  }
  return {};
};

const isSupervisorUser = (plans) =>
  plans && plans[0] && plans[0].planLevel && Number(plans[0].planLevel) === 4;
const isCompostUser = (plans) => plans && plans[0].segment && Number(plans[0].segment) === 14;
// const isGrainUser = plans => plans && plans[0].segment && Number(plans[0].segment) === 13;

const Login = ({ userHasLoggedIn, Cookies, embedded }) => {
  const initialState = {
    isForgetPasswordDialogOpen: false,
    username: '',
    password: '',
    error: false,
    error_msg: ''
  };

  const history = useHistory();
  const location = useLocation();
  const params = getUrlParams(location.search);
  const classes = useStyles();
  const footerClasses = directConnectStyles();
  const i18n = useSelector((store) => store.i18n);
  const dispatch = useDispatch();

  const [state, setState] = useState(initialState);
  const handleChange = (e) => {
    e.preventDefault();
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const login = async (e, resetUsername, resetPassword) => {
    let props;
    const url = window.location.href;
    let slashIndex = url.indexOf('//');
    const stripHttps = url.substring(slashIndex + 2, url.length);
    slashIndex = stripHttps.indexOf('/');
    const plainHost = stripHttps.substring(0, slashIndex);
    if (resetUsername && resetPassword) {
      props = { username: resetUsername, password: resetPassword, host: plainHost, url };
    } else {
      e.preventDefault();
      props = { username: state.username, password: state.password, host: plainHost, url };
    }
    let response;
    if (embedded) {
      response = await getLoginAdmin(props);
    } else {
      response = await getLogin(props);
    }
    if (response.status_code === 0) {
      const {
        admin,
        rootAdmin,
        plans,
        service,
        accessToken,
        accessTokenExpiry,
        refreshToken,
        newFeatureLive,
        feedQualityLive,
        limitFreeData
      } = response;
      jwtManager.setToken(accessTokenKey, accessToken, accessTokenExpiry);
      jwtManager.setToken(refreshTokenKey, refreshToken);
      dispatch({ type: 'SET_LOGIN', value: true });
      dispatch({ type: 'SET_EDIT_CONFIRM_NEEDED', value: false });
      if (!admin && !isSupervisorUser(plans)) {
        dispatch({ type: 'SET_SUPERVISOR', value: false });
        dispatch({ type: 'SET_SUPERVISOR_COMPOST', value: false });
      } else {
        dispatch({ type: 'SET_LOGIN', value: true });
        if (admin) {
          console.log('getTranslationsData at: login admin');
          await dispatch(getTranslationsData(i18n.locale ?? 'en', null, admin));
          dispatch({ type: 'SET_SUPERVISOR', value: true });
          dispatch({ type: 'SET_SUPERVISOR_COMPOST', value: true });
          if (rootAdmin) {
            Cookies.set('DEBUG', rootAdmin);
          }
        } else if (isSupervisorUser(plans)) {
          if (isCompostUser(plans)) {
            dispatch({ type: 'SET_SUPERVISOR_COMPOST', value: true });
          } else {
            dispatch({ type: 'SET_SUPERVISOR', value: true });
          }
        }
      }
      userHasLoggedIn(true);

      const oldHayUser = !service && getMaxPlan(plans).planLevel < 4;
      const insightSurveyIncomplete = !!service && !service?.surveyCompleted;
      console.log('NEW FEATURE LIVE: ', newFeatureLive);
      console.log('FEED QUALITY LIVE: ', feedQualityLive);
      console.log('LIMIT FREE DATA LIVE: ', limitFreeData);
      if (embedded) {
        dispatch({ type: 'SET_ADMIN', value: true });
      } else if (!admin && (oldHayUser || insightSurveyIncomplete)) {
        console.log('redirection to INSIGHT from Login');
        history.push({ pathname: '/insight', state: { plan: getMaxPlan(plans), service } });
      } else {
        // This enable to recreate the string with search even when redirected
        const redirectionWithSearch =
          params.redirect && Object.keys(params).length > 1
            ? `${params.redirect}${location.search.substr(Object.values(params)[0].length + 10)}`
            : params.redirect || '/';

        history.push(redirectionWithSearch);
      }
    } else if (
      response.status_code === 103 ||
      response.status_code === 102 ||
      response.status_code === 104
    ) {
      // Wrong username or password
      dispatch({ type: 'SET_LOGIN', value: false });
      setState((tempState) => ({ ...tempState, error: true, error_msg: i18n.login_error }));
    } else {
      dispatch({ type: 'SET_LOGIN', value: false });
    }
  };

  const toggleResetPasswordDialog = () => {
    setState((tempState) => ({
      ...tempState,
      isForgetPasswordDialogOpened: !state.isForgetPasswordDialogOpened
    }));
  };

  const ActivationFooter = () => (
    <Link style={{ bottom: 0 }} className={footerClasses.connectApp} to='/activation'>
      <div style={{ fontWeight: 'bold', fontSize: 16 }}>
        {i18n.link_to_act_title || 'Have a new Quanturi system?'}
      </div>
      <div style={{ fontSize: 12 }}>
        {i18n.link_to_act_msg || 'Click here to activate your system!'}
      </div>
    </Link>
  );

  const LoginForm = () => (
    <>
      <Grid item>
        <Typography color='error' style={{ marginBottom: -20, marginTop: 10 }}>
          {state.error && state.error_msg}
        </Typography>
      </Grid>
      <form className={classes.form} noValidate onSubmit={login}>
        <StyledTextField
          id='outlined-basic'
          variant='outlined'
          margin='normal'
          required
          fullWidth
          name='username'
          autoComplete='username'
          autoFocus
          size='small'
          placeholder={i18n.username || 'Username'}
          onChange={handleChange}
        />
        <StyledTextField
          variant='outlined'
          margin='normal'
          required
          fullWidth
          name='password'
          type='password'
          autoComplete='current-password'
          color='primary'
          size='small'
          placeholder={i18n.password || 'Password'}
          onChange={handleChange}
        />
        <Button
          type='submit'
          fullWidth
          variant='contained'
          color='secondary'
          className={classes.submit}
          disabled={isEmpty(i18n)}
        >
          <span>{i18n.login || 'login'}</span>
        </Button>
      </form>
    </>
  );

  if (embedded) {
    return LoginForm();
  }
  return (
    <div className={classes.mainContainer}>
      <Fade in>
        <Container component='main' maxWidth='xs'>
          <CssBaseline />
          <div className={classes.paper}>
            <Grid
              container
              spacing={10}
              alignItems='center'
              direction='column'
              justifyContent='center'
            >
              <QuanturiSVG
                color={COLORS.white}
                betaColor={COLORS.white}
                style={{ width: 205, marginBottom: 25 }}
              />
            </Grid>
            {LoginForm()}
            <Grid container>
              <Grid item xs>
                <div>
                  <LanguageSelector main />
                </div>
              </Grid>
              <Grid item>
                <LinkUI color='secondary' variant='body2' onClick={toggleResetPasswordDialog}>
                  <span>{i18n.forgot_password}</span>
                </LinkUI>
              </Grid>
            </Grid>
          </div>
        </Container>
      </Fade>

      {!isEmpty(i18n) && <ActivationFooter />}

      {state.isForgetPasswordDialogOpened && (
        <ForgetPasswordDialog
          i18n={i18n}
          isForgetPasswordDialogOpened={state.isForgetPasswordDialogOpened}
          toggleResetPasswordDialog={toggleResetPasswordDialog}
          login={login}
        />
      )}
    </div>
  );
};

export default Login;
