import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { QSlider, ruleEditorStyle } from 'utils/sharedStyles';
import AbsRuleEditor from './rule-editor-groups/AbsRuleEditor';
import RelRuleEditor from './rule-editor-groups/RelRuleEditor';
import FcRuleEditor from './rule-editor-groups/fc-rules/FcRuleEditor';

const RuleEditorDialog = ({
  setMonitorEdited,
  monitorEdited,
  newRule,
  setNewRule,
  selectedRuleType,
  setSelectedRuleType,
  setSelectRuleTypeDialog,
  selectedRule,
  setSelectedRule,
  ruleTypes,
  setRuleInUseEdited,
  monitorInUse,
  selectedMonitor,
  timeStamp,
  FC_IDS,
  FcStatus,
  setToRunScheduler
}) => {
  const { i18n, degrees, sensorConfigurations } = useSelector((store) => ({
    i18n: store.i18n,
    degrees: store.sessionInfo?.ui_preferences?.degrees,
    sensorConfigurations: store.sensorConfigurations
  }));

  console.log('RuleEditorDialog ', monitorEdited, selectedRuleType);
  const classes = ruleEditorStyle();

  useEffect(() => {
    if (selectedRule !== null) {
      console.log('Giving ruleType automatically for update :', selectedRule?.type, ruleTypes);
      setSelectedRuleType(ruleTypes.filter((ruleType) => ruleType.type === selectedRule?.type)[0]);
    }
  }, [ruleTypes, selectedRule, setSelectedRuleType]);

  const components = {
    ABS: AbsRuleEditor,
    REL: RelRuleEditor,
    FC: FcRuleEditor
  };

  const RuleEditor = components[selectedRuleType?.group || 'ABS'];

  return (
    <RuleEditor
      QSlider={QSlider}
      classes={classes}
      i18n={i18n}
      degrees={degrees}
      selectedRuleType={selectedRuleType}
      selectedRule={selectedRule}
      setMonitorEdited={setMonitorEdited}
      monitorEdited={monitorEdited}
      newRule={newRule}
      setNewRule={setNewRule}
      setSelectedRuleType={setSelectedRuleType}
      setSelectRuleTypeDialog={setSelectRuleTypeDialog}
      setSelectedRule={setSelectedRule}
      ruleTypes={ruleTypes}
      setRuleInUseEdited={setRuleInUseEdited}
      monitorInUse={monitorInUse}
      selectedMonitor={selectedMonitor}
      timeStamp={timeStamp}
      sensorConfigurations={sensorConfigurations}
      FC_IDS={FC_IDS}
      FcStatus={FcStatus}
      setToRunScheduler={setToRunScheduler}
    />
  );
};

export default RuleEditorDialog;
