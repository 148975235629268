import React, { useRef, useEffect } from 'react';
import { extend, useThree } from '@react-three/fiber';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

extend({ OrbitControls });

export const Controls = ({ setControlsOn }) => {
  const ref = useRef();
  const { invalidate, camera, gl } = useThree();
  // 'invalidate' for reducing CPU use:
  useEffect(() => {
    // console.log('added Controls listeners');
    ref.current.addEventListener('change', invalidate);
    // ref.current.addEventListener('start', () => setControlsOn(true));
    // ref.current.addEventListener('end', () => setControlsOn(false));
    return () => {
      if (ref && ref.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ref.current.removeEventListener('change', invalidate);
        // ref.current.removeEventListener('start');
        // ref.current.removeEventListener('end');
        // console.log('removed Controls listeners');
      }
    };
  }, [invalidate, setControlsOn]);
  // Footprint controls: just top view without rotating
  return (
    <orbitControls
      ref={ref}
      // eslint-disable-next-line react/no-unknown-property
      args={[camera, gl.domElement]}
      // enableRotate={false}
      // eslint-disable-next-line react/no-unknown-property
      zoomSpeed={1.5}
      // minPolarAngle={(0.8 * Math.PI) / 2}
      // maxPolarAngle={(0.8 * Math.PI) / 2}
      // minAzimuthAngle={0}
      // maxAzimuthAngle={0}
      // mouseButtons={{
      //   LEFT: THREE.MOUSE.PAN,
      //   MIDDLE: THREE.MOUSE.DOLLY,
      //   RIGHT: THREE.MOUSE.PAN
      // }}
      // eslint-disable-next-line react/no-unknown-property
      minPolarAngle={0}
      // eslint-disable-next-line react/no-unknown-property
      maxPolarAngle={Math.PI / 2 - 0.05} // prevent going below grou
      // eslint-disable-next-line react/no-unknown-property
      minAzimuthAngle={-Math.PI / 2}
      // eslint-disable-next-line react/no-unknown-property
      maxAzimuthAngle={Math.PI / 2}
    />
  );
};
