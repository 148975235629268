import { Typography, List, ListItem, ListItemText, Chip } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { InlineIcon } from '@iconify/react';
import bell from '@iconify/icons-mdi/bell';
import bellOff from '@iconify/icons-mdi/bell-off-outline';
import accountBox from '@iconify/icons-mdi/account';
import { isEmpty } from 'lodash';
import { makeStyles, ThemeProvider, createTheme } from '@material-ui/core/styles';
import { i18nVariablesInjection } from 'utils/sharedHelper';
import { tempFormat } from 'assets/utils';

const useStyles = makeStyles(() => ({
  notification: {
    height: 60
  },
  noNotif: {
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
    opacity: 0.4,
    marginTop: 100
  },
  bold: {
    fontWeight: 600
  }
}));

const customTheme = (color) =>
  createTheme({
    palette: {
      primary: {
        main: color
      }
    },
    typography: {
      fontFamily: "'Roboto', sans-serif",
      useNextVariants: true
    }
  });

const DisplayChip = ({ title, color, icon }) => (
  <ThemeProvider theme={customTheme(color)}>
    <Chip
      style={{
        marginLeft: -5,
        paddingLeft: 5,
        marginBottom: 5,
        cursor: 'pointer'
      }}
      icon={icon}
      label={title}
      color='primary'
    />
  </ThemeProvider>
);

const intialState = {
  isLoading: true,
  notifications: []
};

export default function Notifications({ onClick, editConfirmNeeded }) {
  const classes = useStyles();
  const [state, setState] = useState(intialState);

  const { notifications, i18n, degrees } = useSelector((store) => ({
    notifications: store.notifications,
    i18n: store.i18n,
    degrees: store.sessionInfo?.ui_preferences?.degrees
  }));

  useEffect(() => {
    setState({
      notifications,
      isLoading: false
    });
  }, [notifications]);

  return (
    <div>
      {(state.isLoading || isEmpty(state.notifications)) && (
        <div className={classes.noNotif}>
          <Typography variant='h5'>{i18n.no_notif}</Typography>
        </div>
      )}
      <List>
        {state.notifications.map((notification) => {
          let text;
          let icon = <InlineIcon width={18} height={18} icon={accountBox} />;
          if (notification.type === 'noAlerts') {
            icon = <InlineIcon width={18} height={18} icon={bellOff} />;
            text = i18n[notification.props.text] || notification.props.text;
          } else if (notification.type === 'globalAlert') {
            icon = <InlineIcon width={18} height={18} icon={bell} />;
            text = `${i18n.monitor_alert_text} ${
              notification.props.nProbes
            } (${notification.props.probesIDs?.join(', ')})`;
          } else if (notification.type === 'batch') {
            icon = <InlineIcon width={18} height={18} icon={bell} />;
            text = (
              <>
                {i18n.batch}
                {notification.props.completed ? ` (${i18n.completed_s}) ` : ` `}
                <span className={classes.bold}>{notification.props.batch_name}</span>
                {i18n.colon}
                {i18n.high_temperature}{' '}
                <span className={classes.bold}>
                  {tempFormat(degrees, notification.props.maxTemp)} {degrees || '°C'}
                </span>
              </>
            );
          } else if (notification.type === 'reminder') {
            text = i18nVariablesInjection(
              notification.props.trial
                ? i18n.notification_trial_reminder
                : i18n.notification_reminder,
              {
                planName: notification.props.planName,
                days: notification.props.days,
                daysWord: notification.props.days > 1 ? i18n.days : i18n.day
              }
            );
            // OLD WAY
            // <div>
            //   {i18n.reminder_text1}{' '}
            //   <span className={classes.bold}>{notification.props.planName}</span>{' '}
            //   {notification.props.trial && `${i18n.trial || 'trial'} `}
            //   {i18n.reminder_text2}{' '}
            //   <span className={classes.bold}>
            //     {notification.props.days} {notification.props.days > 1 ? i18n.days : i18n.day}
            //   </span>
            //   .
            // </div>
          } else if (notification.type === 'reminderExpired') {
            text = i18nVariablesInjection(i18n.notification_reminder_expired, {
              planName: notification.props.planName
            });
            // OLD WAY
            // text = (
            //   <>
            //     {i18n.reminder_text1}{' '}
            //     <span className={classes.bold}>{notification.props.planName}</span>{' '}
            //     {i18n.reminder_text2_expired}
            //   </>
            // );
          } else if (notification.type === 'reminderSim') {
            text = (
              <>
                {i18n.reminder_text1} <span className={classes.bold}>{i18n.sim_card}</span>{' '}
                {i18n.reminder_text2}{' '}
                <span className={classes.bold}>
                  {notification.props.days} {notification.props.days > 1 ? i18n.days : i18n.day}
                </span>
                .
              </>
            );
          } else if (notification.type === 'reminderSimExpired') {
            text = (
              <>
                {i18n.reminder_text1} <span className={classes.bold}>{i18n.sim_card}</span>{' '}
                {i18n.reminder_text2_expired}
              </>
            );
          } else if (notification.type === 'reminderAPI') {
            text = (
              <>
                {i18n.reminder_text1} <span className={classes.bold}>Quanturi Connect API</span>{' '}
                {i18n.reminder_text2}{' '}
                <span className={classes.bold}>
                  {notification.props.days} {notification.props.days > 1 ? i18n.days : i18n.day}
                </span>
                .
              </>
            );
          } else if (notification.type === 'reminderAPIExpired') {
            text = (
              <>
                {i18n.reminder_text1} <span className={classes.bold}>Quanturi Connect API</span>{' '}
                {i18n.reminder_text2_expired}
              </>
            );
          } else if (notification.type === 'storageMax') {
            icon = <InlineIcon width={18} height={18} icon={bell} />;
            text = (
              <>
                {i18n.storage} <span className={classes.bold}>{notification.props.name}</span>
                {i18n.colon} {i18n.monitor_alert_text}{' '}
                <span className={classes.bold}>{notification.props.count}</span> (
                {notification.props.probeIDs?.join(', ')})
              </>
            );
          }
          return (
            <ListItem
              button
              key={notification.id}
              component={!editConfirmNeeded ? Link : 'div'}
              to={!editConfirmNeeded ? notification.url : ''}
              onClick={onClick}
            >
              <ListItemText
                primary={
                  <DisplayChip
                    icon={icon}
                    title={i18n[notification.title] || notification.title}
                    color={notification.color}
                  />
                }
                secondary={i18n[notification.text] || text}
              />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
}
