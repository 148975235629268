import { Button } from '@material-ui/core';
import React, { useEffect, useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import { Icon } from '@iconify/react';
import arrowRightThick from '@iconify/icons-mdi/arrow-right-thick';
import microsoftExcel from '@iconify/icons-mdi/microsoft-excel';
import dotsVertical from '@iconify/icons-mdi/dots-vertical';
import pencilIcon from '@iconify/icons-mdi/pencil';
import { getAlertSensorDepths } from 'assets/utils';
import { COLORS } from 'utils/colors';

import BasicSensorList from '../../general/basic-sensor-list/BasicSensorList';
import { StorageContext } from '../StorageContext';
import StorageLayout from './StorageLayout';
import StorageStackLayout from './StorageStackLayout';
import LayoutMenubar from './LayoutMenubar';
import StorageDataLoader from '../storage-utils/StorageDataLoader';
// import LayoutSensorPanel from './LayoutSensorPanel';
// import useFetchSensorList from '../../probes/sensor/useFetchSensorList';
// import { getRangeFromData } from '../storage-utils/storageHelper';

const useStyles = makeStyles((theme) => ({
  layoutviewWrapper: {
    position: 'relative'
  },

  leftPanelWrapper: {
    position: 'absolute',
    top: 36,
    height: '100%',
    transition: 'all 200ms',
    borderRight: `1px solid ${COLORS.lightGray2}`,
    zIndex: 1
  },
  storagePanel: {
    backgroundColor: COLORS.whiteSmoke,
    height: '100%',
    padding: 15,
    borderRight: `1px solid ${COLORS.lightGray2}`,
    borderTop: `1px solid ${COLORS.lightGray2}`,
    transition: 'all 200ms'
  },
  sensorListPanel: {
    height: 'calc(100% - 40px)',
    overflowY: 'auto',
    marginTop: 0,
    padding: '5px 5px 5px 10px',
    boxSizing: 'border-box',
    backgroundColor: COLORS.white
  },
  editInfo: {
    display: 'flex',
    marginLeft: 15
  },
  editInfoText: {
    color: COLORS.secondaryColor,
    maxWidth: 185,
    fontSize: 14
  },
  infoIcon: {
    color: COLORS.secondaryColor
  },
  noSensors: {
    padding: '0 10px 0 5px',
    overflow: 'hidden'
  },
  noSensorsTitle: {
    color: COLORS.darkGray,
    fontSize: 16
  },
  noSensorsContent: {
    display: 'flex'
  },
  noSensorsText: {
    color: COLORS.darkGray,
    fontSize: 13,
    margin: '5px 5px 0px 10px'
  },
  noSensorsIconDiv: {
    minWidth: 32,
    height: 32,
    backgroundColor: COLORS.darkGray,
    borderRadius: 20,
    marginTop: 10,
    textAlign: 'center'
  },
  noSensorsIcon: {
    width: 24,
    height: 24,
    color: COLORS.white,
    marginTop: 3
  },
  arrow: {
    color: COLORS.darkGray,
    marginRight: -12,
    marginTop: 6,
    borderLeft: `1px solid ${COLORS.lightGray3}`
  },
  dataPanel: {
    position: 'absolute',
    bottom: 42,
    left: 0,
    width: 250,
    height: 40,
    zIndex: 1000,
    textAlign: 'center',
    borderTop: '1px solid white',
    backgroundColor: COLORS.whiteSmoke,
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
    '&:hover': {
      backgroundColor: COLORS.white,
      '& $expButton': {
        opacity: 1
      },
      '& $dataDots': {
        opacity: 0
      }
    }
  },
  expButton: {
    opacity: 0,
    height: 28,
    lineHeight: '28px',
    maxWidth: 240,
    marginTop: 6,
    fontSize: 12,
    color: COLORS.primaryColor
  },
  expIcon: {
    color: COLORS.darkGreen
  },
  dataDots: {
    opacity: 1,
    position: 'absolute',
    top: 8,
    right: 10,
    width: 24,
    height: 24,
    color: 'rgba(0, 0, 0, 0.54)'
  }
}));

const LEFT_PANEL_WIDTH = 250;

const StorageLayoutView = ({
  selectedStorage,
  setSelectedStorage,
  setIsStorageEdited,
  sensorIDsInOtherStorages,
  viewHeight,
  viewWidth
  // isActive,
  // onLayoutChange,
  // onSensorChange,
}) => {
  const i18n = useSelector((store) => store.i18n);
  const bsConf = useSelector((store) => store.bsConf);
  const auxConf = useSelector((store) => store.auxConf);
  const isAdmin = useSelector((store) => store.isAdmin);
  let sensorsData = useSelector((store) => store.sensorsData);
  // const sensorConfigurations = useSelector(store => store.sensorConfigurations);
  // const minmaxData = useSelector((store) => store.minmaxData);
  const prefs = useSelector((store) => store.sessionInfo);
  const isDataAccess = useSelector((store) => store.isSupervisor && viewWidth >= 600);
  // const chartScale = useSelector((store) =>
  //   store.sessionInfo && store.sessionInfo.ui_preferences
  //     ? store.sessionInfo.ui_preferences.chart_scale
  //     : null
  // );
  const { state, dispatch } = useContext(StorageContext);
  const dispatchRedux = useDispatch();

  // useFetchSensorList(selectedStorage?.sensor_layout?.sensors);
  // console.log(
  //   'useFetchSensorList',
  //   selectedStorage?.sensor_layout?.sensors,
  //   data
  // );

  const scale =
    prefs && prefs.ui_preferences && prefs.ui_preferences.color_steps
      ? prefs.ui_preferences.color_steps
      : [0, 40, 60, 80, 100].map((a) => Number(a));

  const [tempScale] = useState(scale);

  const [isEditing, setIsEditing] = useState(false);

  if (auxConf) {
    sensorsData = sensorsData.concat(auxConf);
  }
  const classes = useStyles();

  const calculateDepthFromData = (sensorIDsInLayout) =>
    !sensorIDsInLayout || sensorIDsInLayout.length === 0
      ? 1
      : Math.max(
          ...sensorsData
            .filter((meas) => sensorIDsInLayout.indexOf(meas.sensor_id) >= 0)
            .map((m) => (m.temperatures ? m.n || m.temperatures.length - 1 : 1))
        );

  const [visuAngle, setVisuAngle] = useState(
    selectedStorage?.layout_prefs?.angleDefault >= 0
      ? Number(selectedStorage.layout_prefs.angleDefault)
      : selectedStorage?.layoutType === 3
      ? 0
      : 45
  );
  const [visuCellsize, setVisuCellsize] = useState(
    selectedStorage?.layout_prefs?.sizeDefault >= 0
      ? Number(selectedStorage.layout_prefs.sizeDefault)
      : selectedStorage.layoutType === 3
      ? 40
      : 50
  );
  const [visuSpacing, setVisuSpacing] = useState(
    selectedStorage?.layout_prefs?.spaceDefault >= 0
      ? Number(selectedStorage.layout_prefs.spaceDefault)
      : selectedStorage.layoutType === 3
      ? 100
      : 50
  );
  const [selectedSensor, doSetSelectedSensor] = useState(state.selectedLayoutProbe);

  const [alertSensorDepths, setAlertSensorDepths] = useState(
    selectedStorage?.monitor?.rules.map((r) => getAlertSensorDepths(r)).flat()
  );

  const [hoveredSensor, setHoveredSensor] = useState(null);
  const setSelectedSensor = (sensorID) => {
    setHoveredSensor(null);
    doSetSelectedSensor(sensorID);
  };

  const [nLayers, setNlayers] = useState(
    !selectedStorage
      ? 1
      : !selectedStorage.sensor_layout
      ? 1
      : selectedStorage.layoutType === 3
      ? selectedStorage.sensor_layout.rows.length
      : calculateDepthFromData(
          selectedStorage?.sensor_layout.sensors
            .filter((sen) => sen.column >= 0)
            .map((sen) => sen.id)
        )
  );
  const defaultLayout = {
    rows: [
      { index: 0, rowLength: 3 },
      { index: 1, rowLength: 3 },
      { index: 2, rowLength: 3 }
    ],
    sensors: []
  };

  useEffect(() => {
    if (
      !selectedStorage?.sensor_layout &&
      selectedStorage?.layoutType !== 3 &&
      !selectedStorage?.undefinedLayoutType
    ) {
      setSelectedStorage((tempStorage) => ({
        ...tempStorage,
        sensor_layout: {
          // floors: 3,
          rows: [
            { index: 0, rowLength: 3 },
            { index: 1, rowLength: 3 },
            { index: 2, rowLength: 3 }
          ],
          sensors: []
        }
      }));
    }
    // return dispatch({ type: 'SHOW_PREFS', value: false });
  }, [dispatch, selectedStorage, setSelectedStorage]);

  const theLayout = selectedStorage?.sensor_layout || defaultLayout;

  const [showLeftPanel, setShowLeftPanel] = useState(true);
  // const [sensorsChanged, setSensorsChanged] = useState(false);

  const toggleLeftPanel = () => {
    setShowLeftPanel(!showLeftPanel);
    // dispatch({ type: 'SHOW_LAYOUT_PANEL', value: !showLeftPanel });
  };

  const onLayoutChange = (layoutRows, floors) => {
    if (floors) {
      // Stack storage with floors
      setSelectedStorage((tempStorage) => ({
        ...tempStorage,
        sensor_layout: {
          ...tempStorage?.sensor_layout,
          rows: layoutRows,
          floors
        }
      }));
    } else {
      setSelectedStorage((tempStorage) => ({
        ...tempStorage,

        sensor_layout: {
          ...tempStorage?.sensor_layout,
          rows: layoutRows
        }
      }));
    }
    dispatchRedux({ type: 'SET_EDIT_CONFIRM_NEEDED', value: true });
    setIsStorageEdited(true); // this call will cause re-rendering and losing IsEditing state
    // theLayout.rows = layoutRows;
  };
  const onSensorChange = (layoutSensors) => {
    setSelectedStorage((tempStorage) => ({
      ...tempStorage,
      sensor_layout: {
        ...tempStorage?.sensor_layout,
        sensors: layoutSensors
      }
    }));
    dispatchRedux({ type: 'SET_EDIT_CONFIRM_NEEDED', value: true });
    setIsStorageEdited(true); // this call will cause re-rendering and losing IsEditing state
    // theLayout.sensors = layoutSensors;
  };

  const layoutApplied = () => {
    // This dispatch will cause crash from dnd. To be examined.
    // "Cannot have two MultiBackends at the same time"
    // dispatch({ type: 'EDITING_LAYOUT', value: !isEditing });
    if (!isEditing) {
      //   console.log('entered isEditing before setState');
      //   // setSelectedStorage({
      //   //   ...selectedStorage,
      //   //   sensor_layout: theLayout
      //   // });
      //   // setIsStorageEdited(true); // this call will cause re-rendering and losing IsEditing state
      //   // dispatchRedux({ type: 'SET_EDIT_CONFIRM_NEEDED', value: true });

      //   // if (sensorsChanged) {
      //   //   console.log('sensorsChanged, call processStorageMonitors');
      //   //   processStorageMonitors({ BS_ID: bsConf.bs_id, UID: ..., itemIDs: [selectedStorage.itemID]});
      //   //   setSensorsChanged(false);
      //   // }
      // } else {
      dispatch({ type: 'PROBE_SELECTED', value: null });
      setSelectedSensor(null); // deselect when editing
      // scroll layout to top when editing starts
      document.getElementById('storageLayoutPanel').scrollTop = 0;
    }

    if (selectedStorage?.layoutType === 3) {
      setNlayers(theLayout.rows.length);
    } else {
      const depth =
        theLayout.floors ||
        calculateDepthFromData(
          theLayout.sensors.filter((sen) => sen.column >= 0).map((sen) => sen.id)
        );
      setNlayers(depth);
    }
    setIsEditing(!isEditing);
    // dispatch({ type: 'EDITING_LAYOUT', value: !isEditing });
  };

  // const onSensorClicked = (sensorId) => {
  //   // open left panel if sensor selected and panel is off
  //   if (!selectedSensor && !showLeftPanel) toggleLeftPanel();

  //   // Select new sensor or if already selected, then toggle deselected
  //   dispatch({ type: 'PROBE_SELECTED', value: selectedSensor === sensorId ? null : sensorId });
  //   setSelectedSensor(selectedSensor === sensorId ? null : sensorId);
  //   dispatch({ type: 'SHOW_PREFS', value: false });
  // };

  // const onAngleChanged = value => {
  //   // dispatch({ type: 'VISU_ANGLE', value });
  //   setVisuAngle(value);
  // };
  // const onSizeChanged = value => {
  //   // dispatch({ type: 'VISU_SIZE', value });
  //   setVisuCellsize(value);
  // };
  // const onSpaceChanged = value => {
  //   // dispatch({ type: 'VISU_SPACING', value });
  //   setVisuSpacing(value);
  // };
  const onToggleSettings = () => {
    dispatch({ type: 'SHOW_PREFS', value: !state.showStoragePrefs });
  };

  const onSensorHover = (sensorID, hoverOn) => {
    if (hoverOn) {
      setHoveredSensor(sensorID);
    } else {
      setHoveredSensor(null);
    }
  };

  // const [scaleMin, scaleMax] = getRangeFromData(
  //   theLayout.sensors.filter((sen) => sen.column >= 0).map((sen) => sen.id),
  //   minmaxData,
  //   chartScale
  // );

  const [alertSensorIDs, setAlertSensorIDs] = useState(
    selectedStorage.monitor?.rules
      .map((r) => r.currentAlerts)
      .flat(1)
      .filter((al) => !!al)
      .map((al) => al.sensor_id)
  );

  useEffect(() => {
    setAlertSensorIDs(
      selectedStorage?.monitor?.rules
        .map((r) => r.currentAlerts)
        .flat(1)
        .filter((al) => !!al)
        .map((al) => al.sensor_id)
    );
    setAlertSensorDepths(
      selectedStorage?.monitor?.rules?.map((r) => getAlertSensorDepths(r)).flat()
    );
  }, [selectedStorage.monitor]);

  const [outdoorID, setOutdoorID] = useState(null);
  const [outdoorIDAdded, setOutdoorIDAdded] = useState(false);

  // Export storage probes data
  const [loadingExport, setLoadingExport] = useState(false);

  const exportPressed = () => {
    setLoadingExport(true);
  };

  useEffect(() => {
    if (selectedStorage.monitor?.rules) {
      setOutdoorID(
        selectedStorage.monitor?.rules?.find((rule) => rule.type === 'COOLDOWN_CONTROL')?.settings
          ?.outdoorID
      );
      setOutdoorIDAdded(true);
    }
  }, [selectedStorage.monitor]);

  const noProbesInfo = (
    <div className={classes.noSensors}>
      <div className={classes.noSensorsTitle}>
        {i18n.empty_storage_title || 'No probes in the storage'}
      </div>
      <div className={classes.noSensorsContent}>
        <div className={classes.noSensorsIconDiv}>
          <Icon className={classes.noSensorsIcon} icon={pencilIcon} />
        </div>
        <div className={classes.noSensorsText}>
          {i18n.edit_storage_info ||
            'Press EDIT button to adjust storage layout and to place probes into the layout.'}
        </div>
        <div className={classes.arrow}>
          <Icon icon={arrowRightThick} width={40} height={40} />
        </div>
      </div>
    </div>
  );

  return (
    <div className={classes.layoutviewWrapper}>
      <LayoutMenubar
        isAdmin={isAdmin}
        isEditing={isEditing}
        showControlsButton={!isEditing}
        i18n={i18n}
        visuAngle={visuAngle}
        maxAngle={selectedStorage?.layoutType === 3 ? 40 : 60}
        minCellSize={selectedStorage?.layoutType === 3 ? 25 : 35}
        visuCellsize={visuCellsize}
        visuSpacing={visuSpacing}
        setVisuAngle={setVisuAngle}
        setVisuCellsize={setVisuCellsize}
        setVisuSpacing={setVisuSpacing}
        nLayers={
          selectedStorage?.layoutType === 3
            ? (selectedStorage.sensor_layout || defaultLayout).rows.length
            : nLayers
        }
        onEditButtonClick={layoutApplied}
        // onSettingsButtonClick={layoutApplied}
        onToggleSettings={onToggleSettings}
        showVisuSettings={state.showStoragePrefs}
        // onAngleChanged={onAngleChanged}
        // onSizeChanged={onSizeChanged}
        // onSpaceChanged={onSpaceChanged}
        // visuSpacing={visuSpacing}
        showLeftPanel={showLeftPanel}
        toggleLeftPanel={toggleLeftPanel}
        setIsStorageEdited={setIsStorageEdited}
        selectedStorage={selectedStorage}
        setSelectedStorage={setSelectedStorage}
      />
      {
        <div>
          {isEditing ? null : (
            <div
              className={classes.leftPanelWrapper}
              style={{ width: LEFT_PANEL_WIDTH, left: showLeftPanel ? 0 : -LEFT_PANEL_WIDTH }}
            >
              <div
                className={classes.sensorListPanel}
                style={{ height: isDataAccess ? 'calc(100% - 80px)' : 'calc(100% - 40px)' }}
              >
                {!theLayout.sensors || isEmpty(theLayout.sensors) ? (
                  noProbesInfo
                ) : (
                  <BasicSensorList
                    sensorIDs={theLayout.sensors
                      .map((s) => s.id)
                      .concat(outdoorID)
                      .filter(Boolean)}
                    showMultipoint
                    dataSnapshot={sensorsData}
                    bsConf={bsConf}
                    auxConf={auxConf}
                    selectedSensor={null}
                    setSelectedSensor={(id) => setSelectedSensor(id)}
                    alertSensorIDs={alertSensorIDs}
                    marginRight={alertSensorIDs && alertSensorIDs.length > 0 ? 14 : 5}
                    alertSensorDepths={alertSensorDepths}
                    showHandleBar={prefs.ui_preferences.handle_temp_enabled === 'true'}
                    onSensorHover={onSensorHover}
                    hoveredSensor={hoveredSensor}
                    outdoorIDS={[outdoorID]}
                    // batches={batches}
                    // storages={storages}
                    outdoorIDAdded={outdoorIDAdded}
                    setOutdoorIDAdded={setOutdoorIDAdded}
                    selectedStorage={selectedStorage}
                    bottomSpace={isDataAccess ? 40 : 0}
                    inStorage
                  />
                )}
                {isDataAccess && (
                  <div className={classes.dataPanel}>
                    <Button
                      startIcon={<Icon className={classes.expIcon} icon={microsoftExcel} />}
                      variant='outlined'
                      onClick={() => exportPressed()}
                      className={classes.expButton}
                    >
                      {i18n.storage_data_download}
                    </Button>
                    <Icon className={classes.dataDots} icon={dotsVertical} />
                    {loadingExport ? (
                      <StorageDataLoader
                        storage={selectedStorage}
                        setLoadingExport={setLoadingExport}
                        sensorIDs={selectedStorage?.sensor_layout.sensors
                          .filter((sen) => sen.column >= 0)
                          .map((sen) => sen.id)
                          .sort((a, b) => (a < b ? -1 : 1))}
                      />
                    ) : null}
                  </div>
                )}
              </div>
            </div>
          )}
          {selectedStorage.layoutType === 3 ? (
            <StorageStackLayout
              viewHeight={viewHeight}
              theLayout={theLayout}
              data={sensorsData}
              angle={isEditing ? 0 : visuAngle}
              cellWidth={isEditing ? 40 : visuCellsize}
              spacingFactor={isEditing ? 100 : visuSpacing}
              bsConf={bsConf}
              auxConf={auxConf}
              isEditing={isEditing}
              isActive
              onLayoutChange={onLayoutChange}
              onSensorChange={onSensorChange}
              // onSensorClicked={onSensorClicked}
              // createLayout={createLayout}
              alertSensorDepths={alertSensorDepths}
              tempScale={tempScale}
              selectedSensor={selectedSensor}
              onSensorHover={selectedSensor ? null : onSensorHover}
              hoveredSensor={hoveredSensor}
              leftPadding={showLeftPanel && !isEditing ? LEFT_PANEL_WIDTH : 0}
              setIsStorageEdited={setIsStorageEdited}
              sensorIDsInOtherStorages={sensorIDsInOtherStorages}
              storageType={selectedStorage.layoutType}
            />
          ) : (
            <StorageLayout
              viewHeight={viewHeight}
              theLayout={theLayout}
              data={sensorsData}
              angle={isEditing ? 0 : visuAngle}
              cellWidth={isEditing ? 45 : visuCellsize}
              spacingFactor={visuSpacing}
              bsConf={bsConf}
              auxConf={auxConf}
              isEditing={isEditing}
              isActive
              onLayoutChange={onLayoutChange}
              onSensorChange={onSensorChange}
              // onSensorClicked={onSensorClicked}
              // createLayout={createLayout}
              alertSensorDepths={alertSensorDepths}
              tempScale={tempScale}
              selectedSensor={selectedSensor}
              onSensorHover={selectedSensor ? null : onSensorHover}
              hoveredSensor={hoveredSensor}
              leftPadding={showLeftPanel ? LEFT_PANEL_WIDTH : 0}
              setIsStorageEdited={setIsStorageEdited}
              sensorIDsInOtherStorages={sensorIDsInOtherStorages}
            />
          )}
        </div>
      }
    </div>
  );
};

export default StorageLayoutView;
