import { SET_TABCONFIG } from 'redux/constants';
import initialState from 'redux/store/initialState';

export default function setTabConfig(state = initialState.tabConfig, action) {
  switch (action.type) {
    case SET_TABCONFIG: {
      return {
        ...state,
        ...action.value
      };
    }
    default: {
      return state;
    }
  }
}
