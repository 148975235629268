import {
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Toolbar,
  Typography,
  IconButton,
  Button,
  useMediaQuery
} from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { Icon } from '@iconify/react';

// ICONS
import { MdClose, MdSettings, MdAccountBox, MdPerson, MdLandscape } from 'react-icons/md';
import googleTranslate from '@iconify/icons-mdi/google-translate';
import warehouseIcon from '@iconify/icons-mdi/warehouse';
import shieldAccount from '@iconify/icons-mdi/shield-account';
import bookOpenVariant from '@iconify/icons-mdi/book-open-variant';
import nail from '@iconify/icons-mdi/nail';

import { COLORS } from 'utils/colors';
import { ADMIN_GROUPS } from 'utils/sharedHelper';

// SVG
import QuanturiSVG from 'assets/svg/QuanturiSVG';
import QuanturiPremiumSVG from 'assets/svg/QuanturiPremiumSVG';

import { useStyles } from './MainNavigator.styled';

import packageJson from '../../../package.json';

const MenuHeader = ({ toggleDrawer }) => {
  const classes = useStyles();
  const { i18n, isSupervisorCompost } = useSelector((store) => ({
    i18n: store.i18n,
    isSupervisorCompost: store.isSupervisorCompost
  }));
  const isXsWidth = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  return (
    <Toolbar className={classes.closeContainer}>
      {(isXsWidth || isSupervisorCompost) && (
        <QuanturiSVG
          color={COLORS.primaryColor}
          betaColor='rgba(0,0,0,0)'
          style={{
            width: 150,
            marginTop: 12,
            marginRight: 15
          }}
        />
      )}

      {!isXsWidth && <Typography style={{ fontSize: 20 }}>{i18n.menu}</Typography>}

      <div className={classes.grow} />
      <IconButton color='inherit' aria-label={i18n.close} onClick={() => toggleDrawer(true)}>
        <MdClose size='1.5em' />
      </IconButton>
    </Toolbar>
  );
};

const MenuItem = ({ to, translateKey, openManual, children }) => {
  const i18n = useSelector((state) => state.i18n);
  const currentView = useSelector((state) => state.currentView);
  const editConfirmNeeded = useSelector((state) => state.editConfirmNeeded);
  const dispatch = useDispatch();

  if (to === 'instructions') {
    return (
      <ListItem button onClick={() => openManual()}>
        <ListItemIcon style={{ color: COLORS.silverSonic }}>
          <Icon width={40} height={35} icon={bookOpenVariant} />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography variant='body1' style={{ color: COLORS.primaryColor }}>
              {i18n.instructions}
            </Typography>
          }
        />
      </ListItem>
    );
  }
  return (
    <ListItem
      onClick={() => editConfirmNeeded && dispatch({ type: 'SET_UNSAVED_CHANGES', value: true })}
      button
      component={!editConfirmNeeded ? Link : 'div'}
      to={!editConfirmNeeded ? `/${to}` : ''}
    >
      {to === 'premium' ? (
        <ListItemIcon>
          <QuanturiPremiumSVG
            fillMode='color'
            color={currentView === 'premium' ? COLORS.secondaryColor : COLORS.silverSonic}
            style={{
              width: 40,
              height: 40,
              marginTop: 4,
              marginBottom: -4
            }}
          />
        </ListItemIcon>
      ) : (
        <ListItemIcon
          style={{ color: currentView === to ? COLORS.secondaryColor : COLORS.silverSonic }}
        >
          {children}
        </ListItemIcon>
      )}

      <ListItemText
        primary={
          <div>
            <Typography
              variant='body1'
              style={{
                display: 'inline-block',
                color: currentView === to ? COLORS.secondaryColor : COLORS.primaryColor
              }}
            >
              {i18n[translateKey || to] || 'Admin'}
            </Typography>
          </div>
        }
      />
    </ListItem>
  );
};

const MenuFooter = ({ adminUsername, logout }) => {
  const classes = useStyles();
  const i18n = useSelector((state) => state.i18n);
  const isAdmin = useSelector((state) => state.isAdmin);
  const sessionInfo = useSelector((state) => state.sessionInfo);

  return (
    <List className={classes.drawerFooter}>
      <ListItem>
        <ListItemIcon style={{ color: COLORS.secondaryColor }}>
          <MdPerson size='1.5em' />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography style={{ fontSize: 16, marginLeft: -20 }}>
              {isAdmin
                ? `${adminUsername} (${
                    ADMIN_GROUPS.find((gr) => gr.value === sessionInfo?.admin_group)?.label
                  })`
                : sessionInfo
                ? sessionInfo.username
                : ''}
            </Typography>
          }
        />
        <ListItemSecondaryAction>
          <Button className={classes.drawerButton} onClick={logout}>
            {i18n.logout}
          </Button>
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem>
        <ListItemSecondaryAction className={classes.drawerVersion}>
          <Typography variant='caption'>{`v${packageJson.version}`}</Typography>
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );
};

const DrawerPopup = ({ drawer, adminUsername, toggleDrawer, openManual, logout }) => {
  const classes = useStyles();
  const isAdmin = useSelector((state) => state.isAdmin);
  const isSupervisor = useSelector((state) => state.isSupervisor);
  const isSupervisorCompost = useSelector((state) => state.isSupervisorCompost);
  const insightService = useSelector((state) => state.insightService);
  const isPremium = useSelector((state) => state.isPremium);
  const isGariUser = useSelector((state) => state.isGariUser);
  const userInfo = useSelector((state) => state.userInfo);
  const expiredPlan = userInfo?.plans && userInfo?.plans[0]?.expired;
  const storageAuth =
    !isGariUser &&
    (insightService || isSupervisor || (isPremium && !expiredPlan)) &&
    !isSupervisorCompost;

  return (
    <Drawer
      anchor='right'
      open={drawer}
      onClose={() => toggleDrawer(false)}
      classes={{ paper: classes.drawerPaper }}
    >
      <div
        tabIndex={0}
        role='button'
        onClick={() => toggleDrawer(false)}
        onKeyDown={() => toggleDrawer(false)}
      >
        <div className={classes.drawerList}>
          <MenuHeader toggleDrawer={toggleDrawer} />
          <Divider />
          <List className={classes.drawerListContainer}>
            {userInfo?.translator && (
              <MenuItem to='translations'>
                <Icon width={40} height={40} icon={googleTranslate} />
              </MenuItem>
            )}

            {isAdmin && (
              <MenuItem to='admin'>
                <Icon width={40} height={40} icon={shieldAccount} />
              </MenuItem>
            )}
            {isSupervisorCompost && (
              <MenuItem to='batches'>
                <MdLandscape size={40} />
              </MenuItem>
            )}
            {storageAuth && (
              <MenuItem to='storages'>
                <Icon width={40} height={40} icon={warehouseIcon} />
              </MenuItem>
            )}
            <MenuItem to='probes'>
              <Icon width={40} height={40} icon={nail} />
            </MenuItem>

            <MenuItem to='settings'>
              <MdSettings size={40} />
            </MenuItem>
            {!isGariUser && (
              <MenuItem to='account'>
                <MdAccountBox size={40} />
              </MenuItem>
            )}
            <Divider style={{ marginTop: 8, marginBottom: 8 }} />
            <MenuItem to='instructions' openManual={openManual} />
          </List>
          <MenuFooter adminUsername={adminUsername} logout={logout} />
        </div>
      </div>
    </Drawer>
  );
};

export default DrawerPopup;
