import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  ListItem,
  ListItemText
} from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postSensorConf, getBsConf } from 'api/serverAPI';

import { useConfirm } from 'hook/useConfirm';
import adminChangesConfirm from 'utils/adminChangesConfirm';
import { _convertBastVerToType } from 'navigation/MainNavigator/helper';
import { useStyles } from '../../../settings/Settings.styled';
import { DefaultSlider, MeasIntervalSlider } from '../../../settings/helper';

export const ListItemWithSlider = ({
  i18n,
  id,
  state,
  setState,
  intervalValues,
  range,
  sensorID,
  bastVersion
}) => {
  const classes = useStyles();

  const handleClick = () => {
    setState((tempState) => ({ ...tempState, [id]: { ...tempState[id], open: !state[id].open } }));
  };

  return (
    <>
      <ListItem button className={classes.tempContainer} onClick={handleClick}>
        <ListItemText
          primary={
            <Typography variant='subtitle1' className={classes.tempTitle}>
              {i18n[id] || state[id].title}
            </Typography>
          }
          secondary={
            <Typography component='div' className={classes.sliderContainer}>
              {id === 'receiving_time' ? (
                <DefaultSlider
                  value={+state[id].value}
                  min={state[id].range[0]}
                  max={state[id].range[1]}
                  defaultValue='3'
                />
              ) : (
                <MeasIntervalSlider
                  value={+state[id].value}
                  i18n={i18n}
                  snap={!!intervalValues}
                  range={range}
                  disabled
                />
              )}
            </Typography>
          }
        />
      </ListItem>
      <SensorSettingsSliderDialog
        i18n={i18n}
        id={id}
        state={state}
        setState={setState}
        intervalValues={intervalValues}
        range={range}
        sensorID={sensorID}
        bastVersion={bastVersion}
      />
    </>
  );
};

export const SensorSettingsSliderDialog = ({
  i18n,
  id,
  state,
  setState,
  intervalValues,
  range,
  sensorID,
  bastVersion
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const confirm = useConfirm();
  const isAdmin = useSelector((store) => store.isAdmin);
  const sessionInfo = useSelector((store) => store.sessionInfo);
  const isDemoMode = useSelector((store) => store.isDemoMode);
  const bsConf = useSelector((store) => store.bsConf);
  const [val, setVal] = useState(state[id].value);

  const handleClick = () => {
    setState((tempState) => ({ ...tempState, [id]: { ...tempState[id], open: !state[id].open } }));
  };

  const saveSettingsSlider = async (newValue) => {
    const sensorObjectToUpdate = bsConf.sensor_configurations.find(
      (s) => s.sensor_id_sys === sensorID
    );
    let sensorConfiguration;
    if (id === 'receiving_time' && newValue === 3) {
      delete sensorObjectToUpdate.receiving_time;
      sensorConfiguration = {
        sensorConfigurations: [{ ...sensorObjectToUpdate }]
      };
    } else if (id === 'measurement_interval' && newValue === 0) {
      delete sensorObjectToUpdate.measurement_interval;
      delete sensorObjectToUpdate.measurement_interval_0;
      delete sensorObjectToUpdate.measurement_interval_1;
      delete sensorObjectToUpdate.measurement_interval_2;
      sensorConfiguration = {
        sensorConfigurations: [{ ...sensorObjectToUpdate }]
      };
    } else if (id === 'measurement_interval' && _convertBastVerToType(bastVersion) < 3) {
      sensorConfiguration = {
        sensorConfigurations: [
          {
            ...sensorObjectToUpdate,
            // measurement_interval: newValue * 60,
            measurement_interval_0: newValue * 60,
            measurement_interval_1: newValue * 60,
            measurement_interval_2: newValue * 60
          }
        ]
      };
    } else {
      sensorConfiguration = {
        sensorConfigurations: [
          {
            ...sensorObjectToUpdate,
            [id]: id === 'measurement_interval' ? newValue * 60 : newValue
          }
        ]
      };
    }

    const dispatchHandler = (newConf) => {
      dispatch({ type: 'SET_BSCONF', value: newConf });
      dispatch({ type: 'SET_SENSORCONF', value: newConf.sensor_configurations });
    };
    let newConfData = bsConf.conf_data;

    try {
      if (isDemoMode && !isAdmin) {
        const logic = (obj) =>
          sensorConfiguration.sensorConfigurations.find(
            (o) => o.sensor_id_sys === obj.sensor_id_sys
          );

        newConfData = newConfData.map((obj) =>
          logic(obj)
            ? {
                ...obj,
                ...logic(obj)
              }
            : obj
        );
        dispatchHandler(newConfData);
      } else {
        const realBs = sensorConfiguration.sensorConfigurations?.find(
          (s) => s.sensor_id_sys === sensorID
        )?.realBs;
        await postSensorConf({ basestationID: realBs || bsConf.bs_id }, sensorConfiguration);
        const response = await getBsConf({ basestationID: bsConf.bs_id });
        console.log('res', response);
        newConfData = response.conf_data;
        if (response.status_code === 0) {
          dispatchHandler(newConfData);
        }
      }
    } catch (err) {
      console.log('err: ', err);
    }
  };

  const onSave = async () => {
    await adminChangesConfirm(isAdmin, confirm, state[id].title, sessionInfo.username);
    saveSettingsSlider(val, id);
    handleClick();
  };

  return (
    <Dialog open={state[id].open} fullWidth aria-labelledby='responsive-dialog-title'>
      <DialogTitle id={id}>
        <div>{i18n[id] || state[id].title}</div>
      </DialogTitle>
      <DialogContent className={classes.dialogContentSlider} elevation={0}>
        <div id='slider_temp_container'>
          {id === 'receiving_time' ? (
            <DefaultSlider
              value={val}
              min={state[id].range[0]}
              max={state[id].range[1]}
              onChange={(e, newValue) => setVal(newValue)}
              defaultValue='3'
            />
          ) : (
            <MeasIntervalSlider
              value={val}
              i18n={i18n}
              snap={!!intervalValues}
              range={range}
              inDialog
              onChange={(e) => setVal(parseInt(e))}
            />
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClick} color='primary'>
          {i18n.cancel}
        </Button>
        <Button onClick={onSave} color='secondary'>
          {i18n.ok}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
