import { isUndefined, isObject } from 'lodash';

export default class VertoStorage {
  constructor() {
    this.valuesMap = new Map();
  }

  getItem(key) {
    const stringKey = String(key);
    if (this.valuesMap.has(key)) {
      try {
        return JSON.parse(this.valuesMap.get(stringKey));
      } catch (error) {
        return this.valuesMap.get(stringKey);
      }
    }
    return null;
  }

  setItem(key, val) {
    const value1 = isUndefined(val) ? null : val;
    const value = isObject(value1) ? JSON.stringify(value1) : value1;
    this.valuesMap.set(String(key), value);
  }

  removeItem(key) {
    this.valuesMap.delete(key);
  }

  clear() {
    this.valuesMap.clear();
  }

  get length() {
    return this.valuesMap.size;
  }
}
