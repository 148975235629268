import React from 'react';
import { format, intervalToDuration } from 'date-fns';
import { COLORS } from 'utils/colors';
import classes from './SpecialBSTooltip.module.css';

const SpecialTooltip = (props) => {
  const { datum, x, y, data, activePoints } = props;
  const dataX = datum.x;
  const dataY = datum.y;

  let colorTooltip;
  let text;
  let lastTMS;
  let comm;
  let meas;
  let beat;
  let len;
  let durationSinceLastTMS;

  const dataType = () => {
    switch (activePoints[0].childName) {
      case 'Data values':
        lastTMS = data.lastTS && data.lastTS[data.lastTS.findIndex((v) => v === dataX) - 1];
        if (lastTMS) {
          durationSinceLastTMS = intervalToDuration({
            start: Math.min(dataX, lastTMS),
            end: Math.max(dataX, lastTMS)
          });
        }
        colorTooltip = COLORS.secondaryColor;
        len = data.dataValues.find((v) => v.x === dataX).length;
        text = `↑ [${len}] ${+len === 0 ? 'Heartbeat' : 'Data'}:${+dataY.toFixed(1)} kB`;
        break;
      case 'Heartbeat values':
        lastTMS = data.lastTS && data.lastTS[data.lastTS.findIndex((v) => v === dataX) - 1];
        if (lastTMS) {
          durationSinceLastTMS = intervalToDuration({
            start: Math.min(dataX, lastTMS),
            end: Math.max(dataX, lastTMS)
          });
        }
        colorTooltip = COLORS.silverSonic;
        len = data.beatValues.find((v) => v.x === dataX).length;
        text = '♡ Heartbeat';
        break;
      case 'Boot values':
        lastTMS = data.lastTS && data.lastTS[data.lastTS.findIndex((v) => v === dataX) - 1];
        if (lastTMS) {
          durationSinceLastTMS = intervalToDuration({
            start: Math.min(dataX, lastTMS),
            end: Math.max(dataX, lastTMS)
          });
        }

        comm = data.bootValues.find((v) => v.x === dataX)?.conf?.comm;
        meas = data.bootValues.find((v) => v.x === dataX)?.conf?.meas0;
        beat = data.confValues.find((v) => v.x === dataX)?.conf?.beat;
        colorTooltip = 'rgb(246, 77, 175)';
        text = (
          <>
            <span>↓ Boot Conf:{+dataY.toFixed(1)} kB</span>
            <br />
            <span className={classes.tooltipSubInfo}>
              Comm: {comm} {beat ? ' ♡' : ''} | Meas: {meas}
            </span>
          </>
        );
        break;
      case 'Conf values':
        lastTMS = data.lastTS && data.lastTS[data.lastTS.findIndex((v) => v === dataX) - 1];
        if (lastTMS) {
          durationSinceLastTMS = intervalToDuration({
            start: Math.min(dataX, lastTMS),
            end: Math.max(dataX, lastTMS)
          });
        }
        comm = data.confValues.find((v) => v.x === dataX).conf.comm;
        meas = data.confValues.find((v) => v.x === dataX).conf.meas0;
        beat = data.confValues.find((v) => v.x === dataX).conf.beat;
        colorTooltip = 'rgb(34, 113, 111)';
        text = (
          <>
            <span>↓ Conf data:{+dataY.toFixed(1)} kB</span>
            <br />
            <span className={classes.tooltipSubInfo}>
              Comm: {comm} {beat ? ' ♡' : ''} | Meas: {meas}
            </span>
          </>
        );
        break;
      default:
        break;
    }
  };
  dataType();

  return (
    <svg style={{ pointerEvents: 'none' }}>
      <foreignObject
        x={x - 45}
        y={Math.max(-10, y > 145 ? 85 : y - 55)}
        width='500px'
        height='100%'
      >
        <div xmlns='http://www.w3.org/1999/xhtml' className={classes.tooltip}>
          <div className={classes.tooltipDateTime}>
            <div className={classes.tooltipDate}>{format(dataX, 'd.M. HH:mm:ss')}</div>
            <div className={classes.tooltipTime}>{`${
              durationSinceLastTMS
                ? `↤ ${durationSinceLastTMS.minutes}m:${durationSinceLastTMS.seconds}s`
                : ''
            }`}</div>
          </div>
          <div style={{ color: colorTooltip }} className={classes.tooltipInfo}>
            {text}
          </div>
        </div>
      </foreignObject>
    </svg>
  );
};

export default SpecialTooltip;
