import { combineReducers } from 'redux';
import setAdmin from './setAdmin';
import setBsConfig from './setBsConfig';
import setAuxConfig from './setAuxConfig';
import setGroupDetails from './setGroupDetails';
import setLanguage, { setSupportedLanguages } from './setLanguage';
import setLayouts from './setLayouts';
import setStorages from './setStorages';
import setBatches from './setBatches';
import setSocketUpdates from './setSocketUpdates';
import setBatchDetails from './setBatchDetails';
import setLoading from './setLoading';
import setLogin from './setLogin';
import setNotifications from './setNotifications';
import setPremium from './setPremium';
import setSimExpired from './setSimExpired';
import setPlanExpired from './setPlanExpired';
import setPremiumCongrats from './setPremiumCongrats';
import setSensorClickHandler from './setSensorClickHandler';
import setSensorConf from './setSensorConf';
import setSensorDetails from './setSensorDetails';
import setSensorGroups from './setSensorGroups';
import setSensorList from './setSensorList';
import setSensorsData from './setSensorsData';
import setMinmaxData from './setMinmaxData';
import setSensorTooltip from './setSensorTooltip';
import setSessionInfo from './setSessionInfo';
import setSupervisorCompost from './setSupervisorCompost';
import setSupervisor from './setSupervisor';
import setUserInfo from './setUserInfo';
import setViewClickHandler from './setViewClickHandler';
import setViewMode from './setViewMode';
import toggleView from './toggleView';
import setChartData from './setChartData';
import setChartConfig from './setChartConfig';
import setDataTable from './setDataTable';
import setDataTableConfig from './setDataTableConfig';
import setTabConfig from './setTabConfig';
import setBaseSation from './setBaseStation';
import setBaseStations from './setBaseStations';
import setAdminBS from './setAdminBS';
import setPreviousUpdateTS from './setPreviousUpdateTS';
import setEditConfirmNeeded from './setEditConfirmNeeded';
import setUnsavedChanges from './setUnsavedChanges';
import setFcStatus from './setFcStatus';
import setShowHandle from './setShowHandle';
import setSensorSorting from './setSensorSorting';
import setGariUser from './setGariUser';
import setDemoMode from './setDemoMode';
import setSurveyAnswers from './setSurveyAnswers';
import setInsightService from './setInsight';
import setAdminSelectedUser from './setAdminSelectedUser';

const rootReducer = combineReducers({
  isAdmin: setAdmin,
  baseStation: setBaseSation,
  baseStations: setBaseStations,
  adminBS: setAdminBS,
  adminSelectedUser: setAdminSelectedUser,
  bsConf: setBsConfig,
  auxConf: setAuxConfig,
  chartData: setChartData,
  chartConfig: setChartConfig,
  dataTable: setDataTable,
  dataTableConfig: setDataTableConfig,
  tabConfig: setTabConfig,
  groupDetails: setGroupDetails,
  i18n: setLanguage,
  supportedLanguages: setSupportedLanguages,
  layouts: setLayouts,
  batches: setBatches,
  storages: setStorages,
  socketUpdates: setSocketUpdates,
  batchDetails: setBatchDetails,
  isLoading: setLoading,
  isLoggedIn: setLogin,
  notifications: setNotifications,
  isPremium: setPremium,
  insightService: setInsightService,
  isSimExpired: setSimExpired,
  isPlanExpired: setPlanExpired,
  premiumCongrats: setPremiumCongrats,
  sensorClickHandler: setSensorClickHandler,
  sensorConfigurations: setSensorConf,
  sensorDetails: setSensorDetails,
  sensorGroups: setSensorGroups,
  sensorList: setSensorList,
  sensorsData: setSensorsData,
  minmaxData: setMinmaxData,
  sensorTooltip: setSensorTooltip,
  sessionInfo: setSessionInfo,
  isSupervisorCompost: setSupervisorCompost,
  isSupervisor: setSupervisor,
  userInfo: setUserInfo,
  viewClickHandler: setViewClickHandler,
  viewMode: setViewMode,
  currentView: toggleView,
  previousUpdateTS: setPreviousUpdateTS,
  editConfirmNeeded: setEditConfirmNeeded,
  unsavedChanges: setUnsavedChanges,
  FcStatus: setFcStatus,
  showHandle: setShowHandle,
  sensorSorting: setSensorSorting,
  isGariUser: setGariUser,
  isDemoMode: setDemoMode,
  surveyAnswers: setSurveyAnswers
});

export default rootReducer;
