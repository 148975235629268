import { Grow, Dialog, useMediaQuery, Paper } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { isFuture } from 'date-fns';
import { stableSort, getComparator } from 'utils/sorting';
import { getDateFromStr } from 'utils/getDateFromStr';
import useFetchSensorList from '../useFetchSensorList';

import { useStyles } from './SensorDetails.styled';
import { getSensorDetails, updateSensorState } from './helper';
import SensorDetailsToolbar from './SensorDetailsToolbar';
import SensorTabView from '../sensor-tabs/SensorTabView';

const initialState = {
  open: true,
  source: '',
  sensorDetails: null,
  sensorPrevious: null,
  sensorNext: null
};

const SensorDetails = ({ isLayout }) => {
  const location = useLocation();
  useFetchSensorList(location.state?.list || '');
  const classes = useStyles();
  const isXsWidth = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const dispatch = useDispatch();

  const {
    sensorsData,
    sensorConfigurations,
    sensorList,
    auxConf,
    isPremium,
    insightService,
    isSupervisor,
    isSupervisorCompost,
    userInfo,
    isAdmin,
    sensorSorting
  } = useSelector((store) => ({
    sensorsData: store.sensorsData,
    sensorConfigurations: store.sensorConfigurations,
    sensorList: store.sensorList,
    auxConf: store.auxConf,
    isPremium: store.isPremium,
    insightService: store.insightService,
    isSupervisor: store.isSupervisor,
    isSupervisorCompost: store.isSupervisorCompost,
    userInfo: store.userInfo,
    isAdmin: store.isAdmin,
    sensorSorting: store.sensorSorting
  }));

  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (sensorsData && sensorConfigurations) {
      const sensorPath = location.pathname.split('/');
      const id = sensorPath[sensorPath.length - 1];
      const sensorConfig = sensorConfigurations?.find((item) => item.sensor_id_sys === id);
      const auxConfig = auxConf?.find((aux) => aux.a === id);
      if (sensorConfig || auxConfig) {
        const source = sensorPath[sensorPath.length - 3];
        const sensorDetails = getSensorDetails(id, sensorConfig, sensorsData, auxConfig);
        const orderedList = stableSort(
          sensorList,
          getComparator(sensorSorting[1], sensorSorting[0])
        );

        const { previous, next } = updateSensorState(orderedList, sensorDetails);
        dispatch({ type: 'SET_SENSORDETAILS', value: sensorDetails });
        setState({
          open: !!auxConfig || !!sensorConfig,
          source,
          sensorDetails,
          sensorPrevious: previous ? previous.id : null,
          sensorNext: next ? next.id : null
        });
      } else {
        dispatch({ type: 'SET_SENSORDETAILS', value: {} });
      }
    }
  }, [
    sensorList,
    sensorConfigurations,
    sensorsData,
    dispatch,
    location.pathname,
    auxConf,
    sensorSorting
  ]);

  const isLoading = useSelector((store) => store.isLoading);
  const isMdWidth = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const [tabConfig, setTabConfig] = React.useState(0);
  const handleChange = (index) => {
    setTabConfig(index);
  };

  const sensorDetailsAuth =
    isAdmin ||
    insightService ||
    (isPremium && userInfo.plans && userInfo.plans[0] && !userInfo.plans[0].expired);
  // Do not show sensor data to non-premium or to any expired plan
  return sensorDetailsAuth ? (
    <Dialog
      className={classes.mainContainer}
      fullScreen={isXsWidth}
      open={state.open}
      maxWidth='lg'
      fullWidth
      TransitionComponent={Grow}
      aria-labelledby='responsive-dialog-title'
    >
      <SensorDetailsToolbar data={state} isLayout={isLayout} />
      <Paper className={isMdWidth ? classes.sensorTabsDialog : classes.sensorTabs} elevation={0}>
        {!isLoading && (
          <SensorTabView
            selected={tabConfig}
            handleChange={handleChange}
            tableEnabled={isAdmin || isSupervisor || isSupervisorCompost || insightService}
            verifixEnabled={
              userInfo && userInfo.verifix && isFuture(getDateFromStr(userInfo.verifix.exp))
            }
            lifetimeEnabled={isAdmin}
          />
        )}
      </Paper>
    </Dialog>
  ) : null;
};

export default SensorDetails;
