import { Fab, Tooltip } from '@material-ui/core';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Icon } from '@iconify/react';
import thermometer from '@iconify/icons-mdi/thermometer';
import grass from '@iconify/icons-mdi/grass';
import crown from '@iconify/icons-mdi/crown';
import admin from '@iconify/icons-mdi/shield-crown-outline';
import cube from '@iconify/icons-mdi/cube';
import wifiProbe from '@iconify/icons-mdi/wifi-arrow-up';
import wifiBS from '@iconify/icons-mdi/wifi-arrow-down';
import batt from '@iconify/icons-mdi/battery-50';
import closeAdmin from '@iconify/icons-mdi/close-circle';
import measAge from '@iconify/icons-mdi/progress-clock';
import { COLORS } from 'utils/colors';
import * as storageConstants from '../../storage-utils/storageConstants';

const useStyles = makeStyles(() => ({
  buttons: {
    position: 'absolute',
    zIndex: 100,
    top: 10
  },
  modeButton: {
    // height: 'calc(100% - 40px)',
    width: 36,
    height: 36,
    boxSizing: 'content-box',
    color: COLORS.white,
    backgroundColor: COLORS.mediumGray,
    border: `2px solid #ffffff55`,
    '&:hover': {
      borderColor: COLORS.white,
      zIndex: 10
    }
  },
  adminModeButton: {
    width: 25,
    height: 25,
    minHeight: 25,
    borderRadius: 1
  },
  adminCloseButton: {
    width: 20,
    height: 20,
    minHeight: 20
  },
  adminModeIcon: {
    width: 20,
    height: 20
  },
  tempButton: {
    '&:hover': {
      backgroundColor: COLORS.secondaryColor
    }
  },
  qualityButton: {
    borderRadius: '0 10px 10px 0',
    '&:hover': {
      backgroundColor: COLORS.quality2
    }
  },

  tempButton2: {
    borderRadius: '10px 0 0 10px',
    '&:hover': {
      zIndex: '10 !important',
      backgroundColor: COLORS.secondaryColor
    }
  },

  hayButton: {
    '&:hover': {
      backgroundColor: COLORS.haytech
    }
  },
  stackButton: {
    '&:hover': {
      backgroundColor: COLORS.yellowBright,
      color: COLORS.primaryColor
    }
  },
  modeIcon: {
    width: 24,
    height: 24
  },
  tempSelected: {
    backgroundColor: COLORS.secondaryColor,
    borderColor: COLORS.white,
    cursor: 'default',
    zIndex: '5 !important',
    '&:hover': {
      color: COLORS.white,
      zIndex: '10 !important',
      backgroundColor: COLORS.secondaryColor
    }
  },
  qualitySelected: {
    backgroundColor: COLORS.quality2,
    borderColor: COLORS.white,
    zIndex: '5 !important',
    cursor: 'default',
    '&:hover': {
      color: COLORS.white,
      backgroundColor: COLORS.quality2
    }
  },
  haySelected: {
    backgroundColor: COLORS.haytech,
    borderColor: COLORS.white,
    cursor: 'default',
    '&:hover': {
      color: COLORS.white,
      backgroundColor: COLORS.haytech
    }
  },
  stackSelected: {
    backgroundColor: COLORS.yellowBright,
    color: COLORS.primaryColor,
    borderColor: COLORS.white,
    cursor: 'default',
    '&:hover': {
      color: COLORS.primaryColor,
      backgroundColor: COLORS.yellowBright
    }
  }
}));

const StackModeButtons = ({
  buttonsLeft,
  selectedMode,
  setSelectedMode,
  showHay,
  showQuality,
  isAdmin,
  i18n
}) => {
  const onModeSelect = (mode) => {
    if (mode !== selectedMode) {
      setSelectedMode(mode);
    }
  };
  const [showAdminbuttons, setShowAdminbuttons] = useState(isAdmin);
  const classes = useStyles();
  return (
    <div className={classes.buttons} style={{ left: buttonsLeft }}>
      <Tooltip
        title={i18n.temperatures || 'Temperatures'}
        arrow
        placement={showQuality || isAdmin ? 'left' : 'right'}
        disableFocusListener
      >
        <Fab
          className={`${classes.modeButton} ${
            showQuality || isAdmin ? classes.tempButton2 : classes.tempButton
          } ${selectedMode === storageConstants.MODE_TEMP ? classes.tempSelected : ''}`}
          color='primary'
          style={{ position: 'absolute', top: 0 }}
          onClick={() => onModeSelect(storageConstants.MODE_TEMP)}
        >
          <Icon className={classes.modeIcon} icon={thermometer} />
        </Fab>
      </Tooltip>
      {isAdmin && showAdminbuttons && (
        <Tooltip title='ADMIN: Probe signal RSSI' arrow placement='bottom' disableFocusListener>
          <Fab
            className={`${classes.modeButton} ${classes.adminModeButton} ${
              selectedMode === storageConstants.MODE_SIGNAL_SENSOR ? classes.tempSelected : ''
            }`}
            color='primary'
            style={{ position: 'absolute', top: 0, left: 90, borderRadius: '7px 1px 1px 7px' }}
            onClick={() => onModeSelect(storageConstants.MODE_SIGNAL_SENSOR)}
          >
            <Icon className={classes.adminModeIcon} icon={wifiProbe} />
          </Fab>
        </Tooltip>
      )}
      {isAdmin && showAdminbuttons && (
        <Tooltip title='ADMIN: BS signal RSSI' arrow placement='bottom' disableFocusListener>
          <Fab
            className={`${classes.modeButton} ${classes.adminModeButton} ${
              selectedMode === storageConstants.MODE_SIGNAL_BS ? classes.tempSelected : ''
            }`}
            color='primary'
            style={{ position: 'absolute', top: 0, left: 117 }}
            onClick={() => onModeSelect(storageConstants.MODE_SIGNAL_BS)}
          >
            <Icon className={classes.adminModeIcon} icon={wifiBS} />
          </Fab>
        </Tooltip>
      )}
      {isAdmin && showAdminbuttons && (
        <Tooltip title='ADMIN: Battery level' arrow placement='bottom' disableFocusListener>
          <Fab
            className={`${classes.modeButton} ${classes.adminModeButton} ${
              selectedMode === storageConstants.MODE_BATT ? classes.tempSelected : ''
            }`}
            color='primary'
            style={{ position: 'absolute', top: 0, left: 144 }}
            onClick={() => onModeSelect(storageConstants.MODE_BATT)}
          >
            <Icon className={classes.adminModeIcon} icon={batt} />
          </Fab>
        </Tooltip>
      )}
      {isAdmin && showAdminbuttons && (
        <Tooltip title='ADMIN: Data age' arrow placement='bottom' disableFocusListener>
          <Fab
            className={`${classes.modeButton} ${classes.adminModeButton} ${
              selectedMode === storageConstants.MODE_MEAS_AGE ? classes.tempSelected : ''
            }`}
            color='primary'
            style={{ position: 'absolute', top: 0, left: 170, borderRadius: '1px 7px 7px 1px' }}
            onClick={() => onModeSelect(storageConstants.MODE_MEAS_AGE)}
          >
            <Icon className={classes.adminModeIcon} icon={measAge} />
          </Fab>
        </Tooltip>
      )}
      {isAdmin && showAdminbuttons && (
        <Tooltip title='Close ADMIN buttons' arrow placement='right' disableFocusListener>
          <Fab
            className={`${classes.adminCloseButton}`}
            color='primary'
            style={{ position: 'absolute', top: 0, left: 200 }}
            onClick={() => {
              onModeSelect(storageConstants.MODE_TEMP);
              setShowAdminbuttons(false);
            }}
          >
            <Icon className={classes.adminModeIcon} icon={closeAdmin} />
          </Fab>
        </Tooltip>
      )}
      {showQuality || isAdmin ? (
        <Tooltip
          title={`${i18n.hay_quality || 'Hay quality'}${
            !showQuality && isAdmin ? ' - ADMIN ACCESS ONLY' : ''
          }`}
          arrow
          placement='right'
          disableFocusListener
        >
          <Fab
            className={`${classes.modeButton} ${classes.qualityButton} ${
              selectedMode === storageConstants.MODE_QUALITY ? classes.qualitySelected : ''
            }`}
            color='primary'
            style={{ position: 'absolute', top: 0, left: 38 }}
            onClick={() => onModeSelect(storageConstants.MODE_QUALITY)}
          >
            <Icon className={classes.modeIcon} icon={!showQuality && isAdmin ? admin : crown} />
          </Fab>
        </Tooltip>
      ) : null}
      {showHay ? (
        <Tooltip
          title={i18n.hay_management || 'Hay management'}
          arrow
          placement='right'
          disableFocusListener
        >
          <Fab
            className={`${classes.modeButton} ${classes.hayButton} ${
              selectedMode === storageConstants.MODE_HAY ? classes.haySelected : ''
            }`}
            color='primary'
            style={{ position: 'absolute', top: 50 }}
            onClick={() => onModeSelect(storageConstants.MODE_HAY)}
          >
            <Icon className={classes.modeIcon} icon={grass} />
          </Fab>
        </Tooltip>
      ) : null}
      <Tooltip
        title={i18n.stack_editor || 'Stack editor'}
        arrow
        placement='right'
        disableFocusListener
      >
        <Fab
          className={`${classes.modeButton} ${classes.stackButton} ${
            selectedMode === storageConstants.MODE_STACK ? classes.stackSelected : ''
          }`}
          color='primary'
          style={{ position: 'absolute', top: showHay ? 100 : 50 }}
          onClick={() => onModeSelect(storageConstants.MODE_STACK)}
        >
          <Icon className={classes.modeIcon} icon={cube} />
        </Fab>
      </Tooltip>
    </div>
  );
};

export default StackModeButtons;
