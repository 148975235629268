import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
  TableSortLabel,
  TablePagination,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTable, usePagination, useSortBy } from 'react-table';
import { isEmpty } from 'lodash';
import { format } from 'date-fns';
import { COLORS } from 'utils/colors';
import { tempFormat } from 'assets/utils';

import Toolbar from './SensorDataTableToolbar';
import TablePaginationActions from './TablePaginationActions';
import SensorDataTableContext from './SensorDataTableContext';

import { useStyles } from './SensorData.styled';
import { checkProbeType } from '../sensor-chart/sensor-chart-type/helpers';

/**
 * @todo Split Header, Body, Cell, Footer of Table into separate components and apply React.memo
 * @todo Apply react-window to improve performance of rendering table with long list data
 * https://codesandbox.io/s/material-ui-react-window-virtualized-table-example-byv8n
 */

const DataTable = ({ columns, data }) => {
  const classes = useStyles();
  const { admin, setAdmin, countDuplicate } = useContext(SensorDataTableContext);
  const { i18n, isAdmin } = useSelector((state) => ({
    i18n: state.i18n,
    isAdmin: state.isAdmin
  }));

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', which has only the rows for the active page
    allColumns,
    gotoPage,
    setPageSize,
    setHiddenColumns,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 10
      }
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    const hiddenColumns = allColumns.filter((column) => !column.show).map((column) => column.id);
    setHiddenColumns(hiddenColumns);
  }, [allColumns, setHiddenColumns]);

  const handleChangePage = (_, pageNum) => gotoPage(pageNum);

  const handleChangeRowsPerPage = (event) => setPageSize(Number(event.target.value));

  return (
    <Table style={{ whiteSpace: 'nowrap' }} stickyHeader {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                <TableSortLabel
                  active={column.isSorted}
                  direction={column.isSortedDesc ? 'asc' : 'desc'}
                >
                  {column.render('Header')}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()}>
        {page.length === 0 && (
          <TableRow>
            <TableCell colSpan={allColumns.length} className={classes.noData}>
              <Typography variant='h5'>{i18n.no_data || 'No data for this period'}</Typography>
            </TableCell>
          </TableRow>
        )}
        {page.map((row) => {
          prepareRow(row);
          const isDuplicate = admin && row.values.duplicate === 'D';
          if (!admin && 'temp_1' in row.values && !row.values.temp_1) {
            // console.log('Multipoint without HH field DETECTED, timestamp:', row.values.date);
            return null;
          }
          return (
            <TableRow {...row.getRowProps()}>
              {row.cells.map((cell, index) => (
                <TableCell
                  key={index}
                  {...cell.getCellProps(() => ({
                    style: {
                      width: ['duplicate', 'repeat'].includes(cell.column.id) && '5%',
                      color: isDuplicate && COLORS.invalidStatus
                    }
                  }))}
                >
                  {cell.render('Cell')}
                </TableCell>
              ))}
            </TableRow>
          );
        })}
      </TableBody>
      <TableFooter>
        <TableRow>
          {isAdmin && (
            <TableCell colSpan={admin ? 2 : 1}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={admin}
                      checked={admin}
                      onChange={(e) => setAdmin(e.target.checked)}
                    />
                  }
                  label={
                    <span style={{ fontSize: 10 }}>
                      Admin <span className={classes.duplicate}>({countDuplicate?.value})</span>
                    </span>
                  }
                />
              </FormGroup>
            </TableCell>
          )}
          <TablePagination
            style={{ padding: 0, overflow: 'hidden' }}
            rowsPerPageOptions={[10, 20, 40, 60, 80, 100, { label: i18n.all || 'All', value: -1 }]}
            colSpan={allColumns.length}
            count={data.length}
            page={pageIndex}
            rowsPerPage={pageSize}
            labelRowsPerPage={i18n.rows_per_page}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} / ${count !== -1 ? count : `>${to}`}`
            }
            SelectProps={{
              inputProps: { 'aria-label': 'Rows per page' },
              native: true
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </TableRow>
      </TableFooter>
    </Table>
  );
};

const SensorDataTable = ({ data, showHandle }) => {
  const { admin } = useContext(SensorDataTableContext);
  const { degrees, i18n, dateFormat, sessionInfo } = useSelector((state) => ({
    degrees: state.sessionInfo?.ui_preferences?.degrees ?? '°C',
    i18n: state.i18n,
    dateFormat: state.sessionInfo?.ui_preferences?.date_format ?? 'd.MM.yyyy',
    sessionInfo: state.sessionInfo
  }));

  const multiBSdata = data.map((d) => d.bs).filter((v, i, a) => a.indexOf(v) === i);

  const mikes = sessionInfo?.username === 'mikesTEST';

  const probeType = checkProbeType({ dataCheck: data[0], showHandle });

  const hhColumns = React.useMemo(
    () =>
      isEmpty(data)
        ? []
        : data[0]?.hh?.map(
            (temp, i) =>
              [
                {
                  name: `Depth ${i + 1} (${degrees})`,
                  Header: `${i18n.depth_title} ${i + 1} (${degrees})`,
                  accessor: `temp_${i + 1}`,
                  show:
                    mikes ||
                    admin ||
                    (probeType !== 'singlepoint' && probeType !== 'singlepoint-handle')
                }
              ]?.flat() || []
          ),
    [admin, mikes, data, degrees, i18n.depth_title, probeType]
  );

  const columns = React.useMemo(
    () =>
      [
        { name: 'ID', Header: 'ID', accessor: 'id', show: true },
        { name: 'Name', Header: i18n.name, accessor: 'name', show: false },
        {
          name: 'Date Time',
          Header: i18n.date_time,
          accessor: 'date',
          show: true,
          Cell: ({ cell: { value } }) => format(value, `${dateFormat} HH:mm:ss`)
        },
        { name: 'Batt', Header: 'Batt', accessor: 'batt', show: admin },
        { name: 'RSs', Header: 'RSs', accessor: 'rss', show: admin },
        { name: 'RSb', Header: 'RSb', accessor: 'rsb', show: admin },
        multiBSdata && multiBSdata.length > 1
          ? { name: 'bs', Header: 'BS', accessor: 'bs', show: admin }
          : { name: 'Tdiff', Header: 'Tdiff', accessor: 'tdiff', show: admin },
        { name: 'D', Header: 'D', accessor: 'duplicate', show: admin },
        { name: 'R', Header: 'R', accessor: 'repeat', show: admin },
        {
          name: `Temperature`,
          Header: `${i18n.temperature} (${degrees})`,
          accessor: 'celcius',
          show: mikes || admin || probeType === 'singlepoint' || probeType === 'singlepoint-handle'
        },
        {
          name: `Handle (${degrees})`,
          Header: `${i18n.handle} (${degrees})`,
          accessor: 'handle',
          show: mikes || admin || showHandle
        },
        ...(hhColumns || [])
      ].flat(),
    [i18n, admin, mikes, degrees, probeType, showHandle, hhColumns, dateFormat, multiBSdata]
  );

  const memoData = React.useMemo(
    () =>
      data
        .filter((d) => (admin ? true : d.duplicate !== 'D'))
        .filter((d) => (admin ? true : !d.notemp))
        .map((d) => {
          if (d.hh && d.hh.length > 0) {
            const jsObj = {};
            let newObj = {};
            if (d.hh) {
              for (let i = 0; i < d.hh.length; i++) {
                // for admin show high precision value
                jsObj[`temp_${i + 1}`] = `${
                  admin || mikes ? d.hh[i] : tempFormat(degrees, d.hh[i])
                }`;
                newObj = { ...d, ...jsObj };
              }
              return newObj;
            }
            if (admin || mikes) {
              return d;
            }
            return null;
          }
          return d;
        })
        .filter(Boolean),
    [admin, mikes, data, degrees]
  );

  return (
    <div>
      <Toolbar
        columns={columns}
        data={memoData.map((d) => ({ ...d, date: format(d.date, 'yyyy-MM-dd HH:mm:ss') }))}
      />
      <DataTable columns={columns} data={memoData} />
    </div>
  );
};

export default SensorDataTable;
