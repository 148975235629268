import {
  Tooltip,
  // Typography,
  // Accordion,
  // AccordionSummary,
  // AccordionDetails,
  Card,
  CardContent,
  Divider,
  Checkbox,
  Button
} from '@material-ui/core';
import { React, useState } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { format } from 'date-fns';
import { InlineIcon } from '@iconify/react';
import fanIcon from '@iconify/icons-mdi/fan';
import bellOff from '@iconify/icons-mdi/bell-off-outline';
import calendarClock from '@iconify/icons-mdi/calendar-clock';
import toggleSwitch from '@iconify/icons-mdi/toggle-switch';
import toggleSwitchOffOutline from '@iconify/icons-mdi/toggle-switch-off-outline';

import checkCircle from '@iconify/icons-mdi/check-circle';

// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { MdNotifications } from 'react-icons/md';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';
import {
  tempFormat,
  tempFormatDelta,
  formatSensorLocation,
  isNil,
  formatTimeDifference
} from 'assets/utils';
import { COLORS } from 'utils/colors';
import { getAge } from 'utils/sharedHelper';
import MonitorRules from '../monitor-utils/MonitorRulesUtils';
import { FanStatus, GetSwitchIcon } from '../../storage-list-item/StorageListItemFCStatusBar';
import { controllerRule } from '../monitor-utils/fcHelpers';

const StorageMonitorRules = ({ i18n, selectedStorage, classes, state, resetAlertHandler }) => {
  const dateFormat = useSelector((store) => store.sessionInfo.ui_preferences.date_format);

  const timeFormat = useSelector((store) => store.sessionInfo.ui_preferences.time_format);

  const degrees = useSelector((store) => store.sessionInfo?.ui_preferences?.degrees);

  const FcStatus = useSelector((store) => store.FcStatus);

  const sensorsInStorage =
    selectedStorage.layoutType === 3
      ? selectedStorage.stacks.map((s) => s.sensors).flat()
      : selectedStorage.sensor_layout?.sensors || [];

  const applyAlertReset = (rule, resetAlerts) => {
    resetAlertHandler(rule, resetAlerts);
  };

  const AlertsTable = ({ alerts, rule, history, resetable }) => {
    const [resetSelection, setResetSelection] = useState([]);

    const toggleAlertReset = (alertIndex) => {
      const selected = resetSelection.find((a) => a === alertIndex);
      if (!isNil(selected)) {
        // deselect, i.e. remove from resetSelection
        setResetSelection(resetSelection.filter((i) => i !== alertIndex));
      } else {
        // Add to resetSelection
        setResetSelection(resetSelection.concat([alertIndex]));
      }
    };

    return (
      <div className={classes.alertTableWrapper}>
        <table className={history && classes.monitorHistory}>
          <tbody>
            <tr>
              {history && <th>{i18n.date || 'date'}</th>}
              <th>
                {history
                  ? alerts && alerts[0].isMin
                    ? i18n.min_temperature
                    : i18n.max_temperature
                  : i18n.temperature}
              </th>
              <th>{i18n.location || 'Location'}</th>
              <th>{i18n.sensor_title || 'Probe'}</th>
              <th>{i18n.duration || 'Duration'}</th>
              <th>
                {resetable ? (
                  <Tooltip
                    arrow
                    title={i18n.reset_selected_alerts || 'Reset selected alerts'}
                    placement='top'
                    disableFocusListener
                  >
                    <span>
                      <Button
                        disabled={isEmpty(resetSelection)}
                        style={{ padding: '0 10px', marginLeft: 10 }}
                        variant='outlined'
                        onClick={() =>
                          applyAlertReset(
                            rule,
                            alerts.filter((a, i) => resetSelection.includes(i))
                          )
                        }
                      >
                        {i18n.reset || 'Reset'}
                      </Button>
                    </span>
                  </Tooltip>
                ) : null}
              </th>
            </tr>
            {alerts.map((alert, index) => {
              const tobeAged = alert.ts;
              const completedAt = history ? alert.resetTS : null;
              const alertAge = getAge(i18n, dateFormat, timeFormat, tobeAged, completedAt);

              const sensorInLayout = sensorsInStorage.find((s) => s.id === alert.sensor_id) || null;
              const location = sensorInLayout
                ? `[${formatSensorLocation(sensorInLayout.row, sensorInLayout.column)}]${
                    alert.depth ? ` - ${alert.depth}m` : ''
                  }`
                : '--';

              return (
                <tr key={index} style={{ textAlign: 'center' }}>
                  {history && <td>{format(alert.ts * 1000, `${dateFormat} ${timeFormat}`)}</td>}
                  <td>
                    {history
                      ? tempFormat(degrees, alert.peak)
                      : tempFormat(degrees, alert.temperature)}{' '}
                    {degrees}
                    {isNil(alert.TP)
                      ? null
                      : ` (∆ ${
                          (history ? alert.peak : alert.temperature) >= alert.TP ? '+' : '-'
                        }${tempFormatDelta(
                          degrees,
                          Math.abs((history ? alert.peak : alert.temperature) - alert.TP),
                          true
                        )})`}
                  </td>
                  <td>{location}</td>
                  <td>{alert.sensor_id}</td>
                  <td>{alertAge}</td>
                  {resetable ? (
                    <td style={{ paddingLeft: 10 }}>
                      <Checkbox
                        checked={resetSelection.includes(index)}
                        onChange={() => toggleAlertReset(index)}
                        style={{ padding: 0 }}
                      />
                    </td>
                  ) : null}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const FcStats = ({ fcStatus, FcInStorage }) => {
    let additionalTimeOn = 0;
    let additionalTimeOff = 0;
    let additionalTimeDrive = 0;
    // console.log('FcStats', fcStatus, FcInStorage);
    if (fcStatus.fc_mode === 'drive' && fcStatus.command === 1 && !!fcStatus.driveChangeTS) {
      additionalTimeDrive = differenceInMilliseconds(
        +new Date() / 1000,
        +new Date(fcStatus.driveChangeTS)
      );
    } else if (fcStatus.fc_mode === 'on' && !!fcStatus.modeChangeTS) {
      additionalTimeOn = differenceInMilliseconds(
        +new Date() / 1000,
        +new Date(fcStatus.modeChangeTS)
      );
    } else if (fcStatus.fc_mode === 'off' && !!fcStatus.modeChangeTS) {
      additionalTimeOff = differenceInMilliseconds(
        +new Date() / 1000,
        +new Date(fcStatus.modeChangeTS)
      );
    }

    const startTime = differenceInMilliseconds(new Date(), +new Date(fcStatus.use_date)) / 1000;
    const runTimeDuration = formatTimeDifference(startTime / 3600, i18n);
    const runningTime =
      fcStatus.modeCounter.on + fcStatus.driveCounter[1] + additionalTimeOn + additionalTimeDrive;

    const totalRunningTime = formatTimeDifference(runningTime / 3600, i18n);
    const onDuration = formatTimeDifference(
      (fcStatus.modeCounter.on + additionalTimeOn) / 3600,
      i18n
    );
    const offDuration = formatTimeDifference(
      (fcStatus.modeCounter.off + additionalTimeOff) / 3600,
      i18n
    );

    const percentageOn =
      runningTime > 0 ? `(${Math.min(100, Math.round((runningTime / startTime) * 100))}%)` : '';

    // console.log(
    //   'additionalTimeDrive: ',
    //   additionalTimeDrive,
    //   'additionalTimeOn: ',
    //   additionalTimeOn,
    //   'additionalTimeOff: ',
    //   additionalTimeOff
    // );
    // console.log('startTime :', startTime, fcStatus.use_date);
    // console.log('runTimeDuration :', runTimeDuration, startTime);
    // console.log('runningTime :', runningTime, fcStatus.modeCounter.on, fcStatus.driveCounter[1]);
    // console.log('onDuration :', onDuration, fcStatus.modeCounter.on);
    // console.log('offDuration :', offDuration, fcStatus.modeCounter.off);
    // console.log(
    //   'percentageOn :',
    //   percentageOn,
    //   runningTime / startTime,
    //   Math.round(
    //     (runningTime / startTime) * 100,
    //     Math.min(100, Math.round((runningTime / startTime) * 100))
    //   )
    // );
    if (!FcInStorage.state) {
      return null;
    }

    return (
      <div className={classes.alertTableWrapper}>
        <table style={{ width: '100%', tableLayout: 'fixed' }}>
          <tbody>
            <tr>
              <th>
                <Tooltip
                  arrow
                  title={i18n.fc_stat_total_fc_time}
                  placement='top'
                  disableFocusListener
                >
                  <div>
                    <InlineIcon
                      width={20}
                      height={20}
                      icon={calendarClock}
                      style={{ color: COLORS.dimGray }}
                    />
                  </div>
                </Tooltip>
              </th>
              <th>
                <Tooltip
                  arrow
                  title={i18n.fc_stat_total_running_time}
                  placement='top'
                  disableFocusListener
                >
                  <div>
                    <InlineIcon
                      width={25}
                      height={25}
                      icon={fanIcon}
                      style={{ color: COLORS.dimGray }}
                    />
                  </div>
                </Tooltip>
              </th>
              <th>
                <Tooltip arrow title={i18n.fc_stat_total_on} placement='top' disableFocusListener>
                  <div>
                    <InlineIcon
                      width={25}
                      height={25}
                      icon={toggleSwitch}
                      style={{ color: COLORS.dimGray }}
                    />
                  </div>
                </Tooltip>
              </th>
              <th>
                <Tooltip arrow title={i18n.fc_stat_total_off} placement='top' disableFocusListener>
                  <div>
                    <InlineIcon
                      width={25}
                      height={25}
                      icon={toggleSwitchOffOutline}
                      style={{ color: COLORS.dimGray }}
                    />
                  </div>
                </Tooltip>
              </th>
            </tr>
            <tr style={{ textAlign: 'center' }}>
              <td>{runTimeDuration}</td>
              <td>
                {totalRunningTime} {percentageOn}
              </td>
              <td>{onDuration}</td>
              <td>{offDuration}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };
  const rules = selectedStorage?.monitor?.rules?.map((rule, i) => {
    const FcInStorage = controllerRule(rule) && rule;
    const itemFcStatus = FcStatus.find((FC) => FC.FC_ID === FcInStorage?.settings?.FC_ID);

    return (
      <Card key={rule.id} className={classes.cardWrap}>
        <CardContent
          // expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
          className={classes.card}
          style={
            itemFcStatus && selectedStorage.itemID === itemFcStatus?.inUse
              ? { backgroundColor: COLORS.fcBackground }
              : null
          }
        >
          <div className={classes.heading}>
            <div className={classes.summary}>
              <div className={classes.monitorRule}>
                <MonitorRules
                  monitor={selectedStorage.monitor}
                  state={state}
                  ruleinStorage={rule}
                  index={i}
                  responsive
                  FcStatus={FcStatus}
                  disabled={isEmpty(sensorsInStorage)}
                  // FcRule={rule.type === 'COOLDOWN_CONTROL'}
                />
              </div>
              <Divider className={classes.divider} orientation='vertical' flexItem />
              <div
                className={
                  itemFcStatus
                    ? [classes.alertSummary, classes.FcStats].join(' ')
                    : classes.alertSummary
                }
              >
                {itemFcStatus && selectedStorage.itemID === itemFcStatus?.inUse ? (
                  <>
                    <div className={classes.FcStatsIcon}>
                      <FanStatus
                        itemFcStatus={itemFcStatus}
                        FcState={FcInStorage.state}
                        size={35}
                        isTooltip
                        showText
                        isManualRule={rule.type === 'MANUAL_CONTROL'}
                      />
                      <GetSwitchIcon itemFcStatus={itemFcStatus} />
                    </div>
                    <FcStats fcStatus={itemFcStatus} FcInStorage={FcInStorage} />
                  </>
                ) : rule.currentAlerts?.length > 0 ? (
                  <>
                    <div>
                      <MdNotifications className={classes.summaryAlertIcon} />
                    </div>
                    <AlertsTable
                      rule={rule}
                      alerts={rule.currentAlerts}
                      resetable={rule.type === 'REL_MIN' || rule.type === 'REL_MAX'}
                    />
                  </>
                ) : rule.currentAlerts?.length === 0 && !isEmpty(sensorsInStorage) ? (
                  <>
                    <InlineIcon className={classes.okStatusIcon} icon={checkCircle} />
                    <div>{i18n.ok_status || 'Status OK'}</div>
                  </>
                ) : isEmpty(sensorsInStorage) ? (
                  <>
                    <InlineIcon className={classes.noProbesIcon} icon={bellOff} />
                    <div>{i18n.empty_storage_title}</div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </CardContent>
        <Divider />
        {/* <CardContent style={{ background: '#8080800f', display: 'flex', flexDirection: 'column' }}>
        <Typography variant='h6' className={classes.titleTable}>
          {i18n.monitor_history || 'Monitor History'}
        </Typography>
        {rule.alertHistory?.length > 0 ? (
          <AlertsTable alerts={rule.alertHistory} history />
        ) : (
          <Typography className={classes.titleTable}>
            {i18n.no_history || 'No history yet'}
          </Typography>
        )}
      </CardContent> */}
      </Card>
    );
  });
  return <div>{rules}</div>;
};

export default StorageMonitorRules;
