import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TimezoneSelect from 'react-timezone-select';

import { setBsTimezone } from 'api/serverAPI';

const EditTimezoneDialog = ({ isTimezoneDialog, setIsTimezoneDialog }) => {
  const dispatch = useDispatch();
  const { i18n, baseStation, bsConf, isDemoMode, isAdmin } = useSelector((store) => ({
    i18n: store.i18n,
    baseStation: store.baseStation,
    bsConf: store.bsConf,
    isDemoMode: store.isDemoMode,
    isAdmin: store.isAdmin
  }));

  const [selectedTimezone, setSelectedTimezone] = useState({
    value: bsConf.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
  });

  const onSave = async () => {
    try {
      if (!isDemoMode || isAdmin) {
        await setBsTimezone({ bs_id: baseStation.bs_BS_ID, timezone: selectedTimezone.value });
      }
      const newBsConfig = { ...bsConf, timezone: selectedTimezone.value };
      dispatch({ type: 'SET_BSCONF', value: newBsConfig });
      setIsTimezoneDialog(!isTimezoneDialog);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Dialog open={isTimezoneDialog} aria-labelledby='responsive-dialog-title'>
      <DialogTitle>
        <div>{i18n.timezone || 'Timezone'}</div>
      </DialogTitle>
      <DialogContent style={{ minWidth: 300, overflowY: 'visible' }} elevation={0}>
        <div className='select-wrapper'>
          <TimezoneSelect value={selectedTimezone} onChange={setSelectedTimezone} />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsTimezoneDialog(!isTimezoneDialog)} color='primary'>
          {i18n.cancel}
        </Button>
        <Button onClick={onSave} color='secondary'>
          {i18n.save || 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditTimezoneDialog;
