import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { stableSort, getComparator } from 'utils/sorting';
import { isNil } from 'assets/utils';
import QualitySensorListItem from './QualitySensorListItem';

const useStyles = makeStyles(() => ({
  qListWrapper: {
    overflowY: 'auto',
    width: 245,
    paddingLeft: 5
  }
}));

const QualitySensorList = ({
  sensorIDs,
  onSensorClick,
  probeQualityData,
  bsConf,
  viewHeight,
  sensorSorting,
  periodUnset,
  i18n
}) => {
  const classes = useStyles();

  // Adjusting list height depending on headers
  const topHeight =
    (document.getElementById('qPeriodPanel')?.offsetHeight || 70) +
    (document.getElementById('qListHead')?.offsetHeight || 70);

  const getQualitySort = (qualityObject) =>
    qualityObject && !isNil(qualityObject.quality)
      ? qualityObject.quality
      : sensorSorting[1] === 'desc'
      ? 1
      : 0;

  const getDropSort = (qualityObject) =>
    qualityObject && !isNil(qualityObject.daysToDrop)
      ? qualityObject.daysToDrop
      : sensorSorting[1] === 'asc'
      ? 1
      : 0;

  const getTabledata = () =>
    sensorIDs.map((sid) => {
      const obj = {
        id: sid,
        drop: getDropSort(probeQualityData.find((q) => q.ID === sid)),
        quality: getQualitySort(probeQualityData.find((q) => q.ID === sid)),
        name:
          bsConf?.sensor_configurations?.find((s) => s.sensor_id_sys === sid)?.sensor_name || sid
      };
      return obj;
    });

  return (
    <div style={{ height: viewHeight - topHeight }} className={classes.qListWrapper}>
      {stableSort(getTabledata(), getComparator(sensorSorting[1], sensorSorting[0]))
        .map((p) => p.id)
        .map((sid) => (
          <QualitySensorListItem
            probeQuality={probeQualityData.find((s) => s.ID === sid)}
            sensorID={sid}
            sensorConf={bsConf.sensor_configurations.find((s) => s.sensor_id_sys === sid)}
            key={sid}
            onSensorClick={onSensorClick}
            periodUnset={periodUnset}
            i18n={i18n}
          />
        ))}
    </div>
  );
};

export default QualitySensorList;
