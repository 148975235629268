import React from 'react';
import { useDrop } from 'react-dnd';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'utils/colors';
import { ItemTypes } from './ItemTypes';
import DraggableSensor from './DraggableSensor';
import SensorPointStatus from './SensorPointStatus';

const BOTTOM_MARGIN = 50; // below 3d layout

const useStyles = makeStyles(() => ({
  layerCell: {
    // backgroundColor: '#eeeeff',
    position: 'relative',
    transition: 'all 300ms'
    // left: 0,
    // right: 0,
    // top: 0,
    // bottom: 0
  },
  pileSeg: {
    height: 35,
    width: 35,
    backgroundColor: 'rgba(0,0,0,0.6)',
    color: COLORS.white,
    // padding: 5,
    boxSizing: 'border-box',
    textAlign: 'center',
    fontSize: '1rem',
    lineHeight: 'normal',
    position: 'relative',
    transition: 'all 200ms',
    border: '1px solid white'
  },
  pileSegHoverable: {
    '&:hover': {
      backgroundColor: COLORS.greenOlive
    }
  },
  columnLabel: {
    fontSize: 14,
    lineHeight: '16px',
    textAlign: 'center',
    cursor: 'default',
    backgroundColor: COLORS.lightGray2,
    border: '1px solid white',
    height: 16
  },
  alertCell: {
    border: '3px solid #f44336'
  }
}));

const LayerCell = ({
  // row,
  // column,
  width,
  depth,
  sensorInColumn,
  isSelected,
  isEditing,
  isActive,
  onSensorMoved,
  onSensorClicked,
  onSensorHover, // not in use, as causes sensorList issue
  columnHover,
  isFaded,
  id,
  label,
  measInLocation,
  showColumnLabel,
  isBottomFloor,
  bgColor,
  cellBgColor,
  isAlert,
  tempScale,
  antiSkew,
  angle,
  showDataless,
  showID
}) => {
  const classes = useStyles();
  const labelStyle = {
    transform: antiSkew.transform,
    marginBottom:
      isEditing || !isBottomFloor ? 0 : BOTTOM_MARGIN / Math.cos((angle * Math.PI) / 180),
    backgroundColor: columnHover ? COLORS.yellowBright : null
  };

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: ItemTypes.SENSOR,
    canDrop: () => !sensorInColumn,
    drop: () => ({ id }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });
  // const pileColor = 'rgba(64,64,64,0.75)';
  const isHot = canDrop && isOver;
  let backgroundColor = cellBgColor || null;
  // if (!isEditing) {
  if (!measInLocation && !sensorInColumn) {
    backgroundColor = cellBgColor || 'rgba(64,64,64,0.5)';
  }
  // }
  if (isSelected) {
    backgroundColor = COLORS.yellowBright;
  } else if (isHot) {
    backgroundColor = COLORS.yellowBright;
  } else if (canDrop) {
    backgroundColor = cellBgColor || 'rgba(64,64,64,0.5)';
  }
  //  else if (sensorInColumn && angle <= 20) {
  //   backgroundColor = `rgba(0,0,0,${0.6 - ((20 - angle) / 20) * 0.4})`;
  // }
  return (
    <div
      ref={drop}
      className={classes.layerCell}
      style={isFaded ? { filter: 'grayscale(1)', opacity: 0.2 } : {}}
    >
      <div
        className={
          isEditing || !measInLocation
            ? classes.pileSeg
            : [classes.pileSeg, classes.pileSegHoverable, isAlert ? classes.alertCell : null].join(
                ' '
              )
        }
        style={
          backgroundColor ? { width, height: width, backgroundColor } : { width, height: width }
        }
        onMouseEnter={() => {
          if (onSensorHover && sensorInColumn) {
            onSensorHover(sensorInColumn.id, true);
          }
        }}
        onMouseLeave={() => {
          if (onSensorHover && sensorInColumn) {
            onSensorHover(sensorInColumn.id, false);
          }
        }}
      >
        {sensorInColumn && !isEditing ? (
          <SensorPointStatus
            isActive={isActive}
            type={sensorInColumn.type}
            sensorID={sensorInColumn.id}
            onSensorClicked={onSensorClicked}
            timestamp={measInLocation ? measInLocation.meas_ts : null}
            width={width}
            temperature={measInLocation ? measInLocation.temperature : null}
            depth={depth}
            tempScale={tempScale}
            isSelected={isSelected}
            showDataless={showDataless}
            showID={showID}
          />
        ) : null}

        {sensorInColumn && isEditing ? (
          <DraggableSensor
            id={sensorInColumn.id}
            disabled={!isEditing}
            isActive={isActive}
            type={sensorInColumn.type}
            onDrop={onSensorMoved}
            measurement={measInLocation}
            onSensorClicked={!isEditing ? onSensorClicked : null}
            bgColor={bgColor}
          />
        ) : null}
      </div>
      {showColumnLabel ? (
        <div className={classes.columnLabel} style={labelStyle}>
          {label}
        </div>
      ) : null}
    </div>
  );
};
export default LayerCell;
