import { TextField, Grid } from '@material-ui/core';
import React from 'react';
import { Autocomplete } from '@material-ui/lab';

const FieldCountry = ({ id, label, countryObject, valueCountry, required, onChange }) => (
  <Autocomplete
    style={{ margin: '16px 0 8px', padding: 0 }}
    id={id}
    className='FormRowInput'
    options={countryObject?.data}
    value={valueCountry}
    getOptionLabel={(option) => option?.label || ''}
    getOptionSelected={(option) => option?.label || ''}
    renderInput={(params) => <TextField {...params} label={label} required={required} fullWidth />}
    onChange={onChange}
    size='small'
  />
);

const DoubleField = ({
  label,
  id,
  type,
  // placeholder,
  required,
  autoComplete,
  value,
  onChange,
  label1,
  id1,
  type1,
  // placeholder1,
  required1,
  autoComplete1,
  value1,
  onChange1
}) => (
  <Grid container item xs={12} spacing={2}>
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          style={{ margin: '16px 0 8px' }}
          className='FormRowInputDouble'
          id={id1}
          type={type1}
          label={label1}
          // placeholder={placeholder1}
          required={required1}
          autoComplete={autoComplete1}
          value={value1}
          onChange={onChange1}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          style={{ margin: '16px 0 8px' }}
          className='FormRowInput'
          id={id}
          type={type}
          label={label}
          // placeholder={placeholder}
          required={required}
          autoComplete={autoComplete}
          value={value}
          onChange={onChange}
          // error={!!errors.billingInfo?.name}
        />
      </Grid>
    </Grid>
  </Grid>
);

const BillingInfo = ({
  i18n,
  billingDetails,
  setBillingDetails,
  companyName,
  setCompanyName,
  countryObject,
  valueCountry,
  setValueCountry
}) => (
  <Grid container style={{ display: 'flex', flexWrap: 'wrap', padding: '0px 25px' }}>
    <DoubleField
      label1={i18n.name}
      id1='name'
      type1='text'
      // placeholder1={i18n.name_placeholder}
      required1
      autoComplete1='name'
      value1={billingDetails.name}
      onChange1={(e) => {
        setBillingDetails({ ...billingDetails, name: e.target.value });
      }}
      label={i18n.email_address}
      id='email'
      type='email'
      // placeholder={i18n.email_placeholder}
      required
      autoComplete='email'
      value={billingDetails.email}
      onChange={(e) => {
        setBillingDetails({ ...billingDetails, email: e.target.value });
      }}
    />
    <DoubleField
      label1={i18n.company_name}
      id1='company'
      type1='text'
      // placeholder1={i18n.company_placeholder}
      required1
      value1={companyName}
      onChange1={(e) => {
        setCompanyName(e.target.value);
      }}
      label={i18n.street_address}
      id='address'
      type='text'
      // placeholder={i18n.street_placeholder}
      required
      value={billingDetails.address.line1}
      onChange={(e) => {
        setBillingDetails({
          ...billingDetails,
          address: { ...billingDetails.address, line1: e.target.value }
        });
      }}
    />
    <DoubleField
      label1={i18n.postal_code}
      id1='postal_code'
      type1='text'
      // placeholder1={i18n.zip_placeholder}
      required1
      value1={billingDetails.address.postal_code}
      onChange1={(e) => {
        setBillingDetails({
          ...billingDetails,
          address: { ...billingDetails.address, postal_code: e.target.value }
        });
      }}
      label={i18n.city}
      id='city'
      type='text'
      // placeholder={i18n.city_placeholder}
      required
      value={billingDetails.address.city}
      onChange={(e) => {
        setBillingDetails({
          ...billingDetails,
          address: { ...billingDetails.address, city: e.target.value }
        });
      }}
    />
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={12} sm={6}>
        <FieldCountry
          id='country'
          type='text'
          required
          label={i18n.country}
          countryObject={countryObject}
          valueCountry={valueCountry}
          value={billingDetails.address.country}
          onChange={(e, val) => {
            setBillingDetails({
              ...billingDetails,
              address: { ...billingDetails.address, country: val?.value }
            });
            setValueCountry(val);
          }}
        />
      </Grid>
    </Grid>
  </Grid>
);

export default BillingInfo;
