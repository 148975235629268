// PUBLIC IMPORTS
import React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';

class QuanturiPremiumSVG extends React.Component {
  render() {
    return (
      <div style={this.props.style}>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 128.05 108.21'>
          <defs>
            <linearGradient
              id='linear-gradient'
              x1='15.72'
              y1='5.8'
              x2='109.32'
              y2='99.41'
              gradientUnits='userSpaceOnUse'
            >
              <stop offset='0' stopColor='#efefef' />
              <stop offset='0.51' stopColor='#fff' />
              <stop offset='1' stopColor='#efefef' />
            </linearGradient>
            <linearGradient
              id='linear-gradient-2'
              x1='14.29'
              y1='4.37'
              x2='110.75'
              y2='100.84'
              gradientUnits='userSpaceOnUse'
            >
              <stop offset='0' stopColor='gray' />
              <stop offset='0.51' stopColor='#e6e6e6' />
              <stop offset='0.83' stopColor='#b9bbbc' />
              <stop offset='1' stopColor='#a6a8aa' />
            </linearGradient>
          </defs>
          <g style={{ isolation: 'isolate' }}>
            <g id='Layer_2' data-name='Layer 2'>
              <g id='RULES'>
                <g
                  style={{
                    opacity: this.props.fillMode === 'gradient' ? '0' : '0',
                    mixBlendMode: 'multiply'
                  }}
                >
                  <rect x='5.02' y='5.02' width='121' height='101.16' rx='29.75' />
                  <path d='M107.49,35.61a17.83,17.83,0,0,0-4.8-8.06A19.89,19.89,0,0,0,94.08,23a49.53,49.53,0,0,0-12.83-1.43H50.66A48.89,48.89,0,0,0,37.89,23a19.85,19.85,0,0,0-8.57,4.56,18.18,18.18,0,0,0-4.85,8.06,41.35,41.35,0,0,0-1.53,12.08V63a41.85,41.85,0,0,0,1.53,12.13,18.26,18.26,0,0,0,4.85,8.13,19.57,19.57,0,0,0,8.57,4.5,50.83,50.83,0,0,0,12.77,1.39H72.74l11.38,16.53a5.44,5.44,0,0,1,.36.48h17.74c-.23-.32-1.28-1.67-1.36-1.77L89.48,88.68q10.49-1.38,15-7.48T109,63V47.69A42.54,42.54,0,0,0,107.49,35.61ZM93.83,62a27.61,27.61,0,0,1-.65,6.48,8.79,8.79,0,0,1-2.13,4.16,8.17,8.17,0,0,1-4,2.18,26.45,26.45,0,0,1-6.39.64H51.25a26.37,26.37,0,0,1-6.38-.64,8.24,8.24,0,0,1-4-2.18,9,9,0,0,1-2.14-4.16A28.28,28.28,0,0,1,38.09,62V48.78a28.15,28.15,0,0,1,.63-6.48,9,9,0,0,1,2.14-4.16,8.33,8.33,0,0,1,4-2.18,26.31,26.31,0,0,1,6.38-.65H80.66a26.4,26.4,0,0,1,6.39.65,8.25,8.25,0,0,1,4,2.18,8.79,8.79,0,0,1,2.13,4.16,27.5,27.5,0,0,1,.65,6.48Z' />
                  <path d='M107.49,35.61a17.83,17.83,0,0,0-4.8-8.06A19.89,19.89,0,0,0,94.08,23a49.53,49.53,0,0,0-12.83-1.43H50.66A48.89,48.89,0,0,0,37.89,23a19.85,19.85,0,0,0-8.57,4.56,18.18,18.18,0,0,0-4.85,8.06,41.35,41.35,0,0,0-1.53,12.08V63a41.85,41.85,0,0,0,1.53,12.13,18.26,18.26,0,0,0,4.85,8.13,19.57,19.57,0,0,0,8.57,4.5,50.83,50.83,0,0,0,12.77,1.39H72.74l11.38,16.53a5.44,5.44,0,0,1,.36.48h17.74c-.23-.32-1.28-1.67-1.36-1.77L89.48,88.68q10.49-1.38,15-7.48T109,63V47.69A42.54,42.54,0,0,0,107.49,35.61ZM93.83,62a27.61,27.61,0,0,1-.65,6.48,8.79,8.79,0,0,1-2.13,4.16,8.17,8.17,0,0,1-4,2.18,26.45,26.45,0,0,1-6.39.64H51.25a26.37,26.37,0,0,1-6.38-.64,8.24,8.24,0,0,1-4-2.18,9,9,0,0,1-2.14-4.16A28.28,28.28,0,0,1,38.09,62V48.78a28.15,28.15,0,0,1,.63-6.48,9,9,0,0,1,2.14-4.16,8.33,8.33,0,0,1,4-2.18,26.31,26.31,0,0,1,6.38-.65H80.66a26.4,26.4,0,0,1,6.39.65,8.25,8.25,0,0,1,4,2.18,8.79,8.79,0,0,1,2.13,4.16,27.5,27.5,0,0,1,.65,6.48Z' />
                </g>
                <rect
                  style={{
                    fill:
                      this.props.fillMode === 'gradient'
                        ? 'url(#linear-gradient)'
                        : 'rgba(0, 0, 0, 0)',
                    stroke:
                      this.props.fillMode === 'gradient'
                        ? 'url(#linear-gradient-2)'
                        : this.props.color,
                    strokeMiterlimit: '10',
                    strokeWidth: '4.05px'
                  }}
                  x='2.02'
                  y='2.02'
                  width='121'
                  height='101.16'
                  rx='29.75'
                />
                <path
                  style={{
                    fill:
                      this.props.fillMode === 'gradient'
                        ? 'url(#linear-gradient-2)'
                        : this.props.color
                  }}
                  d='M104.49,32.61a17.83,17.83,0,0,0-4.8-8.06A19.89,19.89,0,0,0,91.08,20a49.53,49.53,0,0,0-12.83-1.43H47.66A48.89,48.89,0,0,0,34.89,20a19.85,19.85,0,0,0-8.57,4.56,18.18,18.18,0,0,0-4.85,8.06,41.35,41.35,0,0,0-1.53,12.08V60a41.85,41.85,0,0,0,1.53,12.13,18.26,18.26,0,0,0,4.85,8.13,19.57,19.57,0,0,0,8.57,4.5,50.83,50.83,0,0,0,12.77,1.39H69.74l11.38,16.53a5.44,5.44,0,0,1,.36.48H99.22c-.23-.32-1.28-1.67-1.36-1.77L86.48,85.68q10.49-1.38,15-7.48T106,60V44.69A42.54,42.54,0,0,0,104.49,32.61ZM90.83,59a27.61,27.61,0,0,1-.65,6.48,8.79,8.79,0,0,1-2.13,4.16,8.17,8.17,0,0,1-4,2.18,26.45,26.45,0,0,1-6.39.64H48.25a26.37,26.37,0,0,1-6.38-.64,8.24,8.24,0,0,1-4-2.18,9,9,0,0,1-2.14-4.16A28.28,28.28,0,0,1,35.09,59V45.78a28.15,28.15,0,0,1,.63-6.48,9,9,0,0,1,2.14-4.16,8.33,8.33,0,0,1,4-2.18,26.31,26.31,0,0,1,6.38-.65H77.66a26.4,26.4,0,0,1,6.39.65,8.25,8.25,0,0,1,4,2.18,8.79,8.79,0,0,1,2.13,4.16,27.5,27.5,0,0,1,.65,6.48Z'
                />
                <path
                  style={{
                    fill:
                      this.props.fillMode === 'gradient'
                        ? 'url(#linear-gradient-2)'
                        : this.props.color
                  }}
                  d='M104.49,32.61a17.83,17.83,0,0,0-4.8-8.06A19.89,19.89,0,0,0,91.08,20a49.53,49.53,0,0,0-12.83-1.43H47.66A48.89,48.89,0,0,0,34.89,20a19.85,19.85,0,0,0-8.57,4.56,18.18,18.18,0,0,0-4.85,8.06,41.35,41.35,0,0,0-1.53,12.08V60a41.85,41.85,0,0,0,1.53,12.13,18.26,18.26,0,0,0,4.85,8.13,19.57,19.57,0,0,0,8.57,4.5,50.83,50.83,0,0,0,12.77,1.39H69.74l11.38,16.53a5.44,5.44,0,0,1,.36.48H99.22c-.23-.32-1.28-1.67-1.36-1.77L86.48,85.68q10.49-1.38,15-7.48T106,60V44.69A42.54,42.54,0,0,0,104.49,32.61ZM90.83,59a27.61,27.61,0,0,1-.65,6.48,8.79,8.79,0,0,1-2.13,4.16,8.17,8.17,0,0,1-4,2.18,26.45,26.45,0,0,1-6.39.64H48.25a26.37,26.37,0,0,1-6.38-.64,8.24,8.24,0,0,1-4-2.18,9,9,0,0,1-2.14-4.16A28.28,28.28,0,0,1,35.09,59V45.78a28.15,28.15,0,0,1,.63-6.48,9,9,0,0,1,2.14-4.16,8.33,8.33,0,0,1,4-2.18,26.31,26.31,0,0,1,6.38-.65H77.66a26.4,26.4,0,0,1,6.39.65,8.25,8.25,0,0,1,4,2.18,8.79,8.79,0,0,1,2.13,4.16,27.5,27.5,0,0,1,.65,6.48Z'
                />
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

const styles = theme => ({
  hide: {
    display: 'none'
  }
});

export default withRouter(withWidth()(withStyles(styles)(QuanturiPremiumSVG)));
