import { SET_DATATABLECONFIG } from 'redux/constants';
import initialState from 'redux/store/initialState';

export default function setDataTableConfig(state = initialState.dataTableConfig, action) {
  switch (action.type) {
    case SET_DATATABLECONFIG: {
      return {
        ...state,
        ...action.value
      };
    }
    default: {
      return state;
    }
  }
}
