import { AppBar, Tab, Tabs, CircularProgress } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles } from '@material-ui/core/styles';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { getMonitors, getFcItem } from 'api/serverAPI';
import { listStyle } from 'utils/sharedStyles';
import { isEmpty } from 'lodash';
import Appearance from './appearance/Appearance';
import Sensors from './sensors/Sensors';
import BaseStation from './basestation/BaseStation';
import CompostRCs from '../batches/controllers/CompostRCs';
import { SettingsProvider } from './SettingsContext';

const useStyles = makeStyles((theme) => ({
  hide: {
    display: 'none'
  },
  mainContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'hidden'
  },
  spinnerContainer: {
    display: 'flex',
    marginTop: 20,
    marginBottom: 20,
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      padding: 0
    }
  },
  accountView: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  }
}));

const TABS = ['preferences', 'probes', 'basestation', 'rc'];

const Settings = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const classes = useStyles();

  const [index, setIndex] = useState(0);

  const { i18n, isLoading, isGariUser, currentView, userInfo, storeFCs, isCompost } = useSelector(
    (store) => ({
      i18n: store.i18n,
      isLoading: store.isLoading,
      isGariUser: store.isGariUser,
      currentView: store.currentView,
      userInfo: store.userInfo,
      storeFCs: store.FcStatus,
      isCompost: store.isSupervisorCompost
    })
  );

  const listClasses = listStyle();

  const [monitors, setMonitors] = useState(null);
  const [RCs, setRCs] = useState(null);
  const [RCsChanged, setRCsChanged] = useState(false);

  const [isCompostRC] = useState(isCompost && !isGariUser && userInfo?.FC_IDs);

  useEffect(() => {
    if (isCompostRC && !isGariUser && !monitors) {
      const query = {
        UID: userInfo ? userInfo.U_ID : 'no_uid'
      };
      getMonitors(query)
        .then((response) => {
          console.log('Settings response getMonitors: ', response);
          setMonitors(response);
          // setMonitorsChanged(false);
        })
        .catch((error) => {
          console.log('Error in getMonitors: ', error);
        });
    }
    return () => {};
  }, [monitors, isCompostRC, isGariUser, userInfo]);

  useEffect(() => {
    const getFcItemFromAPI = async () => {
      try {
        const response = await getFcItem({ id: userInfo?.FC_IDs.join(',') });
        console.log('Settings response getFcItem', response);
        dispatch({ type: 'SET_FC_STATUS', value: response });
        setRCs(response);
        setRCsChanged(false);
      } catch (error) {
        console.log('Error in getFcItem: ', error);
      }
    };

    if (isCompostRC && !isGariUser && (!storeFCs || isEmpty(storeFCs) || RCsChanged)) {
      getFcItemFromAPI();
    } else {
      setRCs(storeFCs);
    }
  }, [RCs, isCompostRC, isGariUser, storeFCs, RCsChanged, userInfo?.FC_IDs, dispatch]);

  useEffect(() => {
    const getTab = () => {
      // Parsing the given route ex: /account/user
      const checkoutPath = pathname.split('/');
      // Subroute "user" is at the last position
      const pathTab = checkoutPath[checkoutPath.length - 1];
      const idx = pathTab === 'settings' ? 0 : TABS.findIndex((tab) => tab === pathTab);
      setIndex(idx);
    };
    if (currentView !== 'settings') {
      dispatch({ type: 'TOGGLE_VIEW', value: 'settings' });
      dispatch({ type: 'SET_GROUPDETAILS', value: null }); // clear possible storage selection
      getTab();
    }
  }, [currentView, dispatch, pathname]);

  // Handle tab access through router
  const handleChange = (event, value) => {
    event.preventDefault();
    setIndex(value);
    history.replace(`/settings/${TABS[value + (isGariUser ? 1 : 0)]}`);
  };

  // Handle swipeable view index change
  const handleChangeIndex = (idx) => setIndex(idx);

  const views = isCompostRC
    ? [
        <Appearance value={index} key='Appearance' />,
        <Sensors value={index} key='Sensors' />,
        <BaseStation value={index} key='BaseStation' />,
        <div className={listClasses.listMain} style={{ height: '100%' }} key='RCs'>
          <CompostRCs RCs={RCs} monitors={monitors} setRCsChanged={setRCsChanged} />
        </div>
      ]
    : [
        <Appearance value={index} key='Appearance' />,
        <Sensors value={index} key='Sensors' />,
        <BaseStation value={index} key='BaseStation' />
      ];
  if (isGariUser) {
    views.shift();
  }

  return (
    <div className={classes.mainContainer}>
      <div className={isLoading ? classes.spinnerContainer : classes.hide}>
        <CircularProgress color='primary' size={70} disableShrink />
      </div>
      <SettingsProvider>
        <AppBar className={isLoading ? classes.hide : ''} position='static' color='default'>
          <Tabs
            value={index}
            onChange={handleChange}
            indicatorColor='primary'
            textColor='primary'
            centered
          >
            {!isGariUser && <Tab label={i18n.preferences ?? 'Preferences'} />}
            <Tab label={i18n.sensors ?? 'Probes'} />
            <Tab label={i18n.bs ?? 'BaseStation'} />
            {isCompostRC && <Tab label={i18n.rc_tab ?? 'RC'} />}
          </Tabs>
        </AppBar>

        <SwipeableViews
          disabled
          className={isLoading ? classes.hide : classes.accountView}
          containerStyle={{
            height: '100%',
            transition: 'transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s'
          }}
          index={index}
          onChangeIndex={handleChangeIndex}
        >
          {views}
        </SwipeableViews>
      </SettingsProvider>
    </div>
  );
};

export default Settings;
