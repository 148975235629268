import {
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  FormGroup,
  Checkbox
} from '@material-ui/core';
import React from 'react';
import { isEmpty } from 'lodash';
import { DialogActionUtil } from 'utils/DialogActions';

export const AddEmailDialog = ({
  isAddEmailDialog,
  cancel,
  onSaveEmails,
  emails,
  handleSelectionChange,
  i18n
}) => (
  <Dialog open={isAddEmailDialog} aria-labelledby='responsive-dialog-title'>
    <DialogTitle>{i18n.sel_email || 'Select user email'}</DialogTitle>
    <DialogContent>
      <FormGroup>
        {emails.map((email) => (
          <FormControlLabel
            key={email.UID}
            control={
              <Checkbox
                disabled={isEmpty(email.emailAddress)}
                checked={!isEmpty(email.emailAddress) && email.checked}
                onChange={handleSelectionChange}
                name={email.UID}
              />
            }
            label={
              (email.emailName &&
                `${email.emailName} (${
                  isEmpty(email.emailAddress) ? i18n.n_a : email.emailAddress
                })`) ||
              email.emailAddress
            }
          />
        ))}
      </FormGroup>
    </DialogContent>
    <DialogActionUtil
      onCancel={() => cancel()}
      onSave={onSaveEmails}
      text={i18n.apply || 'Apply'}
    />
  </Dialog>
);
