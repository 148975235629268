import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useConfirm } from 'hook/useConfirm';
import adminChangesConfirm from 'utils/adminChangesConfirm';
import { celsiusToF } from 'assets/utils';
import { SETTINGS } from './SettingProps';
import { useStyles } from './Settings.styled';
import {
  DefaultSlider,
  MeasIntervalSlider,
  DefaultColorSlider,
  DefaultChartSlider,
  measRangeLogic
} from './helper';

const SettingsSliderDialog = ({
  i18n,
  degrees,
  id,
  data,
  selectedValue,
  editSettingsSlider,
  toggleSettings,
  saveSettingsSlider,
  marks
}) => {
  const classes = useStyles();
  const confirm = useConfirm();
  const settings = SETTINGS();
  const isAdmin = useSelector((state) => state.isAdmin);
  const bsConf = useSelector((state) => state.bsConf);
  const sessionInfo = useSelector((state) => state.sessionInfo);
  const basestation = useSelector((state) => state.baseStation);
  const [val, setVal] = useState(
    degrees === '°C'
      ? selectedValue
      : Array.isArray(selectedValue)
      ? selectedValue.map((v) => celsiusToF(v))
      : celsiusToF(selectedValue)
  );
  const [notTempSetting] = useState(
    id.includes('interval') || id === 'timeout' || id === 'buffer_max' || id === 'receiving_time'
  );

  /* Following setting ids are not temperature related, so no Fahrenheit conversion: 
      communication_interval
      measurement_interval_0
      timeout
      buffer_max
      receiving_time
*/
  useEffect(() => {
    setVal(
      notTempSetting || degrees === '°C'
        ? selectedValue
        : Array.isArray(selectedValue)
        ? selectedValue.map((v) => celsiusToF(v))
        : celsiusToF(selectedValue)
    );
  }, [degrees, id, notTempSetting, selectedValue]);

  useEffect(() => {
    if (settings[id].type === 'temp_slider') {
      if (degrees === '°C' && selectedValue < 0) {
        setVal(0);
      }
      if (degrees === '°C' && selectedValue > 100) {
        setVal(100);
      }
    }
  }, [settings, degrees, selectedValue, val, id]);

  const onSave = async () => {
    await adminChangesConfirm(isAdmin, confirm, settings[id].title, sessionInfo?.username);
    saveSettingsSlider(val, id);
  };

  const min =
    settings[id].type === 'temp_slider' && degrees === '°F'
      ? settings[id].range_f[0]
      : settings[id].range[0];

  const max =
    settings[id].type === 'temp_slider' && degrees === '°F'
      ? settings[id].range_f[1]
      : settings[id].range[1];

  const { intervalValues, range } = measRangeLogic(bsConf, basestation);

  return (
    <Dialog open={editSettingsSlider} fullWidth aria-labelledby='responsive-dialog-title'>
      <DialogTitle id={id}>
        <div>{i18n[id] || data[id].title || settings[id].title}</div>
      </DialogTitle>
      <DialogContent className={classes.dialogContentSlider} elevation={0}>
        <div id='slider_temp_container'>
          {id === 'def_color' ? (
            <DefaultColorSlider
              value={
                val && val.length === 5 ? val.map((v) => Math.round(+v)) : [0, 40, 60, 80, 100]
              }
              step={1}
              marks={marks}
              min={degrees === '°C' ? settings[id].range[0] : settings[id].range_f[0]}
              max={degrees === '°C' ? settings[id].range[1] : settings[id].range_f[1]}
              isf={degrees === '°C' ? 0 : 1}
              onChange={(e, newValue) => setVal(newValue)}
            />
          ) : id === 'def_chart' ? (
            <DefaultChartSlider
              value={val ? [Math.round(val[0]), Math.round(val[1])] : [20, 80]}
              step={5}
              marks={marks}
              min={degrees === '°C' ? settings[id].range[0] : settings[id].range_f[0]}
              max={degrees === '°C' ? settings[id].range[1] : settings[id].range_f[1]}
              onChange={(e, newValue) => setVal(newValue)}
            />
          ) : id.includes('measurement_interval') || id === 'tempIntervalTime' ? (
            <MeasIntervalSlider
              value={val}
              i18n={i18n}
              onChange={(e) => setVal(parseInt(e))}
              snap={!!intervalValues}
              range={range}
              inDialog
            />
          ) : (
            <DefaultSlider
              value={val >= 0 ? Math.round(val) : 45}
              step={1}
              marks={settings[id].type === 'temp_slider' ? marks : null}
              min={min}
              max={max}
              onChange={(e, newValue) => setVal(newValue)}
            />
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => toggleSettings()} color='primary'>
          {i18n.cancel}
        </Button>
        <Button onClick={onSave} color='secondary'>
          {i18n.ok}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SettingsSliderDialog;
