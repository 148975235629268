import React from 'react';
import FcCooldownRuleEditor from './FcCooldownRuleEditor';
import FcCycleRuleEditor from './FcCycleRuleEditor';
import FcManualRuleEditor from './FcManualRuleEditor';

const FcRuleEditor = ({
  QSlider,
  classes,
  i18n,
  degrees,
  selectedRuleType,
  selectedRule,
  setMonitorEdited,
  monitorEdited,
  newRule,
  setNewRule,
  setSelectedRuleType,
  setSelectRuleTypeDialog,
  setSelectedRule,
  ruleTypes,
  setRuleInUseEdited,
  monitorInUse,
  selectedMonitor,
  timeStamp,
  sensorConfigurations,
  FC_IDS,
  setToRunScheduler
}) => {
  const components = {
    COOLDOWN_CONTROL: FcCooldownRuleEditor,
    CYCLIC_CONTROL: FcCycleRuleEditor,
    MANUAL_CONTROL: FcManualRuleEditor
  };

  const RuleEditor = components[selectedRuleType?.type || 'COOLDOWN_CONTROL'];

  return (
    <RuleEditor
      QSlider={QSlider}
      classes={classes}
      i18n={i18n}
      degrees={degrees}
      selectedRuleType={selectedRuleType}
      selectedRule={selectedRule}
      setMonitorEdited={setMonitorEdited}
      monitorEdited={monitorEdited}
      newRule={newRule}
      setNewRule={setNewRule}
      setSelectedRuleType={setSelectedRuleType}
      setSelectRuleTypeDialog={setSelectRuleTypeDialog}
      setSelectedRule={setSelectedRule}
      ruleTypes={ruleTypes}
      setRuleInUseEdited={setRuleInUseEdited}
      monitorInUse={monitorInUse}
      selectedMonitor={selectedMonitor}
      timeStamp={timeStamp}
      sensorConfigurations={sensorConfigurations}
      FC_IDS={FC_IDS}
      setToRunScheduler={setToRunScheduler}
    />
  );
};

export default FcRuleEditor;
