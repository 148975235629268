import {
  format,
  startOfDay,
  endOfDay,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear
} from 'date-fns';

import {
  getLogs,
  getAccessLogs,
  getRCList,
  getBsCommData,
  getTestData,
  getUsers,
  getEarlyBsCallsLog,
  getGariCallLog,
  getEnv,
  getBsLogs,
  getBsLoggingList,
  getAdminTranslations,
  getValidityOverview
} from 'api/serverAPI';

export function getAgeString(agee) {
  let age = agee;
  let ageStr = '';
  if (Number.isNaN(age)) {
    return '';
  }
  age = Math.max(age, 0); // no negative ages displayed
  if (age < 60) {
    // was 100 before
    // minutes
    ageStr = `${Math.floor(age)} min`;
  } else {
    age /= 60; // hours
    if (age < 24) {
      ageStr = `${Math.floor(age)} h`;
    } else {
      age /= 24; // days

      ageStr = `${Math.floor(age)} d`;
    }
  }
  return ageStr;
}

export const getDateFormat = (date, noAgestring) => {
  const ageStr = noAgestring ? '' : `(${getAgeString((new Date().getTime() / 1000 - date) / 60)})`;
  return date && `${format(date * 1000, 'yyyy-MM-dd HH:mm:ss')} ${ageStr}`;
};

function firstAndLast(response) {
  const firstItem = response[0];
  const lastItem = response[response.length - 1];

  const objOutput = [];
  objOutput.push(firstItem.time * 1000, lastItem.time * 1000);

  return objOutput;
}

export const getDataForChart = (response) => {
  const dataValues = [];
  const beatValues = [];
  const bootValues = [];
  const confValues = [];
  const lastTS = [];
  let noData = false;
  if (response.length === 0) {
    noData = true;
    return { noData };
  }
  const domain = firstAndLast(response);
  response.forEach((res) => {
    if (res.type === 'data' || res.type === 'data3') {
      const itemData = {
        x: res.time * 1000,
        y: Math.max(0.1, res.bytes_up / 1000),
        conf: res.conf,
        length: res.length
      }; // , label: d.bytes_up / 1000
      if (res.length > 0) {
        dataValues.push(itemData);
      } else {
        beatValues.push(itemData);
      }
    } else if (res.type === 'boot') {
      const itemBoot = {
        x: res.time * 1000,
        y: Math.max(1, (res.bytes_down || 1000) / 1000),
        conf: res.conf
      }; // , label: d.bytes_up / 1000
      bootValues.push(itemBoot);
    } else if (res.type === 'conf' || res.type === 'conf3') {
      const itemConf = {
        x: res.time * 1000,
        y: Math.max(1, (res.bytes_down || 1000) / 1000),
        conf: res.conf
      }; // , label: d.bytes_up / 1000
      confValues.push(itemConf);
    }
    lastTS.push(res.time * 1000);
  });
  return {
    dataValues,
    beatValues,
    bootValues,
    confValues,
    lastTS,
    noData,
    domain
  };
};

export const genericAdminFunction = async (
  apiCall,
  setMessage,
  getRows,
  setData,
  setUpdateNeeded,
  props,
  customMessage,
  setCustomState,
  setNAux
) => {
  setMessage(customMessage || 'Updating...');
  if (setNAux) {
    setNAux(0);
  }
  try {
    const response = await apiCall(props);
    if (response.status_code !== 0) {
      setMessage('Error, check the console');
    } else {
      const rows = getRows(response.results);
      setData(rows);
      setMessage(null);
      if (setNAux && response.nAux) {
        setNAux(response.nAux);
      }
      if (setCustomState) {
        setCustomState(response.other);
      }
      if (setUpdateNeeded) {
        setUpdateNeeded(false);
      }
      return response.results;
    }
  } catch (err) {
    return null;
  }
};

export const getBsCommDataDataFromAPI = async (basestationID, offset, hours) => {
  let data = {};
  try {
    data = await getBsCommData({
      basestationID,
      offset,
      hours,
      getTS: true
    });
  } catch (err) {
    console.log('err: ', err);
    return;
  }
  return data;
};

export const getUsersDataFromAPI = async () => {
  let res = {};
  try {
    res = await getUsers();
  } catch (err) {
    console.log('err: ', err);
    return;
  }
  return res;
};

export const getTestDataFromAPI = async (BS_ID, lastUpdate) => {
  let res = {};
  const endTS = +endOfDay(lastUpdate) > +new Date() ? null : +endOfDay(lastUpdate) / 1000;
  try {
    res = await getTestData({ BS_ID, endTS });
    // console.log('res', res);
  } catch (err) {
    console.log('err: ', err);
    return;
  }
  return res;
};

export const getValidityOverviewFromAPI = async (BS_ID, fromDate, toDate) => {
  let res = {};
  console.log('getValidityOverviewFromAPI', fromDate, toDate);
  try {
    res = await getValidityOverview({
      BS_ID,
      startTS: fromDate.getTime() / 1000,
      endTS: toDate.getTime() / 1000
    });
    // console.log('res', res);
  } catch (err) {
    console.log('err: ', err);
    return;
  }
  return res;
};

export const getEarlyBsCallsLogDataFromAPI = async (props) => {
  let res = {};
  const startTS = +startOfMonth(props.lastUpdate) / 1000;
  const endTS = +endOfMonth(props.lastUpdate) / 1000;
  try {
    res = await getEarlyBsCallsLog({ startTS, endTS });
    // console.log('res', res);
  } catch (err) {
    console.log('err: ', err);
    return;
  }
  return res;
};

export const getGariLogsDataFromAPI = async (props) => {
  let res = {};
  const startTS = startOfYear(props.lastUpdate);
  const endTS = endOfYear(props.lastUpdate);
  try {
    res = await getGariCallLog({ startTS, endTS });
    // console.log('res', res);
  } catch (err) {
    console.log('err: ', err);
    return;
  }
  return res;
};

export const getLogsDataFromAPI = async (props) => {
  let res = {};
  const startTS = startOfDay(props.lastUpdate) / 1000;
  const endTS = endOfDay(props.lastUpdate) / 1000;
  try {
    res = await getLogs({
      server: props.server,
      env: getEnv(process.env.REACT_APP_API_ENV || ''),
      timeFilter: [startTS, endTS]
    });
    // console.log('res', res);
  } catch (err) {
    console.log('err: ', err);
    return;
  }
  if (!props.showAux) {
    const auxFiltered = res.results.filter(
      (log) => typeof log.message !== 'string' || log.message.substr(0, 7) !== 'AuxData'
    );
    const nAux = res.results.length - auxFiltered.length;
    return {
      ...res,
      results: auxFiltered,
      nAux
    };
  }
  return res;
};

export const getAccessLogsDataFromAPI = async (props) => {
  let res = {};
  const startTS = startOfDay(props.lastUpdate) / 1000;
  const endTS = endOfDay(props.lastUpdate) / 1000;
  try {
    res = await getAccessLogs({
      server: props.server,
      env: getEnv(process.env.REACT_APP_API_ENV || ''),
      timeFilter: [startTS, endTS]
    });
    // console.log('res', res);
  } catch (err) {
    console.log('err: ', err);
    return;
  }
  return res;
};

export const getRCListFromAPI = async () => {
  let res = {};
  try {
    res = await getRCList();
    // console.log('res', res);
  } catch (err) {
    console.log('err: ', err);
    return;
  }
  return res;
};

export const getBsLogsDataFromAPI = async (props) => {
  let res = {};
  const startTS = +startOfDay(props.lastUpdate) / 1000;
  const endTS = +endOfDay(props.lastUpdate) / 1000;
  try {
    res = await getBsLogs({ basestationID: props.selectedBS, startTS, endTS });
    // console.log('res', res);
  } catch (err) {
    console.log('err: ', err);
    return;
  }
  return res;
};

export const getLoggingBsFromAPI = async () => {
  let res = {};
  try {
    res = await getBsLoggingList();
    // console.log('res', res);
  } catch (err) {
    console.log('err: ', err);
    return;
  }
  return res;
};

export const getTranslationsFromAPI = async (props) => {
  let res = {};
  try {
    res = await getAdminTranslations({ locale: props.locale, isAdmin: true });
    // console.log('res', res);
  } catch (err) {
    console.log('err: ', err);
    return;
  }
  return res;
};
