import { SET_OVERVIEWDATA, SET_CHARTDATA, RESET_CHARTDATA } from 'redux/constants';
import initialState from 'redux/store/initialState';

export default function setChartData(state = initialState.chartData, action) {
  switch (action.type) {
    case SET_OVERVIEWDATA: {
      return {
        ...state,
        snapshots: action.snapshots
      };
    }
    case SET_CHARTDATA: {
      return {
        ...state,
        ...action.fullChartData,
        isSnapShot: action.isSnapShot,
        tempSensorData: action.sensorData
      };
    }
    case RESET_CHARTDATA: {
      return {};
    }
    default: {
      return state;
    }
  }
}
