import {
  Button,
  TextField,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  InputAdornment,
  Link
} from '@material-ui/core';

import React, { useState } from 'react';
import { isEmpty } from 'lodash';

import SwipeableViews from 'react-swipeable-views';
import PinInput from 'react-pin-input';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';

import { getPasswordCodeByUsername, postResetPassword } from 'api/serverAPI';

import ForgetUsernameDialog from './ForgetUsernameDialog';
import { useDialogStyles } from './Login.styled';

const EnterUserNameView = ({
  i18n,
  resetUsername,
  onCancel,
  handleChange,
  resetPassword,
  resetErrorMsg,
  toggleGetUsernameDialog
}) => {
  const classes = useDialogStyles();

  return (
    <>
      <DialogContent elevation={0}>
        <div className={classes.resetView}>
          <Grid container justifyContent='space-around'>
            <Typography className={classes.dialogMessage}>{i18n.enter_username}</Typography>
            <TextField
              id='reset_username'
              name='resetUsername'
              label={i18n.username}
              style={{ margin: 8 }}
              value={resetUsername}
              error={!isEmpty(resetErrorMsg)}
              onChange={handleChange}
              fullWidth
            />
            <Typography color='error'>{!isEmpty(resetErrorMsg) && resetErrorMsg}</Typography>
          </Grid>
          <Grid item align='right'>
            <Link color='secondary' onClick={toggleGetUsernameDialog}>
              <span>{i18n.forgot_username}</span>
            </Link>
          </Grid>
        </div>
      </DialogContent>

      <DialogActions>
        <Button onClick={onCancel} color='primary'>
          {i18n.cancel}
        </Button>
        <Button onClick={resetPassword} color='secondary'>
          {i18n.next}
        </Button>
      </DialogActions>
    </>
  );
};

const ResetPasswordView = ({
  i18n,
  resetUsername,
  onCancel,
  resetPassword,
  resetErrorMsg,
  toggleResetPasswordDialog,
  login
}) => {
  const classes = useDialogStyles();
  const [state, setState] = useState({
    errorNew: false,
    errorNewMsg: '',
    errorConfirm: false,
    errorConfirmMsg: '',
    tempCode: '',
    tempNew: { value: '', show: false },
    tempConfirm: { value: '', show: false },
    resetError: false,
    resetErrorMsg: '',
    isForgetUsernameDialogOpened: false
  });

  const handleChangePassword = (e) => {
    e.preventDefault();
    const { show } = state[`temp${e.target.id}`];
    setState({ ...state, [`temp${e.target.id}`]: { value: e.target.value, show } });
  };

  const handleShowPassword = (id) => {
    const { show } = state[`temp${id}`];
    const { value } = state[`temp${id}`];
    setState({ ...state, [`temp${id}`]: { value, show: !show } });
  };

  const handleChangeCode = (value) => {
    setState({ ...state, tempCode: value });
  };

  const resetPasswordAgain = () => {
    setState({ ...state, tempCode: '' });
    resetPassword();
  };

  const confirmReset = async () => {
    const { tempCode, tempNew, tempConfirm } = state;
    if (tempNew.value !== tempConfirm.value) {
      setState({
        ...state,
        errorNew: true,
        errorConfirm: true,
        errorConfirmMsg: i18n.passwords_not_matching,
        resetError: false,
        resetErrorMsg: ''
      });
      return;
    }
    if (tempNew.value.length < 5) {
      setState({
        ...state,
        errorNew: true,
        errorNewMsg: i18n.pwd_too_short,
        resetError: false,
        resetErrorMsg: ''
      });
      return;
    }

    if (tempConfirm.value.length < 5) {
      setState({
        ...state,
        errorConfirm: true,
        errorConfirmMsg: i18n.pwd_too_short,
        resetError: false,
        resetErrorMsg: ''
      });
      return;
    }

    if (tempNew.value === tempConfirm.value) {
      setState({
        ...state,
        errorNew: false,
        errorNewMsg: '',
        errorConfirm: false,
        errorConfirmMsg: '',
        resetError: false,
        resetErrorMsg: ''
      });
    }

    const reset = {
      resetCode: tempCode,
      username: resetUsername,
      newPassword: tempNew.value
    };

    const response = await postResetPassword(reset);
    if (response.status_code === 0) {
      setState({
        ...state,
        reset: false,
        errorNew: false,
        errorConfirm: false,
        resetError: false
      });
      toggleResetPasswordDialog();
      login(null, resetUsername, tempNew.value);
    } else {
      setState({
        ...state,
        errorNew: false,
        errorNewMsg: '',
        errorConfirm: false,
        errorConfirmMsg: '',
        resetError: true,
        resetErrorMsg: i18n.reset_code_error || 'error'
      });
    }
  };

  return (
    <>
      <DialogContent elevation={0}>
        <div className={classes.resetView}>
          <Grid
            container
            spacing={10}
            alignItems='center'
            direction='column'
            justifyContent='center'
          >
            <Grid item>
              <Typography className={classes.dialogMessage}>{i18n.enter_reset_code}</Typography>

              <PinInput
                length={6}
                onChange={handleChangeCode}
                type='numeric'
                style={{
                  display: 'block',
                  width: 225,
                  margin: '15px auto'
                }}
                inputStyle={{
                  width: 30,
                  fontSize: 20,
                  fontFamily: "'Roboto', sans-serif",
                  borderColor: 'rgba(0,0,0,0)',
                  borderBottom: '1px solid rgba(0,0,0,0.42)'
                }}
                inputFocusStyle={{
                  width: 30,
                  fontSize: 20,
                  fontFamily: "'Roboto', sans-serif",
                  borderColor: 'rgba(0,0,0,0)',
                  borderBottom: '2px solid #252525'
                }}
              />

              <Typography color='error'>
                {(!isEmpty(state.resetErrorMsg) && resetErrorMsg) ||
                  (state.resetError && state.resetErrorMsg)}
              </Typography>

              <Typography className={classes.dialogPasswordMessage}>
                {i18n.enter_password}
              </Typography>

              <TextField
                id='New'
                label={i18n.new_password}
                type={state.tempNew.show ? 'value' : 'password'}
                value={state.tempNew.value}
                // error={state.errorNew}
                onChange={handleChangePassword}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton onClick={() => handleShowPassword('New')}>
                        {state.tempNew.show ? <MdVisibility /> : <MdVisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />

              <Typography className={classes.password} color='error'>
                {state.errorNew ? state.errorNewMsg : ''}
              </Typography>

              <TextField
                id='Confirm'
                label={i18n.confirm_password}
                type={state.tempConfirm.show ? 'value' : 'password'}
                value={state.tempConfirm.value}
                // error={state.errorConfirm}
                onChange={handleChangePassword}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton onClick={() => handleShowPassword('Confirm')}>
                        {state.tempConfirm.show ? <MdVisibility /> : <MdVisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />

              <Typography color='error'>
                {state.errorConfirm ? state.errorConfirmMsg : ''}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </DialogContent>

      <DialogActions>
        <Button onClick={resetPasswordAgain} color='primary'>
          {i18n.resend_sms}
        </Button>
        <div className={classes.grow} />
        <Button onClick={onCancel} color='primary'>
          {i18n.cancel}
        </Button>
        <Button onClick={confirmReset} color='secondary'>
          {i18n.ok}
        </Button>
      </DialogActions>
    </>
  );
};

const ForgetPasswordDialog = ({
  i18n,
  isForgetPasswordDialogOpened,
  toggleResetPasswordDialog,
  login
}) => {
  // const dispatch = useDispatch();
  const classes = useDialogStyles();

  const [state, setState] = useState({
    resetIndex: 0,
    resetUsername: '',
    resetErrorMsg: ''
  });

  const onCancel = () => {
    setState({ ...state, resetIndex: 0 });
    toggleResetPasswordDialog();
  };

  const handleChangeUserName = (e) => {
    e.preventDefault();
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const resetPassword = async () => {
    const response = await getPasswordCodeByUsername({ username: state.resetUsername });
    if (response.status_code === 0) {
      setState({ ...state, resetIndex: 1, resetErrorMsg: '' });
    } else if (response.status_code === 1003) {
      // Wrong username
      setState({ ...state, resetErrorMsg: i18n.username_error });
    } else if (response.status_code === 1004) {
      // Problem with the phone number
      setState({ ...state, resetErrorMsg: i18n.phone_not_confirmed_msg });
    } else if (response.status_code === 1007) {
      // Too many codes
      setState({ ...state, resetErrorMsg: i18n.too_many_codes });
    } else if (response.status_code === 1006) {
      // Reset code is incorrect
      setState({ ...state, resetErrorMsg: i18n.reset_code_error });
    } else {
      // dispatch({ type: 'SET_LOGIN', value: false });
    }
  };

  const toggleGetUsernameDialog = () => {
    setState((tempState) => ({
      ...tempState,
      isForgetUsernameDialogOpened: !state.isForgetUsernameDialogOpened
    }));
  };

  return (
    <Dialog
      className={classes.dialog}
      open={isForgetPasswordDialogOpened}
      aria-labelledby='responsive-dialog-title'
    >
      <DialogTitle id='reset-password'>{i18n.reset_password}</DialogTitle>
      <SwipeableViews disabled index={state.resetIndex} className={classes.dialogContent}>
        <EnterUserNameView
          i18n={i18n}
          resetUsername={state.resetUsername}
          onCancel={onCancel}
          resetPassword={resetPassword}
          handleChange={handleChangeUserName}
          resetErrorMsg={state.resetErrorMsg}
          toggleGetUsernameDialog={toggleGetUsernameDialog}
        />

        <ResetPasswordView
          i18n={i18n}
          resetUsername={state.resetUsername}
          onCancel={onCancel}
          resetPassword={resetPassword}
          resetErrorMsg={state.resetErrorMsg}
          setResetErrorMsg={setState}
          toggleResetPasswordDialog={toggleResetPasswordDialog}
          login={login}
        />
      </SwipeableViews>
      {state.isForgetUsernameDialogOpened && (
        <ForgetUsernameDialog
          i18n={i18n}
          isForgetUsernameDialogOpened={state.isForgetUsernameDialogOpened}
          toggleGetUsernameDialog={toggleGetUsernameDialog}
        />
      )}
    </Dialog>
  );
};

export default ForgetPasswordDialog;
