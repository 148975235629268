import React from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dialog, Grow } from '@material-ui/core';
import DialogTitleWithClose from 'utils/DialogWithCloseIcon';
import ExpiredInsightFreemium from 'components/general/ExpiredInsightFreemium';
import ServiceRenewalDialog from './ServiceRenewalDialog';

const ServiceRenewal = ({ unauthorized }) => {
  const history = useHistory();
  const userInfo = useSelector((state) => state.userInfo);
  const i18n = useSelector((store) => store.i18n);
  const { trial } = userInfo.service;
  const oldFreemiumExpiredTrial = userInfo.service && (trial === 2 || trial === -1);

  // 6.5.24: FR Insight users not to anymore pay online but by invoice.
  const isFrenchInsight = userInfo.service && userInfo.country === 'fr';

  // Regarding service subscription renewal, please contact
  // Concernant le renouvellement du service, veuillez contacter
  const frTitle = i18n.msg_service_subscription;
  const frText = (
    <div>
      {i18n.msg_fr_pleasecontact}{' '}
      <a
        href={`mailto:support@quanturi.com?subject=${i18n.msg_service_subscription} - ${userInfo.bs_BS_IDs[0]}`}
      >
        support@quanturi.com
      </a>
      .
    </div>
  );

  const onCloseFrench = () => {
    history.replace('/account/services');
  };

  return isFrenchInsight ? (
    <Dialog
      open
      maxWidth='md'
      PaperProps={{ style: { overflow: 'auto' } }}
      TransitionComponent={Grow}
    >
      <DialogTitleWithClose
        style={{ minWidth: 300 }}
        onClose={() => {
          onCloseFrench();
        }}
      >
        {frTitle}
      </DialogTitleWithClose>
      <div style={{ padding: 20, paddingBottom: 40 }}>{frText}</div>
    </Dialog>
  ) : (
    <>
      {ReactDOM.createPortal(
        oldFreemiumExpiredTrial ? (
          <ExpiredInsightFreemium />
        ) : (
          <ServiceRenewalDialog unauthorized={unauthorized} />
        ),
        document.getElementById('dialog-root')
      )}
    </>
  );
};

export default ServiceRenewal;
