import { SET_BATCHES } from 'redux/constants';
import initialState from 'redux/store/initialState';

export default function setBatches(state = initialState.batches, action) {
  switch (action.type) {
    case SET_BATCHES: {
      return action.value;
    }
    default: {
      return state;
    }
  }
}
