import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { size } from 'lodash';
import { AdminTable } from '../helpers/TableComponents';
import { genericAdminFunction, getTranslationsFromAPI } from '../helpers/helper';
import { TopBarTranslations, TranslationsDetails } from './TranslationsDetails';

const headCells = [
  { id: 'id', label: 'ID' },
  { id: 'key', label: 'Key' },
  { id: 'text', label: 'Text' },
  { id: 'category', label: 'Category' }
];

const Translations = ({ i18n, setData, setMessage }) => {
  const userInfo = useSelector((state) => state.userInfo);
  const sessionInfo = useSelector((state) => state.sessionInfo);
  const rootAdmin = sessionInfo?.admin_group === 0;
  const specialTranslator = userInfo.translator || sessionInfo.translator;

  const [locale, setLocale] = useState(specialTranslator ? specialTranslator[0] : i18n.locale);
  const [categories, setCategories] = useState([]);

  // Data To Update according to the table
  const barTitle = `Translations (${locale})`;
  const defaultOrderBy = 'id';
  const defaultOrder = 'desc';
  const withSubTable = true;
  const selectableRow = true;

  const paginationLimit = 20;
  const rowsPerPageOptions = [20, 50, 100, { label: i18n.all || 'All', value: -1 }];
  // const noFooterNeeded = true;
  const [openedDialog, setOpenedDialog] = useState(false);
  const [detailsData, setDetailsData] = useState(null);
  const [updatedNeeded, setUpdateNeeded] = useState(true);

  const [dataAPI, setDataAPI] = useState([]);

  const allCategories = {
    // Value '0' is for all categories option
    value: 0,
    label: `${'All categories'} (${size(dataAPI)})`
  };

  const categoryOptions = [allCategories];

  categories.forEach((c) => {
    categoryOptions.push({
      value: c,
      label: `${c} (${size(dataAPI.filter((tr) => tr.category === c))})`
    });
  });

  const [selectedCategory, setSelectedCategory] = useState(allCategories);

  const categorySelectionChange = (selection) => {
    setSelectedCategory(categoryOptions.find((c) => c.value === selection));
    if (selection === 0) {
      setData(dataAPI);
    } else {
      setData(dataAPI.filter((d) => d.category === selection));
    }
  };

  useEffect(() => {
    const getRows = (d) => {
      const translationsArray = [];
      d.forEach((k, i) =>
        translationsArray.push({
          id: i,
          key: k.key,
          text: k.text,
          category: k.category
        })
      );
      setDataAPI(translationsArray);
      return translationsArray;
    };

    const getTranslations = async () => {
      const props = { locale };
      await genericAdminFunction(
        getTranslationsFromAPI,
        setMessage,
        getRows,
        setData,
        setUpdateNeeded,
        props,
        null,
        setCategories
      );
      if (selectedCategory.value) {
        setData(dataAPI.filter((data) => data.category === selectedCategory.value));
      }
    };

    if (updatedNeeded) {
      getTranslations();
    }
  }, [i18n, updatedNeeded, setData, setMessage, locale, selectedCategory.value, dataAPI]);

  const customClick = (row, open, setOpen) => {
    if (open === false) {
      setDetailsData(row);
      setOpenedDialog(true);
      setOpen(false);
    }
  };

  const [newTranslation, setNewTranslation] = useState(false);

  const onOpenDialog = (newTrt) => {
    setOpenedDialog(true);
    if (newTrt) {
      setNewTranslation(true);
    }
  };

  const DetailsDialog = () => (
    <TranslationsDetails
      detailsData={detailsData}
      locale={locale}
      i18n={i18n}
      openedDialog={openedDialog}
      setOpenedDialog={setOpenedDialog}
      setUpdateNeeded={setUpdateNeeded}
      categories={categories}
      rootAdmin={rootAdmin}
      newTranslation={newTranslation}
      setNewTranslation={setNewTranslation}
    />
  );

  const [translatorMode, setTranslatorMode] = useState(false);

  const AdditionalComponent = () => (
    <TopBarTranslations
      locale={locale}
      setLocale={setLocale}
      setData={setData}
      dataAPI={dataAPI}
      categoryOptions={categoryOptions}
      selectedCategory={selectedCategory}
      categorySelectionChange={categorySelectionChange}
      setDetailsData={setDetailsData}
      onOpenDialog={onOpenDialog}
      setUpdateNeeded={setUpdateNeeded}
      specialTranslator={specialTranslator}
      translatorMode={translatorMode}
      setTranslatorMode={setTranslatorMode}
      i18nLocale={i18n.locale}
      rootAdmin={rootAdmin}
    />
  );
  return {
    AdditionalComponent,
    barTitle,
    defaultOrderBy,
    defaultOrder,
    paginationLimit,
    rowsPerPageOptions,
    withSubTable,
    selectableRow,
    DetailsDialog,
    customClick
  };
};

const AdminUserTable = () => <AdminTable AdminCustom={Translations} headCells={headCells} />;

export default AdminUserTable;
