import {
  useMediaQuery,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Typography,
  InputAdornment,
  Tooltip
} from '@material-ui/core';
import React, { useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { MdVisibility, MdVisibilityOff } from 'react-icons/md';

import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useConfirm } from 'hook/useConfirm';
import adminChangesConfirm from 'utils/adminChangesConfirm';

import { postChangeUserPassword } from 'api/serverAPI';

import { accountPasswordStyles } from 'utils/sharedStyles';

const initialState = {
  temp_old: { value: '', show: false },
  temp_new: { value: '', show: false },
  temp_confirm: { value: '', show: false }
};

const ChangePasswordDialog = ({ editPassword, togglePassword }) => {
  const { i18n, isAdmin, sessionInfo, isDemoMode } = useSelector((store) => ({
    i18n: store.i18n,
    isAdmin: store.isAdmin,
    sessionInfo: store.sessionInfo,
    isDemoMode: store.isDemoMode
  }));

  const changePasswordSchema = yup.object().shape({
    oldPassword: yup.string().required(i18n.old_pwd_required || 'Old password is required'),
    newPassword: yup
      .string()
      .required(i18n.new_pwd_required || 'New password is required')
      .min(5, i18n.pwd_too_short || 'Password is quite short'),
    // .max(32, i18n.pwd_too_long || 'Password is quite long'),
    confirmPassword: yup
      .string()
      .oneOf(
        [yup.ref('newPassword'), null],
        i18n.new_conf_pwd_match || 'New and confirmed passwords must match'
      )
      .required(i18n.conf_pws_required || 'Confirmed password is required')
  });

  const classes = accountPasswordStyles();
  const confirm = useConfirm();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm({
    resolver: yupResolver(changePasswordSchema)
  });

  const isXsWidth = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const [state, setState] = useState(initialState);

  const handleChange = (e, id) => {
    const { show } = state[`temp_${id}`];
    setState({
      ...state,
      [`temp_${id}`]: { value: e.target.value, show }
    });
  };

  const handleShowPassword = (id) => {
    const { show, value } = state[`temp_${id}`];
    setState({
      ...state,
      [`temp_${id}`]: { value, show: !show }
    });
  };

  const _savePassword = async (password) => {
    let responsePassword;
    try {
      responsePassword = await postChangeUserPassword(password);
    } catch (err) {
      console.log('err: ', err);
      return;
    }

    switch (responsePassword.status_code) {
      case 1003:
        setError('oldPassword', {
          type: 'notMatch',
          message: i18n.old_password_incorrect
        });
        break;
      case 1009:
        setError('oldPassword', {
          type: 'notMatch',
          message: i18n.old_password_incorrect
        });
        break;
      case 1001:
        setError('oldPassword', {
          type: 'required',
          message: i18n.one_password_missing
        });
        break;
      case 0:
      default:
        togglePassword();
        break;
    }
  };

  const onSave = async () => {
    const password = {
      oldPassword: state.temp_old.value,
      newPassword: state.temp_new.value
    };

    if (isAdmin) {
      password.UID = sessionInfo.UID;
      password.username = sessionInfo.username;
    }
    await adminChangesConfirm(isAdmin, confirm, 'password', sessionInfo.username);
    _savePassword(password);
  };

  return (
    <Dialog fullScreen={isXsWidth} open={editPassword} aria-labelledby='responsive-dialog-title'>
      <DialogTitle id='change_password'>{i18n.change_password}</DialogTitle>
      <DialogContent className={classes.dialogContentPassword} elevation={0}>
        <TextField
          name='oldPassword'
          {...register('oldPassword')}
          label={i18n.old_password}
          type={state.temp_old.show ? 'value' : 'password'}
          value={state.temp_old.value}
          error={!!errors.oldPassword}
          onChange={(e) => handleChange(e, 'old')}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton onClick={() => handleShowPassword('old')}>
                  {state.temp_old.show ? <MdVisibility /> : <MdVisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />

        <Typography className={classes.password} color='error'>
          {errors.oldPassword?.message}
        </Typography>
        <TextField
          name='newPassword'
          {...register('newPassword')}
          label={i18n.new_password}
          type={state.temp_new.show ? 'value' : 'password'}
          value={state.temp_new.value}
          error={!!errors.newPassword}
          onChange={(e) => handleChange(e, 'new')}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton onClick={() => handleShowPassword('new')}>
                  {state.temp_new.show ? <MdVisibility /> : <MdVisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <Typography className={classes.password} color='error'>
          {errors.newPassword?.message}
        </Typography>
        <TextField
          name='confirmPassword'
          {...register('confirmPassword')}
          label={i18n.confirm_password}
          type={state.temp_confirm.show ? 'value' : 'password'}
          value={state.temp_confirm.value}
          error={!!errors.confirmPassword}
          onChange={(e) => handleChange(e, 'confirm')}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton onClick={() => handleShowPassword('confirm')}>
                  {state.temp_confirm.show ? <MdVisibility /> : <MdVisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <Typography className={classes.password} color='error'>
          {errors.confirmPassword?.message}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={togglePassword} color='primary'>
          {i18n.cancel}
        </Button>
        <Tooltip title={isDemoMode ? i18n.demo_not_allowed || 'Not available in the demo' : ''}>
          <span style={isDemoMode ? { cursor: 'not-allowed', color: 'lightgray' } : {}}>
            <Button
              onClick={!isDemoMode ? handleSubmit(onSave) : undefined}
              disabled={isDemoMode}
              color='secondary'
            >
              {i18n.ok}
            </Button>
          </span>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default ChangePasswordDialog;
