import React from 'react';
import { startOfDay } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { tempFormat } from 'assets/utils';
import { COLORS } from 'utils/colors';
import DayTrendBar from './DayTrendBar';

const useStyles = makeStyles(() => ({
  trendChart: {
    // border: '1px solid white',
    display: 'flex',
    color: COLORS.primaryColor
  },
  tempScale: {
    width: 10,
    borderTop: '1px solid #ccc',
    borderRight: '1px solid #ccc',
    borderBottom: '1px solid #ccc',
    justifyContent: 'space-between',
    display: 'flex',
    flexFlow: 'column',
    fontSize: 11,
    marginLeft: 25
  },
  scaleFigure: {
    margin: '4px 4px 2px -25px',
    textAlign: 'right'
  }
}));

const SECS_IN_DAY = 24 * 60 * 60;

export default function TemperatureTrendChartlet({
  itemID,
  nDays,
  minmaxData,
  sensorIDs,
  nPoints,
  depth,
  isAggregate,
  scaleMin,
  scaleMax,
  isInteractive,
  chartHeight
}) {
  const classes = useStyles();
  // const i18n = useSelector(store => store.i18n);
  const degrees = useSelector((store) => store.sessionInfo?.ui_preferences?.degrees);

  // We display the chart of nDays from today backwards.
  // We know that the minmaxData dates (field b) are the start of day dates

  // construct an array of timestamps of the minmaxData.

  const today = Math.round(+startOfDay(new Date()) / 1000);

  // [6, 5, 4, 3, 2, 1, 0]
  const dayBarDates = Array.from(Array(nDays).keys())
    .reverse()
    .map((dayOffset) => today - dayOffset * SECS_IN_DAY);

  return (
    <div className={classes.trendChart}>
      <div className={classes.tempScale} style={{ height: chartHeight || 80 }}>
        <div className={classes.scaleFigure}>{`${tempFormat(degrees, scaleMax, 0)}${degrees}`}</div>
        <div className={classes.scaleFigure}>{`${tempFormat(degrees, scaleMin, 0)}${degrees}`}</div>
      </div>
      {dayBarDates.map((ts, index) => (
        <DayTrendBar
          itemID={itemID}
          key={ts}
          timestamp={ts}
          scaleMin={scaleMin}
          scaleMax={scaleMax}
          dayData={minmaxData
            .filter((minmax) => sensorIDs.indexOf(minmax.a) >= 0)
            .filter((minmax) => minmax.b >= ts && minmax.b < ts + SECS_IN_DAY)}
          prevDayData={minmaxData
            .filter((minmax) => sensorIDs.indexOf(minmax.a) >= 0)
            .filter((minmax) => minmax.b >= ts - SECS_IN_DAY && minmax.b < ts)}
          isAggregate={isAggregate}
          nPoints={nPoints}
          depth={depth}
          height={chartHeight || 80}
          isLastDay={index === dayBarDates.length - 1}
          isFirstDay={index === 0}
          isInteractive={isInteractive}
        />
      ))}
    </div>
  );
}
