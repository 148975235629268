import React from 'react';
import {
  SurveyRadio,
  SurveySlider,
  SurveyToggleButton
} from 'components/account/account-users/account-background/helpers';

const SurveyScale = ({ i18n, state, setState }) => {
  const WeightUnit = ({ id }) => (
    <div style={{ maxWidth: 500 }}>
      <SurveyRadio i18n={i18n} id={id} info={state[id]} setState={setState} />
    </div>
  );

  const HayTypeUnit = ({ id }) => (
    <div style={{ maxWidth: 500 }}>
      <SurveyRadio i18n={i18n} id={id} info={state[id]} setState={setState} />
    </div>
  );

  const AverageBales = ({ id }) => (
    <div style={{ maxWidth: 500 }}>
      <SurveySlider
        i18n={i18n}
        id={id}
        info={state[id]}
        setState={setState}
        label={i18n.hayTypeUnit_option_bales}
      />
    </div>
  );

  const AverageTons = ({ id }) => (
    <div style={{ maxWidth: 500 }}>
      <SurveySlider
        i18n={i18n}
        id={id}
        info={state[id]}
        setState={setState}
        label={i18n.hayTypeUnit_option_tons}
      />
    </div>
  );

  const BalesType = ({ id }) => (
    <SurveyToggleButton i18n={i18n} id={id} info={state[id]} setState={setState} />
  );

  const BaleWeight = ({ id }) => (
    <div style={{ maxWidth: 500 }}>
      <SurveySlider
        i18n={i18n}
        id={id}
        info={state[id]}
        setState={setState}
        label={state.weightUnit.value}
      />
    </div>
  );
  return (
    <div>
      <WeightUnit id='weightUnit' />
      {state.hayType.value === 'bales' ? (
        <>
          <HayTypeUnit id='hayTypeUnit' />
          {state.hayTypeUnit.value === 'bales' ? (
            <AverageBales id='balesPerYear' />
          ) : (
            <AverageTons id='averageTonsPerYear' />
          )}
          <BalesType id='balesType' />
          <BaleWeight id='balesWeight' />
        </>
      ) : (
        <AverageTons id='averageTonsPerYear' />
      )}
    </div>
  );
};

export default SurveyScale;
