import { Tooltip } from '@material-ui/core';
import React, { useRef, useState, useEffect } from 'react';
import * as THREE from 'three';
import { Html } from '@react-three/drei';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import { InlineIcon } from '@iconify/react';
// import viewGrid from '@iconify/icons-mdi/view-grid';
// import viewGridOutline from '@iconify/icons-mdi/view-grid-outline';
import checkboxMultipleBlank from '@iconify/icons-mdi/checkbox-multiple-blank';
import checkboxMultipleBlankOutline from '@iconify/icons-mdi/checkbox-multiple-blank-outline';
import bellIcon from '@iconify/icons-mdi/bell';
import warningIcon from '@iconify/icons-mdi/arrow-down-circle-outline';
import alertIcon from '@iconify/icons-mdi/arrow-down-circle';
import { formatSensorRow, getDateString, celsiusToF } from 'assets/utils';
import { COLORS } from 'utils/colors';
import * as storageConstants from '../storage-utils/storageConstants';

const useStyles = makeStyles(() => ({
  probeLabel: {
    backgroundColor: COLORS.primaryColor,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'white',
    color: 'white',
    borderRadius: 3,
    padding: '3px 5px',
    fontSize: 13,
    textAlign: 'center',
    position: 'absolute',
    left: 25,
    top: -20,
    opacity: 0.7,
    cursor: 'default'
  },
  alertLabel: {
    borderColor: COLORS.alert,
    opacity: 1
  },
  permanentLabel: {
    fontSize: 22,
    left: -33,
    top: 20,
    padding: '3px 10px'
  },
  gridLabel: {
    backgroundColor: COLORS.primaryColor,
    border: '1px solid white',
    color: 'white',
    borderRadius: 3,
    padding: '4px 8px',
    fontSize: 15,
    minWidth: 12,
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'yellow',
      color: COLORS.primaryColor,
      borderColor: COLORS.primaryColor
    }
  },
  selected: {
    backgroundColor: '#cccc00'
  },
  toggleButton: {
    borderRadius: 20,
    backgroundColor: COLORS.darkGray,
    height: 32,
    width: 32,
    boxSizing: 'border-box',
    lineHeight: '34px',
    padding: 0,
    '&:hover': {
      backgroundColor: COLORS.primaryColor,
      color: 'white',
      borderColor: 'white'
    }
  },
  alertSpot: {
    // backgroundColor: '#ffffff99',
    width: 17,
    height: 17,
    borderRadius: 10,
    position: 'absolute',
    // left: -8,
    bottom: -7,
    backgroundColor: 'white'
  },
  alertIcon: {
    width: 15,
    height: 15,
    color: COLORS.alert,
    position: 'absolute',
    left: 1,
    top: 0,
    zIndex: 100
  },
  valueAlertIcon: {
    width: 18,
    height: 18,
    color: COLORS.alert,
    position: 'absolute',
    left: 1,
    top: 0,
    zIndex: 100,
    backgroundColor: 'white',
    borderRadius: 10
  },
  alertIconBg: {
    color: '#ffffff99',
    width: 19,
    height: 19,
    position: 'absolute',
    left: -1,
    top: -2,
    zIndex: 99
  }
}));

export const AlertSpot = (props) => {
  const classes = useStyles();
  const { sid, onSensorClick, type } = props;
  const icon = !type
    ? bellIcon
    : type === 'valueWarning'
    ? warningIcon
    : type === 'valueAlert'
    ? alertIcon
    : bellIcon;
  return (
    <Html
    // distanceFactor={3}
    >
      <div
        aria-hidden='true'
        className={classes.alertSpot}
        onClick={() => {
          if (onSensorClick) {
            onSensorClick(sid);
          }
        }}
      >
        <InlineIcon className={type ? classes.valueAlertIcon : classes.alertIcon} icon={icon} />
        {/* {!type && <InlineIcon className={classes.alertIconBg} icon={icon} />} */}
      </div>
    </Html>
  );
};

export const ProbeLabel = ({ id, alert, mode, data, i18n, degrees }) => {
  const classes = useStyles();
  let label;
  if (data && mode === storageConstants.MODE_TEMP) {
    const temp =
      degrees === '°C' ? data?.temperature.toFixed(1) : celsiusToF(data?.temperature).toString();
    label = `${temp} ${degrees}`;
  }
  if (data && mode === storageConstants.MODE_SIGNAL_SENSOR) {
    label = `${data?.sensor_RSSI} dBm`;
  }
  if (data && mode === storageConstants.MODE_SIGNAL_BS) {
    label = `${data?.bs_RSSI} dBm`;
  }
  if (data && mode === storageConstants.MODE_BATT) {
    label = `${(data?.batt_voltage / 100).toFixed(2)} V`;
  }
  if (data && mode === storageConstants.MODE_MEAS_AGE) {
    label = getDateString(data?.meas_ts * 1000, i18n);
  }

  return (
    <Html>
      <div className={`${classes.probeLabel} ${alert ? classes.alertLabel : ''}`}>
        {`${id}`}
        {label ? <span style={{ fontSize: 10, lineHeight: '10px' }}> {label}</span> : null}
      </div>
    </Html>
  );
};

export const PermanentProbeLabel = ({ id }) => {
  const classes = useStyles();
  return (
    <Html distanceFactor={5}>
      <div className={`${classes.probeLabel} ${classes.permanentLabel}`}>{`${id.substr(
        3,
        6
      )}`}</div>
    </Html>
  );
};

const Line = ({ style, coords }) => {
  // const { points, color } = { props };
  // const lineRef = useRef(null);
  const ends = [];
  // ends.push(new THREE.Vector3(-2, 0.02, 0.5));
  // ends.push(new THREE.Vector3(2, 0.02, 0.5));
  ends.push(new THREE.Vector3(coords.x0, 0.02, coords.z0));
  ends.push(new THREE.Vector3(coords.x1, 0.02, coords.z1));
  const lineGeometry = new THREE.BufferGeometry().setFromPoints(ends);

  return (
    // eslint-disable-next-line react/no-unknown-property
    <line geometry={lineGeometry}>
      <lineBasicMaterial
        // eslint-disable-next-line react/no-unknown-property
        attach='material'
        color={style.color}
        // eslint-disable-next-line react/no-unknown-property
        linewidth={10}
        // eslint-disable-next-line react/no-unknown-property
        linecap='round'
        // eslint-disable-next-line react/no-unknown-property
        linejoin='round'
      />
    </line>
  );
};

const GridLabel = ({
  position,
  index,
  labelText,
  styles,
  minWidth,
  selected,
  onClick,
  toggleButton,
  i18n
}) => (
  <mesh
    position={position}
    // eslint-disable-next-line react/no-unknown-property
    rotation={[-Math.PI / 2, 0, 0]}
  >
    <planeGeometry
      // eslint-disable-next-line react/no-unknown-property
      args={[0.5, 0.5]}
    />
    <meshLambertMaterial
      color='#ddaa22'
      // eslint-disable-next-line react/no-unknown-property
      transparent
      opacity={0}
    />
    <Html>
      <div
        aria-hidden='true'
        onKeyDown={() => onClick(index)}
        onClick={() => onClick(index)}
        style={{ minWidth }}
        className={`${styles.gridLabel} ${selected ? styles.selected : ''} ${
          toggleButton ? styles.toggleButton : ''
        }`}
      >
        {toggleButton ? (
          <Tooltip
            title={i18n.toggle_labels || 'Toggle labels'}
            arrow
            placement='bottom'
            disableFocusListener
          >
            <InlineIcon
              width={18}
              icon={
                toggleButton === 'select' ? checkboxMultipleBlank : checkboxMultipleBlankOutline
              }
            />
          </Tooltip>
        ) : (
          labelText
        )}
      </div>
    </Html>
  </mesh>
);

const HoverCell = ({ position, col, row, onClick, isPile }) => {
  const [hover, setHover] = useState(false);
  const cellRef = useRef();
  useEffect(() => {
    document.body.style.cursor = hover ? 'pointer' : 'auto';
  }, [hover]);

  return (
    <mesh
      ref={cellRef}
      position={position}
      // eslint-disable-next-line react/no-unknown-property
      rotation={[-Math.PI / 2, 0, 0]}
      onPointerOver={() => setHover(true)}
      onPointerOut={() => setHover(false)}
      onClick={() => onClick(col, row, isPile)}
    >
      <planeGeometry
        // eslint-disable-next-line react/no-unknown-property
        args={[0.95, 0.95]}
      />
      <meshLambertMaterial
        color={isPile ? '#004444' : COLORS.yellow}
        // eslint-disable-next-line react/no-unknown-property
        transparent
        opacity={hover ? 1 : 0}
      />
    </mesh>
  );
};

export const StackGrid = (props) => {
  const classes = useStyles();
  // console.log('grid props', props);
  // const gridRef = useRef();
  const {
    stack,
    showRowLabels,
    showColumnLabels,
    showProbeLabels,
    showLabelsButton,
    onRowSelectionChanged,
    onColumnSelectionChanged,
    selectedRows,
    selectedColumns,
    onToggleLabels,
    pilePositions,
    baleLocationsToAdd,
    pilesToRemove,
    onPileToAddOrRemove,
    baleRows,
    baleCols,
    i18n
    // controlsOn
  } = props;

  // Horizontal lines for each row. Width is cols, z increases from -rows to 0.
  const horizontalLines = Array.from(Array(stack.nRows + 1).keys()).map((rowIndex) => (
    <Line
      key={rowIndex}
      style={{ color: stack.color || COLORS.mediumGray }}
      coords={{
        x0: 0,
        x1: stack.nColumns,
        z0: -stack.nRows + rowIndex,
        z1: -stack.nRows + rowIndex
      }}
    />
  ));
  // Horizontal lines for each col. z between -rows..0, x increases from 0 to cols.
  const verticalLines = Array.from(Array(stack.nColumns + 1).keys()).map((colIndex) => (
    <Line
      key={colIndex}
      style={{ color: stack.color || COLORS.mediumGray }}
      coords={{
        x0: colIndex,
        x1: colIndex,
        z0: -stack.nRows,
        z1: 0
      }}
    />
  ));
  let rowLabels = showRowLabels
    ? // If baleRows given, only show labels on those rows
      Array.from(Array(stack.nRows).keys())
        .filter((rowIndex) => (baleRows ? baleRows.includes(rowIndex) : true))
        .map((rowIndex) => (
          <GridLabel
            styles={classes}
            index={rowIndex}
            selected={selectedRows?.includes(rowIndex)}
            labelText={formatSensorRow(rowIndex)}
            key={rowIndex}
            onClick={onRowSelectionChanged}
            position={[-0.8, 0.5, -stack.nRows + rowIndex + 1 - 0.6]}
          />
        ))
    : [];
  if (showLabelsButton) {
    rowLabels = rowLabels.concat([
      <GridLabel
        styles={classes}
        index={stack.nRows}
        key={stack.nRows}
        selected={false}
        toggleButton={
          (showLabelsButton ? showProbeLabels : showRowLabels || showColumnLabels)
            ? 'deselect'
            : 'select'
        }
        onClick={onToggleLabels}
        position={[-0.8, 0.5, 0.5]}
        i18n={i18n}
      />
    ]);
  }
  const columnLabels = showColumnLabels
    ? // If baleCols given, only show labels on those columns
      Array.from(Array(stack.nColumns).keys())
        .filter((colIndex) => (baleCols ? baleCols.includes(colIndex) : true))
        .map((colIndex) => (
          <GridLabel
            styles={classes}
            index={colIndex}
            selected={selectedColumns?.includes(colIndex)}
            labelText={colIndex + 1}
            key={colIndex}
            minWidth={stack.nColumns >= 9 && 17}
            onClick={onColumnSelectionChanged}
            position={[colIndex + 0.2, 0.5, 0.5]}
          />
        ))
    : null;

  const hoverPileCells = (
    pilePositions
      ? Array.from(Array(stack.nRows).keys())
          .map((row) => Array.from(Array(stack.nColumns).keys()).map((col) => ({ col, row })))
          .flat()
          // Case both row(s) and col(s) selected: No hovering on selected cols and rows.
          // The selection is the intersection, so toggling there gets complicated.
          .filter((cell) =>
            !isEmpty(selectedColumns) && !isEmpty(selectedRows)
              ? !selectedColumns.includes(cell.col) && !selectedRows.includes(cell.row)
              : true
          )
          // If baleLocationsToAdd we are adding piles, so no interaction on pile cells
          // If baleLocationsToRemove we are removing piles, so no interaction on empty cells
          // If no locations selected, all cells are interactive
          .filter((cell) =>
            isEmpty(baleLocationsToAdd)
              ? isEmpty(pilesToRemove)
                ? true
                : pilePositions.find((pile) => pile.col === cell.col && pile.row === cell.row)
              : !pilePositions.find((pile) => pile.col === cell.col && pile.row === cell.row)
          )
      : []
  ).map((hoverCell, i) => (
    <HoverCell
      key={i}
      onClick={onPileToAddOrRemove}
      isPile={Boolean(
        pilePositions.find((pile) => pile.col === hoverCell.col && pile.row === hoverCell.row)
      )}
      col={hoverCell.col}
      row={hoverCell.row}
      position={[hoverCell.col + 0.5, 0.01, -stack.nRows + hoverCell.row + 0.5]}
    />
  ));

  return (
    <group {...props}>
      {horizontalLines}
      {verticalLines}
      {rowLabels}
      {columnLabels}
      {hoverPileCells}
    </group>
  );
};
