import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { Icon } from '@iconify/react';
import cube from '@iconify/icons-mdi/cube';
import arrowRightThick from '@iconify/icons-mdi/arrow-right-thick';
import microsoftExcel from '@iconify/icons-mdi/microsoft-excel';
import dotsVertical from '@iconify/icons-mdi/dots-vertical';
import { COLORS } from 'utils/colors';
import { handleExpired, getMaxPlan } from 'assets/utils';
import { RemoveProbeDialog } from './RemoveProbeDialog';
import * as storageConstants from '../../storage-utils/storageConstants';
import Stacks3D from '../../storage-stacks/Stacks3D';
import StorageDataLoader from '../../storage-utils/StorageDataLoader';

// import { useSelector } from 'react-redux';
// import { useDispatch } from 'react-redux';

import StackModeButtons from './StackModesButtons';
import StackEditAccordion from './StackEditAccordion';
import BasicSensorList from '../../../general/basic-sensor-list/BasicSensorList';
import StorageQualityView from '../../quality-views/StorageQualityView';

import { getPilesFromBales, getBalesFromPiles } from '../../storage-utils/storageHelper';

const LEFT_PANEL_WIDTH = 250;
const DEFAULT_PILE_HEIGHT = storageConstants.DEFAULT_FLOORS;

const useStyles = makeStyles((theme) => ({
  sensorListPanel: {
    // height: 'calc(100% - 40px)',
    overflowY: 'auto',
    marginTop: 0,
    padding: '5px 0px 5px 10px',
    boxSizing: 'border-box',
    borderRight: '1px solid #ccc'
  },
  noSensors: {
    padding: 10,
    marginTop: 10,
    overflow: 'hidden'
  },
  noSensorsTitle: {
    color: COLORS.darkGray,
    fontSize: 16
  },
  noSensorsContent: {
    display: 'flex'
  },
  noSensorsText: {
    color: COLORS.darkGray,
    fontSize: 13,
    margin: '5px 5px 0px 10px'
  },
  noSensorsIconDiv: {
    minWidth: 32,
    height: 32,
    backgroundColor: COLORS.darkGray,
    borderRadius: 20,
    marginTop: 10,
    textAlign: 'center'
  },
  noSensorsIcon: {
    width: 24,
    height: 24,
    color: COLORS.white,
    marginTop: 3
  },
  arrow: {
    color: COLORS.darkGray,
    marginRight: -12,
    marginTop: 10,
    borderLeft: `1px solid ${COLORS.lightGray3}`
  },
  dataPanel: {
    position: 'absolute',
    bottom: -40,
    left: 0,
    width: 250,
    height: 40,
    zIndex: 1000,
    textAlign: 'center',
    borderTop: '1px solid white',
    backgroundColor: COLORS.whiteSmoke,
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
    '&:hover': {
      backgroundColor: COLORS.white,
      '& $expButton': {
        opacity: 1
      },
      '& $dataDots': {
        opacity: 0
      }
    }
  },
  expButton: {
    opacity: 0,
    height: 28,
    lineHeight: '28px',
    maxWidth: 240,
    marginTop: 6,
    fontSize: 12,
    color: COLORS.primaryColor
  },
  expIcon: {
    color: COLORS.darkGreen
  },
  dataDots: {
    opacity: 1,
    position: 'absolute',
    top: 8,
    right: 10,
    width: 24,
    height: 24,
    color: 'rgba(0, 0, 0, 0.54)'
  }
}));
const StorageStacksView = ({
  viewWidth,
  viewHeight,
  selectedStorage,
  setSelectedStorage,
  setIsStorageEdited,
  storages,
  showHay,
  feedQualityLive,
  qualityMode,
  editMode
}) => {
  const dispatchRedux = useDispatch();

  const i18n = useSelector((store) => store.i18n);
  const sensorsData = useSelector((store) => store.sensorsData);
  const bsConf = useSelector((store) => store.bsConf);
  const isAdmin = useSelector((store) => store.isAdmin);
  const userInfo = useSelector((store) => store.userInfo);
  const degrees = useSelector((store) => store.sessionInfo?.ui_preferences?.degrees);
  const isDataAccess = useSelector((store) => store.isSupervisor && viewWidth >= 600);
  const editConfirmNeeded = useSelector((state) => state.editConfirmNeeded);
  const sensorConfigurations = useSelector((state) => state.sensorConfigurations);
  const history = useHistory();

  const plan = getMaxPlan(userInfo?.plans, userInfo?.service);
  const expInfo = handleExpired(plan);
  // Enabled for
  // - Insight users (has service)
  // - Not in refused state (trial: -1)
  // - Insight is valid (expInfo.planExpired false)
  const qualityViewEnabled =
    userInfo?.service && userInfo?.service.trial !== -1 && !expInfo?.planExpired;

  console.log('feedQualityLive, qualityViewEnabled', feedQualityLive, qualityViewEnabled);
  const classes = useStyles();

  const stackIndex = 0; // For now only 1 stack

  const [selectedSensor, setSelectedSensor] = useState(null);
  const [preSelectedSensor, setPreSelectedSensor] = useState(null);
  const [probeQualityData, setProbeQualityData] = useState(null);
  const [clickedQualitySensor, setClickedQualitySensor] = useState(null);
  const [showInfoDialog, setShowInfoDialog] = useState(false);

  const [alertSensorIDs] = useState(
    selectedStorage.monitor?.rules
      .map((r) => r.currentAlerts)
      .flat(1)
      .filter((al) => !!al)
      .map((al) => al.sensor_id)
  );

  const onListSensorHover = (id) => {
    if (selectedSensor !== id) {
      setSelectedSensor(null);
    }
  };
  const sensorQualityClickedIn3D = (id) => {
    // const res = sensorConfigurations.map((s) => s.sensor_id_sys).find((sid) => sid === id);
    // case multi-BS: show only data of current BS
    if (sensorConfigurations.map((s) => s.sensor_id_sys).find((sid) => sid === id)) {
      setClickedQualitySensor(id);
    }
  };

  const openSensorData = (id, tempView) => {
    // console.log(id, 'tempView ', tempView);
    if (editConfirmNeeded) {
      dispatchRedux({ type: 'SET_UNSAVED_CHANGES', value: true });
    } else if (selectedStorage) {
      dispatchRedux({ type: 'RESET_CHARTDATA' });
      dispatchRedux({ type: 'RESET_DATATABLE' });
      history.push({
        pathname: `/storages/${selectedStorage?.itemID}/probes/${id}`,
        state: {
          urlBack: tempView
            ? `/storages/${selectedStorage?.itemID}`
            : `/storages/${selectedStorage?.itemID}`, // /quality`,
          list: [] // getStorageSensorIds(selectedStorage)
        }
      });
    }
  };

  const sensorClickedIn3D = (id) => {
    // const res = sensorConfigurations.map((s) => s.sensor_id_sys).find((sid) => sid === id);
    // case multi-BS: show only data of current BS
    if (sensorConfigurations.map((s) => s.sensor_id_sys).find((sid) => sid === id)) {
      openSensorData(id, true);
    }
    // if (id !== selectedSensor) {
    //   setSelectedSensor(id);
    //   // Scroll 'stackSensorList' to get selectedSensor to sight
    //   if (
    //     document.getElementById(`probeListItem-${id}`) &&
    //     document.getElementById('stackSensorList')
    //   ) {
    //     const selectedTop =
    //       document.getElementById(`probeListItem-${id}`).getBoundingClientRect().top || 0;
    //     // Subtract the headers height from the top coordinate
    //     const headersOffset = calcOverheadHeight();
    //     document.getElementById('stackSensorList').scrollTop = Math.max(
    //       0,
    //       selectedTop - headersOffset
    //     );
    //   }
    // } else setSelectedSensor(null);
  };

  const keepFullStack = true; // No pile-level editing. Keep stack height at pileHeight

  // const [piles, setPiles] = useState(selectedStorage.stacks[stackIndex].bales);
  const [bales, setBales] = useState(
    getBalesFromPiles(selectedStorage.stacks[stackIndex].piles || [])
  );

  const [pileHeight, setPileHeight] = useState(
    keepFullStack &&
      selectedStorage.stacks[stackIndex].piles &&
      !isEmpty(selectedStorage.stacks[stackIndex].piles)
      ? Math.max(...selectedStorage.stacks[stackIndex].piles.map((p) => p.h))
      : DEFAULT_PILE_HEIGHT
  );
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [loosePilesToAdd, setLoosePilesToAdd] = useState([]);
  const [pilesToRemove, setPilesToRemove] = useState([]);
  const [loosePilesToSubtract, setLoosePilesToSubtract] = useState([]);
  const [addingProbePosition, setAddingProbePosition] = useState(null); // probe placement mode
  const [selectedMode, setSelectedMode] = useState(
    qualityMode
      ? storageConstants.MODE_QUALITY
      : editMode
      ? storageConstants.MODE_STACK
      : storageConstants.MODE_TEMP
  );
  const [viewType, setViewType] = useState(editMode ? 'probes' : null); // props, bales or probes
  const [addFullStackSelected, setAddFullStackSelected] = useState(false);
  const [removeAllProbes, setRemoveAllProbes] = useState(false);

  const viewTypeChange = (type) => {
    if (type !== viewType) {
      setViewType(type);
      // Clear setting state
      if (!keepFullStack) {
        setPileHeight(DEFAULT_PILE_HEIGHT);
      }
      setSelectedRows([]);
      setSelectedColumns([]);
      setAddingProbePosition(null);
      setLoosePilesToAdd([]);
      setLoosePilesToSubtract([]);
      setPilesToRemove([]);
      setSelectedSensor(null);
      setAddFullStackSelected(false);
    }
  };

  const modeChanged = (mode) => {
    if (mode === storageConstants.MODE_STACK) {
      viewTypeChange(keepFullStack ? 'probes' : 'props');
    } else {
      viewTypeChange(null);
    }
    setSelectedSensor(null);
    setSelectedMode(mode);
    // if (mode === storageConstants.MODE_QUALITY) {
    //   history.push({
    //     pathname: `/storages/${selectedStorage?.itemID}/quality`
    //   });
    // } else if (mode === storageConstants.MODE_STACK) {
    //   history.push({
    //     pathname: `/storages/${selectedStorage?.itemID}/edit`
    //   });
    // } else {
    //   history.push({
    //     pathname: `/storages/${selectedStorage?.itemID}`
    //   });
    // }
    // setLoosePilesToAdd([]);
    setAddFullStackSelected(false);
  };

  const [probeToRemove, setProbeToRemove] = useState(null);

  const handleCloseDeleteDialog = () => {
    setProbeToRemove(null);
    setRemoveAllProbes(false);
  };

  const onRowSelectionChanged = (selectedRow) => {
    if (viewType === 'probes') {
      setAddingProbePosition(null);
      // Probes: only one col / row selected
      if (selectedRows.includes(selectedRow)) {
        setSelectedRows([]); // toggle off
      } else {
        setSelectedRows([selectedRow]);
      }
      setSelectedColumns([]);
      return;
    }
    setAddFullStackSelected(false);
    const balesOnRow = bales.filter((bale) => bale.r === selectedRow && bale.f === 0);
    if (
      !isEmpty(pilesToRemove) ||
      (isEmpty(selectedRows) &&
        isEmpty(loosePilesToAdd) &&
        balesOnRow.length === selectedStorage.stacks[stackIndex].nColumns)
    ) {
      if (selectedRows.includes(selectedRow)) {
        // Toggle selection off
        setPilesToRemove(pilesToRemove.filter((p) => p.row !== selectedRow));
        setSelectedRows(selectedRows.filter((r) => r !== selectedRow));
      } else {
        setSelectedRows(selectedRows.concat([selectedRow]));
        setPilesToRemove(
          pilesToRemove.concat(
            balesOnRow.map((bale) => {
              const br = { row: bale.r, col: bale.c };
              return br;
            })
          )
        );
      }
      return;
    }
    if (selectedRows.includes(selectedRow)) {
      // Toggle selection off
      setSelectedRows(selectedRows.filter((r) => r !== selectedRow));
      // forget possible subtract piles from the row
      setLoosePilesToSubtract(loosePilesToSubtract.filter((sub) => sub.row !== selectedRow));
    } else if (viewType === 'probes') {
      // Probes: only one col / row selected
      setSelectedRows([selectedRow]);
      setSelectedColumns([]);
    } else {
      setSelectedRows(selectedRows.concat([selectedRow]));
      // remove piles in selected row from loosePilesToAdd
      setLoosePilesToAdd(loosePilesToAdd.filter((pile) => pile.row !== selectedRow));
      // forget possible subtract piles from the row
      setLoosePilesToSubtract(loosePilesToSubtract.filter((sub) => sub.row !== selectedRow));
    }
    setAddingProbePosition(null);
  };

  const onColumnSelectionChanged = (selectedColumn) => {
    setAddFullStackSelected(false);
    if (!isEmpty(pilesToRemove)) {
      setAddingProbePosition(null);
      return;
    }
    if (selectedColumns.includes(selectedColumn)) {
      // Toggle selection off
      setSelectedColumns(selectedColumns.filter((r) => r !== selectedColumn));
      // forget possible subtract piles from the row
      setLoosePilesToSubtract(loosePilesToSubtract.filter((sub) => sub.col !== selectedColumn));
    } else if (viewType === 'probes') {
      // Probes: only one col / row selected
      setSelectedColumns([selectedColumn]);
      setSelectedRows([]);
    } else {
      setSelectedColumns(selectedColumns.concat([selectedColumn]));
      // remove piles in selected col from loosePilesToAdd
      setLoosePilesToAdd(loosePilesToAdd.filter((pile) => pile.col !== selectedColumn));
      // forget possible subtract piles from the row
      setLoosePilesToSubtract(loosePilesToSubtract.filter((sub) => sub.col !== selectedColumn));
    }
    setAddingProbePosition(null);
  };

  const onPileToAdd = (col, row) => {
    setAddFullStackSelected(false);
    // Toggle clicked pile
    const pileAlreadySelected = loosePilesToAdd.find(
      (pile) => pile.col === col && pile.row === row
    );
    if (pileAlreadySelected) {
      // console.log('1 pileAlreadySelected: remove it');
      // remove the pile from loosePilesToAdd
      setLoosePilesToAdd(loosePilesToAdd.filter((pile) => !(pile.col === col && pile.row === row)));
    } else if (selectedRows.includes(row) || selectedColumns.includes(col)) {
      // Get count of substracted in col and row
      const nLooseInCol = loosePilesToSubtract.filter((sub) => sub.col === col).length;
      const nLooseInRow = loosePilesToSubtract.filter((sub) => sub.row === row).length;

      if (!isEmpty(selectedRows) && !isEmpty(selectedColumns)) {
        // Intersection of cols/rows is selected
        // console.log('Case intersection: no piles');
      } else if (loosePilesToSubtract.find((sub) => sub.row === row && sub.col === col)) {
        // console.log('2 pile not selected but row/col is');
        // Action on selected row or col
        // console.log('3 toggle pile back to selection');
        // If the pile is in loosePilesToSubtract, toggle it back to the selection
        setLoosePilesToSubtract(
          loosePilesToSubtract.filter((sub) => !(sub.row === row && sub.col === col))
        );
      } else {
        // console.log('4 take pile away from selection');
        // Else remove the pile from selection
        setLoosePilesToSubtract(loosePilesToSubtract.concat({ col, row }));
        //     const nrows = selectedStorage?.sensor_layout?.rows?.length;
        // const ncols = selectedStorage?.sensor_layout?.rows[0].rowLength;
      }

      // If whole column is deselected, remove it from selected columns
      if (
        selectedColumns.includes(col) &&
        nLooseInCol + 1 === selectedStorage.stacks[stackIndex].nRrows
      ) {
        // console.log(col, ' Whole col piles deselected ');
        setSelectedColumns(selectedColumns.filter((c) => c !== col));
        setLoosePilesToSubtract(loosePilesToSubtract.filter((sub) => sub.col !== col));
      }
      // If whole row is deselected, remove it from selected rows
      if (
        selectedRows.includes(row) &&
        nLooseInRow + 1 === selectedStorage.stacks[stackIndex].nColumns
      ) {
        // console.log(col, ' Whole row piles deselected ');
        setSelectedRows(selectedRows.filter((r) => r !== row));
        setLoosePilesToSubtract(loosePilesToSubtract.filter((sub) => sub.row !== row));
      }
    } else {
      // console.log('5 just add loose pile');
      // add the pile to loosePilesToAdd
      setLoosePilesToAdd(loosePilesToAdd.concat({ col, row }));
    }
  };

  const onPileToRemove = (col, row) => {
    setAddFullStackSelected(false);
    const pileAlreadySelected = pilesToRemove.find((pile) => pile.col === col && pile.row === row);
    if (pileAlreadySelected) {
      // remove the pile from pilesToRemove
      setPilesToRemove(pilesToRemove.filter((pile) => !(pile.col === col && pile.row === row)));
    } else {
      setPilesToRemove(pilesToRemove.concat({ col, row }));
    }
  };

  const onPileToAddOrRemove = (col, row, remove) => {
    if (remove) {
      onPileToRemove(col, row);
    } else {
      onPileToAdd(col, row);
    }
  };

  const onClearSelection = () => {
    setLoosePilesToAdd([]);
    setPilesToRemove([]);
    setLoosePilesToSubtract([]);
    setSelectedRows([]);
    setSelectedColumns([]);
  };

  const fullStackPressed = (value) => {
    setAddFullStackSelected(value);
    if (value) {
      setSelectedColumns([]);
      setSelectedRows(Array.from(Array(selectedStorage.stacks[stackIndex].nRows).keys()));
    } else {
      onClearSelection();
    }
  };

  const onPileHeightChange = (height) => {
    setPileHeight(height);
  };

  const getFootprintToAdd = (rows, columns) => {
    let rowSelection = rows;
    let columnSelection = columns;
    if (rowSelection.length > 0) {
      // Row selected
      if (columnSelection.length === 0) {
        // Only rows selected: get full rows
        columnSelection = Array.from(Array(selectedStorage.stacks[stackIndex].nColumns).keys());
      }
    } else if (columnSelection.length > 0) {
      // No rows selected, only columns: get full columns
      rowSelection = Array.from(Array(selectedStorage.stacks[stackIndex].nRows).keys());
    }
    let footprintToAdd = rowSelection
      .map((r) =>
        columnSelection
          .filter((c) => !loosePilesToSubtract.find((sub) => sub.col === c && sub.row === r))
          .map((c) => ({ col: c, row: r }))
      )
      .flat()
      .filter((cell) => !bales.find((bale) => bale.c === cell.col && bale.r === cell.row));
    // In addition to row/col selection, add manually selected pile cells
    footprintToAdd = footprintToAdd.concat(loosePilesToAdd);
    return footprintToAdd;
  };

  const getCoordsToAdd = (rows, columns) => {
    const footprintToAdd = getFootprintToAdd(rows, columns);
    // Add y coord based on number of floors
    const floorBales = [];
    Array.from(Array(pileHeight).keys())
      .map((floor) =>
        footprintToAdd.map((fp) => {
          const floorBale = { col: fp.col, row: fp.row };
          // Do not add floors if there is a pile in this {row,coord}
          const balesInLoc = bales.filter((bale) => bale.c === fp.col && bale.r === fp.row);
          if (isEmpty(balesInLoc)) {
            floorBale.floor = floor;
            floorBales.push(floorBale);
          }
          return floorBale;
        })
      )
      .flat();
    return floorBales;
  };

  const onAddOrRemovePiles = () => {
    let updatedBales = bales;
    let updatedSensors = selectedStorage.stacks[stackIndex].sensors || [];
    if (!isEmpty(pilesToRemove)) {
      // const balesToRemove = bales.filter((bale) =>
      //   pilesToRemove.find((pile) => pile.col === bale.c && pile.row === bale.r)
      // );
      const doRemoveBales = true; // window.confirm(`Remove ${balesToRemove.length} bales?`);
      updatedBales = bales.filter(
        (bale) => !pilesToRemove.find((pile) => pile.col === bale.c && pile.row === bale.r)
      );
      if (doRemoveBales) {
        // Also remove sensors that are possibly in the removed piles
        updatedSensors = selectedStorage.stacks[stackIndex].sensors.filter(
          (sen) => !pilesToRemove.find((pile) => pile.col === sen.column && pile.row === sen.row)
        );
      } else {
        return;
      }
    } else {
      // Adding bales
      const baleCoords = getCoordsToAdd(selectedRows, selectedColumns);
      // baleCoords is array of {col: 0, row: 0, floor: 0}

      // [
      //   { id: 1, r: 1, c: 2, f: 0, bat: 111 },
      //   { id: 2, r: 1, c: 2, f: 1, bat: 111 },
      //   { id: 3, r: 1, c: 2, f: 2, bat: 111 },
      //   { id: 4, r: 1, c: 3, f: 0, bat: 111 },
      //   { id: 5, r: 2, c: 2, f: 0, bat: 111 }
      // ];
      const maxBaleID = bales && bales.length > 0 ? Math.max(...bales.map((b) => b.id)) : 0;
      updatedBales = bales.concat(
        baleCoords.map((loc, i) => {
          const bale = { id: maxBaleID + i + 1, r: loc.row, c: loc.col, f: loc.floor };
          return bale;
        })
      );
    }

    // Clear adding state
    setPileHeight(DEFAULT_PILE_HEIGHT);
    setSelectedRows([]);
    setSelectedColumns([]);
    setLoosePilesToAdd([]);
    setPilesToRemove([]);
    setLoosePilesToSubtract([]);
    setAddFullStackSelected(false);
    setBales(updatedBales);

    const changedStorage = {
      ...selectedStorage,
      stacks: selectedStorage.stacks.map((stack) =>
        !stack.index
          ? {
              ...stack,
              piles: getPilesFromBales(updatedBales, selectedStorage.stacks[stackIndex]),
              // bales: updatedBales,
              sensors: updatedSensors
            }
          : { ...stack }
      )
    };
    setSelectedStorage(changedStorage);
    dispatchRedux({ type: 'SET_EDIT_CONFIRM_NEEDED', value: true });
    setIsStorageEdited(true); // this call will cause re-rendering and losing IsEditing state
  };

  const getFullStackBales = (rows, columns, height) =>
    Array.from(Array(rows).keys())
      .map((row) =>
        Array.from(Array(columns).keys()).map((col) =>
          Array.from(Array(height || pileHeight).keys()).map((floor) => ({
            c: col,
            r: row,
            f: floor
          }))
        )
      )
      .flat(2);

  const onStackSizeChange = (size, height) => {
    // Remove sensors and piles that are possibly in the removed rows or columns
    let updatedSensors = selectedStorage.stacks[stackIndex].sensors.filter(
      (sen) => sen.row < size.nRows && sen.column < size.nColumns
    );
    if (keepFullStack) {
      // The pile height may have changed. Remove sensors that are not within pile height
      const maxHei = height || pileHeight;
      updatedSensors = updatedSensors.filter((sen) => sen.floor < maxHei);
    }
    const updatedBales = keepFullStack
      ? getFullStackBales(size.nRows, size.nColumns, height || pileHeight)
      : bales.filter((bale) => bale.r < size.nRows && bale.c < size.nColumns);
    setBales(updatedBales);
    const piles = getPilesFromBales(
      updatedBales,
      selectedStorage.stacks[stackIndex],
      size.nRows,
      size.nColumns
    );
    const changedStorage = {
      ...selectedStorage,
      stacks: selectedStorage.stacks.map((stack) =>
        !stack.index
          ? {
              ...stack,
              nRows: size.nRows,
              nColumns: size.nColumns,
              sensors: updatedSensors,
              piles
            }
          : { ...stack }
      )
    };
    setSelectedStorage(changedStorage);
    dispatchRedux({ type: 'SET_EDIT_CONFIRM_NEEDED', value: true });
    setIsStorageEdited(true);
  };

  // keepFullStack mode: Keep all pile heights to the given value
  const onConstantHeightChange = (height) => {
    setPileHeight(height);
    onStackSizeChange(
      {
        nRows: selectedStorage.stacks[stackIndex].nRows,
        nColumns: selectedStorage.stacks[stackIndex].nColumns
      },
      height
    );
  };

  const onSensorChange = (layoutSensors) => {
    if (!layoutSensors) {
      // Cancel placement
      setAddingProbePosition(null);
      return;
    }
    const changedStorage = {
      ...selectedStorage,
      stacks: selectedStorage.stacks.map((stack) =>
        !stack.index
          ? {
              ...stack,
              sensors: layoutSensors
            }
          : { ...stack }
      )
    };
    setSelectedStorage(changedStorage);
    dispatchRedux({ type: 'SET_EDIT_CONFIRM_NEEDED', value: true });
    setIsStorageEdited(true);
    setAddingProbePosition(null);
  };

  const onRemoveSensor = (event, id) => {
    document.body.style.cursor = 'auto'; // reset the hover state
    setProbeToRemove(id);
  };
  const removeProbeHandler = () => {
    onSensorChange(
      removeAllProbes
        ? []
        : selectedStorage.stacks[stackIndex].sensors.filter((s) => s.id !== probeToRemove)
    );
    setProbeToRemove(null);
    setRemoveAllProbes(false);
  };

  const onProbePositionClick = (pos) => {
    if (preSelectedSensor) {
      onSensorChange(
        selectedStorage.stacks[stackIndex].sensors.concat([
          {
            id: preSelectedSensor,
            row: pos.r,
            column: pos.c,
            floor: pos.f
          }
        ])
      );
      setPreSelectedSensor(null);
    } else {
      setAddingProbePosition(pos);
    }
  };

  const onStoragePeriodChange = (from, to, demoDays) => {
    const period = { from, to };
    if (demoDays) {
      period.demoDays = demoDays;
    }
    const changedStorage = {
      ...selectedStorage,
      qualityPeriod: period
    };
    setSelectedStorage(changedStorage);
    dispatchRedux({ type: 'SET_EDIT_CONFIRM_NEEDED', value: true });
    setIsStorageEdited(true);
  };

  const sensorIDs = selectedStorage?.stacks
    .map((s) => s.sensors)
    .flat()
    .map((s) => s.id);

  const noProbesInfo = (
    <div className={classes.noSensors}>
      <div className={classes.noSensorsTitle}>
        {i18n.empty_storage_title || 'No probes in the storage'}
      </div>
      <div className={classes.noSensorsContent}>
        <div className={classes.noSensorsIconDiv}>
          <Icon className={classes.noSensorsIcon} icon={cube} />
        </div>
        <div className={classes.noSensorsText}>
          {i18n.empty_storage_info ||
            'Open Stack editor to adjust storage layout and to place probes into the stack.'}
        </div>
        <div className={classes.arrow}>
          <Icon icon={arrowRightThick} width={40} height={40} />
        </div>
      </div>
    </div>
  );

  // Export storage probes data
  const [loadingExport, setLoadingExport] = useState(false);

  const exportPressed = () => {
    setLoadingExport(true);
  };

  return (
    <div style={{ position: 'relative' }}>
      <StackModeButtons
        showHay={showHay}
        showQuality={qualityViewEnabled}
        isAdmin={isAdmin}
        selectedMode={selectedMode}
        setSelectedMode={modeChanged}
        buttonsLeft={LEFT_PANEL_WIDTH + 10}
        i18n={i18n}
      />
      <Stacks3D
        viewHeight={viewHeight}
        viewWidth={viewWidth - LEFT_PANEL_WIDTH}
        leftPadding={LEFT_PANEL_WIDTH}
        selectedStorage={selectedStorage}
        selectedRows={viewType === 'props' ? [] : selectedRows}
        selectedColumns={viewType === 'props' ? [] : selectedColumns}
        bales={bales}
        baleLocationsToAdd={
          viewType === 'bales' ? getCoordsToAdd(selectedRows, selectedColumns) : []
        }
        editingType={
          selectedMode === storageConstants.MODE_TEMP || viewType === 'props' ? null : viewType
        }
        viewMode={selectedMode}
        degrees={degrees}
        qualityData={selectedMode === storageConstants.MODE_QUALITY && (probeQualityData || [])}
        data={sensorsData}
        showRowLabels={viewType === 'bales'}
        showColumnLabels={viewType === 'bales'}
        onRowSelectionChanged={onRowSelectionChanged}
        onColumnSelectionChanged={onColumnSelectionChanged}
        onPileToAddOrRemove={onPileToAddOrRemove}
        pilesToRemove={viewType === 'props' ? [] : pilesToRemove}
        i18n={i18n}
        addingProbePosition={addingProbePosition}
        setAddingProbePosition={onProbePositionClick}
        onRemoveSensor={onRemoveSensor}
        hoveredSensor={storageConstants.MODE_TEMP ? selectedSensor : null}
        onSensorClick={
          selectedMode !== storageConstants.MODE_QUALITY
            ? sensorClickedIn3D
            : sensorQualityClickedIn3D
        }
        alertSensorIDs={selectedMode === storageConstants.MODE_TEMP ? alertSensorIDs : []}
        hideLabelsButton={isEmpty(sensorIDs)}
      />
      {selectedMode !== storageConstants.MODE_STACK &&
        selectedMode !== storageConstants.MODE_QUALITY && (
          <div
            id='stackSensorList'
            style={{ height: viewHeight - (isDataAccess ? 40 : 0), width: LEFT_PANEL_WIDTH }}
            className={classes.sensorListPanel}
          >
            {isEmpty(sensorIDs) ? (
              noProbesInfo
            ) : (
              <BasicSensorList
                sensorIDs={sensorIDs}
                // showMultipoint
                dataSnapshot={sensorsData}
                bsConf={bsConf}
                // auxConf={auxConf}
                selectedSensor={null}
                // setSelectedSensor={(id) => setSelectedSensor(id)}
                alertSensorIDs={alertSensorIDs}
                marginRight={isEmpty(alertSensorIDs) ? 10 : 17}
                //   alertSensorDepths={alertSensorDepths}
                // showHandleBar={prefs.ui_preferences.handle_temp_enabled === 'true'}
                onSensorHover={onListSensorHover}
                hoveredSensor={selectedSensor}
                selectedStorage={selectedStorage}
                inStorage
              />
            )}
            {isDataAccess && (
              <div className={classes.dataPanel}>
                <Button
                  startIcon={<Icon className={classes.expIcon} icon={microsoftExcel} />}
                  variant='outlined'
                  onClick={() => exportPressed()}
                  className={classes.expButton}
                >
                  {i18n.storage_data_download}
                </Button>
                <Icon className={classes.dataDots} icon={dotsVertical} />
                {loadingExport ? (
                  <StorageDataLoader
                    storage={selectedStorage}
                    setLoadingExport={setLoadingExport}
                    sensorIDs={sensorIDs.sort((a, b) => (a < b ? -1 : 1))}
                  />
                ) : null}
              </div>
            )}
          </div>
        )}
      {selectedMode === storageConstants.MODE_HAY && <div>Hay loads</div>}
      {selectedMode === storageConstants.MODE_QUALITY && (
        <div style={{ height: viewHeight, width: LEFT_PANEL_WIDTH }}>
          <StorageQualityView
            viewHeight={viewHeight}
            sensorIDs={sensorIDs}
            selectedStorage={selectedStorage}
            probeQualityData={probeQualityData}
            setProbeQualityData={setProbeQualityData}
            onStoragePeriodChange={onStoragePeriodChange}
            dataSnapshot={sensorsData}
            clickedQualitySensor={clickedQualitySensor}
            setClickedQualitySensor={setClickedQualitySensor}
            showInfoDialog={showInfoDialog || clickedQualitySensor}
            setShowInfoDialog={setShowInfoDialog}
            openSensorData={openSensorData}
          />
        </div>
      )}
      {selectedMode === storageConstants.MODE_STACK && (
        <StackEditAccordion
          viewHeight={viewHeight}
          width={LEFT_PANEL_WIDTH}
          selectedStorage={selectedStorage}
          stackIndex={stackIndex}
          onStackSizeChange={onStackSizeChange}
          keepFullStack={keepFullStack}
          onHeightChange={onConstantHeightChange}
          pileHeight={pileHeight}
          onPileHeightChange={onPileHeightChange}
          onAddBales={onAddOrRemovePiles}
          footprintToAdd={getFootprintToAdd(selectedRows, selectedColumns)}
          pilesToRemove={isEmpty(pilesToRemove) ? null : pilesToRemove}
          onClearSelection={onClearSelection}
          addFullStackSelected={addFullStackSelected}
          fullStackPressed={fullStackPressed}
          bales={bales}
          editingType={keepFullStack ? 'probes' : null}
          setEditingType={viewTypeChange}
          onSensorChange={onSensorChange}
          addingProbePosition={addingProbePosition}
          preSelectedSensor={preSelectedSensor}
          setPreSelectedSensor={setPreSelectedSensor}
          storages={storages}
          rowSelected={selectedRows.length}
          columnSelected={selectedColumns.length}
        />
      )}
      <RemoveProbeDialog
        i18n={i18n}
        open={!!probeToRemove}
        openDeleteDialog={probeToRemove}
        handleCloseDeleteDialog={handleCloseDeleteDialog}
        probeToRemove={probeToRemove}
        removeAll={removeAllProbes}
        setRemoveAll={setRemoveAllProbes}
        handleDelete={() => removeProbeHandler()}
        textAction={i18n.remove || 'Remove'}
      />
    </div>
  );
};

export default StorageStacksView;
