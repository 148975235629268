import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'utils/colors';

export const useStyles = makeStyles((theme) => ({
  left: {
    flex: '1 1 auto'
  },
  actions: {
    flex: '1 1 auto',
    textAlign: 'right'
  },
  icon: {
    padding: '6px 12px 12px 12px',
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  [theme.breakpoints.down('sm')]: {
    left: {
      padding: '8px 0px'
    },
    actions: {
      textAlign: 'right'
    }
  },
  [theme.breakpoints.down('xs')]: {
    actions: {
      textAlign: 'center'
    },
    icon: {
      padding: '6px'
    },
    title: {
      fontSize: '15px'
    }
  },
  speedDial: {
    '& > button': {
      background: COLORS.whisperGray,
      width: 40,
      height: 40,
      color: COLORS.black
    },
    '& > button:hover': {
      background: COLORS.whisperGray,
      width: 40,
      height: 40,
      color: COLORS.black
    }
  }
}));
