import { format } from 'date-fns';
import { i18nVariablesInjection } from 'utils/sharedHelper';

let initStyle = 'body { font-size: 13pt; margin:0; }';
initStyle +=
  '.pagebreak {width:100%; height: 2px; margin: 80px 0 40px; border-bottom: 2px solid #ccc;} ';
initStyle +=
  '@media print {.pagebreak {page-break-before: always !important; margin:0; height:0; border:none} }';

let reportHTML = '';
let styleCSS = '';

const fontdef =
  "<link href='http://fonts.googleapis.com/css?family=Roboto:400,500,600' rel='stylesheet' type='text/css'>";

const styles = {
  reportWrapper:
    "{ width: 21cm; padding: 1cm; padding-left: 2cm; padding-right: 0cm; font-family: 'Roboto',sans-serif  } ",
  reportTop: '{ font-size: 13pt; line-height: 23px }',
  reportLogo: '{width: 3cm; float:left}',
  reportHead:
    '{text-transform: uppercase; padding-bottom: 20px; margin-top: 0px; padding-left: 3.5cm; margin-bottom: 100px; font-weight: 500; } ',
  reportDatum: '{float: right; text-align: right; font-weight: 500 }',
  time: '{ margin-top: 5px; font-weight: 400 }',
  mainTitle: '{font-size: 24pt; font-weight: 500; margin-bottom: 15px }',
  subTitle: '{font-size: 18pt; font-weight: 500; margin: 20px 0 10px}',
  rowTitle:
    '{font-weight: 500; padding: 5px 20px 5px 0px; vertical-align: top; white-space: nowrap}',
  rowText: '{padding-bottom: 5px; vertical-align: top; width: 100% }',
  verifixTable: '{font-size: 13pt}',
  verifixTableTR: '{vertical-align: text-bottom}',
  phaseSection: '{ padding-top: 20px }',
  verifTable:
    '{ font-size: 11pt; width: 100%; text-align: center; line-height: 12px; border-collapse: collapse; border: 1px solid #999; margin-bottom: 20px; }',
  verifTableTR: '{line-height: 15px }',
  verifTableTH:
    '{line-height: 15px; border: 1px solid #999; padding: 5px 10px; font-weight: 500; background: -webkit-gradient(linear, left top, left bottom, from(#f6f6f6), to(#eee))}',
  verifTableTD: '{ border: 1px solid #999; box-sizing: inherit; padding: 5px }',
  dateCell: '{ width: 60px }',
  tempCell: '{ width: 60px }',
  diffCell: '{ width: 90px; min-width: 80px }'
};

//  /////////////////////////////////
//
//            Report header
//
const generateReportHeader = (endDate, dateFormat) => {
  const reportLogo = document.getElementById('reportLogo');
  console.log('generateReportHeader ', endDate);
  reportHTML += '<div class="reportTop">';
  reportHTML += '<div class="reportDatum">';
  reportHTML += `<div class="date">${format(new Date(endDate), dateFormat)}</div>`;
  // reportHTML += `<div class="time">${format(new Date(), timeFormat)}</div>`;
  reportHTML += '</div>';
  reportHTML += `<div class="reportLogo" type="image/svg+xml"> ${
    reportLogo ? reportLogo.querySelector('svg').outerHTML : 'QUANTURI'
  }</div>`;
  reportHTML += `<div class="reportHead"></div>`;
  reportHTML += '</div>';

  styleCSS += `.reportTop ${styles.reportTop}`;
  styleCSS += `.reportLogo ${styles.reportLogo}`;
  styleCSS += `.reportHead  ${styles.reportHead}`;
  styleCSS += `.reportDatum  ${styles.reportDatum}`;
  styleCSS += `.time  ${styles.time}`;
};

//  /////////////////////////////////
//
//            Verification info
//
const generateVerificationInfo = (i18n, probeID, dates, verifixID, dateCalibration) => {
  // Report title
  reportHTML += '<div class="mainTitle">';
  reportHTML += i18n.verifix_report_title;
  reportHTML += '</div>';

  reportHTML += '<table class="verifixTable"><tbody><tr class="verifixTableTR">';
  reportHTML += '<td><div class="rowTitle">';
  reportHTML += i18n.verification_date;
  reportHTML += i18n.colon;
  reportHTML += '</div></td><td><div class="rowText">';
  reportHTML += dates || '&ndash;';

  reportHTML += '</div></td></tr><tr class="verifixTableTR"><td><div class="rowTitle">';
  reportHTML += i18n.description;
  reportHTML += i18n.colon;
  reportHTML += '</div></td><td><div class="rowText">';
  reportHTML += i18n.verifix_description_msg || '&ndash;';

  reportHTML += '</div></td></tr><tr class="verifixTableTR"><td><div class="rowTitle">';
  reportHTML += i18n.sensor_id;
  reportHTML += i18n.colon;
  reportHTML += '</div></td><td><div class="rowText">';
  reportHTML += probeID || '&ndash;';

  reportHTML += '</div></td></tr><tr class="verifixTableTR"><td><div class="rowTitle">';
  reportHTML += i18n.verifix_probe_ref;
  reportHTML += i18n.colon;
  reportHTML += '</div></td><td><div class="rowText">';
  reportHTML += verifixID || '&ndash;';

  reportHTML += '</div></td></tr><tr class="verifixTableTR"><td><div class="rowTitle">';
  reportHTML += i18n.operating_mode_title;
  reportHTML += i18n.colon;
  reportHTML += '</div></td><td><div class="rowText">';

  const operatingModeMsg = i18nVariablesInjection(i18n.operating_mode_msg, {
    probe_id: probeID,
    ref_id: verifixID,
    date: dateCalibration
  });
  if (operatingModeMsg) {
    // preserve line breaks
    const descLines = operatingModeMsg.split('\n');
    for (let i = 0; i < descLines.length; i++) {
      reportHTML += `<p>${descLines[i]}</p>`;
    }
  } else {
    reportHTML += '&ndash;';
  }
  reportHTML += '</div></td></tr></tbody></table>';

  styleCSS += `.mainTitle  ${styles.mainTitle}`;
  styleCSS += `.verifixTable  ${styles.verifixTable}`;
  styleCSS += `.verifixTable tr  ${styles.verifixTableTR}`;
  styleCSS += `.rowTitle  ${styles.rowTitle}`;
  styleCSS += `.rowText  ${styles.rowText}`;
};

//  /////////////////////////////////
//
//            Verification data
//
const generateVerificationData = (i18n, verifItems) => {
  console.log('verifItems', verifItems);
  reportHTML += '<div class="phaseSection">';
  reportHTML += `<div class="subTitle">${i18n.verif_data}</div>`;
  reportHTML += '<table class="verifTable"><tbody><tr>';
  reportHTML += `<th class="dateCell">${i18n.date_time}</th>`;
  reportHTML += `<th class="tempCell">${i18n.verifix_probe_temp}</th>`;
  reportHTML += `<th class="tempCell">${i18n.verifix_ref_temp}</th>`;
  reportHTML += `<th class="diffCell">${i18n.verifix_diff}</th>`;

  for (let x = 0; x < verifItems.length; x++) {
    const exe = verifItems[x];
    reportHTML += '<tr>';
    reportHTML += `<td class="dateCell">${exe.ts} </td>`;
    reportHTML += `<td class="tempCell">${exe.temp}</td>`;
    reportHTML += `<td class="tempCell">${exe.ref_temp}</td>`;
    reportHTML += `<td class="diffCell">${exe.diff}</td>`;

    reportHTML += '</tr>';
  }

  reportHTML += '</tbody></table>';

  reportHTML += '</div>';

  styleCSS += `.subTitle  ${styles.subTitle}`;
  styleCSS += `.dateCell  ${styles.dateCell}`;
  styleCSS += `.tempCell  ${styles.tempCell}`;
  styleCSS += `.diffCell  ${styles.diffCell}`;
};

//  /////////////////////////////////
//
//            MAIN FUNCTION
//
export async function openPrintableReport(generatedReport) {
  const { reportContent, reportStyle, documentName } = generatedReport;

  const externalWindow = window.open('', '_blank');

  // const headEl = externalWindow.document.createElement('head');
  externalWindow.document.head.innerHTML = fontdef;
  externalWindow.document.title = documentName;

  const styleEl = externalWindow.document.createElement('style');
  externalWindow.document.body.appendChild(styleEl);
  styleEl.innerHTML = reportStyle;

  const reportContainerEl = externalWindow.document.createElement('div');
  reportContainerEl.innerHTML = reportContent;
  externalWindow.document.body.appendChild(reportContainerEl);
}

async function generate(
  i18n,
  // timeFormat,
  dateFormat,
  // degrees,
  data,
  probeID,
  dates,
  endDate,
  verifixID,
  dateCalibration
) {
  reportHTML = '';
  styleCSS = initStyle;
  generateReportHeader(endDate, dateFormat);
  generateVerificationInfo(i18n, probeID, dates, verifixID, dateCalibration);
  generateVerificationData(i18n, data);

  // Phase section styles
  styleCSS += `.phaseSection ${styles.phaseSection}`;
  styleCSS += `.verifTable ${styles.verifTable}`;
  styleCSS += `.verifTable tr ${styles.verifTableTR}`;
  styleCSS += `.verifTable th ${styles.verifTableTH}`;
  styleCSS += `.verifTable td ${styles.verifTableTD}`;

  // Add outermost wrapper
  reportHTML = `<div class="reportWrapper">${reportHTML}</div>`;
  styleCSS += `.reportWrapper ${styles.reportWrapper}`;
  // return { reportHTML, styleCSS };
}
export async function generatePrintableReport(
  i18n,
  // timeFormat,
  dateFormat,
  // degrees,
  data,
  probeID,
  dates,
  endDate,
  verifixID,
  dateCalibration
) {
  const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  await wait(1);
  await generate(
    i18n,
    // timeFormat,
    dateFormat,
    // degrees,
    data,
    probeID,
    dates,
    endDate,
    verifixID,
    dateCalibration
  );
  return { reportContent: reportHTML, reportStyle: styleCSS };
}
