import { Dialog, DialogTitle, FormControlLabel, Checkbox } from '@material-ui/core';
import React from 'react';

import { DialogActionUtil } from '../../../../utils/DialogActions';

export const RemoveProbeDialog = ({
  i18n,
  openDeleteDialog,
  handleCloseDeleteDialog,
  handleDelete,
  textAction,
  removeAll,
  setRemoveAll,
  probeToRemove
}) =>
  !probeToRemove ? null : (
    <div>
      <Dialog
        style={{ minWidth: 350 }}
        open={!!openDeleteDialog}
        onClose={() => handleCloseDeleteDialog()}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle disableTypography style={{ fontSize: '1.2rem' }} id='alert-dialog-title'>
          {removeAll
            ? null
            : `${probeToRemove}${i18n.colon}${i18n.remove_probe_confirm || 'Remove probe?'}`}
          <div style={{ marginTop: 5, marginBottom: -10 }}>
            <FormControlLabel
              // className={classes.formControl}
              control={
                <Checkbox
                  // className={classes.boolean}
                  checked={removeAll}
                  // disabled={Boolean(!isEmpty(pilesToRemove))}
                  onChange={() => setRemoveAll(!removeAll)}
                />
              }
              label={i18n.remove_all_sensors}
            />
          </div>
        </DialogTitle>
        <DialogActionUtil
          onCancel={handleCloseDeleteDialog}
          onSave={handleDelete}
          text={removeAll ? i18n.remove_all_sensors : textAction || i18n.remove || 'remove'}
        />
      </Dialog>
    </div>
  );
