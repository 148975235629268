import { getSignalLevel, getBatteryLevel } from 'assets/utils';

export const getSensorDetails = (id, sensorConfiguration, sensorsData, auxConf) => {
  // Parsing the given route ex: /dashboard/sensor/004056
  // ex: "004056"
  let sensorData;
  let subid;
  let name;
  let temperature;
  let measTs;
  let battVoltage;
  let battery;
  let sensorRSSI;
  let bsRSSI;
  let signal;
  let address;
  let isAux;

  if (auxConf) {
    sensorData = auxConf;
    isAux = true;
    address = sensorData.aa;
    subid = sensorData.aa;
    name = sensorData.a || sensorData.aa;
    temperature = sensorData?.temperature || '';
    measTs = sensorData.meas_ts || 0;
    battVoltage = sensorData?.batt_voltage || 0;
    battery = getBatteryLevel(battVoltage);
    sensorRSSI = sensorData?.sensor_RSSI || 0;
    bsRSSI = sensorData?.bs_RSSI || 0;
    signal = getSignalLevel(sensorRSSI, bsRSSI, measTs);
  } else {
    isAux = false;
    address = sensorConfiguration?.sensor_id_bs;
    sensorData = sensorsData.find((item) => item?.sensor_id === sensorConfiguration.sensor_id_sys);
    subid = sensorConfiguration.sensor_id_sys;
    name = sensorConfiguration.sensor_name ? sensorConfiguration.sensor_name : subid;
    temperature = sensorData ? sensorData.temperature : '';
    measTs = sensorData ? sensorData.meas_ts : 0;
    battVoltage = sensorData ? sensorData.batt_voltage : 0;
    battery = getBatteryLevel(battVoltage);
    sensorRSSI = sensorData ? sensorData.sensor_RSSI : 0;
    bsRSSI = sensorData ? sensorData.bs_RSSI : 0;
    signal = getSignalLevel(sensorRSSI, bsRSSI, measTs);
  }

  return {
    id,
    subid,
    address,
    isAux,
    name,
    temperature,
    meas_ts: measTs,
    batt_voltage: battVoltage,
    battery,
    sensor_RSSI: sensorRSSI,
    bs_RSSI: bsRSSI,
    signal,
    logs: sensorConfiguration?.sensor_log,
    group: null
  };
};

export const updateSensorState = (sensorList, sensorDetails) => {
  // ex: "dashboard"
  // ROUTING SENSOR ARROWS AND MANAGING THE GROUP
  let previous;
  let next;

  // Filter duplicate sensors (possible in multiBS, when same probes under many BSs)
  const noDupsSensorList = sensorList.filter(
    (v, i, a) => a.findIndex((el) => el.id === v.id) === i
  );

  const currentIndex = noDupsSensorList.findIndex((item) => item.id === sensorDetails.id);
  if (currentIndex !== -1) {
    // The sensor is in the list, not grouped
    // Routing the arrows to sensors
    previous = noDupsSensorList[currentIndex - 1];
    next = noDupsSensorList[currentIndex + 1];
  }
  return { previous, next };
};
