import { SET_GROUPDETAILS } from 'redux/constants';
import initialState from 'redux/store/initialState';

// SET_GROUPDETAILS: 29.5.23: used to store a selected storage in redux.
// This is for multi-BS account to indicate a disabled storage, if storage's probes
// are not under the currently selected BS

export default function setGroupDetails(state = initialState.groupDetails, action) {
  switch (action.type) {
    case SET_GROUPDETAILS: {
      return action.value;
    }
    default: {
      return state;
    }
  }
}
