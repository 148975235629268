import { Tooltip, TableSortLabel } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Icon } from '@iconify/react';
import tagIcon from '@iconify/icons-mdi/tag';
import grass from '@iconify/icons-mdi/crown';
import { COLORS } from 'utils/colors';

const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);

const useStyles = makeStyles(() => ({
  tableHeader: {
    marginTop: 5,
    padding: '3px 5px 3px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  headerButton: {
    backgroundColor: COLORS.whisperGray,
    borderRadius: 3,
    padding: '3px 1px 3px 3px',
    marginLeft: 3
  },
  tableHeaderName: {
    flexGrow: 1,
    width: '47%',
    textAlign: 'left',
    marginLeft: 0,
    paddingLeft: 8
  },
  tableHeaderQuality: {
    flexGrow: 1,
    width: '53%',
    textAlign: 'left',
    paddingLeft: 5,
    minWidth: 60,
    boxSizing: 'border-box'
  },
  tableHeaderDrop: {
    flexGrow: 1,
    width: '4%',
    textAlign: 'left',
    // paddingLeft: 5,
    minWidth: 10,
    boxSizing: 'border-box',
    backgroundColor: 'white',
    marginLeft: 0,
    marginRight: 6
  },
  headerIcon: {
    width: 16,
    height: 16,
    color: COLORS.lightGray3,
    '&hover': {
      color: COLORS.primaryColor
    }
  },
  headerCol: {
    marginRight: -5,
    marginBottom: -5
  }
}));

export default function QualitySensorListHeader({
  sortID,
  sortDirection,
  handleSort,
  marginRight
}) {
  const classes = useStyles();
  const i18n = useSelector((state) => state.i18n);

  const sortIds = {
    name: i18n.sensor_title,
    quality: i18n.quality,
    drop: 'drop'
  };
  const labels = {
    name: i18n.sort_by_id || 'Sort by ID',
    quality: i18n.sort_by_quality || 'Sort by quality',
    drop: i18n.sort_by_drop || 'Sort by caution'
  };

  const icons = {
    name: <Icon className={classes.headerIcon} icon={tagIcon} />,
    quality: <Icon className={classes.headerIcon} icon={grass} />,
    drop: <div />
  };

  return (
    <>
      <div className={classes.tableHeader} style={{ marginRight: marginRight || 0 }}>
        {Object.keys(sortIds).map((id) => (
          <TableSortLabel
            key={id}
            className={[
              classes[`tableHeader${capitalizeFirstLetter(id)}`],
              classes.headerButton
            ].join(' ')}
            active={sortID === id}
            direction={sortDirection}
            onClick={() => handleSort(id, sortDirection)}
          >
            <Tooltip
              title={labels[id]}
              placement='left'
              enterDelay={500}
              enterNextDelay={1000}
              disableFocusListener
            >
              <div className={classes.headerCol}>{icons[id]}</div>
            </Tooltip>
          </TableSortLabel>
        ))}
      </div>
    </>
  );
}
