import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { listStyle } from 'utils/sharedStyles';
// import { getMonitors } from 'api/serverAPI';
// import { fetchRCs } from '../helper';
import CompostRCEditor from './CompostRCEditor';
import CompostRCItem from './CompostRCItem';

const CompostRCs = ({ RCs, monitors, setRCsChanged }) => {
  const [selectedRC, setSelectedRC] = useState(null);
  // const [RCs] = useState([givenRCs]);

  const { i18n, userInfo, isSimExpired, batches, storages } = useSelector((store) => ({
    i18n: store.i18n,
    userInfo: store.userInfo,
    sessionInfo: store.sessionInfo,
    isSimExpired: store.isSimExpired,
    batches: store.batches,
    storages: store.storages
    // storeFCs: store.FcStatus
  }));

  // useEffect(() => {
  //   console.log('CompostRCs Use eff ', storeFCs);
  //   // if (userInfo.FC_IDs && (!RCs || RCs.length < 1)) {
  //   // fetchRCs(userInfo.FC_IDs, setRCs);
  //   // }
  //   setRCs(storeFCs);
  // }, [storeFCs]);

  const onRCSelected = (rc) => {
    let theRC = null;
    // console.log('onSelected: ', rc.name);
    if (RCs && rc) {
      theRC = RCs?.find((temp) => temp.FC_ID === rc.FC_ID && temp.channel === rc.channel);
      // console.log('theRC= ', theRC.name);
      if (theRC) {
        setSelectedRC(theRC);
        return;
      }
    }
    setSelectedRC(null);
  };

  // eslint-disable-next-line no-unused-vars
  const closeRC = () => {
    // console.log('closeRC');
    // fetchRCs(userInfo.FC_IDs, setRCs);
    setSelectedRC(null);
  };

  const listClasses = listStyle();

  return (
    <div className={listClasses.list}>
      {isSimExpired ? (
        <div className={listClasses.welcome}>
          <h2>{i18n.process_templates || 'Process templates'}</h2>
          <>{i18n.sim_expired_msg}</>
        </div>
      ) : (
        <>
          {selectedRC && (
            <CompostRCEditor
              selectedRC={selectedRC}
              onClose={() => closeRC()}
              userInfo={userInfo}
              rcStorage={
                selectedRC.inUse ? storages?.find((s) => s.itemID === selectedRC.inUse) : null
              }
              rcBatch={
                selectedRC.batchRef
                  ? batches?.find((b) => b.batch_id === selectedRC.batchRef)
                  : null
              }
              compostMonitors={monitors?.filter((mon) => mon.compost)}
              batches={batches}
              RCs={RCs}
              setRCsChanged={setRCsChanged}
            />
          )}
          {RCs?.map((item) => (
            <CompostRCItem
              rcStorage={item.inUse ? storages?.find((s) => s.itemID === item.inUse) : null}
              rcBatch={item.batchRef ? batches?.find((b) => b.batch_id === item.batchRef) : null}
              key={`${item.FC_ID}.${item.channel}`}
              rc={item}
              onRCSelected={onRCSelected}
              monitors={monitors}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default CompostRCs;
