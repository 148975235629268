import { Dialog, Grow } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Spinner } from 'utils/Spinners';
import DialogTitleWithClose from 'utils/DialogWithCloseIcon';
import { getRenewalInfo } from 'api/serverAPI';
import ServiceRenewalForm from './ServiceRenewalForm';
import { generateBillingInfo } from '../helpers';
import { ELEMENTS_OPTIONS } from '../StripeElements';

const ServiceRenewalDialog = ({ unauthorized }) => {
  const history = useHistory();

  const { i18n, userInfo } = useSelector((store) => ({
    i18n: store.i18n,
    userInfo: store.userInfo
  }));

  const [renewalState, setRenewalState] = useState({});
  const [stripeLoader, setStripeLoader] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const headerLogic = (type) => {
    if (type === 'installment') {
      return i18n.insight_installment || 'Insight Installment';
    }
    return i18n.insight_renewal || 'Insight Renewal';
  };

  const [headerName] = useState(headerLogic(userInfo.service.nextPayment.type));

  useEffect(() => {
    const getInfo = async () => {
      try {
        const renewalInfo = await getRenewalInfo({
          U_ID: userInfo.U_ID
        });
        setRenewalState((prevState) => ({
          ...prevState,
          ...generateBillingInfo(userInfo, i18n),
          userInfo,
          renewalInfo
        }));
        setStripeLoader(renewalInfo.stripe_pk);
        setIsLoading(false);
      } catch (e) {
        console.log('ServiceRenewalDialog ERROR', e);
      }
    };

    if (isLoading) {
      getInfo();
    }
  }, [i18n, isLoading, userInfo]);

  const onCloseCheckout = () => {
    history.replace('/account/services');
  };

  if (!stripeLoader) {
    return null;
  }

  const stripePromise = loadStripe(stripeLoader);

  return (
    <Dialog
      open
      maxWidth='md'
      PaperProps={{ style: { overflow: 'auto' } }}
      TransitionComponent={Grow}
    >
      {isLoading ? (
        <div style={{ margin: 50 }}>
          <Spinner noStyle />
        </div>
      ) : (
        <>
          <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS(i18n.locale)}>
            <DialogTitleWithClose onClose={onCloseCheckout}>{headerName}</DialogTitleWithClose>
            <ServiceRenewalForm
              i18n={i18n}
              renewalState={renewalState}
              unauthorized={unauthorized}
            />
          </Elements>
        </>
      )}
    </Dialog>
  );
};

export default ServiceRenewalDialog;
