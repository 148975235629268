import { Tooltip } from '@material-ui/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { format, startOfDay } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import localeFormat from 'utils/localeFormat';

import { tempFormat, isNil } from 'assets/utils';
import { COLORS } from 'utils/colors';
import TempTrapezoidSVG from './TempTrapezoidSVG';

const useStyles = makeStyles(() => ({
  dayBar: {
    width: 25,
    color: COLORS.primaryColor,
    fontSize: 12,
    textAlign: 'center',
    overflow: 'hidden'
  },
  dayLabel: {
    fontSize: 10,
    textAlign: 'center',
    cursor: 'default',
    textTransform: 'capitalize'
  }
}));

export default function DayTrendBar({
  itemID,
  timestamp,
  scaleMin,
  scaleMax,
  dayData,
  prevDayData,
  isAggregate,
  nPoints,
  depth,
  height,
  isInteractive
}) {
  const classes = useStyles();

  const { i18n, dateFormat, degrees } = useSelector((store) => ({
    i18n: store.i18n,
    dateFormat: store.sessionInfo?.ui_preferences?.date_format,
    degrees: store.sessionInfo?.ui_preferences?.degrees
  }));

  // If nPoints===0, it is Haytech sensor, so the values are taken from h & l-fields instead of hh & ll

  // Check for invalid data (mixed single and multipoint values on one sensor)

  const dataError =
    depth > 1 &&
    ((dayData && dayData.map((d) => d.hh).includes(undefined)) ||
      (prevDayData && prevDayData.map((d) => d.hh).includes(undefined)));

  const maxTemp = dataError
    ? null
    : isAggregate
    ? Math.max(
        ...dayData
          .filter((d) => d.hh)
          .map((d) => d.hh.slice(1, d.hh.length)) // ignore 1st (handle temp)
          .flat(1)
          .filter((t) => !isNil(t))
          .concat(dayData.filter((d) => !d.hh).map((d) => d.h))
      )
    : Math.max(
        ...dayData
          .map((data) => (nPoints && data.hh ? data.hh[depth] : data.h))
          .filter((t) => !isNil(t))
      );

  const minTemp = dataError
    ? null
    : isAggregate
    ? Math.min(
        ...dayData
          .filter((d) => d.ll)
          .map((d) => d.ll.slice(1, d.ll.length)) // ignore 1st (handle temp)
          .flat(1)
          .concat(dayData.filter((d) => !d.ll).map((d) => d.l))
          .filter((t) => !isNil(t)) // Ignore null temps
      )
    : Math.min(
        ...dayData
          .map((data) => (nPoints && data.ll ? data.ll[depth] : data.l))
          .filter((t) => !isNil(t))
      );

  const maxPrevTemp = dataError
    ? null
    : isAggregate
    ? prevDayData && prevDayData.length > 0
      ? Math.max(
          ...prevDayData
            .filter((d) => d.hh)
            .map((d) => d.hh.slice(1, d.hh.length)) // ignore 1st (handle temp)
            .flat(1)
            .concat(prevDayData.filter((d) => !d.hh).map((d) => d.h))
            .filter((t) => !isNil(t)) // Ignore null temps
        )
      : maxTemp
    : prevDayData && prevDayData.length > 0
    ? Math.max(
        ...prevDayData
          .map((data) => (nPoints && data.hh ? data.hh[depth] : data.h))
          .filter((t) => !isNil(t))
      )
    : maxTemp;

  const minPrevTemp = dataError
    ? null
    : isAggregate
    ? prevDayData && prevDayData.length > 0
      ? Math.min(
          ...prevDayData
            .filter((d) => d.ll)
            .map((d) => d.ll.slice(1, d.ll.length)) // ignore 1st (handle temp)
            .flat(1)
            .concat(prevDayData.filter((d) => !d.hh).map((d) => d.l))
            .filter((t) => !isNil(t)) // Ignore null temps
        )
      : minTemp
    : prevDayData && prevDayData.length > 0
    ? Math.min(
        ...prevDayData
          .map((data) => (nPoints && data.ll ? data.ll[depth] : data.l))
          .filter((t) => !isNil(t))
      )
    : minTemp;

  const texts = [format(timestamp * 1000, dateFormat)];
  if (
    !dayData ||
    dayData.length === 0 ||
    dataError ||
    minTemp === Infinity ||
    maxTemp === -Infinity
  ) {
    texts.push(i18n.nodata || 'No data');
  } else {
    texts.push(
      `${i18n.maximum ? i18n.maximum[0].toUpperCase() + i18n.maximum.slice(1) : 'Max'}${
        i18n.colon
      }${tempFormat(degrees, maxTemp)} ${degrees}`
    );
    texts.push(
      `${i18n.minimum ? i18n.minimum[0].toUpperCase() + i18n.minimum.slice(1) : 'Min'}${
        i18n.colon
      }${tempFormat(degrees, minTemp)} ${degrees}`
    );
  }

  // For today's bar, do not show min/max lines in full width, but instead end the lines in "real time",
  // according to the last data timestamp. E.g. at noon, the lines are to halfway of the day.
  let todayDataHours = 24;
  if (
    dayData &&
    dayData[0] &&
    +startOfDay(new Date(dayData[0].b * 1000)) === +startOfDay(new Date())
  ) {
    const lastTS = Math.max(...dayData.map((d) => d.b2));
    if (lastTS) {
      todayDataHours = Math.min(24, +format(lastTS * 1000, 'H') + 1);
    }
  }

  const [dayHover, setDayHover] = useState(false);

  const dayBar =
    !dayData || dayData.length === 0 || minTemp === Infinity || maxTemp === -Infinity ? (
      <div
        className={classes.noDataBox}
        style={{
          height: height + 2,
          boxSizing: 'border-box',
          backgroundColor: COLORS.whiteSmoke,
          border: '1px solid #ccc',

          borderLeft: 'none',
          borderRight: '1px solid #ccc'
        }}
      />
    ) : (
      <div
        style={{
          boxSizing: 'border-box',
          border: '1px solid #ccc',
          borderLeft: 'none',
          borderRight: '1px solid #ccc'
        }}
      >
        <TempTrapezoidSVG
          id={`${itemID}_${timestamp}_${depth || 1}`} // gradients seem to need a unique id
          style={{
            maxHeight: height,
            minHeight: height,
            boxSizing: 'border-box',
            backgroundColor: dayHover ? COLORS.extandedRow : COLORS.white
          }}
          wid={24}
          dataTo={todayDataHours}
          hei={height}
          T0={scaleMin}
          T1={scaleMax}
          noData={!dayData || dayData.length === 0}
          maxTemp0={maxPrevTemp}
          maxTemp1={maxTemp}
          minTemp0={minPrevTemp}
          minTemp1={minTemp}
          bgColor={dayHover ? COLORS.extandedRow : COLORS.white}
        />
      </div>
    );

  // Multi-line tooltip
  const tooltipText = <span style={{ whiteSpace: 'pre-line' }}>{texts.join('\n')}</span>;
  return isInteractive ? (
    <Tooltip title={tooltipText} placement='bottom' arrow disableFocusListener>
      <div
        className={classes.dayBar}
        style={{ height: height + 20 }}
        onMouseEnter={() => setDayHover(true)}
        onMouseLeave={() => setDayHover(false)}
      >
        {dayBar}
        <div className={classes.dayLabel}>
          {format(timestamp * 1000, 'EEEEEE', { locale: localeFormat(i18n.locale) })}
        </div>
      </div>
    </Tooltip>
  ) : (
    <div className={classes.dayBar} style={{ height: height + 20 }}>
      {dayBar}
      <div className={classes.dayLabel}>
        {format(timestamp * 1000, 'EEEEEE', { locale: localeFormat(i18n.locale) })}
      </div>
    </div>
  );
}
