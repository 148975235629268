import React, { useState } from 'react';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { InlineIcon } from '@iconify/react';
import handRight from '@iconify/icons-mdi/hand-right';
import { getGradientColor, tempFormat } from 'assets/utils';
import { COLORS } from 'utils/colors';
import classes from './SpecialSensorTooltip.module.css';

export default function CustomTooltip(props) {
  const degrees = useSelector((store) => store.sessionInfo?.ui_preferences?.degrees ?? '°C');

  const {
    datum,
    x,
    y,
    displaydata,
    chartHei,
    dateFormat,
    timeFormat,
    selectedDepth,
    windowWidth,
    handleShowed
  } = props;

  const COMPACT = 5;
  const XTRACOMPACT = 12;
  const dataX = datum.x;
  const dataY = datum.y;

  const [dataLength] = useState(
    displaydata && displaydata[0] && displaydata[0].n ? displaydata[0].n : displaydata[0].hh.length
  );

  const hh =
    !displaydata?.results &&
    displaydata?.find((t) => t.b * 1000 === dataX)?.hh?.slice(1, dataLength + 1)
      ? displaydata?.find((t) => t.b * 1000 === dataX)?.hh?.slice(1, dataLength + 1)
      : [];

  const handleTemp =
    !displaydata?.results && displaydata?.find((t) => t.b * 1000 === dataX)?.hh[0]
      ? displaydata?.find((t) => t.b * 1000 === dataX)?.hh[0]
      : [];

  const depthHH = hh?.map((temp, i) => (
    <div
      key={i}
      className={
        dataLength >= XTRACOMPACT
          ? classes.tooltipTempsXtraCompact
          : dataLength >= COMPACT
          ? classes.tooltipTempsCompact
          : classes.tooltipTemps
      }
      style={
        selectedDepth === i + 1
          ? { fontWeight: 'bold' }
          : selectedDepth === ''
          ? {}
          : { color: COLORS.warning }
      }
    >
      <span
        className={
          dataLength >= XTRACOMPACT
            ? [classes.dot, classes.dotXtraCompact].join(' ')
            : dataLength >= COMPACT
            ? [classes.dot, classes.dotCompact].join(' ')
            : classes.dot
        }
        style={
          selectedDepth !== i + 1 && selectedDepth !== ''
            ? { backgroundColor: COLORS.white }
            : { backgroundColor: getGradientColor(temp) }
        }
      />
      {tempFormat(degrees, temp)} {degrees}
    </div>
  ));

  const calcBarHeight = (nPoints) =>
    nPoints > 0
      ? 20 + (nPoints - 1) * (nPoints >= XTRACOMPACT ? 11 : nPoints >= COMPACT ? 18 : 28)
      : 0;

  const verticalOffset = 15;
  const horizontalOffset = x >= 1275 && windowWidth <= 1825 ? 100 : 0;
  const centered = {
    x: x - 113 / 2 - horizontalOffset,
    y: y - (186 + verticalOffset)
  };

  return (
    <svg style={{ pointerEvents: 'none' }}>
      {selectedDepth ? (
        <circle cx={x} cy={y} r='6' stroke='none' fill={getGradientColor(dataY)} />
      ) : (
        <line
          role='presentation'
          x1={x}
          x2={x}
          y1={50}
          y2={chartHei - 50}
          style={{ stroke: 'rgba(0,0,0,0.2)', strokeWidth: 3 }}
        />
      )}

      <foreignObject x={centered.x} y={y} width='500px' height='100%'>
        <div
          xmlns='http://www.w3.org/1999/xhtml'
          className={selectedDepth ? [classes.tooltip, classes.tip].join(' ') : classes.tooltip}
        >
          <div className={classes.tooltipDateTime}>
            <div className={classes.tooltipDate}>{format(dataX, dateFormat)}</div>
            <div className={classes.tooltipTime}>{format(dataX, timeFormat)}</div>
          </div>
          {handleShowed && (
            <div className={classes.tooltipHandleTemp}>
              <InlineIcon rotate='90deg' icon={handRight} />
              {` ${tempFormat(degrees, handleTemp)} ${degrees}`}
            </div>
          )}
          <div
            className={classes.vl}
            style={{
              height: `${calcBarHeight(hh.length)}px`,
              borderLeft: `${hh.length > 4 ? 2 : 3}px solid #252525`
            }}
          />
          {depthHH}
        </div>
      </foreignObject>
    </svg>
  );
}
