import { Tooltip, Button } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { Icon } from '@iconify/react';
import warning from '@iconify/icons-mdi/arrow-down-circle-outline';
import alert from '@iconify/icons-mdi/arrow-down-circle';

import { makeStyles } from '@material-ui/core/styles';
import { i18nVariablesInjection } from 'utils/sharedHelper';
import { COLORS } from 'utils/colors';
import { celsiusToF, isQualityWarning, isQualityAlert } from 'assets/utils';

const useStyles = makeStyles(() => ({
  qBarWrapper: {
    margin: 5,
    marginRight: 8,
    display: 'flex',
    padding: 0,
    marginLeft: 0
  },
  qSquare: {
    width: 20,
    height: 15,
    border: `1px solid ${COLORS.white}`
  },
  noqSquare: {
    width: 20,
    height: 15,
    border: `1px solid ${COLORS.white}`
  },
  qDropIndicator: {
    color: COLORS.alert,
    position: 'absolute',
    right: -20,
    top: 5,
    width: 18,
    height: 18
  }
}));

const QualityBar = ({ probeQuality, onQualityClick, periodUnset, i18n }) => {
  const classes = useStyles();
  const degrees = useSelector((store) => store.sessionInfo?.ui_preferences?.degrees);
  let temperature = probeQuality?.lastTemp;
  if (probeQuality?.lastTemp && degrees === '°F') {
    temperature = celsiusToF(probeQuality.lastTemp);
  }
  const nLevels = 5;
  const indices = Array.from(Array(nLevels).keys()); // [0,1,2,3]
  const barSquares = indices.map((i) => (
    <div
      style={{
        backgroundColor: probeQuality?.bars
          ? i >= probeQuality.bars
            ? COLORS.altoGray
            : probeQuality?.color
          : periodUnset
          ? COLORS.altoGray
          : 'transparent'
      }}
      className={probeQuality?.bars ? classes.qSquare : classes.noqSquare}
      key={i}
    />
  ));

  const getTooltipText = (nBars) => {
    switch (nBars) {
      case 5:
        return i18n.quality_rate_title_5;
      case 4:
        return i18n.quality_rate_title_4;
      case 3:
        return i18n.quality_rate_title_3;
      case 2:
        return i18n.quality_rate_title_2;
      case 1:
        return i18n.quality_rate_title_1;
      default:
        return '';
    }
  };

  const dropAlertIndicator = isQualityAlert(probeQuality) ? (
    <Tooltip
      title={i18nVariablesInjection(i18n.quality_drop_alert, {
        temperature,
        degrees,
        nHours: Math.round(probeQuality?.daysToDrop * 24)
      })}
      placement='right'
      disableFocusListener
    >
      <Icon icon={alert} className={classes.qDropIndicator} />
    </Tooltip>
  ) : isQualityWarning(probeQuality) ? (
    <Tooltip
      title={i18nVariablesInjection(i18n.quality_drop_warning, {
        temperature,
        degrees,
        nDays: Math.round(probeQuality?.daysToDrop)
      })}
      placement='right'
      disableFocusListener
    >
      <Icon icon={warning} className={classes.qDropIndicator} />
    </Tooltip>
  ) : null;

  return (
    <>
      <Tooltip
        title={
          probeQuality?.bars
            ? getTooltipText(probeQuality.bars)
            : periodUnset
            ? i18n.storage_period_not_set || 'Storage period not set'
            : i18n.storage_period_no_data || 'No data from storage period'
        }
        placement='right'
        disableFocusListener
      >
        <Button onClick={() => onQualityClick(probeQuality)} className={classes.qBarWrapper}>
          {barSquares}
        </Button>
      </Tooltip>
      {dropAlertIndicator}
    </>
  );
};

export default QualityBar;
