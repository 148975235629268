import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({ component: Component, props: cProps, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      cProps.isLoggedIn ? (
        <Component {...props} {...cProps} />
      ) : (
        <Redirect to={`/login?redirect=${props.location.pathname}${props.location.search}`} />
      )
    }
  />
);

export default PrivateRoute;
