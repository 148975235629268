export const COLORS = {
  primaryColor: '#252525', // black
  secondaryColor: '#6EBDBB', // blue-green
  secondaryDark: '#4D8482', // dark blue-green
  adminDev: '#FF6F00', // blaze orange
  adminProd: '#C83232', // persian red
  premium: '#669966', // green
  compost: '#cb5d12', // orange
  supervisor: '#265969', // dark blue
  haytech: '#99cc33', // light green
  quality4: '#92D050',
  quality3: '#B7C153',
  quality2: '#9E924F',
  quality1: '#74653C',
  quality0: '#7F5441',
  fcBackground: '#f1f5fb', // very ligtblue
  reminder: '#ef6c00', // orange
  validStatus: '#2e6ca4', // blue
  invalidStatus: '#be1508', // red
  warning: '#aaa', // red
  alert: '#f44336', // Red Orange
  schedulerRed: '#BF4B47', // Red
  exetypeIcon: '#b30303', // Red
  chartArea: '#e5efef', // blue zurcon
  whisperGray: '#eee', // whisper gray,
  altoGray: '#ddd', // alto gray,
  lightGray: '#d4d4d4', // light gray
  lightGray2: '#ccc', // light gray
  lightGray3: '#999', // light gray
  mediumGray: '#666', // medium gray
  darkGray: '#515151', // dark gray
  dimGray: '#717171', // dim gray,
  boulderGray: '#777', // boulder gray,
  eclipseGray: '#3f3f3f', // eclipse gray,
  silverSonic: '#757575', // Sonic Silver,
  chetwodeBlue: '#5c6bc0', // chetwodeBlue
  lightBlue: '#c5cae9', // light blue,
  zirconBlue: '#E5EFEF', // blue zircon;
  mediumPurple: '#66d', // medium purple,
  greenJade: '#00b34a', // green jade,
  greenOlive: '#787800', // green olive,
  greenForest: '#78c277', // green forest,
  darkGreen: '#138147', // dark green,
  yellow: '#ffff00', // electric yellow,
  yellowBright: '#F0F01E', // bright Turquoise,
  orange: '#ffab00', // orange,
  ruleTypeMax: '#bb9999', // rosy Brown,
  ruleTypeMin: '#8899bb', // rock Blue,
  extandedRow: '#f1f1fe', // ghost white,
  selectedRow: '#e1e1ee', // quartz white,
  tango: '#e99eff', // light magenta,
  tangoXN: '#f7fa19', // bright yellow,
  tangoTXN: '#808080', // medium grat,
  white: '#ffffff', // white
  whiteSmoke: '#f5f5f5', // White Smoke
  snow: '#fff5f5', // very light pink,
  black: '#000000', // pure black,
  periskopGreen: '#30B08C',
  periskopBlue: '#0E3B43',
  periskopLightBlue: '#44575B'
};
