import { Tooltip, TableSortLabel } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Icon } from '@iconify/react';
import tagIcon from '@iconify/icons-mdi/tag';
import temperatureCelsius from '@iconify/icons-mdi/temperature-celsius';
import temperatureFahrenheit from '@iconify/icons-mdi/temperature-fahrenheit';
import progressClock from '@iconify/icons-mdi/progress-clock';
import wifiIcon from '@iconify/icons-mdi/wifi';
import { COLORS } from 'utils/colors';

const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);

const useStyles = makeStyles(() => ({
  tableHeader: {
    marginTop: 5,
    marginBottom: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  headerButton: {
    backgroundColor: COLORS.whisperGray,
    borderRadius: 3,
    padding: '3px 1px 3px 3px',
    marginLeft: 3
  },
  tableHeaderName: {
    flexGrow: 1,
    width: '45%',
    textAlign: 'left',
    marginLeft: 0,
    paddingLeft: 8
  },
  tableHeaderTemperature: {
    flexGrow: 1,
    width: '25%',
    textAlign: 'left',
    paddingLeft: 5,
    minWidth: 60,
    boxSizing: 'border-box'
  },
  tableHeaderUpdated: {
    flexGrow: 1,
    width: 60,
    textAlign: 'left',
    boxSizing: 'border-box'
  },
  tableHeaderSignal: {
    flexGrow: 1,
    width: 40,
    textAlign: 'left',
    boxSizing: 'border-box'
  },
  headerIcon: {
    width: 16,
    height: 16,
    color: COLORS.lightGray3,
    '&hover': {
      color: COLORS.primaryColor
    }
  },
  headerCol: {
    marginRight: -5,
    marginBottom: -5
  }
}));

export default function SensorListHeader({ sortID, sortDirection, handleSort, marginRight }) {
  const classes = useStyles();
  const i18n = useSelector((state) => state.i18n);
  // const isPremium = useSelector((state) => state.isPremium);
  const degrees = useSelector((store) => store.sessionInfo?.ui_preferences?.degrees || '°C');

  const sortIds = {
    name: i18n.sensor_title,
    temperature: i18n.temperature,
    signal: i18n.signal,
    updated: i18n.updated
  };
  const labels = {
    name: i18n.sort_by_id || 'Sort by ID',
    temperature: i18n.sort_by_temp || 'Sort by temperature',
    signal: i18n.sort_by_signal || 'Sort by signal strength',
    updated: i18n.sort_by_updated || 'Sort by last measurement time'
  };

  const icons = {
    name: <Icon className={classes.headerIcon} icon={tagIcon} />,
    temperature: (
      <Icon
        className={classes.headerIcon}
        icon={degrees === '°C' ? temperatureCelsius : temperatureFahrenheit}
      />
    ),
    signal: <Icon className={classes.headerIcon} icon={wifiIcon} />,
    updated: <Icon className={classes.headerIcon} icon={progressClock} />
  };

  return (
    <>
      <div className={classes.tableHeader} style={{ marginRight: marginRight || 0 }}>
        {Object.keys(sortIds).map((id) => (
          <TableSortLabel
            key={id}
            className={[
              classes[`tableHeader${capitalizeFirstLetter(id)}`],
              classes.headerButton
            ].join(' ')}
            active={sortID === id}
            direction={sortDirection}
            onClick={() => handleSort(id, sortDirection)}
          >
            <Tooltip
              title={labels[id]}
              placement='left'
              enterDelay={500}
              enterNextDelay={1000}
              disableFocusListener
            >
              <div className={classes.headerCol}>{icons[id]}</div>
            </Tooltip>
          </TableSortLabel>
        ))}
      </div>
    </>
  );
}
