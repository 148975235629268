import { SET_PREMIUMCONGRATS } from 'redux/constants';
import initialState from 'redux/store/initialState';

export default function setPremiumCongrats(state = initialState.premiumCongrats, action) {
  switch (action.type) {
    case SET_PREMIUMCONGRATS: {
      return action.value;
    }
    default: {
      return state;
    }
  }
}
