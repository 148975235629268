import { Tooltip, ListSubheader } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { InlineIcon } from '@iconify/react';
import layers from '@iconify/icons-mdi/layers';
import layersTripleOutline from '@iconify/icons-mdi/layers-triple-outline';

const StorageTypeOptions = ({ classes, i18n, typeSelection, setTypeSelection }) => (
  <>
    <ListSubheader className={classes.typeTitle} component='div' disableSticky>
      {i18n.storage_structure_type || 'Storage type'}
    </ListSubheader>
    <ToggleButtonGroup color='primary' exclusive>
      <Tooltip
        title={i18n.storage_type_stack_desc || 'Stack storage with HAYTECH probes'}
        placement='bottom'
        disableFocusListener
      >
        <ToggleButton
          className={classes.storageTypeToggleButton}
          value='stack'
          selected={typeSelection === 'stack'}
          onClick={() => setTypeSelection('stack')}
        >
          <InlineIcon
            className={classes.stackIcon}
            icon={layersTripleOutline}
            width={40}
            height={40}
          />
          {i18n.storage_type_stack || 'Stack'}
        </ToggleButton>
      </Tooltip>

      <Tooltip
        title={i18n.storage_type_flat_desc || 'Flat storage with Tango probes'}
        placement='bottom'
        disableFocusListener
      >
        <ToggleButton
          className={classes.storageTypeToggleButton}
          value='flat'
          selected={typeSelection === 'flat'}
          onClick={() => setTypeSelection('flat')}
        >
          <InlineIcon className={classes.flatIcon} icon={layers} width={40} height={40} />
          {i18n.storage_type_flat || 'Flat'}
        </ToggleButton>
      </Tooltip>
    </ToggleButtonGroup>
  </>
);

export default StorageTypeOptions;
