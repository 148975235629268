import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableSortLabel
} from '@material-ui/core';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import { isEmpty, isNaN } from 'lodash';
import { format, isToday } from 'date-fns';
import { COLORS } from 'utils/colors';
import { isNil } from 'assets/utils';
import { stableSort, getComparator } from 'utils/sorting';
import { isEmpty } from 'lodash';

export const adminTableStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    height: 'inherit',
    whiteSpace: 'nowrap',
    marginTop: 20
  },
  nodata: {
    textAlign: 'center',
    margin: 30
  },
  tableContainer: {
    // height: 'inherit'
    height: '100%'
  },
  paper: {
    padding: 5
  },
  table: {
    minWidth: 550
  },
  row: {
    '&:hover': {
      backgroundColor: COLORS.whisperGray,
      cursor: 'pointer'
    }
  },
  cell: {
    border: '1px solid #eeeeeedb',
    padding: 2,
    maxWidth: 400,
    overflow: 'hidden',
    textAlign: 'left',
    paddingLeft: 10,
    fontSize: 13,
    lineHeight: '15px'
  },
  trExtanded: {
    backgroundColor: COLORS.extandedRow
  },
  extCell: {
    borderBottom: `1px solid ${COLORS.selectedRow}`
  },
  alertCell: {
    color: COLORS.alert,
    position: 'relative',
    overflow: 'hidden',
    fontWeight: 'bold'
  },
  coverBar: {
    background: COLORS.alert,
    height: 14,
    position: 'absolute',
    width: '50%',
    opacity: 0.15,
    right: 3
  },
  selectedRow: {
    fontWeight: 'bold',
    backgroundColor: COLORS.selectedRow,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  head: {
    background: '-webkit-gradient(linear, left top, left bottom, from(#f6f6f6), to(#eee))',
    whiteSpace: 'nowrap'
  },
  infoWrapper: {
    width: '100%',
    overflow: 'auto',
    background: COLORS.whisperGray,
    fontSize: 15,
    // fontWeight: 'bold',
    padding: 15,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  },
  infoHeader: {
    fontSize: 18,
    marginBottom: 10
  },
  infoImage: {
    width: 250,
    height: 'fit-content',
    minHeight: 150,
    marginRight: 20,
    marginTop: 30,
    [theme.breakpoints.down('xs')]: {
      marginTop: 10,
      width: 170,
      minHeight: 100
    }
  },
  infoText: { whiteSpace: 'normal', fontSize: 13, paddingBottom: 15 },
  tableWrapper: {
    maxHeight: 135,
    overflowY: 'auto'
  },
  infoTextblock: {
    display: 'block',
    maxWidth: 600,
    maxHeight: 300,
    overflowY: 'auto',
    [theme.breakpoints.down('xs')]: {
      maxHeight: 180
    }
  },
  spinnerContainer: {
    display: 'flex',
    marginTop: 50,
    justifyContent: 'center'
  }
}));

const AdminTable = ({ data }) => {
  const classes = adminTableStyles();

  const headCells = [
    { id: 'dayIndex', label: '#Day (data)' },
    { id: 'from', label: 'Date' },
    { id: 'N', label: 'N' },
    { id: 'coveredHours', label: 'Hours covered' },
    { id: 'minTemp', label: 'Temp range' },
    { id: 'minHH', label: 'N temps' },
    { id: 'minBatt', label: 'Batt range' },
    { id: 'signal', label: 'dBm' }
  ];

  const [order, setOrder] = React.useState(headCells[0].ts);
  const [orderBy, setOrderBy] = React.useState('asc');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    // console.log('ORDER ', orderBy, order);
  };

  return (
    <div className={classes.root}>
      {/* //   <div className={classes.paper}> */}
      <AdminDataTable
        classes={classes}
        order={order}
        orderBy={orderBy}
        handleRequestSort={handleRequestSort}
        data={data}
        headCells={headCells}
      />
      {!data && <div className={classes.nodata}>Yet no data for analysis</div>}
    </div>
  );
};

export const EnhancedTableHead = ({ classes, order, orderBy, onRequestSort, headCells }) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.head}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='center'
            className={classes.cell}
            padding='normal'
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export const AdminTableBody = ({ classes, tableData, order, orderBy, headCells }) => {
  const [expandedTS, setExpandedTS] = useState(0);

  const onClickHandler = (ts) => {
    // console.log('row', row);
    if (expandedTS === ts) {
      // console.log('collapse', format(new Date(ts * 1000), 'd.M.yyyy'));
      setExpandedTS(0); // deselect
    } else {
      // console.log('expand', format(new Date(ts * 1000), 'd.M.yyyy'));
      setExpandedTS(ts);
    }
  };

  const getCellValue = (id, data, meas, index) => {
    let pkts = [];
    if (id === 'from' || id === 'N') {
      pkts = data.data
        .map((d) => d.pktc)
        .filter(Boolean)
        .filter((v, i, a) => a.indexOf(v) === i); // remove duplicate pkt counters
    }
    if (id === 'from') {
      let obj = null;
      if (!meas && data?.data) {
        if (!isEmpty(pkts)) {
          obj = (
            <span
              style={{
                fontSize: 10,
                marginLeft: 3,
                color: pkts.length >= 36 ? COLORS.alert : 'inherit'
              }}
            >{` ♦ ${pkts.length} / d`}</span>
          );
        }
      }
      return meas
        ? {
            text: `${format(meas.b * 1000, 'd.M.yyyy HH:mm:ss')}`,
            obj: meas.pktc ? (
              <span style={{ fontSize: 10, marginLeft: 3 }}>{` ♦ ${meas.pktc}`}</span>
            ) : null
          }
        : { text: format(data.from * 1000, 'd.M.yyyy'), obj };
    }
    if (id === 'dayIndex') {
      return meas
        ? { text: '' }
        : { text: `#${data.dayIndex}${data.dataDayIndex ? ` (${data.dataDayIndex})` : ''}` };
    }
    if (id === 'N') {
      return meas
        ? { text: `#${index}` }
        : { text: `${data.N} / d`, alert: isEmpty(pkts) && (!data.N || data.N >= 36) };
    }
    if (id === 'coveredHours') {
      if (meas) {
        return { text: `${`0${new Date(meas.b * 1000).getHours()}`.slice(-2)}h` };
      }
      if (data.N) {
        const dayHours = isToday(new Date(data.from * 1000)) ? new Date().getHours() + 1 : 24;
        return {
          text: `${data.coveredHours} / ${
            dayHours // isToday(new Date(data.from * 1000)) ? new Date().getHours() + 1 : 24
          }h`,
          alert:
            // !isToday(new Date(data.from * 1000)) &&
            data.coveredHours < dayHours && Math.ceil(((dayHours - data.coveredHours) / 24) * 100)
        };
      }
      return { text: '' };
    }
    if (id === 'minTemp') {
      return meas
        ? meas.hh
          ? {
              text: `[${meas.hh
                .map((tmp) => (isNil(tmp) ? 'null' : Math.round(tmp)))
                .join(', ')}] °C`,
              alert: meas.hh.filter((tmp) => isNil(tmp) || tmp <= 0).length > 0
            }
          : { text: `${Math.round(meas.h)} °C`, alert: data?.data && data.data.find((d) => d.hh) }
        : data.N
        ? {
            text: `${
              Math.round(data.minTemp) === Math.round(data.maxTemp)
                ? `${Math.round(data.minTemp)}`
                : `${Math.round(data.minTemp)}-${Math.round(data.maxTemp)}`
            } ${
              data.minHandle && data.maxHandle
                ? Math.round(data.minHandle) === Math.round(data.maxHandle)
                  ? ` [✋${Math.round(data.minHandle)}]`
                  : ` [✋${Math.round(data.minHandle)}-${Math.round(data.maxHandle)}]`
                : ''
            } °C`,
            alert:
              data.minTemp <= 0 ||
              data.maxTemp > 90 ||
              (data.maxHandle && Math.round(data.maxHandle) >= 50)
          }
        : { text: '' };
    }
    if (id === 'minHH') {
      if (!data.N) {
        return { text: '' };
      }
      if (meas) {
        return {
          text: meas.hh?.filter((h) => !isNil(h)).length || '',
          alert: meas.hh && meas.hh.length !== meas.hh.filter((h) => !isNil(h)).length
        };
      }
      if (data.minHH === data.maxHH) {
        const hhDiff = data.data?.filter((d) => d.hh && d.hh.length !== data.minHH).length > 0;
        return { text: data.minHH, alert: hhDiff };
      }
      return { text: `${data.minHH} - ${data.maxHH}`, alert: true };
    }
    if (id === 'minBatt') {
      if (meas) {
        return { text: Math.round(meas.e), alert: Math.round(meas.e) < 360 };
      }
      return data.N
        ? {
            text: `${Math.round(data.minBatt)} - ${Math.round(data.maxBatt)}`,
            alert: Math.round(data.minBatt) < 360
          }
        : { text: '' };
    }
    if (id === 'data') {
      return meas ? { text: '#' } : { text: (data.data && data.data.length) || 0 };
    }
    if (id === 'signal') {
      if (!meas) {
        const dBms = data.data.map((d) => d.f);
        const minsig = Math.min(...dBms);
        const maxsig = Math.max(...dBms);
        if (!Number.isFinite(minsig) && !Number.isFinite(minsig)) {
          return { text: '' };
        }
        if (minsig === maxsig) {
          return { text: minsig, alert: maxsig === 0 };
        }
        return { text: `${minsig}..${maxsig}`, alert: maxsig === 0 };
      }
      return { text: meas.f, alert: meas.f === 0 };
    }
    return { text: '?' };
  };

  return (
    <TableBody>
      {stableSort(tableData, getComparator(order, orderBy))?.map((row, i) => {
        // {tableData?.map((row, i) => {
        // console.log('ROW ', row);
        // const values = Object.values(row);
        // const cells = values.map((r, index) => {
        const cells = headCells.map((head, index) => {
          const value = getCellValue(head.id, row);
          return (
            <TableCell
              className={`${classes.cell} ${value.alert ? classes.alertCell : ''}`}
              key={`${i}${index}`}
              align='center'
            >
              {value.alert && head.id === 'coveredHours' ? (
                <>
                  <div className={classes.coverBar} style={{ width: `${value.alert}%` }} />
                  <div>{value.text}</div>
                </>
              ) : (
                <>
                  {value.text}
                  {value.obj ? value.obj : null}
                </>
              )}
            </TableCell>
          );
        });
        return (
          <>
            <TableRow
              key={i}
              tabIndex={-1}
              className={
                row.N ? (expandedTS === row.from ? classes.selectedRow : classes.row) : null
              }
              onClick={() => onClickHandler(row.from, row)}
            >
              {cells}
            </TableRow>
            {expandedTS === row.from
              ? row.data
                  .sort((a, b) => (a.b > b.b ? -1 : 1))
                  .map((subrow, j) => (
                    <TableRow key={`${i}${j}`} tabIndex={-1} className={classes.trExtanded}>
                      {headCells.map((head, index) => {
                        const value = getCellValue(head.id, row, subrow, row.data.length - j);
                        return (
                          <TableCell
                            className={`${classes.cell} ${classes.extCell} ${
                              value.alert ? classes.alertCell : ''
                            }`}
                            key={`${i}_${j}_${index}`}
                            align='center'
                          >
                            {value.text}
                            {value.obj ? value.obj : null}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))
              : null}
          </>
        );
      })}
    </TableBody>
  );
};

export const AdminDataTable = ({
  classes,
  order,
  orderBy,
  headCells,
  handleRequestSort,
  data,
  rowsPerPage
}) => (
  <TableContainer className={classes.tableContainer}>
    <Table className={classes.table}>
      <EnhancedTableHead
        classes={classes}
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        rowCount={data?.length}
        headCells={headCells}
      />
      <AdminTableBody
        classes={classes}
        tableData={data}
        order={order}
        orderBy={orderBy}
        rowsPerPage={rowsPerPage}
        headCells={headCells}
      />
    </Table>
  </TableContainer>
);

export default AdminTable;
