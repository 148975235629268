import React from 'react';
import { COLORS } from 'utils/colors';

const NoteTooltip = (props) => {
  const {
    x,
    y,
    datum: { log }
  } = props;
  return (
    <foreignObject height='100%' width='100%' x={x - 70} y={y + 10}>
      <div
        xmlns='http://www.w3.org/1999/xhtml'
        style={{
          width: '120px',
          height: 'auto',
          padding: '8px',
          background: COLORS.darkGray,
          color: COLORS.white,
          borderRadius: '4px',
          fontSize: '12px'
        }}
      >
        <div style={{ margin: '5px 0', fontSize: '10px', fontWeight: 'bold' }}>{log.date}</div>
        <div>{log.note}</div>
      </div>
    </foreignObject>
  );
};

export default NoteTooltip;
