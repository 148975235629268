import { Paper, List, ListSubheader, Divider, Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import warehouseIcon from '@iconify/icons-mdi/warehouse';
import { Icon } from '@iconify/react';

import { i18nVariablesInjection } from 'utils/sharedHelper';
import { celsiusToF } from 'assets/utils';
import { useStyles } from '../Settings.styled';
import ListItemWithText from '../ListItemWithText';
import ListItemWithSlider from '../ListItemWithSlider';
import { useSettingsContext, useSettingSelectionContext } from '../SettingsContext';
import { TEMPERATURE_MARKS } from '../SettingProps';

const Appearance = () => {
  const classes = useStyles();

  const settings = useSettingsContext();

  const { toggleSettings } = useSettingSelectionContext();

  const {
    i18n,
    isAdmin,
    isLoading,
    isPremium,
    insightService,
    isSupervisorCompost,
    degrees,
    sensorsData,
    sensorConfigurations
  } = useSelector((store) => ({
    i18n: store.i18n,
    isAdmin: store.isAdmin,
    isLoading: store.isLoading,
    isPremium: store.isPremium,
    insightService: store.insightService,
    isSupervisorCompost: store.isSupervisorCompost,
    degrees: store.sessionInfo?.ui_preferences?.degrees ?? '°C',
    sensorsData: store.sensorsData,
    sensorConfigurations: store.sensorConfigurations
  }));

  // Only show multipoint handle setting if user has multipoint sensors
  const hasMultipoints =
    sensorsData
      .filter((d) => sensorConfigurations.map((s) => s?.sensor_id_sys).includes(d?.sensor_id))
      .filter((meas) => meas?.temperatures).length > 0;

  const minSlider = degrees === '°C' ? 0 : 20;
  const maxSlider = degrees === '°C' ? 100 : 220;

  const SAFETY_ALERT_DEGREES = 65;

  return (
    <div className={classes.main}>
      <div className={classes.groupContainer}>
        <Paper className={isLoading ? classes.hide : classes.settingsView}>
          <List>
            <ListSubheader component='div' disableSticky>
              {i18n.general_preferences || 'General settings'}
            </ListSubheader>
            <ListItemWithText
              i18n={i18n}
              id='lang'
              data={settings}
              toggleSettings={toggleSettings}
            />

            <ListItemWithText
              i18n={i18n}
              id='d_format'
              data={settings}
              toggleSettings={toggleSettings}
            />

            <ListItemWithText
              i18n={i18n}
              id='t_format'
              data={settings}
              toggleSettings={toggleSettings}
            />

            <ListItemWithText
              i18n={i18n}
              id='degrees'
              data={settings}
              toggleSettings={toggleSettings}
            />
          </List>
        </Paper>
      </div>
      {insightService && (
        <div className={classes.groupContainer}>
          <Paper className={isLoading ? classes.hide : classes.settingsView}>
            <List>
              <ListSubheader component='div' disableSticky>
                {i18n.general_alert_settings}
              </ListSubheader>
              <div className={classes.alertsDescDiv}>
                <Icon icon={warehouseIcon} className={classes.alertsIcon} />
                <Typography variant='body1' className={classes.alertsDesc}>
                  {i18n.general_alert_desc}
                </Typography>
              </div>
              <ListItemWithText
                highlight={settings.safety_alert?.value === 'false'}
                i18n={i18n}
                id='safety_alert'
                data={settings}
                toggleSettings={toggleSettings}
              />
              <Typography variant='body1' className={classes.safetySettingDesc}>
                {i18nVariablesInjection(i18n.safety_alert_desc, {
                  limit: degrees !== '°C' ? celsiusToF(SAFETY_ALERT_DEGREES) : SAFETY_ALERT_DEGREES,
                  degrees
                })}
              </Typography>
            </List>
          </Paper>
        </div>
      )}

      {(isPremium || insightService) && (
        <div className={classes.groupContainer}>
          <Paper className={isLoading ? classes.hide : classes.settingsView}>
            <List>
              <ListSubheader component='div' disableSticky>
                {i18n.general_display_preferences || 'General display settings'}
              </ListSubheader>
              <ListItemWithSlider
                i18n={i18n}
                degrees={degrees}
                id='def_color'
                data={settings}
                marks={TEMPERATURE_MARKS(minSlider, maxSlider, degrees)}
                toggleSettings={toggleSettings}
              />
              <ListItemWithSlider
                i18n={i18n}
                degrees={degrees}
                id='def_chart'
                data={settings}
                marks={TEMPERATURE_MARKS(minSlider, maxSlider, degrees)}
                toggleSettings={toggleSettings}
              />
              {(isAdmin || !isSupervisorCompost) && hasMultipoints && (
                <ListItemWithText
                  i18n={i18n}
                  id='handle_temp'
                  data={settings}
                  toggleSettings={toggleSettings}
                />
              )}
            </List>
          </Paper>
        </div>
      )}
      {isSupervisorCompost && (
        <div className={classes.groupContainer}>
          <Paper className={isLoading ? classes.hide : classes.settingsView}>
            <List>
              <ListSubheader component='div' disableSticky>
                {i18n.batch_preferences || 'Default batch settings'}
              </ListSubheader>
              <ListItemWithSlider
                i18n={i18n}
                degrees={degrees}
                id='temp_alert'
                data={settings}
                marks={TEMPERATURE_MARKS(minSlider, maxSlider, degrees)}
                toggleSettings={toggleSettings}
              />
              <Divider />
              <ListSubheader component='div' disableSticky>
                {i18n.report_content}
              </ListSubheader>

              <ListItemWithText
                i18n={i18n}
                id='incl_exc'
                data={settings}
                toggleSettings={toggleSettings}
              />
              <ListItemWithText
                i18n={i18n}
                id='incl_sens_data'
                data={settings}
                toggleSettings={toggleSettings}
              />
              {settings?.incl_sens_data?.value === 'true' ? (
                <ListItemWithText
                  i18n={i18n}
                  id='meas_per_day'
                  data={settings}
                  toggleSettings={toggleSettings}
                />
              ) : null}
            </List>
          </Paper>
        </div>
      )}
    </div>
  );
};

export default Appearance;
