import * as THREE from 'three';
import { differenceInDays, differenceInHours } from 'date-fns';
import {
  isQualityWarning,
  isQualityAlert,
  getGradientColor,
  isNil,
  hexToRgb,
  addIntensity
} from 'assets/utils';
import * as storageConstants from '../storage-utils/storageConstants';

export const get3DStorageColumns = (selectedStorage, stackIndex) =>
  selectedStorage?.stacks
    ? selectedStorage.stacks[stackIndex || 0].nColumns
    : storageConstants.DEFAULT_COLUMNS;

export const get3DStorageRows = (selectedStorage, stackIndex) =>
  selectedStorage?.stacks
    ? selectedStorage.stacks[stackIndex || 0].nRows
    : storageConstants.DEFAULT_ROWS;

export const getCameraHeight = (selectedStorage) =>
  Math.max(10, get3DStorageColumns(selectedStorage));

export const getCameraDist = (selectedStorage) =>
  Math.max(10, get3DStorageColumns(selectedStorage));

// For indicating bale stack
export const getBalesFootprint = (bales) =>
  bales
    .filter((bale) => bale.f === 0)
    .map((bale) => {
      const loc = { col: bale.c, row: bale.r };
      return loc;
    });

// For indicating selected rows and cols
export const getSelectionFootprint = (selectedStorage, rowSelection, columnSelection) => {
  // For each row: get {row, col } all columns
  // For each column: get {row, col } all rows
  const nCols = get3DStorageColumns(selectedStorage);
  const nRows = get3DStorageRows(selectedStorage);
  const allColumns = Array.from(Array(nCols).keys());
  const allRows = Array.from(Array(nRows).keys());
  const colsOfRows = rowSelection
    .map((r) =>
      allColumns.map((c) => {
        const cell = { col: c, row: r };
        return cell;
      })
    )
    .flat();
  const rowsOfcol = columnSelection
    .map((c) =>
      allRows.map((r) => {
        const cell = { col: c, row: r };
        return cell;
      })
    )
    .flat();
  return colsOfRows.concat(rowsOfcol);
};

// eslint-disable-next-line no-unused-vars
export const getBalesPosition = (selectedStorage, stack, stackLayoutString) => [
  -get3DStorageColumns(selectedStorage) / 2 + 0.5,
  0.5,
  -get3DStorageRows(selectedStorage) / 2 + 0.5
];

// eslint-disable-next-line no-unused-vars
export const getCellsPosition = (selectedStorage, stack, stackLayoutString) => [
  -get3DStorageColumns(selectedStorage) / 2 + 0.5,
  0,
  -get3DStorageRows(selectedStorage) / 2 + 0.5
];

// eslint-disable-next-line no-unused-vars
export const getStackPosition = (selectedStorage, stack, stackLayoutString) => [
  -get3DStorageColumns(selectedStorage) / 2,
  0,
  get3DStorageRows(selectedStorage) / 2
];

export const getProbeAppearance = (probe, data, viewMode, qualityData) => {
  if (qualityData) {
    const probeQuality = qualityData.find((d) => d?.ID === probe.id);
    const quality = probeQuality && !isNil(probeQuality.quality) ? probeQuality.quality : null;
    // Add intensity to gradientColor
    const qRGB = !isNil(quality)
      ? addIntensity(
          hexToRgb(probeQuality.color3D || probeQuality.color),
          // hexToRgb('#aa7712'),
          probeQuality.intensity || 1.5
        )
      : hexToRgb('#aaaaaa');
    return {
      id: probe.id,
      tempColor: new THREE.Color(qRGB),
      size: probeQuality?.size || 0.2,
      edges: probeQuality?.bars < 4,
      valueAlert: isQualityAlert(probeQuality),
      valueWarning: isQualityWarning(probeQuality)
    };
  }

  const probeData = data.find((d) => d?.sensor_id === probe.id);

  const temp = probeData && !isNil(probeData.temperature) ? probeData.temperature : null;
  const signalSensor =
    probeData && !isNil(probeData.sensor_RSSI) ? probeData.sensor_RSSI + 100 : null;
  const signalBS = probeData && !isNil(probeData.bs_RSSI) ? probeData.bs_RSSI + 100 : null;
  const batt = probeData && !isNil(probeData.batt_voltage) ? probeData.batt_voltage : null;
  const measTs = probeData && !isNil(probeData.meas_ts) ? probeData.meas_ts : null;

  let colorValue = temp;
  let sizeValue = temp;
  let useDefaultColors = false;
  if (viewMode === storageConstants.MODE_SIGNAL_SENSOR) {
    colorValue = 67 - signalSensor;
    sizeValue = 40 - signalSensor;
    useDefaultColors = true;
  }
  if (viewMode === storageConstants.MODE_SIGNAL_BS) {
    colorValue = 67 - signalBS;
    sizeValue = 40 - signalBS;
    useDefaultColors = true;
  }
  if (viewMode === storageConstants.MODE_BATT) {
    colorValue = Math.min(90, 395 - batt);
    sizeValue = Math.min(70, 390 - batt);
    useDefaultColors = true;
  }
  if (viewMode === storageConstants.MODE_MEAS_AGE) {
    const now = new Date();
    const sensorTs = new Date(measTs * 1000);
    const hours = differenceInHours(now, sensorTs);
    const days = differenceInDays(now, sensorTs);
    useDefaultColors = true;
    if (hours <= 24) {
      colorValue = Math.max(10, Math.min(60, 25 + hours * hours));
      sizeValue = Math.min(40, 25 + hours * hours);
    } else {
      console.log(probe.id, days);
      colorValue = Math.min(80, 50 + days * days);
      sizeValue = Math.min(60, 50 + days);
    }
  }

  // Add intensity to gradientColor
  const rgbColor = !isNil(temp)
    ? addIntensity(getGradientColor(colorValue, false, useDefaultColors), 1.5)
    : hexToRgb('#aaaaaa');
  return {
    id: probe.id,
    tempColor: new THREE.Color(rgbColor),
    size: !isNil(temp) ? Math.max(0.25, sizeValue / 100) : 0.2
  };
};
