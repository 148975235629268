import { includes } from 'lodash';
import { getAnonymousTranslations, getUserTranslations, getAdminTranslations } from 'api/serverAPI';
import { I18N_SET_LANGUAGE, SET_SUPPORTED_LANGUAGES } from 'redux/constants';
import initialState from 'redux/store/initialState';

export default function setLanguage(state = initialState.i18n, action) {
  switch (action.type) {
    case I18N_SET_LANGUAGE: {
      return action.value;
    }
    default: {
      return state;
    }
  }
}

export function setSupportedLanguages(state = initialState.supportedLanguages, action) {
  switch (action.type) {
    case SET_SUPPORTED_LANGUAGES: {
      return action.value;
    }
    default: {
      return state;
    }
  }
}

export const getTranslationsData = (locale, UID, auth) => async (dispatch) => {
  const getTranslationData = async () => {
    try {
      let response;
      if (includes(['anonymous', 'insightSurvey', undefined, null], auth)) {
        console.log('getAnonymousTranslations');
        response = await getAnonymousTranslations({ locale, UID, auth });
      } else if (auth === true) {
        console.log('getAdminTranslations');
        response = await getAdminTranslations({ locale, UID, auth });
      } else {
        console.log('getUserTranslations');
        response = await getUserTranslations({ locale, UID, auth });
      }

      if (response.status_code !== 0) {
        alert(
          'Their was a problem fetching the language files. Please contact support@quanturi.com if the problem persists.'
        );
      }

      const data = await response.results;

      const object = {};

      for (let i = 0; i < data.length; i += 1) {
        object[data[i].key] = data[i].text;
      }

      return { results: object, supported_languages: response.supported_languages };
    } catch (e) {
      console.log(`getTranslationData error: ${e.message}`);
      return null;
    }
  };

  try {
    const translationData = await getTranslationData();
    if (translationData?.results !== null) {
      await dispatch({ type: 'I18N_SET_LANGUAGE', value: translationData.results });
      await dispatch({
        type: 'SET_SUPPORTED_LANGUAGES',
        value: translationData.supported_languages
      });
    } else {
      return null;
    }
  } catch (e) {
    console.log('err:', e);
  }
};
