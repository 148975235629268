import { ListItem, Tooltip } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';

import { Icon } from '@iconify/react';
import nail from '@iconify/icons-mdi/nail';
import { isEmpty } from 'lodash';
import { isNil, isSMSMessagingOff } from 'assets/utils';
import { listItemStyle } from 'utils/sharedStyles';
import StorageListItemTitle from './StorageListItemTitle';
import StorageListItemStatusBar from './StorageListItemStatusBar';
import StorageListItemFCStatusBar from './StorageListItemFCStatusBar';
import TemperatureTrendChartlet from '../../general/trend-chartlet/TemperatureTrendChartlet';
import { getRangeFromData } from '../storage-utils/storageHelper';
import { controllerRule, isAlertRule } from '../monitors/monitor-utils/fcHelpers';

const StorageListItem = ({ item, disabled }) => {
  const classes = listItemStyle();

  const { i18n, minmaxData, auxConf, chartScale, FcStatus, sensorsData } = useSelector((store) => ({
    i18n: store.i18n,
    minmaxData: store.minmaxData,
    auxConf: store.auxConf,
    sensorsData: store.sensorsData,
    FcStatus: store.FcStatus,
    chartScale:
      store.sessionInfo && store.sessionInfo.ui_preferences
        ? store.sessionInfo.ui_preferences.chart_scale
        : null
  }));

  const sensorIDsPlaced =
    item?.layoutType === 3
      ? item?.stacks
          .map((s) => s.sensors)
          .flat()
          .map((s) => s.id)
      : item?.sensor_layout
      ? item.sensor_layout?.sensors?.filter((sen) => sen.column >= 0).map((sen) => sen.id) || []
      : [];

  // Get the current min&max temps of the storage
  // Consider only temps that are newer than 24 hours; older do not reflect the current temperature.
  // const tsMin =
  //   moment()
  //     .subtract(24, 'hours')
  //     .valueOf() / 1000;
  let storageData = sensorsData?.filter((data) => sensorIDsPlaced.indexOf(data?.sensor_id) >= 0);
  if (auxConf) {
    const auxData = auxConf.filter((aux) => sensorIDsPlaced.indexOf(aux.a) >= 0);
    storageData = storageData.concat(auxData);
  }

  // .filter(data => data.meas_ts > tsMin);
  // Flat array of all current temperatures
  // For multipoint: "temperatures" array (without 1st value, which is handle temp)
  // For singepoints: "temperature" value
  const flatTemps = storageData
    .filter((d) => d.temperatures)
    .map((d) => d.temperatures.slice(1, d.temperatures.length)) // ignore 1st
    .flat(1)
    .concat(storageData.filter((d) => !d.temperatures).map((d) => d.temperature))
    .filter((t) => !isNil(t));

  const maxTemp = Math.max(...flatTemps);
  const minTemp = Math.min(...flatTemps);
  let sensorCount = null;
  if (!item.data) {
    sensorCount = (
      <div
        style={{ paddingLeft: 100, width: 'auto' }} // fine tuning no sensors indicator
        className={[classes.statusText, classes.sensorCount].join(' ')}
      >
        {disabled ? (
          <div style={{ display: 'flex', height: 30 }}>
            <Icon className={classes.noSensorsIcon} icon={nail} />
            <div className={classes.noSensors}>0</div>
          </div>
        ) : (
          <Tooltip
            title={<span style={{ whiteSpace: 'pre-line' }}>{i18n.no_probes || 'No Probes'}</span>}
            placement='left'
            disableFocusListener
          >
            <div style={{ display: 'flex', height: 30 }}>
              <Icon className={classes.noSensorsIcon} icon={nail} />
              <div className={classes.noSensors}>0</div>
            </div>
          </Tooltip>
        )}
      </div>
    );
  }

  const storageMinmaxData =
    minmaxData?.filter((data) => sensorIDsPlaced.indexOf(data.a) >= 0) || [];

  const [scaleMin, scaleMax] = getRangeFromData(sensorIDsPlaced, storageMinmaxData, chartScale);

  // FC Logic
  const FcInStorage = item.monitor?.rules.find((rule) => controllerRule(rule));
  const outdoorID =
    FcInStorage &&
    item.monitor?.rules?.find((rule) => rule.type === 'COOLDOWN_CONTROL')?.settings?.outdoorID;

  const itemFcStatus = FcStatus?.find((FC) => FC.FC_ID === FcInStorage?.settings.FC_ID);

  const currentOutdoorTemp =
    !!FcInStorage && sensorsData?.find((s) => s.sensor_id === outdoorID)?.temperature;

  const alertRulesInStorage = item?.monitor?.rules.find((rule) => isAlertRule(rule));
  // console.log(currentOutdoorTemp, outdoorID, sensorsData);

  return (
    // <Link className={classes.noLink} to={`/storages/${item.itemID}`}>
    <ListItem
      button={!disabled}
      className={classes.Item}
      // onClick={() => {
      //   if (handleClick) {
      //     handleClick(item.itemID);
      //   }
      // }}
      id={`storage-${item.itemID}`}
    >
      <div className={disabled ? classes.ListItemDisabled : classes.ListItem}>
        {/* {alertIndicator} */}
        <StorageListItemTitle
          // alerted='false'
          phaseCount='1'
          storageName={item.name}
          status='1'
          isAdmin='false'
          i18n={i18n}
          messagingOff={
            !item.monitor ||
            isEmpty(sensorIDsPlaced) ||
            (item.monitor && (!alertRulesInStorage || isSMSMessagingOff(item)))
          }
        />
        <div className={classes.itemContent}>
          {/* BATCH RELATED */}
          {/* <div className={[classes.itemContentSection, classes.phaseInfo, classes.storageBatch].join(' ')}>
            <div className={classes.phaseName}>
              <MdLandscape />
              {item.batch?.text}
            </div>
          </div> */}
          {!isEmpty(sensorIDsPlaced) && (
            <div className={classes.itemStatus}>
              <StorageListItemStatusBar
                minTemp={minTemp}
                maxTemp={maxTemp}
                monitor={item.monitor}
                probeIDs={sensorIDsPlaced}

                // isFuturePhase={undefined}
                // status={1}
                // item={item}
              />
            </div>
          )}
          {!!FcInStorage && (
            <div className={classes.itemStatus}>
              <StorageListItemFCStatusBar
                outdoorTemp={currentOutdoorTemp}
                minTemp={minTemp}
                maxTemp={maxTemp}
                monitor={item.monitor}
                itemFcStatus={itemFcStatus}

                // isFuturePhase={undefined}
                // status={1}
                // item={item}
              />
            </div>
          )}
          <div className={classes.itemContentSection}>
            <div
              className={
                isEmpty(sensorIDsPlaced)
                  ? [classes.tempsPanel, classes.tempsWithSensor, classes.noSensors].join(' ')
                  : classes.storageChartPanel
              }
            >
              {!isEmpty(sensorIDsPlaced) ? (
                <TemperatureTrendChartlet
                  itemID={item.itemID}
                  nDays={7}
                  minmaxData={storageMinmaxData}
                  sensorIDs={sensorIDsPlaced}
                  isAggregate
                  scaleMax={scaleMax}
                  scaleMin={scaleMin}
                  chartHeight={80}
                />
              ) : (
                sensorCount
              )}
            </div>
          </div>
          <div className={classes.stretchSection} />
        </div>
      </div>
    </ListItem>
    // </Link>
  );
};

export default StorageListItem;
