import {
  Dialog,
  DialogContent,
  TextField,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tooltip,
  IconButton
} from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import { getTranslationsData } from 'redux/reducers/setLanguage';
import DialogTitleWithClose from 'utils/DialogWithCloseIcon';
import { updateTranslationFile } from 'api/serverAPI';
import { DialogActionUtil } from 'utils/DialogActions';

const useStyles = makeStyles(() => ({
  textFieldShort: {
    margin: 10
  },
  additionalComponent: {
    display: 'flex',
    margin: 10,
    justifyContent: 'space-between'
  },
  languageInfo: {
    display: 'flex'
  }
}));

export const TranslationsDetails = ({
  detailsData,
  locale,
  // i18n,
  openedDialog,
  setOpenedDialog,
  setUpdateNeeded,
  categories,
  rootAdmin,
  newTranslation,
  setNewTranslation
}) => {
  const [translationToUpdate, setTranslationToUpdate] = useState(detailsData);
  // const dispatch = useDispatch();
  const classes = useStyles();

  const [allowKeyEdit, setAllowKeyEdit] = useState(newTranslation);

  const onCancel = () => {
    setOpenedDialog(false);
    setAllowKeyEdit(false);
    setNewTranslation(false);
  };

  const changeHandler = (e) => {
    setTranslationToUpdate({
      ...translationToUpdate,
      [e.target.id || e.target.name]: e.target.value
    });
  };

  const onSaveTranslationHandler = async () => {
    try {
      const res = await updateTranslationFile({
        locale,
        data: translationToUpdate,
        keyChange: allowKeyEdit
      });
      if (res.status_code === 0) {
        setTranslationToUpdate(null);
        setUpdateNeeded(true);
        setAllowKeyEdit(false);
        setOpenedDialog(false);
        // if (locale === i18n.locale) await dispatch(getTranslationsData(i18n.locale)); --> For now a refresh will suffice to see the changes in the app, this was resulting in a memory leak
        // console.log('getTranslationsData at: TranslationsDetails');
      } else {
        alert('A problem came up');
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Dialog open={openedDialog} fullWidth maxWidth='lg'>
      <DialogTitleWithClose onClose={() => [setOpenedDialog(false), setAllowKeyEdit(false)]}>
        Update or create a translation
      </DialogTitleWithClose>
      <DialogContent elevation={0}>
        {rootAdmin && (
          <FormControlLabel
            control={
              <Switch
                checked={allowKeyEdit}
                onChange={() => setAllowKeyEdit(!allowKeyEdit)}
                name='Allow Changing all keys'
              />
            }
            label='Allow Changing all keys'
          />
        )}

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {rootAdmin && (
            <TextField
              className={classes.textFieldShort}
              id='id'
              label='Position'
              type='text'
              disabled
              value={translationToUpdate?.id}
              // onChange={(e) => changeHandler(e)}
              variant='outlined'
              size='small'
            />
          )}

          <TextField
            className={classes.textFieldShort}
            id='key'
            label='Key'
            type='text'
            value={translationToUpdate?.key}
            onChange={(e) => changeHandler(e)}
            variant='outlined'
            size='small'
            disabled={!allowKeyEdit}
          />
          <TextField
            className={classes.textFieldShort}
            id='text'
            label='Value'
            type='textarea'
            multiline
            value={translationToUpdate?.text}
            onChange={(e) => changeHandler(e)}
            variant='outlined'
            size='small'
          />
          <FormControl style={{ minWidth: 175 }}>
            <InputLabel>Category</InputLabel>
            <Select
              className={classes.textFieldShort}
              name='category'
              label='category'
              value={translationToUpdate?.category ?? ''}
              onChange={(e) => changeHandler(e)}
              // variant='outlined'
              size='small'
              disabled={!rootAdmin}
            >
              {categories.map((gr) => (
                <MenuItem key={gr} value={gr}>
                  {gr}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <DialogActionUtil
          onCancel={() => onCancel()}
          onSave={() => onSaveTranslationHandler()}
          text='Save'
        />
      </DialogContent>
    </Dialog>
  );
};

export const TopBarTranslations = ({
  locale,
  setLocale,
  categoryOptions,
  selectedCategory,
  categorySelectionChange,
  dataAPI,
  setDetailsData,
  onOpenDialog,
  setUpdateNeeded,
  specialTranslator,
  translatorMode,
  setTranslatorMode,
  i18nLocale,
  rootAdmin
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const supportedLanguages = useSelector((state) => state.supportedLanguages);
  const publishedLanguage = supportedLanguages.find((lan) => lan.locale === locale);

  const addTranslationItem = () => {
    setDetailsData({ id: dataAPI.length, key: '', text: '', category: 'App General' });
    onOpenDialog(1);
  };

  const onLocaleChangeHandler = (e) => {
    setLocale(e.target.value);
    setUpdateNeeded(true);
    categorySelectionChange(0);
  };

  const filteredLanguages = specialTranslator
    ? supportedLanguages.filter((lang) => specialTranslator.find((s) => s === lang.locale))
    : supportedLanguages;

  const translatorModeHandler = () => {
    const getTranslations = async () => {
      await dispatch(getTranslationsData(translatorMode ? 'en' : locale, null, true));
      setTranslatorMode(!translatorMode);
    };
    getTranslations();
  };

  return (
    <div className={classes.additionalComponent}>
      <div className={classes.languageInfo}>
        <FormControl style={{ minWidth: 125, margin: '0px 5px' }}>
          <InputLabel style={{ top: -25, fontSize: 12 }} disableAnimation shrink={false}>
            Choose the language
          </InputLabel>

          <Select value={locale} onChange={onLocaleChangeHandler}>
            {filteredLanguages.map((lang) => (
              <MenuItem key={lang.locale} value={lang.locale}>
                {lang.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl style={{ minWidth: 125, margin: '0px 5px' }}>
          <InputLabel style={{ top: -25, fontSize: 12 }} disableAnimation shrink={false}>
            Filter by category
          </InputLabel>
          <Select
            style={{ minWidth: 180 }}
            value={selectedCategory.value}
            onChange={(e) => categorySelectionChange(e.target.value)}
          >
            {categoryOptions &&
              categoryOptions.map((cat, i) => (
                <MenuItem
                  key={i}
                  style={{
                    color: 'inherit',
                    borderBottom: 'none'
                  }}
                  value={cat.value}
                >
                  {cat.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControlLabel
          control={<Switch checked={publishedLanguage.published} name='Published' disabled />}
          style={{ margin: '0px 5px' }}
          label='Published'
        />
        <FormControlLabel
          control={<Switch checked={translatorMode} onClick={translatorModeHandler} />}
          style={{ margin: '0px 5px' }}
          label={translatorMode ? `Translator mode (${i18nLocale})` : 'Translator mode off'}
        />
      </div>

      {rootAdmin && (
        <Tooltip title='Add translation (globally)' arrow placement='left' disableFocusListener>
          <IconButton color='secondary' onClick={() => addTranslationItem()}>
            <AddCircleIcon style={{ fontSize: 40 }} />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};
