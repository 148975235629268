import { Snackbar } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { Alert } from '@material-ui/lab';
import { isEmpty } from 'lodash';
import { Spinner } from 'utils/Spinners';
import { getSensorType, createDefaultStack } from 'assets/utils';
import SelectedStorageDetails from './storage-details/SelectedStorageDetails';
import SelectedStorageToolbar from './storage-toolbar/SelectedStorageToolbar';
import StorageCreate from './storage-create/StorageCreate';
import * as storageConstants from './storage-utils/storageConstants';

const StorageSelected = ({
  storages,
  storagesChanges,
  setStoragesChanges,
  monitors,
  setMonitors,
  createMonitor,
  setCreateMonitor,
  setMonitorsChanges,
  selectedMonitor,
  setSelectedMonitor,
  waitForServer,
  setWaitForServer,
  selectedStorageUpdates,
  setSelectedStorageUpdates,
  qualityMode,
  editMode
}) => {
  const { itemID } = useParams();
  const dispatch = useDispatch();

  const [selectedStorage, setSelectedStorage] = useState(null);
  const [isStorageEdited, setIsStorageEdited] = useState(false);
  const i18n = useSelector((store) => store.i18n);
  const feedQualityLive = useSelector((store) => store.sessionInfo?.feedQualityLive);
  const insightService = useSelector((store) => store.insightService);
  const surveyAnswers = useSelector((store) => store.surveyAnswers);
  const isAdmin = useSelector((store) => store.isAdmin);
  const isDemoMode = useSelector((store) => store.isDemoMode);
  const sensorTypes = useSelector((store) =>
    store.sensorConfigurations.map((s) => getSensorType(s.sensor_id_sys, s.multipoint))
  );

  useEffect(() => {
    if (itemID && (!selectedStorage || selectedStorageUpdates)) {
      setSelectedStorage(storages?.find((s) => +s.itemID === +itemID));
      setSelectedStorageUpdates(false);
    }
    // we reuse redundant groupDetails redux field for "storage details open" state
    dispatch({ type: 'SET_GROUPDETAILS', value: itemID });
  }, [
    itemID,
    selectedStorage,
    selectedStorageUpdates,
    setSelectedStorageUpdates,
    storages,
    dispatch
  ]);

  const [isMissingInfoOpen, setIsMissingInfoOpen] = useState();

  useEffect(() => {
    const checkMissingInfo = () => {
      let missingInfo = !selectedStorage.capacity || !selectedStorage.type || !selectedStorage.name;

      if (selectedStorage.undefinedLayoutType) {
        const showTypeOptions =
          !isEmpty(sensorTypes.filter((s) => s !== 'H')) &&
          sensorTypes.filter((s) => s === 'H').length > 5;

        const typeSelection = isEmpty(sensorTypes)
          ? surveyAnswers?.hayType === 'bales'
            ? 'stack'
            : 'flat'
          : !isEmpty(sensorTypes.filter((s) => s !== 'H'))
          ? 'flat'
          : 'stack';

        if (showTypeOptions) {
          missingInfo = true;
        } else if (!missingInfo && typeSelection === 'stack' && !selectedStorage.stacks) {
          setSelectedStorage((prevStorage) => {
            const newData = { ...prevStorage };
            newData.layoutType = 3;
            // Create initial empty stack for stack storage
            newData.stacks = [
              createDefaultStack(
                storageConstants.DEFAULT_COLUMNS,
                storageConstants.DEFAULT_ROWS,
                storageConstants.DEFAULT_FLOORS
              )
            ];

            return newData;
          });
        }
      }
      setIsMissingInfoOpen(missingInfo);
    };

    if (!storagesChanges && selectedStorage && isMissingInfoOpen !== false) {
      checkMissingInfo();
    }
    return () => {};
  }, [isMissingInfoOpen, selectedStorage, sensorTypes, storagesChanges, surveyAnswers?.hayType]);

  if (!Number.isFinite(+itemID)) {
    return null;
  }

  if (Number.isFinite(+itemID)) {
    if (
      !selectedStorage &&
      storages &&
      !waitForServer &&
      !storages.find((storage) => +storage.itemID === +itemID)
    ) {
      return (
        <Snackbar open anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert severity='error'>
            {i18n.storage_not_found || 'The storage you are trying to reach does not exist'}
          </Alert>
        </Snackbar>
      );
    }
    if (!selectedStorage) {
      return <Spinner />;
    }
  }

  return (
    <>
      <SelectedStorageToolbar
        selectedStorage={selectedStorage}
        setSelectedStorage={setSelectedStorage}
        setStoragesChanges={setStoragesChanges}
        isStorageEdited={isStorageEdited}
        setIsStorageEdited={setIsStorageEdited}
      />
      <SelectedStorageDetails
        selectedStorage={selectedStorage}
        setSelectedStorage={setSelectedStorage}
        setIsStorageEdited={setIsStorageEdited}
        setStoragesChanges={setStoragesChanges}
        listMonitors={monitors}
        setMonitors={setMonitors}
        createMonitor={createMonitor}
        setCreateMonitor={setCreateMonitor}
        setMonitorsChanges={setMonitorsChanges}
        selectedMonitor={selectedMonitor}
        setSelectedMonitor={setSelectedMonitor}
        storages={storages}
        qualityMode={qualityMode}
        editMode={editMode}
        feedQualityLive={feedQualityLive}
      />
      {!isAdmin &&
        insightService &&
        !isDemoMode &&
        isMissingInfoOpen &&
        ReactDOM.createPortal(
          <StorageCreate
            storages={storages}
            setStoragesChanges={setStoragesChanges}
            setWaitForServer={setWaitForServer}
            selectedStorage={selectedStorage}
            setSelectedStorage={setSelectedStorage}
            isMissingInfoOpen={isMissingInfoOpen}
            setIsMissingInfoOpen={setIsMissingInfoOpen}
            setIsStorageEdited={setIsStorageEdited}
            isDemoMode={isDemoMode}
          />,
          document.getElementById('dialog-root')
        )}
    </>
  );
};

export default StorageSelected;
