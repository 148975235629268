import './App.css'; // FOR THE FONTS
import './nouislider.css';
import React from 'react';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ConfirmProvider } from 'hook/useConfirm';
import AppRoutes from 'navigation/AppRoutes';
import { COLORS } from 'utils/colors';
import { store } from './redux/store/configureStore';

const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.primaryColor
    },
    secondary: {
      main: COLORS.secondaryColor
    }
  },
  typography: {
    fontFamily: "'Roboto', sans-serif",
    useNextVariants: true
  },
  overrides: {
    MuiDialog: {
      paper: {
        margin: 0,
        overflowY: 'visible'
      }
    },
    MuiIconButton: {
      root: {
        '&$disabled': {
          color: 'rgba(255, 255, 255, 0.15)'
        }
      }
    },
    MuiCircularProgress: {
      circleDisableShrink: {
        strokeDasharray: '40px, 200px',
        strokeLinecap: 'round'
      }
    },
    MUIDataTableSelectCell: {
      checked: { color: `${COLORS.secondaryColor} !important` }
    },
    MuiTableCell: {
      root: {
        padding: '2px 0 0 20px'
      }
    },
    MuiTablePagination: {
      actions: {
        marginLeft: 5
      },
      root: {
        '&:last-child': { border: 'none' }
      }
    },
    MuiSlider: {
      root: {
        width: '90%'
      }
    },
    MUIDataTableToolbar: {
      icon: {
        '&:hover': {
          color: COLORS.secondaryColor
        }
      },
      iconActive: {
        color: COLORS.secondaryColor
      }
    }
  }
});

const App = () => (
  <ThemeProvider theme={theme}>
    <ConfirmProvider>
      <Provider store={store}>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </Provider>
    </ConfirmProvider>
  </ThemeProvider>
);

export default App;
