import { Tooltip } from '@material-ui/core';
import React from 'react';

import warehouseIcon from '@iconify/icons-mdi/warehouse';
import deltaIcon from '@iconify/icons-mdi/delta';
import checkCircle from '@iconify/icons-mdi/check-circle';

import { Icon } from '@iconify/react';
import { useSelector } from 'react-redux';
import { getGradientColor, tempFormat, tempFormatDelta, isNil } from 'assets/utils';
import { COLORS } from 'utils/colors';

const FcIndicators = ({ classes, FcState, FcSettings, delta }) => {
  const degrees = useSelector((store) => store.sessionInfo?.ui_preferences?.degrees);
  const i18n = useSelector((store) => store.i18n);
  const temp = delta ? FcState?.delta : FcState?.refTemp;
  const noTemp = isNil(temp) || temp === -Infinity || temp === Infinity || Number.isNaN(temp);
  // const noMaxTemp = isNil(maxTemp) || maxTemp === -Infinity || maxTemp === Infinity;

  const icon = delta ? deltaIcon : +FcState?.zone === -1 ? checkCircle : warehouseIcon;
  // const maxTempColor = noMaxTemp
  //   ? COLORS.warning
  //   : `rgba(${
  //       getGradientColor(maxTemp)
  //         .split('(')[1]
  //         .split(')')[0]
  //     },1)`;

  // const TempOpacity = 1; // isNil(minTS) || noTemp ? 1 : getOpacity(minTS * 1000);
  // const maxTempOpacity = isNil(maxTS) || noMaxTemp ? 1 : getOpacity(maxTS * 1000);
  const backgroundColor = noTemp
    ? COLORS.warning
    : !delta
    ? +FcState?.zone === -1
      ? COLORS.secondaryColor
      : `rgba(${getGradientColor(temp).split('(')[1].split(')')[0]},1)`
    : FcState?.zone !== 1
    ? COLORS.greenForest
    : COLORS.alert;

  const TempStyle = { opacity: 1, backgroundColor };
  // if only 1 sensor or no data, show only current max temp (as it is same as min temp)
  // phase?.sensor_progress?.length === 1 || noTemp
  //   ? { opacity: 0 }
  //   : { opacity: TempOpacity, backgroundColor: TempColor };

  // const maxTempStyle = { opacity: maxTempOpacity, backgroundColor: maxTempColor };

  const getRefText = (settings, isDelta, temperature, exception, note) => {
    if (isDelta) {
      if (exception) {
        // 1,2: missing or outdated outdoor temp
        if (exception === 1 || exception === 2) {
          return `${i18n.rc_exception_1}`;
        }
        return `${note}`;
      }
      return `${i18n.delta_text}`;
    }
    if (!settings) {
      return '';
    }
    if (exception) {
      // 1,2: missing or outdated outdoor temp
      // 3,4,5: missing or outdated temp
      if (exception === 1 || exception === 2) {
        return `${i18n.rc_exception_1}`;
      }
      if (exception === 3 || exception === 4 || exception === 5) {
        return `${i18n.rc_exception_3}`;
      }
      return `${note}`;
    }
    if (isNil(temperature)) {
      return '';
    }
    if (settings.tempRef === 'MAX_DEPTH') {
      return `${i18n.MAX_DEPTH} ${settings.refDepth}m`;
    }
    if (settings.tempRef === 'AVG_DEPTH') {
      return `${i18n.AVERAGE_DEPTH} ${settings.refDepth}m`;
    }
    if (settings.tempRef === 'MAX') {
      return `${i18n.MAX_TEMP}`;
    }
    if (settings.tempRef === 'AVG') {
      return `${i18n.AVERAGE_TEMP}`;
    }
  };

  return (
    <div
      className={[classes.tempsPanel, classes.statusFcPanel].join(' ')}
      style={delta ? { minWidth: 60 } : {}}
    >
      <div className={classes.tempValues}>
        <div
          style={delta ? { ...TempStyle, ...{ justifyContent: 'space-evenly' } } : TempStyle}
          className={classes.tempIndicator}
        >
          {delta ? (
            <div className={classes.deltaTemp}>Δ</div>
          ) : (
            <Icon className={classes.tempIconMin} icon={icon} />
          )}
          <Tooltip
            title={
              <div>{getRefText(FcSettings, delta, temp, FcState?.exception, FcState?.note)}</div>
            }
            placement='bottom'
            disableFocusListener
          >
            <div className={classes.tempFigure} style={delta ? { minWidth: 'auto' } : {}}>
              {noTemp
                ? '- - -'
                : delta
                ? tempFormatDelta(degrees, +temp, 0)
                : tempFormat(degrees, +temp, 0)}
              {!delta && ` ${degrees}`}
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default FcIndicators;
