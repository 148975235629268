import { useState, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { generateSensorList } from './helper';
import { getSensorDetails } from './sensor-detail/helper';

const initialState = {
  sensorList: null,
  sortID: 'temperature',
  sortDirection: 'asc'
};

const useFetchSensorList = (sensors) => {
  const dispatch = useDispatch();
  const { sensorsData, sensorConfigurations, auxConf } = useSelector((store) => ({
    sensorsData: store.sensorsData,
    sensorConfigurations: store.sensorConfigurations,
    auxConf: store.auxConf
  }));

  const sensorConfigurationsMemo = useMemo(() => sensorConfigurations, [sensorConfigurations]);
  const sensorsDataMemo = useMemo(() => sensorsData, [sensorsData]);
  const [state, setState] = useState(initialState);

  const getSensorList = useCallback(
    (search) => {
      if (!isEmpty(sensorConfigurationsMemo)) {
        let tempSensorList = generateSensorList(sensorConfigurationsMemo, sensorsDataMemo, search);
        if (auxConf) {
          auxConf.forEach((aux) => {
            const auxObj = getSensorDetails(aux.a, null, null, aux);
            tempSensorList.push(auxObj);
          });
        }
        tempSensorList = sensors
          ? tempSensorList.filter((sensor) => sensors.find((s) => s === sensor.id))
          : tempSensorList;
        dispatch({ type: 'SET_SENSORLIST', value: tempSensorList });
        setState((tempState) => ({
          ...tempState,
          sensorList: tempSensorList
        }));
      }
    },
    [sensorConfigurationsMemo, sensorsDataMemo, auxConf, sensors, dispatch]
  );

  useEffect(() => {
    getSensorList();
  }, [getSensorList]);

  return { data: state, handleSortData: setState, getSensorList };
};

export default useFetchSensorList;
