// PUBLIC IMPORTS
import { Divider } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { debounce, isEmpty } from 'lodash';
import { useLocation } from 'react-router-dom';

// import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
// import { saveNote, deleteNote } from 'components/sensor/sensor-notes/helper';

// CUSTOM COMPONENTS IMPORT
// import SensorNotesDialog from 'components/sensor/sensor-notes/SensorNotesDialog';
import SensorControlChart from '../SensorControlChart';

import { handleChangeSlider, handleChangeSpan } from './helper';
import useFetchSensorData from './useFetchSensorData';

import {
  Loading,
  Chart,
  Slider,
  MeasureButtons,
  MoistureButton,
  DateButtons
} from './SensorChartComponents';

import { useStyles } from './SensorChart.styled';
import { min, max } from './constants';

const SensorChart = () => {
  const location = useLocation();

  const classes = useStyles();
  const { promiseInProgress } = usePromiseTracker();
  const reduxDispatch = useDispatch();

  const [showMoisture, setShowMoisture] = useState(false);

  const { isAdmin, chartConfig, sensorData, auxConf, auxIDuser, isDemoMode, isMoistureDemo } =
    useSelector((store) => ({
      isAdmin: store.isAdmin,
      // isSupervisor: store.isSupervisor,
      chartConfig: store.chartConfig,
      // sessionInfo: store.sessionInfo,
      // sensorDetails: store.sensorDetails,
      sensorData: store.chartData?.tempSensorData,
      auxConf: store.auxConf,
      auxIDuser: store.userInfo?.auxDataIDs ? store.userInfo.auxDataIDs[0]?.id : null,
      isDemoMode: store.isDemoMode,
      isMoistureDemo: store.bsConf?.bs_id === 'DEMOdata0'
    }));

  const sensorPath = location.pathname.split('/');
  const id = sensorPath[sensorPath.length - 1];

  const auxConfig = auxConf?.find((aux) => aux.a === id);
  const { date, mode, span } = chartConfig;

  const isConfigChangedRef = useRef(false);

  const data = useFetchSensorData(isConfigChangedRef.current, auxConfig, auxIDuser, isDemoMode);

  const handleChangeSliderCB = debounce((value, handle, unencoded) => {
    reduxDispatch({
      type: 'SET_CHARTCONFIG',
      value: handleChangeSlider(unencoded, min)
    });
    isConfigChangedRef.current = true;
  }, 100);

  const handleChangeSpanCB = (value) => {
    reduxDispatch({
      type: 'SET_CHARTCONFIG',
      value: handleChangeSpan(value, min, date)
    });
    isConfigChangedRef.current = true;
  };

  const handleChangeModeCB = (value) => {
    reduxDispatch({ type: 'SET_CHARTCONFIG', value: { mode: value } });
    isConfigChangedRef.current = true;
  };

  if (!navigator.onLine) {
    return null;
  }
  const moistDemoData = [];
  // Demo moisture% figures in time order: today is last
  moistDemoData['070048'] = [16.2, 17.4, 18.5, 19.2, 19];
  moistDemoData['070049'] = [18.2, 19.5, 20.6, 21.7, 22.8, 22.5];
  moistDemoData['070374'] = [15.5, 15.9, 18.8, 21.4];
  moistDemoData['070373'] = [14.2, 13.4, 13.8];
  moistDemoData['070380'] = [13.4, 12.5, 12.8, 13.8, 13, 14.2, 13.6];

  const probeID = sensorData ? sensorData[0]?.a : null;
  const probeMoistData = moistDemoData[probeID]?.reverse();

  return (
    <div className={classes.mainContainer}>
      {promiseInProgress || data.isLoading ? (
        <div className={classes.chartLoadingContainer}>
          <Loading isLoading={promiseInProgress} />
        </div>
      ) : (
        // <div style={{ display: 'flex' }}>
        // {(isSupervisor || isAdmin) && (
        //     <>
        //       <CopyTable dataTemperature={data.data_temperature} />
        //     </>
        //   )} */}

        <Chart
          mode={mode}
          data={data}
          sensorData={sensorData}
          probeMoistData={showMoisture && probeMoistData}
        />
        // </div>
      )}
      <div className={classes.controlsContainer}>
        <Divider />
        <SensorControlChart data={data.snapshots} />
        <Slider min={min} max={max} date={date} handleChangeSlider={handleChangeSliderCB} />
        <div className={classes.spanButtonsContainer}>
          {isAdmin && <MeasureButtons mode={mode} handleChangeMode={handleChangeModeCB} />}
          {isMoistureDemo && probeMoistData && !isEmpty(probeMoistData) ? (
            <MoistureButton
              isSelected={showMoisture}
              toggleMoisture={() => setShowMoisture(!showMoisture)}
            />
          ) : (
            <div />
          )}
          <DateButtons
            span={span}
            isSnapShot={data.isSnapShot}
            handleChangeSpan={handleChangeSpanCB}
          />
        </div>
      </div>
    </div>
  );
};

export default SensorChart;
