import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useConfirm } from 'hook/useConfirm';
import BasestationSelect from 'components/general/BasestationSelect';
import { checkAccountMerge, doAccountMerge, getBasestations } from 'api/serverAPI';
import { getPlanName } from 'assets/utils';

import { COLORS } from 'utils/colors';

const MergeAccountsDialog = ({ isMergeAccountsDialog, setIsMergeAccountsDialog }) => {
  // const classes = useStyles();
  const [finalResponse, setFinalResponse] = useState(null);
  const [wait, setWait] = useState(null);
  const [error, setError] = useState(null);
  const confirm = useConfirm();
  const { userInfo, storages, batches } = useSelector((store) => ({
    userInfo: store.userInfo,
    storages: store.storages,
    batches: store.batches
  }));

  const [BSList, setBSList] = useState([]);
  const [selectedBS, setSelectedBS] = useState();
  const [targetUser, setTargetUser] = useState();
  const [currentService] = useState(
    getPlanName(userInfo?.plans ? userInfo?.plans[0] : null, userInfo?.service)
  );
  const [targetService, setTargetService] = useState();

  useEffect(() => {
    const getBSList = async () => {
      // let bsList = [];

      await getBasestations({
        activated: 'true'
      })
        .then((res) => {
          // Sort BS IDs, remove current BS ID from transfer targets.
          const bsList = res.basestations
            .map((bsitem) => bsitem.BS_ID)
            .filter((bsID) => bsID.substring(0, 1) === 'F')
            .filter((bsID) => !userInfo?.bs_BS_IDs?.includes(bsID))
            .sort((a, b) => a.localeCompare(b));
          // console.log('bsList', bsList);
          setBSList(bsList);
        })
        .catch((err) => {
          console.log('Error in getBasestations: ', err);
        });
    };
    getBSList();
  }, [setBSList, userInfo?.bs_BS_IDs]);

  const onSubmit = async () => {
    try {
      await confirm({
        title: 'Hello admin',
        description: `Are you sure you want to merge [${userInfo?.bs_BS_IDs}] to username "${targetUser?.username}" [${targetUser?.bs_BS_IDs}]? This account (username "${userInfo?.username}") will be removed.`
      });
      setWait(true);
      const res = await doAccountMerge({
        UID: userInfo?.U_ID,
        targetUID: targetUser?.U_ID,
        BSs: userInfo?.bs_BS_IDs,
        targetBSs: targetUser?.bs_BS_IDs
      });
      // console.log('doAccountMerge res ', res);

      switch (Number(res.status_code)) {
        case 0: {
          setFinalResponse(res);
          break;
        }
        case 1: {
          setError('Cannot merge account. User not found.');
          break;
        }
        case 2: {
          setError(res.error);
          break;
        }
        default:
          setError(`Error in account merging (status ${res.status_code}): ${res.error} `);
      }
    } catch (e) {
      console.log('Error in doAccountMerge: ', e.message);
      setError(`Error in doAccountMerge: ${e.message}`);
    }
  };

  const handleBSChange = async (newValue) => {
    // console.log('handleBSChange ', newValue.value);
    setTargetUser(null);
    setSelectedBS(newValue);
    setError(null);
    const checkRes = await checkAccountMerge({ BS_ID: newValue.value });
    // console.log('checkRes ', checkRes);
    if (checkRes.user) {
      if (!checkRes.user.username) {
        setError('The target account is incomplete (no username)');
      } else {
        setTargetUser(checkRes.user);
        setTargetService(
          getPlanName(
            checkRes?.user?.plans ? checkRes?.user?.plans[0] : null,
            checkRes?.user?.service
          )
        );
      }
    }
  };

  return (
    <Dialog open={isMergeAccountsDialog} aria-labelledby='responsive-dialog-title'>
      <DialogTitle>Merge Account</DialogTitle>

      <DialogContent elevation={0}>
        {!finalResponse && (
          <div>
            <div style={{ fontWeight: 700 }}>
              {`Merge [${userInfo.bs_BS_IDs}] to another account.`}
            </div>
            <div>{`Customer: ${userInfo.firstname} / ${userInfo.lastname}`}</div>
            <ul>
              {/* <li>{`Subscription: ${currentService}`}</li> */}
              {storages && !isEmpty(storages) && (
                <li>{`Storages (${storages.length}) of this account are moved to the destination account.`}</li>
              )}
              {batches && !isEmpty(batches) && (
                <li>{`Batches (${batches.length}) of this account are moved to the destination account.`}</li>
              )}
              {userInfo.subUsers && !isEmpty(userInfo.subUsers) && (
                <li>{`Additional users (${userInfo.subUsers.length}) of this account are moved to the destination account.`}</li>
              )}
              <li
                style={{ color: COLORS.adminProd }}
              >{`This account (username: "${userInfo.username}") will be removed.`}</li>
            </ul>
            <div style={{ fontSize: 12 }}>Select the destination account BS ID</div>
            <BasestationSelect
              selectedBS={selectedBS?.value}
              suggestionsBS={BSList?.map((item) => ({
                value: item,
                label: item
              }))}
              bsType='quanturi'
              onChange={(item) => handleBSChange(item)}
            />
            {targetUser && (
              <div style={{ border: '1px solid black', padding: 5, marginTop: 10 }}>
                Destination account:
                <ul>
                  <li>{`Customer: ${targetUser.firstname} / ${targetUser.lastname}`}</li>
                  <li>{`Username: "${targetUser.username}"`}</li>
                  <li>{`BS: [${targetUser.bs_BS_IDs}]`}</li>
                  <li>{`Subscription: ${targetService}`}</li>
                  {currentService !== targetService && (
                    <li style={{ color: COLORS.adminProd }}>
                      NOTE: Service subscriptions are different.
                      <br />
                      {`(This account has ${currentService})`}
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
        )}
        {finalResponse && (
          <div>
            <div>{`[${userInfo?.bs_BS_IDs}] merged to [${targetUser?.bs_BS_IDs}].`}</div>
            <div>Reload page to get BS list updated.</div>
          </div>
        )}
        {error && <div style={{ color: COLORS.adminProd, marginTop: 10 }}>{`${error}`}</div>}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setIsMergeAccountsDialog(!isMergeAccountsDialog)}
          variant={finalResponse && 'contained'}
          color={finalResponse ? 'secondary' : 'primary'}
        >
          {finalResponse ? 'Close' : 'Cancel'}
        </Button>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          disabled={!selectedBS || !targetUser || wait}
          onClick={onSubmit}
        >
          Merge account
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MergeAccountsDialog;
