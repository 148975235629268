import React from 'react';
import { format } from 'date-fns';
// import { adminStyles } from 'utils/sharedStyles';

const ActivityDateDisplay = ({ type, date }) => {
  //   const classes = adminStyles();

  let title = 'Last data';
  let typecolor = 'rgb(110, 189, 187)';

  const heartbeat = <span style={{ color: 'rgb(50,50,50)' }}> | ♡</span>;

  if (type === 1) {
    title = 'Last boot';
    typecolor = 'rgb(246, 77, 175)';
  } else if (type === 2) {
    title = 'Last conf';
    typecolor = 'rgb(34, 113, 111)';
  }

  let dateStr = 'N/A';
  try {
    if (date) {
      const dd = new Date(date);
      dateStr = format(dd, 'd.M.yyyy HH:mm');
    }
  } catch (e) {
    console.log('ERROR', e.message);
    dateStr = 'ERROR';
  }
  // date ? format(new Date(date), 'd.M.yyyy hh:mm:ss') :
  return (
    <div style={{ color: typecolor, padding: '2px 5px' }}>
      <div style={{ fontSize: 10 }}>
        {title}
        {type === 3 && heartbeat}
      </div>
      <div style={{ fontSize: 12 }}>{dateStr}</div>
    </div>
  );
};

export default ActivityDateDisplay;
