import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { COLORS } from 'utils/colors';

export const TEMPERATURE_MARKS = (min, max, degrees, specialInc, doubleDigit) => {
  let incr;
  if (specialInc) {
    incr = specialInc;
  } else if (degrees === '°F') {
    incr = 40;
  } else {
    incr = 20;
  }
  const marks = [];
  let i;
  let nb = min - incr;
  for (i = -1; i < Math.floor(max / incr); i++) {
    nb += incr;
    marks.push({
      value: nb,
      label: `${doubleDigit ? `0${nb}`.slice(-2) : nb}${degrees}`
    });
  }
  return marks;
};

const getLanguageList = (supportedLanguages) => {
  const data = {};

  for (let i = 0; i < supportedLanguages.length; i += 1) {
    data[supportedLanguages[i].locale] = supportedLanguages[i].name;
  }
  return data;
};

export const SETTINGS = () => {
  const supportedLanguages = useSelector((store) => store.supportedLanguages);

  return {
    alarm_treshold_1: {
      title: 'Temperature Alert 1',
      type: 'temp_slider',
      range: [0, 90],
      // value: 0,
      color: COLORS.secondaryColor
    },
    sms1: {
      title: 'SMS 1',
      type: 'radio',
      data: { yes: 'Yes', no: 'No' }
      // value: 'no'
    },
    alarm_treshold_2: {
      title: 'Temperature Alert 2',
      type: 'temp_slider',
      range: [0, 90],
      // value: 0,
      color: COLORS.secondaryColor
    },
    sms2: {
      title: 'SMS 2',
      type: 'radio',
      data: { yes: 'yes', no: 'no' }
      // value: 'no'
    },
    lang: {
      title: 'Language',
      type: 'radio',
      data: getLanguageList(supportedLanguages)
      // value: 'en'
    },
    degrees: {
      title: 'Temperature format',
      type: 'radio',
      data: { '°C': 'Celsius', '°F': 'Fahrenheit' }
      // value: 'C'
    },
    d_format: {
      title: 'Date Format',
      type: 'radio',
      data: {
        'dd/MM/yyyy': format(new Date(), 'dd/MM/yyyy'),
        'd/M/yyyy': format(new Date(), 'd/M/yyyy'),
        'd.M.yyyy': format(new Date(), 'd.M.yyyy'),
        'MM/dd/yyyy': format(new Date(), 'MM/dd/yyyy'),
        'M/d/yyyy': format(new Date(), 'M/d/yyyy')
      }
      // value: 'dd/MM/yyyy'
    },
    t_format: {
      title: 'Time Format',
      type: 'radio',
      data: {
        'HH:mm': format(new Date(), 'HH:mm'),
        'hh:mm a': format(new Date(), 'hh:mm a')
      }
      // value: 'HH:mm'
    },
    safety_alert: {
      title: 'Safety alert',
      type: 'radio',
      data: { true: 'enabled', false: 'disabled' },
      hidden: false
    },
    temp_alert: {
      title: 'Temperature alert',
      type: 'temp_slider',
      range: [0, 100],
      range_f: [20, 220],
      // value: 0,
      // color: COLORS.secondaryColor,
      hidden: false
    },
    incl_exc: {
      title: 'Include exceptions',
      type: 'radio',
      data: { true: 'enabled', false: 'disabled' },
      // value: '0',
      hidden: false
    },
    incl_sens_data: {
      title: 'Include sensor data table',
      type: 'radio',
      data: { true: 'enabled', false: 'disabled' },
      // value: '0',
      hidden: false
    },
    meas_per_day: {
      title: 'Measurements per day',
      type: 'radio',
      data: { 2: 2, 4: 4, 8: 8, 12: 12, 24: 24 },
      // value: '0',
      hidden: false
    },
    def_chart: {
      title: 'Default chart range',
      type: 'temp_slider',
      range: [0, 100],
      range_f: [20, 220],
      // value: 0,
      // color: COLORS.secondaryColor,
      hidden: false
    },
    def_color: {
      title: 'Default color range',
      type: 'temp_slider',
      range: [0, 100],
      range_f: [20, 220],
      // value: 0,
      // color: COLORS.secondaryColor,
      hidden: false
    },
    handle_temp: {
      title: 'Show multipoint handle temperature',
      type: 'radio',
      data: { true: 'enabled', false: 'disabled' },
      // value: '0',
      hidden: true
    },
    // ONLY FOR ADMIN
    heartbeat: {
      title: 'Heartbeat',
      type: 'radio',
      data: { 1: 'Enabled', 0: 'Disabled' },
      // value: '0',
      hidden: true
    },
    communication_interval: {
      title: 'Server communication interval',
      type: 'slider',
      range: [1, 60],
      // value: 0,
      color: COLORS.secondaryColor,
      hidden: false
    },
    timeout: {
      title: 'Request timeout',
      type: 'slider',
      range: [0, 120],
      // value: 0,
      color: COLORS.adminProd,
      hidden: true
    },
    buffer_max: {
      title: 'Max measurement buffer size',
      type: 'slider',
      range: [0, 100],
      // value: 0,
      color: COLORS.adminProd,
      hidden: true
    },
    clear_buffer: {
      title: 'Clear buffer',
      type: 'radio',
      data: { 1: 'Enabled', 0: 'Disabled' },
      // value: '0',
      hidden: true
    },
    tempIntervalDegrees: {
      title: 'Measurement acceleration temperature limit',
      type: 'slider',
      range: [50, 99],
      // value: 0,
      color: COLORS.secondaryColor,
      hidden: false
    },
    tempIntervalTime: {
      title: 'Accelerated measurement interval',
      type: 'slider',
      range: [1, 60],
      // value: 0,
      color: COLORS.secondaryColor,
      hidden: false
    },
    measurement_interval_0: {
      title: 'Default measurement interval',
      type: 'slider',
      range: [1, 60],
      // value: 0,
      color: COLORS.secondaryColor,
      hidden: false
    },
    measurement_interval_1: {
      title: 'Measurement interval 1',
      type: 'slider',
      range: [1, 60],
      // value: 0,
      color: COLORS.secondaryColor,
      hidden: false
    },
    measurement_interval_2: {
      title: 'Measurement interval 2',
      type: 'slider',
      range: [1, 60],
      // value: 0,
      color: COLORS.secondaryColor,
      hidden: false
    },
    receiving_time: {
      title: 'Receiving time',
      type: 'slider',
      range: [4, 10],
      // value: 5,
      color: COLORS.secondaryColor,
      hidden: true
    }
  };
};
