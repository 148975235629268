import { SET_LOGIN } from 'redux/constants';
import initialState from 'redux/store/initialState';

export default function setLogin(state = initialState.isLoggedIn, action) {
  switch (action.type) {
    case SET_LOGIN: {
      return action.value;
    }
    default: {
      return state;
    }
  }
}
