import { Tooltip } from '@material-ui/core';
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
// import { format as formatTZ } from 'date-fns-tz';
import { /* sub, */ isSameDay, format } from 'date-fns';

import { InlineIcon } from '@iconify/react';

// import updateIcon from '@iconify/icons-mdi/update';
import handBackRight from '@iconify/icons-mdi/hand-back-right';
import fanOff from '@iconify/icons-mdi/fan-off';
import fanIcon from '@iconify/icons-mdi/fan';
import fanRemove from '@iconify/icons-mdi/fan-remove';
import toggleSwitch from '@iconify/icons-mdi/toggle-switch';
import toggleSwitchOffOutline from '@iconify/icons-mdi/toggle-switch-off-outline';
import timer1 from '@iconify/icons-mdi/clock-time-one-outline';
import timer2 from '@iconify/icons-mdi/clock-time-two-outline';
import timer3 from '@iconify/icons-mdi/clock-time-three-outline';
import timer4 from '@iconify/icons-mdi/clock-time-four-outline';
import timer5 from '@iconify/icons-mdi/clock-time-five-outline';
import timer6 from '@iconify/icons-mdi/clock-time-six-outline';
import timer7 from '@iconify/icons-mdi/clock-time-seven-outline';
import timer8 from '@iconify/icons-mdi/clock-time-eight-outline';
import timer9 from '@iconify/icons-mdi/clock-time-nine-outline';
import timer10 from '@iconify/icons-mdi/clock-time-ten-outline';
import timer11 from '@iconify/icons-mdi/clock-time-eleven-outline';
import timer12 from '@iconify/icons-mdi/clock-time-twelve-outline';
import localeFormat from 'utils/localeFormat';
import { COLORS } from 'utils/colors';
import classesSpin from 'utils/Spinner.module.css';
import { listItemStatusBarStyle } from 'utils/sharedStyles';
import FcIndicators from './FcIndicators';
import { controllerRule } from '../monitors/monitor-utils/fcHelpers';

export const FanStatus = ({ itemFcStatus, FcState, size, isTooltip, showText, isManualRule }) => {
  const i18n = useSelector((store) => store.i18n);
  // console.log('FanStatus manual ', isManualRule);
  // const timezone = useSelector((store) => store.bsConf.timezone);
  const timeFormat = useSelector((store) => store.sessionInfo.ui_preferences.time_format);

  // Use 1-12'o clock icons
  const getTimerIcon = (hour) => {
    switch (hour % 12) {
      case 0:
        return timer12;
      case 1:
        return timer1;
      case 2:
        return timer2;
      case 3:
        return timer3;
      case 4:
        return timer4;
      case 5:
        return timer5;
      case 6:
        return timer6;
      case 7:
        return timer7;
      case 8:
        return timer8;
      case 9:
        return timer9;
      case 10:
        return timer10;
      case 11:
        return timer11;
      default:
        return timer12;
    }
  };

  let title;
  let icon;
  let style = { color: COLORS.darkGray };
  let className;
  let extra;

  if (itemFcStatus) {
    if (
      itemFcStatus.connectionStatus &&
      (itemFcStatus.connectionStatus.isOffline || itemFcStatus.connectionStatus?.newOfflineLogged)
    ) {
      title = i18n.fc_offline;
      icon = fanRemove;
      style = { color: COLORS.alert };
    } else if (itemFcStatus.fc_mode === 'drive') {
      if (+itemFcStatus?.manualCommand === 1 && !!itemFcStatus.isManual) {
        title = '';
        icon = fanIcon;
        style = {
          color:
            +FcState?.command === 0 && !isManualRule ? COLORS.invalidStatus : COLORS.validStatus
        };
        className = classesSpin.rotateFan;
      } else if (+itemFcStatus?.manualCommand === 0 && !!itemFcStatus.isManual) {
        title = '';
        icon = fanOff;
        style = {
          color: +FcState?.command === 0 || isManualRule ? COLORS.validStatus : COLORS.invalidStatus
        };
      } else if (+FcState?.command === 0 && !!FcState?.scheduled) {
        // Here applying the timezone to the displayed scheduled time
        // const timezoneSplited = String(formatTZ(new Date(), 'XXX', { timeZone: timezone })).split(
        //   ':'
        // );
        // const scheduledTime = sub(FcState?.scheduled * 1000, {
        //   hours: timezoneSplited[0],
        //   minutes: timezoneSplited[1]
        // });
        // However, is the time displayed correctly as new Date?
        const scheduledTime = new Date(FcState?.scheduled * 1000);

        const iscurrentDate = isSameDay(scheduledTime, new Date());
        let timeDisplayed;
        let timeDisplayedShort;
        if (iscurrentDate) {
          timeDisplayed = format(scheduledTime, timeFormat);
          timeDisplayedShort = timeDisplayed;
        } else {
          timeDisplayed = format(scheduledTime, `EEEE ${timeFormat}`, {
            locale: localeFormat(i18n.locale)
          });
          timeDisplayedShort = format(scheduledTime, timeFormat);
        }
        const timerIcon = getTimerIcon(scheduledTime.getHours());
        title = (
          <div>
            {i18n.scheduled_start}
            {i18n.colon}
            {timeDisplayed}
          </div>
        );
        icon = timerIcon;
        style = { color: COLORS.validStatus };
        extra = <span style={{ textAlign: 'center' }}>{showText ? timeDisplayedShort : null}</span>;
      } else if (+FcState?.command === 1) {
        title = i18n.fc_drive_on;
        icon = fanIcon;
        style = { color: COLORS.validStatus };
        className = classesSpin.rotateFan;
      } else if (+FcState?.command === 0) {
        title = i18n.fc_drive_off;
        icon = fanOff;
        style = { color: COLORS.validStatus };
      }
    } else if (itemFcStatus.fc_mode === 'on') {
      if (+FcState?.command === 0) {
        title = i18n.fc_on_forced;
        icon = fanIcon;
        // style = { color: COLORS.invalidStatus };
        className = classesSpin.rotateFan;
      } else if (+FcState?.command === 1) {
        title = i18n.fc_on_ok;
        icon = fanIcon;
        // style = { color: COLORS.validStatus };
        className = classesSpin.rotateFan;
      }
    } else if (itemFcStatus.fc_mode === 'off') {
      if (+FcState?.command === 0) {
        title = i18n.fc_off_ok;
        icon = fanOff;
        // style = { color: COLORS.validStatus };
      } else if (+FcState?.command === 1) {
        title = i18n.fc_off_forced;
        icon = fanOff;
        // style = { color: COLORS.invalidStatus };
      }
    }

    return (
      <Tooltip title={isTooltip ? title || '' : ''} placement='bottom' disableFocusListener>
        <div>
          <InlineIcon width={size} height={size} icon={icon} style={style} className={className} />
          {extra}
        </div>
      </Tooltip>
    );
  }
  return null;
};
export const GetSwitchIcon = ({ itemFcStatus }) => {
  let icon;
  let style = { color: COLORS.dimGray, padding: '0 2px' };
  let size = 25;
  if (itemFcStatus) {
    if (
      itemFcStatus.connectionStatus &&
      (itemFcStatus.connectionStatus.isOffline || itemFcStatus.connectionStatus.newOfflineLogged)
    ) {
      return null;
    }
    if (itemFcStatus.fc_mode === 'drive') {
      if (itemFcStatus.isManual) {
        icon = handBackRight;
        style = { ...style, transform: 'rotate(50deg) translate(-2px, 2px) scale(0.9)' };
        size = 20;
      }
    } else if (itemFcStatus.fc_mode === 'on') {
      icon = toggleSwitch;
    } else if (itemFcStatus.fc_mode === 'off') {
      icon = toggleSwitchOffOutline;
    }
    return <InlineIcon width={size} height={size} icon={icon} style={style} />;
  }
  return null;
};

// Title with batch name and status indicator (active, completed)
const StorageListItemFCStatusBar = ({
  // outdoorTemp,
  // minTemp,
  // maxTemp,
  minTS,
  maxTS,
  monitor,
  itemFcStatus
}) => {
  // console.log('StorageListItemFCStatusBar', monitor);
  const classes = listItemStatusBarStyle();
  const i18n = useSelector((store) => store.i18n);
  const FcState = monitor?.rules?.find((rule) => controllerRule(rule))?.state;
  const FcSettings = monitor?.rules?.find((rule) => controllerRule(rule))?.settings;

  // const noData =
  //   isNil(minTemp) ||
  //   minTemp === -Infinity ||
  //   minTemp === Infinity ||
  //   isNil(maxTemp) ||
  //   maxTemp === -Infinity ||
  //   maxTemp === Infinity;

  if (monitor?.rules?.find((rule) => rule.type === 'CYCLIC_CONTROL')) {
    return (
      <CyclingStatusBar
        i18n={i18n}
        classes={classes}
        FcSettings={FcSettings}
        FcState={FcState}
        itemFcStatus={itemFcStatus}
      />
    );
  }
  if (monitor?.rules?.find((rule) => rule.type === 'MANUAL_CONTROL')) {
    return (
      <ManualStatusBar
        i18n={i18n}
        classes={classes}
        FcSettings={FcSettings}
        FcState={FcState}
        itemFcStatus={itemFcStatus}
      />
    );
  }
  return (
    <CooldownStatusBar
      classes={classes}
      FcSettings={FcSettings}
      FcState={FcState}
      maxTS={maxTS}
      minTS={minTS}
      itemFcStatus={itemFcStatus}
    />
  );
};

const CyclingStatusBar = ({ i18n, classes, FcSettings, FcState, itemFcStatus }) => {
  // console.log('CyclingStatusBar ', FcSettings, FcState, itemFcStatus);

  // Short test spans: update in 10 s. - only for dev & test
  // 30-60 min span: update 30s
  // >60 min span: update 60s
  const interval = FcSettings?.cycleSpan < 30 ? 10 : FcSettings?.cycleSpan <= 60 ? 30 : 60; // seconds in updating progress bar
  const addedTime = useRef(0); // client-side progress, added to cycle start time
  const now = useRef(new Date());
  const [currentProgressPercent, setCurrentProgressPercent] = useState(
    (((+now.current + addedTime.current) / 1000 - FcState?.currentCycleStarted) /
      60 /
      FcSettings?.cycleSpan) *
      100
  );

  const cycleStarted = useRef(null);
  if (!cycleStarted?.current || +cycleStarted?.current !== +FcState?.currentCycleStarted) {
    // New cycle started, reset the progress
    cycleStarted.current = FcState?.currentCycleStarted;
    addedTime.current = 0;
    now.current = new Date();
    setCurrentProgressPercent(
      (((+now.current + addedTime.current) / 1000 - FcState?.currentCycleStarted) /
        60 /
        FcSettings?.cycleSpan) *
        100
    );
    // console.log('*** Set cycle start: ', new Date(cycleStarted.current * 1000));
  } else {
    // console.log('*   Cycle start: ', new Date(cycleStarted.current * 1000));
  }

  const intervalId = useRef(null);

  // No progress showing when FC offline in manual run/stop mode
  const showNoProgress =
    itemFcStatus?.fc_mode !== 'drive' ||
    (itemFcStatus?.connectionStatus &&
      (itemFcStatus.connectionStatus.isOffline || itemFcStatus.connectionStatus?.newOfflineLogged));

  useEffect(() => {
    // addedTime = 0;
    if (!intervalId.current) {
      // console.log('create interval');
      intervalId.current = setInterval(() => {
        // console.log('add time to ', addedTime.current);
        addedTime.current += interval * 1000;
        setCurrentProgressPercent(
          (((+now.current + addedTime.current) / 1000 - FcState?.currentCycleStarted) /
            60 /
            FcSettings?.cycleSpan) *
            100
        );
      }, interval * 1000);
    }

    return () => {
      // It is important to clear the interval when rendering ends
      // so we don't accumulate multipe intervals
      clearInterval(intervalId.current);
      intervalId.current = null;
      // console.log('interval cleared');
    };
  }, [interval, FcSettings?.cycleSpan, FcState?.currentCycleStarted, showNoProgress]);

  const onTimePercent = (FcSettings?.runMinutes / FcSettings?.cycleSpan) * 100;

  return (
    <div className={classes.statusArea} style={{ minHeight: 'auto' }}>
      <div className={[classes.cyclicFcPanel, classes.statusFcPanel].join(' ')}>
        <div
          className={showNoProgress ? classes.targetBarWrapperDisabled : classes.targetBarWrapper}
        >
          <>
            <div className={classes.targetBar}>
              <div className={classes.targetBarText}>
                {FcSettings?.cycleSpan} {i18n.minute_mini}
              </div>
              <div
                className={classes.targetProgressDiff}
                style={
                  showNoProgress ? { display: 'none' } : { width: `${currentProgressPercent}%` }
                }
              />
              <div className={classes.targetProgress} style={{ width: `${onTimePercent}%` }} />
            </div>
          </>
        </div>
        <div style={{ display: 'flex', margin: '0 5px', alignItems: 'center' }}>
          <GetSwitchIcon itemFcStatus={itemFcStatus} />
          <FanStatus itemFcStatus={itemFcStatus} isTooltip FcState={FcState} size={20} />
        </div>
      </div>
    </div>
  );
};

const CooldownStatusBar = ({ classes, FcSettings, FcState, maxTS, minTS, itemFcStatus }) => {
  const FcStatusInfo = (
    <div
      className={
        // monitor
        //   ? classes.statusIconsPanel
        //   :
        [classes.statusIconsPanel, classes.statusFcPanel].join(' ')
      }
    >
      <FcIndicators
        classes={classes}
        FcSettings={FcSettings}
        FcState={FcState}
        maxTS={maxTS}
        minTS={minTS}
        delta
      />
      <div style={{ display: 'flex', margin: '0 5px', alignItems: 'center' }}>
        <GetSwitchIcon itemFcStatus={itemFcStatus} />
        <FanStatus itemFcStatus={itemFcStatus} isTooltip FcState={FcState} size={20} />
      </div>
    </div>
  );

  return (
    <div className={classes.statusArea} style={{ minHeight: 'auto' }}>
      <FcIndicators classes={classes} FcSettings={FcSettings} FcState={FcState} />
      {FcStatusInfo}
    </div>
  );
};

const ManualStatusBar = ({ classes, FcSettings, FcState, itemFcStatus }) => {
  const FcStatusInfo = (
    <div
      className={
        // monitor
        //   ? classes.statusIconsPanel
        //   :
        [classes.statusIconsPanel, classes.statusFcPanel].join(' ')
      }
    >
      {/* <FcIndicators
        classes={classes}
        FcSettings={FcSettings}
        FcState={FcState}
        maxTS={maxTS}
        minTS={minTS}
        delta
      /> */}
      <div style={{ display: 'flex', margin: '0 5px', alignItems: 'center' }}>
        <GetSwitchIcon itemFcStatus={itemFcStatus} />
        <FanStatus itemFcStatus={itemFcStatus} isTooltip FcState={FcState} size={20} isManualRule />
      </div>
    </div>
  );

  return (
    <div className={classes.statusArea} style={{ minHeight: 'auto' }}>
      <FcIndicators classes={classes} FcSettings={FcSettings} FcState={FcState} />
      {FcStatusInfo}
    </div>
  );
};

export default StorageListItemFCStatusBar;
