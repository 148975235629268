import {
  Dialog,
  DialogTitle,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import fanIcon from '@iconify/icons-mdi/fan';
import { COLORS } from 'utils/colors';
import classesSpin from 'utils/Spinner.module.css';
import { DeleteDialog } from 'utils/DeleteDialog';
import { DialogActionUtil } from 'utils/DialogActions';

const FcRuleEditor = ({
  classes,
  i18n,
  degrees,
  selectedRuleType,
  selectedRule,
  setMonitorEdited,
  monitorEdited,
  newRule,
  setNewRule,
  setSelectedRuleType,
  setSelectRuleTypeDialog,
  setSelectedRule,
  ruleTypes,
  setRuleInUseEdited,
  monitorInUse,
  selectedMonitor,
  timeStamp,
  FC_IDS
}) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [fanAnimation, setFanAnimation] = useState(true);

  const [controller, setController] = useState(FC_IDS?.length === 1 ? FC_IDS[0] : '');

  const onResetStates = () => {
    setNewRule(false);
    setSelectRuleTypeDialog(false);
    setSelectedRuleType(null);
    setSelectedRule(null);
    setOpenDeleteDialog(false);

    setController(FC_IDS?.length === 1 ? FC_IDS[0] : '');
  };

  const handleDeleteRule = () => {
    setMonitorEdited({
      ...monitorEdited,
      rules: monitorEdited.rules.filter((ruleToDelete) => ruleToDelete !== selectedRule)
    });

    onResetStates();
  };

  useEffect(() => {
    if (selectedRule) {
      setController(selectedRule?.settings?.FC_ID || '');
    }
  }, [degrees, ruleTypes, selectedRule, setSelectedRule]);

  const onSaveFCRule = (selectedRuleToEdit) => {
    const settingsToSave = {
      FC_ID: controller
    };

    if (selectedRuleToEdit) {
      return {
        id: selectedRuleToEdit.id,
        type: selectedRuleType.type,
        ruleChanged: {
          changedAt: timeStamp,
          oldSettings: selectedMonitor && {
            ...selectedMonitor.rules.find((rule) => rule.id === selectedRuleToEdit.id)?.settings
          }
        },
        settings: settingsToSave
      };
    }
    return {
      id:
        monitorEdited.rules.length >= 1
          ? Math.max(...monitorEdited.rules?.map((s) => +s.id)) + 1
          : 1,
      type: selectedRuleType.type,
      settings: settingsToSave
    };
  };
  const onSaveRule = () => {
    let ruleData;
    if (selectedRule) {
      if (selectedRuleType.group === 'FC') {
        ruleData = onSaveFCRule(selectedRule);
      } else {
        return;
      }

      setMonitorEdited({
        ...monitorEdited,
        rules: monitorEdited.rules.map((rule) =>
          rule.id === ruleData.id
            ? {
                ...monitorEdited.rules.find((ruleEdited) => ruleEdited.id === ruleData.id),
                ...ruleData
              }
            : { ...rule }
        )
      });
      console.log('ruleData edit', ruleData);
    } else {
      if (selectedRuleType.group === 'FC') {
        ruleData = onSaveFCRule();
      } else {
        return;
      }

      console.log('ruleData', ruleData);

      if (monitorEdited.rules) {
        setMonitorEdited({
          ...monitorEdited,
          rules: [...monitorEdited.rules, ruleData]
        });
      } else {
        setMonitorEdited({
          ...monitorEdited,
          rules: [ruleData]
        });
      }
    }
    if (monitorInUse) {
      setRuleInUseEdited(true);
    }
    onResetStates();
  };

  return (
    <Dialog className={classes.dialog} open={newRule} aria-labelledby='responsive-dialog-title'>
      <DialogTitle>
        <div className={classes.DialogTitle}>
          <div>{i18n[`${selectedRuleType?.type}_title`] || selectedRuleType?.title}</div>
        </div>
      </DialogTitle>

      <div className={classes.div} style={{ overflow: 'auto' }}>
        <div style={{ paddingRight: 10 }}>
          <div style={{ background: COLORS.fcBackground, padding: '10px', margin: '10px 0' }}>
            {controller ? (
              <Icon
                with='2em'
                height='2em'
                className={
                  fanAnimation
                    ? [classes.FC_icon, classesSpin.rotateFanSel].join(' ')
                    : [classes.FC_icon, classesSpin.rotateFanSel2].join(' ')
                }
                icon={fanIcon}
              />
            ) : null}
            <div className={classes.div}>
              <Typography id='button-group' gutterBottom>
                {i18n.controller || 'Controller'}
              </Typography>
              <FormControl className={classes.formControl} style={{ margin: 0 }}>
                <InputLabel required>{i18n.select_controller || 'Select controller'}</InputLabel>
                <Select
                  value={controller}
                  onChange={(e) => [setController(e.target.value), setFanAnimation(!fanAnimation)]}
                >
                  <MenuItem value=''>
                    <em> {i18n.no_controller || 'No controller'}</em>
                  </MenuItem>
                  {FC_IDS?.map((FC, i) => (
                    <MenuItem key={i} value={FC}>
                      {FC}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {/* <div style={{ margin: '0 10px' }}>
              <FormControlLabel
                control={
                  <Switch
                    disabled={!controller}
                    checked={smsNotifs}
                    onChange={() => setSmsNotifs(!smsNotifs)}
                    name='SMS Switch'
                  />
                }
                label={`${i18n.notif_status || 'SMS notifications'}${i18n.colon}${
                  smsNotifs ? i18n.enabled || 'enabled' : i18n.disabled || 'disabled'
                }`}
              />
            </div> */}
          </div>
        </div>
      </div>
      <DialogActionUtil
        onCancel={onResetStates}
        onSave={onSaveRule}
        text={i18n.save || 'Save'}
        disabled={!controller}
        deleteButton={selectedRule}
        handleDelete={() => setOpenDeleteDialog(true)}
      />
      <DeleteDialog
        i18n={i18n}
        openDeleteDialog={openDeleteDialog}
        handleCloseDeleteDialog={() => setOpenDeleteDialog(false)}
        handleDelete={handleDeleteRule}
        text={i18n.alert_remove_rule || 'Do you want to remove this rule?'}
      />
    </Dialog>
  );
};

export default FcRuleEditor;
