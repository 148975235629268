import {
  Dialog,
  DialogTitle,
  Typography,
  Slider,
  Divider,
  FormControl,
  FormControlLabel,
  Switch,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { format, addDays, startOfWeek } from 'date-fns';
import { Icon } from '@iconify/react';
import fanIcon from '@iconify/icons-mdi/fan';
import localeFormat from 'utils/localeFormat';
import { tempFormat, fToCelsius } from 'assets/utils';
import ThermometerSun from 'assets/svg/ThermometerSun';
import { COLORS } from 'utils/colors';
import classesSpin from 'utils/Spinner.module.css';
import { DeleteDialog } from 'utils/DeleteDialog';
import { DialogActionUtil } from 'utils/DialogActions';
import { TEMPERATURE_MARKS } from 'components/settings/SettingProps';
import SchedulerSlider from './SchedulerSlider';

const FcRuleEditor = ({
  QSlider,
  classes,
  i18n,
  degrees,
  selectedRuleType,
  selectedRule,
  setMonitorEdited,
  monitorEdited,
  newRule,
  setNewRule,
  setSelectedRuleType,
  setSelectRuleTypeDialog,
  setSelectedRule,
  ruleTypes,
  setRuleInUseEdited,
  monitorInUse,
  selectedMonitor,
  timeStamp,
  sensorConfigurations,
  FC_IDS,
  setToRunScheduler
}) => {
  const { auxConf } = useSelector((store) => ({
    auxConf: store.auxConf
  }));

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const dayIndices = [1, 2, 3, 4, 5, 6, 0]; // Mon to Sun

  // RULE TYPE GROUP 3

  const outdoorProbes = sensorConfigurations?.filter((sensor) => +sensor.sensor_id_sys < 90000);

  const possibleDepths = auxConf ? Math.max(...auxConf.map((aux) => aux.hh?.length - 1)) : 4;

  const depthArray = Array.from(Array(possibleDepths + 1).keys()).slice(1);

  const [targetTemp, setTargetTemp] = useState(tempFormat(degrees, 10, 0));
  const [outdoorDelta, setOutdoorDelta] = useState([
    tempFormat(degrees, 5, 0),
    tempFormat(degrees, 15, 0)
  ]);
  const [outdoorProbeID, setOutdoorProbeID] = useState(
    outdoorProbes?.length === 1 ? outdoorProbes[0].sensor_id_sys : ''
  );
  const [tempRef, setTempRef] = useState('AVG');
  const [depth, setDepth] = useState(0);
  const [controller, setController] = useState(FC_IDS?.length === 1 ? FC_IDS[0] : '');
  const [smsNotifs, setSmsNotifs] = useState(false);
  const [scheduler, setScheduler] = useState(false);

  const [fanAnimation, setFanAnimation] = React.useState(true);

  // Scheduler
  const weekArray = Array.from(Array(7)).map((e, i) =>
    format(addDays(startOfWeek(new Date()), i), 'EEE', { locale: localeFormat(i18n.locale) })
  );

  const [disabledHours0, setDisabledHours0] = React.useState([[12, 12]]);
  const [disabledHours1, setDisabledHours1] = React.useState([[12, 12]]);
  const [disabledHours2, setDisabledHours2] = React.useState([[12, 12]]);
  const [disabledHours3, setDisabledHours3] = React.useState([[12, 12]]);
  const [disabledHours4, setDisabledHours4] = React.useState([[12, 12]]);
  const [disabledHours5, setDisabledHours5] = React.useState([[12, 12]]);
  const [disabledHours6, setDisabledHours6] = React.useState([[12, 12]]);

  const [reverseDays, setReverseDays] = useState(dayIndices.map(() => false));

  const disabledHours = {
    0: disabledHours0,
    1: disabledHours1,
    2: disabledHours2,
    3: disabledHours3,
    4: disabledHours4,
    5: disabledHours5,
    6: disabledHours6
  };
  const setDisabledHours = {
    0: setDisabledHours0,
    1: setDisabledHours1,
    2: setDisabledHours2,
    3: setDisabledHours3,
    4: setDisabledHours4,
    5: setDisabledHours5,
    6: setDisabledHours6
  };

  const onResetStates = () => {
    setNewRule(false);
    setSelectRuleTypeDialog(false);
    setSelectedRuleType(null);
    setSelectedRule(null);
    setOpenDeleteDialog(false);

    // RULE TYPE 3
    setTargetTemp(tempFormat(degrees, 10, 1));
    setOutdoorDelta([tempFormat(degrees, 5, 1), tempFormat(degrees, 15, 1)]);
    setOutdoorProbeID(outdoorProbes?.length === 1 ? outdoorProbes[0].sensor_id_sys : '');
    setTempRef('AVG');
    setDepth(0);
    setController(FC_IDS?.length === 1 ? FC_IDS[0] : '');
    setSmsNotifs(false);
    setScheduler(false);
    setDisabledHours0([[12, 12]]);
    setDisabledHours1([[12, 12]]);
    setDisabledHours2([[12, 12]]);
    setDisabledHours3([[12, 12]]);
    setDisabledHours4([[12, 12]]);
    setDisabledHours5([[12, 12]]);
    setDisabledHours6([[12, 12]]);
  };

  const handleDeleteRule = () => {
    setMonitorEdited({
      ...monitorEdited,
      rules: monitorEdited.rules.filter((ruleToDelete) => ruleToDelete !== selectedRule)
    });

    onResetStates();
  };

  useEffect(() => {
    if (selectedRule) {
      // RULE TYPE GROUP 3
      setController(selectedRule?.settings?.FC_ID || '');
      setTargetTemp(
        selectedRule?.settings?.targetTemp !== undefined
          ? tempFormat(degrees, +selectedRule.settings?.targetTemp, 1)
          : tempFormat(degrees, 10, 1)
      );
      setOutdoorDelta(
        selectedRule?.settings?.delta1 !== undefined && selectedRule?.settings?.delta2 !== undefined
          ? [
              tempFormat(degrees, selectedRule?.settings?.delta1, 1),
              tempFormat(degrees, selectedRule?.settings?.delta2, 1)
            ]
          : [tempFormat(degrees, 5, 1), tempFormat(degrees, 15, 1)]
      );
      setOutdoorProbeID(selectedRule?.settings?.outdoorID || '');
      setTempRef(selectedRule?.settings?.tempRef.substring(0, 3) || 'AVG');
      setDepth(selectedRule?.settings?.refDepth || 0);
      setSmsNotifs(selectedRule?.settings?.notifications || false);
      setScheduler(selectedRule?.settings?.schedulerEnabled || false);
      if (selectedRule?.settings?.reverseDisabled) {
        setReverseDays(selectedRule.settings.reverseDisabled);
      }
      setDisabledHours0(selectedRule?.settings.disabledHours[0] || [[12, 12]]);
      setDisabledHours1(selectedRule?.settings.disabledHours[1] || [[12, 12]]);
      setDisabledHours2(selectedRule?.settings.disabledHours[2] || [[12, 12]]);
      setDisabledHours3(selectedRule?.settings.disabledHours[3] || [[12, 12]]);
      setDisabledHours4(selectedRule?.settings.disabledHours[4] || [[12, 12]]);
      setDisabledHours5(selectedRule?.settings.disabledHours[5] || [[12, 12]]);
      setDisabledHours6(selectedRule?.settings.disabledHours[6] || [[12, 12]]);
    }
  }, [degrees, ruleTypes, selectedRule, setSelectedRule]);

  const onSaveFCRule = (selectedRuleToEdit) => {
    const settingsToSave = {
      FC_ID: controller,
      outdoorID: outdoorProbeID,
      targetTemp: degrees === '°C' ? +targetTemp : fToCelsius(+targetTemp),
      delta1: degrees === '°C' ? +outdoorDelta[0] : fToCelsius(+outdoorDelta[0]),
      delta2: degrees === '°C' ? +outdoorDelta[1] : fToCelsius(+outdoorDelta[1]),
      tempRef: +depth === 0 ? tempRef : `${tempRef}_DEPTH`,
      refDepth: depth,
      outdoorAgelimit: 2,
      dataAgelimit: 6,
      hysteresis: 1,
      notifications: smsNotifs,
      schedulerEnabled: scheduler,
      disabledHours,
      reverseDisabled: reverseDays
    };

    if (selectedRuleToEdit) {
      return {
        id: selectedRuleToEdit.id,
        type: selectedRuleType.type,
        ruleChanged: {
          changedAt: timeStamp,
          oldSettings: selectedMonitor && {
            ...selectedMonitor.rules.find((rule) => rule.id === selectedRuleToEdit.id)?.settings
          }
        },
        settings: settingsToSave
      };
    }
    return {
      id:
        monitorEdited.rules.length >= 1
          ? Math.max(...monitorEdited.rules?.map((s) => +s.id)) + 1
          : 1,
      type: selectedRuleType.type,
      settings: settingsToSave
    };
  };
  const onSaveRule = () => {
    let ruleData;
    if (selectedRule) {
      if (selectedRuleType.group === 'FC') {
        ruleData = onSaveFCRule(selectedRule);
      } else {
        return;
      }

      setMonitorEdited({
        ...monitorEdited,
        rules: monitorEdited.rules.map((rule) =>
          rule.id === ruleData.id
            ? {
                ...monitorEdited.rules.find((ruleEdited) => ruleEdited.id === ruleData.id),
                ...ruleData
              }
            : { ...rule }
        )
      });
      console.log('ruleData edit', ruleData);
    } else {
      if (selectedRuleType.group === 'FC') {
        ruleData = onSaveFCRule();
      } else {
        return;
      }

      console.log('ruleData', ruleData);

      if (monitorEdited.rules) {
        setMonitorEdited({
          ...monitorEdited,
          rules: [...monitorEdited.rules, ruleData]
        });
      } else {
        setMonitorEdited({
          ...monitorEdited,
          rules: [ruleData]
        });
      }
    }
    if (monitorInUse) {
      setRuleInUseEdited(true);
    }
    onResetStates();
  };

  // Makes sure that at least one day has restrictions
  const disabledScheduler = (object) => {
    if (object) {
      const keys = Object.keys(object);
      const values = Object.values(object);

      const arr = [];
      for (let i = 0; i < keys.length; i += 1) {
        if (values[i][0][0] === values[i][0][1]) {
          arr.push(i);
        }
      }
      return arr.length === 7;
    }
    return false;
  };

  // Makes sure not all the values are [0, 24]
  const disabledScheduler2 = (object) => {
    if (object) {
      const keys = Object.keys(object);
      const values = Object.values(object);

      const arr = [];
      for (let i = 0; i < keys.length; i += 1) {
        if (values[i][0][0] === 0 && values[i][0][1] === 24) {
          arr.push(i);
        }
      }
      return arr.length === 7;
    }
    return false;
  };

  const disabledSave = (typeGroupToSave) => {
    switch (typeGroupToSave) {
      case 'FC':
        return (
          !controller ||
          !outdoorProbeID ||
          (controller &&
            scheduler &&
            (disabledScheduler(disabledHours) || disabledScheduler2(disabledHours)))
        );
      default:
        break;
    }
  };

  const schedulerHandler = () => {
    setScheduler(!scheduler);
    if (monitorInUse) {
      setToRunScheduler(true);
    }
  };

  const minSlider = degrees === '°C' ? 0 : 20;
  const maxSlider = degrees === '°C' ? 60 : 140;

  const setReversed = (dayIndex, value) => {
    const newRev = reverseDays.map((d, i) => (i === dayIndex ? value : d));
    setReverseDays(newRev);
  };

  return (
    <Dialog className={classes.dialog} open={newRule} aria-labelledby='responsive-dialog-title'>
      <DialogTitle>
        <div className={classes.DialogTitle}>
          <div>{i18n[`${selectedRuleType?.type}_title`] || selectedRuleType?.title}</div>
        </div>
      </DialogTitle>

      <div className={classes.div} style={{ overflow: 'auto' }}>
        <div style={{ paddingRight: 10 }}>
          <div className={classes.div}>
            <Typography style={{ marginBottom: 40 }} id='button-group' gutterBottom>
              {i18n.target_temp || 'Target temperature'}
            </Typography>
          </div>
          <div className={classes.divQSlider}>
            <QSlider
              min={minSlider}
              max={maxSlider}
              value={targetTemp}
              marks={TEMPERATURE_MARKS(minSlider, maxSlider, degrees)}
              valueLabelFormat={`${targetTemp} ${degrees}`}
              onChange={(event, newValue) => setTargetTemp(newValue)}
              valueLabelDisplay='on'
              aria-labelledby='greater or lower than selected temperature'
            />
          </div>
          <Divider />
          <div className={classes.div}>
            <Typography style={{ marginBottom: 40 }} id='button-group' gutterBottom>
              {i18n.outdoor_delta || 'Outdoor ∆ range for operation'}
            </Typography>
          </div>
          <div className={classes.divQSlider}>
            <Slider
              min={minSlider}
              max={maxSlider}
              value={outdoorDelta}
              marks={TEMPERATURE_MARKS(minSlider, maxSlider, degrees)}
              onChange={(event, newValue) => setOutdoorDelta(newValue)}
              valueLabelFormat={(val) => Math.round(val)}
              valueLabelDisplay='on'
              aria-labelledby='greater or lower than selected temperature'
            />
          </div>
          {/* <Divider /> */}
          <div style={{ background: COLORS.fcBackground, padding: '10px', margin: '10px 0' }}>
            <ThermometerSun
              style={{
                width: '30px',
                height: '30px',
                float: 'right',
                margin: '8px 1px'
              }}
              color={COLORS.silverSonic}
            />
            <div className={classes.div}>
              <Typography id='button-group' gutterBottom>
                {i18n.outdoor_probe || 'Outdoor probe ID'}
              </Typography>
              <FormControl className={classes.formControl} style={{ margin: 0 }}>
                <InputLabel required>{i18n.select_probe || 'Select probe'}</InputLabel>
                <Select
                  value={outdoorProbeID}
                  onChange={(e) => setOutdoorProbeID(e.target.value)}
                  disabled={selectedRuleType?.group === 'REL'}
                >
                  <MenuItem value=''>
                    <em> {i18n.select_probe || 'Select probe'}</em>
                  </MenuItem>
                  {outdoorProbes?.map((sens) => (
                    <MenuItem key={sens.sensor_id_sys} value={sens.sensor_id_sys}>
                      {sens.sensor_name || sens.sensor_id_sys}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          {/* <Divider /> */}
          <div>
            <FormControl className={classes.formControl} style={{ width: 275 }}>
              <InputLabel>{i18n.ref_temp || 'Reference temperature'}</InputLabel>
              <Select value={tempRef} onChange={(e) => setTempRef(e.target.value)}>
                {selectedRuleType?.refs?.map((ref) => (
                  <MenuItem key={ref.id} value={ref.name}>
                    {i18n[ref.labeli18n] || ref.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControlDepth}>
              <InputLabel>{i18n.depth_title || 'Depth'}</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={depth}
                onChange={(e) => setDepth(e.target.value)}
              >
                <MenuItem value='0'>
                  <em>{i18n.any || 'Any'}</em>
                </MenuItem>
                {depthArray?.map((d) => (
                  <MenuItem key={d} value={d}>
                    {d}m
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {/* <Divider /> */}
          <div style={{ background: COLORS.fcBackground, padding: '10px', margin: '10px 0' }}>
            {controller ? (
              <Icon
                with='2em'
                height='2em'
                className={
                  fanAnimation
                    ? [classes.FC_icon, classesSpin.rotateFanSel].join(' ')
                    : [classes.FC_icon, classesSpin.rotateFanSel2].join(' ')
                }
                icon={fanIcon}
              />
            ) : null}
            <div className={classes.div}>
              <Typography id='button-group' gutterBottom>
                {i18n.controller || 'Controller'}
              </Typography>
              <FormControl className={classes.formControl} style={{ margin: 0 }}>
                <InputLabel required>{i18n.select_controller || 'Select controller'}</InputLabel>
                <Select
                  value={controller}
                  onChange={(e) => [setController(e.target.value), setFanAnimation(!fanAnimation)]}
                >
                  <MenuItem value=''>
                    <em> {i18n.no_controller || 'No controller'}</em>
                  </MenuItem>
                  {FC_IDS?.map((FC, i) => (
                    <MenuItem key={i} value={FC}>
                      {FC}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div style={{ margin: '0 10px' }}>
              <FormControlLabel
                control={
                  <Switch
                    disabled={!controller}
                    checked={smsNotifs}
                    onChange={() => setSmsNotifs(!smsNotifs)}
                    name='SMS Switch'
                  />
                }
                label={`${i18n.notif_status || 'SMS notifications'}${i18n.colon}${
                  smsNotifs ? i18n.enabled || 'enabled' : i18n.disabled || 'disabled'
                }`}
              />
            </div>
            <div style={{ margin: '0 10px' }}>
              <FormControlLabel
                control={
                  <Switch
                    disabled={!controller}
                    checked={scheduler}
                    onChange={schedulerHandler}
                    name='Scheduler Switch'
                  />
                }
                label={`${i18n.scheduler_status || 'Scheduler'}${i18n.colon}${
                  scheduler ? i18n.enabled || 'enabled' : i18n.disabled || 'disabled'
                }`}
              />
            </div>
            {controller &&
              scheduler &&
              dayIndices.map((key) => {
                const stateSlider = disabledHours[key];
                const setStateSlider = setDisabledHours[key];
                const weekLabel = weekArray[key];
                return (
                  <SchedulerSlider
                    key={key}
                    index={key}
                    weekLabel={weekLabel}
                    disabledHours={stateSlider}
                    setDisabledHours={setStateSlider}
                    monitorInUse={monitorInUse}
                    setToRunScheduler={setToRunScheduler}
                    reversed={reverseDays[key]}
                    setReversed={setReversed}
                    fanIcons
                  />
                );
              })}
          </div>
        </div>
      </div>
      <DialogActionUtil
        onCancel={onResetStates}
        onSave={onSaveRule}
        text={i18n.save || 'Save'}
        disabled={disabledSave(selectedRuleType?.group)}
        deleteButton={selectedRule}
        handleDelete={() => setOpenDeleteDialog(true)}
      />
      <DeleteDialog
        i18n={i18n}
        openDeleteDialog={openDeleteDialog}
        handleCloseDeleteDialog={() => setOpenDeleteDialog(false)}
        handleDelete={handleDeleteRule}
        text={i18n.alert_remove_rule || 'Do you want to remove this rule?'}
      />
    </Dialog>
  );
};

export default FcRuleEditor;
