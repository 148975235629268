import { Typography, ListItem, ListItemText } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { celsiusToF, tempFormat } from 'assets/utils';
import { SETTINGS } from './SettingProps';
import {
  DefaultSlider,
  MeasIntervalSlider,
  DefaultColorSlider,
  DefaultChartSlider,
  measRangeLogic
} from './helper';
import { useStyles } from './Settings.styled';

const ListItemWithSlider = ({ i18n, degrees, id, data, marks, toggleSettings }) => {
  const classes = useStyles();
  const bsConf = useSelector((state) => state.bsConf);
  const baseStation = useSelector((state) => state.baseStation);

  const { intervalValues, range } = measRangeLogic(bsConf, baseStation);
  const settings = SETTINGS();
  const Slider = () => {
    let slider;
    if (id === 'def_color') {
      slider = (
        <DefaultColorSlider
          value={
            data[id].value && data[id].value.length === 5
              ? data[id].value.map((v) => +tempFormat(degrees, v, 0))
              : degrees === '°C'
              ? [0, 40, 60, 80, 100]
              : [celsiusToF(0), celsiusToF(40), celsiusToF(60), celsiusToF(80), celsiusToF(100)]
          }
          marks={marks}
          isf={degrees === '°C' ? 0 : 1}
          min={degrees === '°C' ? settings[id].range[0] : settings[id].range_f[0]}
          max={degrees === '°C' ? settings[id].range[1] : settings[id].range_f[1]}
        />
      );
    } else if (id === 'def_chart') {
      slider = (
        <DefaultChartSlider
          value={
            data.def_chart.value
              ? degrees === '°C'
                ? [Math.round(data[id].value[0]), Math.round(data[id].value[1])]
                : [+celsiusToF(+data[id].value[0]), +celsiusToF(+data[id].value[1])]
              : degrees === '°C'
              ? [20, 80]
              : [+celsiusToF(20), +celsiusToF(80)]
          }
          marks={marks}
          min={degrees === '°C' ? settings[id].range[0] : settings[id].range_f[0]}
          max={degrees === '°C' ? settings[id].range[1] : settings[id].range_f[1]}
        />
      );
    } else if (id === 'temp_alert') {
      slider = (
        <DefaultSlider
          value={
            degrees === '°C'
              ? +data[id].value >= 0
                ? Math.round(data[id].value)
                : 45
              : +celsiusToF(data[id].value) >= 0
              ? +celsiusToF(+data[id].value)
              : +celsiusToF(45)
          }
          marks={marks}
          min={degrees === '°C' ? settings[id].range[0] : settings[id].range_f[0]}
          max={degrees === '°C' ? settings[id].range[1] : settings[id].range_f[1]}
        />
      );
    } else if (id.includes('measurement_interval') || id === 'tempIntervalTime') {
      slider = (
        <MeasIntervalSlider
          value={data[id].value}
          i18n={i18n}
          tooltips
          snap={!!intervalValues}
          range={range}
        />
      );
    } else {
      slider = (
        <DefaultSlider
          value={+data[id].value >= 0 ? +data[id].value : 45}
          marks={marks}
          min={settings[id].range[0]}
          max={settings[id].range[1]}
        />
      );
    }
    return slider;
  };

  return (
    <ListItem
      button
      className={classes.tempContainer}
      onClick={() => toggleSettings(id, settings[id].type)}
    >
      <ListItemText
        primary={
          <Typography variant='subtitle1' className={classes.tempTitle}>
            {i18n[id] || data[id].title || settings[id].title}
          </Typography>
        }
        secondary={
          <Typography component='div' className={classes.sliderContainer}>
            <Slider />
          </Typography>
        }
      />
    </ListItem>
  );
};

export default React.memo(ListItemWithSlider);
