import { parse } from 'date-fns';

export const getDateFromStr = (dateStr, concernTz) => {
  if (!dateStr) {
    return null;
  }
  const shortStr = dateStr.split('').length < 9;
  const tzMs = concernTz ? new Date().getTimezoneOffset() * 60000 : 0;
  const d = new Date(parse(dateStr, `yyyyMMdd${!shortStr ? 'HHmmss' : ''}`, new Date()));
  return new Date(d.getTime() - tzMs);
};
