import {
  Slider,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Checkbox,
  Tooltip
} from '@material-ui/core';
import { useState } from 'react';
import { InlineIcon } from '@iconify/react';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { withStyles } from '@material-ui/styles';
import { COLORS } from 'utils/colors';
import { backgroundInfoStyles } from './backgroundInfoStyles';

// const getSteps = (value, maxes, steps) => {
//   if (value < maxes[0]) return steps[0];
//   if (value < maxes[1]) return steps[1];
//   return steps[2];
//   // const array = [];
//   // for (let steps1 = 0; steps1 < maxes[0]; steps1 += steps[0]) {
//   //   array.push(steps1);
//   // }
//   // for (let step2 = maxes[0]; step2 <= maxes[1]; step2 += steps[1]) {
//   //   array.push(step2);
//   // }
//   // return array;
// };

export const InsightSlider = withStyles({
  root: {
    color: COLORS.haytech
  },
  valueLabel: {
    color: COLORS.haytech
  }
})(Slider);

export const constructSaveObject = (state) => {
  const finalAnswers = {};
  // Iterate over the state to set the key and value
  Object.keys(state)
    .filter((obj) => obj !== 'storages')
    .forEach((item) => {
      if (item === 'activityType' && state[item].extra.value !== '') {
        finalAnswers.activityOther = state[item].extra.value;
      }
      if (item === 'yearlyCuts' && state[item].extra.value === true) {
        finalAnswers[item] = -1;
        return;
      }
      finalAnswers[item] = state[item].value;
    });
  return finalAnswers;
};

export const getI18nText = (i18n, info, type) => i18n[`${info.key}_${type}`] || info[type];
export const getI18nOption = (i18n, key, option) =>
  i18n[`${key}_option_${option.value}`] || option.label;
export const getI18nExtra = (i18n, key, extra) => i18n[`${key}_extra_title`] || extra.title;

export const SurveyToggleButton = ({ i18n, id, info, setState }) => {
  const classes = backgroundInfoStyles();
  const { value, options } = info;

  const [selectedValue, setSelectedValue] = useState(value);

  const onChangeHandler = (e, val) => {
    if (val === null) return;
    setSelectedValue(val);
    setState((tempState) => ({ ...tempState, [id]: { ...tempState[id], value: val } }));
  };
  return (
    <div className={classes.question}>
      <Typography gutterBottom>{getI18nText(i18n, info, 'title')}</Typography>
      <ToggleButtonGroup value={selectedValue} exclusive onChange={onChangeHandler}>
        {options.map((option) => (
          <ToggleButton key={option.value} value={option.value}>
            <InlineIcon
              style={{
                color: selectedValue === option.value && COLORS.haytech,
                marginBottom: 10,
                marginRight: 5
              }}
              width={50}
              height={50}
              icon={option.icon}
            />
            {getI18nOption(
              i18n,
              info.key,
              info.options.find((opt) => opt.value === option.value)
            )}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );
};

export const SurveyRadio = ({ i18n, id, info, setState }) => {
  const classes = backgroundInfoStyles();
  const { value, options, extra } = info;
  const [selectedValue, setSelectedValue] = useState(value);

  const onChangeHandler = (e, val) => {
    setSelectedValue(val);
    setState((tempState) => ({ ...tempState, [id]: { ...tempState[id], value: val } }));
  };

  const [selectedOtherValue, setSelectedOtherValue] = useState(extra?.value);

  const onChangeOtherHandler = (e) => {
    setSelectedOtherValue(e.target.value);
  };

  const onBlurOtherHandler = (e) => {
    setState((tempState) => ({
      ...tempState,
      [id]: { ...tempState[id], extra: { ...tempState[id].extra, value: e.target.value } }
    }));
  };

  return (
    <div className={classes.question}>
      <FormControl>
        <FormLabel>{getI18nText(i18n, info, 'title')}</FormLabel>
        <RadioGroup row value={selectedValue} onChange={onChangeHandler}>
          {options.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={
                <Radio disableRipple classes={{ root: classes.radio, checked: classes.checked }} />
              }
              label={getI18nOption(
                i18n,
                info.key,
                info.options.find((opt) => opt.value === option.value)
              )}
            />
          ))}
        </RadioGroup>
      </FormControl>
      {!!extra && selectedValue === 'other' && (
        <div style={{ marginBottom: 20, marginTop: 5 }}>
          <FormControl>
            <TextField
              id='other'
              type='text'
              label={i18n.please_specify || 'Please specify'}
              variant='outlined'
              value={selectedOtherValue}
              required
              onChange={onChangeOtherHandler}
              onBlur={onBlurOtherHandler}
            />
          </FormControl>
        </div>
      )}
    </div>
  );
};

export const SurveySlider = ({ i18n, id, info, setState, label }) => {
  const { value, step, marks, min, max, extra, valueLabel } = info;
  const nonLinear = typeof step !== 'number';
  const maxValue =
    id === 'balesWeight'
      ? label === 'kg'
        ? max[0]
        : max[1]
      : nonLinear
      ? max[max.length - 1]
      : max;
  const disabled = extra?.value;

  const classes = backgroundInfoStyles();

  const [selectedValue, setSelectedValue] = useState(value);

  const ExtraForCuts = () => (
    <FormControlLabel
      className={classes.extra1}
      control={
        <Checkbox
          classes={{ root: classes.checkbox, checked: classes.checked }}
          checked={extra.value ?? ''}
          onChange={(e) =>
            setState((tempState) => ({
              ...tempState,
              [id]: { ...tempState[id], extra: { ...tempState[id].extra, value: e.target.checked } }
            }))
          }
        />
      }
      label={getI18nExtra(i18n, id, extra)}
    />
  );

  const baleCountMarks = [
    {
      value: 0,
      scaledValue: 0,
      step: 100,
      label: '0'
    },
    {
      value: 10,
      scaledValue: 1000,
      step: 100,
      label: '1000'
    },
    {
      value: 20,
      scaledValue: 2000,
      step: 100,
      label: '2000'
    },
    {
      value: 30,
      scaledValue: 3000,
      step: 200,
      label: '3000'
    },
    {
      value: 40,
      scaledValue: 5000,
      step: 500,
      label: '5000'
    },
    {
      value: 50,
      scaledValue: 10000,
      step: 5000,
      label: '10000'
    },
    {
      value: 58,
      scaledValue: 50000,
      label: '50000'
    }
  ];

  const scale = (val) => {
    // console.log('scale(val) ', val);
    if (val === 0) {
      return 0;
    }
    const limits = baleCountMarks.map((m) => m.value);
    // console.log('limits ', limits);
    // Find the next smallest value of val from limits
    const prevLimitIndex = limits.map((v) => v < val).indexOf(false) - 1;
    // console.log('prevLimitIndex ', prevLimitIndex);
    // console.log('prev mark ', baleCountMarks[prevLimitIndex]);
    const scaledValue =
      baleCountMarks[prevLimitIndex].scaledValue +
      (val - baleCountMarks[prevLimitIndex].value) * baleCountMarks[prevLimitIndex].step;
    return scaledValue;
  };

  const unScale = (volume) => {
    if (volume === 0) {
      return 0;
    }
    // console.log('unScale ', volume);
    const limits = baleCountMarks.map((m) => m.scaledValue);
    // console.log('limits ', limits);
    // Find the next smallest scaledValue of volume from limits
    const prevLimitIndex = limits.map((v) => v < volume).indexOf(false) - 1;
    // console.log('prevLimitIndex ', prevLimitIndex);
    // console.log('prev mark ', baleCountMarks[prevLimitIndex]);
    // console.log('next mark ', baleCountMarks[prevLimitIndex + 1]);
    const val =
      baleCountMarks[prevLimitIndex].value +
      ((volume - baleCountMarks[prevLimitIndex].scaledValue) /
        (baleCountMarks[prevLimitIndex + 1].scaledValue -
          baleCountMarks[prevLimitIndex].scaledValue)) *
        (baleCountMarks[prevLimitIndex + 1].value - baleCountMarks[prevLimitIndex].value);
    return val;
  };

  const onChangeHandler = (e, val, doScale) => {
    // console.log('**** onChangeHandler ', val);
    setSelectedValue(doScale ? scale(val) : val);
  };

  const onChangeCommittedHandler = (e, val, doScale) => {
    // console.log('**** onChangeCommittedHandler ', val, doScale, '->', doScale ? scale(val) : val);
    setState((tempState) => ({
      ...tempState,
      [id]: { ...tempState[id], value: doScale ? scale(val) : val }
    }));
  };

  const getTitleSlider = () => {
    let titleValue = '';
    let titleValueLabel = '';
    if (id === 'storageTime') {
      titleValue =
        selectedValue[0] === selectedValue[1]
          ? `${selectedValue[0]} ${
              selectedValue[0] === 1 ? i18n.month || 'month' : i18n.months || 'months'
            }`
          : `${selectedValue[0]}-${selectedValue[1]} ${i18n.months || 'months'}`;
    } else {
      titleValue = selectedValue.toLocaleString();
    }
    if (label || valueLabel) {
      titleValueLabel = label || valueLabel;
    }
    return `${getI18nText(i18n, info, 'title')}${i18n.colon}${titleValue} ${titleValueLabel}`;
  };
  return (
    <div className={classes.question}>
      <Typography>{getTitleSlider()}</Typography>
      {nonLinear ? (
        <InsightSlider
          className={classes.slider}
          value={unScale(selectedValue)}
          min={0}
          step={1}
          max={58} // 58 steps for baleCountMarks
          marks={baleCountMarks}
          scale={scale}
          onChange={(e, val) => onChangeHandler(e, val, true)}
          onChangeCommitted={(e, val) => onChangeCommittedHandler(e, val, true)}
          valueLabelDisplay={disabled ? 'off' : 'on'}
          valueLabelFormat={(val) => val.toLocaleString()}
          color='secondary'
        />
      ) : (
        <InsightSlider
          className={classes.slider}
          value={selectedValue}
          onChange={onChangeHandler}
          onChangeCommitted={onChangeCommittedHandler}
          valueLabelDisplay={disabled ? 'off' : 'on'}
          valueLabelFormat={(val) => val.toLocaleString()}
          step={step}
          marks={marks}
          min={min}
          max={maxValue}
          color='secondary'
          disabled={disabled}
        />
      )}
      {id === 'yearlyCuts' && <ExtraForCuts />}
    </div>
  );
};

export const StorageTypeSelector = ({ i18n, storageType, onChange, storageTypeOptions }) => (
  <>
    <Typography gutterBottom>{i18n.storage_type || 'Storage type'}</Typography>
    <ToggleButtonGroup value={storageType} exclusive onChange={onChange}>
      {storageTypeOptions.map((option) => (
        <Tooltip key={option.id} title={option.label} arrow placement='bottom' disableFocusListener>
          <ToggleButton
            selected={option.id === storageType}
            style={{ padding: 10 }}
            value={option.id}
          >
            <img width='60px' alt='type' src={option.icon} />
          </ToggleButton>
        </Tooltip>
      ))}
    </ToggleButtonGroup>
  </>
);

export const StorageCapacitySelector = ({
  i18n,
  storageCapacity,
  // hayType,
  typeUnit,
  classes,
  onChangeHandler,
  maxCapacity
}) => (
  <>
    <Typography gutterBottom>
      {i18n.capacity || 'Capacity'} (
      {i18n[`hayTypeUnit_option_${typeUnit === 'bales' ? 'bales' : 'tons'}`]})
    </Typography>
    <InsightSlider
      className={classes.slider}
      value={storageCapacity}
      onChange={onChangeHandler}
      valueLabelDisplay='on'
      valueLabelFormat={(val) => val.toLocaleString()}
      step={
        maxCapacity <= 3000 ? 100 : maxCapacity <= 5000 ? 200 : maxCapacity <= 10000 ? 500 : 1000
      }
      min={0}
      max={maxCapacity}
      color='secondary'
    />
  </>
);
