import { differenceInMinutes, differenceInHours, differenceInDays } from 'date-fns';
import Parser from 'react-html-parser';

export const getAge = (i18n, dateFormat, timeFormat, tobeAged, completedAt) => {
  let Age = '';
  const diffData = completedAt ? new Date(completedAt * 1000) : new Date();
  const mins = differenceInMinutes(diffData, tobeAged * 1000);
  const hours = differenceInHours(diffData, tobeAged * 1000);
  const days = differenceInDays(diffData, tobeAged * 1000);

  const timeValue = mins <= 60 ? mins : mins <= 60 * 24 ? hours : days;
  const timeUnit =
    mins <= 60
      ? ` ${i18n.minute_mini}`
      : mins <= 60 * 24
      ? ` ${i18n.hour_mini}`
      : ` ${i18n.day_mini}`;
  Age = `${timeValue}${timeUnit}`;

  return Age;
};

export const getAgeStrict = (from, to) => {
  const d = Math.abs(from - to);
  const sign = from > to ? '+' : '-';
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  const hDisplay = h > 0 ? `${h}h ` : '';
  const mDisplay = m > 0 ? `${m}:` : '0:';
  const sDisplay = s > 0 ? `${`0${s}`.slice(-2)}` : '00';
  // const hDisplay = h > 0 ? h + (h === 1 ? ' hour, ' : ' hours, ') : '';
  // const mDisplay = m > 0 ? m + (m === 1 ? ' minute, ' : ' minutes, ') : '';
  // const sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : '';

  return `${sign} ${hDisplay}${mDisplay}${sDisplay}`;
};

export const getPreviousUpdateTS = (batches) =>
  Math.max(
    ...batches // take the max of last_ts values of batches
      .filter((b) => Number(b.status) === 1) // only concern ongoing batches
      .map((b) => b.batch_phases[0]) // take the last phase: phases[0] is correct for "light" batches with only last phase
      .map((ph) => ph.sensor_progress)
      .flat() // all sensor pogresses s to flat array
      .filter((sen) => sen.last_ts) // take sensors that have last_ts (future batches don't have yet)
      .map((sen) => sen.last_ts)
  );

export const ADMIN_GROUPS = [
  { value: 0, label: 'Root' },
  { value: 1, label: 'Admin' },
  { value: 2, label: 'Tech' },
  { value: 3, label: 'Sales' }
];

export const i18nVariablesInjection = (text, variables, style, noSpan) => {
  let editedText = text;

  if (!editedText) {
    return '';
  }
  // Injecting variables between '{{_}}', eg: {{variable}}
  const matchRegexForVariables = /{{(([^}][^}]?|[^}]}?)*)}}/g;
  const injectionsArray = editedText.match(matchRegexForVariables);
  if (injectionsArray) {
    for (let index = 0; index < injectionsArray.length; index++) {
      const element = injectionsArray[index];
      const replaceRegex = /({{)|(}})/gm;
      const accessor = element.replace(replaceRegex, '');

      editedText = editedText.replace(
        element,
        noSpan
          ? `${variables[accessor]}`
          : `<span style='font-weight: 600'>${variables[accessor]}</span>`
      );
    }
  }
  // Coloring text between '*', eg: *TEXT*
  const matchRegexForColoredText = /\*([^*]+)\*/g;
  const coloringNeededArray = editedText.match(matchRegexForColoredText);
  if (coloringNeededArray) {
    for (let index = 0; index < coloringNeededArray.length; index++) {
      const element = coloringNeededArray[index];
      const replaceRegex = /(\*)/g;
      const accessor = element.replace(replaceRegex, '');
      editedText = editedText.replace(element, `<span style="${style}">${accessor}</span>`);
    }
  }

  const parsed = Parser(editedText);
  return parsed;
};

export const phoneNumberFormat = (number) => number.replace(/\s+/g, '');
