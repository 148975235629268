import { makeStyles } from '@material-ui/core';
import { COLORS } from 'utils/colors';

export const insightStyles = makeStyles((theme) => ({
  welcomeRoot: {
    backgroundSize: 'cover',
    height: 130,
    width: '100%',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 50
  },
  root: {
    paddingTop: 70,
    margin: 'auto',
    maxWidth: 600
  },
  welcomeContent: {
    maxWidth: 600,
    margin: 'auto',
    marginTop: 130,
    padding: '0 30px 20px'
  },
  content: {
    padding: 20
  },
  survey: { padding: '0 20px' },
  button: {
    margin: 10,
    textAlign: 'center'
  },
  question: {
    // margin: 10,
    padding: '15px 0'
  },
  div: {
    // margin: 10,
    padding: '10px 0'
  },
  errorMessage: {
    // textAlign: 'center',
    fontSize: '0.75rem'
  },
  slider: {
    marginTop: 45,
    width: '100%'
  },
  valueLabel: {
    textAlign: 'center',
    color: 'red'
  },
  stepper: {
    padding: '30px 0',
    margin: 'auto',
    [theme.breakpoints.down('md')]: {
      minHeight: 'auto',
      width: 'auto'
    }
  },
  readyContainer: {
    // margin: '0 30px'
  },
  storageTable: {
    // width: '100%',
    textAlign: 'center',
    border: '1px solid black',
    borderCollapse: 'collapse',
    margin: '20px 0',
    maxHeight: 400,
    overflow: 'auto'
  },
  storageTableHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 5px',
    margin: '5px 10px',
    width: 'calc(100% - 40px)'
  },
  // storageTableBody: {
  //   maxHeight: 400,
  //   overflow: 'auto'
  // },
  storageIcon: {
    position: 'absolute',
    left: 3,
    top: 4
  },
  storageTableContent: {
    backgroundColor: COLORS.whiteSmoke,
    minHeight: 40,
    textTransform: 'initial',
    padding: '0 5px',
    margin: '5px 10px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    width: 'calc(100% - 30px)',
    '&:hover': {
      backgroundColor: COLORS.haytech,
      color: 'white'
      // boxShadow: '1px 1px 5px rgb(0 0 0 / 30%)'
    }
  },
  storageTableDivIcon: {
    width: 50
  },
  buttonDiv: {
    // width: 100,
    textTransform: 'uppercase',
    backgroundColor: 'rgba(100,100,100,0.3)',
    borderRadius: 4,
    margin: 0,
    padding: '3px 15px'
  },
  storageTableDiv: {
    flex: 1,
    padding: 5,
    whiteSpace: 'nowrap',
    textAlign: 'left',
    fontWeight: 'normal',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  storageNameDiv: {
    fontWeight: 'unset',
    marginLeft: 30
  },
  storageEditContent: {
    padding: '0 25px'
  },
  list: {
    listStyleType: '"\\2713"',
    '& > li': {
      padding: 5
    }
  },
  trial: {
    fontWeight: 'bolder',
    fontSize: 18,
    marginBottom: 20
  },
  noCommitment: {
    fontStyle: 'italic',
    fontSize: 'small',
    margin: '10px 0'
  }
}));
