import {
  Grow,
  Dialog,
  DialogTitle,
  DialogContent,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormGroup,
  Typography,
  makeStyles
} from '@material-ui/core';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { DialogActionUtil } from 'utils/DialogActions';
import { postInsightFreemiumTrial } from 'api/serverAPI';
import { COLORS } from 'utils/colors';
import { getPlanName } from 'assets/utils';
import { fetchData } from 'navigation/MainNavigator/helper';
import { i18nVariablesInjection } from 'utils/sharedHelper';

export const useStyles = makeStyles(() => ({
  welcomeTitle: {
    fontWeight: 800,
    marginBottom: 20,
    fontSize: 20,
    verticalAlign: 'middle'
  },
  listBenefits: {
    backgroundColor: COLORS.haytech,
    color: COLORS.white,
    borderRadius: 10,
    padding: '10px 20px',
    marginLeft: 5
  },
  warningList: { paddingLeft: 31, color: COLORS.invalidStatus, lineHeight: '22px' },
  checkbox: { marginTop: 15 },
  errorMessage: {
    fontSize: '0.75rem'
  },
  buttonsPanel: {
    margin: '10px 32px 20px'
  }
}));

const ExpiredDialog = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const i18n = useSelector((state) => state.i18n);
  const userInfo = useSelector((state) => state.userInfo);
  const BS_ID = useSelector((store) => store.bsConf.bs_id);

  const { upgrade } = queryString.parse(useLocation().search);
  // console.log('upgrade =', upgrade);

  const close = () => {
    history.replace('/');
  };

  const [accepted, setAccepted] = useState('true');

  const [termChecked, setTermChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const termAcceptedHandler = () => {
    setErrorMessage(null);
    setTermChecked((prevState) => !prevState);
  };

  const onChangeHandler = (e, val) => {
    setAccepted(val);
    // Makes sure the user sees the list of what he will miss if doesn't accept
    setTimeout(() => {
      document.getElementById('bottom').scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  const onSave = async () => {
    if (!termChecked) {
      setErrorMessage(i18n.must_accept_gts);
      setTimeout(() => {
        document.getElementById('insightAccept').scrollTop = 2000; // Make user see the terms
      }, 500);
    } else {
      if (isSubmitting) {
        return; // prevent possible subsequent calls (primarily prevented already by disabled button)
      }
      try {
        setIsSubmitting(true);
        await postInsightFreemiumTrial({
          accepted,
          U_ID: userInfo.U_ID,
          upgradeBasis: upgrade
        });
        await fetchData(BS_ID, dispatch);
        close();
      } catch (e) {
        console.log('error in postInsightFreemiumTrial', e);
      }
    }
  };

  return (
    <Dialog
      style={{ margin: 30 }}
      open
      maxWidth='lg'
      TransitionComponent={Grow}
      aria-labelledby='responsive-dialog-title'
    >
      <DialogTitle>{getPlanName(null, userInfo.service, null, true)}</DialogTitle>
      <DialogContent id='insightAccept' elevation={0}>
        <div>
          <div className={classes.welcomeTitle}>
            {i18nVariablesInjection(
              i18n.insight_freemium_offer,
              null,
              `color:white; background-color: ${COLORS.haytech}; font-size: 35px; padding: 0 10px; border-radius: 5px; margin-right: 2px;`
            )}
          </div>
          <FormControl component='fieldset'>
            {upgrade ? (
              <div className={classes.listBenefits}>
                {i18nVariablesInjection(i18n.insight_benefits)}
              </div>
            ) : (
              <RadioGroup value={accepted} onChange={onChangeHandler}>
                <FormControlLabel
                  value='true'
                  control={<Radio color='default' />}
                  label={
                    <div className={classes.listBenefits}>
                      {i18nVariablesInjection(i18n.insight_benefits)}
                    </div>
                  }
                />
                <FormControlLabel
                  value='false'
                  control={<Radio color='default' />}
                  label={i18n.no_thanks}
                />
              </RadioGroup>
            )}
          </FormControl>
          {accepted === 'false' && (
            <div className={classes.warningList}>
              {i18nVariablesInjection(i18n.insight_refusal_warning)}
            </div>
          )}
          <FormGroup row className={classes.checkbox}>
            <FormControlLabel
              control={
                <Checkbox
                  required
                  checked={termChecked}
                  color='primary'
                  onClick={termAcceptedHandler}
                />
              }
              required
              label={
                <div>
                  {i18n.accept_gtcs_1}
                  <a
                    rel='noopener noreferrer'
                    style={{ color: 'black' }}
                    href='https://quanturi.com/pages/terms-of-use'
                    target='_blank'
                  >
                    {i18n.accept_gtcs_3}
                  </a>
                  .
                </div>
              }
            />
          </FormGroup>
          <Typography className={classes.errorMessage} color='error' id='bottom'>
            {errorMessage && errorMessage}
          </Typography>
        </div>
      </DialogContent>
      <div className={classes.buttonsPanel}>
        <DialogActionUtil
          onCancel={close}
          cancelText={i18n.close}
          onSave={onSave}
          disabled={isSubmitting}
          text={i18n.start_now}
        />
      </div>
    </Dialog>
  );
};

export default ExpiredDialog;
