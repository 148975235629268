import { SET_PREVIOUS_UPDATE_TS } from 'redux/constants';
import initialState from 'redux/store/initialState';

export default function setPreviousUpdateTS(state = initialState.previousUpdateTS, action) {
  switch (action.type) {
    case SET_PREVIOUS_UPDATE_TS: {
      return action.value;
    }
    default: {
      return state;
    }
  }
}
