// PUBLIC IMPORTS
import React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';

class QuanturiMiniSVG extends React.Component {
  render() {
    return (
      <div style={this.props.style}>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 108.99 91.11'>
          <g id='Layer_2' data-name='Layer 2'>
            <g id='Layer_1-2' data-name='Layer 1'>
              <path
                d='M109,34.28A57.4,57.4,0,0,0,107,18.42a23.51,23.51,0,0,0-6.1-10.59A24.78,24.78,0,0,0,90,1.87,60.28,60.28,0,0,0,73.79,0L35.05.07A60,60,0,0,0,18.88,2,25,25,0,0,0,8,8,24.15,24.15,0,0,0,1.92,18.59,56,56,0,0,0,0,34.46L0,56.83A56.45,56.45,0,0,0,2,72.76,24,24,0,0,0,8.17,83.41,24.29,24.29,0,0,0,19,89.31a61.73,61.73,0,0,0,16.17,1.8l36-.06a3.67,3.67,0,0,0-.34-.46L60.58,75.71H40.7a45.62,45.62,0,0,1-11.49-1.25A17.45,17.45,0,0,1,21.5,70.4a16.31,16.31,0,0,1-4.37-7.31,37.57,37.57,0,0,1-1.39-10.92V38.36a37.14,37.14,0,0,1,1.39-10.88,16.3,16.3,0,0,1,4.37-7.26,17.81,17.81,0,0,1,7.71-4.1,44.09,44.09,0,0,1,11.49-1.3H68.25a44.71,44.71,0,0,1,11.54,1.3,18,18,0,0,1,7.76,4.1,16,16,0,0,1,4.32,7.26,38.33,38.33,0,0,1,1.34,10.88V52.17q0,10.88-4.06,16.36t-13.5,6.73L85.9,89.43l.45.62c1.34-.25,2.62-.53,3.82-.86a24.46,24.46,0,0,0,10.9-5.93,23.57,23.57,0,0,0,6.06-10.68A58.35,58.35,0,0,0,109,56.65Z'
                style={{ fill: this.props.color }}
              />
              <path
                d='M29.38,51.19A25.26,25.26,0,0,0,30,57a8,8,0,0,0,1.92,3.74,7.39,7.39,0,0,0,3.61,2,24.14,24.14,0,0,0,5.75.58H67.71a24.08,24.08,0,0,0,5.75-.58,7.39,7.39,0,0,0,3.61-2A7.91,7.91,0,0,0,79,57a24.72,24.72,0,0,0,.58-5.84V39.34A24.72,24.72,0,0,0,79,33.5a8,8,0,0,0-1.92-3.74,7.47,7.47,0,0,0-3.61-2,24.08,24.08,0,0,0-5.75-.58H41.24a24.14,24.14,0,0,0-5.75.58,7.47,7.47,0,0,0-3.61,2A8.05,8.05,0,0,0,30,33.5a25.26,25.26,0,0,0-.58,5.84Z'
                style={{ fill: this.props.color }}
              />
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

const styles = theme => ({
  hide: {
    display: 'none'
  }
});

export default withRouter(withWidth()(withStyles(styles)(QuanturiMiniSVG)));
