import {
  Button,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core';
import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import ReactPhoneInput from 'material-ui-phone-number';
import { PHONE_COUNTRIES } from 'redux/constants';
import { COLORS } from 'utils/colors';
import { checkPhoneNumber } from 'assets/utils';

import { getUsernameSMS } from 'api/serverAPI';

import { phoneNumberFormat } from 'utils/sharedHelper';
import { useDialogStyles } from './Login.styled';

const EnterPhoneNumberView = ({
  i18n,
  phoneNumber,
  onCancel,
  handleChange,
  getUsername,
  errorMsg,
  successMsg
}) => {
  const classes = useDialogStyles();

  return (
    <>
      <DialogContent elevation={0}>
        <div className={classes.GetView}>
          <Grid container direction='column' alignItems='center'>
            <Typography className={classes.dialogMessage}>{i18n.enter_phone}</Typography>
            <ReactPhoneInput
              style={{ margin: 10 }}
              value={phoneNumber}
              name='phoneNumber'
              onChange={handleChange}
              autoFocus={!isEmpty(errorMsg)}
              disableAreaCodes
              defaultCountry={i18n.locale === 'en' ? 'gb' : i18n.locale}
              autoFormat='false'
              onlyCountries={PHONE_COUNTRIES}
            />
            <Typography color='error'>{!isEmpty(errorMsg) && errorMsg}</Typography>
            <Typography style={{ color: COLORS.greenJade }}>
              {!isEmpty(successMsg) && successMsg}
            </Typography>
          </Grid>
        </div>
      </DialogContent>

      <DialogActions>
        {isEmpty(successMsg) ? (
          <Button onClick={onCancel} color='primary'>
            {i18n.cancel}
          </Button>
        ) : null}
        <Button onClick={isEmpty(successMsg) ? getUsername : onCancel} color='secondary'>
          {isEmpty(successMsg) ? i18n.submit : i18n.back}
        </Button>
      </DialogActions>
    </>
  );
};

const ForgetUsernameDialog = ({ i18n, isForgetUsernameDialogOpened, toggleGetUsernameDialog }) => {
  const classes = useDialogStyles();

  const [state, setState] = useState({
    phoneNumber: '',
    errorMsg: '',
    successMsg: '',
    isValid: null
  });

  const onCancel = () => {
    toggleGetUsernameDialog();
  };

  const handleChangePhoneNumber = (newNumber, country) => {
    setState({
      ...state,
      phoneNumber: newNumber,
      isValid: checkPhoneNumber(newNumber, country.dialCode)
    });
  };

  const getUsername = async () => {
    if (!state.isValid) {
      setState({
        ...state,
        errorMsg: i18n.phone_error
      });
      return;
    }

    const response = await getUsernameSMS({ phoneNumber: phoneNumberFormat(state.phoneNumber) });
    if (response.status_code === 0) {
      setState({
        ...state,
        successMsg: `${i18n.username_sent} ${state.phoneNumber}`,
        errorMsg: ''
      });
    } else if (response.status_code === 1003) {
      // Number not found
      setState({ ...state, errorMsg: i18n.phone_not_found_msg });
    } else if (response.status_code === 1004) {
      // Number used in more than one account
      setState({ ...state, errorMsg: i18n.phone_multi_account_msg });
    } else if (response.status_code === 1005) {
      // Phone found but not the user
      setState({ ...state, errorMsg: i18n.msg_general_error });
    } else if (response.status_code === 1006) {
      // Get code is incorrect
      setState({ ...state, errorMsg: i18n.phone_not_confirmed_msg });
    }
  };

  return (
    <Dialog
      className={classes.dialog}
      open={isForgetUsernameDialogOpened}
      aria-labelledby='responsive-dialog-title'
    >
      <DialogTitle id='get-username'>{i18n.get_username}</DialogTitle>
      <EnterPhoneNumberView
        i18n={i18n}
        phoneNumber={state.phoneNumber}
        onCancel={onCancel}
        getUsername={getUsername}
        handleChange={handleChangePhoneNumber}
        value={state.phoneNumber}
        errorMsg={state.errorMsg}
        successMsg={state.successMsg}
      />
    </Dialog>
  );
};

export default ForgetUsernameDialog;
