import { AppBar, Tab, Tabs, CircularProgress } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles } from '@material-ui/core/styles';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import AccountPassword from './account-password/AccountPassword';

import AccountUsers from './account-users/AccountUsers';
import AccountServices from './account-services/AccountServices';

const useStyles = makeStyles((theme) => ({
  hide: {
    display: 'none'
  },
  accountMainContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'hidden'
  },
  spinnerContainer: {
    display: 'flex',
    marginTop: 20,
    marginBottom: 20,
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      padding: 0
    }
  },
  accountView: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  }
}));

const TABS = ['users', 'credentials', 'services'];

const Account = () => {
  const dispatch = useDispatch();
  const i18n = useSelector((store) => store.i18n);
  const isLoading = useSelector((store) => store.isLoading);
  const currentView = useSelector((store) => store.currentView);
  const history = useHistory();
  const { pathname } = useLocation();
  const classes = useStyles();

  const [index, setIndex] = useState(0); // Index 0: User - 1: Password - 2: Phones

  useEffect(() => {
    const getTab = () => {
      // Parsing the given route ex: /account/user
      const checkoutPath = pathname.split('/');
      // Subroute "user" is at the last position
      const pathTab = checkoutPath[checkoutPath.length - 1];
      let idx = pathTab === 'account' ? 0 : TABS.findIndex((tab) => tab === pathTab);
      if (checkoutPath.length > 3) {
        idx = 2;
      }
      setIndex(idx);
    };
    if (currentView !== 'account') {
      dispatch({ type: 'TOGGLE_VIEW', value: 'account' });
      dispatch({ type: 'SET_GROUPDETAILS', value: null }); // clear possible storage selection
      getTab();
    }
  });

  // Handle tab access through router
  const handleChange = (event, value) => {
    event.preventDefault();
    setIndex(value);
    history.replace(`/account/${TABS[value]}`);
  };

  // Handle swipeable view index change
  const handleChangeIndex = (idx) => setIndex(idx);

  return (
    <div className={classes.accountMainContainer}>
      <div className={isLoading ? classes.spinnerContainer : classes.hide}>
        <CircularProgress color='primary' size={70} disableShrink />
      </div>
      <AppBar className={isLoading ? classes.hide : ''} position='static' color='default'>
        <Tabs
          value={index}
          onChange={handleChange}
          indicatorColor='primary'
          textColor='primary'
          centered
        >
          <Tab label={i18n.users || 'Users'} />
          <Tab label={i18n.credentials || 'Credentials'} />
          <Tab label={i18n.services || 'Services'} />
        </Tabs>
      </AppBar>

      <SwipeableViews
        disabled
        className={isLoading ? classes.hide : classes.accountView}
        containerStyle={{
          height: '100%',
          transition: 'transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s'
        }}
        index={index}
        onChangeIndex={handleChangeIndex}
      >
        <AccountUsers value={index} />
        <AccountPassword value={index} />
        <AccountServices value={index} />
      </SwipeableViews>
    </div>
  );
};

export default Account;
