import React, { useRef, useState, useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import { Edges } from '@react-three/drei';
import * as THREE from 'three';
import { isEmpty } from 'lodash';
import { COLORS } from 'utils/colors';
import {
  getBalesFootprint,
  getSelectionFootprint,
  getBalesPosition,
  getStackPosition,
  getCellsPosition,
  getCameraDist,
  get3DStorageColumns,
  get3DStorageRows,
  getProbeAppearance
} from './stackHelpers';
import { Controls } from './Controls';
import { StackGrid, ProbeLabel, AlertSpot, PermanentProbeLabel } from './StackGrid';

// Re-using same materials for objects.
// eslint-disable-next-line react/no-unknown-property
const baleAddMaterial = <meshLambertMaterial color='#ffff55' transparent opacity={0.3} />;
// eslint-disable-next-line react/no-unknown-property
const baleMaterial = <meshLambertMaterial color='#ffffff' transparent opacity={0.15} />;
// const baleMaterial = (
//   <meshLambertMaterial side={THREE.DoubleSide} color='#ffffff' transparent opacity={0.1} />
// );
const editableBaleMaterial = (
  // eslint-disable-next-line react/no-unknown-property
  <meshLambertMaterial side={THREE.DoubleSide} color='#ffffff' transparent opacity={0.3} />
);

const hilightBaleMaterial = (
  // eslint-disable-next-line react/no-unknown-property
  <meshLambertMaterial side={THREE.DoubleSide} color={COLORS.yellow} transparent opacity={0.6} />
);

const placeProbeBaleMaterial = (
  // eslint-disable-next-line react/no-unknown-property
  <meshLambertMaterial side={THREE.DoubleSide} color={COLORS.yellow} transparent opacity={0.9} />
);

const removableBaleMaterial = (
  // eslint-disable-next-line react/no-unknown-property
  <meshLambertMaterial side={THREE.DoubleSide} color='#449999' transparent opacity={0.3} />
);

const removeProbeBaleMaterial = (
  // eslint-disable-next-line react/no-unknown-property
  <meshLambertMaterial color={COLORS.yellow} transparent opacity={0} />
);

// eslint-disable-next-line react/no-unknown-property
const baleGeometry = <boxGeometry args={[0.95, 0.95, 0.95]} />;

const Stacks3D = ({
  viewWidth,
  viewHeight,
  selectedStorage,
  stackLayoutString,
  leftPadding,
  selectedRows,
  selectedColumns,
  baleLocationsToAdd, // locations of bales to be added
  bales, // bales already placed
  editingType,
  data,
  showRowLabels,
  showColumnLabels,
  onRowSelectionChanged,
  onColumnSelectionChanged,
  onPileToAddOrRemove,
  pilesToRemove,
  i18n,
  addingProbePosition,
  setAddingProbePosition,
  onRemoveSensor,
  onSensorHover, // hilight sensor in sensor list
  hoveredSensor, // sensor hovered in sensor list
  onSensorClick,
  alertSensorIDs,
  hideLabelsButton,
  qualityData,
  viewMode,
  degrees
}) => {
  // console.log('** Render Stacks3D Q=', qualityData);
  useEffect(
    () =>
      // console.log('Stacks3D useEffect');
      () => {
        // Place for possible clean-up, destroying objects
        console.log('Stacks3D useEffect return');
      },
    []
  );

  const probeAppearances = selectedStorage.stacks[0].sensors.map((probe) =>
    getProbeAppearance(probe, data, viewMode, qualityData)
  );

  const [showLabels, setShowLabels] = useState(false);
  const [showProbeLabels, setShowProbeLabels] = useState(editingType === 'probes');

  /* Tried to listen when controls are active (user is zooming etc)
  to avoid cell hovering while controls are active,
  but it caused re-renders and cumulative setControlsOn calls
  const [controlsInAction, setControlsInAction] = useState(false);
  const setControlsOn = (value) => {
    console.log(`setControlsOn ${value}`);
    if (value !== controlsInAction) {
      setControlsInAction(value);
    }
  };
  */

  const stackIndex = 0;
  const [aStack] = useState({
    rows: get3DStorageRows(selectedStorage, stackIndex),
    cols: get3DStorageColumns(selectedStorage, stackIndex)
  });
  const stackHeight = bales && bales.length > 0 ? Math.max(...bales.map((b) => b.f)) : 0;
  // console.log('FP stack ', aStack);

  const isProbeInteraction =
    editingType === 'probes' && (!isEmpty(selectedRows) || !isEmpty(selectedColumns));

  const onToggleLabels = () => {
    if (editingType === 'bales') {
      setShowLabels((preValue) => !preValue);
    } else {
      setShowProbeLabels((preValue) => !preValue);
    }
  };
  const HiglightCell = (props) => (
    // eslint-disable-next-line react/no-unknown-property
    <mesh {...props}>
      <planeGeometry
        // eslint-disable-next-line react/no-unknown-property
        args={[1, 1]}
      />
      <meshLambertMaterial
        // color={editingType === 'probes' ? '#ffffff' : '#6EBDBB'}
        color='#ffffff'
        // eslint-disable-next-line react/no-unknown-property
        transparent
        opacity={0.2}
      />
    </mesh>
  );

  const HihglightedCells = () =>
    (editingType === 'probes'
      ? getBalesFootprint(bales)
      : getSelectionFootprint(selectedStorage, selectedRows, selectedColumns)
    ).map((cell, i) => (
      <HiglightCell key={i} rotation={[-Math.PI / 2, 0, 0]} position={[cell.col, 0.02, cell.row]} />
    ));

  const BaleToAdd = (props) => (
    <mesh {...props}>
      {baleGeometry}
      {baleAddMaterial}
    </mesh>
  );

  // probe: { column: 8,floor: 1, id: "005361", row: 3,data:... }

  // In editingType 'probes', show only selected column or row
  const Bales = () =>
    bales
      .filter((bale) =>
        editingType === 'bales'
          ? true
          : (isEmpty(selectedRows) && isEmpty(selectedColumns)) ||
            selectedRows.includes(bale.r) ||
            selectedColumns.includes(bale.c)
      )
      .map((bale, i) => {
        const probe = selectedStorage.stacks[0].sensors.find(
          (p) => p.column === bale.c && p.row === bale.r && p.floor === bale.f
        );
        return (
          <Bale
            key={i}
            probe={probe}
            bid={bale.id}
            bat={bale.bat}
            position={[bale.c, bale.f, bale.r]}
            removable={pilesToRemove.find((pile) => pile.col === bale.c && pile.row === bale.r)}
          />
        );
      });

  const Probe = (props) => {
    const probeRef = useRef();
    const [probeHover, setProbeHover] = useState(false);
    const { probe, position, hover } = props;
    const [biggerCube, setBiggerCube] = useState(hoveredSensor === probe.id);
    const { tempColor, size, valueWarning, valueAlert } = probeAppearances.find(
      (app) => probe.id === app.id
    );
    // eslint-disable-next-line react/no-unknown-property
    return (
      <mesh
        ref={probeRef}
        position={position}
        onClick={(e) => {
          if (!isProbeInteraction && editingType === 'probes') {
            onRemoveSensor(e, probe.id);
          } else if (!editingType) {
            // Clicked probe in status view
            // alert(`Info: probe ID: ${probe.id}\nBale ID: ${bid}\nBatch ID:${bat}`);
            if (onSensorClick) {
              onSensorClick(probe.id);
            }
          }
        }}
        onPointerOver={(e) => {
          e.stopPropagation(); // prevent hovering on multiple bales
          if (!editingType || editingType === 'probes') {
            document.body.style.cursor = !editingType ? 'auto' : 'pointer';
            setBiggerCube(true);
            setProbeHover(true);
            if (onSensorHover) {
              onSensorHover(probe.id);
            }
          }
        }}
        onPointerOut={() => {
          document.body.style.cursor = 'auto';
          setProbeHover(false);
          setBiggerCube(false);
          if (onSensorHover) {
            onSensorHover(null);
          }
        }}
      >
        <boxGeometry
          // eslint-disable-next-line react/no-unknown-property
          args={
            biggerCube
              ? [Math.max(0.3, size * 1.2), Math.max(0.3, size * 1.2), Math.max(0.3, size * 1.2)]
              : [size, size, size]
          }
        />
        <meshLambertMaterial
          color={
            !qualityData && (hover || probeHover || hoveredSensor === probe.id)
              ? COLORS.yellow
              : tempColor
          }
        />
        {/* {probeHover ? (
          <ProbeLabel alert={alertSensorIDs?.includes(probe.id)} id={probe.id} />
        ) : null} */}
        {alertSensorIDs?.includes(probe.id) ? (
          <AlertSpot sid={probe.id} onSensorClick={onSensorClick} />
        ) : null}
        {qualityData && valueWarning && (
          <AlertSpot onSensorClick={onSensorClick} type='valueWarning' sid={probe.id} />
        )}
        {qualityData && valueAlert && (
          <AlertSpot onSensorClick={onSensorClick} type='valueAlert' sid={probe.id} />
        )}
        {probeHover ? (
          <ProbeLabel
            id={probe.id}
            mode={viewMode}
            data={data.find((d) => d?.sensor_id === probe.id)}
            i18n={i18n}
            degrees={degrees}
          />
        ) : null}
        {showProbeLabels ? <PermanentProbeLabel id={probe.id} /> : null}
        {qualityData && <Edges scale={1} color={tempColor} />}
      </mesh>
    );
  };

  const Bale = (props) => {
    const { probe, position, bat, bid, removable } = props;
    const [hover, setHover] = useState(false);
    const placingProbeHere =
      addingProbePosition &&
      addingProbePosition.r === position[2] &&
      addingProbePosition.c === position[0] &&
      addingProbePosition.f === position[1];
    const baleRef = useRef();
    if (isProbeInteraction) {
      if (probe) {
        return (
          <group>
            <Probe
              hover={!addingProbePosition && hover}
              probe={probe}
              bat={bat}
              bid={bid}
              position={position}
            />
            <mesh
              ref={baleRef}
              {...props}
              onPointerOver={(e) => {
                if (addingProbePosition) {
                  return;
                }
                e.stopPropagation();
                setHover(true);
                document.body.style.cursor = 'pointer';
              }}
              onPointerOut={(e) => {
                if (addingProbePosition) {
                  document.body.style.cursor = 'auto';
                  return;
                }
                e.stopPropagation();
                setHover(false);
                document.body.style.cursor = 'auto';
              }}
              onClick={(e) => {
                e.stopPropagation(); // prevent event on multiple bales
                if (!addingProbePosition) {
                  onRemoveSensor(e, probe.id);
                }
              }}
            >
              {baleGeometry}
              {isProbeInteraction
                ? !addingProbePosition && hover
                  ? removeProbeBaleMaterial
                  : editableBaleMaterial
                : baleMaterial}
            </mesh>
          </group>
        );
      }
      return (
        <mesh
          ref={baleRef}
          {...props}
          onPointerOver={(e) => {
            e.stopPropagation(); // prevent hovering on multiple bales
            if (addingProbePosition) {
              if (placingProbeHere) {
                document.body.style.cursor = 'pointer';
              }
              return;
            }
            setHover(true);
            document.body.style.cursor = 'pointer';
          }}
          onPointerOut={() => {
            if (addingProbePosition) {
              document.body.style.cursor = 'auto';
              return;
            }
            setHover(false);
            document.body.style.cursor = 'auto';
          }}
          onClick={(e) => {
            e.stopPropagation(); // prevent event on multiple bales
            if (editingType === 'probes') {
              if (addingProbePosition) {
                // deselect selected bale
                if (placingProbeHere) {
                  setAddingProbePosition(null);
                }
                return;
              }
              setAddingProbePosition({ r: position[2], c: position[0], f: position[1] });
              setHover(false);
            }
          }}
        >
          {baleGeometry}
          {isProbeInteraction
            ? (addingProbePosition ? placingProbeHere : hover)
              ? placingProbeHere
                ? placeProbeBaleMaterial
                : hilightBaleMaterial
              : editableBaleMaterial
            : baleMaterial}
        </mesh>
      );
    }
    if (probe) {
      return (
        <group>
          <Probe probe={probe} bat={bat} bid={bid} position={position} />
          <mesh {...props}>
            {baleGeometry}
            {isProbeInteraction
              ? editableBaleMaterial
              : removable
              ? removableBaleMaterial
              : baleMaterial}
          </mesh>
        </group>
      );
    }
    return (
      <mesh {...props}>
        {baleGeometry}
        {isProbeInteraction
          ? editableBaleMaterial
          : removable
          ? removableBaleMaterial
          : baleMaterial}
      </mesh>
    );
  };

  const BalesToAdd = () =>
    !isEmpty(pilesToRemove)
      ? []
      : baleLocationsToAdd.map((bale, i) => (
          <BaleToAdd key={i} position={[bale.col, bale.floor, bale.row]} />
        ));

  const Floor = (props) => {
    const { stack } = props;
    return (
      // eslint-disable-next-line react/no-unknown-property
      <mesh {...props} position={[-0.4, 0, 0.4]} rotation={[-Math.PI / 2, 0, 0]}>
        <planeGeometry
          // eslint-disable-next-line react/no-unknown-property
          args={[stack.nColumns + 1.75, stack.nRows + 1.75]}
        />
        <meshBasicMaterial color={COLORS.darkGray} />
      </mesh>
    );
  };

  // eslint-disable-next-line react/no-unknown-property
  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          width: viewWidth,
          height: viewHeight,
          // minWidth: 400, // Horizontal scrolling in mobile view
          // overflowX: 'auto',
          backgroundColor: COLORS.darkGray,
          position: 'absolute',
          left: leftPadding,
          zIndex: 99
        }}
      >
        <Canvas
          style={{ backgroundColor: COLORS.primaryColor }}
          camera={{
            fov: 50,
            position: [-aStack.rows / 2 - 5, 5 + stackHeight, getCameraDist(selectedStorage)]
          }}
          mode='concurrent' // reducing CPU use, see https://docs.pmnd.rs/react-three-fiber/advanced/pitfalls
          frameloop='demand' // reducing CPU use
        >
          <Controls />
          {/* <Controls setControlsOn={setControlsOn} /> */}
          {/* <gridHelper args={[10, 10]} /> */}

          {/* <pointLight intensity={0.2} position={[-10, 15, 10]} /> */}

          <Floor stack={selectedStorage.stacks[stackIndex]} />
          {/* <hemisphereLight intensity={0.2} /> */}
          <hemisphereLight
            // eslint-disable-next-line react/no-unknown-property
            groundColor={0x080808}
            // eslint-disable-next-line react/no-unknown-property
            intensity={0.5}
          />
          {/* <hemisphereLight intensity={0.2} /> */}

          {/* <spotLight position={[-10, 10, -10]} angle={2} /> */}
          {/* <Box position={[0, 1, 0]} /> */}
          <group position={getBalesPosition(selectedStorage, aStack, stackLayoutString)}>
            <Bales />
          </group>
          <group position={getBalesPosition(selectedStorage, aStack, stackLayoutString)}>
            <BalesToAdd />
          </group>
          <group position={getCellsPosition(selectedStorage, aStack, stackLayoutString)}>
            <HihglightedCells />
          </group>

          <StackGrid
            stack={selectedStorage.stacks[stackIndex]}
            onToggleLabels={onToggleLabels}
            showRowLabels={editingType === 'probes' || (showLabels && showRowLabels)}
            showColumnLabels={editingType === 'probes' || (showLabels && showColumnLabels)}
            showProbeLabels={showProbeLabels}
            showLabelsButton={editingType === 'bales' || !hideLabelsButton}
            baleRows={editingType === 'probes' && bales.map((b) => b.r)}
            baleCols={editingType === 'probes' && bales.map((b) => b.c)}
            onRowSelectionChanged={onRowSelectionChanged}
            onColumnSelectionChanged={onColumnSelectionChanged}
            selectedRows={selectedRows}
            selectedColumns={selectedColumns}
            position={getStackPosition(selectedStorage, aStack, stackLayoutString)}
            pilePositions={editingType === 'bales' && getBalesFootprint(bales)}
            baleLocationsToAdd={baleLocationsToAdd}
            pilesToRemove={pilesToRemove}
            onPileToAddOrRemove={onPileToAddOrRemove}
            i18n={i18n}
            // controlsOn={controlsInAction}
          />
        </Canvas>
      </div>
    </div>
  );
};

export default Stacks3D;
