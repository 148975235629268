import React from 'react';
import { range, first, last } from 'lodash';
import { format, startOfWeek, differenceInHours, startOfDay, endOfDay, sub, add } from 'date-fns';
import { DAY_IN_MILLISECONDS } from 'redux/constants';
import { getGradientColor } from 'assets/utils';
import { Spinner } from 'utils/Spinners';

export const checkProbeType = ({ dataCheck, singlePointOnly, showHandle }) => {
  // singlePointOnly: ensure Compost data chart shows only singlepoint
  if (showHandle) {
    if (dataCheck?.hh && ((!dataCheck.n && dataCheck.hh.length >= 2) || dataCheck.n > 1)) {
      return singlePointOnly ? 'singlepoint-handle' : 'multipoint';
    }
  }
  if (dataCheck?.hh && ((!dataCheck.n && dataCheck.hh.length > 2) || dataCheck.n > 1)) {
    return singlePointOnly ? 'singlepoint-handle' : 'multipoint';
  }

  if (dataCheck?.hh?.length === 2 || dataCheck?.n === 1) {
    return 'singlepoint-handle';
  }
  return 'singlepoint';
};

export const getDataForChart = (
  min,
  max,
  data,
  minValue,
  maxValue,
  isSnapShot,
  sensorData,
  singlePointOnly
) => {
  let curveMin;
  let curveMax;
  if (isSnapShot) {
    curveMin = Math.min(...data.filter((point) => point.y !== null).map((point) => point.y));
    curveMax = Math.max(...data.filter((point) => point.y !== null).map((point) => point.y));
  } else {
    curveMin = Math.min(...data.filter((point) => point.y !== null).map((point) => point.y));
    curveMax = Math.max(...data.filter((point) => point.y !== null).map((point) => point.y));
  }

  const minValueTemp =
    min === -Infinity ? minValue : Math.floor(curveMin) <= min ? Math.floor(curveMin / 5) * 5 : min;
  const maxValueTemp =
    max === Infinity ? maxValue : Math.ceil(curveMax) >= max ? Math.ceil(curveMax / 5) * 5 : max;

  const customRange = range(minValueTemp, maxValueTemp + 1, 10);

  let domain = [];
  let rangeDays = [];
  let rangeWeeks = [];
  let rangeMonths = [];
  let rangeBiMonths = [];
  let rangeTime = [];
  let rangeLabel = false;
  let rangeFormat = '';
  let reference = [];
  let referenceLabel = false;
  let referenceFormat = '';
  let tooltipFormat = '';

  tooltipFormat = isSnapShot ? 'MMM d' : 'MMM d HH:mm';

  domain = [first(data).x, last(data).x];
  rangeDays = range(domain[0], domain[1], DAY_IN_MILLISECONDS);
  rangeWeeks = range(
    +startOfWeek(domain[0], { weekStartsOn: 1 }),
    domain[1],
    7 * DAY_IN_MILLISECONDS
  );
  rangeMonths = rangeWeeks.filter((time) => +format(time, 'dd') <= 7);
  rangeBiMonths = rangeMonths.filter((time) => +format(time, 'M') % 2 === 0);
  let days;
  let firstDay;
  let dayStarts;
  let probeType;

  let todayDataHours = 24;
  let dailyData;
  const maxValues = [];
  const minValues = [];
  const dataMiddle = [];
  let maxTooltipValues = [];
  const tooltipValues = [];

  if (!isSnapShot) {
    days = Math.max(
      0,
      Math.ceil((differenceInHours(endOfDay(domain[1]), startOfDay(domain[0])) + 24) / 24)
    );

    firstDay = sub(domain[0], { days: 2 });
    dayStarts = Array.from(Array(days).keys()).map((i) =>
      startOfDay(add(firstDay, { days: i + 1 }))
    );

    probeType = checkProbeType({ dataCheck: sensorData[0] || [], singlePointOnly });
    // console.log('probeType ', probeType, singlePointOnly);
    let ready = false;

    for (let i = 0; i < dayStarts.length; i++) {
      dailyData = sensorData?.filter(
        (meas) =>
          Number(meas.b) * 1000 >= +new Date(dayStarts[i]) &&
          (i === dayStarts.length - 1 || Number(meas.b) * 1000 < +new Date(dayStarts[i + 1]))
      );
      if (!dailyData || !days || !firstDay || !dayStarts) {
        return <Spinner />;
      }
      if (
        dailyData &&
        dailyData[0] &&
        +startOfDay(new Date(dailyData[0].b * 1000)) === +startOfDay(new Date())
      ) {
        const lastTS = Math.max(...dailyData.map((d) => d.b));
        if (lastTS) {
          todayDataHours = Math.min(24, +format(lastTS * 1000, 'H') + 1);
        }
      }
      if (dailyData.length > 0) {
        const time = +add(dayStarts[i], { hours: todayDataHours });
        let maxx;
        let minn;
        if (probeType === 'multipoint') {
          const temps = dailyData
            .map((d) => d.hh)
            .filter(Boolean)
            .map((t) => t.slice(1))
            .flat()
            .filter(Boolean);
          maxx = Math.max(...temps);
          minn = Math.min(...temps);
        } else if (probeType === 'singlepoint-handle') {
          const temps = dailyData
            .map((d) => d.hh)
            .filter(Boolean)
            .map((d) => d[1])
            .flat();

          maxx = Math.max(...temps);
          minn = Math.min(...temps);
        } else {
          const temps = dailyData.map((d) => d.h).flat();
          dailyData.map((d) => d.h).flat();
          maxx = Math.max(...temps);
          minn = Math.min(...temps);
        }
        maxValues.push({
          x: time,
          y: maxx
        });
        minValues.push({
          x: time,
          y: minn
        });
        dataMiddle.push({
          x: time,
          y: minn,
          y0: maxx
        });
      }
      if (i === dayStarts.length - 1) {
        ready = true;
      }
    }

    if (ready) {
      maxTooltipValues = data.filter((point) => point.y !== null);
      maxTooltipValues.forEach((val) => {
        if (val.x > maxValues[0]?.x) {
          const closestTimestamp = maxValues
            .map((t) => t.x)
            .reverse()
            .find((e) => e < val.x);

          const T1Index = maxValues.findIndex((v) => v.x === closestTimestamp);
          const T2Index = T1Index + 1;
          const T1 = maxValues[T1Index].y;
          const T2 = maxValues[T2Index]
            ? maxValues[T2Index].y
            : maxTooltipValues[maxTooltipValues.length - 1].y;
          const H1 = maxValues[T1Index].x;
          const H2 = maxValues[T2Index]
            ? maxValues[T2Index].x
            : maxTooltipValues[maxTooltipValues.length - 1].x;

          const h = (val.x - maxValues[T1Index].x) / 1000 / 60 / 60;
          const h24 = (H2 - H1) / 1000 / 60 / 60;

          const finalFormula = T1 + (h / h24) * (T2 - T1);
          tooltipValues.push({
            x: val.x,
            y: finalFormula
          });
        }
      });
    }
  }
  const minCurveMin = Math.min(...minValues?.map((t) => t.y)) || null;
  const minCurveMax = Math.max(...minValues?.map((t) => t.y)) || null;
  const maxCurveMin = Math.min(...maxValues?.map((t) => t.y)) || null;
  const maxCurveMax = Math.max(...maxValues?.map((t) => t.y)) || null;

  if (document.getElementById('chartMainContainer').offsetWidth / rangeWeeks.length > 70) {
    // Medium tick spacing -> Add daily ticks with weeks labels (bold)
    reference = rangeWeeks;
    referenceFormat = 'MMM d';
    referenceLabel = true;
    rangeTime = rangeDays;

    if (document.getElementById('chartMainContainer').offsetWidth / rangeDays.length > 70) {
      // Large / XL tick spacing -> Add all days labels
      rangeTime = rangeDays;
      rangeFormat = 'MMM d';
      rangeLabel = true;
    }
  } else if (document.getElementById('chartMainContainer').offsetWidth / rangeWeeks.length > 20) {
    // Small spacing -> Add weekly ticks with months labels (bold)
    reference = rangeMonths;
    referenceFormat = 'MMMM';
    referenceLabel = true;
    rangeTime = rangeWeeks;
  } else {
    // XS spacing -> Add monthly ticks with bimensual periods labels (bold)
    reference = rangeBiMonths;
    referenceFormat = 'MMM';
    referenceLabel = true;
    rangeTime = rangeMonths;
  }

  return {
    minValue: minValueTemp,
    maxValue: maxValueTemp,
    curveMin,
    curveMax,
    customRange,
    domain,
    rangeTime,
    rangeLabel,
    rangeFormat,
    reference,
    referenceLabel,
    referenceFormat,
    tooltipFormat,
    dayStarts,
    minCurveMin,
    minCurveMax,
    maxCurveMin,
    maxCurveMax,
    maxValues,
    minValues,
    dataMiddle,
    tooltipValues,
    probeType
  };
};

export const linearGradient = (curveMin, curveMax, i) => (
  <linearGradient id={i ? `colorGradient-${i}` : 'colorGradient'} x1='0%' y1='0%' x2='0%' y2='100%'>
    <stop offset='0%' stopColor={getGradientColor(curveMax)} />
    <stop offset='10%' stopColor={getGradientColor(curveMin + 0.9 * (curveMax - curveMin))} />
    <stop offset='20%' stopColor={getGradientColor(curveMin + 0.8 * (curveMax - curveMin))} />
    <stop offset='30%' stopColor={getGradientColor(curveMin + 0.7 * (curveMax - curveMin))} />
    <stop offset='40%' stopColor={getGradientColor(curveMin + 0.6 * (curveMax - curveMin))} />
    <stop offset='50%' stopColor={getGradientColor(curveMin + 0.5 * (curveMax - curveMin))} />
    <stop offset='60%' stopColor={getGradientColor(curveMin + 0.4 * (curveMax - curveMin))} />
    <stop offset='70%' stopColor={getGradientColor(curveMin + 0.3 * (curveMax - curveMin))} />
    <stop offset='80%' stopColor={getGradientColor(curveMin + 0.2 * (curveMax - curveMin))} />
    <stop offset='90%' stopColor={getGradientColor(curveMin + 0.1 * (curveMax - curveMin))} />
    <stop offset='100%' stopColor={getGradientColor(curveMin)} />
  </linearGradient>
);
