import DefaultStorage from './defaultStorage';
import QuanturiStorage from './quanturiStorage';

export const checkLocalStorageSupport = () => {
  const testKey = 'test';
  try {
    window.localStorage.setItem(testKey, '1');
    if (window.localStorage.getItem(testKey) !== '1') {
      return false;
    }
    window.localStorage.removeItem(testKey);
    return true;
  } catch (error) {
    return false;
  }
};

export const initStorage = () => {
  if (checkLocalStorageSupport()) {
    return new DefaultStorage();
  }
  return new QuanturiStorage();
};

export const storage = initStorage();
