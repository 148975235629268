import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Tooltip,
  IconButton
} from '@material-ui/core';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { InlineIcon } from '@iconify/react';
import eyeOff from '@iconify/icons-mdi/eye-off';
import eyeSettings from '@iconify/icons-mdi/eye-settings';

import { useSelector, useDispatch } from 'react-redux';

// import { MonitorMonitorStyle, listItemStatusBarStyle } from 'utils/sharedStyles';
import { storageMonitorsStyles } from 'utils/sharedStyles';
import { StorageContext } from '../../StorageContext';
import MonitorEditor from '../monitor-editor/MonitorEditor';
import StorageMonitorRules from './StorageMonitorRules';
import StorageMonitorAlerts from './StorageMonitorAlerts';

const StorageMonitors = ({
  selectedStorage,
  setSelectedStorage,
  setIsStorageEdited,
  listMonitors,
  setMonitors,
  createMonitor,
  setCreateMonitor,
  setMonitorsChanges,
  selectedMonitor,
  setSelectedMonitor,
  storages,
  setStoragesChanges
}) => {
  const { state } = useContext(StorageContext);
  const dispatch = useDispatch();
  const listMonitorsRef = useRef(listMonitors);

  const classes = storageMonitorsStyles();
  const i18n = useSelector((store) => store.i18n);
  const userInfo = useSelector((store) => store.userInfo);
  const FcStatus = useSelector((store) => store.FcStatus);
  const FC_IDS = useSelector((store) => store.userInfo?.FC_IDs);

  const monitorHandler = (e) => {
    if (e.target.value === '+1') {
      setCreateMonitor(true);
      setSelectedMonitor(null);
    } else {
      setSelectedStorage((tempStorage) => ({
        ...tempStorage,
        monitor: listMonitors.find((mon) => mon.itemID === e.target.value) || null
      }));
      setIsStorageEdited(true);
      dispatch({ type: 'SET_EDIT_CONFIRM_NEEDED', value: true });
    }
  };

  const [monitorLength, setMonitorLength] = useState(listMonitors.length);

  useEffect(() => {
    if (monitorLength !== listMonitors.length) {
      const newMonitorIndex = Math.max(...listMonitors.map((p) => +p.itemID));
      // console.log('newMonitorIndex', newMonitorIndex);
      setSelectedStorage((tempStorage) => ({
        ...tempStorage,
        monitor: listMonitors.find((mon) => +mon.itemID === newMonitorIndex) || null
      }));
      setIsStorageEdited(true);
      dispatch({ type: 'SET_EDIT_CONFIRM_NEEDED', value: true });
      setMonitorLength(listMonitors.length);
    } else if (listMonitorsRef.current !== listMonitors) {
      const ID = +selectedStorage.monitor.itemID;
      setSelectedStorage((tempStorage) => ({
        ...tempStorage,
        monitor: listMonitors.find((mon) => +mon.itemID === ID) || null
      }));
      listMonitorsRef.current = listMonitors;
    }
  }, [
    dispatch,
    listMonitors,
    monitorLength,
    selectedStorage,
    setIsStorageEdited,
    setSelectedStorage
  ]);

  const [editingFromStorageMonitors, setEditingFromStorageMonitors] = useState(false);

  const goToEditorHandler = () => {
    setCreateMonitor(true);
    setSelectedMonitor(listMonitors.find((mon) => mon.itemID === selectedStorage?.monitor.itemID));
    setEditingFromStorageMonitors(true);
  };

  // in state of each sensor_id, we must reset the element [depth-1] of
  // TP, alerts, prevData and alertTSs arrays to null
  const resetRuleState = (sensorState, sensorID, depth) => {
    // console.log('sensorState', sensorState);
    // console.log(`resetRuleState on depth ${depth}: "${sensorID}" [${depth - 1}]`);
    const newState = {
      ...sensorState,
      TP: sensorState.TP.map((tp, i) => (i === depth - 1 ? null : tp)),
      alertTSs: sensorState.alertTSs.map((ts, i) => (i === depth - 1 ? null : ts)),
      alerts: sensorState.TP.map((al, i) => (i === depth - 1 ? null : al)),
      prevData: sensorState.prevData.map((al, i) => (i === depth - 1 ? null : al))
    };
    // console.log('newState', newState);
    return newState;
  };
  const resetAlertHandler = (changedRule, resetAlerts) => {
    // console.log('reset', changedRule, resetAlerts);
    // console.log('selectedStorage', selectedStorage);
    // Remove resetAlerts from currentAlerts
    const resetsRemoved = changedRule.currentAlerts.filter(
      (a) =>
        !resetAlerts.find(
          (res) => res.sensor_id === a.sensor_id && res.depth === a.depth && res.ts === a.ts
        )
    );

    // in state of each sensor_id, we must reset the element [depth-1] of TP, alerts and alertTSs arrays to null
    let ruleState = changedRule.state;
    // console.log('ruleState', ruleState);
    resetAlerts.map((res) => {
      const newSensorDepthState = resetRuleState(
        ruleState.find((st) => st.sensor_id === res.sensor_id),
        res.sensor_id,
        res.depth
      );
      ruleState = ruleState.map((st) =>
        st.sensor_id === res.sensor_id ? newSensorDepthState : st
      );
      return ruleState;
    });
    // console.log('ruleState after', ruleState);

    const newRule = {
      ...changedRule,
      currentAlerts: resetsRemoved,
      state: ruleState
    };

    // console.log('newRule', newRule);
    // Replace changed rule in storage monitor
    const changedStorage = {
      ...selectedStorage,
      monitor: {
        ...selectedStorage.monitor,
        rules: selectedStorage.monitor.rules.map((rule) =>
          rule.id === changedRule.id ? newRule : rule
        )
      }
    };
    // console.log('changedStorage', changedStorage);
    setSelectedStorage(changedStorage);
    setIsStorageEdited(true);
    dispatch({ type: 'SET_EDIT_CONFIRM_NEEDED', value: true });
  };

  return (
    <div className={classes.detailsContent}>
      <div className={classes.monitorSelector}>
        <div />
        <div>
          <Tooltip
            title={i18n.monitor_settings || 'Monitor settings'}
            arrow
            placement='left'
            disableFocusListener
          >
            <IconButton style={{ marginTop: 10 }} onClick={goToEditorHandler}>
              <InlineIcon icon={eyeSettings} width={20} height={20} />
            </IconButton>
          </Tooltip>
          <FormControl className={classes.formControl}>
            {/* <Inline onClick={goToEditorHandler}>
            <eyeSettings />
          </IconButton> */}

            <InputLabel id='demo-simple-select-label'>
              {selectedStorage?.monitor ? i18n.monitor : i18n.select_monitor || 'Select monitor'}
            </InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={selectedStorage?.monitor?.itemID || ''}
              onChange={monitorHandler}
            >
              <MenuItem value=''>
                <em>{selectedStorage?.monitor ? i18n.no_monitor : i18n.select_monitor}</em>
              </MenuItem>

              {/* <MenuItem value='0'>Select monitor</MenuItem> */}
              {listMonitors &&
                listMonitors
                  .filter((m) => !m.compost)
                  .map((monitor) => {
                    const disabled =
                      monitor?.rules
                        .map((rule) => rule?.settings?.FC_ID)
                        .map((FC) =>
                          FcStatus.find(
                            (ID) =>
                              ID?.FC_ID === FC &&
                              !!ID?.inUse &&
                              ID?.inUse !== selectedStorage?.itemID
                          )
                        )
                        .filter(Boolean).length > 0;

                    return (
                      <MenuItem key={monitor.itemID} value={monitor.itemID} disabled={disabled}>
                        {monitor.name}
                      </MenuItem>
                    );
                  })}
              <MenuItem value='+1'>{i18n.add_monitor || 'Add monitor...'}</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      {selectedStorage.monitor ? (
        <>
          <div>
            <Typography variant='h6' className={classes.titleCard}>
              {i18n.monitor_status || 'Monitor Status'}
            </Typography>
            <StorageMonitorRules
              state={state}
              i18n={i18n}
              selectedStorage={selectedStorage}
              classes={classes}
              resetAlertHandler={resetAlertHandler}
            />
          </div>
          <div>
            <Typography variant='h6' className={classes.titleCard}>
              {i18n.alert_recipients || 'Alert recipients'}
            </Typography>
            <StorageMonitorAlerts
              i18n={i18n}
              selectedStorage={selectedStorage}
              setSelectedStorage={setSelectedStorage}
              userInfo={userInfo}
              setIsStorageEdited={setIsStorageEdited}
              classes={classes}
            />
          </div>
        </>
      ) : (
        <div className={classes.noMonitor}>
          <p>{i18n.no_monitor_assigned || 'No monitor assigned'}</p>
          <InlineIcon style={{ padding: '0 5px' }} height='10em' width='10em' icon={eyeOff} />
        </div>
      )}
      {createMonitor && (
        <MonitorEditor
          // closeMonitorCreate={closeMonitorCreate}
          // onClose={() => closeMonitorCreate()}
          storages={storages}
          createMonitor={createMonitor}
          setCreateMonitor={setCreateMonitor}
          userInfo={userInfo}
          setMonitorsChanges={setMonitorsChanges}
          selectedMonitor={selectedMonitor}
          setSelectedMonitor={setSelectedMonitor}
          fromStorageMonitors
          editingFromStorageMonitors={editingFromStorageMonitors}
          setEditingFromStorageMonitors={setEditingFromStorageMonitors}
          selectedStorage={selectedStorage}
          FC_IDS={FC_IDS}
          FcStatus={FcStatus}
          setStoragesChanges={setStoragesChanges}
          monitors={listMonitors}
          setMonitors={setMonitors}
        />
      )}
    </div>
  );
};

export default StorageMonitors;
