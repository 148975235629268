// PUBLIC IMPORTS
import { Typography, Tooltip } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { format, isToday } from 'date-fns';

import { useSelector, useDispatch } from 'react-redux';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { InlineIcon } from '@iconify/react';
import thermometerChevronUp from '@iconify/icons-mdi/thermometer-chevron-up';
import handRight from '@iconify/icons-mdi/hand-right';

import { range } from 'lodash';
import {
  VictoryChart,
  VictoryLine,
  VictoryScatter,
  VictoryAxis,
  VictoryTooltip,
  VictoryVoronoiContainer,
  VictoryArea
} from 'victory';
import localeFormat from 'utils/localeFormat';
import { getGradientColor, isNil, tempFormat } from 'assets/utils';

import { COLORS } from 'utils/colors';
import { useStyles, chartStyles } from './SensorChartType.styled';
import CustomTooltip from './CustomTooltip';
import SpecialSensorTooltip from './SpecialSensorTooltip';
import { getDataForChart, linearGradient } from './helpers';

const NoData = ({ i18n, classes }) => (
  <div className={classes.noDataDiv}>
    <Typography variant='h5' className={classes.noDataTypo}>
      {i18n.no_data}
    </Typography>
  </div>
);

const initialState = {
  dataValue: [{ x: 0, y: -1000 }],
  minValue: 20,
  maxValue: 60,
  curveMin: 20,
  curveMax: 60,
  customRange: range(20, 61, 10),
  domain: [0, 1],
  rangeTime: [0],
  rangeLabel: false,
  rangeFormat: '',
  reference: [],
  referenceLabel: false,
  referenceFormat: '',
  tooltipFormat: ''
};

export default function SensorTemperatureChart({
  yMin,
  yMax,
  data,
  isSnapShot,
  sensorData,
  i18n,
  degrees,
  dateFormat,
  timeFormat,
  probeMoistData
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = useState(initialState);
  const isAdmin = useSelector((store) => store.isAdmin);
  const isSupervisorCompost = useSelector((store) => store.isSupervisorCompost);
  const showHandleLine = useSelector((store) => store.showHandle);
  const handleTempEnabled = useSelector(
    (store) => store.sessionInfo?.ui_preferences?.handle_temp_enabled === 'true'
  );
  const auxProbe = sensorData && sensorData[0]?.aa;

  const [selectedDepth, setSelectedDepth] = useState('');
  const handleData =
    state.dayStarts &&
    sensorData &&
    sensorData
      .filter((d) => !!d.hh)
      .filter((sensor) => sensor.b * 1000 >= +state.dayStarts[1])
      .map((sensor) => ({ x: sensor.b * 1000, y: sensor.hh[0] }));

  const [depthData, setDepthData] = useState([]);

  useEffect(() => {
    if (!data[1]) {
      setState((tempState) => ({ ...tempState, domain: [0, 1] }));
    }
    setState((tempState) => ({
      ...tempState,
      dataValue: data,
      ...getDataForChart(yMin, yMax, data, 250, 400, isSnapShot, sensorData, isSupervisorCompost)
    }));
  }, [data, isSnapShot, yMin, yMax, sensorData, isSupervisorCompost]);

  const gradientMax = linearGradient(state.maxCurveMin, state.maxCurveMax, 'max');
  const gradientMin = linearGradient(state.minCurveMin, state.minCurveMax, 'min');
  const gradientSnapShot = linearGradient(state.curveMin, state.curveMax, 'snapshot');

  const getTickFormat = (value) => {
    try {
      return (
        (state.rangeLabel &&
          format(new Date(value), state.rangeFormat, { locale: localeFormat(i18n.locale) })) ||
        (state.reference.findIndex((time) => time === value) !== -1
          ? format(new Date(value), state.referenceFormat, { locale: localeFormat(i18n.locale) })
          : '')
      );
    } catch (e) {
      console.log(`getTickFormat error: ${e.message}, value=`, value);
      return '';
    }
  };

  const handleChangeDepth = (event, newDepth) => {
    if (newDepth === 0) {
      dispatch({ type: 'SET_SHOW_HANDLE', value: !showHandleLine });
    } else if (newDepth) {
      setSelectedDepth(newDepth);
      setDepthData(
        sensorData
          .filter((d) => !!d.hh)
          .filter((a, i) => (i > 0 ? a.b - sensorData[i - 1].b > 60 : true)) // filter duplicates
          .filter((sensor) => sensor.b * 1000 >= +state.dayStarts[1])
          .map((sensor) => ({ x: sensor.b * 1000, y: sensor.hh[newDepth] }))
      );
    } else {
      setSelectedDepth('');
      setDepthData([]);
    }
  };

  const blacklist =
    selectedDepth !== ''
      ? ['Max Values', 'Min Values', 'Tooltip Values', 'Fill area', 'Handle Values']
      : ['Max Values', 'Min Values', 'Fill area', 'Handle Values'];

  let maxTempsByDepth = [];
  // Visualize max temp in the current time range on each depth
  if (sensorData && sensorData.length > 0) {
    const multipointData = sensorData.filter((d) => !!d.hh);
    if (multipointData.length > 0) {
      const nMax = Math.max(...multipointData.map((d) => d.hh.length));
      // console.log('sensorData ', sensorData, 'nMax=', nMax);
      const depths = Array.from(Array(nMax).keys());
      maxTempsByDepth = depths.map((n) =>
        Math.max(...sensorData?.filter((d) => !!d.hh && d.hh[n]).map((d) => d.hh[n]))
      );
      // console.log('maxTempsByDepth=', maxTempsByDepth);
    }
  }

  const [isLayerSelection] = useState(
    sensorData[0]?.n ? sensorData[0].n > 1 : sensorData[0]?.hh?.length > 2
  );

  const handleConditions = handleTempEnabled && !auxProbe && (isAdmin || !isSupervisorCompost);
  const handleConditionsTooltip = showHandleLine && handleConditions;

  let probeMoistDays = !probeMoistData ? [] : state.rangeTime;
  if (!isToday(probeMoistDays[probeMoistDays.length - 1])) {
    probeMoistDays = [];
  }
  probeMoistDays = probeMoistDays
    .filter((ts, i) => i > state.rangeTime.length - probeMoistData?.length - 1)
    .reverse();
  const moistData = !probeMoistData
    ? []
    : probeMoistDays.map((dayTS, i) => {
        // state.dataValue
        //   .filter((point) => point.y !== null && !Number.isNaN(point.y))
        //   .filter((d, i) => i % 10 === 0)
        //   .map((d) => {
        const value = probeMoistData[i] || null; // 5 + Math.round(Math.random() * 250) / 10;
        const scaledValue = !value
          ? null
          : Math.round(((state.maxValue - state.minValue) / (30 - 0)) * value); // scale 0-30% to temp range
        return {
          // ...d,
          x: dayTS + 12 * 3600 * 1000,
          y: scaledValue,
          moist: value
        };
      });

  // console.log('moistData ', moistData);
  return (
    <div
      className={
        isSnapShot ? [classes.mainContainer, classes.overviewBg].join(' ') : classes.mainContainer
      }
      id='chartMainContainer'
    >
      {isSnapShot ? (
        <div className={classes.ovewviewIndicator}>
          <InlineIcon className={classes.ovewviewIcon} icon={thermometerChevronUp} />
          <div className={classes.ovewviewText}>{i18n.chart_max_overview || 'Daily maximum'}</div>
        </div>
      ) : null}
      {isLayerSelection && !isSnapShot && (
        <div>
          <Typography className={classes.depthTitle}>{i18n.depth_title || 'Depth'}</Typography>
          <ToggleButtonGroup
            className={classes.depthSelector}
            orientation='vertical'
            color='primary'
            size='small'
            value={selectedDepth}
            exclusive
            onChange={handleChangeDepth}
            style={{
              maxHeight: (document.getElementById('chartMainContainer')?.offsetHeight || 400) - 80,
              overflow: 'auto'
            }}
          >
            {handleConditions && (
              <Tooltip key={0} title={i18n.handle_temp_text} placement='right' disableFocusListener>
                <ToggleButton
                  style={{
                    margin: '0px 0px 1px',
                    backgroundColor: showHandleLine ? COLORS.white : COLORS.mediumGray
                  }}
                  className={classes.depthSelectorButton}
                  // disabled={selectedDepth === 0}
                  // onClick={() => handleChangeDepth(0)}
                  value={0}
                >
                  <InlineIcon
                    key={0}
                    style={{ color: showHandleLine ? COLORS.mediumGray : COLORS.white }}
                    rotate='90deg'
                    icon={handRight}
                  />
                </ToggleButton>
              </Tooltip>
            )}
            {sensorData[0].hh
              .slice(1, sensorData[0].n ? sensorData[0].n + 1 : sensorData[0].hh.length)
              .map((hh, i) => (
                <Tooltip
                  key={i + 1}
                  title={
                    !isNil(maxTempsByDepth[i + 1])
                      ? `${i18n.max_temperature} ${tempFormat(
                          degrees,
                          maxTempsByDepth[i + 1]
                        )}${degrees}`
                      : null
                  }
                  placement='right'
                  disableFocusListener
                >
                  <ToggleButton
                    style={
                      !isNil(maxTempsByDepth[i + 1]) && selectedDepth !== i + 1
                        ? {
                            color: COLORS.white,
                            margin: '0px 0px 1px',
                            backgroundColor: getGradientColor(maxTempsByDepth[i + 1])
                          }
                        : { margin: '0px 0px 1px' }
                    }
                    className={
                      auxProbe
                        ? [classes.depthSelectorButton, classes.auxDepth].join(' ')
                        : classes.depthSelectorButton
                    }
                    value={i + 1}
                  >
                    {auxProbe ? i + 1 : `${i + 1} m`}
                  </ToggleButton>
                </Tooltip>
              ))}
          </ToggleButtonGroup>
        </div>
      )}
      {data[1] && data[1].y === null && <NoData i18n={i18n} classes={classes} />}

      <VictoryChart
        padding={{ top: 50, bottom: 50, left: 70, right: 50 }}
        width={
          document.getElementById('chartMainContainer')?.offsetWidth -
            (isLayerSelection && !isSnapShot ? 70 : 0) || 0
        }
        height={document.getElementById('chartMainContainer')?.offsetHeight || 0}
        scale={{ x: 'linear', y: 'linear' }}
        containerComponent={
          <VictoryVoronoiContainer
            voronoiDimension='x'
            cursorDimension={
              depthData.length === 0 && !isSnapShot && state.probeType === 'multipoint' ? 'x' : null
            }
            labels={() =>
              isSnapShot || state.probeType !== 'multipoint'
                ? [state.tooltipFormat, state.minValue, state.maxValue]
                : ' '
            }
            labelComponent={
              isSnapShot || state.probeType !== 'multipoint' ? (
                <CustomTooltip
                  dateFormat={dateFormat}
                  timeFormat={timeFormat}
                  mode={0}
                  displaydata={sensorData}
                  selectedDepth={selectedDepth}
                  isSnapShot={isSnapShot}
                  i18n={i18n}
                />
              ) : (
                <VictoryTooltip
                  flyoutComponent={
                    <SpecialSensorTooltip
                      dateFormat={dateFormat}
                      timeFormat={timeFormat}
                      mode={0}
                      displaydata={sensorData}
                      selectedDepth={selectedDepth}
                      windowWidth={window.innerWidth}
                      chartHei={document.getElementById('chartMainContainer')?.offsetHeight || 0}
                      handleShowed={handleConditionsTooltip}
                      i18n={i18n}
                    />
                  }
                />
              )
            }
            responsive={false}
            voronoiBlacklist={blacklist}
          />
        }
      >
        {!isSnapShot && state.probeType === 'multipoint' && (
          <VictoryArea // Fill area
            name='Fill area'
            data={state.dataMiddle}
            style={{
              data: {
                strokeWidth: 0,
                fill: 'rgb(235,240,249)'
              }
            }}
          />
        )}

        {/* Temperature Axis (vertical) */}
        <VictoryAxis
          dependentAxis
          domain={[state.minValue, state.maxValue]}
          tickValues={state.customRange}
          tickFormat={(t) => `${tempFormat(degrees, t, 0)}${degrees}`}
          standalone={false}
          offsetY={50}
          style={chartStyles.horizontalGrid}
        />
        {/* Time Axis (horizontal) */}
        <VictoryAxis
          domain={state.domain}
          tickValues={state.rangeTime}
          tickFormat={(value) => getTickFormat(value)}
          standalone={false}
          offsetY={50}
          style={chartStyles.verticalGrid}
        />
        <VictoryAxis
          domain={state.domain}
          tickValues={state.reference}
          standalone={false}
          offsetY={50}
          style={chartStyles.rangeStick}
        />

        {!isSnapShot && state.probeType === 'multipoint' && (
          <VictoryLine
            name='Max Values'
            data={state.maxValues}
            style={{
              data: {
                stroke:
                  state.maxCurveMin === state.maxCurveMax
                    ? `rgba(${getGradientColor(state.maxCurveMax).split('(')[1].split(')')[0]},1)`
                    : 'url(#colorGradient-max)',
                strokeWidth: 4
              }
            }}
          />
        )}
        {!isSnapShot && state.probeType === 'multipoint' && (
          <VictoryLine
            name='Tooltip Values'
            data={state.tooltipValues}
            style={{ data: { stroke: 'transparent' } }}
          />
        )}
        {!isSnapShot && state.probeType === 'multipoint' && (
          <VictoryLine // Min Values
            name='Min Values'
            data={state.minValues}
            style={{
              data: {
                stroke:
                  state.minCurveMin === state.minCurveMax
                    ? `rgba(${getGradientColor(state.minCurveMax).split('(')[1].split(')')[0]},1)`
                    : 'url(#colorGradient-min)',
                strokeWidth: 2
              }
            }}
          />
        )}

        {!isSnapShot && state.probeType === 'multipoint' && depthData.length > 0 ? (
          <VictoryScatter
            name='Sensor Values'
            data={depthData}
            size={2}
            style={{
              data: {
                fill: COLORS.mediumGray
              }
            }}
          />
        ) : null}

        {handleConditionsTooltip &&
        !isSnapShot &&
        state.probeType === 'multipoint' &&
        handleData.length > 0 ? (
          <VictoryLine
            name='Handle Values'
            data={handleData}
            size={2}
            style={{
              data: {
                stroke: COLORS.boulderGray,
                strokeWidth: 1.5
              }
            }}
          />
        ) : null}

        {probeMoistData && !isSnapShot && (
          <VictoryScatter
            name='moistureScatter'
            data={moistData}
            domain={{
              x: state.domain,
              y: [state.minValue, state.maxValue]
            }}
            size={4}
            labels={({ datum }) => `${datum.moist}%`}
            style={{
              data: {
                fill: COLORS.mediumPurple
              },
              labels: {
                fontSize: 12,
                fill: COLORS.mediumPurple
              }
            }}
          />
        )}
        {probeMoistData && !isSnapShot && (
          <VictoryLine
            name='moistureLine'
            data={moistData}
            interpolation='cardinal'
            domain={{
              x: state.domain,
              y: [state.minValue, state.maxValue]
            }}
            size={4}
            style={{
              data: {
                strokeWidth: 1,
                stroke: COLORS.mediumPurple
              }
            }}
          />
        )}
        {(isSnapShot || state.probeType !== 'multipoint') && (
          <VictoryLine
            data={state.dataValue.filter((point) => point.y !== null)}
            domain={{
              x: state.domain,
              y: [state.minValue, state.maxValue]
            }}
            interpolation='monotoneX'
            standalone={false}
            style={{ data: { stroke: 'url(#colorGradient-snapshot)', strokeWidth: 4 } }}
          />
        )}
      </VictoryChart>

      <svg style={{ height: 0 }}>
        <defs>{gradientMax}</defs>
        <filter id='shadow'>
          <feDropShadow dx='0' dy='1' stdDeviation='1' floodOpacity='0.4' />
        </filter>
      </svg>
      <svg style={{ height: 0 }}>
        <defs>{gradientMin}</defs>
        <filter id='shadow'>
          <feDropShadow dx='0' dy='1' stdDeviation='1' floodOpacity='0.4' />
        </filter>
      </svg>
      <svg style={{ height: 0 }}>
        <defs>{gradientSnapShot}</defs>
        <filter id='shadow'>
          <feDropShadow dx='0' dy='1' stdDeviation='1' floodOpacity='0.4' />
        </filter>
      </svg>
    </div>
  );
}
