import {
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  CircularProgress
} from '@material-ui/core';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import { isEmpty } from 'lodash';
import { format, sub, startOfDay, endOfDay } from 'date-fns';
import { DialogActionUtil } from 'utils/DialogActions';
import { getMinmaxData } from 'api/serverAPI';
import { COLORS } from 'utils/colors';
import { isNil, tempFormat } from 'assets/utils';
import StoragePeriodView from '../quality-views/StoragePeriodView';
import * as storageConstants from './storageConstants';

const XLSX = require('xlsx');

const useStyles = makeStyles(() => ({
  reportDialog: {
    maxWidth: 400,
    minWidth: 300
  },
  spinnerContainer: {
    height: 72,
    width: '100%',
    display: 'flex',
    boxSizing: 'border-box',
    paddingLeft: 'calc(50% - 30px)'
  },
  errorIcon: {
    width: 50,
    height: 50,
    color: COLORS.alert
  },
  okIcon: {
    width: 50,
    height: 50,
    color: COLORS.greenForest
  }
}));

const StorageDataLoader = ({ storage, sensorIDs, setLoadingExport }) => {
  const classes = useStyles();
  const degrees = useSelector((store) => store.sessionInfo?.ui_preferences?.degrees);
  const sensorConf = useSelector((store) => store.sensorConfigurations);

  const [cancelled, setCancelled] = useState(false);
  const [loadingStarted, setLoadingStarted] = useState(false);
  const [loadingError, setLoadingError] = useState(null);
  const [isPeriodDialogOpen, setIsPeriodDialogOpen] = useState(false);

  const [fromDate, setFromDate] = useState(
    startOfDay(sub(endOfDay(new Date()), { days: storageConstants.DEFAULT_PERIOD }))
  );
  const [toDate, setToDate] = useState(endOfDay(new Date()));
  const [prevFromDate, setPrevFromDate] = useState(fromDate);
  const [prevToDate, setPrevToDate] = useState(toDate);

  const { i18n, bsConf } = useSelector((store) => ({
    i18n: store.i18n,
    bsConf: store.bsConf
  }));

  const basestationID = bsConf?.bs_id;

  const cancelStoragePeriod = () => {
    setFromDate(prevFromDate);
    setToDate(prevToDate);
  };
  const applyStoragePeriod = (start, end) => {
    setFromDate(start);
    setToDate(end);
    setPrevFromDate(start);
    setPrevToDate(end);
  };

  const cancelDownload = () => {
    setCancelled(true);
    setLoadingExport(false);
  };

  async function startDownload() {
    setLoadingError(null);
    setLoadingStarted(true);
    const args = {
      basestationID,
      sensorIDs,
      fromTS: fromDate.getTime() / 1000,
      toTS: toDate.getTime() / 1000
    };
    await getMinmaxData(args)
      .then((response) => {
        if (cancelled) {
          return;
        }
        if (response.status_code) {
          setLoadingError(`ERROR: status=${response.status_code}`);
          setLoadingStarted(false);
          return;
        }
        const data = [];
        const nPoints = [];
        sensorIDs.map((sid, i) => {
          data[i] = [];
          const np = response
            .filter((meas) => meas.a === sensorIDs[i])
            .filter((meas) => meas.hh)
            .map((meas) => meas.hh.length - 1);
          nPoints[i] = np && !isEmpty(np) ? Math.max(...np) : 1;
          const sensorData = response
            .filter((meas) => meas.a === sensorIDs[i])
            .map((meas) => {
              const conv = {};
              conv.ID = sid;
              conv[i18n.name] =
                sensorConf?.find((s) => s.sensor_id_sys === sid)?.sensor_name || sid;
              conv[i18n.date] = format(new Date(meas.b * 1000), 'yyyy-MM-dd');
              if (meas.hh && meas.hh.length > 2) {
                meas.hh
                  .filter((val, ind) => ind > 0)
                  .map((hh, dep) => {
                    if (!isNil(hh)) {
                      conv[`${i18n.max_temperature} (${dep + 1}m)`] = Number(
                        tempFormat(degrees, hh)
                      );
                    }
                    return hh;
                  });
              } else {
                conv[i18n.max_temperature] = Number(tempFormat(degrees, meas.h));
              }
              return conv;
            });
          data[i] = data[i].concat(sensorData.filter((d) => !!d));
          return sid;
        });

        const wb = XLSX.utils.book_new();
        sensorIDs.map((id, probeIndex) => {
          const ws = XLSX.utils.json_to_sheet(data[probeIndex]);
          if (data[probeIndex]) {
            XLSX.utils.book_append_sheet(wb, ws, `${id}`);
          }
          return ws;
        });

        if (!cancelled) {
          XLSX.writeFile(
            wb,
            `${storage.name} ${format(fromDate, 'dd.MM.yyyy')}-${format(toDate, 'dd.MM.yyyy')}.xlsx`
          );
        }
        setLoadingExport(false); // Finally close dialog
      })
      .catch((error) => {
        setLoadingError(`ERROR: ${error.message}`);
        setLoadingStarted(false);
      });
  }

  return (
    <Dialog open aria-labelledby='responsive-dialog-title'>
      <DialogTitle>{loadingStarted ? i18n.loading_data : i18n.storage_data_download}</DialogTitle>
      <DialogContent className={classes.reportDialog} elevation={0}>
        {loadingStarted ? (
          <div className={classes.spinnerContainer}>
            <CircularProgress color='primary' size={60} disableShrink />
          </div>
        ) : (
          <div>
            <Typography className={classes.panelTitle} color='textSecondary'>
              {i18n.data_period || 'Data period'}
            </Typography>
            <StoragePeriodView
              sensorIDs={sensorIDs}
              fromDate={fromDate}
              toDate={toDate}
              setFromDate={setFromDate}
              setToDate={setToDate}
              ongoing={false}
              setOngoing={() => {}}
              storagePeriodChanged={() => {}}
              applyStoragePeriod={applyStoragePeriod}
              cancelStoragePeriod={cancelStoragePeriod}
              title={i18n.data_period || 'Data period'}
              desc=''
              todayLabel={null}
              isPeriodDialogOpen={isPeriodDialogOpen}
              setIsPeriodDialogOpen={setIsPeriodDialogOpen}
            />
          </div>
        )}
        {loadingError ? <Typography color='error'>{loadingError}</Typography> : null}
      </DialogContent>
      <DialogActionUtil
        onCancel={cancelDownload}
        onSave={() => startDownload()}
        text={i18n.download || 'Download'}
        disabled={loadingStarted}
      />
    </Dialog>
  );
};

export default StorageDataLoader;
