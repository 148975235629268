import {
  Button,
  Typography,
  Card,
  CardContent,
  TextField,
  Checkbox,
  FormControlLabel,
  FormGroup
} from '@material-ui/core';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { getPlanColor } from 'assets/utils';
import { accountServicesStyles } from 'utils/sharedStyles';
import { getDescription } from '../helpers';

const InvoiceRequest = ({
  i18n,
  billingDetails,
  setBillingDetails,
  upgradeInfo,
  // setInvoiceRequest,
  processing,
  forwardHandleSubmit,
  onClose
}) => {
  const [termChecked, setTermChecked] = useState(false);
  const classes = accountServicesStyles();
  const schema = yup
    .object()
    .shape({
      email: yup.string().email(i18n.invalid_format).required(i18n.field_required)
    })
    .required();

  const termAcceptedHandler = () => {
    setTermChecked((prevState) => !prevState);
  };

  const onSubmit = () => {
    // console.log('onSubmit email=', billingDetails.email);
    forwardHandleSubmit(null, true);
  };

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Card style={{ boxShadow: 'none', margin: '-10px 10px 15px' }}>
        <CardContent>
          <div
            className={classes.chip}
            style={{
              backgroundColor: getPlanColor(upgradeInfo.currentPlan),
              marginTop: -10,
              marginBottom: 10,
              minHeight: 26,
              width: 'fit-content',
              display: 'flex',
              /* justify-content: center; */
              alignItems: 'center',
              padding: '3px 10px'
            }}
          >
            <span style={{ verticalAlign: 'middle' }}>
              {getDescription(upgradeInfo.products, upgradeInfo, i18n)}
            </span>
          </div>
          <Typography>{i18n.renew_invoice}</Typography>

          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px 0' }}>
            <TextField
              label={i18n.email_address}
              type='email'
              {...register('email')}
              required
              error={Boolean(errors.email)}
              helperText={errors.email?.message}
              // placeholder={i18n.email_placeholder}
              value={billingDetails.email}
              style={{ minWidth: 300 }}
              onChange={(e) => {
                setBillingDetails({ ...billingDetails, email: e.target.value });
              }}
            />
          </div>
          <FormGroup row style={{ width: 'fit-content' }}>
            <FormControlLabel
              control={
                <Checkbox
                  required
                  checked={termChecked}
                  color='primary'
                  onClick={termAcceptedHandler}
                />
              }
              required
              label={
                <div>
                  {i18n.accept_gtcs_1}
                  <a
                    rel='noopener noreferrer'
                    style={{ color: 'black' }}
                    href='https://quanturi.com/pages/terms-of-use'
                    target='_blank'
                  >
                    {i18n.accept_gtcs_3}
                  </a>
                  .
                </div>
              }
            />
          </FormGroup>
          <div
            style={{ width: '100%', textAlign: 'right', marginBottom: -30, padding: '10px 0px' }}
          >
            <Button
              // variant='contained'
              color='primary'
              onClick={() => onClose()}
              aria-hidden='true'
              style={{ marginRight: 10 }}
            >
              {i18n.cancel}
            </Button>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              aria-hidden='true'
              disabled={!termChecked || processing}
            >
              {i18n.renew_subscription}
            </Button>
          </div>
        </CardContent>
      </Card>
    </form>
  );
};

export default InvoiceRequest;
