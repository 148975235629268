import {
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  FormGroup,
  Checkbox
} from '@material-ui/core';
import React from 'react';
import { DialogActionUtil } from 'utils/DialogActions';

export const AddPhoneDialog = ({
  isAddPhoneDialog,
  cancel,
  onSavePhones,
  phoneNumbers,
  handleSelectionChange,
  i18n
}) => (
  <Dialog open={isAddPhoneDialog} aria-labelledby='responsive-dialog-title'>
    <DialogTitle>{i18n.sel_phone || 'Select user phone'}</DialogTitle>
    <DialogContent>
      <FormGroup>
        {phoneNumbers.map((number) => (
          <FormControlLabel
            key={number.UID}
            control={
              <Checkbox
                checked={number.checked}
                onChange={handleSelectionChange}
                name={number.UID}
              />
            }
            label={
              (number.phoneName && `${number.phoneName} (${number.phoneNumber})`) ||
              number.phoneNumber
            }
          />
        ))}
      </FormGroup>
    </DialogContent>
    <DialogActionUtil
      onCancel={() => cancel()}
      onSave={onSavePhones}
      text={i18n.apply || 'Apply'}
    />
  </Dialog>
);
