import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  InputBase,
  Select,
  Checkbox,
  MenuItem,
  FormControl,
  FormControlLabel,
  FormGroup
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { compact, isEmpty, keys, values, sortBy } from 'lodash';
import { DebounceInput } from 'react-debounce-input';

import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import BasestationSelect from 'components/general/BasestationSelect';
import { useConfirm } from 'hook/useConfirm';
import { getBsConf, postTransferSensors, getBasestations } from 'api/serverAPI';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minHeight: '50vh',
    maxHeight: '50vh'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200
  }
}));

const TransferSensorsDialog = ({
  isTransferSensorsDialog,
  setIsTransferSensorsDialog,
  bsConf,
  selectedMultiBS,
  i18n,
  userInfo,
  isAdmin
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const [allBasestations, setAllBasestations] = useState([]);

  const [state, setState] = useState({
    targetBSList: isAdmin
      ? allBasestations
      : userInfo.bs_BS_IDs.filter((bs) => bs !== (selectedMultiBS || bsConf.bs_id)),
    allSensors: sortBy(
      selectedMultiBS
        ? bsConf?.sensor_configurations.filter((sc) => sc.realBs === selectedMultiBS)
        : bsConf?.sensor_configurations,
      'sensor_id_sys'
    ),
    selectedBS: '',
    searchKeyword: '',
    selectedSensors: {}
  });

  useEffect(() => {
    const basestationsList = async () => {
      // Filter to get all basestations
      if (isEmpty(allBasestations)) {
        await getBasestations({
          activated: 'true'
        })
          .then((response) => {
            // Sort BS IDs, remove current BS ID from transfer targets.
            const BsList = response.basestations
              .map((bs) => bs.BS_ID)
              .filter((bs) => (bsConf ? bs !== (selectedMultiBS || bsConf.bs_id) : true))
              .sort((a, b) => a.localeCompare(b));
            setAllBasestations(BsList);
            setState((tempState) => ({
              ...tempState,
              targetBSList: BsList
            }));
          })
          .catch((error) => {
            console.log('Error in getBasestations: ', error);
          });
      }
    };
    if (isAdmin) {
      basestationsList();
    }
  }, [allBasestations, isAdmin, bsConf, selectedMultiBS]);

  const handleChangeBS = (event) => {
    setState((tempState) => ({
      ...tempState,
      selectedBS: isAdmin ? event.value : event.target.value
    }));
  };

  const handleChangeSensor = (event) => {
    setState((tempState) => ({
      ...tempState,
      selectedSensors: {
        ...tempState.selectedSensors,
        [event.target.name]: event.target.checked
      }
    }));
  };

  const handleSearch = (event) => {
    const keyword = event.target.value;
    setState((tempState) => ({
      ...tempState,
      searchKeyword: keyword,
      allSensors: isEmpty(keyword)
        ? bsConf?.sensor_configurations
        : compact(
            bsConf?.sensor_configurations.map((sensor) => {
              if (sensor.sensor_id_sys.includes(keyword)) {
                return sensor;
              }
              return null;
            })
          )
    }));
  };

  const onSave = async () => {
    if (isAdmin) {
      await confirm({
        title: 'Hello admin',
        description: `Are you sure you want to transfer probes from ${bsConf.bs_id} to ${state.selectedBS} ?`
      });
    }
    try {
      await postTransferSensors({
        currentBS: selectedMultiBS || bsConf.bs_id,
        selectedBS: state.selectedBS,
        selectedSensors: keys(state.selectedSensors).filter(
          (sensorKey) => state.selectedSensors[sensorKey] === true
        )
      });
      const responseBSConf = await getBsConf({ basestationID: bsConf.bs_id });
      dispatch({ type: 'SET_BSCONF', value: responseBSConf.conf_data });
      setIsTransferSensorsDialog(false);
    } catch (e) {
      console.log(e);
    }
  };

  const isDisabled =
    isEmpty(state.selectedBS) ||
    isEmpty(state.selectedSensors) ||
    isEmpty(values(state.selectedSensors).filter((b) => b === true));
  return (
    <Dialog
      open={isTransferSensorsDialog}
      aria-labelledby='responsive-dialog-title'
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle>
        <div>{i18n.transfer_sensors || 'Transfer sensors'}</div>
      </DialogTitle>
      <DialogContent elevation={0}>
        <div>
          {i18n.transfer_sensors_text || 'Transfer selected sensors to another base station.'}
        </div>
        <br />
        <div>
          <FormControl className={classes.formControl}>
            {isAdmin ? (
              <BasestationSelect
                selectedBS={state.selectedBS}
                suggestionsBS={state.targetBSList.map((item) => ({
                  value: item,
                  label: item
                }))}
                // suggestionsGariBS={state.targetBSList?.map((item) => ({
                //   value: item.BS_ID,
                //   label: item.BS_ID
                // }))}
                bsType='quanturi'
                onChange={(item) => handleChangeBS(item)}
              />
            ) : (
              <Select value={state.selectedBS} displayEmpty onChange={handleChangeBS}>
                <MenuItem value=''>{i18n.sel_trg_system || 'Select target system'}</MenuItem>
                {state.targetBSList.map((bs, idx) => (
                  <MenuItem key={idx} value={bs}>
                    {bs}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
          <FormControl className={classes.formControl}>
            <DebounceInput
              element={InputBase}
              minLength={2}
              debounceTimeout={300}
              onChange={handleSearch}
              value={state.searchKeyword}
              placeholder={i18n.msg_table_search_tip || 'Find sensor'}
              endAdornment={
                <InputAdornment position='end'>
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
        <div>
          <FormControl component='fieldset' className={classes.formControl}>
            <FormGroup>
              {state.allSensors.map((sensor) => (
                <FormControlLabel
                  key={sensor.sensor_id_sys}
                  control={
                    <Checkbox
                      checked={state.selectedSensors[sensor.sensor_id_sys] || false}
                      onChange={handleChangeSensor}
                      name={sensor.sensor_id_sys}
                    />
                  }
                  label={sensor.sensor_id_sys}
                />
              ))}
            </FormGroup>
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsTransferSensorsDialog(false)} color='primary'>
          {i18n.cancel}
        </Button>
        <Button onClick={onSave} color='secondary' disabled={isDisabled}>
          {i18n.transfer || 'Transfer'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TransferSensorsDialog;
