import { size } from 'lodash';
import { startOfDay, sub } from 'date-fns';
import { getTranslationsData } from 'redux/reducers/setLanguage';

import {
  getSessionInfo,
  getUserInfo,
  getSensorsData,
  getMinmaxData,
  getBaseStation,
  getBasestations,
  getBsConf,
  getBatches,
  getStorages,
  postUiPrefs,
  getChangedBatches,
  setBsTimezone,
  getInsightSurvey,
  getFcItem
} from 'api/serverAPI';
import { getPreviousUpdateTS } from 'utils/sharedHelper';
import {
  updateNotifications,
  getMaxPlan,
  convertAuxData2,
  planHandler,
  handleExpired
} from 'assets/utils';
import { initialSettings } from '../../components/settings/helper';

const handleAdminUser = async (dataSession, selectedBS) => {
  // Filter to get all basestations
  const allBasestations = dataSession.users.filter((user) => size(user.bs_ids) === 1);
  let allGariBasestations;

  if (allGariBasestations === undefined) {
    await getBasestations({
      configType: 'gari',
      activated: 'true'
    })
      .then((response) => {
        allGariBasestations = response.basestations;
      })
      .catch((error) => {
        console.log('Error in getBasestations: ', error);
      });
  }

  const lastBS = allBasestations[allBasestations.length - 1]; // latest bs activated
  dataSession.users
    .filter((user) => size(user.bs_ids) > 1)
    .forEach((user) => {
      user.bs_ids.forEach((idBS) => allBasestations.push({ ...user, bs_ids: [idBS] }));
    });

  // Check selectedBS in cookie is right id
  let selectedBSAvailable = allBasestations.find((bs) => bs.bs_ids[0] === selectedBS);

  if (!selectedBSAvailable) {
    selectedBSAvailable = lastBS;
  }
  selectedBSAvailable.admin = 1;
  selectedBSAvailable.admin_group = dataSession.admin_group;
  selectedBSAvailable.admin_username = dataSession.admin_username;
  selectedBSAvailable.translator = dataSession.translator;
  selectedBSAvailable.newFeatureLive = dataSession.newFeatureLive;
  selectedBSAvailable.feedQualityLive = dataSession.feedQualityLive;
  selectedBSAvailable.limitFreeData = dataSession.limitFreeData;
  const gariBS = allGariBasestations.find((gBS) => gBS.BS_ID === selectedBS);
  if (gariBS) {
    selectedBSAvailable = gariBS;
    return {
      state: {
        basestations: allBasestations.sort((a, b) => a.bs_ids[0].localeCompare(b.bs_ids[0])),
        gariBasestations: allGariBasestations.sort((a, b) => a.BS_ID.localeCompare(b.BS_ID)),
        selectedBS: gariBS ? selectedBSAvailable : selectedBSAvailable.bs_ids[0],
        adminUsername: dataSession.username
      }
    };
  }

  return {
    dataUser: gariBS
      ? null
      : await getUserInfo({
          UID: selectedBSAvailable?.UID
        }),
    sessionInfo: selectedBSAvailable,
    state: {
      basestations: allBasestations.sort((a, b) => a.bs_ids[0].localeCompare(b.bs_ids[0])),
      gariBasestations: allGariBasestations.sort((a, b) => a.BS_ID.localeCompare(b.BS_ID)),
      selectedBS: gariBS ? selectedBSAvailable : selectedBSAvailable.bs_ids[0],
      adminUsername: dataSession.username
    }
  };
};

const handleMultiBSUser = async (dataSession, selectedBS) => {
  const allBasestations = dataSession.bs_ids.map((idBS) => ({
    ...dataSession,
    bs_ids: [idBS]
  }));

  // Check selectedBS in cookie is right id
  const selectedBSAvailable = allBasestations.find((bs) => bs.bs_ids[0] === selectedBS);

  return {
    dataUser: await getUserInfo(),
    sessionInfo: dataSession,
    state: {
      basestations: allBasestations,
      selectedBS: !selectedBSAvailable ? allBasestations[0].bs_ids[0] : selectedBS
    }
  };
};

const handleNormalUser = async (dataSession) => ({
  dataUser: await getUserInfo(),
  sessionInfo: dataSession,
  state: {
    selectedBS: dataSession.bs_ids[0]
  }
});

const handleDataUserPlan = (userinfo) => {
  const plan = getMaxPlan(userinfo.plans, userinfo.service);
  return plan;
};

const getlatestMeas = (measArray) => {
  const maxTS = Math.max(...measArray.map((meas) => meas.meas_ts));
  return measArray.find((meas) => meas.meas_ts === maxTS);
};

const handleSensorData = async (selectedBS, UID, isDemoMode) => {
  let responseSensorsData = await getSensorsData({
    basestationID: selectedBS,
    UID,
    isDemoMode
  });
  // Filter out null timestamps
  responseSensorsData = responseSensorsData.filter((meas) => meas.meas_ts);
  if (responseSensorsData.status_code === undefined) {
    // Handling case of having multiple data items (double BS returning latest data of both).
    // For each probe, we shall take only the latest of those data items.
    const uniqueDataIDs = responseSensorsData
      .map((d) => d.sensor_id)
      .filter((v, i, a) => a.indexOf(v) === i);
    const measPerID = uniqueDataIDs.map((id) =>
      responseSensorsData.filter((d) => d.sensor_id === id)
    );
    const latestMeasPerID = measPerID.map((measArray) => getlatestMeas(measArray));
    return latestMeasPerID; // responseSensorsData;
  }
};

const handleMinmaxData = async (selectedBS, auxDataIDs, UID, isDemoMode) => {
  const responseMinmaxData = await getMinmaxData({
    basestationID: selectedBS,
    auxDataID: auxDataIDs ? auxDataIDs[0].id : false,
    UID,
    fromTS: Math.round(sub(startOfDay(new Date()), { days: 7 }) / 1000),
    isDemoMode
  });
  if (responseMinmaxData.status_code === undefined) {
    return responseMinmaxData;
  }
};

const handleBatches = async (
  user,
  selectedBS,
  previousUpdateTS,
  storeBatches,
  isDemoMode,
  dispatch
) => {
  // We need to get RC items at some point of loading data, so here it seemed to work.
  if (user?.userinfo?.FC_IDs) {
    await getFcItem({ id: user?.userinfo?.FC_IDs.join(',') })
      .then((response) => {
        console.log('handleBatches getFcItem', response);
        dispatch({ type: 'SET_FC_STATUS', value: response });
      })
      .catch((error) => {
        console.log('Error in generalUpdate getFcItem: ', error);
      });
  }

  if (previousUpdateTS && storeBatches) {
    const changedBatches = await getChangedBatches({
      UID: user.userinfo.U_ID,
      BS_ID: selectedBS,
      sinceTS: previousUpdateTS // the timestamp, e.g. 1615476629
    });

    const responseBatches = storeBatches.map((batch) => {
      const newB = changedBatches.batches.find((newBatch) => newBatch.batch_id === batch.batch_id);
      return newB
        ? {
            ...batch,
            ...newB
          }
        : batch;
    });

    if (!changedBatches.status_code) {
      console.log('getChangedBatches in handleBatches', responseBatches);
      return responseBatches;
    }
  } else {
    const query = {
      UID: user.userinfo.U_ID,
      BS_ID: selectedBS,
      summary: true,
      statuses: [1, 2],
      isDemoMode
    };

    if (user.userinfo.demo) {
      delete query.summary;
      query.statuses = [1, 2, 3];
    }
    const responseBatches = await getBatches(query);
    // On HTTP status 500, obviously token expired -> go to login
    if (responseBatches.status && responseBatches.status === 500) {
      window.location.href = '/login';
    }
    if (!responseBatches.status_code) {
      console.log('getBatches in handleBatches', responseBatches);
      return responseBatches.batches;
    }
  }
  return null;
};

const handleStorages = async (user, isDemoMode) => {
  const query = {
    UID: user.userinfo.U_ID
  };
  if (isDemoMode) {
    query.isDemoMode = isDemoMode;
  }
  const responseStorages = await getStorages(query);
  // On HTTP status 500, obviously token expired -> go to login
  if (responseStorages.status && responseStorages.status === 500) {
    window.location.href = '/login';
  }

  if (!responseStorages.status_code) {
    console.log('getStorages in handleStorages', responseStorages);
    return responseStorages;
  }
  // }
  return null;
};

const handleBSConfig = async (selectedBS) => {
  const responseBSConf = await getBsConf({ basestationID: selectedBS });
  if (responseBSConf.status_code === 0) {
    return responseBSConf;
  }
};

export const convertBsTypeToString = (type, rev) => {
  let gen = '';
  let typeString = '';
  if (type) {
    switch (Number(type)) {
      case -1:
        // V3 initially registered, type is determined from first BS call
        typeString = 'V3 - Type yet undefined';
        break;
      case 1:
        // V1 STD
        typeString = 'V1 Standard';
        break;
      case 2:
        // V2 STD or V3 Ethernet, depending on rev
        // v.4.0.x -> V3 Ethernet G2
        // v.4.2.x -> V3 Ethernet G3
        if (rev) {
          const mainVersion = rev.substring(1, 4);
          if (mainVersion === '4.0') {
            typeString = 'V3 Ethernet';
            gen = ' (G2)';
            break;
          } else if (mainVersion === '4.2') {
            typeString = 'V3 Ethernet';
            gen = ' (G3)';
            break;
          } else if (mainVersion === '5.1') {
            typeString = 'Ethernet';
            gen = ' (G4)';
            break;
          } else {
            typeString = 'V2 Standard';
            break;
          }
        } else {
          typeString = 'V2 Standard';
          break;
        }
      case 3:
        // V2 GPRS or V3 Mobile, depending on rev
        // v.4.1.x -> V3 Mobile G2
        // v.4.3.x -> V3 Mobile G3

        if (rev) {
          const mainVersion = rev.substring(1, 4);
          if (mainVersion === '4.1') {
            typeString = 'V3 Mobile';
            gen = ' (G2)';
            break;
          } else if (mainVersion === '4.3') {
            typeString = 'V3 Mobile';
            gen = ' (G3)';
            break;
          } else if (mainVersion === '5.2') {
            typeString = 'Mobile';
            gen = ' (G4)';
            break;
          } else {
            typeString = 'GPRS';
            break;
          }
        } else {
          typeString = 'GPRS';
          break;
        }
      default:
        typeString = `Odd type "${type}"?`;
        break;
    }
  } else {
    typeString = 'V1 Standard'; // Old V1's may have no type
  }
  return `${typeString}${gen}`;
};

export const _convertBastVerToType = (bastVer) => {
  const bastNum = Number(bastVer);
  // BS ver 1 and 2 may have e.g. string "10" or "21".
  return bastVer >= 10 ? (bastNum / 10).toFixed(1).toString() : bastNum.toFixed(1).toString();
};

const handleBaseStation = async (selectedBS, responseBSConf) => {
  const responseBSArray = await getBaseStation({
    basestationID: selectedBS,
    bsGroup: responseBSConf.conf_data.bsGroup
  });
  if (responseBSArray.status_code === 0) {
    return {
      data: responseBSArray.data[0],
      multiBasestations: responseBSArray.data
    };
  }
};

const handleNotification = (sensorsData, bsConf, batches, storages, dataUser, expiredStates) => {
  const props = {
    sensorsData,
    sensorConfigurations: bsConf.conf_data.sensor_configurations.concat(bsConf.aux_data || []),
    bsConf: bsConf.conf_data,
    batches,
    storages,
    sensorGroups: bsConf.conf_data.sensor_groups || [],
    userInfo: dataUser.userinfo,
    currentView: null,
    expiredStates
  };
  return updateNotifications(props);
};

const handleSurveyAnswers = async (service, UID) => {
  try {
    if (service.plan === 2) {
      const results = await getInsightSurvey(UID && { UID });
      if (results.status_code === 0) {
        return results.data;
      }
      return {};
    }
  } catch (e) {
    console.log('Error in handleSurveyAnswers', e);
  }
};

const oldDateFormatConverter = (dataSessionReceived, data) => {
  const dateFormatToCheck = dataSessionReceived.ui_preferences
    ? dataSessionReceived.ui_preferences.date_format
    : data.sessionInfo.ui_preferences?.date_format;
  const connector = dateFormatToCheck?.charAt(dateFormatToCheck.length - 5);
  let newDateFormat;
  if (
    dateFormatToCheck
      ?.split(connector)
      ?.map((c) => c === 'YYYY')
      .includes(true)
  ) {
    newDateFormat = dateFormatToCheck
      ?.split(connector)
      ?.map((c) => (!c.split('').includes('M') ? c.toLowerCase() : c))
      .join(connector);
  }
  return newDateFormat;
};

const uiPrefsCheck = async (dispatch, dataSessionReceived, data) => {
  let dataSessionUpdate;
  if (
    !dataSessionReceived.admin &&
    data.sessionInfo.ui_preferences &&
    data.sessionInfo.ui_preferences.language
  ) {
    // After login, set the language from prefs
    console.log('getTranslationsData at: uiPrefsCheck');
    dispatch(
      getTranslationsData(
        data.sessionInfo.ui_preferences.language,
        data.dataUser.userinfo.U_ID,
        !!data.dataUser.userinfo.translator ?? 'user'
      )
    );
  }

  const dateFormatConverted = oldDateFormatConverter(dataSessionReceived, data);
  if (
    dateFormatConverted ||
    (data.sessionInfo.ui_preferences &&
      (!data.sessionInfo.ui_preferences.language ||
        !data.sessionInfo.ui_preferences.date_format ||
        !data.sessionInfo.ui_preferences.time_format ||
        !data.sessionInfo.ui_preferences.degrees ||
        data.sessionInfo.ui_preferences.degrees === 'C' ||
        !data.sessionInfo.ui_preferences.temperature_alert ||
        !data.sessionInfo.ui_preferences.include_exceptions ||
        !data.sessionInfo.ui_preferences.include_sensor_data ||
        !data.sessionInfo.ui_preferences.measurements_per_day ||
        !data.sessionInfo.ui_preferences.chart_scale ||
        !data.sessionInfo.ui_preferences.color_steps ||
        !data.sessionInfo.ui_preferences.handle_temp_enabled)) ||
    !data.sessionInfo.ui_preferences
  ) {
    // Handle temp false for compost users
    const planToCheck = data.dataUser.userinfo.plans?.find(
      (p) => +p.planLevel === 4 && +p.segment === 14
    );
    const isSupervisorCompost =
      planToCheck && +planToCheck.planLevel === 4 && +planToCheck.segment === 14;

    const defaultSettings = {
      prefs: {
        ...data.sessionInfo.ui_preferences,
        language:
          data.sessionInfo.ui_preferences?.language ||
          data.dataUser.userinfo.language?.toLowerCase() ||
          initialSettings.lang.value,
        date_format:
          dateFormatConverted ||
          data.sessionInfo.ui_preferences?.date_format ||
          initialSettings.d_format.value,
        time_format: data.sessionInfo.ui_preferences?.time_format || initialSettings.t_format.value,
        degrees:
          data.sessionInfo.ui_preferences?.degrees &&
          data.sessionInfo.ui_preferences?.degrees !== 'C'
            ? data.sessionInfo.ui_preferences.degrees
            : initialSettings.degrees.value,
        safety_alert:
          data.sessionInfo.ui_preferences?.safety_alert || initialSettings.safety_alert.value,
        temperature_alert:
          data.sessionInfo.ui_preferences?.temperature_alert || initialSettings.temp_alert.value,
        include_exceptions:
          data.sessionInfo.ui_preferences?.include_exceptions || initialSettings.incl_exc.value,
        include_sensor_data:
          data.sessionInfo.ui_preferences?.include_sensor_data ||
          initialSettings.incl_sens_data.value,
        measurements_per_day:
          data.sessionInfo.ui_preferences?.measurements_per_day ||
          initialSettings.meas_per_day.value,
        chart_scale:
          data.sessionInfo.ui_preferences?.chart_scale || initialSettings.def_chart.value,
        color_steps:
          data.sessionInfo.ui_preferences?.color_steps || initialSettings.def_color.value,
        handle_temp_enabled: isSupervisorCompost
          ? 'false'
          : data.sessionInfo.ui_preferences?.handle_temp_enabled ||
            initialSettings.handle_temp.value
      }
    };

    dataSessionUpdate = { ...data.sessionInfo, ui_preferences: defaultSettings.prefs };

    if (data.sessionInfo.ui_preferences !== defaultSettings.prefs) {
      data.sessionInfo.ui_preferences = defaultSettings.prefs;

      if (dataSessionReceived.admin && data.sessionInfo && defaultSettings) {
        defaultSettings.UID = data.sessionInfo.UID;
      }
      try {
        if (!data.dataUser.userinfo.demo) {
          await postUiPrefs(defaultSettings);
        }
        dispatch({ type: 'SET_SESSIONINFO', value: data.sessionInfo });
        dispatch({ type: 'SET_SHOW_HANDLE', value: defaultSettings.prefs.handle_temp_enabled });
        if (!dataSessionReceived.admin) {
          console.log('getTranslationsData at: POSTUIPREFS');
          dispatch(
            getTranslationsData(
              data.sessionInfo.ui_preferences.language,
              data.dataUser.userinfo.U_ID,
              'user'
            )
          );
        }
      } catch (err) {
        console.log('Error in postUiPrefs: ', err);
      }
    }
  }
  return dataSessionUpdate;
};

export const generateAllData = async (
  selectedBS,
  dispatch,
  previousUpdateTS,
  userInfo,
  bsConf,
  isAdmin,
  storeBatches,
  isDemoMode
) => {
  try {
    let responseBSConf = { conf_data: bsConf };
    let responseBS = {};
    let responseSensorsData = [];
    let expiredStates = {};
    let data = { state: { selectedBS }, dataUser: { userinfo: userInfo } };
    let dataSession = { admin: isAdmin };
    let plan = {};
    let responseMinmaxData = [];
    let responseBatches = [];
    let responseStorages = [];
    let notifications = [];
    let dataSessionReceived = {};
    let gariUser = false;
    let responseSurveyAnswers = {};

    const generalStatesUpdate = async () => {
      console.log('generalStatesUpdate');
      try {
        dataSessionReceived = await getSessionInfo();
        if (dataSessionReceived.status_code === 0) {
          // ADMIN BS SELECT
          if (dataSessionReceived.admin) {
            data = await handleAdminUser(dataSessionReceived, selectedBS);
          }
          // MULTI BS USER
          else if (size(dataSessionReceived.bs_ids) > 1) {
            data = await handleMultiBSUser(dataSessionReceived, selectedBS);
          }
          // NORMAL USER
          else {
            data = await handleNormalUser(dataSessionReceived);
          }
          gariUser = data.state.selectedBS?.configType === 'gari';
          if (gariUser) {
            responseBSConf = await handleBSConfig(data.state.selectedBS.BS_ID);
            responseBS = await handleBaseStation(data.state.selectedBS.BS_ID, responseBSConf);
            responseSensorsData = await handleSensorData(
              data.state.selectedBS.BS_ID,
              data.sessionInfo?.UID
            );
            return;
          }
          const dataSessionUpdated = await uiPrefsCheck(dispatch, dataSessionReceived, data);
          dataSession = dataSessionUpdated || data.sessionInfo;

          responseBSConf = await handleBSConfig(data.state.selectedBS);

          responseBS = await handleBaseStation(data.state.selectedBS, responseBSConf);
          if (data.dataUser.userinfo.service) {
            responseSurveyAnswers = await handleSurveyAnswers(
              data.dataUser.userinfo.service,
              !!dataSession.admin && data.sessionInfo?.UID
            );
          }
          // On HTTP status 500, obviously token expired -> go to login
          if (dataSessionReceived.status && dataSessionReceived.status === 500) {
            window.location.href = '/login';
          }
        }
      } catch (e) {
        console.log('generalStatesUpdate ERROR', e.message);
      }
    };
    const measurementRelatedStatesUpdate = async () => {
      console.log('measurementRelatedStatesUpdate');
      try {
        responseSensorsData = await handleSensorData(
          data.state?.selectedBS,
          data.dataUser?.userinfo?.U_ID,
          isDemoMode ?? data.dataUser.userinfo.demo
        );
        if (
          !!dataSession.admin ||
          (isDemoMode ?? data.dataUser.userinfo.demo) ||
          (plan.planLevel === 4 && +plan.segment === 14 && !expiredStates.planExpired)
        ) {
          responseBatches = await handleBatches(
            data.dataUser,
            data.state.selectedBS,
            previousUpdateTS,
            storeBatches,
            isDemoMode ?? data.dataUser.userinfo.demo,
            dispatch
          );
        }
        if (
          !!dataSession.admin ||
          (isDemoMode ?? data.dataUser.userinfo.demo) ||
          (!(plan.planLevel === 4 && +plan.segment === 14) && !expiredStates.planExpired)
        ) {
          responseMinmaxData = await handleMinmaxData(
            data.state.selectedBS,
            data.dataUser.userinfo.auxDataIDs,
            data.dataUser.userinfo.U_ID,
            isDemoMode ?? data.dataUser.userinfo.demo
          );
          responseStorages = await handleStorages(
            data.dataUser,
            isDemoMode ?? data.dataUser.userinfo.demo
          );
        }

        notifications = handleNotification(
          responseSensorsData,
          responseBSConf,
          responseBatches,
          responseStorages,
          data.dataUser,
          expiredStates
        );
      } catch (e) {
        console.log('measurementRelatedStatesUpdate ERROR', e.message);
      }
    };

    const updatesUtils = () => {
      plan = handleDataUserPlan(data.dataUser.userinfo);
      expiredStates = handleExpired(plan, responseBSConf?.conf_data);
    };

    if (!previousUpdateTS) {
      await generalStatesUpdate();
    }
    if (gariUser) {
      return {
        data,
        dataSession: dataSessionReceived,
        responseSensorsData,
        responseBS,
        responseBSConf,
        gariUser
      };
    }

    updatesUtils();
    await measurementRelatedStatesUpdate();
    return {
      data,
      dataSession,
      plan,
      responseSensorsData,
      responseMinmaxData,
      responseBatches,
      responseStorages,
      responseBS,
      responseBSConf,
      notifications,
      expiredStates,
      previousUpdateTS,
      responseSurveyAnswers,
      isDemoMode: isDemoMode ?? data.dataUser?.userinfo?.demo ?? false
    };
  } catch (err) {
    console.log('err: ', err);
    window.location.href = '/login';
  }
};

export const dispatchDataToStore = (objData, dispatch) => {
  const {
    data,
    dataSession,
    plan,
    responseSensorsData,
    responseMinmaxData,
    responseBatches,
    responseStorages,
    responseBS,
    responseBSConf,
    notifications,
    gariUser,
    expiredStates,
    previousUpdateTS,
    responseSurveyAnswers,
    isDemoMode
  } = objData;

  // Handle temp false for compost users
  let sessionInfo = dataSession;
  const isSupervisorCompost = plan && +plan.planLevel === 4 && +plan.segment === 14;

  const measurementRelatedDispatchUpdate = () => {
    console.log('measurementRelatedDispatchUpdate', responseBatches);
    dispatch({ type: 'SET_SENSORS_DATA', value: responseSensorsData || [] });
    if (gariUser) {
      dispatch({ type: 'SET_NOTIFICATIONS', value: [] });
    } else {
      dispatch({ type: 'SET_MINMAXDATA', value: responseMinmaxData });
      dispatch({ type: 'SET_BATCHES', value: responseBatches });
      dispatch({ type: 'SET_STORAGES', value: responseStorages });
      dispatch({ type: 'SET_NOTIFICATIONS', value: notifications });
      dispatch({ type: 'SET_PREVIOUS_UPDATE_TS', value: getPreviousUpdateTS(responseBatches) });
    }
  };

  const generalDispatchUpdate = async () => {
    console.log('generalDispatchUpdate');
    dispatch({ type: 'SET_LOADING', value: true });

    // dispatch({ type: 'SET_SESSIONINFO', value: [] });
    if (dataSession?.ui_preferences?.handle_temp_enabled === 'true' && isSupervisorCompost) {
      const compostHandlePrefs = {
        prefs: {
          ...dataSession.ui_preferences,
          handle_temp_enabled: 'false'
        }
      };
      try {
        if (!data.dataUser.userinfo.demo) {
          await postUiPrefs(compostHandlePrefs);
        }
        sessionInfo = { ...data.sessionInfo, ui_preferences: compostHandlePrefs.prefs };
      } catch (error) {
        console.log('Error in postUiPrefs: ', error);
      }
    }

    // ADMIN BS SELECT
    if (typeof isDemoMode === 'boolean') {
      if (dataSession.admin) {
        dispatch({ type: 'SET_ADMIN', value: true });
        dispatch({ type: 'SET_SESSIONINFO', value: sessionInfo || null });
        // dispatch(getTranslationsData('en'));
      }
      // MULTI BS USER
      else if (size(dataSession.bs_ids) > 1) {
        dispatch({ type: 'SET_SESSIONINFO', value: sessionInfo });
      }
      // NORMAL USER
      else {
        dispatch({ type: 'SET_SESSIONINFO', value: sessionInfo });
      }
    }
    dispatch({
      type: 'SET_SHOW_HANDLE',
      value:
        (isDemoMode === 'GRAIN').toString() ||
        sessionInfo?.ui_preferences?.handle_temp_enabled ||
        false
    });
    if (gariUser) {
      dispatch({ type: 'SET_GARI_USER', value: true });
    } else {
      dispatch({ type: 'SET_GARI_USER', value: false });
      if (!isDemoMode || typeof isDemoMode === 'boolean') {
        dispatch({ type: 'SET_USERINFO', value: data.dataUser?.userinfo });
      }
      // dispatch({ type: 'SET_NOTIFICATIONS', value: notifications });
      dispatch({ type: 'SET_MINMAXDATA', value: responseMinmaxData });
      // dispatch({ type: 'SET_BATCHES', value: responseBatches });
      // dispatch({ type: 'SET_STORAGES', value: responseStorages });

      dispatch({
        type: 'SET_DEMO_MODE',
        value: isDemoMode
      });

      planHandler(plan, dispatch, isDemoMode);
      // console.log(
      //   'SET_PLAN_EXPIRED value:',
      //   expiredStates.planExpired,
      //   'SET_SIM_EXPIRED value:',
      //   expiredStates.simExpired
      // );
      if (!gariUser) {
        dispatch({ type: 'SET_PLAN_EXPIRED', value: expiredStates.planExpired });
        dispatch({ type: 'SET_SIM_EXPIRED', value: expiredStates.simExpired });
        dispatch({ type: 'SET_SURVEY_ANSWERS', value: responseSurveyAnswers });
      }
    }
    if (!gariUser) {
      dispatch({ type: 'SET_SURVEY_ANSWERS', value: responseSurveyAnswers });
    }

    dispatch({
      type: 'SET_SENSORGROUPS',
      value: responseBSConf.conf_data.sensor_groups || []
    });

    dispatch({ type: 'SET_BASESTATION', value: responseBS.data });
    dispatch({ type: 'SET_BASESTATIONS', value: responseBS.multiBasestations });
    dispatch({ type: 'SET_ADMIN_BS', value: responseBS.data.bs_BS_ID });
    dispatch({
      type: 'SET_AUXCONF',
      value: responseBSConf.aux_data?.map((aux) => convertAuxData2(aux)) || null
    });
    dispatch({
      type: 'SET_SENSORCONF',
      value: responseBSConf.conf_data.sensor_configurations
    });
    if (!responseBSConf?.conf_data?.timezone && !gariUser && !dataSession.admin) {
      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      try {
        if (!data.dataUser.userinfo.demo) {
          setBsTimezone({ bs_id: data.state.selectedBS, timezone: tz });
        }
        const newBsConfig = { ...responseBSConf.conf_data, timezone: tz };
        dispatch({ type: 'SET_BSCONF', value: newBsConfig });
      } catch (e) {
        console.log(e);
      }
    } else {
      dispatch({ type: 'SET_BSCONF', value: responseBSConf.conf_data });
    }
  };
  if (!previousUpdateTS) {
    generalDispatchUpdate();
  }
  measurementRelatedDispatchUpdate();
  dispatch({ type: 'SET_LOADING', value: false });
};

export const clearDataToStore = (dispatch) => {
  // DESTROY ALL DATA
  dispatch({ type: 'SET_SENSORCONF', value: [] });
  dispatch({ type: 'SET_SENSORGROUPS', value: [] });
  dispatch({ type: 'SET_SENSORS_DATA', value: [] });
  dispatch({ type: 'SET_MINMAXDATA', value: null });
  dispatch({ type: 'SET_SENSORLIST', value: [] });
  dispatch({ type: 'SET_BASESTATION', value: null });
  dispatch({ type: 'SET_BASESTATIONS', value: null });
  dispatch({ type: 'SET_ADMIN_BS', value: null });
  dispatch({ type: 'SET_ADMIN_SELECTED_USER', value: null });
  dispatch({ type: 'SET_BSCONF', value: null });
  dispatch({ type: 'SET_AUXCONF', value: null });
  dispatch({ type: 'SET_USERINFO', value: null });
  dispatch({ type: 'SET_SESSIONINFO', value: null });
  dispatch({ type: 'SET_ADMIN', value: false });
  dispatch({ type: 'SET_LOGIN', value: null });
  dispatch({ type: 'SET_FC_STATUS', value: [] });
  dispatch({ type: 'SET_DEMO_MODE', value: false });
  dispatch({ type: 'SET_SURVEY_ANSWERS', value: null });
};

export const fetchData = async (
  BS_ID,
  dispatch,
  previousUpdateTS,
  userInfo,
  bsConf,
  isAdmin,
  storeBatches,
  isDemoMode
) => {
  console.log('fetchData');
  try {
    const objData = await generateAllData(
      BS_ID,
      dispatch,
      previousUpdateTS,
      userInfo,
      bsConf,
      isAdmin,
      storeBatches,
      isDemoMode
    );
    if (objData) {
      dispatchDataToStore(objData, dispatch);
      return objData;
    }
  } catch (err) {
    console.log(err);
    window.location.href = '/login';
  }
};
