import {
  Dialog,
  DialogTitle,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { format } from 'date-fns';
// import { COLORS } from 'utils/colors';
import { getPhaseRClog } from 'api/serverAPI';

// import { AdminTable } from '../../admin/helpers/TableComponents';

const useStyles = makeStyles((theme) => ({
  dialog: {
    minHeight: 250,
    minWidth: 500,
    padding: 20,
    paddingTop: 0,
    overflowY: 'auto',
    overflowX: 'hidden',
    [theme.breakpoints.down('xs')]: {
      minHeight: 'auto',
      minWidth: 'auto',
      padding: '0'
    }
  }
}));

const formatTemps = (temps) => {
  if (!temps || isEmpty(temps)) {
    return '';
  }
  const maxTemp = Math.max(...temps);
  const minTemp = Math.min(...temps);
  if (temps.length === 1 || minTemp === maxTemp) {
    return `${temps[0]}°C`;
  }
  return `${minTemp}-${maxTemp}°C`;
};

const formatCycleText = (logItem) => {
  if (logItem?.ventModeChanged && logItem?.ventModeChanged === 'cycle') {
    if (logItem?.pending?.rule?.runMinutes && logItem?.pending?.rule?.cycleSpanMinutes) {
      return `${logItem?.pending?.rule?.runMinutes}/${logItem?.pending?.rule?.cycleSpanMinutes}min`;
    }
  }
  return '';
};
const formatManualText = (logItem) => {
  const manual = 'isManual' in logItem;
  return manual ? (logItem.isManual ? `M on (${logItem.manualCommand})` : 'M off') : '';
};

const PhaseRCLog = ({ isOpen, RC, setIsOpen, full, batch, phase }) => {
  const classes = useStyles();

  const [data, setData] = useState(null);
  const [limit] = useState(100);

  useEffect(() => {
    const getLog = async () => {
      const response = await getPhaseRClog({
        type: 'change',
        FC_ID: RC?.FC_ID,
        channel: full && RC?.channel,
        limit,
        batch_id: batch?.batch_id,
        fromTS: phase?.phase_start_date,
        toTS: phase?.phase_completed_date || Math.round(new Date().getTime() / 1000)
      });
      if (response.status_code) {
        setData([]);
        return;
      }
      // sort a.ts - b.ts => newest last
      setData(response?.filter((log) => log.date).sort((a, b) => a.ts - b.ts));
    };

    if (!data) {
      getLog();
    }
  }, [data, setData, batch, phase, RC, full, limit]);

  /* Possible log item fields
  batchRef
  monitorRef

  1 modeChanged
  2 ventModeChanged
  3 commandChanged
  4 isManual
    manualCommand
  5 offlineChanged
  
  
  */

  const cellStyle = {
    padding: 0,
    paddingLeft: 5,
    maxWidth: 30,
    width: 30,
    borderRight: '1px solid #ddd',
    fontSize: 11,
    textWrap: 'nowrap',
    overflow: 'hidden'
  };
  const cellStyle10 = {
    padding: 0,
    paddingLeft: 5,
    maxWidth: 10,
    width: 10,
    borderRight: '1px solid #ddd',
    fontSize: 11,
    textWrap: 'nowrap',
    overflow: 'hidden'
  };
  const cellStyle120 = {
    padding: 0,
    paddingLeft: 5,
    maxWidth: 80,
    width: 80,
    borderRight: '1px solid #ddd',
    fontSize: 11,
    textWrap: 'nowrap',
    overflow: 'hidden'
  };

  return (
    <Dialog
      maxWidth='lg'
      fullWidth
      className={classes.dialog}
      open={isOpen}
      aria-labelledby='responsive-dialog-title'
    >
      <DialogTitle>
        {full ? `RC log: ${RC.name}` : 'Phase RC log'}
        {full && <Typography>{`Last ${limit} events`}</Typography>}
        {!full && (
          <Typography>{`Batch ${batch.batch_id} ("${batch.batch_name}") - phase ${phase.index} ("${phase.phase_name}")`}</Typography>
        )}
      </DialogTitle>

      {/* <div style={{ minHeight: 400, overflowY: 'auto' }}>
        {!data ? (
          'Loading...'
        ) : (
          <table style={{ fontSize: 12 }}>
            <tbody>
              {getTableHead()}
              {data.map((logitem, i) => getTableRow(logitem, i))}
            </tbody>
          </table>
        )}
      </div> */}

      <TableContainer>
        <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
          <TableHead>
            <TableRow>
              <TableCell style={cellStyle120}>Date</TableCell>
              <TableCell style={cellStyle}>Batch</TableCell>
              <TableCell style={cellStyle10}>C</TableCell>
              <TableCell style={cellStyle}>Net</TableCell>
              <TableCell style={cellStyle}>Mode</TableCell>
              <TableCell style={cellStyle}>Vent</TableCell>
              <TableCell style={cellStyle}>Comm</TableCell>
              <TableCell style={cellStyle}>Manual</TableCell>
              <TableCell style={{ ...cellStyle, maxWidth: 40, width: 40 }}>DataTS</TableCell>
              <TableCell style={cellStyle}>Temps</TableCell>
              <TableCell style={{ ...cellStyle, maxWidth: 40, width: 40 }}>Cycle</TableCell>
              <TableCell style={cellStyle}>Secs</TableCell>
              {/* <TableCell>All</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((logItem, i) => (
              <TableRow
                style={{ maxHeight: 20 }}
                key={i}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell style={cellStyle120}>
                  {format(new Date(logItem?.date), 'd.M.yy HH:mm:ss')}
                </TableCell>
                <TableCell style={cellStyle}>{logItem?.batchRef}</TableCell>
                <TableCell style={cellStyle10}>{logItem?.command}</TableCell>
                <TableCell style={cellStyle}>{logItem?.offlineChanged}</TableCell>
                <TableCell style={cellStyle}>{logItem?.modeChanged}</TableCell>
                <TableCell style={cellStyle}>{logItem?.ventModeChanged}</TableCell>
                <TableCell style={cellStyle}>{logItem?.commandChanged}</TableCell>
                <TableCell style={cellStyle}>{formatManualText(logItem)}</TableCell>
                <TableCell style={cellStyle}>
                  {logItem?.pending?.lastDataTS
                    ? format(new Date(logItem?.pending?.lastDataTS * 1000), 'd.M. HH:mm:ss')
                    : ''}
                </TableCell>
                <TableCell style={cellStyle}>{formatTemps(logItem?.pending?.temps)}</TableCell>
                <TableCell style={cellStyle}>{formatCycleText(logItem)}</TableCell>
                <TableCell style={cellStyle}>{logItem.currentCycleSecs}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Button onClick={() => setIsOpen(false)}>Close</Button>
    </Dialog>
  );
};

export default PhaseRCLog;
