import { startOfDay } from 'date-fns';
import { DAY_IN_MILLISECONDS } from 'redux/constants';

const dateData = [+startOfDay(new Date()) - 6 * DAY_IN_MILLISECONDS, +startOfDay(new Date())];

const initialState = {
  i18n: {},
  supportedLanguages: [{ locale: 'en', code: 'gb', name: 'English', published: true }],
  baseStation: {},
  baseStations: null,
  adminBS: null,
  adminSelectedUser: null,
  currentView: '',
  chartData: {},
  chartConfig: {
    date: dateData,
    span: 2, // 1 - MONTH / 2 - WEEK / 3 - DAY
    mode: 0 // 0 - TEMPERATURE / 1 - SIGNAL / 2 - VOLTAGE
  },
  dataTable: {
    countDuplicate: { value: 0 },
    sensorDataTable: null
  },
  dataTableConfig: {
    admin: false, // DEFAULT ADMIN CHECKBOX IS FALSE
    filter: {
      fromDate: dateData[0],
      toDate: dateData[1]
    }
  },
  tabConfig: {
    selection: 0 // FIRST TAB
  },
  isLoading: true,
  isLoggedIn: null,
  isAdmin: false,
  insightService: false,
  isPremium: false,
  isSupervisorCompost: false,
  isSupervisor: false,
  isPlanExpired: false,
  isSimExpired: false,
  premiumCongrats: false,
  sessionInfo: null,
  userInfo: null,
  bsConf: null,
  auxConf: null,
  sensorsData: null,
  minmaxData: null,
  sensorConfigurations: [],
  sensorGroups: [],
  sensorDetails: null,
  groupDetails: null,
  notifications: [],
  sensorList: [],
  storages: [],
  batches: [],
  socketUpdates: false,
  batchDetails: null,
  previousUpdateTS: +new Date(),
  layouts: [
    {
      id: '1',
      name: 'Layout1',
      fields: [
        {
          id: '1',
          name: 'Field1',
          sensor_locations: []
        }
      ]
    },
    {
      id: '2',
      name: 'Layout2',
      fields: [
        {
          id: '1',
          name: 'Field1',
          sensor_locations: []
        },
        {
          id: '2',
          name: 'Field2',
          sensor_locations: []
        }
      ]
    }
  ],
  sensorTooltip: null,
  sensorClickHandler: null,
  viewMode: '2D',
  viewClickHandler: null,
  editConfirmNeeded: false,
  unsavedChanges: false,
  FcStatus: [],
  showHandle: false,
  sensorSorting: ['temperature', 'asc'],
  isGariUser: false,
  isDemoMode: false,
  surveyAnswers: {}
};

export default initialState;
