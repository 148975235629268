import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField
} from '@material-ui/core';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { postBsDisplayName } from 'api/serverAPI';

const EditDisplayNameDialog = ({ isDisplayNameDialog, setIsDisplayNameDialog }) => {
  const dispatch = useDispatch();
  const { i18n, baseStation, bsConf, isDemoMode, isAdmin } = useSelector((store) => ({
    i18n: store.i18n,
    baseStation: store.baseStation,
    bsConf: store.bsConf,
    isDemoMode: store.isDemoMode,
    isAdmin: store.isAdmin
  }));

  const [displayName, setDisplayName] = useState(bsConf?.display_name || '');

  const handleChange = (event) => setDisplayName(event.target.value);

  const onSave = async () => {
    try {
      if (!isDemoMode || isAdmin) {
        await postBsDisplayName({ bs_id: baseStation.bs_BS_ID, display_name: displayName });
      }
      const newBsConfig = { ...bsConf, display_name: displayName };
      dispatch({ type: 'SET_BSCONF', value: newBsConfig });
      setDisplayName('');
      setIsDisplayNameDialog(!isDisplayNameDialog);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Dialog open={isDisplayNameDialog} aria-labelledby='responsive-dialog-title'>
      <DialogTitle>
        <div>{i18n.bs || 'Base station'}</div>
      </DialogTitle>
      <DialogContent elevation={0}>
        <TextField
          label={i18n.display_name || 'Display name'}
          value={displayName}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsDisplayNameDialog(!isDisplayNameDialog)} color='primary'>
          {i18n.cancel}
        </Button>
        <Button onClick={onSave} color='secondary'>
          {i18n.save || 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDisplayNameDialog;
