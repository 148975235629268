import { Typography, Button, Tooltip, FormControlLabel, Checkbox } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import { Icon } from '@iconify/react';
import hand from '@iconify/icons-mdi/hand-pointing-right';
import broom from '@iconify/icons-mdi/broom';
import { COLORS } from 'utils/colors';
import BalesHeightControls from './BalesHeightControls';

const useStyles = makeStyles(() => ({
  propsWrapper: {
    padding: '0px 0px 20px'
  },
  propGroup: {
    padding: '0px 0px 5px 10px'
  },
  propLabel: {
    fontSize: 12,
    color: COLORS.lightGray3
  },
  addButtonPanel: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 10px'
  },
  clearButton: {
    height: 37,
    minWidth: 37,
    borderRadius: 20,
    width: 37,
    boxSizing: 'border-box',
    padding: 0
  },
  infoIcon: {
    marginBottom: -5,
    marginRight: 3
  },
  fullStack: {
    margin: '-10px 0px 5px 10px'
  }
}));

const PilesControls = ({
  width,
  pileHeight,
  onPileHeightChange,
  onAddBales,
  footprintToAdd,
  pilesToRemove,
  onClearSelection,
  bales,
  addFullStackSelected,
  fullStackPressed
}) => {
  const classes = useStyles();
  const i18n = useSelector((store) => store.i18n);

  const nBales = !pilesToRemove
    ? pileHeight * footprintToAdd.length
    : bales.filter((bale) =>
        pilesToRemove.find((pile) => pile.col === bale.c && pile.row === bale.r)
      ).length;

  return (
    <div className={classes.propsWrapper}>
      <div style={{ border: 'none' }} className={classes.propGroup}>
        <Typography variant='body1' className={classes.propLabel}>
          <Icon className={classes.infoIcon} icon={hand} width={20} />
          {i18n.place_pile_hint || 'Select location to add or remove piles.'}
        </Typography>
        <BalesHeightControls
          disabled={Boolean(!isEmpty(pilesToRemove))}
          width={width - 20}
          pileHeight={pileHeight}
          onPileHeightChange={onPileHeightChange}
        />
      </div>
      <div className={classes.fullStack}>
        <FormControlLabel
          // className={classes.formControl}
          control={
            <Checkbox
              // className={classes.boolean}
              checked={addFullStackSelected}
              disabled={Boolean(!isEmpty(pilesToRemove))}
              onChange={() => fullStackPressed(!addFullStackSelected)}
            />
          }
          label={i18n.fill_stack || 'Fill stack'}
        />
      </div>
      <div style={{ border: 'none' }} className={classes.addButtonPanel}>
        <Button
          type='submit'
          color={pilesToRemove ? 'primary' : 'secondary'}
          style={{ color: COLORS.white }}
          disabled={nBales === 0}
          variant='contained'
          onClick={() => onAddBales()}
        >
          {`${pilesToRemove ? 'Remove' : 'Add'} ${nBales || ''} bales`}
        </Button>
        <Button
          type='submit'
          color='primary'
          style={{ color: COLORS.white }}
          disabled={nBales === 0}
          variant='contained'
          onClick={() => {
            onClearSelection();
            fullStackPressed(false);
          }}
          className={classes.clearButton}
        >
          <Tooltip
            title={i18n.clear_selection || 'Clear selection'}
            arrow
            placement='bottom'
            disableFocusListener
          >
            <Icon icon={broom} width='24' height='40' />
          </Tooltip>
        </Button>
      </div>
    </div>
  );
};

export default PilesControls;
