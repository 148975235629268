import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField
} from '@material-ui/core';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { makeStyles } from '@material-ui/core/styles';
import { useConfirm } from 'hook/useConfirm';
import { postReplaceBS } from 'api/serverAPI';

const useStyles = makeStyles(() => ({
  helperText: {
    margin: '5px 0'
  }
}));

const ReplaceBSDialog = ({ bs, isReplaceBSDialog, setIsReplaceBSDialog }) => {
  const classes = useStyles();
  const { userInfo, isGariUser } = useSelector((store) => ({
    userInfo: store.userInfo,
    isGariUser: store.isGariUser
  }));
  const [response, setResponse] = useState(null);
  const [wait, setWait] = useState(null);
  const confirm = useConfirm();

  const schema = yup.object().shape({
    bsid: yup
      .string()
      .required('Basestation ID is required')
      .matches(/^(([A-Z])([0-9]{7}))*$/, ' ')
    // .min(8, 'Write the BS ID carefully')
    // .max(8, 'Invalid ID')
  });

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    setError
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema)
  });

  const onSubmit = async (values) => {
    try {
      const { bsid } = values;
      await confirm({
        title: 'Hello admin',
        description: `Are you sure you want to replace ${
          isGariUser ? 'GARI BS ' : ''
        }"${bs}" with ${bsid} ?`
      });
      setWait(true);
      const res = await postReplaceBS({ old_bs: bs, new_bs: bsid, UID: userInfo?.U_ID });

      switch (Number(res.status_code)) {
        case 0: {
          setResponse(bsid);
          break;
        }
        case 1: {
          setError('bsid', {
            type: 'notMatch',
            message: 'Cannot replace BS. Missing resources for current BS.'
          });
          break;
        }
        case 2: {
          setError('bsid', {
            type: 'notMatch',
            message: 'Cannot replace BS merged with more than 1 other BS.'
          });
          break;
        }
        case 3: {
          setError('bsid', {
            type: 'notMatch',
            message: 'Cannot replace BS. The new BS ID is already in use.'
          });
          break;
        }
        default:
          setError('bsid', {
            type: 'notMatch',
            message: `Error in BS replacement (status ${res.status_code}). See Admin/server logs for details. `
          });
      }
    } catch (e) {
      console.log('Error in postReplaceBS: ', e.message);
    }
  };

  return (
    <Dialog open={isReplaceBSDialog} aria-labelledby='responsive-dialog-title'>
      <DialogTitle>{`Replace ${isGariUser ? 'GARI ' : ''}Base Station`}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <DialogContent elevation={0}>
          <div>{`Enter the BS ID to replace "${bs}"`}</div>
          <TextField
            variant='outlined'
            margin='dense'
            name='bsid'
            {...register('bsid')}
            required
            fullWidth
            error={Boolean(errors.bsid)}
            helperText={errors.bsid?.message}
            FormHelperTextProps={{ classes: { root: classes.helperText } }}
            disabled={response || wait}
          />
          {response && (
            <div>
              <div>{`${bs} replaced successfully with ${response}.`}</div>
              <div>Just reload page!</div>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsReplaceBSDialog(!isReplaceBSDialog)}
            variant={response && 'contained'}
            color={response ? 'secondary' : 'primary'}
          >
            {response ? 'Close' : 'Cancel'}
          </Button>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            disabled={!isValid || response || wait}
          >
            Replace BS
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ReplaceBSDialog;
