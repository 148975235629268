import { cloneDeep, isEmpty } from 'lodash';
import { addNewUser, getUserInfo } from 'api/serverAPI';

const countriesEN = require('../../../assets/countries/en.json');
const countriesFR = require('../../../assets/countries/fr.json');
const countriesDE = require('../../../assets/countries/de.json');
const countriesFI = require('../../../assets/countries/fi.json');
const countriesHR = require('../../../assets/countries/hr.json');
const countriesIT = require('../../../assets/countries/it.json');

export const countriesOptions = (lang) => {
  let countriesJson;
  switch (lang) {
    case 'fr':
      countriesJson = countriesFR;
      break;
    case 'de':
      countriesJson = countriesDE;
      break;
    case 'fi':
      countriesJson = countriesFI;
      break;
    case 'hr':
      countriesJson = countriesHR;
      break;
    case 'it':
      countriesJson = countriesIT;
      break;
    default:
      countriesJson = countriesEN;
      break;
  }
  return countriesJson;
};

export const countriesOps = (lang) =>
  Object.entries(countriesOptions(lang)).map((option) => ({
    value: option[0],
    label: option[1]
  }));

export const countryObj = (lang) => ({
  key: 'country',
  title: 'Country',
  type: 'radio',
  data: countriesOptions(lang),
  value: ''
});

const defaultUser = (lang) => ({
  name: {
    key: 'name',
    title: 'Name',
    label: 'Name',
    type: 'text',
    value: ''
  },
  phone: {
    key: 'phone',
    title: 'Mobile Phone',
    label: 'Mobile Phone',
    type: 'text',
    value: ''
  },
  email: {
    key: 'email',
    title: 'Email',
    label: 'Email address',
    type: 'text',
    value: ''
  },
  company: {
    key: 'company',
    title: 'Company',
    label: 'Company name',
    type: 'text',
    value: ''
  },
  address: {
    key: 'address',
    title: 'Address',
    label: 'Street address',
    type: 'text',
    value: ''
  },
  zip: {
    key: 'zip',
    title: 'Postal code',
    label: 'Postal code',
    type: 'text',
    value: ''
  },
  city: {
    key: 'city',
    title: 'City',
    label: 'City',
    type: 'text',
    value: ''
  },
  country: countryObj(lang)
});

const userTemplate = {
  name: {
    key: 'name',
    title: 'Name',
    label: 'Name',
    type: 'text',
    value: ''
  },
  phone: {
    key: 'phone',
    title: 'Mobile Phone',
    label: 'Mobile Phone',
    type: 'text',
    value: ''
  },
  email: {
    key: 'email',
    title: 'Email',
    label: 'Email address',
    type: 'text',
    value: ''
  }
};

export const generateUser = async (userInfo, i18n, dispatch) => {
  const user = { ...defaultUser(i18n.locale) };

  user.name.title = i18n.full_name;
  user.name.value = userInfo.firstname;

  user.phone.title = i18n.phone_number;
  user.phone.value = userInfo.phones[0].phoneNumber;
  user.phone.confirmed = userInfo.phones[0].confirmed;

  user.email.title = i18n.email_address;
  user.email.value = userInfo.email;

  user.company.title = i18n.company_name;
  user.company.value = userInfo.lastname;

  user.address.title = i18n.street_address;
  user.address.value = userInfo.address;

  user.zip.title = i18n.postal_code;
  user.zip.value = userInfo.zip;

  user.city.title = i18n.city;
  user.city.value = userInfo.city;

  user.country.title = i18n.country;
  user.country.value = userInfo.country;

  // Migration to new app: convert additional phone numbers to subUsers.
  if (userInfo.phones.length > 1 && isEmpty(userInfo.subUsers)) {
    try {
      const phones = userInfo.phones.filter((phone) => phone.index > 0);
      if (!isEmpty(phones)) {
        const promises = phones.map((additionalPhone) =>
          addNewUser({
            UID: userInfo.U_ID,
            new_user: {
              name: additionalPhone.phoneName,
              mobilePhone: additionalPhone.phoneNumber,
              email: ''
            }
          })
        );
        await Promise.all(promises);

        const dataUser = await getUserInfo({ UID: userInfo.UID });
        if (dataUser.status_code === 0) {
          dispatch({ type: 'SET_USERINFO', value: dataUser.userinfo });
        }

        const otherUsers = phones.map((additionalPhone) => {
          const newU = cloneDeep(userTemplate);
          newU.name.title = i18n.full_name;
          newU.phone.title = i18n.phone_number;
          newU.email.title = i18n.email_address;

          newU.name.value = additionalPhone.phoneName;
          newU.phone.value = additionalPhone.phoneNumber;
          newU.email.value = '';
          return newU;
        });

        return [{ ...user }, ...otherUsers];
      }
    } catch (e) {
      console.log(e);
    }
  }

  if (userInfo.subUsers) {
    const otherUsers = userInfo.subUsers.map((u) => {
      const newU = cloneDeep(userTemplate);
      newU.name.title = i18n.full_name;
      newU.phone.title = i18n.phone_number;
      newU.email.title = i18n.email_address;

      newU.name.value = u.name;
      newU.phone.value = u.phone;
      newU.email.value = u.email;
      return newU;
    });
    return [{ ...user }, ...otherUsers];
  }
  return [{ ...user }];
};
