import { Button, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useConfirm } from 'hook/useConfirm';
import { postInsightSurvey } from 'api/serverAPI';
import adminChangesConfirm from 'utils/adminChangesConfirm';

import { accountUsersStyles } from 'utils/sharedStyles';
import {
  SurveyRadio,
  SurveySlider,
  SurveyToggleButton,
  constructSaveObject
} from 'components/account/account-users/account-background/helpers';

const EditAnswersItemDialog = ({ info, toggleEditInfo, backgroundInfo, dispatch }) => {
  const classes = accountUsersStyles();
  const i18n = useSelector((store) => store.i18n);
  const confirm = useConfirm();
  const { isAdmin, sessionInfo, isDemoMode } = useSelector((store) => ({
    isAdmin: store.isAdmin,
    sessionInfo: store.sessionInfo,
    isDemoMode: store.isDemoMode
  }));

  const [state, setState] = useState(backgroundInfo);

  const onSave = async () => {
    await adminChangesConfirm(isAdmin, confirm, state[info.key].key, sessionInfo.username);
    const answers = constructSaveObject(state);
    try {
      let resultSurvey;
      if (isDemoMode) {
        resultSurvey = { status_code: 0 };
      } else {
        resultSurvey = await postInsightSurvey({ answers });
      }
      if (resultSurvey.status_code === 0) {
        dispatch({ type: 'SET_SURVEY_ANSWERS', value: answers });
        toggleEditInfo();
      }
    } catch (e) {
      console.log('error in saving');
    }
  };
  const sliderLabel = () => {
    const { key } = info;
    if (key.substring(key.split('').length, key.split('').length - 7) === 'PerYear') {
      return i18n[`hayTypeUnit_option_${state.hayTypeUnit.value === 'bales' ? 'bales' : 'tons'}`];
    }
    if (key === 'balesWeight') {
      return state.weightUnit.value;
    }
  };
  return (
    <Dialog open fullWidth={info.type === 'slider'}>
      <DialogContent className={classes.dialogContent} elevation={0}>
        {info.type === 'radio' ? (
          <SurveyRadio i18n={i18n} id={info.key} info={state[info.key]} setState={setState} />
        ) : info.type === 'slider' ? (
          <SurveySlider
            i18n={i18n}
            id={info.key}
            info={state[info.key]}
            setState={setState}
            label={sliderLabel()}
          />
        ) : (
          <SurveyToggleButton
            i18n={i18n}
            id={info.key}
            info={state[info.key]}
            setState={setState}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => toggleEditInfo()} color='primary'>
          {i18n.cancel}
        </Button>
        <Button onClick={onSave} color='secondary'>
          {/* disabled={error}  */}
          {i18n.ok}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditAnswersItemDialog;
