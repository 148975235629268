import frLocale from 'date-fns/locale/fr';
import enLocale from 'date-fns/locale/en-US';
import fiLocale from 'date-fns/locale/fi';
import deLocale from 'date-fns/locale/de';
import itLocale from 'date-fns/locale/it';
import hrLocale from 'date-fns/locale/hr';
import esLocale from 'date-fns/locale/es';

const localeMap = {
  en: enLocale,
  fr: frLocale,
  fi: fiLocale,
  de: deLocale,
  it: itLocale,
  hr: hrLocale,
  es: esLocale
};

const localeFormat = (locale) => localeMap[locale];

export default localeFormat;
