import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import SensorDetails from 'components/probes/sensor/sensor-detail/SensorDetails';
import { controllerRule, isAlertRule } from 'components/storages/monitors/monitor-utils/fcHelpers';

import { selectedDetailsStyle } from 'utils/sharedStyles';
import { calcOverheadHeight } from 'assets/utils';
import * as storageConstants from '../storage-utils/storageConstants';
import StorageDetailsTabs from './storage-details-tabs/StorageDetailsTabs';
import StorageLayoutView from '../storage-layout/StorageLayoutView';
// import StorageBatch from './storage-details-tabs/StorageBatch';
import StorageMonitors from '../monitors/storage-monitor/StorageMonitors';
import StorageProperties from './storage-details-tabs/StorageProperties';
import StorageStacksView from './storage-details-tabs/StorageStacksView';

const SelectedStorageDetails = ({
  selectedStorage,
  setSelectedStorage,
  setIsStorageEdited,
  listMonitors,
  setMonitors,
  createMonitor,
  setCreateMonitor,
  setMonitorsChanges,
  selectedMonitor,
  setSelectedMonitor,
  storages,
  setStoragesChanges,
  qualityMode,
  editMode,
  feedQualityLive
}) => {
  const classes = selectedDetailsStyle();

  const [selectedTab, setSelectedTab] = React.useState(storageConstants.TAB_LAYOUT);
  const { FcStatus } = useSelector((store) => ({
    FcStatus: store.FcStatus
  }));
  const FcInStorage = selectedStorage?.monitor?.rules.find((rule) => controllerRule(rule));
  const alertRulesInStorage = selectedStorage?.monitor?.rules.find((rule) => isAlertRule(rule));
  const itemFcStatus = FcStatus?.find((FC) => FC.FC_ID === FcInStorage?.settings.FC_ID);

  const manualFCControl = itemFcStatus && itemFcStatus.isManual;

  const heightOverhead = calcOverheadHeight();

  const [viewWidth, setViewWidth] = useState(
    window.innerWidth - document.getElementById('rightDrawer').offsetWidth
  );
  const [viewHeight, setViewHeight] = useState(window.innerHeight - heightOverhead);

  const timeOutFunctionId = useRef();

  useEffect(() => {
    const workAfterResizeIsDone = () => {
      // console.log(
      //   '---- resize done ',
      //   window.innerWidth - document.getElementById('rightDrawer').offsetWidth - 250,
      //   'x',
      //   window.innerHeight - heightOverhead
      // );
      setViewWidth(window.innerWidth - document.getElementById('rightDrawer').offsetWidth);
      setViewHeight(window.innerHeight - heightOverhead);
    };
    const handleResize = () => {
      clearTimeout(timeOutFunctionId.current);
      timeOutFunctionId.current = setTimeout(workAfterResizeIsDone, 500);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [heightOverhead]);

  return (
    <div>
      <div className={classes.selectedContainerVh}>
        <StorageDetailsTabs
          selectedStorage={selectedStorage}
          selectedTab={selectedTab}
          tabChange={(event, newValue) => {
            setSelectedTab(newValue);
          }}
          manualFCControl={manualFCControl}
          alertRulesInStorage={alertRulesInStorage}
        />
        {selectedTab === storageConstants.TAB_LAYOUT &&
          (selectedStorage?.layoutType === 3 ? (
            <StorageStacksView
              viewHeight={viewHeight}
              viewWidth={viewWidth}
              selectedStorage={selectedStorage}
              setSelectedStorage={setSelectedStorage}
              setIsStorageEdited={setIsStorageEdited}
              storages={storages}
              // showHay={isInsight} // hay disabled for now
              feedQualityLive={feedQualityLive}
              qualityMode={qualityMode}
              editMode={editMode}
            />
          ) : (
            <>
              <StorageLayoutView
                viewHeight={viewHeight - 36}
                viewWidth={viewWidth}
                selectedStorage={selectedStorage}
                setSelectedStorage={setSelectedStorage}
                setIsStorageEdited={setIsStorageEdited}
                sensorIDsInOtherStorages={storages
                  ?.filter((s) => s.itemID !== selectedStorage?.itemID)
                  ?.filter((s) => s.sensor_layout || s.stacks)
                  ?.map((s) =>
                    s.layoutType === 3
                      ? s.stacks.map((st) => st.sensors).flat()
                      : s.sensor_layout.sensors
                  )
                  ?.flat(1)
                  ?.map((s) => s.id)}
              />
              <Route
                path='probes/:probeId'
                // render={() => (isPremium ? <SensorDetails /> : <SensorPremium />)}
                render={() => <SensorDetails />}
              />
            </>
          ))}
        {/* {selectedTab === storageConstants.TAB_BATCHES && (
          <StorageBatches setIsStorageEdited={setIsStorageEdited} />
        )} */}
        {selectedTab === storageConstants.TAB_MONITORS && (
          <StorageMonitors
            selectedStorage={selectedStorage}
            setSelectedStorage={setSelectedStorage}
            setIsStorageEdited={setIsStorageEdited}
            listMonitors={listMonitors}
            setMonitors={setMonitors}
            createMonitor={createMonitor}
            setCreateMonitor={setCreateMonitor}
            setMonitorsChanges={setMonitorsChanges}
            selectedMonitor={selectedMonitor}
            setSelectedMonitor={setSelectedMonitor}
            storages={storages}
            setStoragesChanges={setStoragesChanges}
          />
        )}
        {selectedTab === storageConstants.TAB_PROPERTIES && (
          <StorageProperties
            selectedStorage={selectedStorage}
            setSelectedStorage={setSelectedStorage}
            setIsStorageEdited={setIsStorageEdited}
          />
        )}
      </div>
    </div>
  );
};

export default SelectedStorageDetails;
