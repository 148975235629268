import {
  Button,
  IconButton,
  Dialog,
  // DialogTitle,
  DialogContent,
  DialogActions,
  Typography
} from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { InlineIcon } from '@iconify/react';
import closeIcon from '@iconify/icons-mdi/close';
import { format } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'utils/colors';
import QualityLegendItem from './QualityLegendItem';

const useStyles = makeStyles((theme) => ({
  dialogHead: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 0 10px'
  },
  dialogTitle: {
    fontSize: '1.25rem',
    fontWeight: 500,
    marginLeft: 22
  },
  closeButton: {
    color: COLORS.primaryColor,
    margin: '-10px 5px -10px 10px'
  },
  infoText: {
    fontSize: 12,
    margin: '10px 0'
  },
  periodText: {
    fontSize: 12,
    margin: '-22px 0px 10px 24px',
    [theme.breakpoints.down('xs')]: {
      margin: '-13px 0px 10px 24px'
    }
  },
  noDataText: {
    // fontSize: 16,
    color: COLORS.alert,
    margin: '-22px 0px 10px 24px',
    [theme.breakpoints.down('xs')]: {
      margin: '-13px 0px 10px 24px'
    }
  },
  mainProbeButton: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  smallProbeButton: {
    display: 'none',
    maxHeight: 30,
    margin: '0 24px 10px',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    },
    lineHeight: '20px',
    width: 'fit-content'
  }
}));

const QualityInfoDialog = ({
  i18n,
  isDialogOpen,
  setIsDialogOpen,
  clickedQuality,
  setClickedQuality,
  setClickedQualitySensor,
  fromDate,
  toDate,
  ongoing,
  openSensorData
}) => {
  const classes = useStyles();
  const { dateFormat } = useSelector((store) => ({
    dateFormat: store.sessionInfo?.ui_preferences?.date_format
  }));

  const onClose = () => {
    setIsDialogOpen(false);
    setClickedQualitySensor(null);
    setClickedQuality(null);
  };

  const nLevels = 5;
  const indices = Array.from(Array(nLevels).keys()).reverse(); // [4,3,2,1,0]
  const ratingLegends = (
    <div>
      {indices.map((i) => (
        <QualityLegendItem
          i18n={i18n}
          key={i}
          nBars={i + 1}
          probeQuality={clickedQuality?.bars === i + 1 ? clickedQuality : null}
          light={clickedQuality}
        />
      ))}
    </div>
  );

  return (
    <Dialog
      open={isDialogOpen}
      aria-labelledby='responsive-dialog-title'
      fullWidth
      onClose={onClose}
      style={{ padding: 10 }}
    >
      {/* // ${clickedQuality?.ID ? `- ${i18n.sensor_id} ${clickedQuality?.ID}` : ''} */}
      <div className={classes.dialogHead}>
        <div className={classes.dialogTitle}>
          {`${i18n.quality_info_title} 
          
          `}
        </div>
        <div>
          {clickedQuality && (
            <Button
              className={classes.mainProbeButton}
              color='primary'
              variant='contained'
              onClick={() => openSensorData(clickedQuality?.ID)}
            >
              {`${i18n.sensor} ${clickedQuality?.ID}`}
            </Button>
          )}
          <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
            <InlineIcon icon={closeIcon} width={24} height={24} />
          </IconButton>
        </div>
      </div>
      {clickedQuality && (
        <Typography variant='body1' className={classes.periodText}>
          {`${i18n.storage_period}${i18n.colon}${format(
            fromDate,
            dateFormat ?? 'yyyy-MM-dd'
          )} \u2013 ${ongoing ? '' : format(toDate, dateFormat ?? 'yyyy-MM-dd')}`}
        </Typography>
      )}
      {clickedQuality && (
        <Button
          className={classes.smallProbeButton}
          color='primary'
          variant='contained'
          onClick={() => openSensorData(clickedQuality?.ID)}
        >
          {`${i18n.sensor} ${clickedQuality?.ID}`}
        </Button>
      )}
      {clickedQuality && !clickedQuality?.bars && (
        <Typography style={{ marginTop: 0 }} variant='body1' className={classes.noDataText}>
          {i18n.storage_period_no_data || 'No data from storage period'}
        </Typography>
      )}
      <DialogContent elevation={0}>
        {!clickedQuality && (
          <Typography style={{ marginTop: 0 }} variant='body1' className={classes.infoText}>
            {i18n.quality_infotext1}
          </Typography>
        )}
        {ratingLegends}
        {clickedQuality && (
          <Typography variant='body1' className={classes.infoText}>
            {i18n.quality_infotext2}
          </Typography>
        )}
      </DialogContent>

      <div>
        <DialogActions>
          {/* <Button
            onClick={() => {
              if (clickedQuality) {
                openSensorData(clickedQuality?.ID);
              }
            }}
            color='secondary'
          >
            PROBE data
          </Button> */}
          <Button onClick={onClose} color='primary'>
            {i18n.close}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default QualityInfoDialog;
