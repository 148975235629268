import { Button } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import { Icon } from '@iconify/react';
import calendarRange from '@iconify/icons-mdi/calendar-range';
import calendarAlert from '@iconify/icons-mdi/calendar-alert';
import { COLORS } from 'utils/colors';
import StoragePeriodDialog from './StoragePeriodDialog';

const useStyles = makeStyles(() => ({
  calButton: {
    marginRight: 5,
    width: 18,
    height: 18
  },
  datesButton: {
    paddingLeft: 0,
    textTransform: 'lowercase',
    width: '100%',
    justifyContent: 'left'
  },
  periodPanel: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  periodDates: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '48px',
    width: '100%'
  }
}));

const StoragePeriodView = ({
  sensorIDs,
  fromDate,
  toDate,
  setOngoing,
  setFromDate,
  setToDate,
  ongoing,
  unset,
  storagePeriodChanged,
  applyStoragePeriod,
  cancelStoragePeriod,
  title,
  desc,
  todayLabel,
  isPeriodDialogOpen,
  setIsPeriodDialogOpen
}) => {
  const classes = useStyles();
  const { dateFormat, i18n } = useSelector((store) => ({
    dateFormat: store.sessionInfo?.ui_preferences?.date_format,
    i18n: store.i18n
  }));

  return (
    <div className={classes.periodPanel}>
      {isPeriodDialogOpen && (
        <StoragePeriodDialog
          setOngoing={setOngoing}
          ongoing={ongoing}
          sensorIDs={sensorIDs}
          fromDate={fromDate}
          toDate={toDate}
          setFromDate={setFromDate}
          setToDate={setToDate}
          isPeriodDialogOpen={isPeriodDialogOpen}
          setIsPeriodDialogOpen={setIsPeriodDialogOpen}
          storagePeriodChanged={storagePeriodChanged}
          applyStoragePeriod={applyStoragePeriod}
          cancelStoragePeriod={cancelStoragePeriod}
          title={title}
          desc={desc}
          todayLabel={todayLabel}
        />
      )}
      <div className={classes.periodDates}>
        <Button
          aria-hidden='true'
          classes={{ root: classes.datesButton }}
          style={unset ? { textTransform: 'uppercase', color: COLORS.alert } : {}}
          onClick={() => setIsPeriodDialogOpen(true)}
          // disabled={!sensorIDs || isEmpty(sensorIDs)}
        >
          <Icon className={classes.calButton} icon={unset ? calendarAlert : calendarRange} />
          {unset
            ? `${i18n.set_storage_period || 'Set storage period'}`
            : `${format(fromDate, dateFormat ?? 'yyyy-MM-dd')} \u2013 ${
                ongoing && todayLabel ? todayLabel : format(toDate, dateFormat ?? 'yyyy-MM-dd')
              }`}
        </Button>
      </div>
    </div>
  );
};

export default StoragePeriodView;
