// PUBLIC IMPORTS
import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';

import { isNumber, range, size } from 'lodash';
import {
  VictoryChart,
  VictoryLine,
  // VictoryScatter,
  VictoryAxis,
  // VictoryTooltip,
  VictoryVoronoiContainer
} from 'victory';
import localeFormat from 'utils/localeFormat';
import { COLORS } from 'utils/colors';
// import { getGradientColor } from 'assets/utils';
import { useStyles, chartStyles } from './SensorChartType.styled';
import CustomAllTooltip from './CustomAllTooltip';
// import NoteTooltip from './NoteTooltip';
// import NoteScatterPoint from './NoteScatterPoint';
import { getDataForChart, linearGradient } from './helpers';

const initialState = {
  dataTempValue: [{ x: 0, y: -1000 }],
  dataHandleValue: [{ x: 0, y: -1000 }],
  dataSignalValue: [{ x: 0, y: -1000 }],
  dataBattValue: [{ x: 0, y: -1000 }],
  minValue: -10,
  maxValue: 110,
  customRange: range(-10, 110, 10),
  domain: [0, 1],
  rangeTime: [0],
  rangeLabel: false,
  rangeFormat: '',
  reference: [],
  referenceLabel: false,
  referenceFormat: '',
  tooltipFormat: ''
};

export default function SensorAllChart({
  min,
  max,
  sensorData,
  dataTemp,
  dataBatt,
  dataSignal,
  logs,
  isSnapShot,
  i18n,
  dateFormat,
  timeFormat
}) {
  const classes = useStyles();
  const [state, setState] = useState(initialState);
  const handleData = isSnapShot
    ? []
    : state.dayStarts &&
      sensorData &&
      sensorData
        .filter((d) => !!d.hh)
        .filter((sensor) => sensor.b * 1000 >= +state.dayStarts[1])
        .map((sensor) => ({ x: sensor.b * 1000, y: sensor.hh[0] }));

  const tempRange = [
    Math.min(...dataTemp.map((t) => t.y).filter(Boolean)),
    Math.max(...dataTemp.map((t) => t.y).filter(Boolean))
  ];
  if (Number.isNaN(tempRange[0])) {
    tempRange[0] = 0;
  }
  if (Number.isNaN(tempRange[1])) {
    tempRange[1] = 100;
  }
  const gradient = linearGradient(tempRange[0], tempRange[1]);

  // console.log('SensorAllChart sensorData', sensorData);
  // console.log('SensorAllChart dataTemp', dataTemp);
  // console.log('SensorAllChart handleData', handleData);

  useEffect(() => {
    if (!dataTemp[1]) {
      setState({ domain: [0, 1] });
    }
    if (isNumber(dataTemp[1]?.y)) {
      setState({
        dataTempValue: dataTemp,
        dataHandleValue: dataTemp,
        dataSignalValue: dataSignal,
        dataBattValue: dataBatt,
        ...getDataForChart(min, max, dataTemp, -10, 110, isSnapShot, [])
      });
    }
  }, [min, max, dataTemp, dataSignal, dataBatt, isSnapShot]);

  const getTickFormat = (value) =>
    (state.rangeLabel &&
      format(new Date(value), state.rangeFormat, { locale: localeFormat(i18n.locale) })) ||
    (state.reference.findIndex((time) => time === value) !== -1
      ? format(new Date(value), state.referenceFormat, { locale: localeFormat(i18n.locale) })
      : '');

  return (
    <div
      className={
        isSnapShot ? [classes.mainContainer, classes.overviewBg].join(' ') : classes.mainContainer
      }
      id='chartMainContainer'
    >
      <VictoryChart
        style={chartStyles.victoryChart}
        padding={{ top: 50, bottom: 50, left: 70, right: 50 }}
        width={document.getElementById('chartMainContainer')?.offsetWidth || 0}
        height={document.getElementById('chartMainContainer')?.offsetHeight || 0}
        scale={{ x: 'linear', y: 'linear' }}
        containerComponent={
          <VictoryVoronoiContainer
            labels={() => [state.tooltipFormat, -10, 110]}
            labelComponent={
              <CustomAllTooltip
                tempData={state.dataTempValue}
                signalData={state.dataSignalValue}
                battData={state.dataBattValue}
                handleData={handleData}
                mode={10}
                i18n={i18n}
                dateFormat={dateFormat}
                timeFormat={timeFormat}
                chartHei={document.getElementById('chartMainContainer')?.offsetHeight || 0}
              />
            }
            responsive={false}
            voronoiBlacklist={['data1', 'noteScatter']}
          />
        }
      >
        <defs>
          <filter id='shadow'>
            <feDropShadow dx='0' dy='1' stdDeviation='1' floodOpacity='0.4' />
          </filter>
        </defs>
        <VictoryAxis
          domain={state.domain}
          tickValues={state.rangeTime}
          tickFormat={(value) => getTickFormat(value)}
          offsetY={50}
          style={chartStyles.verticalGrid}
        />
        {size(logs) > 0 && (
          <VictoryAxis
            domain={state.domain}
            tickValues={logs.map((log) => log.log_ts)}
            tickFormat={() => null}
            offsetY={50}
            style={chartStyles.verticalGrid2}
          />
        )}
        <VictoryAxis
          domain={state.domain}
          tickValues={state.reference}
          offsetY={50}
          style={chartStyles.rangeStick}
        />
        <VictoryAxis
          dependentAxis
          domain={[-10, 100]}
          tickValues={range(0, 110, 10)}
          // tickFormat={(value) => `${value}`}
          tickFormat={() => ''}
          style={chartStyles.horizontalGrid}
        />
        <VictoryLine
          name='dataTemp'
          data={state.dataTempValue.filter((data) => data.y !== null)}
          domain={{
            x: state.domain,
            y: [-10, 110]
          }}
          interpolation='monotoneX'
          style={{
            data: {
              stroke: 'url(#colorGradient)',
              strokeWidth: 3
            }
          }}
        />
        <VictoryLine
          name='dataHandle'
          data={handleData?.filter((data) => data.y !== null)}
          domain={{
            x: state.domain,
            y: [-10, 110]
          }}
          interpolation='monotoneX'
          style={{ data: { stroke: COLORS.boulderGray, strokeWidth: 1 } }}
        />

        <VictoryLine
          name='dataSignal'
          data={state.dataSignalValue.filter((data) => data.y !== null)}
          domain={{
            x: state.domain,
            y: [-10, 110]
          }}
          interpolation='monotoneX'
          style={{ data: { stroke: COLORS.chetwodeBlue, strokeWidth: 2 } }}
        />

        <VictoryLine
          name='dataBatt'
          data={state.dataBattValue.filter((data) => data.y !== null)}
          domain={{
            x: state.domain,
            y: [-10, 110]
          }}
          interpolation='monotoneX'
          style={{ data: { stroke: COLORS.orange, strokeWidth: 2 } }}
        />
      </VictoryChart>
      <svg style={{ height: 0 }}>
        <defs>{gradient}</defs>
        <filter id='shadow'>
          <feDropShadow dx='0' dy='1' stdDeviation='1' floodOpacity='0.4' />
        </filter>
      </svg>
    </div>
  );
}
