// PUBLIC IMPORTS
import React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';

class PeriskopSVG extends React.Component {
  render() {
    return (
      <div id={this.props.id} style={this.props.style}>
        <svg
          id='Calque_1'
          data-name='Calque 1'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 1623.77 417.85'
        >
          <path
            style={{ fill: '#fff' }}
            d='M657.82,152.74A63.19,63.19,0,0,0,624,143.47a56.18,56.18,0,0,0-26.41,6.18,53.25,53.25,0,0,0-12.08,8.71V146.28h-36.8V339H585.2V270.11a49.9,49.9,0,0,0,12.36,8.76,58.28,58.28,0,0,0,26.41,5.9,62.49,62.49,0,0,0,33.71-9.27,65.69,65.69,0,0,0,23.45-25.28q8.57-16,8.57-36,0-20.22-8.43-36.23A64.32,64.32,0,0,0,657.82,152.74Zm-10.4,80.2A33.51,33.51,0,0,1,635.48,246a32.5,32.5,0,0,1-17.69,4.77A34.73,34.73,0,0,1,599.67,246a30.66,30.66,0,0,1-12.08-13.07,42.35,42.35,0,0,1-4.07-19,39.25,39.25,0,0,1,4.35-18.82A33.46,33.46,0,0,1,600,182.24a35.4,35.4,0,0,1,35.53,0,33.79,33.79,0,0,1,11.94,12.92A39.37,39.37,0,0,1,651.78,214,40.16,40.16,0,0,1,647.42,232.94Z'
          />
          <path
            style={{ fill: '#fff' }}
            d='M810.49,151.9q-15.18-8.7-34.27-8.71a72.51,72.51,0,0,0-36.52,9.27A68.78,68.78,0,0,0,714,177.74q-9.4,16-9.4,36.24,0,20.51,9.55,36.52a68.84,68.84,0,0,0,26.26,25.28q16.71,9.27,38.06,9.27a78.31,78.31,0,0,0,30.62-5.9,66.53,66.53,0,0,0,24.44-17.7l-21.91-21.91A36.85,36.85,0,0,1,797,250.22a50.22,50.22,0,0,1-18.82,3.37A41.29,41.29,0,0,1,758,248.81a31.87,31.87,0,0,1-13.35-13.9,40.24,40.24,0,0,1-2.86-7.76l99.07-.25a49.67,49.67,0,0,0,1.41-8.28c.18-2.53.28-5,.28-7.45q0-19.66-8.43-35.11A61,61,0,0,0,810.49,151.9Zm-53.66,27a37.13,37.13,0,0,1,19.11-4.78q10.38,0,17.41,4.22a28.39,28.39,0,0,1,11,12.36,39.43,39.43,0,0,1,2.92,8.75l-65.48.19a40.05,40.05,0,0,1,2.58-7.12A31.45,31.45,0,0,1,756.83,178.87Z'
          />
          <path
            style={{ fill: '#fff' }}
            d='M955.29,146.7a46,46,0,0,0-17.84-3.23q-22.76,0-34.83,14.47c-.1.12-.18.25-.28.37v-12h-36.8V282h36.8V207.24q0-15.45,7.44-22.75T929,177.18a29.79,29.79,0,0,1,10,1.55,17.14,17.14,0,0,1,7.44,5.19l23-26.68A39.36,39.36,0,0,0,955.29,146.7Z'
          />
          <rect style={{ fill: '#fff' }} x='982.12' y='146.28' width='37.36' height='135.68' />
          <path
            style={{ fill: '#fff' }}
            d='M1000.94,82.23a19.71,19.71,0,0,0-14.89,6.19,21.9,21.9,0,0,0,0,29.91,19.91,19.91,0,0,0,14.89,6q9.27,0,15-6a22.29,22.29,0,0,0,0-29.91Q1010.21,82.24,1000.94,82.23Z'
          />
          <path
            style={{ fill: '#fff' }}
            d='M1088.58,176.9q4.22-2.52,12.64-2.53a39.27,39.27,0,0,1,16.43,3.52,39.88,39.88,0,0,1,13.91,10.81l22.19-22.47a54.78,54.78,0,0,0-22.62-17.56,77.69,77.69,0,0,0-30.47-5.76q-16,0-27.81,5.2A41.22,41.22,0,0,0,1054.59,163a39.79,39.79,0,0,0-6.46,22.61q0,12.36,5,20.23A36.19,36.19,0,0,0,1066,218.05a77,77,0,0,0,16.44,6.89l16.43,4.77a57.21,57.21,0,0,1,12.78,5.2,9.39,9.39,0,0,1,5.06,8.57q0,4.77-4.78,7.44t-13.76,2.67a51.32,51.32,0,0,1-20.23-3.93,41.8,41.8,0,0,1-16-12.08l-22.19,22.19a68.29,68.29,0,0,0,16,13.62,74.49,74.49,0,0,0,20.37,8.71,88.54,88.54,0,0,0,22.89,2.95q24.43,0,38.77-11.66t14.32-31.6q0-12.36-4.91-20.22a39,39,0,0,0-12.64-12.65,71.53,71.53,0,0,0-16.3-7.44q-8.57-2.67-16.43-4.91a60.41,60.41,0,0,1-12.64-4.92,8.42,8.42,0,0,1-4.78-7.73A7.84,7.84,0,0,1,1088.58,176.9Z'
          />
          <polygon
            style={{ fill: '#fff' }}
            points='1305.43 146.28 1262.73 146.28 1213.58 208.46 1213.58 78.86 1176.78 78.86 1176.78 281.96 1213.58 281.96 1213.58 215.78 1263.02 281.96 1307.96 281.96 1252.15 210.81 1305.43 146.28'
          />
          <path
            style={{ fill: '#fff' }}
            d='M1419.63,152.46q-16.44-9.27-37.23-9.27-20.51,0-36.93,9.41a71,71,0,0,0-26,25.29q-9.56,15.87-9.55,35.81,0,20.22,9.55,36.38a70.12,70.12,0,0,0,26,25.56q16.42,9.42,36.93,9.41,20.79,0,37.37-9.41a69.59,69.59,0,0,0,26.12-25.7,70.39,70.39,0,0,0,9.55-36.24,68.12,68.12,0,0,0-9.69-36A70.56,70.56,0,0,0,1419.63,152.46Zm-6.47,80.34a32.67,32.67,0,0,1-12.36,12.92,35.15,35.15,0,0,1-18.4,4.78,33.86,33.86,0,0,1-18-4.78,33,33,0,0,1-12.22-12.92,42.8,42.8,0,0,1,0-37.64,32.05,32.05,0,0,1,12.22-12.78,34.85,34.85,0,0,1,48.45,12.78,37.41,37.41,0,0,1,4.64,18.82A39.23,39.23,0,0,1,1413.16,232.8Z'
          />
          <path
            style={{ fill: '#fff' }}
            d='M1611.34,178a64.28,64.28,0,0,0-23.46-25.29,63.15,63.15,0,0,0-33.84-9.27,56.15,56.15,0,0,0-26.41,6.18,53.25,53.25,0,0,0-12.08,8.71V146.28h-36.8V339h36.52V270.11a49.9,49.9,0,0,0,12.36,8.76,58.25,58.25,0,0,0,26.41,5.9,62.43,62.43,0,0,0,33.7-9.27,65.72,65.72,0,0,0,23.46-25.28q8.57-16,8.57-36Q1619.77,194,1611.34,178Zm-33.85,54.91A33.59,33.59,0,0,1,1565.55,246a32.54,32.54,0,0,1-17.7,4.77,34.67,34.67,0,0,1-18.11-4.77,30.66,30.66,0,0,1-12.08-13.07,42.35,42.35,0,0,1-4.08-19,39.25,39.25,0,0,1,4.36-18.82A33.38,33.38,0,0,1,1530,182.24a32.73,32.73,0,0,1,17.55-4.78,33.08,33.08,0,0,1,18,4.78,33.87,33.87,0,0,1,11.94,12.92,39.37,39.37,0,0,1,4.36,18.82A40.16,40.16,0,0,1,1577.49,232.94Z'
          />
          <path
            style={{ fill: '#fff' }}
            d='M271.85,4H146C67.7,4,4,67.7,4,146V271.85c0,78.3,63.7,142,142,142H271.85c78.3,0,142-63.7,142-142V146C413.85,67.7,350.15,4,271.85,4ZM377.33,271.85A105.49,105.49,0,0,1,271.85,377.33H193.19V323.11H232a62.49,62.49,0,0,0,33.71-9.27,65.62,65.62,0,0,0,23.45-25.28q8.56-16,8.57-35.95,0-20.24-8.43-36.24a64.29,64.29,0,0,0-23.45-25.28A63.19,63.19,0,0,0,232,181.82h-6.33A105.47,105.47,0,0,0,120.16,287.29v86.84A105.52,105.52,0,0,1,40.52,271.85V146A105.49,105.49,0,0,1,146,40.52H271.85A105.49,105.49,0,0,1,377.33,146ZM191.51,252.33a39.34,39.34,0,0,1,4.35-18.82,33.48,33.48,0,0,1,12.08-12.93,32.73,32.73,0,0,1,17.56-4.77,33.1,33.1,0,0,1,18,4.77,34,34,0,0,1,11.94,12.93,39.34,39.34,0,0,1,4.35,18.82,40.22,40.22,0,0,1-4.35,19,33.64,33.64,0,0,1-11.94,13.06,32.47,32.47,0,0,1-17.7,4.77,34.64,34.64,0,0,1-18.12-4.77,30.69,30.69,0,0,1-12.08-13.06A42.55,42.55,0,0,1,191.51,252.33Z'
          />
        </svg>
      </div>
    );
  }
}

const styles = (theme) => ({
  hide: {
    display: 'none'
  }
});

export default withRouter(withWidth()(withStyles(styles)(PeriskopSVG)));
