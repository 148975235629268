import { Typography, Dialog, DialogTitle } from '@material-ui/core';
import React, { useState, useEffect } from 'react';

import { tempFormat, fToCelsius } from 'assets/utils';
import { DeleteDialog } from 'utils/DeleteDialog';
import { DialogActionUtil } from 'utils/DialogActions';
import { TEMPERATURE_MARKS } from '../../../../../settings/SettingProps';

const AbsRuleEditor = ({
  QSlider,
  classes,
  i18n,
  degrees,
  selectedRuleType,
  selectedRule,
  setMonitorEdited,
  monitorEdited,
  newRule,
  setNewRule,
  setSelectedRuleType,
  setSelectRuleTypeDialog,
  setSelectedRule,
  setRuleInUseEdited,
  monitorInUse,
  selectedMonitor,
  timeStamp
}) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  // RULE TYPE GROUP 1
  const [temperature, setTemperature] = useState(45);
  // const [tempRef, setTempRef] = useSstate(''); // state.type);
  // const [depth, setDepth] = useState(''); // state.type);

  const onResetStates = () => {
    setNewRule(false);
    setSelectRuleTypeDialog(false);
    setSelectedRuleType(null);
    setSelectedRule(null);
    setOpenDeleteDialog(false);

    // RULE TYPE 1
    setTemperature(tempFormat(degrees, 45, 1));
    // setTempRef('');
    // setDepth('');
  };

  const handleDeleteRule = () => {
    setMonitorEdited({
      ...monitorEdited,
      rules: monitorEdited.rules.filter((ruleToDelete) => ruleToDelete !== selectedRule)
    });

    onResetStates();
  };

  useEffect(() => {
    if (selectedRule) {
      // RULE TYPE GROUP 1
      setTemperature(
        selectedRule?.settings?.alertLimit !== undefined
          ? tempFormat(degrees, Number(selectedRule.settings?.alertLimit), 0)
          : tempFormat(degrees, 45, 0)
      ); // state.settings?.alertLimit);
      // setDepth(
      //   selectedRule?.settings?.depthProbe !== undefined
      //     ? Number(selectedRule.settings?.depthProbe)
      //     : ''
      // ); // state.settings?.alertLimit);
      // setTempRef(
      //   selectedRule?.settings?.tempRef
      //     ? ruleTypes
      //         .filter(type => type.type === selectedRule?.type)[0]
      //         ?.refs.filter(ref => ref.name === selectedRule?.settings?.tempRef)[0]?.id
      //     : ''
      // );
    }
  }, [degrees, selectedRule]);

  const onSaveABSRule = (selectedRuleToEdit) => {
    const settingsToSave = {
      alertLimit: degrees === '°C' ? temperature : fToCelsius(temperature),
      // depthProbe: depth !== '' ? depth : null,
      tempRef: 'SINGLE' // selectedRuleType?.refs?.filter(ref => ref.id === tempRef)[0]?.name
    };

    if (selectedRuleToEdit) {
      return {
        id: selectedRuleToEdit.id,
        type: selectedRuleType.type,
        ruleChanged: {
          changedAt: timeStamp,
          oldSettings: selectedMonitor && {
            ...selectedMonitor.rules.find((rule) => rule.id === selectedRuleToEdit.id)?.settings
          },
          newSettings: settingsToSave
        },
        settings: settingsToSave
      };
    }
    return {
      id:
        monitorEdited.rules.length >= 1
          ? Math.max(...monitorEdited.rules?.map((s) => +s.id)) + 1
          : 1,
      type: selectedRuleType.type,
      settings: settingsToSave
    };
  };

  const onSaveRule = () => {
    let ruleData;
    if (selectedRule) {
      if (selectedRuleType.group === 'ABS') {
        ruleData = onSaveABSRule(selectedRule);
        console.log('ruleData sel', ruleData, onSaveABSRule(selectedRule));
      } else {
        return;
      }

      setMonitorEdited({
        ...monitorEdited,
        rules: monitorEdited.rules.map((rule) =>
          rule.id === ruleData.id
            ? {
                ...monitorEdited.rules.find((ruleEdited) => ruleEdited.id === ruleData.id),
                ...ruleData
              }
            : { ...rule }
        )
      });
    } else {
      if (selectedRuleType.group === 'ABS') {
        ruleData = onSaveABSRule();
      } else {
        return;
      }

      console.log('ruleData', ruleData);

      if (monitorEdited.rules) {
        setMonitorEdited({
          ...monitorEdited,
          rules: [...monitorEdited.rules, ruleData]
        });
      } else {
        setMonitorEdited({
          ...monitorEdited,
          rules: [ruleData]
        });
      }
    }
    if (monitorInUse) {
      setRuleInUseEdited(true);
    }
    onResetStates();
  };

  const disabledSave = (typeGroupToSave) => {
    switch (typeGroupToSave) {
      case 'ABS':
        return false; // tempRef === '' || (tempRef === 3 && depth === '');
      default:
        break;
    }
  };

  const minSlider = degrees === '°C' ? 0 : 20;
  const maxSlider = degrees === '°C' ? 100 : 220;

  return (
    <Dialog className={classes.dialog} open={newRule} aria-labelledby='responsive-dialog-title'>
      <DialogTitle>
        <div className={classes.DialogTitle}>
          <div>{i18n[`${selectedRuleType?.type}_title`] || selectedRuleType?.title}</div>
        </div>
      </DialogTitle>
      <div className={classes.div}>
        <div className={classes.div}>
          <Typography style={{ marginBottom: 40 }} id='button-group' gutterBottom>
            {i18n.alert_limit || 'Alert limit'}
          </Typography>
        </div>
        <div className={classes.divQSlider}>
          <QSlider
            min={minSlider}
            max={maxSlider}
            track={selectedRuleType?.type === 'ABS_MAX' ? 'inverted' : 'normal'}
            // track='inverted'
            value={+temperature}
            marks={TEMPERATURE_MARKS(minSlider, maxSlider, degrees)}
            valueLabelFormat={`${temperature} ${degrees}`}
            onChange={(event, newValue) => setTemperature(newValue)}
            valueLabelDisplay='on'
            aria-labelledby='greater or lower than selected temperature'
          />
        </div>
      </div>
      {/* <Divider />
      <form className={classes.dialog} noValidate autoComplete='on'> */}
      {/* {selectedRuleType?.group === 'ABS' && (
          <>
            <FormControl className={classes.formControl}>
              <InputLabel required id='demo-simple-select-label'>
                {i18n.ref_temp || 'Reference temperature'}
              </InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={tempRef}
                onChange={e => setTempRef(e.target.value)}
                disabled={selectedRuleType?.group === 'REL'}
              >
                <MenuItem value=''>
                  <em> {i18n.ref_temp || 'Reference temperature'}</em>
                </MenuItem>
                {selectedRuleType?.refs?.map(ref => (
                  <MenuItem key={ref.id} value={ref.id}>
                    {i18n[ref.name] || ref.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {tempRef === 3 && DepthSelect} */}
      {/* </>
        )} */}
      {/* </form> */}
      <DialogActionUtil
        onCancel={onResetStates}
        onSave={onSaveRule}
        text={i18n.save || 'Save'}
        disabled={disabledSave(selectedRuleType?.group)}
        deleteButton={selectedRule}
        handleDelete={() => setOpenDeleteDialog(true)}
      />
      <DeleteDialog
        i18n={i18n}
        openDeleteDialog={openDeleteDialog}
        handleCloseDeleteDialog={() => setOpenDeleteDialog(false)}
        handleDelete={handleDeleteRule}
        text={i18n.alert_remove_rule || 'Do you want to remove this rule?'}
      />
    </Dialog>
  );
};

export default AbsRuleEditor;
