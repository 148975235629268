// PUBLIC IMPORTS
import { Paper, Tab, Tabs, Typography, Tooltip } from '@material-ui/core';
import React from 'react';

// REDUX
import { useSelector } from 'react-redux';
import { Icon } from '@iconify/react';
import shieldAccount from '@iconify/icons-mdi/shield-account';

import SensorChart from 'components/probes/sensor/sensor-chart/SensorChart';
// import SensorNotes from 'components/sensor/sensor-notes/SensorNotes';
import SensorProperties from 'components/probes/sensor/sensor-properties/SensorProperties';
import SensorData from 'components/probes/sensor/sensor-data/SensorData';
import { useStyles } from './SensorTabView.styled';
import VerifixContainer from '../probe-verification/VerifixContainer';
import ProbeLifetimeContainer from './ProbeLifetimeContainer';

export const SensorAppBar = ({
  value,
  handleChange,
  tableEnabled,
  verifixEnabled,
  lifetimeEnabled
}) => {
  const classes = useStyles();
  const { i18n } = useSelector((state) => ({
    i18n: state.i18n
  }));

  return (
    <Paper square>
      <Tabs
        className={classes.sensorTab}
        value={value}
        onChange={(e, index) => handleChange(index)}
        indicatorColor='primary'
        textColor='primary'
        variant='fullWidth'
      >
        <Tab
          className={classes.sensorTab}
          label={
            <Typography variant='body2' color='inherit' style={{ fontSize: 11 }}>
              {i18n.history}
            </Typography>
          }
          // icon={<MdTrendingUp size='1.5em' />}
        />
        {tableEnabled && (
          <Tab
            className={classes.sensorTab}
            label={
              <Typography variant='body2' color='inherit' style={{ fontSize: 11 }}>
                {i18n.data}
              </Typography>
            }
            // icon={<MdFormatListBulleted size='1.5em' />}
          />
        )}
        <Tab
          className={classes.sensorTab}
          label={
            <Typography variant='body2' color='inherit' style={{ fontSize: 11 }}>
              {i18n.properties}
            </Typography>
          }
          // icon={<MdLocalOffer size='1.5em' />}
        />
        {verifixEnabled && (
          <Tab
            className={classes.sensorTab}
            label={
              <Typography variant='body2' color='inherit' style={{ fontSize: 11 }}>
                {i18n.verification}
              </Typography>
            }
          />
        )}
        {lifetimeEnabled && (
          <Tooltip title='Data regularity statistics' placement='bottom' disableFocusListener>
            <Tab
              className={classes.sensorTab}
              // icon={<Icon icon={shieldAccount} />}
              // iconPosition='start'
              label={<Icon icon={shieldAccount} />}
            />
          </Tooltip>
        )}
      </Tabs>
    </Paper>
  );
};

// If tableEnabled, tabs are 0 (chart), 1(table), 2(properties)
// otherwise tabs are 0 (chart),1 (properties)
export const SensorContent = ({ value, tableEnabled, verifixEnabled }) => {
  // console.log('SensorContent', value, tableEnabled, verifixEnabled, lifetimeEnabled);
  const contentComp = [
    <SensorChart />,
    <SensorData />,
    <SensorProperties />,
    <VerifixContainer />,
    <ProbeLifetimeContainer />
  ];

  if (!tableEnabled) {
    contentComp.splice(1, 1);
  }
  if (!verifixEnabled) {
    contentComp.splice(3, 1);
  }

  return contentComp[value];
};
