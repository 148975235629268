import { Slider, IconButton, Typography } from '@material-ui/core';
import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Icon } from '@iconify/react';
import squareMedium from '@iconify/icons-mdi/square-medium';
import squareIcon from '@iconify/icons-mdi/square';
import gridIcon from '@iconify/icons-mdi/grid';
import layersIcon from '@iconify/icons-mdi/layers';
import cardsDiamond from '@iconify/icons-mdi/cards-diamond';
import closeIcon from '@iconify/icons-mdi/close';
import { useDispatch } from 'react-redux';
import { COLORS } from 'utils/colors';

const useStyles = makeStyles(() => ({
  title: {
    padding: '10px 0 0',
    marginRight: 20,
    maxWidth: 200,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  sliderWrapper: {
    display: 'flex',
    width: 200,
    height: 40,
    margin: '15px 0',
    position: 'relative'
  },
  slider: {
    marginTop: 4
  },
  iconWrapperLeft: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 20
  },
  iconWrapperRight: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 20
  },
  infoIcon: {
    width: 20,
    height: 20,
    color: COLORS.boulderGray
  },
  closeButton: {
    float: 'right'
  },
  doubleIcon: {
    display: 'flex',
    flexFlow: 'column',
    transform: 'scale(2, 0.7)'
  },
  closeX: {
    margin: 0,
    padding: 0,
    position: 'absolute',
    right: 8,
    top: 8,
    width: 24,
    height: 24
  }
}));

const PrettoSlider = withStyles({
  root: {
    color: COLORS.primaryColor,
    height: 8
  },
  thumb: {
    height: '24px !important',
    width: '24px !important',
    backgroundColor: COLORS.white,
    border: '2px solid currentColor',
    marginTop: '-8px !important',
    marginLeft: '-12px !important',
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit'
    }
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
    fontFamily: `'Roboto', sans-serif`,
    letterSpacing: -0.5
  },
  track: {
    height: 8,
    borderRadius: 4,
    backgroundColor: 'transparent !important'
  },
  rail: {
    height: 8,
    borderRadius: 4
  }
})(Slider);

const LayoutControlPanel = ({
  // currentAngle,
  // currentSize,
  // currentSpace,
  // onAngleChanged,
  // onSizeChanged,
  visuAngle,
  maxAngle,
  visuCellsize,
  minCellSize,
  visuSpacing,
  setVisuAngle,
  setVisuCellsize,
  setVisuSpacing,
  onClose,
  nLayers,
  i18n,
  setIsStorageEdited,
  setSelectedStorage
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // const [angle, setAngle] = useState(visuAngle);
  // const [size, setSize] = useState(visuCellsize);
  // const [space, setSpace] = useState(currentSpace);

  const angleSliderChanged = (e, value) => {
    setVisuAngle(value);
  };

  const angleSlidingDone = (e, value) => {
    setVisuAngle(value);
    setIsStorageEdited(true);
    dispatch({ type: 'SET_EDIT_CONFIRM_NEEDED', value: true });
    setSelectedStorage((tempStorage) => ({
      ...tempStorage,
      layout_prefs: {
        ...tempStorage.layout_prefs,
        angleDefault: value
      }
    }));
  };

  const sizeSliderChanged = (e, value) => {
    setVisuCellsize(value);
  };

  const sizeSlidingDone = (e, value) => {
    setVisuCellsize(value);
    setIsStorageEdited(true);
    dispatch({ type: 'SET_EDIT_CONFIRM_NEEDED', value: true });
    setSelectedStorage((tempStorage) => ({
      ...tempStorage,
      layout_prefs: {
        ...tempStorage.layout_prefs,
        sizeDefault: value
      }
    }));
  };

  const spaceSliderChanged = (e, value) => {
    setVisuSpacing(value);
  };

  const spaceSlidingDone = (e, value) => {
    setVisuSpacing(value);
    setIsStorageEdited(true);
    dispatch({ type: 'SET_EDIT_CONFIRM_NEEDED', value: true });
    setSelectedStorage((tempStorage) => ({
      ...tempStorage,
      layout_prefs: {
        ...tempStorage.layout_prefs,
        spaceDefault: value
      }
    }));
  };

  const getSpacingIcon = () => (
    <div className={classes.doubleIcon}>
      <Icon
        className={classes.spaceIcon}
        style={{ color: nLayers === 1 ? COLORS.lightGray2 : COLORS.boulderGray }}
        icon={cardsDiamond}
      />
      <Icon
        className={classes.spaceIcon}
        style={{ color: nLayers === 1 ? COLORS.lightGray2 : COLORS.boulderGray }}
        icon={cardsDiamond}
      />
    </div>
  );

  return (
    <>
      <Typography className={classes.title} color='textSecondary'>
        {i18n.layout_appearance || 'Layout appearance'}
      </Typography>
      <IconButton
        button='true'
        color='inherit'
        aria-label={i18n.close}
        className={classes.closeX}
        onClick={onClose}
      >
        <Icon icon={closeIcon} />
      </IconButton>
      <div className={classes.sliderWrapper}>
        <div className={classes.iconWrapperLeft}>
          <Icon className={classes.infoIcon} icon={gridIcon} />
        </div>
        <PrettoSlider
          className={classes.slider}
          value={visuAngle}
          // orientation='vertical'
          min={0}
          max={maxAngle || 60}
          step={5}
          onChange={angleSliderChanged}
          onChangeCommitted={angleSlidingDone}
          valueLabelDisplay='off'
          aria-labelledby='continuous-slider'
        />
        <div
          className={classes.iconWrapperRight}
          style={{ transform: 'skewX(30deg) scaleY(0.7) scaleX(0.9)' }}
        >
          <Icon className={classes.infoIcon} icon={gridIcon} />
        </div>
      </div>
      <div className={classes.sliderWrapper}>
        <div className={classes.iconWrapperLeft}>
          <Icon className={classes.infoIcon} icon={squareMedium} />
        </div>
        <PrettoSlider
          className={classes.slider}
          value={visuCellsize}
          min={minCellSize || 35}
          max={80}
          step={2}
          onChange={sizeSliderChanged}
          onChangeCommitted={sizeSlidingDone}
          valueLabelDisplay='off'
          aria-labelledby='continuous-slider'
        />
        <div className={classes.iconWrapperRight}>
          <Icon className={classes.infoIcon} icon={squareIcon} />
        </div>
      </div>

      <div className={classes.sliderWrapper}>
        <div className={classes.iconWrapperLeft}>
          <Icon
            style={nLayers === 1 ? { color: COLORS.lightGray2 } : {}}
            className={classes.infoIcon}
            icon={layersIcon}
          />
        </div>
        <PrettoSlider
          className={classes.slider}
          value={visuSpacing}
          min={0}
          max={100}
          step={2}
          disabled={nLayers <= 1}
          onChange={spaceSliderChanged}
          onChangeCommitted={spaceSlidingDone}
          valueLabelDisplay='off'
          aria-labelledby='continuous-slider'
        />
        <div className={classes.iconWrapperRight}>{getSpacingIcon(nLayers)}</div>
      </div>
    </>
  );
};
export default LayoutControlPanel;
