import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'utils/colors';
import { getGradientColor } from 'assets/utils';

const useStyles = makeStyles(() => ({
  sensorHandle: {
    backgroundColor: COLORS.lightGray2,
    zIndex: 4,
    height: 45,
    width: 30,
    top: 15,
    position: 'absolute',
    left: 'calc(50% - 13px)',
    borderRadius: '5px 5px  0 0',
    color: COLORS.white
  },
  sensorID: {
    height: 32,
    zIndex: 6,
    position: 'absolute',
    left: 0,
    right: 0,
    border: `2px solid ${COLORS.primaryColor}`,
    boxSizing: 'border-box',
    borderRadius: 4,
    backgroundColor: COLORS.lightGray2,
    color: COLORS.primaryColor,
    textAlign: 'center',
    width: 'inherit'
  },

  clickable: {
    cursor: 'pointer'
  },
  sensorIDseries: {
    fontSize: 10
    // marginTop: 7
  },
  sensorIDnumber: {
    fontSize: 13,
    lineHeight: '15px'
  }
}));

export default function SensorHandle({ id, onSensorClicked, temperature }) {
  const classes = useStyles();
  const sensorHandleClasses = [classes.sensorHandle];

  if (onSensorClicked) {
    sensorHandleClasses.push(classes.clickable);
  }

  return (
    <div
      className={classes.sensorID}
      style={{
        position: 'unset',
        backgroundColor: temperature !== null ? getGradientColor(temperature) : COLORS.ightGray2
      }}
    >
      <div className={classes.sensorIDseries}>{id.substr(0, 3)}</div>
      <div className={classes.sensorIDnumber}>{id.substr(3, 3)}</div>
    </div>
  );
}
