import { SET_SHOW_HANDLE } from 'redux/constants';
import initialState from 'redux/store/initialState';

export default function setSessionInfo(state = initialState.showHandle, action) {
  switch (action.type) {
    case SET_SHOW_HANDLE: {
      return action.value;
    }
    default: {
      return state;
    }
  }
}
