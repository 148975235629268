import { isEmpty } from 'lodash';
// import cowIcon from '@iconify/icons-mdi/cow';
import grass from '@iconify/icons-mdi/grass';
import cube from '@iconify/icons-mdi/cube';
import cylinder from '@iconify/icons-mdi/cylinder';
import cubeOutline from '@iconify/icons-mdi/cube-outline';

export const insightBackground = {
  activityType: {
    key: 'activityType',
    title: 'What describes your activity?',
    label: 'Type of activity',
    type: 'radio',
    options: [
      { label: 'Livestock farm', value: 'livestock' },
      { label: 'Dairy farm', value: 'dairy' },
      { label: 'Hay producer', value: 'hay' },
      { label: 'Research', value: 'research' },
      { label: 'Other', value: 'other' }
    ],
    value: null,
    hide: false,
    extra: {
      key: 'activityOther',
      title: 'activityOther',
      label: 'Type of activity',
      type: 'text',
      value: '',
      hide: true
    }
  },
  hayType: {
    key: 'hayType',
    title: 'Type of hay',
    label: 'Type of hay',
    type: 'toggle',
    options: [
      { value: 'bales', label: 'Bales', icon: cube },
      { value: 'loose', label: 'Loose', icon: grass }
    ],
    value: null,
    hide: false
  },
  yearlyCuts: {
    key: 'yearlyCuts',
    title: 'Number of hay cuts in normal year',
    label: 'Number of hay cuts',
    type: 'slider',
    value: 0,
    step: 1,
    marks: true,
    min: 0,
    max: 8,
    hide: false,
    extra: {
      key: 'cutsNonApplicable',
      title: 'Not applicable',
      label: 'Number of hay cuts',
      type: 'radio',
      options: [true, false],
      value: false
    }
  },
  weightUnit: {
    key: 'weightUnit',
    title: 'Weight Unit',
    label: 'Weight Unit',
    type: 'radio',
    options: [
      { label: 'Kilogram (kg)', value: 'kg' },
      { label: 'Pound (lb)', value: 'lb' }
    ],
    value: 'kg',
    hide: false
  },
  hayTypeUnit: {
    key: 'hayTypeUnit',
    title: 'Hay volume unit',
    label: 'Hay volume unit',
    type: 'toggle',
    options: [
      { value: 'bales', label: 'Bales' },
      { value: 'tons', label: 'Tons' }
    ],
    value: 'bales',
    hide: true
  },
  balesPerYear: {
    key: 'balesPerYear',
    title: 'Hay volume processed annually (bales)',
    label: 'Number of bales',
    type: 'slider',
    value: 0,
    step: [200, 500, 10000],
    marks: false,
    min: 0,
    max: [3000, 10000, 50000],
    hide: false
  },
  averageTonsPerYear: {
    key: 'averageTonsPerYear',
    title: 'Hay volume processed annually (tons)',
    label: 'Tons of hay',
    type: 'slider',
    // valueLabel: 't',
    value: 0,
    step: [200, 500, 10000],
    marks: false,
    min: 0,
    max: [3000, 10000, 50000],
    hide: false
  },
  balesType: {
    key: 'balesType',
    title: 'Type of hay bales',
    label: 'Type of hay bales',
    type: 'toggle',
    options: [
      { value: 'square', label: 'Square', icon: cubeOutline },
      { value: 'round', label: 'Round', icon: cylinder }
    ],
    value: null,
    hide: false
  },
  balesWeight: {
    key: 'balesWeight',
    title: 'Weight of a hay bale',
    label: 'Bale weight',
    type: 'slider',
    labelOption: 'kg',
    value: 0,
    step: 50,
    marks: true,
    min: 0,
    max: [1000, 2000],
    hide: false
  },
  storageTime: {
    key: 'storageTime',
    title: 'Typical hay storage time',
    label: 'Hay storing time',
    type: 'slider',
    value: [0, 0],
    step: 1,
    marks: true,
    min: 0,
    max: 12,
    extra: true,
    hide: false
  },
  storagesNumber: {
    key: 'storagesNumber',
    title: 'Number of hay storages on site',
    label: 'storagesNumber',
    type: 'slider',
    value: 0,
    step: 1,
    marks: true,
    min: 0,
    max: 10,
    hide: true
  }
};

export const generateAnswersObject = (answers) => {
  const answersObject = { ...insightBackground };

  if (isEmpty(answers)) {
    return answersObject;
  }

  answersObject.activityType.value = answers.activityType ?? answersObject.activityType.value;
  answersObject.activityType.extra.value =
    answers.activityOther ?? answersObject.activityType.extra.value;
  answersObject.hayType.value = answers.hayType ?? answersObject.hayType.value;
  if (answers.yearlyCuts === -1) {
    answersObject.yearlyCuts.extra.value = true;
  } else {
    answersObject.yearlyCuts.value = answers.yearlyCuts ?? answersObject.yearlyCuts.value;
    answersObject.yearlyCuts.extra.value = false;
  }
  answersObject.weightUnit.value = answers.weightUnit ?? answersObject.weightUnit.value;
  answersObject.hayTypeUnit.value = answers.hayTypeUnit ?? answersObject.hayTypeUnit.value;
  answersObject.balesPerYear.value = answers.balesPerYear ?? answersObject.balesPerYear.value;
  answersObject.averageTonsPerYear.value =
    answers.averageTonsPerYear ?? answersObject.averageTonsPerYear.value;
  answersObject.balesType.value = answers.balesType ?? answersObject.balesType.value;
  answersObject.balesWeight.value = answers.balesWeight ?? answersObject.balesWeight.value;
  answersObject.balesWeight.labelOption = answers.weightUnit ?? answersObject.weightUnit.value;
  answersObject.storageTime.value = answers.storageTime ?? answersObject.storageTime.value;
  answersObject.storagesNumber.value = answers.storagesNumber ?? answersObject.storagesNumber.value;

  return answersObject;
};
