import { Typography, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { InlineIcon } from '@iconify/react';
import { isEmpty } from 'lodash';
import nail from '@iconify/icons-mdi/nail';
import grid from '@iconify/icons-mdi/grid';
import cubeOutline from '@iconify/icons-mdi/cube-outline';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { COLORS } from 'utils/colors';
// import StoragePropertiesControls from './StoragePropertiesControls';
import StackSizeControls from './StackSizeControls';
import PilesControls from './PilesControls';
import ProbeControls from './ProbeControls';

const Accordion0margin = withStyles({
  root: {
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
})(Accordion);

const useStyles = makeStyles(() => ({
  accIcon: {
    width: 18,
    height: 18,
    marginRight: 5,
    marginBottom: -2
  },
  gridIcon: {
    width: 16,
    height: 16,
    marginLeft: 1,
    marginRight: 6,
    marginBottom: -2
  },
  accTitle: {
    fontSize: 14
  },
  selectedTitle: {
    fontWeight: 'bold'
  },
  accContent: {
    padding: 0
  },
  closedAcc: {
    backgroundColor: COLORS.whiteSmoke
  },
  propLabel: {
    fontSize: 12,
    color: COLORS.lightGray3
  },
  stackSize: {
    borderBottom: `1px solid ${COLORS.altoGray}`,
    paddingLeft: 10,
    paddingBottom: 10
  }
}));

const StackEditAccordion = ({
  viewHeight,
  width,
  selectedStorage,
  stackIndex,
  onStackSizeChange,
  onHeightChange,
  keepFullStack,
  pileHeight,
  onPileHeightChange,
  onAddBales,
  footprintToAdd,
  pilesToRemove,
  onClearSelection,
  addFullStackSelected,
  fullStackPressed,
  bales,
  editingType,
  setEditingType,
  onSensorChange,
  addingProbePosition,
  preSelectedSensor,
  setPreSelectedSensor,
  storages,
  rowSelected,
  columnSelected
}) => {
  const i18n = useSelector((state) => state.i18n);
  const [expanded, setExpanded] = useState(editingType);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    setEditingType(panel);
  };

  // const accordionHeights = 162;
  // const stackSizeHeight = 135;

  const classes = useStyles();
  return (
    <div style={{ width }}>
      <Accordion0margin expanded={expanded === 'props'} onChange={handleChange('props')}>
        <AccordionSummary
          className={expanded !== 'props' ? classes.closedAcc : null}
          expandIcon={<ExpandMoreIcon />}
          // aria-controls='panel1a-content'
          id='props'
        >
          <Typography
            className={`${classes.accTitle} ${expanded === 'props' ? classes.selectedTitle : ''}`}
          >
            <InlineIcon className={classes.gridIcon} icon={grid} />
            {i18n.stack_size || 'Stack size'}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accContent}>
          <div>
            <div className={classes.stackSize}>
              {/* <Typography variant='body1' className={classes.propLabel}>
                {i18n.stack_size || 'Stack size'}
              </Typography> */}
              <StackSizeControls
                width={width - 20}
                theStack={selectedStorage.stacks[stackIndex]}
                onLayoutChange={onStackSizeChange}
                showPileHeight={keepFullStack}
                pileHeight={pileHeight}
                onHeightChange={onHeightChange}
              />
            </div>
            {/* <StoragePropertiesControls
              viewHeight={Math.max(50, viewHeight - accordionHeights - stackSizeHeight - 10)}
              width={width}
              selectedStorage={selectedStorage}
            /> */}
          </div>
        </AccordionDetails>
      </Accordion0margin>
      {keepFullStack ? null : (
        <Accordion0margin
          style={{ marginTop: 1 }}
          expanded={expanded === 'bales'}
          onChange={handleChange('bales')}
        >
          <AccordionSummary
            className={expanded !== 'bales' ? classes.closedAcc : null}
            expandIcon={<ExpandMoreIcon />}
            // aria-controls='panel2a-content'
            id='bales'
          >
            <Typography
              className={`${classes.accTitle} ${expanded === 'bales' ? classes.selectedTitle : ''}`}
            >
              <InlineIcon className={classes.accIcon} icon={cubeOutline} />
              {i18n.bales || 'Bales'}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accContent}>
            <PilesControls
              width={width}
              pileHeight={pileHeight}
              onPileHeightChange={onPileHeightChange}
              onAddBales={onAddBales}
              footprintToAdd={footprintToAdd}
              pilesToRemove={pilesToRemove}
              onClearSelection={onClearSelection}
              bales={bales}
              addFullStackSelected={addFullStackSelected}
              fullStackPressed={fullStackPressed}
            />
          </AccordionDetails>
        </Accordion0margin>
      )}
      <Accordion0margin
        disabled={isEmpty(bales)}
        style={{ marginTop: 1 }}
        expanded={expanded === 'probes'}
        onChange={handleChange('probes')}
      >
        <AccordionSummary
          className={expanded !== 'probes' ? classes.closedAcc : null}
          expandIcon={<ExpandMoreIcon />}
          // aria-controls='panel3a-content'
          id='probes'
        >
          <Typography
            className={`${classes.accTitle} ${expanded === 'probes' ? classes.selectedTitle : ''}`}
          >
            <InlineIcon className={classes.accIcon} icon={nail} />
            {i18n.probes || 'Probes'}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accContent}>
          <ProbeControls
            viewHeight={viewHeight}
            width={width}
            selectedStorage={selectedStorage}
            onSensorChange={onSensorChange}
            addingProbePosition={addingProbePosition}
            storages={storages}
            preSelectedSensor={preSelectedSensor}
            setPreSelectedSensor={setPreSelectedSensor}
            rowSelected={rowSelected}
            columnSelected={columnSelected}
            nAccordions={keepFullStack ? 2 : 3}
          />
        </AccordionDetails>
      </Accordion0margin>
    </div>
  );
};

export default StackEditAccordion;
