import { SET_DATATABLE, RESET_DATATABLE } from 'redux/constants';
import initialState from 'redux/store/initialState';

export default function setDataTable(state = initialState.dataTable, action) {
  switch (action.type) {
    case SET_DATATABLE: {
      return {
        ...state,
        countDuplicate: action.countDuplicate,
        sensorDataTable: action.sensorDataTable
      };
    }
    case RESET_DATATABLE: {
      return {
        ...state,
        countDuplicate: { value: 0 },
        sensorDataTable: null
      };
    }
    default: {
      return state;
    }
  }
}
