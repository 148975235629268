// PUBLIC IMPORTS
import React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';

class BatchOutline extends React.Component {
  render() {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        width='12'
        height='12'
        preserveAspectRatio='xMidYMid meet'
        viewBox='0 0 12 12'
        style={this.props.style}
      >
        <polygon style={{ fill: this.props.color }} points="0.6,9 3.9,4.6 5.6,6.8 4.8,7.4 3.9,6.2 2.6,8 9.5,8 7,4.7 6,6 5.4,5.1 7,3 11.5,9 "/>
      </svg>
    );
  }
}

const styles = theme => ({
  hide: {
    display: 'none'
  }
});

export default withRouter(withWidth()(withStyles(styles)(BatchOutline)));
