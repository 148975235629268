import { Typography, InputAdornment, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { DebounceInput } from 'react-debounce-input';
import { isEmpty } from 'lodash';
import { Icon } from '@iconify/react';
import hand from '@iconify/icons-mdi/hand-pointing-right';
import handDown from '@iconify/icons-mdi/hand-pointing-down';
import magnifyIcon from '@iconify/icons-mdi/magnify';
import closeIcon from '@iconify/icons-mdi/close';
import { COLORS } from 'utils/colors';
import { getSensorType } from 'assets/utils';
import PlaceSensorList from './PlaceSensorList';

const useStyles = makeStyles(() => ({
  propsWrapper: {
    // overflowY: 'auto',
    // overflowX: 'hidden'
  },
  propsPanel: {},
  propGroup: {
    padding: '5px 5px 10px 10px'
  },
  propLabel: {
    fontSize: 12,
    color: COLORS.lightGray3
  },
  probesTitle: {
    marginTop: 10,
    fontSize: 15,
    minWidth: '75%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  searchTitle: {
    minWidth: '50%',
    maxWidth: '50%'
  },
  infoIcon: {
    marginBottom: -5,
    marginRight: 3
  },
  probesTitleAndSearch: {
    display: 'flex'
  },
  sensorFind: {
    marginRight: 10
  }
}));

const ProbeControls = ({
  viewHeight,
  width,
  selectedStorage,
  onSensorChange,
  addingProbePosition,
  preSelectedSensor,
  setPreSelectedSensor,
  rowSelected,
  columnSelected,
  storages,
  nAccordions
}) => {
  const classes = useStyles();
  const i18n = useSelector((store) => store.i18n);
  const isDemoMode = useSelector((store) => store.isDemoMode);
  const bsConf = useSelector((store) => store.bsConf);
  const auxConf = useSelector((store) => store.auxConf);
  const sensorsData = useSelector((store) => store.sensorsData.concat(auxConf || []));

  const [searchString, setSearchString] = useState('');
  const [searchMode, setSearchMode] = useState(false);

  const accOverhead = (nAccordions - 1) * 48 + 66; // N-1 x closed and 1x open accordion summary +2px

  const stackIndex = 0;

  const onSensorSelect = (id) => {
    if (addingProbePosition) {
      if (!id) {
        // Cancel placement
        onSensorChange();
        return;
      }
      onSensorChange(
        selectedStorage.stacks[stackIndex].sensors.concat([
          {
            id,
            row: addingProbePosition.r,
            column: addingProbePosition.c,
            floor: addingProbePosition.f
          }
        ])
      );
    } else if (preSelectedSensor === id) {
      setPreSelectedSensor(null);
    } else {
      setPreSelectedSensor(id);
    }
  };

  const sensorIDsInOtherStorages = storages
    ?.filter((s) => s.itemID !== selectedStorage?.itemID)
    ?.filter((s) => s.sensor_layout || s.stacks)
    ?.map((s) =>
      s.layoutType === 3 ? s.stacks.map((st) => st.sensors).flat() : s.sensor_layout.sensors
    )
    ?.flat(1)
    ?.map((s) => s.id);

  const sensorIDsInStorage = selectedStorage?.stacks[stackIndex].sensors?.map((sen) => sen.id);
  const occupiedIDs = sensorIDsInOtherStorages.concat(sensorIDsInStorage);

  const confSensors = bsConf?.sensor_configurations || [];

  // Filter sensors for the storage type: for stack storage, only Haytechs
  const sensorIdsForStorageType = confSensors.filter((s) =>
    selectedStorage.layoutType === 3
      ? getSensorType(s.sensor_id_sys, null, isDemoMode) === 'H'
      : getSensorType(s.sensor_id_sys) !== 'H'
  );

  const availableSensors = sensorIdsForStorageType.filter(
    (s) => !occupiedIDs.includes(s.sensor_id_sys)
  );

  const clearSearch = () => {
    setSearchString('');
    setSearchMode(false);
  };

  const handleSearch = (event) => {
    const keyword = event.target.value;
    setSearchString(keyword);
  };

  return (
    <div style={{ width }} className={classes.propsWrapper}>
      <div>
        {isEmpty(availableSensors) ? null : (
          <div id='probeListHeader' style={{ border: 'none' }} className={classes.propGroup}>
            <Typography variant='body1' className={classes.propLabel}>
              <Icon
                className={classes.infoIcon}
                icon={addingProbePosition ? handDown : hand}
                width={20}
              />
              {addingProbePosition
                ? i18n.select_probe_hint || 'Select a probe to the location.'
                : rowSelected
                ? i18n.selected_row_hint || 'Select a location from the row.'
                : columnSelected
                ? i18n.selected_column_hint || 'Select a location from the column.'
                : i18n.select_stack_hint || 'To add a probe, select column or row.'}
            </Typography>

            <div className={classes.probesTitleAndSearch}>
              <div
                className={`${classes.probesTitle} ${searchMode ? classes.searchTitle : ''}`}
              >{`${i18n.vacant_sensors || 'Available probes'} (${availableSensors.length})`}</div>
              <DebounceInput
                className={classes.sensorFind}
                element={TextField}
                minLength={1}
                debounceTimeout={150}
                onChange={handleSearch}
                onFocus={() => setSearchMode(true)}
                onBlur={() => isEmpty(searchString) && setSearchMode(false)}
                value={searchString}
                // placeholder={i18n.msg_table_search_tip || 'Find sensor'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start' onClick={() => clearSearch()}>
                      <Icon
                        width='20'
                        height='20'
                        icon={searchString.length === 0 ? magnifyIcon : closeIcon}
                        style={
                          searchString.length === 0
                            ? { color: COLORS.warning }
                            : { cursor: 'pointer' }
                        }
                      />
                    </InputAdornment>
                  )
                }}
              />
            </div>
          </div>
        )}
        <PlaceSensorList
          selectedStorage={selectedStorage}
          allSensors={availableSensors}
          availableSensors={availableSensors
            .filter(
              (s) =>
                s.sensor_id_sys.includes(searchString) ||
                (s.sensor_name && s.sensor_name.includes(searchString))
            )
            .map((s) => s.sensor_id_sys)}
          addingProbePosition={addingProbePosition}
          bsConf={bsConf}
          auxConf={auxConf}
          sensorsData={sensorsData}
          viewHeight={viewHeight - accOverhead}
          onSensorClick={onSensorSelect}
          selectedSensor={preSelectedSensor}
          hintChanged={addingProbePosition || rowSelected || columnSelected}
        />
      </div>
    </div>
  );
};

export default ProbeControls;
