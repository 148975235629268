import { SET_VIEWMODE } from 'redux/constants';
import initialState from 'redux/store/initialState';

export default function setViewMode(state = initialState.viewMode, action) {
  switch (action.type) {
    case SET_VIEWMODE: {
      return action.value;
    }
    default: {
      return state;
    }
  }
}
