import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { fetchData } from 'navigation/MainNavigator/helper';
import './styles.css';
import { payHandler, getCurrencySign, getOrderSummary } from './helpers';
import { countryObj, countriesOps } from '../../account-users/helpers';
import InvoiceRequest from './checkout-components/InvoiceRequest';
import { SuccessDialog, ProcessingDialog } from './checkout-components/CheckoutDialogs';
import BillingInfo from './checkout-components/BillingInfo';
import { CardField, ErrorMessage, SubmitButton, SummaryField } from './StripeElements';

const Checkout = ({
  i18n,
  i18nStripe,
  renewalInfo,
  upgradeInfo,
  // setUpgradeInfo,
  unauthorized,
  success,
  setSuccess,
  isFreeUser,
  onClose
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { billingInfo, currentPlan, renewal } = upgradeInfo;
  const { dateFormat, isSupervisor, isSupervisorCompost, bsId, bsIDs } = useSelector((store) => ({
    dateFormat: store.sessionInfo?.ui_preferences?.date_format,
    isSupervisor: store.isSupervisor,
    isSupervisorCompost: store.isSupervisorCompost,
    bsId: store.bsConf?.bs_id,
    bsIDs: store.userInfo.bs_BS_IDs
  }));
  const countriesOptions = countriesOps(i18n.locale);

  // PLAN
  const {
    planLabel,
    planPrice,
    simPrice,
    simLabel,
    apiPrice,
    apiLabel,
    subTotal,
    totalVTA,
    totalPrice
  } = getOrderSummary(currentPlan, dateFormat, renewal, upgradeInfo, i18n, renewalInfo, isFreeUser);

  const countryObject = { ...countryObj, data: countriesOptions };
  const [valueCountry, setValueCountry] = useState(
    countriesOptions.find((o) => o.value === billingInfo.country)
  );
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [successInvoice, setSuccessInvoice] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [billingDetails, setBillingDetails] = useState({
    name: billingInfo.name,
    email: billingInfo.email,
    address: {
      city: billingInfo.city,
      country: billingInfo.country,
      line1: billingInfo.address,
      postal_code: billingInfo.zip
    }
  });
  const [companyName, setCompanyName] = useState(billingInfo.company);

  // const [invoiceRequest, setInvoiceRequest] = useState(false);

  const handleSubmit = async (event, invoice) => {
    event?.preventDefault();
    setError(null);
    payHandler(
      stripe,
      elements,
      CardElement,
      cardComplete,
      upgradeInfo,
      billingDetails,
      companyName,
      totalPrice,
      i18n,
      i18nStripe,
      setProcessing,
      setSuccess,
      setError,
      dispatch,
      { ...renewalInfo, bsIDs },
      invoice,
      setSuccessInvoice
    );
  };

  const reset = async () => {
    const resetState = () => {
      setError(null);
      setProcessing(false);
      setSuccess(false);
      setSuccessInvoice(false);
      // setInvoiceRequest(false);
      setBillingDetails({
        name: '',
        email: '',
        address: {
          city: '',
          country: '',
          line1: '',
          postal_code: ''
        }
      });
      setCompanyName('');
      setSuccess(false);
      history.replace(success ? '/' : '/account/services');
    };
    if (success) {
      try {
        await fetchData(bsId, dispatch);
        resetState();
      } catch (e) {
        alert('An error occured, please refresh the page');
      }
    } else {
      resetState();
    }
  };

  return unauthorized || success || successInvoice ? (
    <SuccessDialog
      success={success}
      successInvoice={successInvoice}
      error={error}
      i18n={i18n}
      billingDetails={billingDetails}
      upgradeInfo={upgradeInfo}
      unauthorized={unauthorized}
      reset={reset}
    />
  ) : (
    <>
      {!isSupervisor && !isSupervisorCompost ? (
        <form className='Form' onSubmit={handleSubmit}>
          <h3 className='Title'>{i18n.billing_information}</h3>
          <fieldset className='FormGroup'>
            <BillingInfo
              i18n={i18n}
              billingDetails={billingDetails}
              setBillingDetails={setBillingDetails}
              companyName={companyName}
              setCompanyName={setCompanyName}
              countryObject={countryObject}
              valueCountry={valueCountry}
              setValueCountry={setValueCountry}
            />
          </fieldset>
          <h3 className='Title'>{i18n.order_summary}</h3>

          <fieldset className='FormGroup'>
            {upgradeInfo.products.plan === 1 && (
              <SummaryField label={planLabel} value={`${planPrice}€`} />
            )}
            {upgradeInfo.products.sim > 0 && (
              <SummaryField label={simLabel} value={`${simPrice}€`} />
            )}
            {upgradeInfo.products.api === 1 && (
              <SummaryField label={apiLabel} value={`${apiPrice}€`} />
            )}
            <SummaryField label={i18n.subtotal} value={`${subTotal}€`} />
            <SummaryField
              label={`${i18n.vat} (${parseInt(upgradeInfo.taxRate)}%)`}
              value={`${totalVTA.toFixed(2)}€`}
            />
            <SummaryField total label={i18n.total} value={`${totalPrice.toFixed(2)}€`} />
          </fieldset>
          <h3 className='Title'>{i18n.payment}</h3>
          <fieldset className='FormCardElement'>
            <CardField
              onChange={(e) => {
                setError(null);
                // console.log('error CardField', e, e.error);
                if (e.error) {
                  setError(e.error);
                  // setError({
                  //   ...e.error,
                  //   message: i18nStripe[e.error.code] || e.error.message
                  // });
                }
                setCardComplete(e.complete);
              }}
            />
          </fieldset>
          {error && <ErrorMessage>{error.message}</ErrorMessage>}
          <div className='submitDiv'>
            <SubmitButton i18n={i18n} processing={processing} error={error} disabled={!stripe}>
              {i18n.buy} ({totalPrice.toFixed(2)} {getCurrencySign(upgradeInfo.currency)})
            </SubmitButton>
          </div>
        </form>
      ) : (
        <InvoiceRequest
          i18n={i18n}
          billingDetails={billingDetails}
          setBillingDetails={setBillingDetails}
          upgradeInfo={upgradeInfo}
          // setInvoiceRequest={setInvoiceRequest}
          processing={processing}
          forwardHandleSubmit={handleSubmit}
          onClose={onClose}
        />
      )}
      {processing && !isSupervisor && !isSupervisorCompost && <ProcessingDialog i18n={i18n} />}
    </>
  );
};

const CheckoutForm = ({
  i18n,
  i18nStripe,
  renewalInfo,
  upgradeInfo,
  setUpgradeInfo,
  unauthorized,
  success,
  setSuccess,
  isFreeUser,
  onClose
}) => (
  <div className='AppWrapper'>
    <Checkout
      i18n={i18n}
      i18nStripe={i18nStripe}
      renewalInfo={renewalInfo}
      upgradeInfo={upgradeInfo}
      setUpgradeInfo={setUpgradeInfo}
      unauthorized={unauthorized}
      success={success}
      setSuccess={setSuccess}
      isFreeUser={isFreeUser}
      onClose={onClose}
    />
  </div>
);

export default CheckoutForm;
