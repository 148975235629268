import {
  Chip,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
  Button
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Icon } from '@iconify/react';
import shieldAccount from '@iconify/icons-mdi/shield-account';
import imageFilterHdr from '@iconify/icons-mdi/image-filter-hdr';
import warehouseIcon from '@iconify/icons-mdi/warehouse';
import BatchOutline from 'assets/svg/BatchOutline';

import { getSignalLevel, isLowBattery } from 'assets/utils';
import ListFooter from 'utils/ListFooter';
import { COLORS } from 'utils/colors';
import { stableSort, getComparator } from 'utils/sorting';
import SensorListItem from './SensorListItem';
import SensorListHeader from './SensorListHeader';
import { getSensorDetails } from '../../probes/sensor/sensor-detail/helper';
import { getStorageSensorIds } from '../../storages/storage-utils/storageHelper';
import ExpiredDialog from '../ExpiredDialog';
import ExpiredInsightFreemium from '../ExpiredInsightFreemium';

const useStyles = makeStyles(() => ({
  sensorsListWrapper: {
    display: 'flex',
    flexFlow: 'column',
    overflow: 'hidden'
  },
  idDigit: {
    fontSize: '10px',
    lineHeight: '10px'
  },
  hidden: {
    display: 'none'
  },
  sensorUsageList: {},
  sensorUsageChip: {
    fontSize: 11,
    height: 20,
    paddingLeft: 3,
    margin: '2px 2px 0px 0px',
    fontFamily: "'Roboto', sans-serif",
    maxWidth: 120,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: COLORS.white,
      borderColor: COLORS.mediumGray
    }
  },
  noLink: {
    color: 'inherit',
    textDecoration: 'inherit'
  }
}));

const BasicSensorList = ({
  sensorIDs,
  showMultipoint,
  dataSnapshot,
  bsConf,
  auxConf,
  selectedSensor,
  showHandleBar,
  alertSensorIDs,
  marginRight,
  alertSensorDepths,
  setSelectedSensor,
  hoveredSensor,
  onSensorHover,
  storages, // indicate sensor's presence in storages
  batches, // indicate sensor's presence in batches
  isPassive,
  newSensorsActivated,
  setNewSensorsActivated,
  outdoorIDS,
  outdoorIDAdded,
  setOutdoorIDAdded,
  storagesWithFC,
  isExpired,
  isSettingsView,
  selectedStorage,
  isFreeUser,
  isPlanExpired,
  onSensorClick,
  inStorage
}) => {
  const dispatch = useDispatch();
  const i18n = useSelector((state) => state.i18n);
  const isAdmin = useSelector((state) => state.isAdmin);
  const userInfo = useSelector((state) => state.userInfo);
  // const isPremium = useSelector((state) => state.isPremium);
  const sensorSorting = useSelector((state) => state.sensorSorting);
  const editConfirmNeeded = useSelector((state) => state.editConfirmNeeded);
  const socketUpdates = useSelector((store) => store.socketUpdates);
  const isDemoMode = useSelector((store) => store.isDemoMode);
  const limitFreeDataLive = useSelector((store) => store.sessionInfo?.limitFreeData);
  const history = useHistory();
  const classes = useStyles();
  const trial = userInfo?.service?.trial;
  const oldFreemiumExpiredTrial = trial && (trial === 2 || trial === -1) && isPlanExpired;
  const isInsightRefused = limitFreeDataLive && trial && trial === -1;

  const [showRefusedDialog, setShowRefusedDialog] = useState(false);

  const [refusedSensor, setRefusedSensor] = useState(null);

  const sensorClicked = (clickedID) => {
    const id = refusedSensor || clickedID;
    if (onSensorClick) {
      onSensorClick(id);
      return;
    }
    if (isInsightRefused && !refusedSensor) {
      if (isAdmin) {
        setRefusedSensor(id); // provide admin bypass to data
      }
      setShowRefusedDialog(true);
      return;
    }
    setRefusedSensor(null);
    setShowRefusedDialog(false);
    if (id === selectedSensor) {
      if (setSelectedSensor) {
        setSelectedSensor(null);
      }
    } else if (editConfirmNeeded) {
      dispatch({ type: 'SET_UNSAVED_CHANGES', value: true });
    } else {
      if (setSelectedSensor) {
        setSelectedSensor(id);
      }
      if (selectedStorage) {
        dispatch({ type: 'RESET_CHARTDATA' });
        dispatch({ type: 'RESET_DATATABLE' });
        history.push({
          pathname: `/storages/${selectedStorage?.itemID}/probes/${id}`,
          state: {
            urlBack: `/storages/${selectedStorage?.itemID}`,
            list: getStorageSensorIds(selectedStorage)
          }
        });
      }
    }
  };

  const getSensorStorages = (sensorID) => {
    let sensorPlaces = [];
    if (storages && storages.length > 0) {
      sensorPlaces = storages
        .filter(() => (isDemoMode ? isDemoMode !== 'WASTE' : true)) // in demo, take off sensors if waste demo
        // filter unique storages (in case duplicates bug)
        .filter((value, i, array) => array.map((a) => a.itemID).indexOf(value.itemID) === i)
        .filter((sto) => {
          if (
            storagesWithFC.find((obj) => obj.storage === sto.name && obj.outdoorID === sensorID)
          ) {
            return sensorID;
          }
          return getStorageSensorIds(sto).indexOf(sensorID) >= 0;
        })
        // sort alphabetically
        .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
        .map((sto) => {
          const obj = { type: 1, label: sto.name, link: `/storages/${sto.itemID}` };
          return obj;
        });
    }
    return sensorPlaces;
  };

  // Initially sort by temperature
  // const [sensors, setSensors] = useState([]);

  const getTabledata = () =>
    sensorIDs.map((sid) => {
      let lastData;
      const auxProbe = auxConf?.find((s) => s.a === sid);
      if (auxProbe) {
        lastData = getSensorDetails(auxProbe.a, null, null, auxProbe);
      } else {
        lastData = dataSnapshot.find((s) => s?.sensor_id === sid);
      }
      const signalLevel = lastData
        ? isLowBattery(lastData)
          ? -4
          : getSignalLevel(lastData.sensor_RSSI, lastData.bs_RSSI, lastData.meas_ts)
        : 0;
      const obj = {
        id: sid,
        temperature: lastData ? lastData.temperature : null,
        name: bsConf?.sensor_configurations?.find((s) => s?.sensor_id_sys === sid)
          ? bsConf?.sensor_configurations?.find((s) => s?.sensor_id_sys === sid)?.sensor_name || sid
          : sid,
        signal: signalLevel,
        updated: lastData ? new Date() - lastData.meas_ts * 1000 : null
      };
      return obj;
    });

  useEffect(() => {
    const getUpdate = () => {
      // setSensors(getTabledata());
      if (!outdoorIDAdded) {
        if (setNewSensorsActivated) {
          setNewSensorsActivated(false);
        }
      } else {
        setOutdoorIDAdded(false);
      }
      dispatch({ type: 'SET_SOCKET_UPDATES', value: false });
    };

    if (socketUpdates || newSensorsActivated || outdoorIDAdded) {
      getUpdate();
    }
    return () => {
      // console.log('getUpdate Cleanup');
    };
  }, [
    socketUpdates,
    newSensorsActivated,
    outdoorIDAdded,
    setNewSensorsActivated,
    setOutdoorIDAdded,
    dispatch,
    sensorIDs,
    auxConf,
    bsConf?.sensor_configurations,
    dataSnapshot
  ]);

  const handleSort = (newSortId) => {
    const sortDirectionChanged = sensorSorting[0] === newSortId;
    const newDirection =
      sortDirectionChanged && sensorSorting[1] === 'asc'
        ? 'desc'
        : sortDirectionChanged && sensorSorting[1] === 'desc'
        ? 'asc'
        : sensorSorting[1];
    dispatch({ type: 'SET_SENSOR_SORTING', value: [newSortId, newDirection] });
  };

  // References to sensor's storages or batches
  const getSensorChips = (sensorID) => {
    // Get items for storage(s) where sensor is located
    let sensorPlaces = getSensorStorages(sensorID);
    if (batches && batches.length > 0) {
      sensorPlaces = sensorPlaces.concat(
        batches
          .filter(() => (isDemoMode ? isDemoMode === 'WASTE' : true)) // in demo, take off batches if not waste demo
          .filter((b) => Number(b.status) <= 2) // active/completed batches
          // filter unique batches (until redux.batches bug fix, there may be duplicates)
          .filter((value, i, array) => array.map((a) => a.batch_id).indexOf(value.batch_id) === i)
          .filter((b) => {
            const batchSensors = b.batch_phases[b.batch_phases.length - 1].sensor_progress;
            return batchSensors?.map((s) => s.sensor_id).indexOf(sensorID) >= 0;
          })
          // sort alphabetically
          .sort((a, b) => (a.batch_name.toLowerCase() < b.batch_name.toLowerCase() ? -1 : 1))
          .map((b) => {
            const obj = {
              type: 2,
              completedBatch: Number(b.status) === 2,
              label: b.batch_name,
              link: `/batches/${b.batch_id}`
            };
            return obj;
          })
      );
    }
    // sensorPlaces is array of storage and batch name labels
    return sensorPlaces.length === 0 ? null : (
      <div className={classes.sensorUsageList}>
        {sensorPlaces.map((place, idx) => (
          <Link key={idx} className={classes.noLink} to={place.link}>
            <Chip
              aria-hidden='true' // enables onClick for div
              className={classes.sensorUsageChip}
              variant='outlined'
              size='small'
              style={place.completedBatch ? { color: COLORS.mediumGray } : null}
              icon={
                place.completedBatch ? (
                  <BatchOutline
                    style={{
                      minWidth: 18,
                      minHeight: 18,
                      marginLeft: 3,
                      marginRight: -4
                    }}
                    color={COLORS.boulderGray}
                  />
                ) : (
                  <Icon
                    // style={
                    //   place.completedBatch
                    //     ? { minWidth: 18, color: COLORS.lightGray3 }
                    //     : { minWidth: 18 }
                    // }
                    icon={place.type === 1 ? warehouseIcon : imageFilterHdr}
                  />
                )
              }
              label={place.label}
            />
          </Link>
        ))}
      </div>
    );
  };

  const sensorComponents = stableSort(
    getTabledata(),
    getComparator(sensorSorting[1], sensorSorting[0])
  )
    .map((p) => p.id)
    .filter((sid) => sensorIDs.includes(sid))
    .map((sid) => (
      <SensorListItem
        key={sid}
        sensorID={sid}
        showMultipoint={!isExpired && showMultipoint}
        lastData={
          dataSnapshot.find((s) => s?.sensor_id === sid) || auxConf?.find((aux) => aux?.a === sid)
        }
        sensorConf={
          bsConf?.sensor_configurations?.find((s) => s?.sensor_id_sys === sid) ||
          auxConf?.find((aux) => aux.a === sid)
        }
        isAux={auxConf?.find((aux) => aux.a === sid)}
        sensorClicked={sensorClicked}
        isSelectedSensor={selectedSensor === sid}
        marginRight={marginRight}
        i18n={i18n}
        showAlert={!isExpired && alertSensorIDs && alertSensorIDs.indexOf(sid) >= 0}
        alertDepths={
          alertSensorDepths && alertSensorDepths.find((s) => s?.sensor_id === sid)?.depths
        }
        showHandleBar={showHandleBar}
        hoveredSensor={hoveredSensor}
        onSensorHover={onSensorHover}
        isPassive={
          isPassive ||
          isExpired ||
          (!bsConf?.sensor_configurations?.find((s) => s.sensor_id_sys === sid) &&
            !auxConf?.find((aux) => aux.a === sid))
        }
        additionalComponent={
          !isExpired && !isSettingsView && !isFreeUser ? getSensorChips(sid) : null
        }
        isOutdoorID={outdoorIDS?.find((id) => id === sid)}
        isExpired={isExpired}
        // sensor={sensors.find((s) => s.id === sid)}
      />
    ));

  const listHeader = (
    <SensorListHeader
      marginRight={marginRight}
      sortID={sensorSorting[0]}
      sortDirection={sensorSorting[1]}
      handleSort={handleSort}
    />
  );

  const paginationLimit = inStorage ? 200 : 50;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(paginationLimit);
  const [rowsPerPageOptions] = useState(
    sensorIDs.length > 200
      ? [50, 100, 200, { label: i18n.all || 'All', value: -1 }]
      : sensorIDs.length > 100
      ? [50, 100, { label: i18n.all || 'All', value: -1 }]
      : [50, { label: i18n.all || 'All', value: -1 }]
  );

  const listFooter = (
    <ListFooter
      data={sensorComponents}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      i18n={i18n}
      rowsPerPageOptions={rowsPerPageOptions}
    />
  );

  const listWithPagination = (
    <TableContainer>
      <Table className={classes.table} aria-label='custom pagination table'>
        <TableBody>
          {(rowsPerPage > 0
            ? sensorComponents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : sensorComponents
          ).map((row) => (
            <TableRow key={row.key}>
              <TableCell style={{ border: 'none', padding: 0 }} component='th' scope='row'>
                {row}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        {listFooter}
      </Table>
    </TableContainer>
  );

  return (
    <>
      <div
        className={classes.sensorsListWrapper}
        style={isSettingsView ? { filter: 'grayscale(1)' } : null}
      >
        {sensorComponents.length > 0 && !isExpired ? listHeader : null}
        {sensorComponents.length > paginationLimit ? listWithPagination : sensorComponents}
        {oldFreemiumExpiredTrial ? <ExpiredInsightFreemium /> : <ExpiredDialog />}
      </div>
      <Dialog open={showRefusedDialog} aria-labelledby='responsive-dialog-title'>
        <DialogTitle>{i18n.limited_data_title || 'Access to data history limited'}</DialogTitle>
        <Typography style={{ margin: '0 25px 20px', maxWidth: 400 }}>
          {i18n.limited_data_text ||
            'Probe data history is not available in this limited version of the Quanturi INSIGHT service. Please renew the service to get access.'}
        </Typography>
        <DialogActions>
          <Button
            onClick={() => {
              setRefusedSensor(null);
              setShowRefusedDialog(false);
            }}
          >
            {i18n.close || 'Close'}
          </Button>
          <Button
            style={{ color: COLORS.white }}
            onClick={() => {
              setRefusedSensor(null);
              setShowRefusedDialog(false);
              history.push(`/account/services/renewal?upgrade=1`);
            }}
            variant='contained'
            color='secondary'
          >
            {i18n.renew_subscription || 'Renew subscription'}
          </Button>
        </DialogActions>
        {isAdmin && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: COLORS.whisperGray,
              padding: 10,
              borderTop: `1px solid ${COLORS.darkGray}`
            }}
          >
            <Button
              variant='outlined'
              style={{ fontSize: 12, padding: '0 10px' }}
              onClick={() => sensorClicked()}
            >
              <Icon style={{ marginRight: 5 }} icon={shieldAccount} />
              OPEN PROBE DATA
            </Button>
          </div>
        )}
      </Dialog>
    </>
  );
};

export default BasicSensorList;
