import Nouislider from 'react-nouislider';
import { COLORS } from 'utils/colors';
import { getGradientColor, tempFormat } from 'assets/utils';
import { QSlider, ColorSlider, ChartSlider } from './Settings.styled';

export const initialSettings = {
  alarm_treshold_1: {
    value: 0,
    color: COLORS.secondaryColor
  },
  sms1: {
    value: 'no'
  },
  alarm_treshold_2: {
    value: 0,
    color: COLORS.secondaryColor
  },
  sms2: {
    value: 'no'
  },
  lang: {
    value: 'en'
  },
  degrees: {
    value: '°C'
  },
  d_format: {
    value: 'd.M.yyyy'
  },
  t_format: {
    value: 'HH:mm'
  },
  safety_alert: {
    value: 'true'
  },
  temp_alert: {
    value: 85
    // color: COLORS.secondaryColor
  },
  incl_exc: {
    value: 'false'
  },
  incl_sens_data: {
    value: 'false'
  },
  meas_per_day: {
    value: 12
  },
  def_chart: {
    value: [0, 100]
  },
  def_color: {
    value: [0, 40, 60, 80, 100]
  },
  handle_temp: {
    value: 'true'
  },
  // ONLY FOR ADMIN
  heartbeat: {
    value: '0'
  },
  communication_interval: {
    value: 0,
    color: COLORS.secondaryColor
  },
  timeout: {
    value: 0,
    color: COLORS.adminProd
  },
  buffer_max: {
    value: 0,
    color: COLORS.adminProd
  },
  clear_buffer: {
    value: '0'
  },
  tempIntervalDegrees: {
    value: 0,
    color: COLORS.secondaryColor
  },
  tempIntervalTime: {
    value: 0,
    color: COLORS.secondaryColor
  },
  measurement_interval_0: {
    value: 0,
    color: COLORS.secondaryColor
  },
  measurement_interval_1: {
    value: 0,
    color: COLORS.secondaryColor
  },
  measurement_interval_2: {
    value: 0,
    color: COLORS.secondaryColor
  },
  receiving_time: {
    value: 5,
    color: COLORS.secondaryColor
  }
};

export const generateSettings = (i18n, bsConf, isAdmin, sessionInfo, degrees) => {
  const newSettings = bsConf ? { ...initialSettings } : {};

  if (bsConf) {
    newSettings.alarm_treshold_1.value = bsConf.alarm_treshold_1;
    newSettings.alarm_treshold_2.value = bsConf.alarm_treshold_2;
    newSettings.alarm_treshold_1.color = getGradientColor(bsConf.alarm_treshold_1);
    newSettings.alarm_treshold_2.color = getGradientColor(bsConf.alarm_treshold_2);
    newSettings.sms1.value = Number(bsConf.warning_messages) === 1 ? 'yes' : 'no';
    newSettings.sms2.value = Number(bsConf.alert_messages) === 1 ? 'yes' : 'no';
  }

  if (bsConf?.tempIntervalDegrees) {
    newSettings.tempIntervalDegrees.value = bsConf.tempIntervalDegrees;
    newSettings.tempIntervalDegrees.title = `${
      i18n.interval_accelerate_temp || 'Measurement acceleration temperature limit'
    }`;
    newSettings.tempIntervalTime.value = bsConf.tempIntervalTime / 60;
    newSettings.tempIntervalTime.title = `${
      i18n.meas_int_title || 'Measurement interval above'
    } ${tempFormat(degrees, bsConf.tempIntervalDegrees, 0)}${degrees}`;
  }
  if (bsConf && (isAdmin || bsConf.userBSIntervals)) {
    newSettings.communication_interval.value = bsConf.communication_interval / 60;
    newSettings.measurement_interval_0.value = bsConf.measurement_interval_0 / 60;
    newSettings.measurement_interval_1.value = bsConf.measurement_interval_1 / 60;
    newSettings.measurement_interval_2.value = bsConf.measurement_interval_2 / 60;

    newSettings.measurement_interval_0.title = i18n.def_meas_int_title;
    newSettings.measurement_interval_1.title = `${
      i18n.meas_int_title || 'Measurement interval above'
    } ${tempFormat(degrees, bsConf.alarm_treshold_1, 0)}${degrees}`;
    newSettings.measurement_interval_2.title = `${
      i18n.meas_int_title || 'Measurement interval above'
    } ${tempFormat(degrees, bsConf.alarm_treshold_2, 0)}${degrees}`;
  }

  if (bsConf && isAdmin) {
    if (!bsConf.heartbeat) {
      newSettings.heartbeat.hidden = true;
    } else {
      newSettings.heartbeat.value = bsConf.heartbeat.toString();
      newSettings.heartbeat.hidden = false;
    }

    if (!bsConf.timeout) {
      newSettings.timeout.hidden = true;
    } else {
      newSettings.timeout.value = bsConf.timeout;
      newSettings.timeout.hidden = false;
    }

    if (!bsConf.buffer_max) {
      newSettings.buffer_max.hidden = true;
    } else {
      newSettings.buffer_max.value = bsConf.buffer_max;
      newSettings.buffer_max.hidden = false;
    }

    if (!bsConf.clear_buffer) {
      newSettings.clear_buffer.hidden = true;
    } else {
      newSettings.clear_buffer.value = bsConf.clear_buffer.toString();
      newSettings.clear_buffer.hidden = false;
    }

    if (!bsConf.receiving_time) {
      newSettings.receiving_time.hidden = true;
    } else {
      newSettings.receiving_time.value = bsConf.receiving_time;
      newSettings.receiving_time.hidden = false;
    }
  }

  if (sessionInfo) {
    if (sessionInfo.ui_preferences) {
      newSettings.lang.value = sessionInfo.ui_preferences.language;
      newSettings.degrees.value = sessionInfo.ui_preferences.degrees;
      newSettings.d_format.value = sessionInfo.ui_preferences.date_format;
      newSettings.t_format.value = sessionInfo.ui_preferences.time_format;
      newSettings.safety_alert.value = sessionInfo.ui_preferences.safety_alert || 'true';
      newSettings.temp_alert.value = sessionInfo.ui_preferences.temperature_alert;
      newSettings.incl_exc.value = sessionInfo.ui_preferences.include_exceptions;
      newSettings.incl_sens_data.value = sessionInfo.ui_preferences.include_sensor_data;
      newSettings.meas_per_day.value = sessionInfo.ui_preferences.measurements_per_day;
      newSettings.def_chart.value = sessionInfo.ui_preferences.chart_scale;
      newSettings.def_color.value = sessionInfo.ui_preferences.color_steps;
      newSettings.handle_temp.value = sessionInfo.ui_preferences.handle_temp_enabled;
    }
  }
  return newSettings;
};

export const prepareBsConfigForSaving = (settings, isAdmin) => {
  let bsConfTemp;
  if (isAdmin) {
    bsConfTemp = {
      alarm_treshold_1: settings.alarm_treshold_1.value,
      alarm_treshold_2: settings.alarm_treshold_2.value,
      warning_messages: settings.sms1.value === 'yes' ? 1 : 0,
      alert_messages: settings.sms2.value === 'yes' ? 1 : 0,
      heartbeat: parseInt(settings.heartbeat.value),
      communication_interval: parseInt(settings.communication_interval.value) * 60,
      timeout: settings.timeout.value === 'Default' ? 0 : parseInt(settings.timeout.value),
      buffer_max: settings.buffer_max.value === 'OFF' ? 0 : parseInt(settings.buffer_max.value),
      clear_buffer: parseInt(settings.clear_buffer.value),
      measurement_interval_0: parseInt(settings.measurement_interval_0.value) * 60,
      measurement_interval_1: parseInt(settings.measurement_interval_1.value) * 60,
      measurement_interval_2: parseInt(settings.measurement_interval_2.value) * 60,
      receiving_time: settings.receiving_time.value
    };
    if (settings.tempIntervalDegrees) {
      bsConfTemp.tempIntervalDegrees = parseInt(settings.tempIntervalDegrees.value);
      bsConfTemp.tempIntervalTime = parseInt(settings.tempIntervalTime.value) * 60;
    }
  } else {
    bsConfTemp = {
      alarm_treshold_1: settings.alarm_treshold_1.value,
      alarm_treshold_2: settings.alarm_treshold_2.value,
      warning_messages: settings.sms1.value === 'yes' ? 1 : 0,
      alert_messages: settings.sms2.value === 'yes' ? 1 : 0,
      measurement_interval_0: parseInt(settings.measurement_interval_0.value) * 60
    };
    if (settings.alarm_treshold_1.value < 100) {
      bsConfTemp.measurement_interval_1 = parseInt(settings.measurement_interval_1.value) * 60;
    }
    if (settings.alarm_treshold_2.value < 100) {
      bsConfTemp.measurement_interval_2 = parseInt(settings.measurement_interval_2.value) * 60;
    }
    if (settings.tempIntervalDegrees) {
      bsConfTemp.tempIntervalDegrees = parseInt(settings.tempIntervalDegrees.value);
      bsConfTemp.tempIntervalTime = parseInt(settings.tempIntervalTime.value) * 60;
    }
  }
  return bsConfTemp;
};

export const DefaultSlider = ({ value, marks, min, max, onChange, defaultValue }) => (
  <QSlider
    color='primary'
    value={value}
    step={1}
    track='inverted'
    marks={marks}
    min={min}
    max={max}
    valueLabelDisplay='on'
    onChange={onChange}
    valueLabelFormat={(x) => {
      if (+x === +defaultValue) {
        return 'Default';
      }
      return x;
    }}
  />
);

export const DefaultColorSlider = ({ value, marks, isf, min, max, onChange }) => (
  <ColorSlider
    color='primary'
    value={value}
    aria-labelledby='discrete-slider-always'
    step={1}
    marks={marks}
    track={false}
    is_f={isf}
    onChange={onChange}
    min={min}
    max={max}
    valueLabelDisplay='off'
  />
);

export const DefaultChartSlider = ({ value, marks, min, max, onChange }) => (
  <ChartSlider
    color='primary'
    value={value}
    aria-labelledby='discrete-slider-always'
    step={1}
    marks={marks}
    track={false}
    onChange={onChange}
    min={min}
    max={max}
    valueLabelDisplay='on'
  />
);

export const measRangeLogic = (bsConf, baseStation, sensorLogic) => {
  // For admin meas_intervals
  const defaultV3Range = [];
  for (let i = sensorLogic ? 0 : 1; i < 60; i++) {
    defaultV3Range.push(i);
  }
  for (let i = 1; i <= 24; i++) {
    defaultV3Range.push(i * 60);
  }

  const intervalValues = bsConf.tempIntervalDegrees
    ? [5, 10, 15, 30, 45, 60]
    : (!sensorLogic && bsConf.userBSIntervals) ||
      (Number(baseStation?.firmware_ver) >= 3 && defaultV3Range);

  const minValue = intervalValues ? intervalValues[0] : sensorLogic ? 0 : 1;
  const maxValue = intervalValues ? intervalValues[intervalValues.length - 1] : 60;
  const range = { min: minValue, max: maxValue };

  if (intervalValues) {
    for (let i = 1; i < intervalValues.length - 1; i++) {
      const percent = Math.round((i / (intervalValues.length - 1)) * 100);
      range[`${percent}%`] = intervalValues[i];
    }
  }
  return { intervalValues, range };
};

export const MeasIntervalSlider = ({ i18n, value, range, snap, onChange, inDialog }) => (
  <Nouislider
    start={[value]}
    onChange={onChange}
    tooltips={[
      {
        to(v) {
          if (parseInt(v) === 0) {
            return 'Default';
          }
          if (parseInt(v) > 60) {
            return `${parseInt(v) / 60} ${i18n.hour_mini}`;
          }
          return `${parseInt(v)} ${i18n.minute_mini}`;
        }
      }
    ]}
    snap={snap}
    range={range}
    disabled={!inDialog}
  />
);
