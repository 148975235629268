import { SET_LOADING } from 'redux/constants';
import initialState from 'redux/store/initialState';

export default function setLoading(state = initialState.isLoading, action) {
  switch (action.type) {
    case SET_LOADING: {
      return action.value;
    }
    default: {
      return state;
    }
  }
}
