import { Dialog, DialogContent, Grid, Select, MenuItem, FormControl } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import { DialogActionUtil } from 'utils/DialogActions';
import {
  deleteVerification,
  getVerifications,
  // saveVerification,
  startVerification,
  stopVerification
} from 'api/serverAPI';
import DialogTitleWithClose from 'utils/DialogWithCloseIcon';

import { COLORS } from 'utils/colors';
import QuanturiSVG from 'assets/svg/QuanturiSVG';
import VerifixTable from './VerifixTable';
import ReportDataLoader from './VerifixReportDataLoader';

export const useStyles = makeStyles(() => ({
  mainContainer: {
    height: 'calc(100% - 100px)',
    padding: 25
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'space-between'
  },
  dialogTitle: {
    minWidth: 200
  },
  detailsDialogTitle: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 14
  },
  detailsDialogContent: {
    fontWeight: 'bold',
    padding: '5px 0'
  },
  contentFirst: {
    paddingBottom: 20
  },
  noVerifAvailable: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  verificationSelectorSection: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 20
  },
  verificationSelector: {
    paddingLeft: 10
  },
  verifSelector: {
    minWidth: 125,
    margin: '0px 5px'
  },
  detailsSection: {
    paddingLeft: 0,
    height: 'inherit'
  },
  detailsTitle: {
    padding: '5px 0'
  },
  detailsContent: {
    fontWeight: 'bold'
  }
}));

const quanturiLogo = (
  <QuanturiSVG
    id='reportLogo'
    color={COLORS.primaryColor}
    style={{
      width: 90,
      position: 'absolute',
      display: 'none',
      opacity: 0
    }}
  />
);

const GenericDialog = ({
  open,
  classes,
  onClose,
  title,
  Content,
  onCancel,
  onSave,
  textSave,
  noSystemAvailable
}) => (
  <Dialog open={open}>
    {!!title && (
      <DialogTitleWithClose className={classes.dialogTitle} onClose={onClose}>
        {title}
      </DialogTitleWithClose>
    )}
    <DialogContent className={classes.dialogContent} elevation={0}>
      <Content />
    </DialogContent>
    <DialogActionUtil
      onCancel={onCancel}
      onSave={onSave}
      text={textSave}
      disabled={noSystemAvailable}
    />
  </Dialog>
);

const CreateNewVerification = ({
  i18n,
  classes,
  setLastUpdate,
  UID,
  BS_ID,
  probeID,
  listVerifixIDs,
  verifixID,
  setVerifixID,
  verifixBS,
  newVerificationDialogOpen,
  setNewVerificationDialogOpen,
  noSystemAvailable
}) => {
  const onCreateNewVerificationHander = async () => {
    const newVerificationObject = {
      UID,
      BS_ID,
      probeID,
      verifixID,
      verifixBS,
      startDate: new Date()
    };
    try {
      await startVerification(newVerificationObject);
      setLastUpdate(new Date());
    } catch (e) {
      console.log('error', e);
    }
  };

  const Content = () => {
    if (noSystemAvailable) {
      return <div>{i18n.no_verifix_available}</div>;
    }

    return (
      <Grid container justifyContent='space-around' direction='column'>
        <div className={classes.contentFirst}>
          <div className={classes.detailsDialogTitle}>{i18n.verifix_probe_checked}</div>
          <div className={classes.detailsDialogContent}>{probeID}</div>
        </div>
        <div>
          <div className={classes.detailsDialogTitle}>{i18n.verifix_probe_ref}</div>
          <div className={classes.detailsDialogContent}>
            <Select value={verifixID} onChange={(e) => setVerifixID(e.target.value)}>
              {listVerifixIDs.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      </Grid>
    );
  };

  return (
    <GenericDialog
      open={newVerificationDialogOpen}
      classes={classes}
      onClose={() => setNewVerificationDialogOpen(false)}
      title={i18n.new_verif}
      Content={Content}
      onCancel={() => setNewVerificationDialogOpen(false)}
      onSave={onCreateNewVerificationHander}
      noSystemAvailable={noSystemAvailable}
      textSave={i18n.start}
    />
  );
};

const EndVerification = ({
  i18n,
  classes,
  endVerificationDialogOpen,
  setEndVerificationDialogOpen,
  setLastUpdate,
  itemID,
  data
}) => {
  const Content = () => <div>{i18n.verifix_end_msg}</div>;
  const onEndNewVerificationHander = async () => {
    if (itemID) {
      try {
        if (!data || isEmpty(data)) {
          await deleteVerification({ itemID });
        } else {
          await stopVerification({ itemID });
        }
        setLastUpdate(new Date());
      } catch (e) {
        console.log('EndVerification error', e);
      }
    }
  };

  return (
    <GenericDialog
      open={endVerificationDialogOpen}
      classes={classes}
      Content={Content}
      onCancel={() => setEndVerificationDialogOpen(false)}
      onSave={onEndNewVerificationHander}
      textSave={i18n.end_verif}
    />
  );
};
const DeleteVerification = ({
  i18n,
  classes,
  itemID,
  setLastUpdate,
  deleteVerificationDialogOpen,
  setDeleteVerificationDialogOpen
}) => {
  const Content = () => <div>{i18n.verifix_delete_msg}</div>;

  const onDeleteVerification = async () => {
    try {
      await deleteVerification({ itemID });
      setLastUpdate(new Date());
    } catch (e) {
      console.log('DeleteVerification error', e);
    }
  };

  return (
    <GenericDialog
      open={deleteVerificationDialogOpen}
      classes={classes}
      Content={Content}
      onCancel={() => setDeleteVerificationDialogOpen(false)}
      onSave={() => onDeleteVerification()}
      textSave={i18n.delete_verif}
    />
  );
};

const VerifixContainer = () => {
  const location = useLocation();
  const probePath = location.pathname.split('/');
  const probeID = probePath[probePath.length - 1];
  const [data, setData] = useState([]);
  const [lastUpdate, setLastUpdate] = useState(new Date());
  const [loadingReport, setLoadingReport] = useState(false);
  const i18n = useSelector((state) => state.i18n);
  const dateFormat = useSelector((store) => store.sessionInfo?.ui_preferences?.date_format);
  const timeFormat = useSelector((store) => store.sessionInfo?.ui_preferences?.time_format);
  const degrees = useSelector((store) => store.sessionInfo?.ui_preferences?.degrees);
  const UID = useSelector((state) => state.userInfo.U_ID);
  const verifixObject = useSelector((state) => state.userInfo.verifix);
  const BS_ID = useSelector((state) => state.bsConf.bs_id);
  const listVerifixIDs = verifixObject.verifixProbes;
  const noSystemAvailable = !verifixObject.verifixProbes || !verifixObject.verifixBS;

  const [verifixID, setVerifixID] = useState(listVerifixIDs && listVerifixIDs[0]);
  const classes = useStyles();

  const ongoingVerification = (verif) => !!verif && !verif.endDate;

  const [verifications, setVerifications] = useState([]);
  const [verifIndex, setVerifIndex] = useState(0);

  const [newVerificationDialogOpen, setNewVerificationDialogOpen] = useState(false);
  const [endVerificationDialogOpen, setEndVerificationDialogOpen] = useState(false);
  const [deleteVerificationDialogOpen, setDeleteVerificationDialogOpen] = useState(false);

  const [oneVerificationOngoing, setOneVerificationOngoing] = useState(
    !!verifications.find((v) => v.endDate === null)
  );

  useEffect(() => {
    const getVerificationsHandler = async () => {
      setVerifIndex(0);
      setNewVerificationDialogOpen(false);
      setEndVerificationDialogOpen(false);
      setDeleteVerificationDialogOpen(false);
      const verifs = await getVerifications({ UID, probeID });
      setVerifications(verifs.results);
      setOneVerificationOngoing(!!verifs.results.find((v) => v.endDate === null));
    };
    getVerificationsHandler();
  }, [lastUpdate, UID, probeID]);

  const onOpenReport = () => {
    setLoadingReport(true);
  };

  const verificationStatusHandler = () => {
    if (ongoingVerification(verifications[verifIndex])) {
      setEndVerificationDialogOpen(!endVerificationDialogOpen);
    } else {
      setNewVerificationDialogOpen(!newVerificationDialogOpen);
    }
  };

  const verifDates = (verif) =>
    `${format(new Date(verif.startDate), 'dd.M.yyyy HH:mm')} - ${
      !verif.endDate ? '' : format(new Date(verif.endDate), 'dd.M.yyyy HH:mm')
    }`;

  const verificationName = (verif) =>
    ongoingVerification(verif) ? i18n.ongoing : format(new Date(verif.endDate), 'dd.M.yyyy HH:mm');
  return (
    <div className={classes.mainContainer}>
      {/* <div> */}
      {quanturiLogo}
      <div className={classes.verificationSelectorSection}>
        <div>
          {i18n.verifications}
          {i18n.colon}
        </div>
        <div className={classes.verificationSelector}>
          {isEmpty(verifications) ? (
            <span className={classes.noVerifAvailable}>{i18n.no_verifs}</span>
          ) : (
            <FormControl className={classes.verifSelector}>
              <Select value={verifIndex} onChange={(e) => setVerifIndex(e.target.value)}>
                {verifications.map((verif, i) => (
                  <MenuItem key={i} value={i}>
                    {verificationName(verif)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </div>
      </div>
      <div className={classes.detailsSection}>
        {isEmpty(verifications) ? (
          <div />
        ) : (
          <>
            <div>
              <div className={classes.detailsTitle}>
                {i18n.verification_date}
                <span className={classes.detailsContent}>
                  {i18n.colon}{' '}
                  {verifications[verifIndex] ? verifDates(verifications[verifIndex]) : ''}
                </span>
              </div>
              <div className={classes.detailsTitle}>
                {i18n.verifix_probe_ref}
                <span className={classes.detailsContent}>
                  {i18n.colon} {verifications[verifIndex].verifixID}
                </span>
              </div>
            </div>
            <VerifixTable
              verification={verifications[verifIndex]}
              dateFormat={dateFormat}
              degrees={degrees}
              data={data}
              setData={setData}
            />
          </>
        )}
      </div>
      {/* </div> */}
      <DialogActionUtil
        onCancel={onOpenReport}
        cancelDisabled={isEmpty(verifications) || ongoingVerification(verifications[verifIndex])}
        onSave={verificationStatusHandler}
        text={
          ongoingVerification(verifications[verifIndex])
            ? !data || isEmpty(data)
              ? i18n.cancel
              : i18n.end_verif
            : i18n.new_verif
        }
        disabled={!ongoingVerification(verifications[verifIndex]) && oneVerificationOngoing}
        cancelText={i18n.report}
        deleteButton
        handleDelete={() => setDeleteVerificationDialogOpen(true)}
        deleteUnauthorized={isEmpty(verifications) || !verifications[verifIndex].endDate}
        specialDeleteUnauthorized=''
      />
      {loadingReport ? (
        <ReportDataLoader
          i18n={i18n}
          loadingReport={loadingReport}
          setLoadingReport={setLoadingReport}
          BS_ID={BS_ID}
          timeFormat={timeFormat}
          dateFormat={dateFormat}
          verification={verifications[verifIndex]}
          reportDates={verifDates(verifications[verifIndex])}
          endDate={verifications[verifIndex].endDate}
          reportName={`${verifications[verifIndex]?.probeID}_verification_report_${format(
            new Date(),
            'yyyyMMdd'
          )}`}
          data={data}
        />
      ) : null}
      <CreateNewVerification
        i18n={i18n}
        classes={classes}
        setLastUpdate={setLastUpdate}
        UID={UID}
        BS_ID={BS_ID}
        probeID={probeID}
        listVerifixIDs={listVerifixIDs}
        verifixID={verifixID}
        setVerifixID={setVerifixID}
        verifixBS={verifixObject.verifixBS}
        newVerificationDialogOpen={newVerificationDialogOpen}
        setNewVerificationDialogOpen={setNewVerificationDialogOpen}
        noSystemAvailable={noSystemAvailable}
      />
      <EndVerification
        i18n={i18n}
        classes={classes}
        itemID={verifications[verifIndex]?.itemID}
        setLastUpdate={setLastUpdate}
        endVerificationDialogOpen={endVerificationDialogOpen}
        setEndVerificationDialogOpen={setEndVerificationDialogOpen}
        data={data}
      />
      <DeleteVerification
        i18n={i18n}
        classes={classes}
        itemID={verifications[verifIndex]?.itemID}
        setLastUpdate={setLastUpdate}
        deleteVerificationDialogOpen={deleteVerificationDialogOpen}
        setDeleteVerificationDialogOpen={setDeleteVerificationDialogOpen}
      />
    </div>
  );
};

export default VerifixContainer;
