import React, { Suspense, useState, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { getTranslationsData } from 'redux/reducers/setLanguage';
import { getAuth, getSessionInfo, postRefreshToken } from 'api/serverAPI';
import jwtManager, { accessTokenKey, refreshTokenKey } from 'api/jwtManager';
import { getUrlParams } from 'components/general/login/Login';
import PublicAPI from 'api/publicAPI';
import Insight from 'components/insight/Insight';
import PublicRoute, { PATH_LIST, ANONYMOUS_PATH_LIST } from './PublicRoute';
import PrivateRoute from './PrivateRoute';

import MainNavigator from './MainNavigator/MainNavigator';

const SensorProduction = React.lazy(() => import('components/admin/SensorProduction'));
const GariLogs = React.lazy(() => import('components/admin/GariLogs'));
const EarlyBsCallsLog = React.lazy(() => import('components/admin/EarlyBsCallsLog'));
const Activate = React.lazy(() => import('components/activation/Selection'));
const Activation = React.lazy(() => import('components/activation/system/Activation'));
const GariMigration = React.lazy(() => import('components/activation/system/GariMigration'));
const ActivatingProbes = React.lazy(() => import('components/activation/probes/ActivatingProbes'));
const CustomerSupport = React.lazy(() => import('components/customerSupport/CustomerSupport'));

const Login = React.lazy(() => import('components/general/login/Login'));
const NoMatch = React.lazy(() => import('components/general/NoMatch'));

// App state object in root to store state of authentication
const initialState = {
  isLoggedIn: false,
  isLoading: true
};

const storeRequestParamsToStorage = ({ accessToken, refreshToken }) => {
  if (accessToken && refreshToken) {
    jwtManager.setToken(accessTokenKey, accessToken);
    jwtManager.setToken(refreshTokenKey, refreshToken);
  }
};

const AppRoutes = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const anonymousPath = !!ANONYMOUS_PATH_LIST.find(
    (path) => path === location.pathname.split('/')[1]
  );

  const [state, setState] = useState(initialState);

  const userHasLoggedIn = (loggedIn) =>
    setState((tempState) => ({ ...tempState, isLoggedIn: loggedIn }));

  useEffect(() => {
    const fetchAuth = async () => {
      try {
        if (!state.isLoggedIn) {
          // if having request parameters, storing them to localStorage,
          const params = getUrlParams(window.location.search);
          storeRequestParamsToStorage(params);
          // Checking whether authenticated or not
          const response = jwtManager.getToken(accessTokenKey) ? await getAuth() : {};
          // On HTTP status 500, obviously token expired -> go to login
          if (response.status_code && response.status_code === 500) {
            window.location.href = '/login';
          } else if (response.status_code === 0) {
            // Set and trigger a new token interval after refreshing
            await postRefreshToken();
            const dataSession = await getSessionInfo();
            if (anonymousPath || dataSession.admin) {
              console.log('getTranslationsData at: fetchAuth');
              await dispatch(
                getTranslationsData(
                  dataSession?.ui_preferences?.language ?? 'en',
                  dataSession.UID,
                  !!dataSession.admin || 'anonymous'
                )
              );
            }
            if (dataSession.admin) {
              dispatch({ type: 'SET_ADMIN', value: true });
            }
            dispatch({ type: 'SET_LOGIN', value: true });
            setState((tempState) => ({ ...tempState, isLoggedIn: true, isLoading: false }));
          } else {
            dispatch({ type: 'SET_LOGIN', value: false });
            setState((tempState) => ({ ...tempState, isLoading: false }));
          }
        } else {
          setState((tempState) => ({ ...tempState, isLoading: false }));
        }
      } catch (err) {
        setState((tempState) => ({ ...tempState, isLoading: false }));
        dispatch({ type: 'SET_LOGIN', value: false });
      }
    };

    fetchAuth();
    return () => {};
  }, [anonymousPath, dispatch, state.isLoggedIn]);

  return (
    !state.isLoading && (
      <Suspense fallback={<div />}>
        <Switch>
          <Route exact path='/api/v1/getSummary' component={PublicAPI} />
          <Route exact path='/api/v1/getOverview' component={PublicAPI} />
          <Route exact path='/api/v1/getMeasurements' component={PublicAPI} />
          <Route exact path='/api/v2/activateSystem' component={PublicAPI} />
          <Route exact path='/api/v2/activateProbes' component={PublicAPI} />
          <Route exact path='/api/v2/deactivateBS' component={PublicAPI} />
          <Route exact path='/api/v2/reactivateBS' component={PublicAPI} />
          <Route
            exact
            path='/SAV-quanturi'
            render={(props) => <CustomerSupport {...props} userHasLoggedIn={userHasLoggedIn} />}
          />
          <Route exact path='/test_' component={SensorProduction} />
          <Route exact path='/g-log' component={GariLogs} />
          <Route exact path='/bslog' component={EarlyBsCallsLog} />
          <Route
            exact
            path='/activation/system'
            render={(props) => <Activation {...props} userHasLoggedIn={userHasLoggedIn} />}
          />
          <Route
            exact
            path='/activation?code1=:id'
            render={(props) => <Activation {...props} userHasLoggedIn={userHasLoggedIn} />}
          />
          <Route
            exact
            path='/activation?code2=:id'
            render={(props) => <ActivatingProbes {...props} userHasLoggedIn={userHasLoggedIn} />}
          />
          <Route
            exact
            path='/activation/probes'
            render={(props) => <ActivatingProbes {...props} userHasLoggedIn={userHasLoggedIn} />}
          />
          <Route
            path='/activation'
            render={(props) => <Activate {...props} userHasLoggedIn={userHasLoggedIn} />}
          />
          <Route
            path='/insight'
            render={(props) => <Insight {...props} userHasLoggedIn={userHasLoggedIn} />}
          />
          <Route
            exact
            path='/login'
            render={(props) => (
              <Login {...props} userHasLoggedIn={userHasLoggedIn} Cookies={Cookies} />
            )}
          />
          <Route
            exact
            path='/gari'
            render={(props) => <GariMigration {...props} Cookies={Cookies} />}
          />
          <PrivateRoute
            path='/'
            component={MainNavigator}
            props={{ isLoggedIn: state.isLoggedIn, Cookies }}
          />
          <PublicRoute path={PATH_LIST} props={{ isLoggedIn: state.isLoggedIn }} />
          <Route component={NoMatch} />
        </Switch>
      </Suspense>
    )
  );
};

export default AppRoutes;
