import { Dialog, DialogTitle, Typography, Divider, ButtonBase } from '@material-ui/core';
import React from 'react';

import { InlineIcon } from '@iconify/react';

import { DialogActionUtil } from 'utils/DialogActions';
import { templateMonitorStyle } from 'utils/sharedStyles';
import FanHandSVG from 'assets/svg/FanHandSVG';
import RuleEditorDialog from './RuleEditorDialog';
import { iconSelected } from '../../monitor-utils/MonitorRulesUtils';
import { controllerRule } from '../../monitor-utils/fcHelpers';

const RuleTypeSelector = ({
  i18n,
  setMonitorEdited,
  monitorEdited,
  ruleTypes,
  selectRuleTypeDialog,
  setSelectRuleTypeDialog,
  selectedRuleType,
  setSelectedRuleType,
  selectedRule,
  setSelectedRule,
  newRule,
  setNewRule,
  setRuleInUseEdited,
  monitorInUse,
  selectedMonitor,
  timeStamp,
  FC_IDS,
  FcStatus,
  setToRunScheduler
}) => {
  const classes = templateMonitorStyle();
  // const classes = useStyles();
  // useEffect(() => {
  //   if (!selectedRule) {
  //     setSelectedRuleType(null);
  //   }
  // }, [selectedRule, setSelectedRuleType]);

  const onCancel = () => {
    setSelectRuleTypeDialog(false);
  };

  const onSelect = (type) => {
    setSelectedRuleType(type);
    setNewRule(true);
    setSelectedRule(null);
    setSelectRuleTypeDialog(false);
  };

  const disabled =
    monitorEdited?.rules?.map((rule) => controllerRule(rule))?.filter(Boolean)?.length > 0;

  return (
    <div>
      <Dialog
        className={classes.dialog}
        open={selectRuleTypeDialog}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogTitle>{i18n.select_rule_type || 'Select rule type'}</DialogTitle>
        <Divider />
        <div className={classes.div}>
          {ruleTypes.map((type) => {
            const disabledRule = disabled && type.group === 'FC';
            return (
              <ButtonBase
                key={type.id}
                onClick={() => (disabledRule ? {} : onSelect(type))}
                style={disabledRule ? { opacity: '50%', cursor: 'not-allowed' } : {}}
                className={classes.selectableRule}
              >
                <div className={classes.selectableRuleContent}>
                  <div
                    className={[
                      classes.ruleIconWrapper,
                      type.type.includes('MAX')
                        ? classes.hot
                        : type.type.includes('MIN')
                        ? classes.cold
                        : null
                    ].join(' ')}
                  >
                    {type.type === 'MANUAL_CONTROL' ? (
                      <div className={classes.ruleIcon} style={{ paddingLeft: 5 }}>
                        <FanHandSVG
                          color='white'
                          // style={{ width: 26, height: 26 }}
                        />
                      </div>
                    ) : (
                      <InlineIcon
                        className={
                          type.type === 'REL_MIN'
                            ? [classes.ruleIcon, classes.ruleIcon_REL_MIN].join(' ')
                            : type.type === 'REL_MAX'
                            ? [classes.ruleIcon, classes.ruleIcon_REL_MAX].join(' ')
                            : classes.ruleIcon
                        }
                        icon={iconSelected(type.type)}
                      />
                    )}
                  </div>
                  <div className={classes.ruleText}>
                    <Typography variant='body1' className={classes.ruleTitle}>
                      {i18n[`${type.type}_title`] || type.title}
                    </Typography>

                    <Typography className={classes.ruleDesc} variant='body2' component='p'>
                      {i18n[`${type.type}_text`] || type.text}
                    </Typography>
                  </div>
                </div>
              </ButtonBase>
            );
          })}
        </div>
        <DialogActionUtil onCancel={onCancel} cancelOnly />
      </Dialog>

      <RuleEditorDialog
        setMonitorEdited={setMonitorEdited}
        monitorEdited={monitorEdited}
        newRule={newRule}
        setNewRule={setNewRule}
        selectedRuleType={selectedRuleType}
        setSelectedRuleType={setSelectedRuleType}
        setSelectRuleTypeDialog={setSelectRuleTypeDialog}
        selectedRule={selectedRule}
        setSelectedRule={setSelectedRule}
        ruleTypes={ruleTypes}
        setRuleInUseEdited={setRuleInUseEdited}
        monitorInUse={monitorInUse}
        selectedMonitor={selectedMonitor}
        timeStamp={timeStamp}
        FC_IDS={FC_IDS}
        FcStatus={FcStatus}
        setToRunScheduler={setToRunScheduler}
      />
    </div>
  );
};

export default RuleTypeSelector;
