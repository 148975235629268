import { TableFooter, TablePagination, TableRow } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  table: {
    minWidth: 500
  },
  toolbar: {
    fontSize: '1rem',
    borderRadius: '0 0 3px 3px',
    borderRight: '1px solid #eeeeeedb',
    borderLeft: '1px solid #eeeeeedb',
    background: '-webkit-gradient(linear, left top, left bottom, from(#f6f6f6), to(#eee))',
    opacity: 0.8,
    paddingLeft: 5,
    '& > div:nth-of-type(2)': {
      margin: 5
    }
  }
});

export default function ListFooter({
  data,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  i18n,
  rowsPerPageOptions
}) {
  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          count={data?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage={i18n.rows_per_page}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} / ${count !== -1 ? count : `>${to}`}`
          }
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          classes={{
            toolbar: classes.toolbar,
            caption: classes.caption
          }}
        />
      </TableRow>
    </TableFooter>
  );
}
