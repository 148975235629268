import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import SensorDetails from './sensor/sensor-detail/SensorDetails';
import Sensors from '../settings/sensors/Sensors';
import GuardedRoute from '../../navigation/MainNavigator/GuardedRoute';

const Probes = () => {
  const isAdmin = useSelector((state) => state.isAdmin);
  const isPremium = useSelector((state) => state.isPremium);
  const insightService = useSelector((state) => state.insightService);

  const dispatch = useDispatch();
  const { url } = useRouteMatch();

  useEffect(() => {
    dispatch({ type: 'TOGGLE_VIEW', value: 'probes' }); // changes menu icon selection
    dispatch({ type: 'SET_GROUPDETAILS', value: null }); // clear possible storage selection
  }, [dispatch]);

  return (
    <div style={{ height: '100%', overflow: 'auto' }}>
      <Sensors probesView />
      <GuardedRoute
        exact
        path={`${url}/:probeId`}
        component={SensorDetails}
        auth={isPremium || isAdmin || insightService}
      />
    </div>
  );
};

export default Probes;
