import { compact } from 'lodash';
import { getSubId, getSignalLevel, getBatteryLevel } from 'assets/utils';

export const generateSensorList = (sensorConfigurations, sensorsData, search = '') =>
  compact(
    sensorConfigurations.map((config) => {
      const data = sensorsData.find((item) => item?.sensor_id === config.sensor_id_sys);
      const subid = getSubId(config.sensor_id_sys);
      const name = config ? (config.sensor_name ? config.sensor_name : subid) : subid;

      if (name.toLowerCase().indexOf(search.toLowerCase()) !== -1) {
        const battVoltage = sensorsData?.batt_voltage || 0;
        const measTs = data?.meas_ts || 0;
        const sensorRSSI = data?.sensor_RSSI || 0;
        const bsRSSI = data?.bs_RSSI || 0;
        return {
          type: 'sensor',
          id: config.sensor_id_sys,
          subid,
          name,
          temperature: data && !Number.isNaN(data.temperature) ? data.temperature : '',
          meas_ts: measTs,
          batt_voltage: battVoltage,
          battery: getBatteryLevel(battVoltage),
          sensor_RSSI: sensorRSSI,
          bs_RSSI: bsRSSI,
          signal: getSignalLevel(sensorRSSI, bsRSSI, measTs)
        };
      }
      return null;
    })
  );
