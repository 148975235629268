import { Tooltip } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';

import { Icon, InlineIcon } from '@iconify/react';

import checkCircle from '@iconify/icons-mdi/check-circle';
import { MdNotifications } from 'react-icons/md';

import eyeOff from '@iconify/icons-mdi/eye-off';
import circleOffOutline from '@iconify/icons-mdi/circle-off-outline';
import nail from '@iconify/icons-mdi/nail';

import { isNil } from 'assets/utils';
import { COLORS } from 'utils/colors';
import { listItemStatusBarStyle } from 'utils/sharedStyles';
import TempIndicators from 'utils/TempIndicators';

// Title with batch name and status indicator (active, completed)
const StorageListItemStatusBar = ({ minTemp, maxTemp, minTS, maxTS, monitor, probeIDs }) => {
  const classes = listItemStatusBarStyle();
  const i18n = useSelector((store) => store.i18n);

  const noData =
    isNil(minTemp) ||
    minTemp === -Infinity ||
    minTemp === Infinity ||
    isNil(maxTemp) ||
    maxTemp === -Infinity ||
    maxTemp === Infinity;

  let statusicons = (
    <div className={classes.okWrapper}>
      {noData ? (
        <Tooltip title={i18n.no_data_yet} placement='bottom' disableFocusListener>
          <div>
            <Icon
              style={{
                margin: 0,
                color: COLORS.warning,
                width: 32,
                height: 32,
                marginTop: 3
              }}
              className={classes.okStatusIcon}
              icon={circleOffOutline}
            />
          </div>
        </Tooltip>
      ) : (
        <Icon
          style={{
            margin: 0,
            color: null,
            width: null,
            height: null,
            marginTop: 0
          }}
          className={classes.okStatusIcon}
          icon={noData ? circleOffOutline : checkCircle}
        />
      )}
    </div>
  );

  let ruleCount = [];
  if (monitor?.rules) {
    ruleCount = monitor?.rules
      ?.map((rule) => rule.currentAlerts?.map((alert) => alert.sensor_id))
      .flat(1)
      .filter(Boolean)
      .filter((value, i, arr) => arr.indexOf(value) === i).length;
  }

  if (monitor && ruleCount !== 0) {
    statusicons = (
      <div className={classes.alertWrapper}>
        <MdNotifications size='2em' style={{ color: COLORS.alert }} />
        {/* <Icon style={{ margin: 0 }} className={classes.okStatusIcon} icon={MdNotifications} /> */}
        <div className={classes.alertsCount}>{ruleCount}</div>
      </div>
    );
  }

  const monitorInfo = (
    <div
      style={{ display: 'inherit', flexDirection: 'column', textAlign: 'center' }}
      className={
        monitor
          ? classes.statusIconsPanel
          : [classes.noMonitorStatusBar, classes.statusBar].join(' ')
      }
    >
      <div className={classes.monitorStyle}>
        {monitor ? monitor.name : i18n.no_monitor || 'No monitor'}
      </div>
      {monitor ? (
        statusicons
      ) : (
        <InlineIcon style={{ padding: 5 }} height={20} width={20} icon={eyeOff} />
      )}
    </div>
  );

  // Limit N of probe IDs displayed in tooltip, and sort by ID
  const Nshown = 10;
  const probesShown = probeIDs
    ? probeIDs.length > Nshown
      ? probeIDs.length === Nshown + 1
        ? probeIDs.sort((a, b) => (a < b ? -1 : 1))
        : probeIDs
            .sort((a, b) => (a < b ? -1 : 1))
            .filter((s, i) => i < Nshown)
            .concat(`+${probeIDs.length - Nshown} ${i18n.more || 'more'}`)
      : probeIDs.sort((a, b) => (a < b ? -1 : 1))
    : [];
  const probesListText = <span style={{ whiteSpace: 'pre-line' }}>{probesShown.join('\n')}</span>;
  const probesCount = (
    <Tooltip title={probesListText} placement='right' disableFocusListener>
      <div className={classes.probeCountPanel}>
        <div className={classes.probeCount}>{probeIDs ? probeIDs.length : 0}</div>
        <Icon className={classes.probeCountIcon} icon={nail} />
      </div>
    </Tooltip>
  );

  return (
    <div className={classes.statusArea}>
      <TempIndicators
        classes={classes}
        minTemp={minTemp}
        maxTemp={maxTemp}
        maxTS={maxTS}
        minTS={minTS}
      />
      {probesCount}
      {monitorInfo}
    </div>
  );
};

export default StorageListItemStatusBar;
