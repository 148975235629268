import React, { useReducer } from 'react';

const reducer = (state, action) => {
  switch (action.type) {
    case 'GET_RULE_TYPES':
      return { ...state, ruleTypes: action.value };
    case 'ADD_STORAGE':
      return { ...state, storages: action.value };
    case 'CHANGE_MATERIAL':
      return { ...state, storageMaterial: action.value };
    case 'CHANGE_DESCRIPTION':
      return { ...state, storageDescription: action.value };
    case 'FETCH_ROWS':
      return { ...state, rows: action.value };
    case 'FETCH_SENSORS':
      return { ...state, sensors: action.value };
    case 'CHANGE_TEMPLATE_NAME':
      return { ...state, processTemplateName: action.value };
    case 'CHANGE_TEMPLATE':
      return { ...state, processTemplate: action.value };
    case 'CHANGE_START_DATE':
      return { ...state, startDate: action.value };
    case 'CHANGE_TARGET_LIST':
      return { ...state, targetList: action.value };
    case 'CHANGE_BOOLEAN':
      return { ...state, phaseCompAuto: !state.phaseCompAuto };
    case 'CHANGE_CONT_TIME':
      return { ...state, phaseContTime: !state.phaseContTime };
    case 'CHANGE_VENTIL':
      return { ...state, useVentil: !state.useVentil };
    case 'CHANGE_RC':
      return { ...state, rc: action.value };
    case 'CHANGE_RC_RULE':
      return { ...state, rcRule: action.value };
    case 'CHANGE_PHASE_INFO':
      return { ...state, phaseInfo: action.value };
    case 'SHOW_LAYOUT_PANEL':
      return { ...state, showLayoutPanel: action.value };
    case 'VISU_ANGLE':
      return { ...state, visualAngle: action.value };
    case 'VISU_SIZE':
      return { ...state, visualCellsize: action.value };
    case 'VISU_SPACING':
      return { ...state, visualSpacing: action.value };
    case 'SHOW_PREFS':
      return { ...state, showStoragePrefs: action.value };
    case 'PROBE_SELECTED':
      // console.log('context PROBE_SELECTED ', action.value);
      return { ...state, selectedLayoutProbe: action.value };
    case 'EDITING_LAYOUT':
      // console.log('context EDITING_LAYOUT ', action.value);
      return { ...state, isEditingLayout: action.value };

    default:
  }
};

const initialState = {
  showLayoutPanel: true,
  visualAngle: 45,
  visualCellsize: 50,
  visualSpacing: 50,
  showStoragePrefs: false,
  selectedLayoutProbe: null,
  isEditingLayout: false,
  rows: [{ index: '0', rowName: 'Row 1', rowLength: '1' }],
  sensors: [],
  ruleTypes: [
    {
      id: 1,
      title: 'High temperature alert',
      text: 'Detect temperature raise above defined alert level',
      // icon: 'arrowCollapseUp',
      type: 'ABS_MAX',
      group: 'ABS',
      refs: [
        {
          id: 1,
          name: 'SINGLE',
          label: 'Single Measurement'
        },
        {
          id: 2,
          name: 'AVERAGE_TEMP',
          label: 'Average temperature'
        },
        {
          id: 3,
          name: 'AVERAGE_DEPTH',
          label: 'Average at depth',
          depths: [1, 2, 3, 4]
        }
      ]
    },
    {
      id: 2,
      title: 'Low temperature alert',
      text: 'Detect temperature drop below defined alert level',
      // icon: 'arrowCollapseDown',
      type: 'ABS_MIN',
      group: 'ABS',
      refs: [
        {
          id: 1,
          name: 'SINGLE',
          label: 'Single Measurement'
        },
        {
          id: 2,
          name: 'AVERAGE_TEMP',
          label: 'Average temperature'
        },
        {
          id: 3,
          name: 'AVERAGE_DEPTH',
          label: 'Average at depth',
          depths: [1, 2, 3, 4]
        }
      ]
    },
    {
      id: 3,
      title: 'Relative raise alert',
      text: 'Detect relative temperature raise during stable or decreasing overall temperature trend',
      // icon: 'downup',
      type: 'REL_MAX',
      group: 'REL',
      refs: [
        {
          id: 1,
          name: 'SINGLE',
          label: 'Single Measurement'
        },
        {
          id: 2,
          name: 'AVERAGE_TEMP',
          label: 'Average temperature'
        },
        {
          id: 3,
          name: 'AVERAGE_DEPTH',
          label: 'Average at depth',
          depths: [1, 2, 3, 4]
        }
      ]
    },
    {
      id: 4,
      title: 'Relative drop alert',
      text: 'Detect relative temperature drop during stable or increasing overall temperature trend',
      // icon: 'downup',
      type: 'REL_MIN',
      group: 'REL',
      refs: [
        {
          id: 1,
          name: 'SINGLE',
          label: 'Single Measurement'
        },
        {
          id: 2,
          name: 'AVERAGE_TEMP',
          label: 'Average temperature'
        },
        {
          id: 3,
          name: 'AVERAGE_DEPTH',
          label: 'Average at depth',
          depths: [1, 2, 3, 4]
        }
      ]
    },
    // {
    //   id: 7,
    //   title: 'Manual controller',
    //   text: 'Drive a Relay Controller manually',
    //   // icon: 'downup',
    //   type: 'MANUAL_CONTROL',
    //   group: 'FC',
    //   compostRule: true
    // },
    {
      id: 6,
      title: 'Duty cycle controller',
      text: 'Drive a Fan Controller with on/off time cycles',
      // icon: 'downup',
      type: 'CYCLIC_CONTROL',
      group: 'FC'
    },
    {
      id: 5,
      title: 'Cooldown controller',
      text: 'Drive a Fan Controller based on delta temperature',
      // icon: 'downup',
      type: 'COOLDOWN_CONTROL',
      group: 'FC',
      refs: [
        {
          id: 1,
          name: 'AVG',
          label: 'Average temperature',
          labeli18n: 'AVERAGE_TEMP'
        },
        // {
        //   id: 2,
        //   name: 'AVG_DEPTH',
        //   label: 'Average temperature at given depth',
        //   depths: [1, 2, 3, 4]
        // },
        {
          id: 2,
          name: 'MAX',
          label: 'Max temperature',
          labeli18n: 'max_temperature'
        }
        // {
        //   id: 4,
        //   name: 'MAX_DEPTH',
        //   label: 'Max temperature at given depth',
        //   depths: [1, 2, 3, 4]
        // }
        // {
        //   id: 3,
        //   name: 'AVERAGE_DEPTH',
        //   label: 'Average at depth',
        //   depths: [1, 2, 3, 4]
        // }
      ]
    }
    // {
    //   id: 4,
    //   title: 'Data break alert',
    //   text: 'Detect missing data from one or more probes',
    //   icon: 'nodata',
    //   type: 'DATA_BR'
    // }
  ],
  storages: [
    {
      id: 1,
      name: 'Storage A1',
      material: 'Corn Stabilized',
      type: 'Flat Storage',
      width: '12',
      length: '34',
      height: '49',
      batch: {
        id: 1,
        text: 'B123/21',
        duration: '54 d'
      },
      monitor: {
        name: 'monitor name 1',
        duration: '54 d',
        status: 1
      },
      data: {
        temp: 10
      }
    },
    {
      id: 2,
      name: 'Storage B7',
      material: 'Barley Stabilized',
      batch: {
        id: 2,
        text: 'bla bli blo',
        duration: '70 d'
      }
      // monitor: {
      //   name: 'monitor name 2',
      //   status: 2
      // },
      // data: {
      //   temp: 30
      // }
    }
  ]
};

const StorageContext = React.createContext(initialState);

function StorageProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <StorageContext.Provider value={{ state, dispatch }}>{children}</StorageContext.Provider>;
}
export { StorageContext, StorageProvider };
