import { Typography, Divider } from '@material-ui/core';
import React from 'react';
import arrowCollapseUp from '@iconify/icons-mdi/arrow-collapse-up';
import arrowCollapseDown from '@iconify/icons-mdi/arrow-collapse-down';
import subdirectoryArrowRight from '@iconify/icons-mdi/subdirectory-arrow-right';
import fanAuto from '@iconify/icons-mdi/fan-chevron-down'; // fan-auto';
import { InlineIcon } from '@iconify/react';
import fanIcon from '@iconify/icons-mdi/fan';
import fanClock from '@iconify/icons-mdi/fan-clock';
import FanHandSVG from 'assets/svg/FanHandSVG';

import { useSelector } from 'react-redux';
import { getGradientColor, tempFormat, tempFormatDelta } from 'assets/utils';
import { COLORS } from 'utils/colors';
import { templateMonitorStyle, listItemStatusBarStyle } from 'utils/sharedStyles';
import { controllerRule, ManualFcMode } from './fcHelpers';

export function isNil(value) {
  return value === null || value === undefined;
}

export const iconSelected = (type) => {
  switch (type) {
    case 'ABS_MAX':
      return arrowCollapseUp;
    case 'ABS_MIN':
      return arrowCollapseDown;
    case 'REL_MAX':
      return subdirectoryArrowRight;
    case 'REL_MIN':
      return subdirectoryArrowRight;
    case 'COOLDOWN_CONTROL':
      return fanAuto;
    case 'CYCLIC_CONTROL':
      return fanClock;

    default:
      break;
  }
};

export const CardTag = ({ classes, classesItem, rule, storages, monitor, FcStatus, style }) => {
  const degrees = useSelector((store) => store.sessionInfo?.ui_preferences?.degrees);
  const maxTempColor =
    Number.isNaN(rule.settings?.alertLimit) || rule.settings?.alertLimit === -Infinity
      ? COLORS.warning
      : `rgba(${getGradientColor(rule.settings?.alertLimit).split('(')[1].split(')')[0]},1)`;
  const minTempColor = Number.isNaN(rule.settings?.alertLimit)
    ? COLORS.warning
    : `rgba(${getGradientColor(rule.settings?.alertLimit).split('(')[1].split(')')[0]},1)`;
  // const minTempOpacity = 1; // TODO: Number.isNaN(minTemp) ? 1 : getOpacity(??);
  const maxTempOpacity = 1; // TODO: Number.isNaN(maxTemp) ? 1 : getOpacity(??);

  const getBackgroundColor = () => {
    if (controllerRule(rule)) {
      const storageInUse = FcStatus?.find((FC) => FC.FC_ID === rule.settings.FC_ID)?.inUse;
      const monitorIDofStorage = storages.find((st) => st.itemID === storageInUse)?.monitor?.itemID;
      if (monitorIDofStorage === monitor.itemID) {
        return COLORS.validStatus;
      }
      return COLORS.warning;
    }
    if (rule.type.substring(4, 8) === 'MAX') {
      return maxTempColor;
    }
    return minTempColor;
  };

  let tag;
  if (rule.type.substring(0, 3) === 'ABS') {
    tag = `${
      !Number.isNaN(rule.settings?.alertLimit) && rule.settings?.alertLimit !== -Infinity
        ? `${rule.type.substring(4, 8) === 'MAX' ? '≥ ' : '≤ '}${tempFormat(
            degrees,
            rule.settings?.alertLimit,
            1
          )}`
        : '- - -'
    }
    
     ${degrees}`;
  } else if (rule.type.substring(0, 3) === 'REL') {
    tag = `${
      !Number.isNaN(rule.settings?.alertLimit) && rule.settings?.alertLimit !== -Infinity
        ? `∆ ${rule.type.substring(4, 8) === 'MAX' ? '+' : '-'}${tempFormatDelta(
            degrees,
            rule.settings?.alertLimit
          )}`
        : '- - -'
    }
    
     ${degrees}`;
  } else if (controllerRule(rule)) {
    tag = (
      <div style={{ padding: '0px 7px', fontSize: 11 }}>
        <InlineIcon className={classes.FcIcon} icon={fanIcon} /> {rule.settings.FC_ID}
      </div>
    );
  } else {
    tag = 'TBD';
  }

  return (
    <div style={style} className={[classesItem.tempsPanel, classesItem.tempsPanelRule].join(' ')}>
      <div
        style={{
          backgroundColor: getBackgroundColor(),
          opacity: maxTempOpacity,
          display: 'block'
        }}
        className={[classesItem.tempIndicator, classesItem.tempIndicatorRule].join(' ')}
      >
        <div className={classesItem.tempFigure}>{tag}</div>
      </div>
    </div>
  );
};

const MonitorRules = ({
  monitor,
  state,
  inEditor,
  setSelectedRuleType,
  setSelectedRule,
  setNewRule,
  ruleinStorage,
  lastBottomMargin,
  responsive,
  index,
  FcStatus,
  disabled
}) => {
  const classesItem = listItemStatusBarStyle();
  const classes = templateMonitorStyle();
  const i18n = useSelector((store) => store.i18n);
  const storages = useSelector((store) => store.storages);
  const ruleSelected = (rule) => {
    setSelectedRuleType(state.ruleTypes.filter((ruleType) => ruleType.type === rule?.type)[0]);
    setSelectedRule(rule);
    setNewRule(true);
  };
  const currentFcStatus = FcStatus?.find(
    (fc) => fc.FC_ID === monitor.rules.find((r) => r.settings.FC_ID)?.settings?.FC_ID
  );

  const Logic = ({ rule, i }) => (
    <div key={Math.random()}>
      {!inEditor && !ruleinStorage && <Divider />}

      <div
        className={
          inEditor
            ? classes.selectedObject
            : disabled
            ? [classes.target, classes.targetDisabled].join(' ')
            : classes.target
        }
        key={Math.random() + rule.type} // + rule.id}
        onClick={() => (inEditor ? ruleSelected(rule) : '')}
        aria-hidden='true'
        // compensate card bottom padding
        style={
          lastBottomMargin && monitor?.rules?.length === i + 1
            ? { marginBottom: lastBottomMargin }
            : null
        }
      >
        <div className={classesItem.tempsCard}>
          {/* {state?.ruleTypes?.filter(ruleType => rule.type === ruleType)[0]?.icon} */}
          <div
            className={[
              classes.ruleIconWrapper,
              rule.type.includes('MAX')
                ? classes.hot
                : rule.type.includes('MIN')
                ? classes.cold
                : null
            ].join(' ')}
          >
            {monitor?.rules[i]?.type === 'MANUAL_CONTROL' ? (
              <div className={classes.ruleIcon} style={{ paddingLeft: 5 }}>
                <FanHandSVG
                  color='white'
                  // style={{ width: 26, height: 26 }}
                />
              </div>
            ) : (
              <InlineIcon
                className={
                  rule.type === 'REL_MIN'
                    ? [classes.ruleIcon, classes.ruleIcon_REL_MIN].join(' ')
                    : rule.type === 'REL_MAX'
                    ? [classes.ruleIcon, classes.ruleIcon_REL_MAX].join(' ')
                    : classes.ruleIcon
                }
                icon={iconSelected(monitor?.rules[i]?.type)}
              />
            )}
          </div>
          <Typography
            className={[classes.ruleTitle, responsive ? classes.ruleTitleNarrow : null].join(' ')}
          >
            {i18n[
              `${
                state?.ruleTypes?.filter((ruleType) => monitor?.rules[i]?.type === ruleType.type)[0]
                  ?.type
              }_title`
            ] ||
              state?.ruleTypes?.filter((ruleType) => monitor?.rules[i]?.type === ruleType.type)[0]
                ?.title}
          </Typography>
        </div>
        {ruleinStorage && controllerRule(rule) ? (
          <ManualFcMode
            i18n={i18n}
            classes={classes}
            currentFcStatus={currentFcStatus}
            rule={ruleinStorage}
          />
        ) : (
          <CardTag
            classes={classes}
            classesItem={classesItem}
            rule={rule}
            storages={storages}
            monitor={monitor}
            FcStatus={FcStatus}
          />
        )}
        {/* {rule.settings?.tempRef === 'AVERAGE TEMP' && (
              <div className={[classesItem.tempsPanel, classesItem.tempsPanelRule].join(' ')}>
                <div
                  style={{
                    display: 'block'
                  }}
                  className={[classesItem.tempIndicator, classesItem.tempIndicatorRule].join(' ')}
                >
                  <div className={classesItem.tempFigure}>{i18n.avg_mini || 'AVG'}</div>
                </div>
              </div>
            )}
            {rule.settings?.tempRef === 'AVERAGE DEPTH' && (
              <div className={[classesItem.tempsPanel, classesItem.tempsPanelRule].join(' ')}>
                <div
                  style={{
                    display: 'block'
                  }}
                  className={[classesItem.tempIndicator, classesItem.tempIndicatorRule].join(' ')}
                >
                  <div className={classesItem.tempFigure}>
                    {i18n.avg_mini || 'AVG'} {rule.settings?.depthProbe}m
                  </div>
                </div>
              </div>
            )} */}
      </div>
    </div>
  );

  let visualization;
  if (ruleinStorage) {
    visualization = <Logic key={Math.random()} rule={ruleinStorage} i={index} />;
  } else {
    visualization = monitor.rules?.map((rule, i) => (
      <Logic key={Math.random()} rule={rule} i={i} />
    ));
  }
  return visualization;
};

export default MonitorRules;
