import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import isDevMode from 'utils/isDevMode';

const RouteChangeTracker = () => {
  const location = useLocation();
  // const [initialized, setInitialized] = useState(false);
  const [GA4initialized, setGA4Initialized] = useState(false);

  useEffect(() => {
    // const now = new Date();
    // const changeDate = new Date(2023, 6, 1);
    // GA4 takes over UA on 1.7.2023 Before that, we keep both UA and GA4 in prod
    if (!isDevMode()) {
      ReactGA.initialize([
        {
          trackingId: 'G-5F2BZHCWFQ'
          // gaOptions: {...}, // optional
          // gtagOptions: {...}, // optional
        }
      ]);
      setGA4Initialized(true);
      console.log('init GA4');

      // if (now < changeDate) {
      //   // Keep old GA running in production
      //   console.log('init UA');
      //   ReactGA.initialize('UA-188142654-1'); // old UA ID
      //   setInitialized(true);
      // }
    }
  }, []);

  useEffect(() => {
    // if (initialized && !isDevMode()) {
    //   ReactGA.pageview(location.pathname + location.search); // Old UA call
    // }
    if (GA4initialized) {
      ReactGA.send({
        hitType: 'pageview',
        page: location.pathname + location.search
        // title: 'Custom Title'
      });
    }
  }, [GA4initialized, location]);
  return null;
};

export default RouteChangeTracker;
