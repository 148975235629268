import { storage } from 'utils/storage';
// eslint-disable-next-line import/no-cycle
import { postRefreshToken } from './serverAPI';

export const accessTokenKey = 'accessToken';
export const refreshTokenKey = 'refreshToken';

const jwtManager = () => {
  let refreshTimeOutId;

  const _refreshToken = (delay) => {
    // Validity period of the token in seconds, minus 20 seconds
    refreshTimeOutId = window.setTimeout(postRefreshToken, delay * 1000 - 20 * 1000);
  };

  const _abortRefreshToken = () => {
    if (refreshTimeOutId) {
      window.clearTimeout(refreshTimeOutId);
    }
  };

  const getToken = (tokenKey) => storage.getItem(tokenKey);

  const setToken = (tokenKey, token, delay) => {
    storage.setItem(tokenKey, token);
    if (delay) {
      _abortRefreshToken();
      _refreshToken(delay);
    }
  };

  const removeToken = (tokenKey) => {
    storage.removeItem(tokenKey);
    _abortRefreshToken();
  };

  return {
    getToken,
    setToken,
    removeToken
  };
};

export default jwtManager();
