import React from 'react';

import { SensorAppBar, SensorContent } from './SensorTabViewComponents';

const SensorTabView = ({
  selected,
  handleChange,
  tableEnabled,
  verifixEnabled,
  lifetimeEnabled
}) => (
  <>
    <SensorAppBar
      value={selected}
      handleChange={handleChange}
      tableEnabled={tableEnabled}
      verifixEnabled={verifixEnabled}
      lifetimeEnabled={lifetimeEnabled}
    />
    <SensorContent
      value={selected}
      handleChange={handleChange}
      tableEnabled={tableEnabled}
      verifixEnabled={verifixEnabled}
      lifetimeEnabled={lifetimeEnabled}
    />
  </>
);

export default SensorTabView;
